import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Text from 'components/texts/Text'
import HoverPopupWrapper from './HoverPopupWrapper'

const HOVER_POPUP_WIDTH = 360
// const HOVER_POPUP_OFFSET = 20

const TYPE_MAP = {
  informational: 'tealLightest',
  warning: 'redLightest',
}

const useStyles = makeStyles(theme => ({
  hoverPopup: {
    position: 'absolute',
    width: HOVER_POPUP_WIDTH,
    backgroundColor: 'white',
    boxShadow: '0 2px 8px rgba(90, 116, 129, .1)',
    borderRadius: 4,
    border: `1px solid ${theme.colors.skyBase}`,
    transition: 'opacity .2s linear',
    opacity: 0,
    zIndex: 1000,
  },
  title: {
    backgroundColor: ({ type }) => theme.colors[TYPE_MAP[type]],
    display: 'flex',
    alignItems: 'center',
    padding: 16,
    '& > :nth-child(2)': {
      marginLeft: 12,
    },
  },
  body: {
    padding: 16,
  },
}))

const HoverPopup = React.forwardRef((props, ref) => {
  const {
    type, icon, title, body, hide, action,
  } = props
  const timerRef = React.useRef(null)
  const [styles, setStyles] = useState({ pointerEvents: 'none' })
  const [showPopup, setShowPopup] = useState(false)
  const css = useStyles({ type })

  useEffect(() => {
    const container = ref.current
    function onMouseEnter() {
      clearTimeout(timerRef.current)
      setStyles(oldStyles => ({ ...oldStyles, opacity: 1, pointerEvents: 'auto' }))
    }

    function onMouseLeave(cb) {
      const timeout = action === 'hover' ? 500 : 5000

      timerRef.current = setTimeout(() => {
        setStyles(oldStyles => ({ ...oldStyles, opacity: 0, pointerEvents: 'none' }))
        if (typeof cb === 'function') cb()
      }, timeout)
    }

    function onClick() {
      clearTimeout(timerRef.current)
      setShowPopup(true)
      timerRef.current = setTimeout(() => {
        onMouseEnter()
        onMouseLeave(() => setShowPopup(false))
      }, 100)
    }

    if (container) {
      const { width, left } = container.getBoundingClientRect()
      let prop = 'right'
      const documentWidth = document.body.clientWidth

      if (documentWidth - left /*- HOVER_POPUP_OFFSET*/ > HOVER_POPUP_WIDTH) prop = 'left'

      setStyles(oldStyles => ({
        ...oldStyles,
        top: 'calc(100% + 12px)',
        [prop]: `calc(100% - ${width}px)`,
      }))

      if (action === 'hover') {
        container.addEventListener('mouseenter', onMouseEnter)
        container.addEventListener('mouseleave', onMouseLeave)
      }

      if (action === 'click') {
        container.addEventListener('mousedown', onClick)
      }
    }

    return () => {
      clearTimeout(timerRef.current)
      if (container) {
        container.removeEventListener('mouseenter', onMouseEnter)
        container.removeEventListener('mouseleave', onMouseLeave)
        container.removeEventListener('mousedown', onClick)
      }
    }
  }, [ref, action])

  useEffect(() => {
    const container = ref.current
    if (ref) container.style.position = 'relative'
  }, [ref])

  if (hide) return null

  const PopupWrapper = action === 'click' ? HoverPopupWrapper : React.Fragment

  return (
    <PopupWrapper show={showPopup}>
      <div
        onMouseEnter={e => {
          e.stopPropagation()
          e.preventDefault()
        }}
        className={css.hoverPopup}
        style={styles}
      >
        <div className={css.title}>
          {icon}
          <Text size={16} charcoalDarker>
            {title}
          </Text>
        </div>
        <div className={css.body}>
          <Text charcoalDarker>{body}</Text>
        </div>
      </div>
    </PopupWrapper>
  )
})

HoverPopup.displayName = 'HoverPopup'

HoverPopup.propTypes = {
  action: PropTypes.oneOf(['hover', 'click']),
  hide: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['informational', 'warning']),
  icon: PropTypes.node,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
}

HoverPopup.defaultProps = {
  type: 'informational',
  action: 'hover',
  icon: null,
}

export default React.memo(HoverPopup)

import React from 'react'
import PropTypes from 'prop-types'
import { AddOutlined } from '@material-ui/icons'

import Button from 'components/virtual/buttons/Button'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

function AddPageButton({ pageCopy }) {
  const { lightboxSettings } = React.useContext(BuilderContainerContext)
  const settings = lightboxSettings.getSettings()

  return (
    <Button
      onClick={() => settings.addPage()}
      color="secondary"
      label={`Add ${pageCopy.key}`}
      size="smaller"
      type="text"
      icon={<AddOutlined />}
    />
  )
}

AddPageButton.propTypes = {
  pageCopy: PropTypes.object.isRequired,
}

export default React.memo(AddPageButton)

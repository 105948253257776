import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { useDrag } from 'react-dnd'

const useStyles = makeStyles({
  dragWrapper: {
    opacity: ({ dragging }) => (dragging ? 0 : 1),
  },
})

function useCombinedRefs(...refs) {
  const targetRef = React.useRef()

  React.useEffect(() => {
    refs.forEach(ref => {
      if (!ref) return

      if (typeof ref === 'function') {
        ref(targetRef.current)
      } else {
        ref.current = targetRef.current
      }
    })
  }, [refs])

  return targetRef
}

function DragRef(props, ref = null) {
  const {
    data, canDrag, index, containerId, children,
  } = props
  const dragRef = React.useRef(null)
  const combinedRefs = useCombinedRefs(ref, dragRef)
  const [{ dragging }, drag] = useDrag({
    item: {
      type: 'drag',
      data, // drag data
      index, // array index
      containerId, // drop container id
      ref, // component ref
    },
    collect: monitor => ({ dragging: monitor.isDragging() }),
  })

  const css = useStyles({ dragging })

  drag(combinedRefs)

  return (
    <div ref={combinedRefs} className={cn('draggable', css.dragWrapper)} draggable={canDrag}>
      {children}
    </div>
  )
}

DragRef.propTypes = {
  canDrag: PropTypes.bool,
  index: PropTypes.number.isRequired,
  containerId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
}

DragRef.defaultProps = {
  canDrag: true,
}

const Drag = React.forwardRef(DragRef)

export default React.memo(Drag)

import React from 'react'
import PropTypes from 'prop-types'

import Text from 'components/texts/Text'

import GenericModalBase from './GenericModalBase'

function GenericModal(props) {
  const { body, ...restProps } = props

  return (
    <GenericModalBase {...restProps}>
      <div style={{ marginTop: 18 }} />
      <Text size={16} charcoalLight>
        {body}
      </Text>
    </GenericModalBase>
  )
}

GenericModal.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  show: PropTypes.bool,
}

GenericModal.defaultProps = {
  show: false,
  cancelLabel: 'Cancel',
  confirmLabel: 'Confirm',
}

export default React.memo(GenericModal)

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import cn from 'classnames'

import { P } from 'components/texts'

const useStyles = makeStyles(() => ({
  wrapper: {
    userSelect: 'none',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
  },
  suggestion: {
    cursor: 'pointer',
  },
  separator: {
    marginBottom: 16,
  },
}))

function SearchWrapper(props) {
  const { children, title, customClasses } = props
  const css = useStyles()

  const classNames = cn(css.wrapper, customClasses)

  return (
    <div className={classNames}>
      {title && (
        <div>
          <P>
            <em>{title}</em>
          </P>
          <div className={css.separator} />
        </div>
      )}
      {children}
    </div>
  )
}

SearchWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  customClasses: PropTypes.string,
  title: PropTypes.string,
}

SearchWrapper.defaultProps = {
  customClasses: '',
  title: '',
}

export default React.memo(SearchWrapper)

import React from 'react'
import PropTypes from 'prop-types'

import ConfirmationModal from 'components/virtual/modals/ConfirmationModal'

function DependencyModal(props) {
  const {
    open, setOpenDependency, store, dependency, connect,
  } = props

  const handleClose = () => {
    setOpenDependency(false)
  }

  const onConnect = async () => {
    connect(store)
    handleClose()
  }

  const capitalizeStoreName = store.charAt(0).toUpperCase() + store.slice(1)
  const capitalizeDependencyName = dependency.charAt(0).toUpperCase() + dependency.slice(1)

  const title = `${capitalizeStoreName} requires a ${capitalizeDependencyName} Integration`
  const text = `You must connect ${capitalizeDependencyName} before you can set up your ${capitalizeStoreName} integration.`

  return (
    <ConfirmationModal
      open={open}
      title={title}
      text={text}
      cancelLabel="Cancel"
      submitLabel={`Connect ${capitalizeDependencyName}`}
      onCancel={handleClose}
      onSubmit={onConnect}
    />
  )
}

DependencyModal.propTypes = {
  store: PropTypes.string.isRequired,
  dependency: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpenDependency: PropTypes.func.isRequired,
  connect: PropTypes.func.isRequired,
}

export default React.memo(DependencyModal)

import React from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import TabsMui from '@material-ui/core/Tabs'
import cn from 'classnames'

import { colors } from 'components/virtual/VirtualTheme'

import Text from 'components/texts/Text'
import BuilderTabsPanel from './BuilderTabsPanel'
import Tab from './BuilderTab'
import { BuilderContainerContext } from '../BuilderContainerContext'

const useStyles = makeStyles(theme => ({
  tabsContainer: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  tabs: {
    height: 72,
    display: 'flex',
    alignItems: 'flex-end',
    borderBottom: `1px solid ${theme.colors.skyBase}`,
  },
  tab: {
    minWidth: 180,
    marginRight: 16,
  },
  error: {
    position: 'relative',
    '&:after': {
      position: 'absolute',
      content: '""',
      width: 6,
      height: 6,
      borderRadius: '50%',
      backgroundColor: theme.colors.redBase,
      right: -16,
      top: 8,
    },
  },
}))

// TODO: use Ahnaf's component if possible
const Tabs = withStyles({
  root: {
    flex: 1,
  },
  indicator: {
    backgroundColor: colors.blueBase,
  },
})(TabsMui)

function BuilderTabs(props) {
  const { tabs, location, match } = props
  const { pathname } = location
  const { path, url } = match

  const history = useHistory()
  const context = React.useContext(BuilderContainerContext)
  const {
    builder: { section, errors },
  } = context

  const css = useStyles()

  if (!tabs?.length) return null

  const onTabClick = route => () => history.push(`${url}${route}`)

  const selectedTab = pathname.replace(path, '').split('/')[1]

  return (
    <div className={css.tabsContainer}>
      <div className={css.tabs}>
        <Tabs value={selectedTab} variant={section.fullPage ? 'standard' : 'fullWidth'}>
          {tabs.map(tab => {
            const tabPath = tab.path.replace('/', '')
            const hasError = errors.checkIfErrorInTab(tabPath)

            return (
              <Tab
                classes={{ root: cn({ [css.tab]: !!section.fullPage }) }}
                value={tabPath}
                key={tab.label}
                label={(
                  <span className={cn({ [css.error]: hasError })}>
                    <Text size={16} charcoalDarker>
                      {tab.label}
                    </Text>
                  </span>
                )}
                onClick={onTabClick(tab.path)}
              />
            )
          })}
        </Tabs>
      </div>
      <BuilderTabsPanel tabs={tabs} />
    </div>
  )
}

BuilderTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default React.memo(withRouter(BuilderTabs))

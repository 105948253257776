import React from 'react'
import PropTypes from 'prop-types'
import { useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import SMSPreview from 'views/mobile/sms-marketing/shared/utils/SMSPreview'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    '& > :first-child': {
      width: '100%',
    },
  },
})

function SmsPreviewSection({ name }) {
  const css = useStyles()
  const { values } = useFormState()
  const message = _.get(values, name)

  return (
    <div className={css.container}>
      <SMSPreview noMargin formValues={message} isValid previewOnly={false} />
    </div>
  )
}

SmsPreviewSection.propTypes = {
  name: PropTypes.string.isRequired,
}

export default React.memo(SmsPreviewSection)

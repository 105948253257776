import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Button from 'components/virtual/buttons/Button'
import Text from 'components/texts/Text'

const useStyles = makeStyles({
  button: {
    marginTop: props => (props.alignRight ? 0 : 16),
    '& svg': {
      marginLeft: 8,
    },
  },
  content: {
    alignItems: props => (props.alignRight ? 'center' : 'flex-start'),
    display: 'flex',
    flexDirection: props => (props.alignRight ? 'row' : 'column'),
    justifyContent: 'space-between',
    marginTop: -2,
    width: '100%',
  },
  text: {
    display: props => (props.centerTitle ? 'flex' : 'block'),
    alignItems: props => props.centerTitle && 'center',
    '& > :nth-child(2)': {
      marginTop: 4,
    },
  },
})

function BannerContent(props) {
  const {
    buttonLabel,
    buttonOnClick,
    buttonTo,
    buttonRight,
    buttonRightIcon,
    text,
    title,
    buttonType,
  } = props

  const alignRight = !buttonLabel || buttonRight
  const classes = useStyles({ alignRight, centerTitle: !(title && text) })

  const renderButton = () => {
    if (!buttonLabel) return null

    if (buttonTo) {
      return (
        <Link to={buttonTo}>
          <Button
            className={classes.button}
            color="default"
            label={buttonLabel}
            onClick={buttonOnClick}
            size="small"
            type={buttonType}
          />
        </Link>
      )
    }

    return (
      <Button
        className={classes.button}
        color="default"
        label={buttonLabel}
        onClick={buttonOnClick}
        size="small"
        type={buttonType}
      >
        {buttonRightIcon}
      </Button>
    )
  }

  return (
    <div className={classes.content}>
      <div className={classes.text}>
        {title && (
          <Text charcoalDarker font="medium" size={16}>
            {title}
          </Text>
        )}
        {text && (
          <Text charcoalLight size={14}>
            {text}
          </Text>
        )}
      </div>
      {renderButton()}
    </div>
  )
}

BannerContent.propTypes = {
  buttonLabel: PropTypes.string,
  buttonOnClick: PropTypes.func,
  buttonTo: PropTypes.string,
  buttonRight: PropTypes.bool,
  buttonRightIcon: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  buttonType: PropTypes.string,
}

BannerContent.defaultProps = {
  buttonLabel: '',
  buttonOnClick: () => {},
  buttonTo: null,
  buttonRight: false,
  buttonRightIcon: '',
  text: '',
  title: '',
  buttonType: 'contained',
}

export default React.memo(BannerContent)

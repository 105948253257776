import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import MoreMenu from 'components/virtual/menus/MoreMenu'

const useStyles = makeStyles({
  integrationActions: {
    right: 20,
    position: 'absolute',
  },
})

// Must match ESPIntegrationsModal.js:58
const QUERY_PROP_NAME = 'configEsp'

function ESPIntegrationActions(props) {
  const { setESP: setESPAfterConfirm } = props
  const {
    espIntegration, values, actions, lightboxSettings,
  } = React.useContext(
    BuilderContainerContext
  )
  const history = useHistory()
  const { onSetESPValue } = actions
  const settings = lightboxSettings.getSettings()
  const { ESP } = values
  const { integratedESPs } = espIntegration

  const css = useStyles()

  if (!ESP) return null

  const integrations = Object.values(integratedESPs).filter(
    integration =>
      integration.lightboxEnabled
      && integration.integrationData.id !== settings.espSettings.brandIntegrationId
  )

  const moreActions = [
    ...integrations.map(integration => ({
      label: `Switch to ${integration.label}`,
      onClick() {
        if (settings.status === 'active') {
          setESPAfterConfirm(integration)
          return
        }

        onSetESPValue(integration)
      },
    })),
    {
      label: 'Disconnect ESP',
      onClick() {
        onSetESPValue(null)
      },
    },
    {
      label: 'ESP Settings',
      onClick() {
        history.push(`${history.location.pathname}?${QUERY_PROP_NAME}=true`)
      },
    },
  ]

  return (
    <div className={css.integrationActions}>
      <MoreMenu actions={moreActions} />
    </div>
  )
}

ESPIntegrationActions.propTypes = {
  setESP: PropTypes.func.isRequired,
}

export default React.memo(ESPIntegrationActions)

import React from 'react'

import TextIcon from '@material-ui/icons/TextFields'
import BuildingIcon from '@material-ui/icons/LocationCity'
import BirthdayIcon from '@material-ui/icons/Cake'
import LocationIcon from '@material-ui/icons/LocationOn'
import MapIcon from '@material-ui/icons/Map'
import DropdownIcon from '@material-ui/icons/UnfoldMore'
import EnvelopeIcon from '@material-ui/icons/Mail'
import PhoneIcon from '@material-ui/icons/Phone'

const STANDARD_FIELDS = [
  {
    label: 'Email',
    name: 'email',
  },
  {
    label: 'Phone',
    name: 'phone',
  },
  {
    label: 'First Name',
    name: 'firstName',
  },
  {
    label: 'Last Name',
    name: 'lastName',
  },
  {
    label: 'Birthday',
    name: 'birthday',
  },
  {
    label: 'City',
    name: 'city',
  },
  {
    label: 'State',
    name: 'state',
  },
  {
    label: 'Zip Code',
    name: 'zipCode',
  },
]

const CUSTOM_FIELDS = [
  {
    label: 'Text Field',
    name: 'customText',
  },
  {
    label: 'Dropdown',
    name: 'customDropdown',
  },
]

export default {
  fields: {
    STANDARD_FIELDS,
    CUSTOM_FIELDS,
  },
  getFieldIcon: icon =>
    ({
      customDropdown: <DropdownIcon />,
      zipCode: <LocationIcon />,
      birthday: <BirthdayIcon />,
      city: <BuildingIcon />,
      state: <MapIcon />,
      email: <EnvelopeIcon />,
      phone: <PhoneIcon />,
    }[icon] || <TextIcon />),
}

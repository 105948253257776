import React, { Suspense, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core'
import queryString from 'query-string'
import * as metrics from 'utils/metrics'

import lazyRetry from 'utils/retry'

import SwitchWithFallback, { RedirectToLegacyApp } from 'components/routing/SwitchWithFallback'
import { Spinner } from 'components/spinners'
import LightboxBuilder from 'views/mobile/lightbox-builder'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexFlow: 'column',
    backgroundColor: 'white',
  },
}))

const AboutUs = lazyRetry(() => import('./home/about-us/AboutUs'))
// const MeetTheTeam = lazyRetry(() => import('./home/about-us/MeetTheTeam'))
const Login = lazyRetry(() => import('./login/Login'))
const Register = lazyRetry(() => import('./login/Register'))
const Pricing = lazyRetry(() => import('./home/pricing/Pricing'))
const UpgradePlan = lazyRetry(() => import('./home/pricing/UpgradePlan'))
const AgreementLayout = lazyRetry(() => import('./campaigns/agreement'))
const MobileMessages = lazyRetry(() => import('./messages'))
const Explore = lazyRetry(() => import('./explore'))
const BrandSettings = lazyRetry(() => import('./profile'))
const Discover = lazyRetry(() => import('./discover'))
const Search = lazyRetry(() => import('./search'))
const Shopify = lazyRetry(() => import('./shopify/Shopify'))
const SmsMarketing = lazyRetry(() => import('./sms-marketing'))
const Sweepstakes = lazyRetry(() => import('./sweepstakes'))
const Favorites = lazyRetry(() => import('./favorites'))

// Styleguide Containers
const FormContainer = lazyRetry(() => import('./styleguide/FormContainer'))

function Routes(props) {
  const { location } = props

  const classes = useStyles()

  useEffect(() => {
    metrics.create('view', {
      url: window.location.href,
      meta: {
        app: 'future',
      },
    })
  }, [location])

  const currentBrand = useSelector(state => state.currentBrand)
  const unread = useSelector(state => state.messaging.conversations.unread)

  const unreadTitle = unread ? ` (${unread})` : ''
  const titlePrefix = currentBrand.accountname ? `${currentBrand.accountname}${unreadTitle} - ` : ''

  const parsed = queryString.parse(location.search)
  const desktopVersionRequested = parsed.v === 'd'

  if (desktopVersionRequested) return RedirectToLegacyApp(props)

  return (
    <div className={classes.container}>
      <Helmet
        titleTemplate={`${titlePrefix}%s - DojoMojo`}
        defaultTitle={`${titlePrefix}DojoMojo`}
      />
      <Suspense fallback={<Spinner mobile />}>
        <SwitchWithFallback>
          {/* Temporarily to support URL redirect from email */}
          <Route path="/about-us" component={AboutUs} />
          {/* TODO: Route disabled as of 11/30/2021 */}
          {/* TODO: Remove history.replace in MeetTheTeam component after enabling back */}
          {/* <Route path="/meet-the-team" component={MeetTheTeam} /> */}
          <Route path="/login" render={() => <Login page="login" />} />
          <Route path="/forgot-password" render={() => <Login page="forgotPassword" />} />
          <Route path="/update-password" render={() => <Login page="updatePassword" />} />
          <Route path="/register" component={Register} />
          <Route path="/add-brand" render={() => <Register page="addBrand" />} />
          <Route path="/pricing" component={Pricing} />
          <Route path="/upgrade-plan" component={UpgradePlan} />
          <Route
            path="/builder/:campaignId/partnership-agreement/sign"
            component={AgreementLayout}
          />
          <Route path="/partnership-agreement" component={AgreementLayout} />
          <Route path="/explore" component={Explore} />
          <Route path="/messaging" component={MobileMessages} />
          <Route path="/profile" component={BrandSettings} />
          <Route exact path="/styleguide/form" component={FormContainer} />
          <Route exact path="/" component={Discover} />
          <Route path="/signup-form" component={LightboxBuilder} />
          <Route exact path="/discover/:type" component={Discover} />
          <Route path="/search" component={Search} />
          <Route exact path="/favorites" component={Favorites} />
          <Route path="/sms-marketing/:section?" component={SmsMarketing} />
          <Route path="/shopify/:section?" component={Shopify} />
          <Route path="/sweepstakes/:section?" component={Sweepstakes} />
          <Route
            path="/lightbox"
            render={({ location: { pathname } }) => (
              <Redirect to={pathname.replace('/lightbox', '/signup-form')} />
            )}
          />
        </SwitchWithFallback>
      </Suspense>
    </div>
  )
}

Routes.propTypes = {
  location: PropTypes.object.isRequired,
}

export default React.memo(withRouter(Routes))

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import CompactBanner from 'views/mobile/lightbox-builder/banners/CompactBanner'

const useStyles = makeStyles(() => ({
  marginWrapper: {
    margin: '0 40px',
    animation: '$enter .12s linear',
  },
  '@keyframes enter': {
    '0%': { opacity: '0', transform: 'scale(0.75)' },
    '100%': { opacity: '1', transform: 'scale(1)' },
  },
}))

const MobileDesktopWarningBanner = () => {
  const css = useStyles()

  return (
    <div className={css.marginWrapper}>
      <CompactBanner
        type="error"
        text="Any changes you make to the mobile design will break the link to your desktop design."
      />
    </div>
  )
}

export default MobileDesktopWarningBanner

import axios from 'axios'
import * as metrics from 'utils/metrics'

/* ==== SESSION_OPTIONAL_PATH =====
 * In special cases (Shopify OAuth flow) we do not want to redirect when there are errors in axios
 * The below routes gracefully handle axios errors
 */
const SESSION_OPTIONAL_PATH = ['/app/shopify/oauth']

function getSessionBrandId(userId) {
  try {
    return sessionStorage.getItem(userId)
  } catch (e) {
    console.log('sessionStorage unavailable')
    return null
  }
}

function setSessionBrandId(userId, brandId) {
  try {
    sessionStorage.setItem(userId, brandId)
  } catch (e) {
    console.log('sessionStorage unavailable')
  }
}

export function setImpersonateHeader(impersonating) {
  axios.defaults.headers.common.impersonating = impersonating
}

export function setBrandHeader(userId, overrideBrandId, fallbackBrandId) {
  const brandId = overrideBrandId // if set explicitly in query stringify
    || getSessionBrandId(userId) // otherwise current session's set brand ID
    || fallbackBrandId // otherwise fallback brand ID

  axios.defaults.headers.common.brand = brandId
  setSessionBrandId(userId, brandId)
}

export default () => {
  // set default headers
  axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

  //Before response
  axios.interceptors.request.use(config => {
    config.headers['X-Metrics-Session'] = metrics.getSessionId()
    return config
  })

  //After response
  axios.interceptors.response.use(
    response =>
      //successful response
      response,
    err => {
      //error happened
      if (
        err
        && err.response
        && !SESSION_OPTIONAL_PATH.includes(window.location.pathname)
        && (err.response.status === 401 || err.response.status === 403)
      ) {
        window.location.replace('/app/login')
      } else {
        // store.dispatch({ ...rest, type: ERROR, payload: err })
        throw err
      }
    }
  )
}

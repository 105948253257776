import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { useSelector } from 'react-redux'

import {
  EditorState,
  RichUtils,
  convertFromHTML,
  ContentState,
  convertToRaw,
  SelectionState,
} from 'draft-js'
import draftToHtml from 'draftjs-to-html'

export const MessageEditorContext = React.createContext()

export function MessageEditorProvider({ children }) {
  const [open, setOpen] = useState(false)
  const [hide, setHide] = useState(false)
  const [editorConfig, setEditorConfig] = useState({})
  const timeoutRef = useRef(null)
  const {
    conversations: { meta: conversationMeta },
  } = useSelector(state => state.messaging)

  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const editorRef = React.useRef(null)

  const values = {
    open,
    hide,
    editorConfig,
    editorState,
    editorRef,
    conversationMeta,
  }

  function onHide() {
    setHide(true)
  }
  function onToggleHide() {
    setHide(oldHide => !oldHide)
  }
  function moveEditorSelectionToEnd(currentEditorState) {
    // https://github.com/brijeshb42/medium-draft/issues/71
    const content = currentEditorState.getCurrentContent()
    const blockMap = content.getBlockMap()

    const key = blockMap.last().getKey()
    const length = blockMap.last().getLength()

    // On Chrome and Safari, calling focus on contenteditable focuses the
    // cursor at the first character. This is something you don't expect when
    // you're clicking on an input element but not directly on a character.
    // Put the cursor back where it was before the blur.
    const selection = new SelectionState({
      anchorKey: key,
      anchorOffset: length,
      focusKey: key,
      focusOffset: length,
    })

    return EditorState.forceSelection(currentEditorState, selection)
  }
  function onEditorFocus() {
    editorRef.current.focus()
  }
  function openEditor() {
    clearTimeout(timeoutRef.current)
    setHide(false)
    setOpen(true)
    timeoutRef.current = setTimeout(onEditorFocus, 100)
  }
  function onMessageEditorClose() {
    const afterClose = () => {
      setEditorConfig({}) // clears data
      setEditorState(EditorState.createEmpty()) // clears editor
    }
    setOpen(false)
    timeoutRef.current = setTimeout(afterClose, 1000) // clear data out
  }
  function onSendMessage() {
    const editorContent = convertToRaw(editorState.getCurrentContent())
    const htmlString = draftToHtml(editorContent)

    if (editorConfig.onSubmitAction && typeof editorConfig.onSubmitAction === 'function') {
      editorConfig.onSubmitAction({ message: htmlString })
    }
    onMessageEditorClose()
  }
  function setMessageEditorMeta(opts) {
    if (!opts.onSubmitAction) return

    // if out of conversations, dont show this up
    if (conversationMeta.outOfMessages) return
    /*
      example of opts:
        opts.onSubmitAction = () => {} // it triggers on submit action
        opts.title = `New Message for ${brand.accountname}`
        opts.sendButtonLabel = 'Send Request'
        opts.message = `
          <b>Bold text</b>, <i>Italic text</i><br/ ><br /><strong>Example link</strong>
        `
    */

    let newEditorState = EditorState.createEmpty()

    if (opts.message) {
      const rawMessage = opts.message

      const blocks = convertFromHTML(rawMessage || '')

      const editorFriendlyContent = ContentState.createFromBlockArray(
        blocks.contentBlocks,
        blocks.entityMap
      )
      newEditorState = EditorState.createWithContent(editorFriendlyContent)
    }

    newEditorState = moveEditorSelectionToEnd(newEditorState)

    setEditorConfig(_.pick(opts, ['title', 'onSubmitAction', 'sendButtonLabel']))
    setEditorState(newEditorState)
    openEditor()
  }

  /* Draft-JS methods */
  function onEditorStateChange(newEditorState) {
    setEditorState(newEditorState)
  }
  function onEditorStateKeyCommand(command, currentEditorState) {
    const newEditorState = RichUtils.handleKeyCommand(currentEditorState, command)
    if (newEditorState) {
      setEditorState(newEditorState)
      return 'handled'
    }

    return 'not-handled'
  }
  function onTab() {
    /* TODO: might want to handle tab functionality here? */
  }
  function onBoldClick() {
    onEditorStateKeyCommand('bold', editorState)
  }
  function onItalicClick() {
    onEditorStateKeyCommand('italic', editorState)
  }
  function onToggleBulletPointClick() {
    const newEditorState = RichUtils.toggleBlockType(editorState, 'unordered-list-item')
    setEditorState(newEditorState)
  }
  /* end Draft-JS methods */

  const actions = {
    onClose: onMessageEditorClose,
    setMessageEditorMeta,
    onHide,
    onToggleHide,
    openEditor,
    onEditorStateChange,
    onEditorStateKeyCommand,
    onTab,
    onBoldClick,
    onItalicClick,
    onToggleBulletPointClick,
    onEditorFocus,
    onSendMessage,
  }

  return (
    <MessageEditorContext.Provider value={{ values, actions }}>
      {children}
    </MessageEditorContext.Provider>
  )
}

MessageEditorProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
}

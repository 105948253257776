import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

import { PageTitle } from 'components/builder/titles'
import Text from 'components/texts/Text'
import Separator from 'components/shared/Separator'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

import LightboxBuilderBehaviorBox from './LightboxBuilderBehaviorBox'
import LightboxBuilderUrlPaths from './LightboxBuilderUrlPaths'
import NoDomainDetectedBanner from '../../../banners/NoDomainDetectedBanner'

const useStyles = makeStyles({
  behavior: {
    padding: 20,
  },
  content: {
    maxWidth: 274,
    '& > p:not(:last-child)': {
      marginBottom: 4,
    },
  },
  topMargin: {
    marginTop: 20,
  },
})

function LightboxBuilderBehavior() {
  const {
    lightboxSettings,
    builder: { errors },
  } = React.useContext(BuilderContainerContext)
  const settings = lightboxSettings.getSettings()
  const css = useStyles()

  const triggerLanguage = {
    timing: {
      immediately: 'Show immediately on page load',
      delay: `After landing on the page for ${settings.trigger.delay} seconds `,
      exit: 'Delay until visitor is about to leave page',
    },
    frequency: {
      never: 'Never show again',
      'every-session': 'Every session',
      delay: `Show again ${settings.trigger.displayFrequencyDelay} day(s) after closing `,
    },
  }

  const autoCloseDelayLanguage = delay => {
    if (delay === null) return 'Never'
    const timing = `${delay} ${delay !== 1 ? 'seconds' : 'second'}`
    return `After landing on the Success Page for ${timing}`
  }

  const isDomainError = errors.verify('domain')

  const trigger = (
    <>
      <div className={css.content}>
        <Text charcoalBase>Timing</Text>
        <Text>{triggerLanguage.timing[settings.trigger.type]}</Text>
      </div>
      <div className={cn(css.content, css.topMargin)}>
        <Text charcoalBase>Frequency</Text>
        <Text>{triggerLanguage.frequency[settings.trigger.displayFrequency]}</Text>
      </div>
    </>
  )

  const targeting = (
    <div className={css.content}>
      <Text charcoalBase>Domain</Text>
      <Text redBase={isDomainError}>
        {isDomainError ? (
          'No domain location'
        ) : (
          <a href={settings.domain} target="_blank" rel="noopener noreferrer">
            {settings.domain?.toLowerCase()}
          </a>
        )}
      </Text>
      <br />
      <LightboxBuilderUrlPaths error={isDomainError} />
    </div>
  )

  const autoCloseDelay = (
    <div className={css.content}>
      <Text charcoalBase>Automatically Close the Form</Text>
      <Text>{autoCloseDelayLanguage(settings.autoCloseDelay)}</Text>
    </div>
  )

  const displayFrequencyLanguage = {
    'every-session': 'Every session',
    delay: `After ${settings.trigger.displayFrequencyDelay} days`,
    never: 'Never again',
  }

  const displayFrequency = (
    <div className={css.content}>
      <Text charcoalBase>Upon Completion, Show the Form Again</Text>
      <Text>{displayFrequencyLanguage[settings.trigger.displayFrequency]}</Text>
    </div>
  )

  const isPopUpForm = ['lightbox', 'popup'].includes(settings.type)
  const isEmbedForm = settings.type === 'embed'

  return (
    <div className={css.behavior}>
      <PageTitle text="Form Behavior" />
      {isPopUpForm && (
        <>
          {isDomainError ? <NoDomainDetectedBanner /> : <br />}
          <LightboxBuilderBehaviorBox title="Display Settings" content={trigger} type="trigger" />
          <Separator top={36} bottom={36} />
          <LightboxBuilderBehaviorBox
            title="Close Settings"
            content={autoCloseDelay}
            type="autoCloseDelay"
          />
          <Separator top={36} bottom={36} />
          <LightboxBuilderBehaviorBox
            title="URL Targeting"
            subtitle="Choose where your form will appear."
            content={targeting}
            type="urlTargeting"
          />
        </>
      )}

      {isEmbedForm && (
        <>
          <Separator top={24} bottom={0} color="white" />
          <LightboxBuilderBehaviorBox
            title="Completion Settings"
            content={displayFrequency}
            type="delayFrequency"
            disabled
          />
        </>
      )}
    </div>
  )
}

export default React.memo(LightboxBuilderBehavior)

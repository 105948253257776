import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import { H4, P } from 'components/texts'
import { FormInput } from 'components/forms'

const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: theme.colors.crystal,
    outline: `1px solid ${theme.colors.silver}`,
    padding: '28px 32px 16px',
  },
  select: {
    maxWidth: 500,
  },
}))

function FollowupCriteria(props) {
  const { settingsName } = props
  const css = useStyles()
  const { values } = useFormState()
  const { FOLLOWUP_CRITERIA_OPTIONS, followup_criteria } = values

  const settings = _.get(values, settingsName)
  const disable = !['draft', 'scheduled'].includes(settings.status) && !settings.followupCriteriaEnabled

  const Separator = React.memo(p => <div style={{ marginTop: p.margin || 16 }} />)

  const { current: selectRenderer } = useRef(itemProps => {
    const {
      label, link, linkLabel, disabled,
    } = itemProps

    return (
      <div className={css.item}>
        <P multiline darkGrey={disabled}>
          <strong>{label}</strong>
          {link && (
            <a
              href={link}
              target="_blank"
              rel="noreferrer noopener"
              style={{ pointerEvents: 'auto' }}
            >
              {' '}
              {linkLabel}
            </a>
          )}
        </P>
      </div>
    )
  })

  return (
    <div className={css.wrapper}>
      <H4>
        <strong>Follow-Up Criteria</strong>
      </H4>
      <Separator />
      <div>
        <FormInput
          disabled={disable}
          type="select"
          values={FOLLOWUP_CRITERIA_OPTIONS}
          defaultValue={followup_criteria}
          name={`${settingsName}.followup_criteria`}
          label="Send if subscriber"
          itemRenderer={selectRenderer}
          className={css.select}
        />
      </div>
    </div>
  )
}

FollowupCriteria.propTypes = {
  settingsName: PropTypes.string,
}

FollowupCriteria.defaultProps = {
  settingsName: 'settings',
}

export default React.memo(FollowupCriteria)

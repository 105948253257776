import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { PageTitle } from 'components/builder/titles'
import Button from 'components/virtual/buttons/Button'
import VirtualTheme from 'components/virtual/VirtualTheme'
import Text from 'components/texts/Text'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

const useStyles = makeStyles(theme => ({
  behaviorBox: {
    '& > :first-child': {
      marginBottom: 4,
    },
  },
  box: {
    marginTop: 12,
    padding: 16,
    borderRadius: 4,
    border: `1px solid ${theme.colors.skyBase}`,
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    '& > button': {
      padding: '0 12px',
    },
  },
  content: {
    width: 'calc(100% - 52px)', // 52px -> button width
  },
}))

function LightboxBuilderBehaviorBox(props) {
  const css = useStyles()
  const { builder } = React.useContext(BuilderContainerContext)
  const { drawer } = builder
  const {
    title, subtitle, content, type, disabled,
  } = props

  const editTitle = {
    trigger: 'Display Settings',
    urlTargeting: 'URL Targeting',
    autoCloseDelay: 'Close Settings',
  }[type]

  const onClick = () => drawer.open({ type }, { title: editTitle })

  return (
    <div className={css.behaviorBox}>
      <PageTitle textProps={{ size: 16 }} text={title} />
      {subtitle && <Text charcoalLight>{subtitle}</Text>}
      <div className={css.box}>
        <div className={css.content}>{content}</div>
        <VirtualTheme>
          <Button
            size="small"
            onClick={onClick}
            color="secondary"
            type="outlined"
            label="Edit"
            disabled={disabled}
          />
        </VirtualTheme>
      </div>
    </div>
  )
}

LightboxBuilderBehaviorBox.propTypes = {
  type: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

LightboxBuilderBehaviorBox.defaultProps = {
  disabled: false,
}

export default React.memo(LightboxBuilderBehaviorBox)

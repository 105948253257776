import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import { ReactComponent as CloseImage } from 'images/icons/close.svg'

import { H3, H4 } from 'components/texts'

const useStyles = makeStyles(theme => ({
  topbar: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
  },
  xButton: {
    alignSelf: 'flex-end',
    cursor: 'pointer',
  },
  title: {
    marginBottom: 24,
  },
  tabs: {
    borderBottom: `1px solid ${theme.colors.silver}`,
    width: 'calc(100% + 40px)',
    display: 'flex',
    justifyContent: 'center',
  },
  tab: {
    cursor: 'pointer',
    paddingBottom: 12,
    position: 'relative',
    '&:not(:last-child)': {
      marginRight: 56,
    },
  },
  selected: {
    position: 'absolute',
    width: '100%',
    height: 4,
    backgroundColor: theme.colors.neonMint,
    bottom: -2,
  },
}))

function MediaTabs(props) {
  const {
    tabs, selectedTab, onChange, onClose,
  } = props
  const css = useStyles()

  return (
    <div className={css.topbar}>
      <CloseImage className={css.xButton} onClick={onClose} />
      <div className={css.title}>
        <H3>Insert Media</H3>
      </div>
      <div className={css.tabs}>
        {tabs.map((tab, idx) => (
          <div key={tab} className={css.tab} onClick={() => onChange(idx)}>
            <H4>{selectedTab === idx ? <i>{tab}</i> : tab}</H4>
            {selectedTab === idx && <div className={css.selected} />}
          </div>
        ))}
      </div>
    </div>
  )
}

MediaTabs.propTypes = {
  onChange: PropTypes.func.isRequired,
  tabs: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedTab: PropTypes.number.isRequired,
}

export default React.memo(MediaTabs)

export default campaigns => {
  const pastCampaigns = campaigns.filter(campaign => new Date(campaign.endDate) < new Date())
  const currentCampaigns = campaigns.filter(
    campaign =>
      new Date(campaign.startDate) < new Date()
      && new Date(campaign.endDate) > new Date()
      && campaign.status === 'accepted'
  )
  const upcomingCampaigns = campaigns.filter(
    campaign => new Date(campaign.startDate) > new Date() && campaign.status === 'accepted'
  )

  const filteredCampaigns = {
    pastCampaigns,
    currentCampaigns,
    upcomingCampaigns,
  }

  return filteredCampaigns
}

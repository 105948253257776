import React from 'react'
import PropTypes from 'prop-types'

import { MuiThemeProvider, CssBaseline } from '@material-ui/core'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import withStyles from '@material-ui/core/styles/withStyles'
import virtualStyles from './virtualStyles'

export const colors = {
  // navy
  navyLightest: '#EDF9FF',
  navyLighter: '#94D1F5',
  navyLight: '#0B80D2',
  navyBase: '#004F87',
  navyDarker: '#003664',
  navyDarkest: '#003664',

  // blue
  blueLightest: '#F2FBFE',
  blueLighter: '#9CE1FF',
  blueLight: '#03A9F4',
  blueBase: '#008DE6',
  blueDarker: '#005C9C',
  blueDarkest: '#003F66',

  // teal
  tealLightest: '#F0F9F7',
  tealLighter: '#A2F5E1',
  tealLight: '#14CBA8',
  tealBase: '#01B39D',
  tealDarker: '#026B58',
  tealDarkest: '#05362D',

  // orange
  orangeLightest: '#FFF0D9',
  orangeLighter: '#FFC093',
  orangeLight: '#FF7043',
  orangeBase: '#F4511E',
  orangeDarker: '#CC2900',
  orangeDarkest: '#A40100',

  // yellow
  yellowLightest: '#FFFFE6',
  yellowLighter: '#FFF1A6',
  yellowLight: '#FFD346',
  yellowBase: '#F4AB1E',
  yellowDarker: '#CC8300',
  yellowDarkest: '#995000',

  // green
  greenLightest: '#EFF9F2',
  greenLighter: '#97F1A5',
  greenLight: '#5FC86F',
  greenBase: '#37A047',
  greenDarker: '#198229',
  greenDarkest: '#005000',

  // red
  redLightest: '#FAF0F0',
  redLighter: '#FF9D9D',
  redLight: '#F14D4D',
  redBase: '#D32F2F',
  redDarker: '#B51111',
  redDarkest: '#790000',

  // indigo
  indigoLightest: '#DFECF5',
  indigoLighter: '#99B3F5',
  indigoLight: '#5D6FD3',
  indigoBase: '#3F51B5',
  indigoDarker: '#213397',
  indigoDarkest: '#000B6F',

  // purple
  purpleLightest: '#F5EEF5',
  purpleLighter: '#EEA0FB',
  purpleLight: '#C44FD8',
  purpleBase: '#9C27B0',
  purpleDarker: '#6A007E',
  purpleDarkest: '#4C0060',

  // sky
  skyLighter: '#FBFBFB',
  skyLight: '#F4F7F8',
  skyBase: '#DFE9ED',
  skyDarker: '#BFD2DB',

  // charcoal
  charcoalLighter: '#86A0AC',
  charcoalLight: '#5A7481',
  charcoalBase: '#3F5059',
  charcoalDarker: '#172931',

  white: '#FFF',
  black: '#000',
  proxima: {
    black: '#121212',
    aqua: '#1CE8AB',
    blue: '#41D2CF',
  },
}

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.blueBase,
    },
    secondary: {
      main: colors.navyBase,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1920,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        height: 48,
        minWidth: 48,
        padding: '0px 24px',
        textTransform: 'none',
        whiteSpace: 'nowrap',
      },
      label: {
        fontFamily: 'Larsseit',
        fontSize: '18.5px',
        fontWeight: 500,
        lineHeight: '28px',
      },
      sizeSmall: {
        height: 36,
        padding: '0px 24px',
        '& > *': {
          fontSize: '14px',
          lineHeight: '22px',
        },
      },
      contained: {
        backgroundColor: 'transparent',
        border: `1px solid ${colors.charcoalBase}`,
        '&:disabled': {
          background: colors.skyLight,
          border: `1px solid ${colors.skyLight}`,
          color: colors.skyDarker,
        },
      },
      containedPrimary: {
        border: 'none',
        '&:hover': {
          backgroundColor: colors.navyLight,
        },
      },
      containedSecondary: {
        border: 'none',
        '&:hover': {
          backgroundColor: colors.navyDarker,
        },
      },
      outlined: {
        border: `1px solid ${colors.skyDarker}`,
        opacity: 1,
        '&:disabled': {
          color: colors.skyDarker,
          border: `1px solid ${colors.skyBase}`,
        },
      },
      outlinedPrimary: {
        border: `1px solid ${colors.skyDarker}`,
        '&:disabled': {
          border: `1px solid ${colors.skyBase}`,
        },
        '&:hover': {
          backgroundColor: colors.skyLighter,
          border: `1px solid ${colors.skyBase}`,
          color: colors.navyLight,
        },
      },
      outlinedSecondary: {
        border: `1px solid ${colors.skyDarker}`,
        '&:disabled': {
          border: `1px solid ${colors.skyBase}`,
        },
        '&:hover': {
          backgroundColor: colors.skyLighter,
          border: `1px solid ${colors.skyBase}`,
          color: colors.navyDarker,
        },
      },
      text: {
        '&:disabled': {
          color: colors.skyDarker,
        },
      },
      textPrimary: {
        '&:hover': {
          backgroundColor: colors.skyLighter,
          color: colors.navyLight,
        },
      },
      textSecondary: {
        '&:hover': {
          backgroundColor: colors.skyLighter,
          color: colors.navyDarker,
        },
      },
    },
    MuiCard: {
      root: {
        border: `1px solid ${colors.skyBase}`,
        boxSizing: 'border-box',
        boxShadow: '0px 2px 8px rgba(90, 116, 129, 0.1)',
      },
    },
    MuiPaper: {
      elevation8: {
        boxShadow: '0px 2px 8px rgba(90, 116, 129, 0.1)',
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 4,
      },
    },
    typography: {
      useNextVariants: true,
      fontFamily: [
        'Larsseit',
        'Larsseit-Light',
        'Larsseit-Medium',
        'Larsseit-Bold',
        'sans-serif',
        'serif',
        'verdana',
      ].join(','),
    },
  },
  colors,
})

const StylesWrapper = withStyles(virtualStyles)(({ children }) => children)

function VirtualTheme({ children }) {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <StylesWrapper>{children}</StylesWrapper>
    </MuiThemeProvider>
  )
}

VirtualTheme.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.array]).isRequired,
}

export default VirtualTheme

import React from 'react'
import PropTypes from 'prop-types'

import { Switch as MuiSwitch, makeStyles } from '@material-ui/core'
import Text from 'components/texts/Text'
import ToolTip from 'components/shared/ToolTip'

const useStyles = makeStyles(theme => ({
  switch: {
    '& [class*=MuiIconButton-label]': {
      color: 'inherit',
    },
    '& [class*=MuiSwitch-switchBase]': {
      padding: '4px 4px 4px 0px',
    },
  },
  checked: {
    transform: 'translateX(20px) !important',
  },
  root: {
    overflow: 'visible',
    '& [class*=MuiIconButton-root]': {
      backgroundColor: 'transparent',
      height: 'auto',
      margin: 0,
      width: 'auto',
    },
  },
  sizeSmall: {
    padding: '7px 7px 7px 0px',
  },
  track: {
    backgroundColor: theme.colors.darkGrey,
  },
  label: {
    display: 'inline-flex',
    '& > p': { marginLeft: 16, marginTop: 2 },
    '& > p + *': { marginLeft: 8, marginTop: 4 },
  },
}))

function Switch(props) {
  const {
    disabled, checked, onChange, label, tooltip,
  } = props
  const classes = useStyles()

  return (
    <div className={classes.switch}>
      <MuiSwitch
        checked={checked}
        classes={{
          checked: classes.checked,
          root: classes.root,
          sizeSmall: classes.sizeSmall,
          track: classes.track,
        }}
        color="primary"
        disabled={disabled}
        onChange={() => onChange(!checked)}
        size="small"
      />
      {(label || tooltip) && (
        <div className={classes.label}>
          {label && (
            <Text size={14} font="medium">
              {label}
            </Text>
          )}
          {tooltip && (
            <ToolTip
              padding
              isVirtualTheme
              {...(typeof tooltip === 'string' ? { text: tooltip } : tooltip)}
            />
          )}
        </div>
      )}
    </div>
  )
}

Switch.propTypes = {
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  label: PropTypes.string,
  tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
}

Switch.defaultProps = {
  disabled: false,
  onChange() {},
  label: '',
  tooltip: '',
}

export default React.memo(Switch)

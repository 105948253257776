import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import cn from 'classnames'

import industryTags from 'utils/industryTags'
import styleHelper from 'utils/styleHelper'
import { updateBrand } from 'utils/brands'

import Button from 'components/virtual/buttons/Button'
import Categories from 'components/virtual/selectors/Categories'
import Search from 'components/virtual/inputs/SearchBar'
import Text from 'components/texts/Text'

const useStyles = makeStyles(theme => ({
  actions: {
    alignItems: 'center',
    backgroundColor: theme.colors.skyLight,
    display: 'flex',
    height: 84,
    justifyContent: 'center',
    padding: 16,
    pointerEvents: 'auto',
    width: '100%',
  },
  actionsAllowed: {
    backgroundColor: 'white',
    borderTop: `1px solid ${theme.colors.skyBase}`,
    justifyContent: 'flex-end',
  },
  blurFooter: {
    backgroundImage: 'linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,.8))',
    height: 24,
    pointerEvents: 'none',
  },
  blurHeader: {
    backgroundImage: 'linear-gradient(to top, rgba(255,255,255,0), rgba(255,255,255,.8))',
    height: 12,
    pointerEvents: 'none',
  },
  categories: {
    height: 568,
    overflowY: 'scroll',
    padding: '96px 16px 84px',
    [styleHelper.max(1023)]: {
      paddingBottom: 24,
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
  },
  dynamicSizing: {
    [styleHelper.max(1023)]: {
      width: '66%',
      margin: '0 auto',
    },
    [styleHelper.mobile]: {
      width: '100%',
    },
  },
  footer: {
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    pointerEvents: 'none',
    position: 'absolute',
    width: '100%',
    [styleHelper.max(1023)]: {
      position: 'fixed',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    pointerEvents: 'none',
    position: 'absolute',
    top: -1,
    width: '100%',
  },
  searchbar: {
    alignItems: 'center',
    backgroundColor: 'white',
    display: 'flex',
    height: 84,
    padding: '0px 16px',
    pointerEvents: 'auto',
    width: '100%',
  },
}))

function ChooseIndustriesPage({
  onNext, setValues, track, values,
}) {
  const classes = useStyles()

  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [selected, setSelected] = useState(values.selected || [])

  const categoriesLeft = 3 - selected.length
  const searchTags = industryTags.filter(tag => tag.toLowerCase().includes(search.toLowerCase()))

  const onSubmit = async () => {
    setLoading(true)
    track('submittedBrandProfileInterests')()
    try {
      await updateBrand({
        tagsconnected: selected,
      })
      setValues({ ...values, selected })
      setLoading(false)
      onNext()
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  useEffect(() => {
    track('loadedBrandProfileInterests')()
  }, [track])

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.dynamicSizing}>
          <div className={classes.searchbar}>
            <Search
              fullWidth
              onChange={({ target }) => setSearch(target.value)}
              onClear={() => setSearch('')}
              placeholder="Search Categories"
              value={search}
            />
          </div>
        </div>
        <div className={classes.blurHeader} />
      </div>
      <div className={cn(classes.categories, classes.dynamicSizing)}>
        <Categories max={3} options={searchTags} selected={selected} setSelected={setSelected} />
      </div>
      <div className={classes.footer}>
        <div className={classes.blurFooter} />
        <div className={cn(classes.actions, { [classes.actionsAllowed]: categoriesLeft <= 0 })}>
          {categoriesLeft <= 0 ? (
            <Button disabled={loading} label="Next Step" onClick={onSubmit} size="small" />
          ) : (
            <Text size={16} charcoalLight>
              {`Choose ${categoriesLeft} ${categoriesLeft >= 3 ? 'tags' : 'more'}`}
            </Text>
          )}
        </div>
      </div>
    </div>
  )
}

ChooseIndustriesPage.propTypes = {
  onNext: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  track: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
}

export default React.memo(ChooseIndustriesPage)

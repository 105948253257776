import React, { useEffect } from 'react'
import {
  Switch, Route, Redirect, withRouter,
} from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { BuilderContainerContext } from '../BuilderContainerContext'

const useStyles = makeStyles({
  tabSection: {
    overflowY: 'scroll',
    flex: 1,
    paddingBottom: 32,
  },
})

const BuilderTabsPanel = function BuilderTabsPanel(props) {
  const { tabs, match, location } = props
  const css = useStyles()
  const { url } = match
  const { pathname } = location
  const {
    builder: { drawer },
  } = React.useContext(BuilderContainerContext)

  const renderRoutes = tab => {
    const { component, path } = tab

    return <Route key={path} path={`${url}${path}`} component={component} />
  }

  useEffect(() => {
    const subpath = `/${pathname.split('/').pop()}`
    const tab = tabs.find(({ path }) => path === subpath)

    if (tab) drawer.setDrawerComponent(tab.drawerComponent)
  }, [tabs, pathname, drawer])

  if (!tabs || !tabs.length) return null

  return (
    <section className={css.tabSection}>
      <Switch>
        {tabs.map(renderRoutes)}
        <Redirect to={`${url}${tabs[0].path}`} />
      </Switch>
    </section>
  )
}

BuilderTabsPanel.propTypes = {
  tabs: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

export default React.memo(withRouter(BuilderTabsPanel))

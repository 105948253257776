import React, { useState } from 'react'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

import { ToasterContext } from 'contexts'
import ImageDropPreview from 'components/virtual/utility/ImageDropPreview'

import ImageErrorBanner from '../../../banners/ImageErrorBanner'

function LightboxBuilderPageContentImageDrop() {
  const {
    builder: { drawer, errors },
    lightboxSettings,
  } = React.useContext(BuilderContainerContext)
  const {
    data: { page, index },
    onClose,
  } = drawer
  const toaster = React.useContext(ToasterContext)
  const settings = lightboxSettings.getSettings()

  const [localError, setLocalError] = useState(false)

  const name = `pageMissingImage_${index}`
  const error = errors.verify(name)
    ? localError
      ? 'File size too large. Must be under 5MB.'
      : 'Image required to publish your lightbox'
    : null

  const onImageChange = ({ imageUrl = null, imageName = null }) => {
    const imageObj = { url: imageUrl, fileName: imageName }
    page.heroImage = { ...imageObj }

    // If pages don't have hero image, set first upload as the hero image for every page
    settings.pages.forEach((settingsPage, i) => {
      if (i !== index && !settingsPage.heroImage.url) settingsPage.heroImage = { ...imageObj }
    })
  }
  const onImageRemove = () => onImageChange({})

  // When closing the drawer
  onClose(() => setLocalError(false))

  // only error is image is too large
  const onError = () => {
    const errorMsg = 'File size too large. Must be under 5MB.'
    errors.set(name, errorMsg)
    toaster.showToaster(errorMsg, { timeout: 5000, type: 'warning' })
    setLocalError(true)
  }

  return (
    <ImageDropPreview
      onDelete={onImageRemove}
      onChange={onImageChange}
      onError={onError}
      error={error && <ImageErrorBanner text={error} />}
      imageUrl={page.heroImage.url}
      imageName={page.heroImage.fileName}
    />
  )
}

export default React.memo(LightboxBuilderPageContentImageDrop)

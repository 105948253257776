import React from 'react'
import PropTypes from 'prop-types'
import { Typography, withStyles } from '@material-ui/core'

const styles = theme => {
  const colors = Object.entries(theme.colors).reduce(
    (obj, [key, val]) => ({ ...obj, [key]: { color: val } }),
    {}
  )

  return {
    text: {
      fontSize: 30,
      lineHeight: '30px',
      fontFamily: 'Larsseit-Bold',
    },
    ellipsis: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    obscure: {
      filter: 'blur(3px)',
      userSelect: 'none',
    },
    ...colors,
  }
}

function H1({ children, classes, ...props }) {
  const classnames = Object.keys(props)
    .map(k => props[k] && classes[k])
    .join(' ')

  return (
    <Typography component="h1" className={`${classes.text} ${classnames}`}>
      {children}
    </Typography>
  )
}

H1.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  classes: PropTypes.object.isRequired,
}

export default React.memo(withStyles(styles)(H1))

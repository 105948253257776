import axios from 'axios'
import store from 'utils/reduxStore'

export { default as enhanceMessageObject } from './enhanceMessageObject'
export { default as filterConversations } from './filterConversations'

export const markConversationAsRead = conversationId => {
  const { messages } = store.getState().messaging.conversations

  Object.keys(messages).forEach(key => {
    if (messages[key].conversation_id === conversationId) {
      messages[key].last_read = new Date()
    }
  })

  store.dispatch({
    type: 'ADD_MESSAGE',
    payload: { messages: { ...messages } },
  })
}

export const markCampaignMessagesAsStatus = (status, campaignId, newMessage) => {
  const { messages } = store.getState().messaging.conversations

  Object.keys(messages).forEach(key => {
    if (messages[key].campaign_id === campaignId) messages[key].status = status
  })

  if (newMessage) messages[newMessage.id] = newMessage

  store.dispatch({
    type: 'ADD_MESSAGE',
    payload: { messages: { ...messages } },
  })
}

export const startConversationWithBrand = (brandId, content) =>
  axios.post(`/new-conversation/${brandId}`, { brandId, content })

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Text from 'components/texts/Text'

const useStyles = makeStyles({
  empty: {
    display: 'flex',
    alignItems: 'center',
  },
})

function FormPageEmptyState() {
  const css = useStyles()

  return (
    <div className={css.empty}>
      <Text charcoalLight size={12}>
        Drag and drop fields here
      </Text>
    </div>
  )
}

FormPageEmptyState.propTypes = {}

FormPageEmptyState.defaultProps = {}

export default FormPageEmptyState

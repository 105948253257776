import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import Chip from 'components/shared/Chip'

import NoBorderInput from './NoBorderInput'

const useStyles = makeStyles(theme => ({
  chipsContainer: {
    width: '100%',
    minHeight: 40,
    border: `1px solid ${theme.colors.silver}`,
    padding: '6px 4px',
    display: 'flex',
    flexWrap: 'wrap',
    '& [class*=MuiFormControl]': {
      margin: 0,
      height: 28,
      minWidth: 100,
      '& > [class*=MuiInputBase]': {
        padding: '0 8px',
        minHeight: 28,
      },
    },
  },
  chipContainer: {
    marginRight: 4,
    marginBottom: 4,
  },
}))

const keyCodes = [
  9, // tab
  13, // enter
  32, // space
]

const renderChips = (list, onDelete) =>
  list.map((label, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <Chip onDelete={() => onDelete(index)} label={label} key={`${label}_${index}`} />
  ))

function ChipInput({ list, onListChange }) {
  const classes = useStyles()
  const [text, setText] = useState('')
  const [chips, setChips] = useState(list)

  useEffect(() => setChips(list), [list])

  const addInList = ({ keyCode }) => {
    if (!keyCodes.includes(keyCode)) return

    const newChips = [...chips, text.trim()]
    setText('')
    setChips(newChips)
    onListChange(newChips)
  }

  const deleteInList = index => {
    const newChips = chips.filter((_, i) => i !== index)
    setChips(newChips)
    onListChange(newChips)
  }

  return (
    <div className={classes.chipsContainer}>
      {renderChips(chips, deleteInList)}
      <NoBorderInput value={text} onChange={setText} onKeyUp={addInList} />
    </div>
  )
}

ChipInput.propTypes = {
  list: PropTypes.array,
  onListChange: PropTypes.func,
}

ChipInput.defaultProps = {
  list: [],
  onListChange() {},
}

export default React.memo(ChipInput)

import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { ArrowForwardIos as ArrowIcon } from '@material-ui/icons'
import { useDrop } from 'react-dnd'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import MoreMenu from 'components/virtual/menus/MoreMenu'
import Text from 'components/texts/Text'
import GenericModalBase from 'components/virtual/modals/GenericModalBase'

import { ToasterContext } from 'contexts'
import { ReactComponent as ErrorIcon } from 'images/icons/error-icons/circle-outlined-error.svg'
import { checkPagesForField, checkPageForField } from '../../../../../utils/check-page-fields'
import FormPageInputs from './FormPageInputs'

const useStyles = makeStyles(theme => ({
  formPage: {
    cursor: 'pointer',
    border: ({ focused }) => `1px solid ${theme.colors[focused ? 'blueBase' : 'skyDarker']}`,
    borderRadius: 4,
    backgroundColor: theme.colors.skyLighter,
    transition: 'all .1s linear',
    '&:hover': {
      backgroundColor: theme.colors.skyBase,
      '& .draggable > div > div': {
        backgroundColor: theme.colors.skyLight,
      },
    },
    '&:hover > div:first-child': {
      backgroundColor: theme.colors.skyBase,
    },
  },
  formPageTitle: {
    position: 'relative',
    height: 44,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 12px',
    backgroundColor: theme.colors.skyLight,
    borderBottom: ({ focused }) =>
      (focused ? `3px solid ${theme.colors.blueBase}` : `1px solid ${theme.colors.skyDarker}`),
    textTransform: 'uppercase',
    transition: 'all .1s linear',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    '& .more-icon': {
      color: theme.colors.navyBase,
    },
  },
  formPageProgressMarker: {
    backgroundColor: theme.colors.charcoalLight,
    position: 'absolute',
    transform: 'translate(0, -50%)',
    left: -24,
    top: '50%',
    height: 12,
    width: 12,
    borderRadius: '50%',
  },
  fieldsHeader: {
    padding: '12px 12px 0 12px',
    textTransform: 'uppercase',
  },
  formPageInputs: {
    padding: 12,
    '& > :not(:first-child)': {
      marginTop: 12, // spacing between draggables
    },
  },
  formPageActionRow: {
    height: 54,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 12px',
    borderBottom: `1px solid ${theme.colors.skyDarker}`,
    transition: 'all .1s linear',
    '& svg': {
      transition: 'all .1s linear',
      transform: 'translateX(-2px)',
    },
    '&:hover': {
      backgroundColor: theme.colors.skyBase,
      '& > svg': {
        transform: 'translateX(2px)',
      },
    },
  },
  arrowIcon: {
    color: theme.colors.navyBase,
    fontSize: 15,
  },
  bodyText: {
    marginTop: '14px',
  },
  titleErrorWrapper: {
    width: 'auto',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'auto auto',
    gridColumnGap: '10px',
  },
  errorIcon: {
    height: 17,
    width: 17,
  },
}))

function FormPage(props) {
  const { page, index, pageCopy } = props
  const {
    lightboxSettings, actions, values, builder,
  } = useContext(BuilderContainerContext)
  const focused = values.selectedPage === index
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const css = useStyles({ focused })

  const name = `pageMissingImage_${index}`
  const hasImageError = builder.errors.verify(name)

  const {
    onInsertIntoPage,
    onRemoveFromPage,
    onRemoveFieldsFromList,
    setESPValuesOnPageFields,
  } = actions
  const toaster = useContext(ToasterContext)

  const settings = lightboxSettings.getSettings()

  const toggleDeleteModal = (show = true) => setShowDeleteModal(show)

  const notLastPage = settings.settingsPages.length > 2

  const formHasEmailField = checkPagesForField(settings, 'email')
  const formHasPhoneField = checkPagesForField(settings, 'phone')
  const formHasPhoneButNotEmail = !formHasEmailField && formHasPhoneField
  const formHasEmailButNotPhone = formHasEmailField && !formHasPhoneField
  const formHasOnlyPhoneOrEmail = formHasPhoneButNotEmail || formHasEmailButNotPhone

  const pageHasEmailField = checkPageForField(page, 'email')
  const pageHasPhoneField = checkPageForField(page, 'phone')
  const pageHasOnlyPhoneOrEmail = pageHasEmailField || pageHasPhoneField

  const protectedPage = formHasOnlyPhoneOrEmail && pageHasOnlyPhoneOrEmail
  const canDeletePage = page.isDeletable() && notLastPage && !protectedPage

  const showWarningToaster = message => {
    toaster.showToaster(message, {
      type: 'warning',
      timeout: 5000,
    })
  }

  const showRequiredFieldsToaster = () => {
    showWarningToaster('Your signup form must have either an Email or Phone field.')
  }

  const onDeletePage = () => {
    toggleDeleteModal(false)

    if (protectedPage) {
      showRequiredFieldsToaster()
      return
    }

    if (canDeletePage) {
      onRemoveFieldsFromList(page.inputs)
      settings.removePage(page)
      return
    }

    showWarningToaster('Your signup form must have at least one input page.')
  }

  const onDeletePageClick = () => {
    if (canDeletePage && page.inputs.length > 0) toggleDeleteModal()
    else onDeletePage()
  }

  const onPageClick = () => actions.setSelectedPage(index)

  // fields specific methods
  const onAdd = ({ data: input }) => {
    onInsertIntoPage(page, input)
    setESPValuesOnPageFields() // re-validate esp values on add
  }
  const onDelete = input => onRemoveFromPage(page, input)
  const onSort = (fieldIndex, hoverIndex) => {
    const input = page.inputs[hoverIndex]
    page.addInput(input, fieldIndex)
  }

  const onEditClick = () => {
    actions.setSelectedPage(index)
    builder.drawer.open(
      { page, index, type: pageCopy.key },
      { title: `${pageCopy.key} ${index + 1}: ${pageCopy.description}`, withBackButton: true }
    )
  }

  const [, dropRef] = useDrop({
    accept: 'drag',
    drop: onAdd,
  })

  let pageDescriptor = 'Page'
  if (settings.type === 'embed') pageDescriptor = 'Step'

  const pageActions = [
    {
      label: `Delete ${pageDescriptor}`,
      onClick: onDeletePageClick,
      color: 'redBase',
      colorDisabled: 'redDarkest',
      disabled: !canDeletePage,
    },
  ]

  return (
    <div className={css.formPage} ref={dropRef} onClick={onPageClick}>
      <div className={css.formPageTitle}>
        <div className={css.formPageProgressMarker} />
        <Text navyBase size={10}>
          {`${pageCopy.key} ${index + 1}`}
        </Text>
        <MoreMenu actions={pageActions} />
      </div>

      <div className={css.formPageActionRow} onClick={onEditClick}>
        <div className={css.titleErrorWrapper}>
          <Text navyBase size={14}>
            {pageCopy.description}
          </Text>
          {hasImageError && <ErrorIcon className={css.errorIcon} />}
        </div>
        <ArrowIcon className={css.arrowIcon} />
      </div>

      <div className={css.fieldsHeader}>
        <Text charcoalDark size={10}>
          Fields
        </Text>
      </div>

      <FormPageInputs
        inputs={page.inputs}
        onSort={onSort}
        onDelete={onDelete}
        showRequiredFieldsToaster={showRequiredFieldsToaster}
        hasSingleRequiredField={formHasOnlyPhoneOrEmail}
      />

      <GenericModalBase
        confirmLabel={`Delete ${pageDescriptor}`}
        cancelLabel="Cancel"
        show={showDeleteModal}
        title={`Delete ${pageDescriptor}`}
        onCancel={() => toggleDeleteModal(false)}
        onConfirm={onDeletePage}
      >
        <div className={css.bodyText}>
          <Text size={16} charcoalLight>
            {`You're about to delete this ${pageDescriptor.toLowerCase()}. Any settings and content will not be saved. Are you sure you want to proceed?`}
          </Text>
        </div>
      </GenericModalBase>
    </div>
  )
}

FormPage.propTypes = {
  page: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  pageCopy: PropTypes.object.isRequired,
}

export default React.memo(FormPage)

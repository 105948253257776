import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { P } from 'components/texts'

import CouponDetectorModal from '../CouponDetectorModal'

function EditorCouponWrapper(props) {
  const { children: code } = props

  const timeoutRef = React.useRef(null)
  const spanRef = React.useRef(null)
  const [show, setShow] = useState(false)
  const [shown, setShown] = useState(false)

  const onCodeClick = () => {
    setShown(false)
    setShow(true)
  }

  const onMouseHover = () => {
    clearTimeout(timeoutRef.current)
    if (shown) return
    setShow(true)
  }

  const onMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setShow(false)
      setShown(true)
    }, 1000)
  }

  const placement = spanRef.current && spanRef.current.getBoundingClientRect()

  return (
    <span
      ref={spanRef}
      style={{ display: 'inline-block' }}
      onMouseOver={onMouseHover}
      onMouseLeave={onMouseLeave}
    >
      <P azure>
        <strong onClick={onCodeClick}>{code}</strong>
      </P>
      <CouponDetectorModal
        code={code[0].props.text}
        placement={placement}
        show={show}
        onClose={() => setShow(false)}
      />
    </span>
  )
}

EditorCouponWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default React.memo(EditorCouponWrapper)

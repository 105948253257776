import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Text from 'components/texts/Text'

const useStyles = makeStyles(theme => ({
  box: {
    border: `1px solid ${theme.colors.skyBase}`,
    borderRadius: 4,
    backgroundColor: 'white',
    marginBottom: 12,
  },
  titles: {
    height: 66,
    padding: 16,
    borderBottom: `1px solid ${theme.colors.skyBase}`,
  },
  messages: {
    padding: '12px 16px 0',
  },
}))

function DripPreviewMessageBox(props) {
  const { isFollowup, trigger, children } = props
  const css = useStyles()
  const title = (isFollowup ? 'follow up messages' : 'initial message').toUpperCase()

  return (
    <div className={css.box}>
      <div className={css.titles}>
        <div style={{ marginBottom: 4 }}>
          <Text size={10} charcoalBase>
            {title}
          </Text>
        </div>
        <Text size={12} charcoalLight>
          {`Trigger: ${trigger}`}
        </Text>
      </div>
      <div className={css.messages}>{children}</div>
    </div>
  )
}

DripPreviewMessageBox.propTypes = {
  isFollowup: PropTypes.bool,
  children: PropTypes.node.isRequired,
  trigger: PropTypes.string.isRequired,
}

DripPreviewMessageBox.defaultProps = {
  isFollowup: false,
}

export default React.memo(DripPreviewMessageBox)

import { format } from 'd3-format'
import { isEmail, isURL } from 'validator'
import axios from 'axios'
import moment from 'moment'

import { usStates } from 'utils/usRegions'

/* General */
export const isRequired = (value, copy = null) => !value && (copy || 'Required field')

/* Numerical */
const _isPositive = value => value && +value > 0
const _isPositiveOrZero = value => Number.isFinite(+value) && +value >= 0
const _isInteger = value => Math.round(+value) === +value

export const isNum = value => Number.isNaN(+value) && 'Must be a number'
export const isPositive = value => !_isPositive(value) && 'Must be positive'
export const isPositiveOrZero = value => !_isPositiveOrZero(value) && 'Must be positive or zero'
export const isPositiveInteger = value =>
  (!_isPositive(value) || !_isInteger(value)) && 'Must be positive integer'
export const isPositiveOrZeroInteger = value =>
  (!_isPositiveOrZero(value) || !_isInteger(value)) && 'Must be positive integer or zero'
export const cannotExceed = max => value => +value > max && `Cannot exceed ${format(',')(max)}`
export const atLeast = (min, copy = null) => value =>
  +value < min && (copy || `Must be at least ${format(',')(min)}`)
export const cannotExceedInteger = value => +value > 2147483647 && 'Number is too large'
export const notGreaterThanTime = maxTime => value =>
  moment(value, ['HH:mm A']).isAfter(moment(maxTime, ['HH:mm A'])) && 'Time is too late'
export const notLessThanTime = minTime => value =>
  moment(value, ['HH:mm A']).isBefore(moment(minTime, ['HH:mm A'])) && 'Time is too early'

// Strings
export const atLeastOfChars = min => value =>
  typeof value === 'string'
  && value.length < min
  && `Must be at least ${format(',')(min)} characters`
export const cantExceedOfChars = max => value =>
  typeof value === 'string' && value.length > max && `Cannot exceed ${max} characters`
export const matchPassword = a => b => a !== b && 'Passwords must match'
export const isWebsite = value => !isURL(value) && 'Invalid URL'
export const isValidEmail = value => !isEmail(value) && 'Invalid Email Address'

export const isValidPassword = value =>
  (!value
    || !value.match(/^(?=.*[A-Z])(?=.*[!\\/?@#$&*+()^%])(?=.*[0-9]).*$/)
    || (value && value.length < 8))
  && 'Invalid password'

// TODO: Confirm this regex
export const isValidSocialHandle = value =>
  !/^[!A-z0-9_\-.]*$/.test(value) && 'Must be a valid social handle'

export const uniqueBrandDomain = async value => {
  if (!value) {
    return false
  }
  const domain = value
    .trim()
    .toLowerCase()
    .replace('http://', '')
    .replace('https://', '')
    .replace('www.', '')
    .split('/')[0]
  try {
    await axios.get(`/test-unique-brand-domain/${domain}`)
    return false
  } catch (err) {
    return `${value} has been taken`
  }
}

export const uniqueBrandName = async value => {
  if (!value) {
    return false
  }
  try {
    await axios.get(`/test-unique-brand-name/${encodeURIComponent(value)}`)
    return false
  } catch (err) {
    return `${value} already exists on the platform`
  }
}

export const emailTaken = async value => {
  if (!value) {
    return false
  }
  try {
    await axios.get(`/register/email-available/${value}`)
    return false
  } catch (err) {
    return `${value} has been taken`
  }
}

// Phone Number (logic adopted from legacy)
export const isPhoneNumber = value => {
  const stringifiedValue = value.toString().replace(/\(|\)|-| /g, '')
  return (
    (!stringifiedValue.match(/^[0-9]+$/)
      || !(stringifiedValue.length >= 7 && stringifiedValue.length <= 15))
    && 'Must be valid phone number'
  )
}

export const isValidUsState = value =>
  !usStates.includes(value?.toUpperCase()) && 'Must be a valid US state code'

export const isValidZipCode = value =>
  !/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value) && 'Must be a valid ZIP code'

import axios from 'axios'
import FormData from 'form-data'
import { each, remove, groupBy } from 'lodash'

const calculateIncome = incomeData => {
  if (!incomeData) return null

  const INCOME_KEYS = [
    ['income_15k_24k', '<35k'],
    ['income_25k_34k', '<35k'],
    ['income_35k_49k', '35-49k'],
    ['income_50k_74k', '50-74k'],
    ['income_75k_99k', '75-99k'],
    ['income_100k_149k', '100K +'],
    ['income_150k_199k', '100K +'],
    ['income_above200k', '100K +'],
    ['income_below15k', '<35k'],
  ]

  let totalIncome = 0
  const items = {}
  const labels = []

  INCOME_KEYS.forEach(([incomeKey, incomeLabel]) => {
    const income = incomeData[incomeKey]

    if (!income) return

    const { [incomeLabel]: incomeSum = 0 } = items[incomeLabel] || {}

    items[incomeLabel] = incomeSum + income
  })

  const dataArr = Object.keys(items).map(incomeItem => {
    const value = items[incomeItem]
    totalIncome += value

    labels.push(incomeItem)
    return value
  })

  const graphData = {
    labels,
    datasets: [{ data: dataArr }],
  }

  return {
    ...incomeData,
    graphData,
    totalIncome,
  }
}

export const calculateTotalReach = brand => {
  const { socialData: social, dedicatedListSize } = brand
  let totalReach = 0

  Object.keys(social).forEach(key => {
    if (key.includes('Followers')) totalReach += social[key]
  })

  return totalReach + dedicatedListSize
}

export const loadBrandPartnerships = async brandId => {
  try {
    const { data: partnerships } = await axios.get(`/partnerships-for-brand/${brandId}`)
    return partnerships
  } catch (err) {
    /*  */
  }
  return []
}

export const loadBrand = async id => {
  try {
    const { data } = await axios.get(`/brands/${id}`)

    const {
      ratings,
      tags: rawTags,
      // Social
      facebookFollowers,
      facebookUrl,
      instagramFollowers,
      instagramHandle,
      pinterestFollowers,
      pinterestHandle,
      twitterFollowers,
      twitterHandle,
      youtubeFollowers,
      youtubeHandle,
      tiktokHandle,
      tiktokFollowers,
      socialMediaImpressions,
      // Campaign
      avgEntries,
      campaignsLast90Days,
      hostedCampaigns,
      totalCampaigns,
      pastPartners,
      ...restData
    } = data

    const industryTags = rawTags.map(({ name }) => name)

    const socialData = {
      socialMediaImpressions,
      facebookFollowers,
      facebookUrl,
      instagramFollowers,
      instagramHandle,
      pinterestFollowers,
      pinterestHandle,
      twitterFollowers,
      twitterHandle,
      youtubeFollowers,
      youtubeHandle,
      tiktokHandle,
      tiktokFollowers,
    }

    return {
      ...restData,
      ratings: ratings[0],
      industryTags,
      socialData,
      campaignStats: {
        'Campaigns Hosted': hostedCampaigns,
        'Recent Campaigns': campaignsLast90Days,
        'Total Campaigns': totalCampaigns,
        'Brand Partners': pastPartners,
        'Total Audience Reach': socialMediaImpressions + restData.dedicatedListSize,
      },
    }
  } catch (err) {
    console.log({ err })
  }

  return null
}

export const loadBrandInterests = async brandId => {
  try {
    const { data: brandInsterestsData } = await axios.get(`/brands/interests/${brandId}`)

    return brandInsterestsData
  } catch (err) {
    console.log({ err })
  }

  return null
}

export const loadBrandSellerProfile = async brandId => {
  try {
    const { data: sellerProfileData } = await axios.get(`/brands/seller-profile/${brandId}`)

    return sellerProfileData || {}
  } catch (err) {
    console.log({ err })
  }

  return null
}

export const loadAvailableCampaigns = async brandId => {
  try {
    const { data } = await axios.get(`/available-campaigns/${brandId}`)

    const [campaignsData] = data

    const campaignInvites = groupBy(campaignsData, 'campaign_id')
    const campaigns = []

    each(campaignInvites, invites => {
      const [invite] = invites
      const [hostInvite] = remove(invites, { invitee_id: invite.hostBrandId })
      const campaignPlaceholder = invite || hostInvite

      const partners = invites.filter(partner => partner.status === 'accepted')

      each(invites, inv => {
        inv.invitee = { ...inv, id: inv.invitee_id }

        if (partners.length < 8) {
          if (inv.status === 'interested' && inv.agreement) {
            partners.push(inv)
          }
        }
      })

      const calculateEntries = brand =>
        (brand.averageSignUpsPerCampaign
          ? brand.averageSignUpsPerCampaign
          : parseInt(brand.dedicatedListSize * 0.01, 10))

      const partnerEstimatedEntries = partners.reduce((acc, partner) => {
        const estEntries = calculateEntries(partner)

        return acc + estEntries
      }, 0)

      const hostEstimatedEntries = calculateEntries(hostInvite)
      const estimatedEntries = hostEstimatedEntries + partnerEstimatedEntries

      const campaign = {
        ...campaignPlaceholder,
        hostBrand: {
          id: hostInvite.invitee_id,
          accountname: hostInvite.accountname,
          logo: hostInvite.logo,
          invitee: hostInvite,
          status: 'accepted',
        },
        hostBrandLogo: hostInvite.logo,
        partners,
        id: campaignPlaceholder.campaign_id,
        estimatedEntries,
      }

      campaigns.push(campaign)
    })

    return campaigns
  } catch (err) {
    console.log({ err })
  }

  return null
}

export const loadContentSamples = async brandId => {
  try {
    const { data: contentSamples } = await axios.get(`/brands/content-samples/${brandId}`)

    return contentSamples
  } catch (err) {
    console.log({ err })
  }

  return []
}

export const loadRecentPartners = async brandId => {
  try {
    const { data: recentPartners } = await axios.get(`/brands/${brandId}/recent-partners`)

    return recentPartners
  } catch (err) {
    console.log({ err })
  }

  return []
}

export const loadBrandDegree = async brandId => {
  try {
    const { data: brandDegree } = await axios.get(`/brand-degree/${brandId}`)

    return brandDegree
  } catch (err) {
    console.log({ err })
  }

  return '3rd'
}

// This one calls every function in this file
export const loadBrandProfile = async brandId => {
  try {
    const [
      brand,
      interests,
      sellerProfile,
      upcomingCampaigns,
      contentSamples,
      recentPartners,
      degree,
    ] = await Promise.all([
      loadBrand(brandId),
      loadBrandInterests(brandId),
      loadBrandSellerProfile(brandId),
      loadAvailableCampaigns(brandId),
      loadContentSamples(brandId),
      loadRecentPartners(brandId),
      loadBrandDegree(brandId),
    ])

    const brandObj = {
      ...brand,
      incomeData: calculateIncome(brand.incomeData),
      contentSamples,
      interests,
      sellerProfile,
      upcomingCampaigns,
      recentPartners,
      degree,
    }

    return brandObj
  } catch (err) {
    console.log({ err })
  }

  return null
}

export const getCoverPhotoUrl = (coverPhoto, options = { thumb: false }) => {
  if (
    !coverPhoto
    || typeof coverPhoto !== 'string'
    || coverPhoto.endsWith('.png')
    || coverPhoto.endsWith('.jpeg')
  ) {
    // Do nothing and return the value if it's an image string or a null value
    return coverPhoto
  }

  const imageType = options.thumb ? 'thumb' : 'main'
  return `${coverPhoto}/${imageType}.png`
}

export const updateBrand = async (data, options = {}) => {
  const { url = '/brands', method = 'PUT' } = options

  try {
    const { data: brandData } = await axios(url, {
      method,
      data,
    })

    return brandData
  } catch (err) {
    console.log({ err })
  }

  return false
}

export const updateBrandSellerProfile = async data => {
  try {
    return updateBrand(data, { url: '/brands/seller-profile', method: 'PATCH' })
  } catch (err) {
    console.log({ err })
  }

  return null
}

export const uploadUpdateContentSample = async (...args) => {
  const [image, label, id] = args

  if (!id) {
    const form = new FormData()
    form.append('file', image)
    form.append('label', label)
    form.append('name', image.name)

    try {
      const { data } = await axios.post('/brands/content-samples', form)

      return {
        id: data.id,
        label: data.label,
        s3_url: data.s3_url,
        brand_id: data.brand_id,
      }
    } catch (err) {
      console.log({ err })
    }

    return null
  }

  const data = await updateBrand(
    { id, label },
    { url: `/brands/content-samples/${id}`, method: 'PUT' }
  )

  return data || {}
}

export const deleteContentSample = id =>
  updateBrand(null, { url: `/brands/content-samples/${id}`, method: 'DELETE' })

export const uploadCoverImg = async file => {
  try {
    const form = new FormData()
    form.append('file', file)
    const { data } = await axios.post('/brands/cover', form)

    return data
  } catch (err) {
    console.log({ err })
  }

  return null
}

export const uploadProfileImg = async file => {
  try {
    const form = new FormData()
    form.append('file', file)

    const { data } = await axios.post('/brands/logo', form)

    return data
  } catch (err) {
    console.log({ err })
  }

  return null
}

export const uploadProfileImgFromURL = async imageUrl => {
  try {
    const response = await fetch(imageUrl)
    const blob = await response.blob()
    const [fileName] = imageUrl.split('/').slice(-1)
    return uploadProfileImg(new File([blob], fileName, { type: blob.type }))
  } catch (err) {
    console.log({ err })
    return undefined
  }
}

export const verifyUniqueUrl = async domainToTest => {
  try {
    await axios.get(`/test-unique-update-brand-domain/${domainToTest}`)

    return true
  } catch (err) {
    console.log({ err })
  }

  return false
}

export const sendDataExport = async (brandId, data) =>
  axios.post(`/send-data-export/${brandId}`, data)

export const toggleBrandFavorite = async brandId =>
  axios.post('/toggle-brand-favorite', { brandId })

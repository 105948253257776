import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Text from 'components/texts/Text'
import { BuilderContainerContext } from './BuilderContainerContext'

const useStyles = makeStyles({
  container: {
    padding: '32px 80px',
    backgroundColor: 'white',
    width: 'calc(100vw - 84px)', // 84px to offset left sidebar
    height: 'fit-content',
  },
})

function BuilderNoPreviewContainer({ children }) {
  const css = useStyles()
  const context = React.useContext(BuilderContainerContext)
  const {
    builder: { section },
  } = context

  return (
    <section className={css.container}>
      {section.helperTitle && (
        <span style={{ marginBottom: 4 }}>
          <Text size={10} charcoalLight>
            {section.helperTitle}
          </Text>
        </span>
      )}
      <Text size={20} charcoalDarker>
        {section.title}
      </Text>
      {children}
    </section>
  )
}

BuilderNoPreviewContainer.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
}

export default React.memo(BuilderNoPreviewContainer)

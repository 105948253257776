import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'

import { ButtonBase, makeStyles } from '@material-ui/core'

import Menu from 'components/menus/Menu'
import ContactSupportModal from 'components/modals/ContactSupportModal'

import heartIcon from 'images/nav-bar/heart-icon.svg'
import blueHeartIcon from 'images/nav-bar/blue/heart-icon.svg'
import searchIcon from 'images/nav-bar/search-icon.svg'
import blueSearchIcon from 'images/nav-bar/blue/search-icon.svg'

import * as metrics from 'utils/metrics'
import styleHelper from 'utils/styleHelper'

import MessagesNotifications from './MessagesNotifications'
import { clickedOn, onMenuClick } from './commonUtils'

const useStyles = makeStyles(theme => ({
  icon: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    borderRadius: '50%',
    width: 36,
    height: 36,
    transition: 'background-color .1s linear',
    '&:hover': {
      backgroundColor: 'rgba(44, 53, 65, 0.28)',
    },
  },
  favoritesIcon: {
    margin: '0px 6px',
    '& div': {
      backgroundColor: theme.colors.blueDarker,
      backgroundImage: `url(${heartIcon})`,
    },
  },
  favoritesIconActive: {
    '& div': {
      backgroundColor: 'white !important',
      backgroundImage: `url(${blueHeartIcon})`,
    },
  },
  searchIcon: {
    '& div': {
      backgroundColor: theme.colors.blueDarker,
      backgroundImage: `url(${searchIcon})`,
    },
  },
  searchIconActive: {
    '& div': {
      backgroundColor: 'white !important',
      backgroundImage: `url(${blueSearchIcon})`,
    },
  },
  link: {
    cursor: 'pointer',
    margin: '0 12px',
    fontFamily: 'Larsseit',
    lineHeight: '1',
    color: theme.colors.cornflower,
    '&:hover': {
      fontSize: '14px',
      fontWeight: 'bold',
      color: theme.colors.white,
    },
  },
  hideTablet: {
    [styleHelper.tablet]: {
      display: 'none',
    },
  },
  helpMenu: {
    fontSize: 14,
    fontWeight: 'bold',
    color: 'white',
  },
}))

function NavBarRightMenu(props) {
  const {
    messages,
    notifications,
    unreadMessageCount,
    unreadNotificationCount,
    currentBrand,
    messagesLoading,
  } = props
  const css = useStyles()

  const [openHelpMenu, setOpenHelpMenu] = useState(false)
  const [contactSupportModalOpen, setContactSupportModalOpen] = useState(false)

  const helpMenuItems = [
    {
      label: 'Help Center',
      onClick: () => {
        metrics.create('helpMenu', { meta: { page: 'Knowledge Base', action: 'clickedOnItem' } })
        window.open('https://help.dojomojo.com/hc/en-us', 'tab')
      },
    },
    {
      label: 'Contact Us',
      onClick: () => {
        metrics.create('helpMenu', { meta: { page: 'Contact Us', action: 'clickedOnItem' } })
        setContactSupportModalOpen(true)
      },
    },
  ]

  const onHelpMenuClick = onMenuClick(setOpenHelpMenu, 'Help Menu')

  const HelpMenu = () => (
    <div
      onClick={openHelpMenu ? null : onHelpMenuClick}
      className={cn({ [css.helpMenu]: openHelpMenu })}
    >
      <span className={css.link}>Help</span>
      <Menu
        itemMinHeight={30}
        width={140}
        items={helpMenuItems}
        open={openHelpMenu}
        onClose={onHelpMenuClick}
      />
    </div>
  )

  /*eslint-disable*/
  const NavBarLink = React.useCallback(
    props => (
      <NavLink
        to={props.to}
        className={cn(css[props.classname], { [css.hideTablet]: props.hide })}
        activeClassName={css[`${props.classname}Active`]}
        onClick={() => clickedOn(props.clickedOn)}
      >
        {props.content ? (
          props.content
        ) : (
          <ButtonBase centerRipple className={css.ripple}>
            {/* Div needed for icon */}
            <div className={css.icon} />
          </ButtonBase>
        )}
      </NavLink>
    ),
    []
  )
  /*eslint-enable*/

  return (
    <>
      <NavBarLink to="/search" classname="searchIcon" clickedOn="Search Button" />
      <NavBarLink to="/favorites" classname="favoritesIcon" clickedOn="Favourites Button" hide />
      <MessagesNotifications
        messages={messages}
        notifications={notifications}
        unreadMessageCount={unreadMessageCount}
        unreadNotificationCount={unreadNotificationCount}
        currentBrand={currentBrand}
        messagesLoading={messagesLoading}
      />
      <NavBarLink to="/calendar" classname="link" clickedOn="Calendar" content="Calendar" hide />
      <HelpMenu />
      <ContactSupportModal
        onClose={() => setContactSupportModalOpen(false)}
        open={contactSupportModalOpen}
      />
    </>
  )
}

NavBarRightMenu.propTypes = {
  currentBrand: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  unreadMessageCount: PropTypes.number.isRequired,
  notifications: PropTypes.array.isRequired,
  messagesLoading: PropTypes.bool.isRequired,
  unreadNotificationCount: PropTypes.number.isRequired,
}

export default React.memo(NavBarRightMenu)

import React from 'react'

import { PageTitle, PageSubtitle } from 'components/builder/titles'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

const TITLE = 'Fields'

const SUBTITLES_BY_FORM_TYPE = {
  popup: 'Drag and drop fields into the form pages above.',
  embed: 'Drag and drop fields into the form steps above.',
}

function FieldsTitle() {
  const { lightboxSettings } = React.useContext(BuilderContainerContext)
  const { settings } = lightboxSettings.getSettings()

  let formType = settings.type
  if (formType === 'lightbox') formType = 'popup'

  return (
    <div>
      <PageTitle text={TITLE} />
      <PageSubtitle text={SUBTITLES_BY_FORM_TYPE[formType]} />
    </div>
  )
}

export default React.memo(FieldsTitle)

import React, { useState } from 'react'
import PropTypes from 'prop-types'

import StatusBanner from 'components/virtual/banners/StatusBanner'
import BannerContent from 'components/virtual/banners/BannerContent'

function CompactBanner(props) {
  const { type, withClose, text } = props

  const [open, setOpen] = useState(true)

  return (
    <div style={{ marginBottom: 12 }}>
      <StatusBanner
        fullWidth
        close={withClose}
        open={open}
        onClose={() => setOpen(false)}
        padding={12}
        status={type}
        align="center"
      >
        <BannerContent title={text} />
      </StatusBanner>
    </div>
  )
}

CompactBanner.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  withClose: PropTypes.bool,
}

CompactBanner.defaultProps = {
  type: 'informational',
  withClose: false,
}

export default React.memo(CompactBanner)

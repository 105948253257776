import { useState, useEffect } from 'react'
import axios from 'axios'
import useSmsVerify from './useSmsVerify'

const useBrandTcpa = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [brandTcpaDetails, setBrandTcpaDetails] = useState({})
  const { setSmsVerifyFlag } = useSmsVerify()

  const loadBrandTcpaDetails = async () => {
    setBrandTcpaDetails({})
    setIsLoading(true)
    setIsError(false)

    try {
      const { data: tcpaDetails } = await axios.get('/tcpa')
      setBrandTcpaDetails(tcpaDetails)
    } catch (err) {
      setIsError(true)
    }
    setIsLoading(false)
  }

  const updateBrandTcpaDetails = async tcpaData => {
    setIsLoading(true)
    setIsError(false)
    try {
      const { data } = await axios.put('/tcpa', tcpaData)
      setBrandTcpaDetails(data.updatedTcpaDetails)

      if (data.created) {
        setSmsVerifyFlag()
      }
    } catch (err) {
      setIsError(true)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    loadBrandTcpaDetails()
  }, [])

  return {
    isLoading,
    isError,
    brandTcpaDetails,
    loadBrandTcpaDetails,
    updateBrandTcpaDetails,
    hasAcceptedTCPATerms: Boolean(brandTcpaDetails.accepted_at),
  }
}

export default useBrandTcpa

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Text from 'components/texts/Text'
import Tooltip from 'components/virtual/utility/Tooltip'

const useStyles = makeStyles({
  title: {
    display: 'flex',
    alignItems: 'center',
    '& > :first-child': {
      marginRight: 8,
    },
  },
})

function PageSubgroupTitle(props) {
  const { text, textProps, tooltip } = props
  const css = useStyles()

  return (
    <div className={css.title}>
      <Text charcoalBase {...textProps}>
        {text}
      </Text>
      {tooltip && (
        <Tooltip text={tooltip.text} action={tooltip.action} actionLabel={tooltip.actionLabel} />
      )}
    </div>
  )
}

PageSubgroupTitle.propTypes = {
  tooltip: PropTypes.object,
  text: PropTypes.string.isRequired,
  textProps: PropTypes.object,
}

PageSubgroupTitle.defaultProps = {
  tooltip: null,
  textProps: { size: 16 },
}

export default React.memo(PageSubgroupTitle)

import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import { Input } from 'components/inputs'
import { P } from 'components/texts'
import { Button } from 'components/buttons'

import { SMSEditorContext } from 'contexts/SMSEditorContext'
import SMSEditorGenericModal from './SMSEditorGenericModal'

const useStyles = makeStyles({
  wrapper: {
    width: 552,
    margin: '0px 4px 8px 0px',
  },
  input: {
    marginTop: 12,
    display: 'flex',
    alignItems: 'flex-end',
    '& > div:first-child': {
      // Input component
      width: 'calc(100% - 140px)',
      marginRight: 10,
      '& ::placeholder': {
        marginTop: 2,
      },
    },
    '& > button': {
      flex: 1,
      marginBottom: 2,
    },
  },
})

function CustomFallbackTagModal(props) {
  const {
    show, customTag, onClose, placement, fallback,
  } = props
  const wrapperRef = useRef()
  const timeoutRef = useRef()
  const [value, setValue] = useState('')

  const isDisabled = !value

  const {
    actions: { onAddFallback, setEditorReadOnly },
  } = React.useContext(SMSEditorContext)
  const css = useStyles()

  const onUseFallback = () => {
    const fallbackValue = value
    setTimeout(onClose, 1) // needed~!, dunno why?
    setValue('')
    onAddFallback({ fallback: fallbackValue, tag: customTag })
  }

  const onFocusChange = readOnly => {
    clearTimeout(timeoutRef.current)
    if (readOnly) setEditorReadOnly(readOnly)
    else timeoutRef.current = setTimeout(() => setEditorReadOnly(readOnly), 500)
  }

  useEffect(() => {
    if (wrapperRef.current) {
      const parent = wrapperRef.current.parentNode
      parent.style.marginTop = '12px'

      setValue(fallback.toLowerCase() === 'fallback' ? '' : fallback)
    }
  }, [show, fallback])

  let [, recipientTag] = /\*\|(.+)_OR__|\*/.exec(customTag)
  recipientTag = recipientTag.replace(/_/g, ' ').toLowerCase()

  const text = `Add a fallback–alternate text–in case the recipient's "${recipientTag}" is unavailable.`

  const content = (
    <ClickAwayListener onClickAway={onClose}>
      <div ref={wrapperRef} className={css.wrapper}>
        <P>{text}</P>
        <div className={css.input}>
          <Input
            onBlur={() => onFocusChange(false)}
            onFocus={() => onFocusChange(true)}
            value={value}
            onChange={setValue}
            label="Fallback"
            placeholder="Fallback"
          />
          <Button disabled={isDisabled} onClick={onUseFallback} label="Update" />
        </div>
      </div>
    </ClickAwayListener>
  )

  return <SMSEditorGenericModal show={show} content={content} placement={placement} />
}

CustomFallbackTagModal.propTypes = {
  show: PropTypes.bool.isRequired,
  placement: PropTypes.element.isRequired,
  customTag: PropTypes.string.isRequired,
  fallback: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default React.memo(CustomFallbackTagModal)

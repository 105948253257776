import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { colors } from 'components/virtual/VirtualTheme'

import { BuilderContainerContext } from './BuilderContainerContext'
import BuilderSection from './BuilderSection'

const useStyles = makeStyles({
  sidebar: {
    height: '100%',
    width: 84,
    backgroundColor: colors.white,
    borderRight: `1px solid ${colors.skyLight}`,
    textTransform: 'capitalize',
    position: 'fixed',
    top: 60,
    left: 0,
  },
})

function BuilderSections() {
  const context = React.useContext(BuilderContainerContext)
  const {
    config: {
      sidebar: { sections },
    },
  } = context

  const css = useStyles()

  return (
    <div className={css.sidebar}>
      {sections.map(section => {
        const { path, sidebarButton, previewNavbar } = section
        return (
          <BuilderSection
            key={path}
            to={path}
            button={sidebarButton}
            previewNavbar={previewNavbar}
            section={section}
          />
        )
      })}
    </div>
  )
}

export default React.memo(BuilderSections)

import axios from 'axios'

import processCampaigns from 'utils/campaigns/processCampaigns'

export const loadCampaigns = () => async dispatch => {
  dispatch({ type: 'CAMPAIGNS_LOADING' })

  try {
    const { data: payload } = await axios.get('/campaigns-data')

    const campaigns = processCampaigns(payload)
    return dispatch({ type: 'CAMPAIGNS_LOAD', payload: campaigns })
  } catch (err) {
    //todo handle this
    console.log(err)
  }

  return dispatch({ type: 'CAMPAIGNS_FAILED' })
}

export const a = 'b'

import store from 'utils/reduxStore'
import { throttle } from 'lodash'
import moment from 'moment'
import axios from 'axios'
import { v4 as uuid } from 'uuid'

let session = null
let eventQueue = []

const saveSession = throttle(() => {
  session.createdAt = moment().format()
  localStorage.setItem('metricsSession', JSON.stringify(session))
}, 10000)

export const getSessionId = () => {
  session = session || JSON.parse(localStorage.getItem('metricsSession'))
  if (!session || moment().diff(moment(session.createdAt), 'hours') >= 2) {
    const sessionId = uuid()
    session = {
      id: sessionId,
    }
  }
  saveSession(session)
  return session.id
}

export const sendEventsImmediately = () => {
  if (eventQueue.length === 0) return

  axios.post('/metrics', eventQueue).catch(console.error)

  eventQueue = []
}

const sendEvents = throttle(sendEventsImmediately, 3000)

export const create = (action, data = {}) => {
  data.action = action
  data.url = window.location.href
  data.createdAt = moment.utc().format('YYYY-MM-DD HH:mm:ss')
  data.sessionId = getSessionId()
  eventQueue.push(data)

  // wait for brand to load so that the brand id is set
  if (store.getState().currentBrand.loading) {
    setTimeout(() => {
      sendEvents()
    }, 5000)
    return
  }

  sendEvents()
}

export const sendImmediately = () => {
  sendEventsImmediately()
}

export default create

import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import axios from 'axios'

import { ButtonGroup } from 'components/buttons'
import { MobileModal } from 'components/modals'
import { Form, FormInput } from 'components/forms'
import { isRequired, isPhoneNumber } from 'utils/formValidations'
import { ToasterContext } from 'contexts'
import formatPhoneNumber from 'utils/sms/formatPhoneNumber'

const useStyles = makeStyles({
  form: {
    width: '100%',
  },
  testModal: {
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 0 80px 0',
  },
})

const SendTestModal = ({ show, onClose, message }) => {
  const css = useStyles()
  const [valid, setValid] = useState(true)
  const { showToaster } = useContext(ToasterContext)

  const {
    coupon_code, url_link, custom_sms_message, image_url, image_obj,
  } = message

  const sendTestMessage = async ({ phoneNumber }) => {
    try {
      showToaster('Sending Test...', { timeout: 5000 })
      await axios.post('/sms/sms-send/test-message', {
        coupon_code,
        url_link,
        custom_sms_message,
        phoneNumber,
        image_url,
        image_obj,
      })
      showToaster('Test message sent successfully.', { type: 'success' })
    } catch (e) {
      let errorMessage = 'Test message failed. Please try again later.'
      if (e.message === 'Request failed with status code 429') {
        errorMessage = 'You have reached your SMS test message limit for today.'
      }
      showToaster(errorMessage, { type: 'warning' })
    } finally {
      onClose()
    }
  }

  const onExposeValues = form => setValid(form.valid)

  return (
    <MobileModal centerTitle open={show} title="Send a Test Message" onClose={onClose}>
      <div className={css.testModal}>
        <div className={css.form}>
          <Form onSubmit={sendTestMessage} withSubmit="sendTestSmsId" exposeValues={onExposeValues}>
            <FormInput
              type="tel"
              name="phoneNumber"
              label="Send To"
              placeholder="(555) 555-5555"
              onChange={formatPhoneNumber}
              validations={[isRequired, isPhoneNumber]}
            />
          </Form>
        </div>
      </div>
      <ButtonGroup
        border
        submitId="sendTestSmsId"
        submitLabel="Send Test"
        canSubmit={valid}
        onSubmit={sendTestMessage}
        cancelLabel="Cancel"
        onCancel={onClose}
      />
    </MobileModal>
  )
}

SendTestModal.propTypes = {
  message: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
}

SendTestModal.defaultProps = {
  show: false,
}

export default React.memo(SendTestModal)

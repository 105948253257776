import { useEffect, useRef, useState } from 'react'

function useInputDebounce(initialValue, onChange, controlled = false) {
  const timeoutRef = useRef(null)
  const [textValue, setTextValue] = useState(initialValue)

  // To disable delay when typing
  const onInputChange = ({ target }) => {
    clearTimeout(timeoutRef.current)
    setTextValue(target.value)

    timeoutRef.current = setTimeout(() => onChange?.(target.value), 200)
  }

  useEffect(
    () => () => {
      clearTimeout(timeoutRef.current)
    },
    []
  )

  if (controlled) return [initialValue, onChange]

  return [textValue, onInputChange]
}

export default useInputDebounce

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

import BuilderSections from './BuilderSections'
import BuilderSectionContent from './BuilderSectionContent'
import BuilderSectionEditDrawer from './BuilderSectionEditDrawer'
import { BuilderContainerContext } from './BuilderContainerContext'
import BuilderNoPreviewContainer from './BuilderNoPreviewContainer'

const useStyles = makeStyles({
  container: {
    transition: 'width 1.2s linear',
    backgroundColor: 'white',
    position: 'relative',
    display: 'flex',
    maxHeight: 'calc(100vh - 60px)', // 60px cuz of navbar height
    paddingLeft: 84, // Offsets fixed sidebar
  },
  preview: { width: 0, overflow: 'hidden' },
})

function BuilderSectionsContent() {
  const css = useStyles()
  const {
    builder: {
      preview: { full: isPreview },
      section,
    },
  } = React.useContext(BuilderContainerContext)

  const Wrapper = section?.fullPage ? BuilderNoPreviewContainer : React.Fragment

  return (
    <div className={cn(css.container, { [css.preview]: isPreview })}>
      <BuilderSections />
      <Wrapper>
        <BuilderSectionContent />
        {!section.fullPage && <BuilderSectionEditDrawer />}
      </Wrapper>
    </div>
  )
}

export default React.memo(BuilderSectionsContent)

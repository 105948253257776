import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { PageTitle } from 'components/builder/titles'
import RadioGroup from 'components/virtual/buttons/RadioGroup'
import Text from 'components/texts/Text'
import BehaviorFrequencyDelay from './BehaviorFrequencyDelay'

const useStyles = makeStyles({
  group: {
    paddingLeft: 28,
    paddingBottom: 9,
  },
  radioLabelSpan: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
})

function BehaviorFrequency({ settings, onFrequencyChange }) {
  const css = useStyles()

  const RADIO_GROUPS = {
    groupOne: [
      {
        value: 'every-session',
        label: 'Show again after closing:',
        forceCheck: ['every-session', 'delay'],
      },
    ],
    groupOneSubgroup: [
      { value: 'every-session', label: 'Every session' },
      {
        value: 'delay',
        label: (
          <div className={css.radioLabelSpan}>
            <Text size={16} charcoalBase>
              Every
              {' '}
            </Text>
            <BehaviorFrequencyDelay disabled={settings.trigger.displayFrequency !== 'delay'} />
          </div>
        ),
      },
    ],
    groupTwo: [{ value: 'never', label: 'Never show again' }],
  }

  return (
    <div>
      <PageTitle textProps={{ size: 16 }} text="Frequency" />
      <br />
      <RadioGroup
        values={RADIO_GROUPS.groupOne}
        value={settings.trigger.displayFrequency}
        onChange={onFrequencyChange}
      />
      <div className={css.group}>
        <RadioGroup
          values={RADIO_GROUPS.groupOneSubgroup}
          value={settings.trigger.displayFrequency}
          disabled={settings.trigger.displayFrequency === 'never'}
          onChange={onFrequencyChange}
        />
      </div>
      <RadioGroup
        values={RADIO_GROUPS.groupTwo}
        value={settings.trigger.displayFrequency}
        onChange={onFrequencyChange}
      />
    </div>
  )
}

BehaviorFrequency.propTypes = {
  settings: PropTypes.shape({
    trigger: PropTypes.shape({
      displayFrequency: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onFrequencyChange: PropTypes.func.isRequired,
}

export default React.memo(BehaviorFrequency)

import React from 'react'
import { makeStyles } from '@material-ui/core'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import { DragDropContainer } from 'components/shared/draggable'

import FieldOption from './FieldOption'

const useStyles = makeStyles(theme => ({
  addFieldsWrapper: {
    '& .draggable > div': {
      borderColor: theme.colors.navyLight,
      backgroundColor: theme.colors.navyLightest,
      borderRadius: 4,
    },
  },
}))

function SelectedFields() {
  const css = useStyles()

  const context = React.useContext(BuilderContainerContext)

  const { values, actions, builder } = context
  const { selectedTemplates } = values
  const { onRemoveFieldsFromList } = actions
  const { drawer } = builder

  const render = React.useCallback(
    input => (
      <FieldOption
        key={input}
        onDelete={() => onRemoveFieldsFromList([input])}
        onEdit={() => drawer.open({ data: input })}
        input={input}
      />
    ),
    [onRemoveFieldsFromList] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <div className={css.addFieldsWrapper}>
      <DragDropContainer data={selectedTemplates} itemRender={render} />
    </div>
  )
}

export default React.memo(SelectedFields)

import React from 'react'
import PropTypes from 'prop-types'

import StatusBanner from 'components/virtual/banners/StatusBanner'
import BannerContent from 'components/virtual/banners/BannerContent'

function ActionBanner(props) {
  const {
    type,
    align,
    actionLabel,
    action,
    buttonRight,
    title,
    body,
    onClose,
    show,
    wrapperStyles,
    actionIcon,
  } = props

  return (
    <div style={wrapperStyles}>
      <StatusBanner
        open={show}
        fullWidth
        padding={12}
        status={type}
        align={align}
        close={!!onClose}
        onClose={onClose}
      >
        <BannerContent
          buttonLabel={actionLabel}
          buttonOnClick={action}
          buttonRight={buttonRight}
          title={title}
          text={body}
          buttonRightIcon={actionIcon}
        />
      </StatusBanner>
    </div>
  )
}

ActionBanner.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
  type: PropTypes.string,
  align: PropTypes.string,
  actionLabel: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  actionIcon: PropTypes.string,
  onClose: PropTypes.func,
  buttonRight: PropTypes.bool,
  show: PropTypes.bool,
  wrapperStyles: PropTypes.object,
}

ActionBanner.defaultProps = {
  wrapperStyles: { marginBottom: 12 },
  type: 'informational',
  align: 'center',
  body: null,
  onClose: null,
  buttonRight: true,
  show: true,
  actionIcon: '',
}

export default React.memo(ActionBanner)

import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

// set REACT_APP_DATADOG_CLIENT_TOKEN and REACT_APP_DATADOG_RUM_APP_ID in .env to test datadog
// manually error function example:
// datadogLogs.logger.error('test', { name: 'buttonName', id: 123 })
const initDataDog = () => {
  if (!process.env.REACT_APP_DATADOG_CLIENT_TOKEN || !process.env.REACT_APP_DATADOG_RUM_APP_ID) {
    console.log(
      'Datadog client token or app ID has not been set. Logs are not being sent to Datadog'
    )
    return
  }

  const env = process.env.REACT_APP_DATADOG_ENV ? process.env.REACT_APP_DATADOG_ENV : 'development'

  datadogLogs.setLoggerGlobalContext({ app: 'dojo.future' })

  datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'dojo-web-future',
    env,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['error'], // only send console errors
    sampleRate: 100,
  })

  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_RUM_APP_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'dojo-web-future',
    env,
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  })

  datadogRum.startSessionReplayRecording()
}

export default initDataDog

import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { MenuItem, withStyles, InputAdornment } from '@material-ui/core'
import { KeyboardArrowDownOutlined } from '@material-ui/icons'
import cn from 'classnames'
import { Tiny } from 'components/texts'
import InputBase from './InputBase'

const styles = theme => ({
  disabled: {
    '& [class*=Mui-disabled]': {
      backgroundColor: theme.colors.cream,
      opacity: 0.6,
    },
    '& [class*=MuiSelect-icon]': {
      color: theme.colors.darkGrey,
    },
  },
  icon: {
    marginLeft: 12,
  },
  inputWrapper: {
    width: '100%',
  },
  inputBase: {
    fontFamily: 'Larsseit-Light',
    color: theme.colors.ink,
    padding: 0,
  },
  selectPadding: {
    padding: '11px 12px',
  },
  addBorder: {
    borderTop: `1px solid ${theme.colors.silver}`,
  },
  simpleInputWrapper: {
    width: 'auto',
  },
  simpleInputBase: {
    border: 'none',
    width: 'max-content',
    color: theme.colors.azure,
    fontFamily: 'Larsseit-Medium',
    '& .MuiSelect-icon': {
      color: theme.colors.azure,
    },
  },
  simpleSelectPadding: {
    padding: '14px 25px 12px 0',
  },
  menu: {
    maxHeight: 200,
    boxShadow: theme.shadows.pop(),
  },
  infoText: {
    paddingTop: 4,
    position: 'absolute',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  danger: {
    '& > div [class*=root]': {
      borderColor: theme.colors.alertRed,
    },
  },
})

const renderItems = (item, itemRenderer) => {
  const label = typeof item === 'object' ? item.label : item
  const value = typeof item === 'object' ? item.value : item
  const disabled = typeof item === 'object' ? item.disabled : false
  const borderClass = typeof item === 'object' ? item.borderClass : false

  return (
    <MenuItem
      key={value}
      value={value}
      disabled={disabled}
      classes={{
        root: borderClass,
      }}
    >
      {itemRenderer ? itemRenderer(item) : label}
    </MenuItem>
  )
}

function SelectInput({
  disabled,
  placeholder,
  classes,
  label,
  sublabel,
  onChange,
  value,
  values,
  icon,
  simple,
  itemRenderer, // Renders dropdown components
  validations,
  ...rest
}) {
  const dropdownRef = useRef(null)
  const enhancedValues = values.map(selectValue => {
    if (typeof selectValue === 'object') {
      return {
        ...selectValue,
        ...(selectValue.border && { borderClass: classes.addBorder }),
      }
    }
    return selectValue
  })
  const selectedValue = value
    ? values.find(val => (typeof val === 'object' ? val.value : val) === value)
    : placeholder || 'Select a Value'

  const validationFunc = validations.find(validation => validation(value))
  const validationError = validationFunc && validationFunc(value)

  const hasValidError = value && validationError

  return (
    // Improve this
    <div
      className={cn(classes.inputWrapper, {
        [classes.simpleInputWrapper]: simple,
        [classes.danger]: hasValidError,
      })}
    >
      <InputBase
        disabled={disabled}
        select
        label={label}
        sublabel={sublabel}
        value={value || placeholder}
        defaultValue={value || placeholder}
        onChange={onChange}
        InputProps={{
          startAdornment: icon && (
            <InputAdornment className={classes.icon} position="start">
              {icon}
            </InputAdornment>
          ),
          classes: {
            disabled: disabled && classes.disabled,
            root: cn(classes.inputBase, { [classes.simpleInputBase]: simple }),
          },
        }}
        SelectProps={{
          IconComponent: KeyboardArrowDownOutlined,
          classes: {
            root: cn(classes.selectPadding, { [classes.simpleSelectPadding]: simple }),
          },
          MenuProps: {
            classes: {
              paper: classes.menu,
            },
            onRendered() {
              setTimeout(() => {
                const [, , el] = dropdownRef.current.childNodes

                const { bottom } = el.getBoundingClientRect()

                if (bottom >= window.innerHeight - 40) {
                  el.style.top = ''
                  el.style.bottom = '40px' // `${window.innerHeight  + 40}px`
                }
              }, 50)
            },
            innerRef: dropdownRef,
          },
          renderValue: () => selectedValue.label || selectedValue,
        }}
        {...rest}
      >
        {placeholder && (
          <MenuItem value={placeholder} disabled>
            {placeholder}
          </MenuItem>
        )}
        {enhancedValues.map(item => renderItems(item, itemRenderer))}
      </InputBase>
      {hasValidError && (
        <div className={classes.infoText}>
          {/* validation error message */}
          {validationError && <Tiny alertRed>{validationError}</Tiny>}
        </div>
      )}
    </div>
  )
}

SelectInput.defaultProps = {
  value: '',
  disabled: false,
  placeholder: 'Select Value',
  label: '',
  sublabel: '',
  onChange() {},
  icon: null,
  inputBaseStyles: null,
  selectStyles: null,
  inputWrapperStyles: null,
  simple: false,
  itemRenderer: null,
  validations: [],
}

SelectInput.propTypes = {
  placeholder: PropTypes.string,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  label: PropTypes.string,
  sublabel: PropTypes.string,
  onChange: PropTypes.func,
  values: PropTypes.array.isRequired,
  icon: PropTypes.node,
  inputBaseStyles: PropTypes.string,
  selectStyles: PropTypes.string,
  inputWrapperStyles: PropTypes.string,
  itemRenderer: PropTypes.func,
  simple: PropTypes.bool,
  validations: PropTypes.array,
}

export default React.memo(withStyles(styles)(SelectInput))

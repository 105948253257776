import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { CheckCircleOutline, InfoOutlined } from '@material-ui/icons'
import cn from 'classnames'

import InputWithAction from 'components/virtual/inputs/InputWithAction'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import useInputDebounce from 'hooks/useInputDebounce'
import Text from 'components/texts/Text'

const useStyles = makeStyles(theme => ({
  validation: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  validationIcon: {
    width: 16,
    marginRight: 4,
    '& + p': {
      paddingTop: 4,
    },
  },
  red: { color: theme.colors.redDarkest },
  green: { color: theme.colors.greenDarker },
  yellow: { color: theme.colors.yellowBase },
}))

function EditESPField(props) {
  const css = useStyles()
  const {
    values: { ESP },
  } = React.useContext(BuilderContainerContext)
  const { actions, input } = props
  const { onESPChange, onValidateESPField } = actions
  const [validationValue, setValidationValue] = useState(null)

  const {
    builder: { errors },
  } = React.useContext(BuilderContainerContext)
  const [esp, onInputChange] = useInputDebounce(input.espValue, onESPChange)

  if (input.name === 'email') return null

  const error = errors.verify(`espValue_${input.name}`)

  const onValidationClick = async () => {
    const isValid = await onValidateESPField()
    setValidationValue(isValid ? 'valid' : 'invalid')
  }

  const getValidationMessage = () => {
    let Icon
    let textProps
    let text
    let iconColor

    if (ESP && !esp) {
      Icon = InfoOutlined
      iconColor = 'yellow'
      textProps = { yellowDarker: true }
      text = 'Enter an ESP value to connect this form field with your ESP.'
    } else if (validationValue === 'valid') {
      Icon = CheckCircleOutline
      textProps = { greenDarker: true }
      text = 'Connection successful!'
      iconColor = 'green'
    } else if (validationValue === 'invalid') {
      iconColor = 'red'
      Icon = InfoOutlined
      textProps = { yellowDarker: true }
      text = 'We could not find a matching field value in your ESP. Enter the ESP value and validate the connection.'
    }

    if (!text) return "We'll automatically import the field value once your ESP is connected. If no match is found, manually enter the ESP value for this field above."

    return (
      <span className={css.validation}>
        <Icon className={cn(css.validationIcon, css[iconColor])} />
        <Text size={12} {...textProps}>
          {text}
        </Text>
      </span>
    )
  }

  const validationMessage = getValidationMessage()
  const validationError = validationValue === 'invalid' && validationMessage

  return (
    <InputWithAction
      value={esp}
      onChange={onInputChange}
      fullWidth
      tooltip={{
        text:
          'Match this form field to the corresponding value in your email marketing platform. Copy and paste the value exactly as it appears in your ESP.',
      }}
      label="Value (optional)"
      placeholder="ESP Value"
      buttonLabel="Validate"
      buttonAction={onValidationClick}
      helperText={validationMessage}
      error={esp && (error || validationError)}
    />
  )
}

EditESPField.propTypes = {
  actions: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
}

export default React.memo(EditESPField)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'
import { ReactComponent as CloseIcon } from 'images/icons/close.svg'
import { ReactComponent as ArrowIcon } from 'images/icons/arrow-icons/expand.svg'

import Text from 'components/texts/Text'
import { BuilderContainerContext } from './BuilderContainerContext'

const useStyles = makeStyles(theme => ({
  drawer: {
    overflowY: 'auto',
    padding: 32,
    top: 0,
    left: ({ isOpen }) => (isOpen ? 0 : '-100%'),
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    transition: 'left .2s ease-in-out',
    boxShadow: '0px 2px 4px rgba(90, 116, 129, 0.1)',
  },
  icon: {
    cursor: 'pointer',
    '& path': {
      fill: theme.colors.charcoalBase,
    },
  },
  arrow: {
    transform: 'rotate(-90deg) translate(1px, 0)',
    transition: 'transform .1s ease-in-out',
    '&:hover': {
      transform: 'rotate(-90deg) translate(1px, -3px)',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: 24,
    },
  },
  drawerTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 32,
    textTransform: 'capitalize',
  },
}))

function BuilderSidebarEditDrawer() {
  const context = React.useContext(BuilderContainerContext)
  const {
    builder: {
      drawer: {
        drawerComponent: DrawerComponent, isOpen, close, config,
      },
    },
  } = context
  const css = useStyles({ isOpen })

  return (
    <div className={css.drawer}>
      <div className={css.drawerTitle}>
        <div className={css.title}>
          {config?.withBackButton && (
            <ArrowIcon className={cn(css.icon, css.arrow)} onClick={close} />
          )}
          <Text charcoalBase size={20}>
            {config.title ?? 'Edit Field'}
          </Text>
        </div>
        {!config?.withBackButton && <CloseIcon className={css.icon} onClick={close} />}
      </div>
      {DrawerComponent && <DrawerComponent />}
    </div>
  )
}

BuilderSidebarEditDrawer.propTypes = {}

BuilderSidebarEditDrawer.defaultProps = {}

export default BuilderSidebarEditDrawer

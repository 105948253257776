import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'

import VirtualTheme from 'components/virtual/VirtualTheme'
import Text from 'components/texts/Text'
import Button from 'components/virtual/buttons/Button'

const useStyles = makeStyles(theme => ({
  dialog: {
    maxWidth: ({ full, allowResize }) => (full || allowResize ? 'unset' : 588),
  },
  container: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
    height: ({ full }) => (full ? '100%' : 'auto'),
    padding: ({ pad, bordered }) => {
      if (bordered) return 0
      if (pad) return 32
      return 0
    },
  },
  buttons: ({ pad, bordered }) => {
    const styles = {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > :last-child': {
        marginLeft: 12,
      },
    }

    if (bordered) {
      styles.padding = '16px 32px'
      styles.borderTop = `1px solid ${theme.colors.skyBase}`
    } else if (pad) {
      styles.marginTop = 32
    }

    return styles
  },
}))

function GenericModalBase(props) {
  const {
    title,
    full,
    allowResize,
    children,
    onCancel,
    onConfirm,
    cancelLabel,
    confirmLabel,
    show,
    canSave,
    bordered,
    pad,
    afterCloseCallback,
  } = props

  const css = useStyles({
    bordered,
    full,
    pad,
    allowResize,
  })

  const timeoutRef = useRef(null)
  const hasButtons = onCancel || onConfirm

  useEffect(() => {
    if (!show && afterCloseCallback) {
      timeoutRef.current = setTimeout(afterCloseCallback, 500)
    }

    return () => clearTimeout(timeoutRef.current)
  }, [show]) // eslint-disable-line

  return (
    <VirtualTheme>
      <Dialog
        fullScreen={full}
        open={show}
        PaperProps={{ classes: { root: css.dialog } }}
        // disabled this prop cuz we can't interact with elements outside this modal
        // example: MessageEditor component (uses draft-js) and its mounted via Portals
        // https://mui.com/components/modal/#focus-trap
        disableEnforceFocus
      >
        <div className={css.container}>
          {title && (
            <Text size={20} charcoalDarker>
              {title}
            </Text>
          )}

          {children}

          {hasButtons && (
            <div className={css.buttons}>
              {onCancel && (
                <Button onClick={onCancel} size="small" type="text" label={cancelLabel} />
              )}
              {onConfirm && (
                <Button onClick={onConfirm} size="small" label={confirmLabel} disabled={!canSave} />
              )}
            </div>
          )}
        </div>
      </Dialog>
    </VirtualTheme>
  )
}

GenericModalBase.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  cancelLabel: PropTypes.string,
  confirmLabel: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  afterCloseCallback: PropTypes.func,
  show: PropTypes.bool,
  canSave: PropTypes.bool,
  bordered: PropTypes.bool,
  full: PropTypes.bool,
  allowResize: PropTypes.bool,
  pad: PropTypes.bool,
}

GenericModalBase.defaultProps = {
  show: false,
  pad: true,
  afterCloseCallback: null,
  canSave: true,
  full: false,
  allowResize: false,
  cancelLabel: 'Cancel',
  confirmLabel: 'Confirm',
  bordered: false,
}

export default React.memo(GenericModalBase)

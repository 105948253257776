import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import cn from 'classnames'
import { ReactComponent as CloseIcon } from 'images/icons/close.svg'

import { colors } from 'GlobalTheme'
import { H4, P } from 'components/texts'

const BANNER_TYPES = {
  info: colors.foggy,
  warning: colors.coralLight,
  regular: colors.crystal,
  default: 'transparent',
}

const BORDER_TYPES = {
  warning: colors.lightRed,
}

const useStyles = makeStyles({
  close: {
    position: 'absolute',
    top: 16,
    right: 16,
    cursor: 'pointer',
  },
  content: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
  },
  cta: {
    paddingTop: ({ ctaPad }) => ctaPad || 8,
  },
  text: {
    display: 'grid',
    gridRowGap: '4px',
    marginBottom: 'auto',
  },
  wrapper: {
    position: 'relative',
    padding: ({ pad }) => (pad ? 16 : 0),
    backgroundColor: ({ type }) => BANNER_TYPES[type] || BANNER_TYPES.default,
    marginBottom: 8,
    outline: ({ type, border }) =>
      (border ? `1px solid ${BORDER_TYPES[type] || colors.ink}` : 'none'),
    '& > svg': {
      // icon, not close btn
      margin: '0 24px 0 8px',
      minWidth: 56,
      minHeight: 56,
    },
  },
})

function SMSInfoBanner(props) {
  const {
    title, type, cta, body, onClose, pad, classes, border, icon, ctaPad,
  } = props
  const css = useStyles({
    type,
    pad,
    ctaPad,
    border,
  })

  return (
    <div className={cn(css.wrapper, classes.wrapper)}>
      {icon}
      <div className={css.content}>
        <div className={css.text}>
          {title && (
            <H4 multiline>
              <strong>{title}</strong>
            </H4>
          )}
          {React.isValidElement(body) ? body : <P multiline>{body}</P>}
        </div>
        {cta
          && (React.isValidElement(cta) ? (
            <div className={css.cta}>{cta}</div>
          ) : (
            <div className={css.cta}>
              <Link to={cta.url}>{cta.label}</Link>
            </div>
          ))}
        {onClose && <CloseIcon className={css.close} onClick={onClose} />}
      </div>
    </div>
  )
}

SMSInfoBanner.propTypes = {
  title: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onClose: PropTypes.func,
  type: PropTypes.string,
  cta: PropTypes.string,
  pad: PropTypes.bool,
  ctaPad: PropTypes.string,
  border: PropTypes.bool,
  icon: PropTypes.node,
  classes: PropTypes.object,
}

SMSInfoBanner.defaultProps = {
  type: 'default',
  cta: null,
  title: null,
  onClose: null,
  pad: true,
  ctaPad: '8px',
  border: false,
  icon: null,
  classes: {},
}

export default React.memo(SMSInfoBanner)

import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'

import mojoZenWhiteHead from 'images/logos/mojo-zen-white-head.svg'
import zenShadow from 'images/logos/zen-shadow.svg'

const styles = theme => ({
  wrapper: {
    position: 'fixed',
    zIndex: 5000,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  background: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.colors.darkGreen,
  },
  imageWrapper: {
    width: 200,
    position: 'absolute',
    top: '30%',
    left: '50%',
    marginLeft: -100,
    [theme.breakpoints.down('sm')]: {
      width: 150,
      top: '35%',
      left: '60%',
    },
  },
  mobile: {
    width: 150,
    top: '35%',
    left: '60%',
  },
  tiger: {
    width: '100%',
    animation: '$float 1.5s infinite',
    '& + .tiger-shadow': {
      marginLeft: '12%',
      marginTop: 10,
      width: '76%',
      animation: '$shadow 1.5s ease infinite',
    },
  },
  '@keyframes float': {
    '0%': {
      transform: 'translateY(0px)',
    },
    '50%': {
      transform: 'translateY(-12px)',
    },
    '52%': {
      transform: 'translateY(-12px)',
    },
    '95%': {
      transform: 'translateY(0px)',
    },
    '100%': {
      transform: 'translateY(0px)',
    },
  },
  '@keyframes shadow': {
    '50%': {
      width: '70%',
      marginLeft: '15%',
    },
  },
})

function FloatingTiger({ classes, needsBackground }) {
  return (
    <div className={classes.wrapper}>
      <div className={classes.background} style={{ opacity: needsBackground ? '.5' : '0.05' }} />
      <div className={classes.imageWrapper}>
        <img className={classes.tiger} src={mojoZenWhiteHead} alt="" />
        <img className="tiger-shadow" src={zenShadow} alt="" />
      </div>
    </div>
  )
}

FloatingTiger.propTypes = {
  classes: PropTypes.object.isRequired,
  needsBackground: PropTypes.bool,
}

FloatingTiger.defaultProps = {
  needsBackground: false,
}

export default React.memo(withStyles(styles)(FloatingTiger))

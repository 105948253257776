import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { PageTitle } from 'components/builder/titles'
import RadioGroup from 'components/virtual/buttons/RadioGroup'

import BehaviorTimingDelay from './BehaviorTimingDelay'

const useStyles = makeStyles({
  group: {
    paddingLeft: 28,
  },
})

const TRIGGER_GROUP_1 = [
  { value: 'immediately', label: 'Show immediately on page load' },
  {
    value: 'delay',
    label: 'Delay loading. Display the signup form:',
    forceCheck: ['exit'],
  },
]

const TRIGGER_GROUP_2 = [
  { value: 'exit', label: 'When visitor is exiting the page' },
  { value: 'delay', label: 'After landing on the page for' },
]

function BehaviorTiming({ settings, onTimingChange }) {
  const css = useStyles()

  return (
    <div>
      <PageTitle textProps={{ size: 16 }} text="Timing" />
      <br />
      <RadioGroup
        values={TRIGGER_GROUP_1}
        value={settings.trigger.type}
        onChange={onTimingChange}
      />
      <div className={css.group}>
        <RadioGroup
          values={TRIGGER_GROUP_2}
          value={settings.trigger.type}
          disabled={settings.trigger.type === 'immediately'}
          onChange={onTimingChange}
        />
        <div className={css.group}>
          <BehaviorTimingDelay disabled={settings.trigger.type !== 'delay'} />
        </div>
      </div>
    </div>
  )
}

BehaviorTiming.propTypes = {
  settings: PropTypes.shape({
    trigger: PropTypes.shape({
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  onTimingChange: PropTypes.func.isRequired,
}

export default React.memo(BehaviorTiming)

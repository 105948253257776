import { CompositeDecorator } from 'draft-js'
import validator from 'validator'

import EditorElementsWrapper from './EditorElementsWrapper'

export default function decorators(mergeTagsAvailable) {
  const COUPON_REGEX = /^(?=.*[A-Z])(?=.*[0-9])([A-Z0-9!%]+)$/g
  const CUSTOM_TAG_REGEX = /\*\|[A-Z_]+_OR__[\w_ '!?]+\|\*/g

  const TAG_REGEX = new RegExp(`(\\*\\|)(${mergeTagsAvailable.join('|')})(\\|\\*)`, 'g')

  const URL_REGEX = {
    test: url =>
      validator.isURL(url, {
        require_host: true,
        require_protocol: true,
        require_valid_protocol: true,
      }),
  }

  // reference: https://draftjs.org/docs/advanced-topics-decorators
  function detectTags(regex) {
    return function detectTag(contentBlock, callback) {
      const text = contentBlock.getText()

      let matchArr
      let start

      matchArr = regex.exec(text)

      while (matchArr !== null) {
        start = matchArr.index
        callback(start, start + matchArr[0].length)

        matchArr = regex.exec(text)
      }
    }
  }

  function detect(REGEX) {
    return function matchDetector(contentBlock, callback) {
      const text = contentBlock.getText()
      let textCursor = 0

      text.split(/\s|\n/).forEach(word => {
        if (REGEX.test(word)) {
          const start = text.indexOf(word, textCursor)
          const end = start + word.length

          callback(start, end)
        }

        textCursor += word.length + 1
      })
    }
  }

  return new CompositeDecorator([
    {
      strategy: detectTags(CUSTOM_TAG_REGEX),
      component: EditorElementsWrapper,
      props: {
        type: 'customTag',
      },
    },
    {
      strategy: detectTags(TAG_REGEX),
      component: EditorElementsWrapper,
      props: {
        type: 'mergeTag',
        mergeTags: mergeTagsAvailable,
      },
    },
    {
      strategy: detect(COUPON_REGEX),
      component: EditorElementsWrapper,
      props: {
        type: 'coupon',
      },
    },
    {
      strategy: detect(URL_REGEX),
      component: EditorElementsWrapper,
      props: {
        type: 'link',
      },
    },
  ])
}

import React from 'react'
import { useHistory } from 'react-router-dom'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import ActionBanner from './ActionBanner'

function NoEmailFieldBanner() {
  const history = useHistory()
  const { values } = React.useContext(BuilderContainerContext)
  const { baseUrl } = values

  const onAddEmailFieldClick = () => history.push(`${baseUrl}/design/content`)

  return (
    <ActionBanner
      type="informational"
      align="flex-start"
      actionLabel="Add Email Field"
      action={onAddEmailFieldClick}
      buttonRight={false}
      title="Grow your email and SMS list together"
      body="Looks like your form isn't set up to collect email contacts. Add an email field and connect your ESP to capture email subscribers with this form."
    />
  )
}

export default React.memo(NoEmailFieldBanner)

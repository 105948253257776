import React from 'react'
import PropTypes from 'prop-types'

import GenericModal from 'components/virtual/modals/GenericModal'

function BeforeLeavingWarningModal(props) {
  const { show, onConfirm, onCancel } = props

  return (
    <GenericModal
      show={show}
      title="Heads up-you’re about to leave this page!"
      body="Be sure to save updates and changes to your signup form before you leave this page."
      confirmLabel="Save Changes"
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  )
}

BeforeLeavingWarningModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
}

export default React.memo(BeforeLeavingWarningModal)

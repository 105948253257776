import React, { useState } from 'react'
import PropTypes from 'prop-types'

import GenericModalBase from 'components/virtual/modals/GenericModalBase'
import { Input } from 'components/virtual/inputs'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import useInputDebounce from 'hooks/useInputDebounce'

function LightboxBuilderNameChangeModal({ show, onClose, firstTime }) {
  const { lightboxSettings } = React.useContext(BuilderContainerContext)
  const settings = lightboxSettings.getSettings()
  const [name, setName] = useState(settings.name)

  const [inputValue, setInputValue] = useInputDebounce(name, setName)

  const onCloseClick = () => {
    setTimeout(() => {
      // Reset after the modal disappears
      setInputValue({ target: { value: settings.name } })
    }, 200)
    onClose()
  }

  const onNameChange = () => {
    settings.name = inputValue
    onCloseClick()
  }

  const formType = settings?.type
  let formattedFormType = 'Pop-Up'
  if (formType === 'embed') formattedFormType = 'Embed'

  const title = firstTime ? `Name Your ${formattedFormType}` : `Edit ${formattedFormType} Name`

  return (
    <GenericModalBase
      show={show}
      title={title}
      confirmLabel={firstTime ? 'Next' : 'Save'}
      onConfirm={onNameChange}
      onCancel={onCloseClick}
    >
      <Input
        onChange={setInputValue}
        value={inputValue}
        label={`${formattedFormType} Name`}
        helperText="This won’t appear on your published form. You can always change the name later!"
      />
    </GenericModalBase>
  )
}

LightboxBuilderNameChangeModal.propTypes = {
  show: PropTypes.bool,
  firstTime: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

LightboxBuilderNameChangeModal.defaultProps = {
  show: false,
}

export default React.memo(LightboxBuilderNameChangeModal)

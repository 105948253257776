import React, {
  useEffect, useRef,
} from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Text from 'components/texts/Text'
import Button from 'components/virtual/buttons/Button'
import shopifyIcon from 'images/icons/store-integrations/shopify.svg'
import { ReactComponent as SyncClockwise } from 'images/icons/sync-clockwise.svg'
import { ReactComponent as Logo } from 'images/nav-bar/mojo-white-tiger-logo.svg'
import useHasIntegration from 'hooks/useHasIntegration'

const useStyles = makeStyles(theme => ({
  bodyWrapper: {
    margin: '48px 24px 0 24px',
  },
  title: {
    marginBottom: 8,
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '32px 0',
    alignItems: 'center',
  },
  logoIcon: {
    height: 47,
  },
  syncIcon: {
    height: 24,
    width: 24,
    marginRight: '8px',
  },
  connectButton: {
    width: '100%',
    margin: '32px 0 16px 0',
  },
  connectButtonText: {
    textAlign: 'center',
    marginBottom: 83,
  },
  actions: {
    alignItems: 'center',
    borderTop: `1px solid ${theme.colors.skyBase}`,
    display: 'flex',
    height: 84,
    justifyContent: 'space-between',
    padding: 24,
    width: '100%',
  },
}))

function ConnectShopify({
  onNext, onPrev,
}) {
  const classes = useStyles()
  const shopifyConnectionCheck = useRef(null)
  const {
    hasIntegrations,
    reload,
  } = useHasIntegration()


  const handleShopifyConnect = () => {
    clearInterval(shopifyConnectionCheck.current)
    shopifyConnectionCheck.current = setInterval(() => {
      reload()
    }, 5000)
  }

  useEffect(() => () => {
    clearInterval(shopifyConnectionCheck.current)
  }, [])

  useEffect(() => {
    if (hasIntegrations) {
      clearInterval(shopifyConnectionCheck.current)
      onNext()
    }
  }, [hasIntegrations, onNext])

  return (
    <>
      <div className={classes.bodyWrapper}>
        <div className={classes.title}>
          <Text size={20}>Connect Shopify</Text>
        </div>
        <Text size={16}>
          Follow these steps to connect your shop.
          &nbsp;
          <a href="https://help.dojomojo.com/hc/en-us/articles/360008892612-Integrating-DojoMojo-Shopify" target="_blank" rel="noopener noreferrer">
            Learn More
          </a>
        </Text>

        <div className={classes.imageContainer}>
          <img className={classes.logoIcon} src={shopifyIcon} alt="shopify" />
          <SyncClockwise className={classes.syncIcon} />
          <Logo className={classes.logoIcon} />
        </div>

        <Text size={14}>
          1. Click “Connect” to open the Shopify App Store.
          <br />
          2. Install the DojoMojo app on your store.
          <br />
          3. You will be redirected back here after installing the app.
        </Text>
        <a href="https://apps.shopify.com/dojomojo" target="_blank" rel="noopener noreferrer" onClick={handleShopifyConnect}>
          <Button className={classes.connectButton}>Connect</Button>
        </a>
        <div className={classes.connectButtonText}>
          <Text size={12}>By clicking “Connect”, you will be redirected to Shopify.</Text>
        </div>
      </div>
      <div className={classes.actions}>
        <Button label="Previous" onClick={onPrev} size="small" type="text" />
        <Button onClick={onNext} label="We don't use Shopify" type="text" />
      </div>
    </>
  )
}

ConnectShopify.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
}

export default ConnectShopify

import React from 'react'
import PropTypes from 'prop-types'

import DropdownBase from './DropdownBase'
import DropdownOutlined from './DropdownOutlined'
import DropdownNoBorder from './DropdownNoBorder'

function Dropdown(props) {
  const { type } = props

  let DropdownComponent = DropdownOutlined

  if (type === 'noborder') DropdownComponent = DropdownNoBorder

  return (
    <DropdownBase {...props}>
      <DropdownComponent />
    </DropdownBase>
  )
}

Dropdown.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,

  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  textProps: PropTypes.object,
}

Dropdown.defaultProps = {
  disabled: false,
  fullWidth: false,
  type: 'outlined',
  defaultValue: null,
  label: null,
  placeholder: null,
  textProps: null,
  value: null,
}

export default React.memo(Dropdown)

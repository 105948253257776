import { getSessionId } from 'utils/metrics'
import * as ErrorReporting from './ErrorReporting'

export { default as GoogleAnalytics } from './GoogleAnalytics'

export default function initAnalytics() {
  const sessionId = getSessionId()
  ErrorReporting.init(sessionId)
}

export function setAnalyticsUser(profile = {}) {
  ErrorReporting.setUser(profile)
}

export function setAnalyticsBrand(brand = {}) {
  ErrorReporting.setBrand(brand)
}

export { default as Intercom } from './Intercom'

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

const useStyles = makeStyles(theme => ({
  integrationImg: {
    width: 134,
    height: 74,
    backgroundColor: theme.colors.skyBase,
    border: `1px solid ${theme.colors.skyBase}`,
    borderRadius: 4,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
}))

const NoIntegrationPlaceholder = () => (
  <svg width="135" height="75" viewBox="0 0 135 75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <rect x="0.908936" y="1.1665" width="133" height="73" rx="3.5" fill="#DFE9ED" />
      <path
        d="M78.0756 45.1123C76.7245 45.1123 75.5156 45.6545 74.5912 46.5039L61.9156 39.0039C62.0045 38.5882 62.0756 38.1725 62.0756 37.7388C62.0756 37.3051 62.0045 36.8894 61.9156 36.4737L74.4489 29.046C75.4089 29.9496 76.6712 30.5099 78.0756 30.5099C81.0267 30.5099 83.4089 28.0882 83.4089 25.0882C83.4089 22.0882 81.0267 19.6665 78.0756 19.6665C75.1245 19.6665 72.7423 22.0882 72.7423 25.0882C72.7423 25.5219 72.8134 25.9376 72.9023 26.3533L60.3689 33.781C59.4089 32.8773 58.1467 32.3171 56.7423 32.3171C53.7912 32.3171 51.4089 34.7388 51.4089 37.7388C51.4089 40.7388 53.7912 43.1605 56.7423 43.1605C58.1467 43.1605 59.4089 42.6002 60.3689 41.6966L73.0267 49.2147C72.9378 49.5942 72.8845 49.9918 72.8845 50.3894C72.8845 53.299 75.2134 55.6665 78.0756 55.6665C80.9378 55.6665 83.2667 53.299 83.2667 50.3894C83.2667 47.4798 80.9378 45.1123 78.0756 45.1123Z"
        fill="#86A0AC"
      />
    </g>
    <rect x="0.908936" y="1.1665" width="133" height="73" rx="3.5" stroke="#DFE9ED" />
  </svg>
)

function ESPIntegrationImage() {
  const { values } = React.useContext(BuilderContainerContext)
  const { ESP } = values

  const css = useStyles()

  if (!ESP) return <NoIntegrationPlaceholder />

  return <div className={css.integrationImg} style={{ backgroundImage: `url('${ESP.img}')` }} />
}

export default React.memo(ESPIntegrationImage)

const beltMapping = {
  // Trials
  trial: {
    label: 'Premium Trial',
    onsite: true,
  },
  'post-trial-paywall': {
    label: 'Post-Trial Paywall',
    onsite: true,
  },

  // Original Plans
  white: {
    label: 'Basic',
    onsite: true,
  },
  greenFixed: {
    label: 'Essential: Monthly',
    interval: 'monthly',
    onsite: true,
  },
  greenFixed12: {
    // Deprecated: Replacing with essential-12months-upfront
    label: 'Essential: Annual Site',
    interval: 'annually',
    onsite: true,
  },
  greenFixedUpfront: {
    // Deprecated: Replacing with new 6/12 month Plans
    label: 'Essential: Upfront',
    interval: 'upfront',
  },
  greenFixedB3G1: {
    // Deprecated: Replacing with new B3G1 plans
    label: 'Essential: Buy 3, Get 1',
    interval: 'upfront',
  },
  black: {
    label: 'Premium: Monthly',
    interval: 'monthly',
    onsite: true,
  },
  black12: {
    // Deprecated: Replacing with premium-12month-upfront
    label: 'Premium: Annual Site',
    interval: 'annually',
    onsite: true,
  },
  blackUpfront: {
    // Deprecated: Replacing with new 6/12 month Plans
    label: 'Premium: Upfront',
    interval: 'upfront',
  },
  blackB3G1: {
    // Deprecated: Replacing with new B3G1 plans
    label: 'Premium: Buy 3, Get 1',
    interval: 'upfront',
  },
  elite: {
    label: 'Elite',
  },

  // Monthly Plans
  'pro-monthly': {
    label: 'Pro: Monthly',
    interval: 'monthly',
    onsite: true,
  },

  // Buy 3 Get 1 Plans
  'essential-buy3-get1-monthly': {
    label: 'Essential: Buy 3, Get 1 - Monthly Billing',
    interval: 'monthly',
    b3g1: true,
  },
  'essential-buy3-get1-upfront': {
    label: 'Essential: Buy 3, Get 1 - Upfront Billing',
    interval: 'upfront',
    b3g1: true,
    onsite: true,
  },
  'premium-buy3-get1-monthly': {
    label: 'Premium: Buy 3, Get 1 - Monthly Billing',
    interval: 'monthly',
    b3g1: true,
  },
  'premium-buy3-get1-upfront': {
    label: 'Premium: Buy 3, Get 1 - Upfront Billing',
    interval: 'upfront',
    b3g1: true,
    onsite: true,
  },
  'pro-buy3-get1-monthly': {
    label: 'Pro: Buy 3, Get 1 - Monthly Billing',
    interval: 'monthly',
    b3g1: true,
  },
  'pro-buy3-get1-upfront': {
    label: 'Pro: Buy 3, Get 1 - Upfront Billing',
    interval: 'upfront',
    b3g1: true,
    onsite: true,
  },

  // 6 Month Plans
  'essential-6months-monthly': {
    label: 'Essential: 6 Months - Monthly Billing',
    interval: 'monthly',
  },
  'essential-6months-quarterly': {
    label: 'Essential: 6 Months - Quarterly Billing',
    interval: 'quarterly',
  },
  'essential-6months-upfront': {
    label: 'Essential: 6 Months - Upfront Billing',
    interval: 'bi-annually',
  },
  'premium-6months-monthly': {
    label: 'Premium: 6 Months - Monthly Billing',
    interval: 'monthly',
  },
  'premium-6months-quarterly': {
    label: 'Premium: 6 Months - Quarterly Billing',
    interval: 'quarterly',
  },
  'premium-6months-upfront': {
    label: 'Premium: 6 Months - Upfront Billing',
    interval: 'bi-annually',
  },
  'pro-6months-monthly': {
    label: 'Pro: 6 Months - Monthly Billing',
    interval: 'monthly',
  },
  'pro-6months-quarterly': {
    label: 'Pro: 6 Months - Quarterly Billing',
    interval: 'quarterly',
  },
  'pro-6months-upfront': {
    label: 'Pro: 6 Months - Upfront Billing',
    interval: 'bi-annually',
  },

  // 12 Month Plans
  'essential-12months-monthly': {
    label: 'Essential: 12 Months - Monthly Billing',
    interval: 'monthly',
  },
  'essential-12months-quarterly': {
    label: 'Essential: 12 Months - Quarterly Billing',
    interval: 'quarterly',
  },
  'essential-12months-bi-annual': {
    label: 'Essential: 12 Months - Bi-annual Billing',
    interval: 'bi-annually',
  },
  'essential-12months-upfront': {
    label: 'Essential: 12 Months - Upfront Billing',
    interval: 'annually',
  },
  'premium-12months-monthly': {
    label: 'Premium: 12 Months - Monthly Billing',
    interval: 'monthly',
  },
  'premium-12months-quarterly': {
    label: 'Premium: 12 Months - Quarterly Billing',
    interval: 'quarterly',
  },
  'premium-12months-bi-annual': {
    label: 'Premium: 12 Months - Bi-annual Billing',
    interval: 'bi-annually',
  },
  'premium-12month-upfront': {
    label: 'Premium: 12 Months - Upfront Billing',
    interval: 'annually',
  },
  'pro-12months-monthly': {
    label: 'Pro: 12 Months - Monthly Billing',
    interval: 'monthly',
  },
  'pro-12months-quarterly': {
    label: 'Pro: 12 Months - Quarterly Billing',
    interval: 'quarterly',
  },
  'pro-12months-bi-annual': {
    label: 'Pro: 12 Months - Bi-annual Billing',
    interval: 'bi-annually',
  },
  'pro-12month-upfront': {
    label: 'Pro: 12 Months - Upfront Billing',
    interval: 'annually',
  },

  // Group Plans
  'essential-group-plan': {
    label: 'Essential: Group Plan',
  },
  'premium-group-plan': {
    label: 'Premium: Group Plan',
  },
  'pro-group-plan': {
    label: 'Pro: Group Plan',
  },

  // Custom Plans
  'essential-custom': {
    label: 'Essential: Custom',
  },
  'premium-custom': {
    label: 'Premium: Custom',
  },
  'pro-custom': {
    label: 'Pro: Custom',
  },
}

// Returns brand belt label
const getPlanLabel = plan => (beltMapping[plan] ? beltMapping[plan].label : 'Basic')

// Parses brands Stripe belt to the basic belt version
const cleanPlanText = plan => {
  if (plan.startsWith('pro')) {
    return 'pro'
  }
  if (plan.includes('black') || plan.includes('premium') || plan.includes('elite')) {
    return 'black'
  }
  if (plan.includes('greenFixed') || plan.includes('essential')) {
    return 'greenFixed'
  }
  return plan.includes('trial') ? plan : 'white'
}

// Offsite plans are contracted manually offsite. In these cases we want to sometimes
// treat them differently than the onsite plans. eg: hide upgrade button
const isOffsitePlan = plan => {
  const offsitePlans = Object.entries(beltMapping)
    .filter(([, value]) => !value.onsite)
    .map(([value]) => value)
  return plan && offsitePlans.includes(plan)
}

// Determine if a brand is isTrialing
const isTrialing = (brand, customer) => {
  const subscription = (customer
      && customer.subscriptions
      && customer.subscriptions.data
      && customer.subscriptions.data.length
      && customer.subscriptions.data[0])
    || null
  return (
    (subscription && subscription.plan.id === 'trial')
    || (brand.belt === 'trial' && new Date(brand.trial_end) > new Date())
  )
}

// Determin if brand isTrialing and hasn't upgraded yet
const isTrialingAndNotUpgraded = ({
  belt,
  stripe_belt,
  trial_end,
  extended_trial_end,
  offer_trial_end,
}) =>
  belt === 'trial'
  && stripe_belt === 'trial'
  && (new Date() < new Date(trial_end)
    || new Date() < new Date(extended_trial_end)
    || new Date() < new Date(offer_trial_end))

// Determine if a brand is isDelinquent
const isDelinquent = (brand, customer) =>
  !['trial', 'post-trial-paywall', 'white'].includes(brand.belt)
  && customer.delinquent
  && customer.invoices[0].status !== 'void'

const planPrices = {
  basic: 0,
  essential: 199,
  premium: 499,
  pro: 899,
}

export {
  beltMapping,
  getPlanLabel,
  cleanPlanText,
  isOffsitePlan,
  isTrialing,
  isDelinquent,
  isTrialingAndNotUpgraded,
  planPrices,
}

/* ===== PURPOSE OF THIS FILE =====
 * In special cases (like Shopify OAuth flow) we must allow the route/page to
 * move forward whether we are logged in or not. This reducer is a light weight version
 * that is more forgiving than the standard "ReduceLoaders" component.
 */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { loadProfile } from 'actions/profile'
import { loadCurrentBrand } from 'actions/currentBrand'

import { setImpersonateHeader, setBrandHeader } from 'utils/initAxios'
import { Spinner } from 'components/spinners'

function ReducersLoaderOptional({ children }) {
  const [skipLoadingUserAndBrand, setSkipLoadingUserAndBrand] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const dispatch = useDispatch()
  const profile = useSelector(state => state.profile)
  const currentBrand = useSelector(state => state.currentBrand)

  const userId = profile.id
  const brandId = currentBrand.id

  useEffect(() => {
    const impersonateMatch = window.location.search.match(
      /^\?(?:.*&)?impersonating=([\da-f-]+)(?:&.*|$)/
    )
    let impersonating = impersonateMatch && impersonateMatch[1]

    try {
      // if impersonation token specified in URL, save it
      if (impersonating) {
        sessionStorage.setItem('impersonating', impersonating)
      } else {
        // otherwise check if it was already saved
        impersonating = sessionStorage.getItem('impersonating')
      }
    } catch (er) {
      console.log('sessionStorage unavailable')
    }

    try {
      if (impersonating) setImpersonateHeader(impersonating)
      dispatch(
        loadProfile(impersonating, () => {
          setSkipLoadingUserAndBrand(true)
        })
      )
    } catch (e) {
      // User is not logged in continue gracefully
    }
  }, [dispatch])

  useEffect(() => {
    if (userId) {
      const brandMatch = window.location.search.match(/^\?(?:.*&)?brandId=(\d+)(?:&.*|$)/)
      const overrideBrandId = brandMatch && brandMatch[1]

      const { credentials, mostRecentBrands } = profile
      if (!credentials || !credentials.length) {
        window.location.replace('/app/registrations/brand-setup')
        return
      }

      // If override brand ID specified but user does not have access to it
      if (
        overrideBrandId
        && !credentials.some(
          ({ brandId: credentialBrandId }) => Number(credentialBrandId) === Number(overrideBrandId)
        )
      ) {
        window.location.replace('/app/profile/switch-brand')
        return
      }

      const fallbackBrandId = mostRecentBrands && mostRecentBrands[0] && mostRecentBrands[0].brandId
      setBrandHeader(profile.uid, overrideBrandId, fallbackBrandId)
      dispatch(loadCurrentBrand())
    }
  }, [dispatch, profile, userId])

  useEffect(() => {
    if (brandId) setLoaded(true)
  }, [dispatch, brandId])

  if (!loaded && !skipLoadingUserAndBrand) return <Spinner />

  return children
}

export default React.memo(ReducersLoaderOptional)

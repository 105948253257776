import React from 'react'

import FormPages from './form-pages'
import Fields from './fields'

function LightboxBuilderFields() {
  return (
    <>
      <FormPages />
      <Fields />
    </>
  )
}

export default React.memo(LightboxBuilderFields)

import React from 'react'
import PropTypes from 'prop-types'
import ButtonBase from '@material-ui/core/ButtonBase'
import { AddCircleOutlineOutlined as AddIcon } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

import Text from 'components/texts/Text'

const useStyles = makeStyles(theme => ({
  icon: {
    transform: ({ open }) => (open ? 'rotate(45deg)' : 'none'),
    transition: 'transform .2s linear',
    marginRight: 8,
    fill: ({ isNavy, disabled }) => {
      let color = 'blueBase'
      if (isNavy) color = 'navyBase'
      if (disabled) color = 'skyDarker'
      return theme.colors[color]
    },
  },
}))

function AddButton(props) {
  const {
    buttonClass, onClick, label, color, disabled,
  } = props
  const isNavy = color === 'secondary'
  const css = useStyles({ isNavy, disabled })

  let textProps = { blueBase: true }

  if (isNavy) textProps = { navyBase: true }
  if (disabled) textProps = { skyDarker: true }

  return (
    <ButtonBase onClick={onClick} className={buttonClass} disabled={disabled}>
      <AddIcon className={css.icon} />
      <Text {...textProps}>{label}</Text>
    </ButtonBase>
  )
}

AddButton.propTypes = {
  buttonClass: PropTypes.string,
  label: PropTypes.string.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

AddButton.defaultProps = {
  buttonClass: '',
  disabled: false,
  color: 'secondary',
}

export default React.memo(AddButton)

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import cn from 'classnames'
import { CheckCircle } from '@material-ui/icons'

import Text from 'components/texts/Text'
import Switch from 'components/virtual/selectors/Switch'

const useStyles = makeStyles(theme => ({
  integrationSwitch: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.colors.skyBase}`,
    borderRadius: 4,
    flex: '1 1 calc(33% - 24px)',
    maxWidth: 588,
    height: 80,
    padding: 16,
    transition: 'border .1s linear',
    backgroundColor: ({ status }) =>
      theme.colors[status === 'notsupported' ? 'skyLighter' : 'white'],
  },
  integration: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  focused: {
    border: `1px solid ${theme.colors.blueBase}`,
  },
  imageBg: {
    marginRight: 8,
    width: 48,
    height: 48,
    borderRadius: 4,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  title: {
    '& > :first-child': {
      marginBottom: 4,
    },
  },
  badge: {
    marginLeft: 8,
    position: 'relative',
    '& svg': {
      position: 'absolute',
      color: ({ status }) => theme.colors[status === 'ready' ? 'greenLight' : 'charcoalBase'],
    },
  },
}))

const BADGE_MAP = {
  ready: <CheckCircle />,
}

const COLORS = {
  ready: { greenDarker: true },
  standby: { charcoalBase: true },
  notsupported: { redBase: true },
}

function IntegrationSwitch(props) {
  const {
    image, title, statusText, status, enabled, onEnable,
  } = props
  const [focus, setFocus] = useState(false)
  const css = useStyles({ status })

  const onClick = () => setFocus(true)
  const onBlur = () => setFocus(false)

  const statusTextColor = COLORS[status]
  const badge = BADGE_MAP[status]

  const canClick = status !== 'notsupported'
  const isFocused = enabled || (canClick && focus)

  return (
    <ClickAwayListener onClickAway={onBlur}>
      <div onClick={onClick} className={cn(css.integrationSwitch, { [css.focused]: isFocused })}>
        <div className={css.integration}>
          <div className={css.imageBg} style={{ backgroundImage: `url(${image})` }} />
          <div className={css.title}>
            <Text>
              {title}
              {badge && <span className={css.badge}>{badge}</span>}
            </Text>
            <Text size={12} {...statusTextColor}>
              {statusText}
            </Text>
          </div>
        </div>
        {status !== 'notsupported' && <Switch onChange={onEnable} checked={enabled} />}
      </div>
    </ClickAwayListener>
  )
}

IntegrationSwitch.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  statusText: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,

  enabled: PropTypes.bool.isRequired,
  onEnable: PropTypes.func.isRequired,
}

export default React.memo(IntegrationSwitch)

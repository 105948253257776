import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import { P } from 'components/texts'
import SearchWrapper from './SearchWrapper'

const useStyles = makeStyles(theme => ({
  wrapper: props => ({
    alignItems: 'flex-start',
    backgroundColor: 'white',
    position: 'absolute',
    top: props.isMobile ? 0 : '100%',
    left: 0,
    width: 'calc(100% - 1px)',
    marginLeft: 1,
    boxShadow: theme.shadows.slice(-1),
  }),
  word: {
    cursor: 'pointer',
    '&:not(:first-child)': {
      marginLeft: 24,
    },
  },
}))

function PreviousSearchSuggestions(props) {
  const { suggestions, onSuggestionClick } = props
  const css = useStyles(props)

  if (!suggestions) return null

  const onItemClick = suggestion => e => {
    e.preventDefault()
    onSuggestionClick(suggestion)
  }

  const previousSearchWords = suggestions.map(word => (
    <span key={word} className={css.word} onClick={onItemClick(word)}>
      <strong>{word}</strong>
    </span>
  ))

  return (
    <SearchWrapper title="Search Again" customClasses={css.wrapper}>
      <P azure>{previousSearchWords}</P>
    </SearchWrapper>
  )
}

PreviousSearchSuggestions.propTypes = {
  onSuggestionClick: PropTypes.func.isRequired,
  suggestions: PropTypes.arrayOf(PropTypes.string),
}

PreviousSearchSuggestions.defaultProps = {
  suggestions: [],
}

export default React.memo(PreviousSearchSuggestions)

// Design: https://www.figma.com/file/Jzn9QRV8FZsCqcZWdMXTYR/Component-Library?node-id=0%3A1

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { CheckCircleOutlineOutlined, ErrorOutlineOutlined, InfoOutlined } from '@material-ui/icons'

import Banner from 'components/virtual/banners/Banner'

const useStyles = makeStyles(theme => ({
  icon: {
    paddingRight: props => (props.smaller ? 12 : 16),
  },
  statusBanner: {
    alignItems: props => props.align,
    display: 'flex',
    width: '100%',
    position: 'relative',
  },
  green: {
    color: theme.colors.greenBase,
  },
  grey: {
    color: theme.colors.charcoalBase,
  },
  indigo: {
    color: theme.colors.navyBase,
  },
  red: {
    color: theme.colors.redBase,
  },
  yellow: {
    color: theme.colors.yellowBase,
  },
}))

function StatusBanner(props) {
  const {
    align, children, close, fullWidth, onClose, open, status, padding,
  } = props
  const classes = useStyles({ align })

  const statusMap = {
    alert: { color: 'yellow', icon: <ErrorOutlineOutlined className={classes.yellow} /> },
    branded: { color: 'indigo', icon: <InfoOutlined className={classes.indigo} /> },
    error: { color: 'red', icon: <ErrorOutlineOutlined className={classes.red} /> },
    informational: { color: 'grey', icon: <InfoOutlined className={classes.grey} /> },
    success: { color: 'green', icon: <CheckCircleOutlineOutlined className={classes.green} /> },
  }

  return (
    <Banner
      padding={padding}
      align={align}
      close={close}
      color={statusMap[status].color}
      fullWidth={fullWidth}
      onClose={onClose}
      open={open}
    >
      <div className={classes.statusBanner}>
        <div className={classes.icon}>{statusMap[status].icon}</div>
        {children}
      </div>
    </Banner>
  )
}

StatusBanner.propTypes = {
  align: PropTypes.string,
  children: PropTypes.any,
  padding: PropTypes.number,
  close: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  status: PropTypes.oneOf(['alert', 'branded', 'error', 'informational', 'success']).isRequired,
}

StatusBanner.defaultProps = {
  align: 'stretch',
  children: null,
  close: false,
  padding: 16,
  fullWidth: false,
  onClose: () => {},
  open: true,
}

export default React.memo(StatusBanner)

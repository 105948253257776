import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

const { REACT_APP_SMS_VERIFY_FORM_LINK: smsVerifyFormUrl } = process.env

/**
 * Hook to check if SMS verification is needed - may change drastically with september Bandwidth
 * verification deadline
 */
const useSmsVerify = () => {
  const currentBrand = useSelector(state => state.currentBrand)
  const userProfile = useSelector(state => state.profile)

  const key = `smsVerifyModalNeeded-${currentBrand.id}`

  const [isSmsVerifyModalNeeded, setIsSmsVerifyModalNeeded] = useState(false)

  useEffect(() => {
    const modalNeeded = localStorage.getItem(key)
    if (modalNeeded) {
      setIsSmsVerifyModalNeeded(true)
    }
  }, [key])

  const dismissSmsVerifyModal = () => {
    localStorage.removeItem(key)
    setIsSmsVerifyModalNeeded(false)
  }

  const openVerifyLink = () => {
    window.open(
      `${smsVerifyFormUrl}#brand_id=${currentBrand.id}&user_id=${userProfile.id}`,
      '_blank'
    )
    setIsSmsVerifyModalNeeded(false)
  }

  const setSmsVerifyFlag = () => {
    localStorage.setItem(key, 'true')
  }

  return {
    isSmsVerifyModalNeeded,
    dismissSmsVerifyModal,
    openVerifyLink,
    setSmsVerifyFlag,
  }
}

export default useSmsVerify

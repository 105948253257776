import React from 'react'
import ReactDOM from 'react-dom'
import { makeStyles, ButtonBase, ClickAwayListener } from '@material-ui/core'
import {
  Close as CloseIcon,
  FormatItalic as ItalicIcon,
  FormatBold as BoldIcon,
  FormatListBulleted as BulletPointIcon,
} from '@material-ui/icons'
import { Editor, RichUtils } from 'draft-js'
import cn from 'classnames'

import Text from 'components/texts/Text'
import VirtualTheme from 'components/virtual/VirtualTheme'
import Button from 'components/virtual/buttons/Button'

import { MessageEditorContext } from 'contexts'

const useStyles = makeStyles(theme => ({
  messageEditor: {
    zIndex: 4000,
    width: 520,
    position: 'fixed',
    bottom: props => {
      if (props.open) {
        if (props.hide) return -((48 /* rich utils */ + 350 /* content */ + 1) /* border */)
        return 0
      }
      return '-100%'
    },
    right: '10vw', // TODO: do some crazy calculation just so it's not always 100px from the right
    transition: 'bottom 0.2s cubic-bezier(.17,.67,.83,.67)', // TODO: fix transition when open = true, hide = true|false
    filter: 'drop-shadow(0px 2px 12px rgba(90, 116, 129, 0.2))',
    '& > div': {
      padding: 12,
    },
  },
  messageEditorHeader: {
    cursor: 'pointer',
    backgroundColor: theme.colors.blueLight,
    display: 'flex',
    height: 40,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeIcon: {
    color: 'white',
  },
  messageEditorContent: {
    padding: 12,
    backgroundColor: 'white',
    height: 350,
    overflowY: 'auto',
  },
  messageEditorRichUtils: {
    borderTop: `1px solid ${theme.colors.skyDarker}`,
    backgroundColor: 'white',
    height: 48,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  submitButton: {
    gap: '12px',
    display: 'flex',
    alignItems: 'center',
  },
  formatButton: {
    height: 32,
    width: 32,
    borderRadius: 4,
    overflow: 'hidden',
    '&:not(:last-of-type)': {
      marginRight: 4,
    },
  },
  icon: {
    color: theme.colors.charcoalDarker,
  },
  iconSelected: {
    backgroundColor: theme.colors.skyDarker,
  },
}))

// TODO: might want to change component name
function MessageEditor() {
  const { values, actions } = React.useContext(MessageEditorContext)
  const {
    open,
    hide,
    editorConfig,
    editorState,
    editorRef,
    conversationMeta: { limit: convoLimit, count: convoCount, outOfMessages },
  } = values

  const {
    onClose,
    onHide,
    onToggleHide,
    onEditorStateChange,
    onEditorStateKeyCommand,
    onTab,
    onBoldClick,
    onItalicClick,
    onToggleBulletPointClick,
    onEditorFocus,
    onSendMessage,
  } = actions

  const css = useStyles({ open, hide })
  const withClickAwayListener = open && !hide
  const MessageEditorWrapper = withClickAwayListener ? ClickAwayListener : React.Fragment

  const limitString = convoLimit && outOfMessages ? 'Out of' : `${convoCount}/${convoLimit}`
  const limit = convoLimit && <Text charcoalDarker>{`${limitString} messages`}</Text>

  const inlineStyle = editorState.getCurrentInlineStyle()
  const isBold = inlineStyle.has('BOLD')
  const isItalic = inlineStyle.has('ITALIC')
  const isList = RichUtils.getCurrentBlockType(editorState) === 'unordered-list-item'

  return ReactDOM.createPortal(
    <MessageEditorWrapper onClickAway={onHide}>
      <div className={css.messageEditor}>
        <div className={css.messageEditorHeader} onClick={onToggleHide}>
          <Text white>{editorConfig.title}</Text>
          <ButtonBase className={css.icon} onClick={onClose}>
            <CloseIcon className={css.closeIcon} />
          </ButtonBase>
        </div>
        <div className={css.messageEditorContent} onClick={onEditorFocus}>
          <Editor
            ref={editorRef}
            editorState={editorState}
            onChange={onEditorStateChange}
            handleKeyCommand={onEditorStateKeyCommand}
            onTab={onTab}
            readOnly={outOfMessages}
          />
        </div>
        <div className={css.messageEditorRichUtils}>
          <div className={css.submitButton}>
            <VirtualTheme>
              <Button
                disabled={outOfMessages}
                label={editorConfig.sendButtonLabel || 'Send Request'}
                onClick={onSendMessage}
              />
            </VirtualTheme>
            <div>{limit}</div>
          </div>
          <div className={css.formatButtons}>
            <ButtonBase className={css.formatButton} onClick={onBoldClick}>
              <BoldIcon className={cn(css.icon, { [css.iconSelected]: isBold })} />
            </ButtonBase>
            <ButtonBase className={css.formatButton} onClick={onItalicClick}>
              <ItalicIcon className={cn(css.icon, { [css.iconSelected]: isItalic })} />
            </ButtonBase>
            <ButtonBase className={css.formatButton} onClick={onToggleBulletPointClick}>
              <BulletPointIcon className={cn(css.icon, { [css.iconSelected]: isList })} />
            </ButtonBase>
          </div>
        </div>
      </div>
    </MessageEditorWrapper>,
    document.querySelector('body'),
    'dojo-quick-message-editor'
  )
}

export default React.memo(MessageEditor)

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import history from 'utils/getHistory'

import { acceptCurrentPlatformTerms } from 'actions/currentBrand'

import UpdatedTermsModalView from 'views/mobile/terms'

function UpdatedTermsModal() {
  const dispatch = useDispatch()
  const currentBrand = useSelector(state => state.currentBrand)
  const profile = useSelector(state => state.profile)

  const open = !currentBrand.has_accepted_latest_terms
    && !profile.impersonating
    && !currentBrand.currentBrandLoading
    && !profile.profileLoading

  const [openTermsModal] = useState(open)

  const onTermsAccept = async () => dispatch(acceptCurrentPlatformTerms())

  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (location.pathname !== '/app' && open && action === 'PUSH') history.replace('')
    })

    return unlisten
  }, [open])

  return <UpdatedTermsModalView open={open && openTermsModal} onTermsAccept={onTermsAccept} />
}

export default React.memo(UpdatedTermsModal)

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Paper, ClickAwayListener } from '@material-ui/core'

import { P, Small } from 'components/texts'
import { Button } from 'components/buttons'
import { Input } from 'components/inputs'

const useStyles = makeStyles(theme => ({
  couponModal: {
    position: 'absolute',
    minWidth: 360,
    padding: 16,
    zIndex: 1,
    marginLeft: 12,
    boxShadow: theme.mixins.shadows.double,
    bottom: 0,
  },
  couponInput: {
    display: 'flex',
    marginBottom: 24,
    alignItems: 'flex-end',
    '& > :first-child': {
      flex: 1,
      marginRight: 8,
    },
    '& > :not(:first-child)': {
      marginBottom: 2,
    },
  },
}))

const EditCouponModal = ({ coupon, onSubmit }) => {
  const css = useStyles()

  const [value, setValue] = useState(coupon)

  const onClick = e => {
    e.stopPropagation()
    e.preventDefault()
    onSubmit(value)
  }

  const onChange = val => {
    const newValue = val.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()
    setValue(newValue)
  }

  return (
    <ClickAwayListener onClickAway={() => onSubmit(coupon)}>
      <Paper className={css.couponModal}>
        <P>
          <strong>Coupon Code</strong>
        </P>
        <div className={css.couponInput}>
          <Input type="text" value={value} placeholder="SAMPLECODE" onChange={onChange} />
          <Button disabled={!value} label="Update" onClick={onClick} type="contained" />
        </div>
        <Small multiline>It may take up to 24 hours for your analytics to be updated.</Small>
      </Paper>
    </ClickAwayListener>
  )
}

EditCouponModal.propTypes = {
  coupon: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
}

EditCouponModal.defaultProps = {
  coupon: '',
}

export default React.memo(EditCouponModal)

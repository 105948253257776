import { useRef } from 'react'
import _ from 'lodash'

/*
const ERROR_OBJECT_EXAMPLES = {
  'field_name':
    { section: 'setup', tab: 'fields', error: 'text', ...anyOtherPropsThatWouldHelpHere },
  'field_name_2': null, // no error
}
 */

function validateValue(value, validations) {
  if (!validations) return false

  return validations.reduce(
    (validationError, validation) => validationError || validation(value),
    null
  )
}

function checkForErrors(fields, errorTemplates) {
  const errors = {}

  errorTemplates.forEach(template => {
    if (template.multiple) {
      // TODO: name wip
      const multipleInputs = _.pickBy(fields, (value, key) => key.startsWith(template.name))

      Object.entries(multipleInputs).forEach(([key, value]) => {
        const error = validateValue(value, template.validations)

        if (error) errors[key] = { ...template, message: error }
      })
      return
    }

    if (template.name in fields) {
      const value = fields[template.name]
      const error = validateValue(value, template.validations)
      if (error && template.name in fields) errors[template.name] = { ...template, message: error }
    }
  })

  return errors
}

function mapErrors(errorTemplates) {
  const mappedErrors = []

  errorTemplates.forEach(errorTemplate => {
    const { fields = [], template = {} } = errorTemplate

    fields.forEach(fieldKey => {
      mappedErrors.push({ ...template, name: fieldKey })
    })
  })

  return mappedErrors
}

function useBuilderErrors({ errorsMap = [] }) {
  const errorsRef = useRef({})
  const { current: mappedErrors } = useRef(mapErrors(errorsMap))

  const hasErrorInBuilder = (name, section) =>
    Object.keys(errorsRef.current).some(key => errorsRef.current[key][section] === name)

  const isValid = (errors = errorsRef.current) => {
    const blockingErrors = Object.values(errors).filter(error => !error.nonBlocking)
    return blockingErrors.length === 0 // if false, there are errors
  }

  const checkIfError = name => name in errorsRef.current && errorsRef.current[name].message
  const checkIfErrorInTab = name => hasErrorInBuilder(name, 'tab')
  const checkIfErrorInSection = name => hasErrorInBuilder(name, 'section')

  const checkForErrorsWrapper = fields =>
    (Array.isArray(fields)
      ? fields.reduce((value, name) => value || checkIfError(name), false)
      : checkIfError(fields))

  // skipBuilder allows to return validations but dont save them for builder to detect them
  const validate = (inputs = {}, skipBuilder = false) => {
    const possibleErrors = checkForErrors(inputs, mappedErrors)
    if (!skipBuilder) errorsRef.current = possibleErrors

    return isValid(possibleErrors)
  }

  const setError = (field, message) => {
    const template = mappedErrors.find(fieldTemplate => {
      if (fieldTemplate.multiple && field.startsWith(fieldTemplate.name)) return true
      return fieldTemplate.name === field
    })
    if (template) errorsRef.current = { ...errorsRef.current, [field]: { ...template, message } }
  }

  return {
    validate,
    set: setError,
    isValid, // no errors
    verify: checkForErrorsWrapper,
    checkIfErrorInTab, // builder
    checkIfErrorInSection, // builder
  }
}

export default useBuilderErrors

import React from 'react'

import { ReactComponent as SettingsIcon } from 'images/icons/builder/setup.svg'
import { ReactComponent as MessageIcon } from 'images/icons/builder/message.svg'
import { ReactComponent as InstallIcon } from 'images/icons/builder/install.svg'
import { ReactComponent as BrushIcon } from 'images/icons/builder/brush.svg'

import { colors } from 'components/virtual/VirtualTheme'
import {
  isRequired,
  cantExceedOfChars,
  isNum,
  isPositiveOrZeroInteger,
} from 'utils/formValidations'
import {
  isDomainRequired,
  isValidDomain,
  hasMissingFields,
  hasRequiredImageMissing,
  hasRepeatedField,
  hasRepeatedESPField,
} from './utils/lightbox-validations'

import LightboxBuilderNavbar from './navbar'
import ESPIntegrationSection from './sections/setup/integrations/ESPIntegrationSection'
import LightboxBuilderBehavior from './sections/setup/behavior/LightboxBuilderBehavior'
import LightboxBuilderBehaviorEdit from './sections/setup/behavior/LightboxBuilderBehaviorEdit'
import {
  LightboxBuilderDesignEdit,
  LightboxBuilderContent,
  LightboxBuilderTheme,
} from './sections/design'
import LightboxBuilderSMSWelcomeMessage from './sections/message/sms/LightboxBuilderSMSWelcomeMessage'
import LightboxBuilderEmailWelcomeMessage from './sections/message/email/LightboxBuilderEmailWelcomeMessage'
import LightboxBuilderInstallationSection from './sections/installation/LightboxBuilderInstallationSection'
import LightboxBuilderLoading from './LightboxBuilderLoading'
import LightboxBuilderPreview from './LightboxBuilderPreview'

const PREVIEW_COLORS = {
  popup: {
    backgroundColor: colors.charcoalBase,
    navbarButtons: {
      active: 'white',
      inactive: colors.charcoalLighter,
    },
  },
  embed: {
    backgroundColor: '#EDEDED',
    navbarButtons: {
      active: colors.charcoalBase,
      inactive: colors.charcoalLighter,
    },
  },
}

function useLightboxBuilderConfig() {
  const url = new URL(document.location.href)
  const params = new URLSearchParams(url.search)
  const formType = params.get('type')

  const previewColors = PREVIEW_COLORS[formType]

  const config = {
    navbar: <LightboxBuilderNavbar />,
    loading: {
      component: <LightboxBuilderLoading />,
      initialText: 'Loading Your Form...', // initial loading
    },
    sidebar: {
      sections: [
        {
          path: '/design',
          sidebarButton: { icon: <BrushIcon />, label: 'Design' },
          tabs: [
            {
              label: 'Content',
              path: '/content',
              component: LightboxBuilderContent,
              drawerComponent: LightboxBuilderDesignEdit,
            },
            {
              label: 'Theme',
              path: '/theme',
              component: LightboxBuilderTheme,
              drawerComponent: LightboxBuilderDesignEdit,
            },
          ],
        },
        {
          path: '/message',
          sidebarButton: { icon: <MessageIcon />, label: 'Message' },
          fullPage: true,
          title: 'Welcome New Subscribers',
          helperTitle: 'AUTOMATED FLOWS',
          tabs: [
            {
              label: 'SMS Welcome',
              path: '/sms',
              component: LightboxBuilderSMSWelcomeMessage,
            },
            {
              label: 'Email Welcome',
              path: '/email',
              component: LightboxBuilderEmailWelcomeMessage,
            },
          ],
        },
        {
          path: '/settings',
          sidebarButton: { icon: <SettingsIcon />, label: 'Settings' },
          tabs: [
            {
              label: 'Integrations',
              path: '/integrations',
              component: ESPIntegrationSection,
              drawerComponent: null,
            },
            {
              label: 'Behavior',
              path: '/behavior',
              component: LightboxBuilderBehavior,
              drawerComponent: LightboxBuilderBehaviorEdit,
            },
          ],
        },
        {
          path: '/install',
          sidebarButton: { icon: <InstallIcon />, label: 'Install' },
          fullPage: true,
          component: LightboxBuilderInstallationSection,
        },
      ],
    },
    preview: {
      component: <LightboxBuilderPreview />,
      colors: previewColors,
    },
    // Error templates
    errors: [
      {
        fields: ['placeholder'],
        template: {
          section: 'design',
          tab: 'content',
          multiple: true,
          validations: [isRequired],
          nonBlocking: true,
        },
      },
      {
        fields: ['espValue'],
        template: {
          section: 'settings',
          tab: 'integrations',
          multiple: true,
          validations: [hasRepeatedESPField],
          nonBlocking: true,
        },
      },
      {
        fields: ['cantExceed'],
        template: {
          section: 'settings',
          tab: 'integrations',
          multiple: true,
          validations: [cantExceedOfChars(32)],
        },
      },
      {
        fields: ['customText', 'customDropdown'],
        template: {
          section: 'design',
          tab: 'content',
          multiple: true,
          validations: [isRequired, cantExceedOfChars(32)],
          nonBlocking: true,
        },
      },
      {
        fields: ['pageMissingFields'],
        template: {
          section: 'design',
          tab: 'content',
          validations: [hasMissingFields],
        },
      },
      {
        fields: ['repeatedValue'],
        template: {
          section: 'design',
          tab: 'content',
          multiple: true,
          validations: [hasRepeatedField],
        },
      },
      {
        fields: ['ESPRequired', 'ESPNotSupported'],
        template: {
          section: 'settings',
          tab: 'integrations',
          validations: [isRequired],
          nonBlocking: true,
        },
      },
      {
        fields: ['domain'],
        template: {
          section: 'settings',
          tab: 'behavior',
          validations: [isDomainRequired],
        },
      },
      {
        fields: ['validDomain'],
        template: {
          section: 'settings',
          tab: 'behavior',
          validations: [isValidDomain],
        },
      },
      {
        fields: ['delay'],
        template: {
          section: 'settings',
          tab: 'behavior',
          validations: [isPositiveOrZeroInteger, isNum],
        },
      },
      {
        fields: ['formFieldCornerRadius', 'buttonCornerRadius'],
        template: {
          section: 'design',
          tab: 'theme',
          validations: [isPositiveOrZeroInteger, isNum],
        },
      },
      {
        fields: ['pageMissingImage'],
        template: {
          section: 'design',
          tab: 'content',
          multiple: true,
          validations: [hasRequiredImageMissing],
        },
      },
      {
        fields: ['lightboxSmsSendMissing'],
        template: {
          section: 'message',
          tab: 'sms',
          validations: [isRequired],
        },
      },
    ],
  }

  return config
}

export default useLightboxBuilderConfig

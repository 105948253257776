import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { TextField, withStyles } from '@material-ui/core'
import cn from 'classnames'

import { H6, Tiny } from 'components/texts'
import makeNumbersPretty from 'utils/makeNumbersPretty'
import ToolTip from 'components/shared/ToolTip'

const styles = () => ({
  inputWrapper: {
    width: '100%',
    position: 'relative',
  },
  labels: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    position: 'relative',
    width: '100%',
  },
  sublabel: {
    paddingTop: 4,
    paddingLeft: 4,
  },
  numlabel: {
    flex: 1,
    textAlign: 'right',
  },
  noBorder: {
    border: 'none',
  },
  tooltip: {
    position: 'relative',
    width: 10,
    height: 8,
    margin: '0 0 0 4px',
    top: 2,
  },
})

function InputBase(props) {
  const {
    classes,
    label,
    sublabel,
    numlabel,
    onChange,
    onBlur,
    onFocus,
    placeholder,
    children,
    customClasses,
    value,
    getRef,
    tooltip,
    ...restProps
  } = props

  const { textInputWrapper = '', ...restCustomClasses } = customClasses

  const inputRef = useRef(null)

  const onValueChange = ({ target }) => onChange(target.value)

  useEffect(() => {
    if (getRef && inputRef.current) {
      const input = inputRef.current.querySelector('input, textarea')
      getRef(input)
    }
  }, [label, getRef, inputRef])

  useEffect(() => {
    if (value === null) onChange('')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={cn(classes.inputWrapper, textInputWrapper)}>
      {(label || sublabel || numlabel) && (
        <div className={classes.labels}>
          {!label ? null : <H6>{label}</H6>}
          {!sublabel ? null : (
            <div className={classes.sublabel}>
              <Tiny>{sublabel}</Tiny>
            </div>
          )}
          {!tooltip ? null : (
            <ToolTip
              isVirtualTheme
              {...(typeof tooltip === 'string' ? { text: tooltip } : tooltip)}
            />
          )}
          {!numlabel ? null : (
            <H6 azure classes={{ text: classes.numlabel }}>
              <strong>
                {typeof numlabel === 'number' ? makeNumbersPretty(numlabel) : numlabel}
              </strong>
            </H6>
          )}
        </div>
      )}
      <TextField
        ref={inputRef}
        classes={restCustomClasses}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onValueChange}
        placeholder={placeholder}
        {...restProps}
      >
        {children}
      </TextField>
    </div>
  )
}

InputBase.defaultProps = {
  disabled: false,
  label: '',
  sublabel: '',
  numlabel: '',
  onChange() {},
  onBlur() {},
  onFocus() {},
  getRef: null,
  placeholder: '',
  value: '',
  children: null,
  customClasses: {},
  validations: [],
  tooltip: null,
}

InputBase.propTypes = {
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  sublabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  numlabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  getRef: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  customClasses: PropTypes.object,
  validations: PropTypes.arrayOf(PropTypes.func),
  tooltip: PropTypes.string,
}

export default React.memo(withStyles(styles)(InputBase))

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import cn from 'classnames'
import Menu from 'components/menus/Menu'
import { ReactComponent as ActionButtonIcon } from 'images/tables/table-action-button.svg'

const useStyles = makeStyles(theme => ({
  wrapper: {
    cursor: 'pointer',
    display: 'flex',
    height: 12,
    alignItems: 'center',
    justifyContent: 'center',
  },
  btn: {
    width: 4,
    height: 4,
    marginRight: 5,
    borderRadius: '100%',
    backgroundColor: theme.colors.azure,
  },
  icon: {
    height: 40,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      height: 25,
      width: 25,
      padding: '10px 4px',
      '&:hover': {
        borderRadius: '50%',
        backgroundColor: theme.colors.skyBase,
      },
    },
  },
}))

function ActionButton(props) {
  const css = useStyles()
  const [open, setOpen] = useState(false)

  const { items, virtualButton } = props

  return (
    <div onClick={() => setOpen(true)} className={cn(css.wrapper, { [css.icon]: virtualButton })}>
      {virtualButton ? (
        <ActionButtonIcon className={css.icon} />
      ) : (
        <>
          <div className={css.btn} />
          <div className={css.btn} />
          <div className={css.btn} />
        </>
      )}
      <Menu
        open={open}
        onClose={() => setOpen(false)}
        items={items}
        itemMinHeight={30}
        virtualButton={virtualButton}
        vertical={virtualButton && 'top'}
        width={180}
      />
    </div>
  )
}

ActionButton.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  virtualButton: PropTypes.bool,
}

ActionButton.defaultProps = {
  virtualButton: false,
}

export default React.memo(ActionButton)

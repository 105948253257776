import React from 'react'
import { makeStyles } from '@material-ui/core'

import { PageTitle } from 'components/builder/titles'
import RadioGroup from 'components/virtual/buttons/RadioGroup'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import InputWithSideLabel from 'components/virtual/inputs/InputWithSideLabel'

const useStyles = makeStyles({
  autoCloseDelay: {},
  subgroup: {
    marginTop: 8,
    paddingLeft: 28,
  },
})

const AUTO_CLOSE_DELAY_OPTIONS = [
  { value: 'never', label: 'Never' },
  { value: 'selected', label: 'After landing on the Success Page for' },
]

function AutoCloseDelay() {
  const css = useStyles()
  const { lightboxSettings } = React.useContext(BuilderContainerContext)
  const settings = lightboxSettings.getSettings()

  const radioValue = settings.autoCloseDelay === null ? 'never' : 'selected'

  const onChange = selection => {
    if (selection === 'never') settings.autoCloseDelay = null
    else settings.autoCloseDelay = 5
  }

  const onAutoDelayChange = e => {
    let { value: delay } = e.target
    if (!/^\d*$/.test(delay)) return
    const MAX = 999
    const MIN = 0
    delay = Math.min(Math.max(+delay, MIN), MAX)
    settings.autoCloseDelay = delay
  }

  const onKeyDown = e => {
    let { value: delay } = e.target
    switch (e.key) {
      case 'ArrowUp':
        delay = Number(delay) + 1
        break
      case 'ArrowDown':
        delay = Math.max(0, Number(delay) - 1)
        break
      default:
        break
    }

    onAutoDelayChange({ target: { value: delay } })
  }

  return (
    <div className={css.autoCloseDelay}>
      <PageTitle textProps={{ size: 16 }} text="Automatically Close the Form" />
      <br />
      <RadioGroup value={radioValue} values={AUTO_CLOSE_DELAY_OPTIONS} onChange={onChange} />
      <div className={css.subgroup}>
        <InputWithSideLabel
          controlledInput
          value={settings.autoCloseDelay || 0}
          onChange={onAutoDelayChange}
          onKeyDown={onKeyDown}
          label="Seconds"
          disabled={radioValue === 'never'}
        />
      </div>
    </div>
  )
}

export default React.memo(AutoCloseDelay)

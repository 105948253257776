import axios from 'axios'
import { setAnalyticsUser } from 'utils/analytics'
import { getDisplayName } from 'utils/profile'

// Moved this in here since this needs to be in scope of reducers/dispatchers
import ws from 'utils/initSocket'

export const loadProfile = (impersonating, handleOnFail = null) => async dispatch => {
  ws.init()

  dispatch({
    type: 'PROFILE_LOADING',
    payload: impersonating,
  })

  try {
    const { data: profile = {} } = await axios.get('/profile')

    const payload = { ...profile, displayName: getDisplayName(profile), ws }
    setAnalyticsUser(payload)

    return dispatch({
      type: 'PROFILE_LOAD',
      payload,
    })
  } catch (err) {
    if (handleOnFail) handleOnFail()
    console.log(err)
  }

  return dispatch({ type: 'PROFILE_FAILED' })
}

export default loadProfile

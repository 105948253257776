import React, { useState } from 'react'

import SupportModal from 'components/modals/store-integrations/SupportModal'
import ActionBanner from './ActionBanner'

function DontSeePreferredESPBanner() {
  const [open, setOpen] = useState(false)

  return (
    <>
      <ActionBanner
        align="center"
        action={() => setOpen(true)}
        actionLabel="Let Us Know"
        title="Don't see your preferred ESP?"
      />
      <SupportModal open={open} setOpenSupport={setOpen} />
    </>
  )
}

export default React.memo(DontSeePreferredESPBanner)

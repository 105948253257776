import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import BuilderLoading from 'components/builder/BuilderLoading'

import BuilderNavbar from './BuilderNavbar'
import BuilderSectionsContent from './BuilderSectionsContent'
import BuilderPreviewContent from './preview-section/BuilderPreviewContent'
import { BuilderContainerContext } from './BuilderContainerContext'

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexFlow: 'column',
    position: 'absolute',
    top: 0,
    zIndex: 1,
    height: '100vh',
    width: '100vw',
  },
  content: {
    position: 'relative',
    height: '100%',
    display: 'flex',
  },
  preview: {
    flex: 1,
    display: ({ show }) => (show ? 'flex' : 'none'),
  },
})

function BuilderContainer() {
  const { builder, config } = React.useContext(BuilderContainerContext)
  const {
    loading,
    section,
    preview: { full: isPreview },
  } = builder
  const css = useStyles({ show: !section.fullPage || isPreview })

  const {
    loading: { initialText = 'Loading...', component: loadingComponent = null },
  } = config

  if (loading) return <BuilderLoading loadingText={initialText} />

  return (
    <div className={css.wrapper}>
      <BuilderNavbar />
      <div className={css.content}>
        {loadingComponent}
        <BuilderSectionsContent />
        <div className={css.preview}>
          <BuilderPreviewContent />
        </div>
      </div>
    </div>
  )
}

export default React.memo(BuilderContainer)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import useInputDebounce from 'hooks/useInputDebounce'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import { Input } from 'components/virtual/inputs'
import Text from 'components/texts/Text'
import Separator from 'components/shared/Separator'

import VirtualTheme from 'components/virtual/VirtualTheme'
import AllowOrHideUrlList from './AllowOrHideUrlList'

const useStyles = makeStyles(theme => ({
  urlTargeting: {
    paddingTop: 12,
    '& input': {
      // all inputs/url path inputs within this component
      color: theme.colors.blueBase,
      caretColor: theme.colors.charcoalBase,
      '&::placeholder': {
        color: theme.colors.charcoalLighter,
      },
    },
  },
  domain: {
    marginTop: 20,
  },
}))

function BehaviorUrlTargeting() {
  const css = useStyles()
  const { builder, lightboxSettings } = React.useContext(BuilderContainerContext)
  const settings = lightboxSettings.getSettings()

  const onDomainChange = domain => {
    settings.domain = domain || null
  }

  const [domain, onInputChange] = useInputDebounce(settings.domain, onDomainChange)
  const { errors } = builder

  const error = errors.verify(['domain', 'validDomain'])

  return (
    <div className={css.urlTargeting}>
      <Text charcoalDarker size={16}>
        Location
      </Text>
      <div className={css.domain}>
        <VirtualTheme>
          <Input
            compact
            fullWidth
            value={domain}
            onChange={onInputChange}
            label="YOUR DOMAIN"
            placeholder="dojomojo.com"
            error={error}
          />
        </VirtualTheme>
      </div>
      <Separator top={24} bottom={24} />
      <AllowOrHideUrlList />
    </div>
  )
}

export default React.memo(BehaviorUrlTargeting)

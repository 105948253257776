import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import { ButtonBase, ClickAwayListener } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { CloseOutlined } from '@material-ui/icons'

import Text from 'components/texts/Text'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

import { ReactComponent as LogoIcon } from 'images/nav-bar/mojo-white-tiger-logo.svg'

import BuilderCloseBeforeSavingPopup from 'components/builder/BuilderCloseBeforeSavingPopup'
import LightboxBuilderNavbarActionButtons from './LightboxBuilderNavbarActionButtons'
import LightboxBuilderStatusBadge from './LightboxBuilderStatusBadge'
import LightboxBuilderNameChangeModal from '../modals/LightboxBuilderNameChangeModal'

const useStyles = makeStyles(theme => ({
  navbar: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  navbarGroup: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    height: 36,
    width: 36,
    marginRight: 40,
  },
  exitButtonWrapper: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  exitButton: {
    cursor: 'pointer',
    margin: 'auto 0 auto 38px',
    height: '32px',
    width: '32px',
    '& path': {
      fill: 'white',
    },
    '&:hover > path': {
      fill: theme.colors.skyDarker,
    },
  },
  title: {
    borderRadius: 4,
    padding: '8px 12px',
    display: 'flex',
    alignItems: 'center',
    '&:hover > :nth-child(2)': {
      opacity: 1,
    },
  },
  editIcon: {
    transition: 'opacity .2s linear',
    opacity: ({ showEdit }) => Number(showEdit),
    marginLeft: 16,
    color: 'white',
  },
}))

function LightboxBuilderNavbar() {
  const {
    lightboxSettings,
    isNew,
    actions,
    values: { returnUrl },
  } = React.useContext(BuilderContainerContext)
  const { onSaveLightbox } = actions
  const [firstTimeSetting, setFirstTimeSetting] = useState(isNew)
  const [showBeforeSavingPopup, setShowBeforeSavingPopup] = useState(false)
  const settings = lightboxSettings.getSettings()
  const history = useHistory()

  const [showEditName, setShowEditName] = useState(isNew)
  const css = useStyles({ showEdit: showEditName })

  const onNameChangeModalClose = () => {
    setShowEditName(false)
    // dont update modal internals until modal has faded out
    setTimeout(() => setFirstTimeSetting(false), 250)
  }

  const onSaveAndExit = () => onSaveLightbox({ closeBuilder: true })

  const onDiscardChanges = () => history.push(returnUrl)

  const onExitButtonClick = () => {
    if (lightboxSettings.isUpdated) setShowBeforeSavingPopup(true)
    else onDiscardChanges()
  }

  const exitButton = (
    <ClickAwayListener onClickAway={() => setShowBeforeSavingPopup(false)}>
      <div className={css.exitButtonWrapper} onClick={onExitButtonClick}>
        <CloseOutlined className={css.exitButton} sx={{ fontSize: 19 }} />
        <BuilderCloseBeforeSavingPopup
          show={showBeforeSavingPopup}
          onSave={onSaveAndExit}
          onDiscard={onDiscardChanges}
          anchorMenuRight
        />
      </div>
    </ClickAwayListener>
  )

  const navbarTitle = (
    <ButtonBase className={css.title} onClick={() => setShowEditName(true)}>
      <Text size={18} white>
        {settings.name}
      </Text>
      <EditIcon className={css.editIcon} />
    </ButtonBase>
  )

  return (
    <div className={css.navbar}>
      <div className={css.navbarGroup}>
        <LogoIcon className={css.logo} />
        {navbarTitle}
        <LightboxBuilderStatusBadge />
      </div>

      <div className={css.navbarGroup}>
        <LightboxBuilderNavbarActionButtons />
        <LightboxBuilderNameChangeModal
          show={showEditName}
          onClose={onNameChangeModalClose}
          firstTime={firstTimeSetting}
        />
        {exitButton}
      </div>
    </div>
  )
}

export default React.memo(LightboxBuilderNavbar)

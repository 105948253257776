import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useLocation } from 'react-router-dom'

import Text from 'components/texts/Text'
import VirtualTheme from 'components/virtual/VirtualTheme'
import Button from 'components/virtual/buttons/Button'
import generatePreview from 'views/mobile/sms-marketing/utils'
import { useSelector } from 'react-redux'
import useWindowResize from 'hooks/useWindowResize'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import DripPreviewMessageBox from './DripPreviewMessageBox'
import DripPreviewMessage from './DripPreviewMessage'

const useStyles = makeStyles(theme => ({
  drip: {
    border: `1px solid ${theme.colors.skyBase}`,
    borderRadius: 4,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  titles: {
    backgroundColor: 'white',
    height: 72,
    display: 'flex',
    alignItems: 'center',
    padding: 20,
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.colors.skyBase}`,
  },
  drips: {
    padding: ({ hasFollowups }) => `20px 20px 8px ${hasFollowups ? 44 : 20}px`,
    backgroundColor: theme.colors.skyLighter,
    overflowY: 'scroll',
    flex: 1,
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    marginLeft: -18,
    borderRight: `1px solid ${theme.colors.charcoalLight}`,
  },
}))

// TODO: request map of followup criteria labels
const FOLLOWUP_CRITERIA_LANGUAGE = {
  'all recipients': 'All subscribers',
  'not clicked': "Subscribers that haven't clicked",
  'not purchased': "Subscribers that haven't purchased",
  'not clicked or purchased': "Subscribers that haven't clicked or purchased",
  'clicked but not purchased': "Subscribers that have clicked but haven't purchased",
}

function DripPreview() {
  const {
    values: { lightboxSmsSend: smsSend },
  } = React.useContext(BuilderContainerContext)
  const history = useHistory()
  const { pathname: url } = useLocation()
  const currentBrand = useSelector(state => state.currentBrand)
  const [height, setHeight] = useState(0) // progress bar style
  const [marginTop] = useState(92) // progress bar style
  const { width } = useWindowResize()

  const { followups = [] } = smsSend
  const hasFollowups = followups.length > 0
  const triggerCriteria = hasFollowups
    ? FOLLOWUP_CRITERIA_LANGUAGE[followups[0].criteria.criteria]
    : null

  const css = useStyles({ hasFollowups })

  const onCreateDrip = () => history.push(`${url}?edit=true`)

  const editButton = (
    <VirtualTheme>
      <Button size="smaller" type="outlined" label="Edit Drip" onClick={onCreateDrip} />
    </VirtualTheme>
  )

  useEffect(() => {
    const items = document.querySelectorAll('.drip-message-content')
    const [, ...rest] = items
    let progressVal = 0

    Array.from(items)
      .slice(0, -1)
      .forEach(element => (progressVal += element.offsetHeight))

    progressVal += 32 // margin and padding
    progressVal += 12 // margin
    progressVal += 66 // header
    progressVal += Array.from(rest).length * 4 // margin // TODO: retest this?

    setHeight(progressVal)
  }, [setHeight, width, followups.length])

  return (
    <div className={css.drip}>
      <div className={css.titles}>
        <Text size={16} charcoalBase>
          SMS Welcome Series Preview
        </Text>
        {editButton}
      </div>
      <div className={css.drips}>
        {hasFollowups && <div className={css.progress} style={{ height, marginTop }} />}
        <DripPreviewMessageBox trigger="Signup form submission">
          <DripPreviewMessage
            image={smsSend.image_url}
            message={generatePreview(smsSend, currentBrand.accountname, currentBrand.website)}
            title={smsSend.name}
            hasFollowups={hasFollowups}
          />
        </DripPreviewMessageBox>
        {hasFollowups && (
          <DripPreviewMessageBox isFollowup trigger={triggerCriteria}>
            {followups.map(followup => (
              <DripPreviewMessage
                key={followup.id}
                hasFollowups
                delay={`${followup.send_interval} ${followup.unit_of_time}`}
                image={followup.image_url}
                message={generatePreview(followup, currentBrand.accountname, currentBrand.website)}
                title={followup.name}
              />
            ))}
          </DripPreviewMessageBox>
        )}
      </div>
    </div>
  )
}

export default React.memo(DripPreview)

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link, withRouter } from 'react-router-dom'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import ButtonBase from '@material-ui/core/ButtonBase'

import { colors } from 'components/virtual/VirtualTheme'
import Text from 'components/texts/Text'
import { BuilderContainerContext } from './BuilderContainerContext'

const useStyles = makeStyles(theme => ({
  item: {
    '&:not(:last-child)': {
      marginBottom: 12,
    },
    cursor: 'pointer',
    padding: '13px 0',
    position: 'relative',
    display: 'grid',
    placeItems: 'center',
    gridRowGap: '8px',
    backgroundColor: 'white',
    width: '100%',
    height: 72,
  },
  selected: {
    backgroundColor: colors.skyLight,
    '& svg *:not(.no-override)': {
      fill: colors.navyLight,
    },
    '&:before': {
      position: 'absolute',
      content: '""',
      left: 0,
      height: '100%',
      width: 4,
      backgroundColor: colors.navyLight,
    },
  },
  error: {
    position: 'relative',
    '&:after': {
      position: 'absolute',
      content: '""',
      width: 6,
      height: 6,
      borderRadius: '50%',
      backgroundColor: theme.colors.redBase,
      left: '55%',
      top: 12,
    },
  },
}))

function BuilderSection(props) {
  const {
    to, button, previewNavbar, section, match, location,
  } = props

  const { url } = match
  const { pathname } = location
  const css = useStyles()
  const {
    builder: { preview, setSection, errors },
  } = React.useContext(BuilderContainerContext)

  const sectionName = to.replace('/', '')
  const selected = pathname.replace(url, '').split('/')[1] === sectionName
  const hasError = errors.checkIfErrorInSection(sectionName)

  useEffect(() => {
    if (selected) {
      preview.setNavbar(previewNavbar)
      setSection(section)
    }
  }, [selected, setSection]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Link to={`${url}${to}`}>
      <ButtonBase className={cn(css.item, { [css.selected]: selected, [css.error]: hasError })}>
        {button.icon}
        <Text navyLight={selected}>{button.label}</Text>
      </ButtonBase>
    </Link>
  )
}

BuilderSection.propTypes = {
  to: PropTypes.string.isRequired,
  previewNavbar: PropTypes.node,
  section: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  button: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
}

BuilderSection.defaultProps = {
  previewNavbar: null,
}

export default React.memo(withRouter(BuilderSection))

import React from 'react'
import PropTypes from 'prop-types'
import { SMSEditorContext } from 'contexts'

import EditorLink from './EditorLinkWrapper'
import EditorTag from './EditorTagWrapper'
import CustomTag from './EditorCustomTagWrapper'
import EditorCoupon from './EditorCouponWrapper'

function EditorElementsWrapper(props) {
  const {
    decoratedText, children, mergeTags, type,
  } = props

  const { ignoredCoupons, minifiedLinks } = React.useContext(SMSEditorContext)

  if (type === 'customTag') return <CustomTag children={children} tag={decoratedText} />
  if (type === 'mergeTag') return <EditorTag children={children} tag={decoratedText} mergeTags={mergeTags} />
  if (type === 'coupon') {
    if (ignoredCoupons.includes(decoratedText)) return children
    return <EditorCoupon children={children} code={decoratedText} />
  }

  const url = minifiedLinks.find(
    ({ originalUrl, minifiedUrl }) => originalUrl === decoratedText || minifiedUrl === decoratedText
  )

  // Then it's a link...
  return (
    <EditorLink
      children={children}
      url={url ? url.originalUrl : decoratedText}
      minifiedUrl={url && url.minifiedUrl}
    />
  )
}

EditorElementsWrapper.propTypes = {
  mergeTags: PropTypes.array.isRequired,
  children: PropTypes.node.isRequired,
  decoratedText: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

export default React.memo(EditorElementsWrapper)

export default [
  'Advertising & Marketing',
  'Alcoholic Beverages',
  'Art & Entertainment',
  'B2B',
  'Beauty & Makeup',
  'Blogs & Social Media',
  'Books',
  'CBD',
  'Community & Non-Profit',
  'Daily Deals',
  'eCommerce',
  'Education',
  'Fitness',
  'Food & Beverages',
  'Gaming',
  'Health & Wellness',
  'Hobbies & Leisure',
  'Home & Garden',
  'Hotels & Accommodations',
  'Kids & Babies',
  'Luxury',
  'Media',
  "Men's Fashion",
  'Music',
  'Organic',
  'Parenting',
  'Pet Supplies',
  'Plant Based',
  'Professional Development',
  'Real Estate',
  'Restaurants',
  'Shopping',
  'Skin Care',
  'Software & Apps',
  'Sports & Adventures',
  'Sustainability',
  'Tech & Electronics',
  'Travel',
  'Wedding & Bridal',
  "Women's Fashion",
  'Other',
]

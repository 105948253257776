import axios from 'axios'

export default async function () {
  const { data: customFieldMergeTags } = await axios.get('/sms/sms-send/custom-field-merge-tags')

  return {
    'Account Tags': [{ label: 'Brand Name', value: 'BRAND_NAME' }],
    // added an asterisk to exclude them from the regular tags regex
    '*Subscriber Tags': [
      { label: 'First Name or (Fallback)', value: 'FIRST_NAME_OR__FALLBACK_' },
      { label: 'Last Name or (Fallback)', value: 'LAST_NAME_OR__FALLBACK_' },
      { label: 'City or (Fallback)', value: 'CITY_OR__FALLBACK_' },
      { label: 'State or (Fallback)', value: 'STATE_OR__FALLBACK_' },
    ],
    ...(customFieldMergeTags && customFieldMergeTags.length
      ? { 'Custom Fields': customFieldMergeTags }
      : {}),
    Other: [{ label: 'Coupon Code', value: 'COUPON_CODE' }],
  }
}

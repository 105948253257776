import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { ChevronLeft } from '@material-ui/icons'

import { H6 } from 'components/texts'
import useResize from 'hooks/useWindowResize'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100vw',
    left: '100vw',
    position: 'absolute',
    backgroundColor: 'white',
    zIndex: 1100,
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    animation: '$in .12s ease-in-out forwards',
    transition: 'left .12s linear',
  },
  navbar: {
    height: 40,
    borderBottom: `1px solid ${theme.colors.silver}`,
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
  },
  title: {
    padding: '4px 16px 0',
  },
  svg: {
    fontSize: '24px',
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    overflowY: 'auto',
  },
  close: {
    animation: '$out .12s ease-in-out forwards',
  },
  '@keyframes in': {
    '100%': {
      left: 0,
    },
  },
  '@keyframes out': {
    '0%': {
      left: 0,
    },
    '100%': {
      left: '100vw',
    },
  },
}))

function SideModal(props) {
  const { children, onBackPress, title } = props
  const classes = useStyles()
  const [close, setClose] = useState(false)
  const { height } = useResize()

  const onBack = () => setClose(true)

  useEffect(() => {
    // Close back
    if (close) setTimeout(onBackPress, 120)
  }, [close, onBackPress])

  return (
    <div style={{ height }} className={`${classes.container} ${close ? classes.close : ''}`}>
      <div className={classes.navbar}>
        <ChevronLeft classes={{ root: classes.svg }} onClick={onBack} />
        <div className={classes.title}>
          <H6>{title}</H6>
        </div>
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  )
}

SideModal.defaultProps = {
  onBackPress() {},
  title: '',
}

SideModal.propTypes = {
  onBackPress: PropTypes.func,
  children: PropTypes.array.isRequired,
  title: PropTypes.string,
}

export default React.memo(SideModal)

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { ArrowForwardIos as ArrowIcon } from '@material-ui/icons'

import Text from 'components/texts/Text'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

const useStyles = makeStyles(theme => ({
  successPage: {
    cursor: 'pointer',
    border: ({ focused }) => `1px solid ${theme.colors[focused ? 'blueBase' : 'skyDarker']}`,
    borderRadius: 4,
    backgroundColor: theme.colors.skyLighter,
    transition: 'all .1s linear',
    '&:hover': {
      backgroundColor: ({ focused }) => theme.colors[focused ? 'skyLighter' : 'skyBase'],
    },
    '&:hover > div:first-child': {
      backgroundColor: ({ focused }) => theme.colors[focused ? 'skyLight' : 'skyBase'],
    },
  },
  successPageTitle: {
    position: 'relative',
    height: 44,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 12px',
    backgroundColor: theme.colors.skyLight,
    borderBottom: ({ focused }) =>
      (focused ? `3px solid ${theme.colors.blueBase}` : `1px solid ${theme.colors.skyDarker}`),
    textTransform: 'uppercase',
    transition: 'all .1s linear',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  successPageProgressMarker: {
    backgroundColor: theme.colors.charcoalLight,
    position: 'absolute',
    transform: 'translate(0, -50%)',
    left: -24,
    top: '50%',
    height: 12,
    width: 12,
    borderRadius: '50%',
  },
  successPageActionRow: {
    height: 54,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 12px',
    '& svg': {
      transition: 'all .1s linear',
      transform: 'translateX(-2px)',
    },
    '&:hover': {
      backgroundColor: theme.colors.skyBase,
      '& > svg': {
        transform: 'translateX(2px)',
      },
    },
  },
  arrowIcon: {
    color: theme.colors.navyBase,
    fontSize: 15,
  },
}))

function SuccessPage({ page, idx, pageCopy }) {
  const {
    lightboxSettings, values, actions, builder,
  } = React.useContext(BuilderContainerContext)
  const { selectedPage } = values
  const settings = lightboxSettings.getSettings()
  const lastPage = settings.pages.length - 1

  const css = useStyles({ focused: selectedPage === lastPage })

  const onPageClick = () => actions.setSelectedPage(lastPage)

  const onEditClick = () => {
    actions.setSelectedPage(idx)
    builder.drawer.open(
      { page, type: 'page', index: idx },
      { title: pageCopy.editorTitle, withBackButton: true }
    )
  }

  return (
    <div onClick={onPageClick} className={css.successPage}>
      <div className={css.successPageTitle}>
        <div className={css.successPageProgressMarker} />
        <Text navyBase size={10}>
          Thank You
          {' '}
          {pageCopy.key}
        </Text>
      </div>

      <div className={css.successPageActionRow} onClick={onEditClick}>
        <Text navyBase size={14}>
          {pageCopy.description}
        </Text>
        <ArrowIcon className={css.arrowIcon} />
      </div>
    </div>
  )
}

SuccessPage.propTypes = {
  page: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
  pageCopy: PropTypes.object.isRequired,
}

export default React.memo(SuccessPage)

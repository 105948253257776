import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  makeStyles,
  Drawer,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  ListItemIcon,
  Avatar,
  Collapse,
} from '@material-ui/core'
import { Link } from 'react-router-dom'

import {
  CloseSharp,
  KeyboardArrowDownOutlined,
  KeyboardArrowRightOutlined,
} from '@material-ui/icons'

import { H4, H6, P } from 'components/texts'

import history from 'utils/getHistory'

const useStyles = makeStyles(() => ({
  list: {
    height: '100vh',
    overflowY: 'scroll',
  },
  brand: {
    padding: '16px 0 0 12px',
    margin: '0 0 0 8',
    '& h4': {
      marginLeft: 12,
      marginTop: 8,
    },
  },
  open: {
    marginTop: 24,
    transform: 'rotate(180deg)',
    transition: 'transform 300ms',
  },
  closed: {
    marginTop: 24,
    transition: 'transform 300ms',
  },
  smsMarketingOpen: {
    marginLeft: 4,
    marginRight: 4,
  },
  smsMarketingClose: {
    marginTop: 4,
    '& h4': {
      marginTop: 2,
    },
  },
  rightOpen: {
    marginLeft: -4,
    marginRight: 12,
    transform: 'rotate(180deg)',
    transition: 'transform 300ms',
  },
  rightClosed: {
    transition: 'transform 300ms',
  },
}))

const logout = () => {
  sessionStorage.setItem('extendedTrialModalShown', false)

  if (window.gapi && window.gapi.auth2) {
    const auth2 = window.gapi.auth2.getAuthInstance()
    if (auth2 && auth2.signOut) auth2.signOut().then(() => console.log('User signed out.'))
  }

  const newHost = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:5000'
  setTimeout(() => (window.location.href = `${newHost}/logout`), 500)
  history.push('/')
}

const useListItemStyles = makeStyles(theme => ({
  root: {
    height: props => (props.isTop ? 64 : 40),
    padding: props => (props.small ? '12px 12px 12px 75px' : 12),
    margin: props => (props.small ? '7px 8px' : 8),
    color: theme.colors.ink,
  },
  divider: {
    // functions required for weird race condition from props above
    margin: () => 0,
    padding: () => '20px 20px 28px 20px',
  },
}))

function ListItemLink(props) {
  const {
    children, small, azure, ...rest
  } = props
  const classes = useListItemStyles({ small })
  const Text = azure ? H6 : small ? P : H4
  return (
    <ListItem component={Link} classes={classes} {...rest}>
      <Text azure={azure}>{children}</Text>
    </ListItem>
  )
}

ListItemLink.propTypes = {
  children: PropTypes.node,
  small: PropTypes.bool,
  azure: PropTypes.bool,
}

ListItemLink.defaultProps = {
  children: null,
  small: false,
  azure: false,
}

function ListItemCloseButton(props) {
  const classes = useListItemStyles({ isTop: true })
  return (
    <List {...props}>
      <ListItem divider classes={classes}>
        <ListItemSecondaryAction>
          <ListItemIcon edge="end">
            <CloseSharp fontSize="large" />
          </ListItemIcon>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  )
}

function MyAccountMenuMobile(props) {
  const { open, onClose, currentBrand } = props

  const [subMenuOpen, setSubMenuOpen] = useState(false)
  const [smsMarketingMenuOpen, setSmsMarketingMenuOpen] = useState(false)
  const classes = useStyles()

  const handleClick = e => {
    e.stopPropagation()
    setSubMenuOpen(_open => !_open)
  }

  const handleSmsMarketingClick = e => {
    e.stopPropagation()
    setSmsMarketingMenuOpen(_open => !_open)
  }

  const closeSmsPlusMenu = () => {
    setSmsMarketingMenuOpen(false)
    onClose()
  }

  const closeBrandPlusMenu = () => {
    setSubMenuOpen(false)
    onClose()
  }

  return (
    <Drawer anchor="right" open={open} onClose={closeBrandPlusMenu}>
      <ListItemCloseButton onClick={onClose} />
      <List onClick={onClose} className={classes.list}>
        <ListItemLink to="/">Discover</ListItemLink>
        <ListItemLink to="/sweepstakes">Sweepstakes</ListItemLink>
        <ListItem className={classes.smsMarketingOpen} onClick={handleSmsMarketingClick}>
          <ListItemText>SMS Marketing</ListItemText>
          <ListItemSecondaryAction onClick={handleSmsMarketingClick}>
            <ListItemIcon edge="end">
              <KeyboardArrowRightOutlined fontSize="large" className={classes.rightClosed} />
            </ListItemIcon>
          </ListItemSecondaryAction>
          <Drawer anchor="right" open={smsMarketingMenuOpen} onClose={closeSmsPlusMenu}>
            <ListItemCloseButton onClick={onClose} />
            <List onClick={onClose} className={classes.list}>
              <ListItem className={classes.smsMarketingClose} onClick={handleSmsMarketingClick}>
                <ListItemIcon>
                  <KeyboardArrowRightOutlined fontSize="large" className={classes.rightOpen} />
                </ListItemIcon>
                <H4 ink>
                  <strong>SMS Marketing</strong>
                </H4>
              </ListItem>
              <ListItemLink to="/sms-marketing/dashboard">Dashboard</ListItemLink>
              <ListItemLink to="/sms-marketing/acquisition-tools">Acquisition Tools</ListItemLink>
              <ListItemLink to="/sms-marketing/campaigns">Campaigns</ListItemLink>
              <ListItemLink to="/sms-marketing/automations">Automations</ListItemLink>
              <ListItemLink to="/sms-marketing/Segments">Segments</ListItemLink>
              <ListItemLink to="/sms-marketing/settings">Settings</ListItemLink>
            </List>
          </Drawer>
        </ListItem>
        {/*<ListItemLink to="/partnerships/opportunities">Opportunities</ListItemLink>*/}
        <ListItemLink to="/analytics">Analytics</ListItemLink>
        <ListItemLink to="/favorites">Favorites</ListItemLink>
        <ListItemLink to="/calendar" divider>
          Calendar
        </ListItemLink>
        <ListItem className={classes.brand} onClick={handleClick}>
          <ListItemAvatar>
            <Avatar alt={currentBrand.accountname} src={currentBrand.logo} />
          </ListItemAvatar>
          <ListItemText style={{ maxWidth: '200px' }}>
            <H4 ellipsis>
              <b>{currentBrand.accountname}</b>
            </H4>
          </ListItemText>
          <ListItemSecondaryAction onClick={handleClick}>
            <ListItemIcon edge="end">
              <KeyboardArrowDownOutlined
                fontSize="large"
                className={subMenuOpen ? classes.open : classes.closed}
              />
            </ListItemIcon>
          </ListItemSecondaryAction>
        </ListItem>
        <Collapse in={subMenuOpen} timeout="auto" unmountOnExit>
          <List component="div">
            <ListItemLink small to={`/explore/brands/${currentBrand.id}`}>
              Brand Profile
            </ListItemLink>
            <ListItemLink small to="/profile/brand/">
              Account Settings
            </ListItemLink>
            <ListItemLink small to="/reviews">
              Reviews
            </ListItemLink>
            <ListItemLink small azure to="/profile/brand/referrals">
              Refer a Brand & Get $25
            </ListItemLink>
          </List>
        </Collapse>
        <ListItemLink
          to="/profile/switch-brand"
          divider
          style={{
            marginTop: !subMenuOpen ? '12px' : null,
            transition: 'margin 300ms',
          }}
        >
          Manage Brands
        </ListItemLink>
        <ListItemLink to="/profile/user/info">Personal Settings</ListItemLink>
        <ListItemLink to="/" onClick={logout}>
          Log Out
        </ListItemLink>
      </List>
    </Drawer>
  )
}

MyAccountMenuMobile.propTypes = {
  currentBrand: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default React.memo(MyAccountMenuMobile)

import React from 'react'
import { useSelector } from 'react-redux'

import { getClaim } from 'actions/brandClaims'

import SignupFormsIcon from 'images/icons/signup-forms.svg'
import soloSweepsIcon from 'images/icons/sweepstakes/solo-avatar.svg'
import partnershipSweepsIcon from 'images/icons/partnership-sweepstakes.svg'
import smsCampaignIcon from 'images/icons/sms-campaign.svg'
import automationsIcon from 'images/icons/automations.svg'

import CreateNewMenu from 'components/virtual/menus/CreateNewMenu'
import DropdownButton from 'components/virtual/buttons/DropdownButton'

function CreateNewButton() {
  const canCreatePartnershipSweepstakes = getClaim('sweepstakes.partnership.create')
  const canCreateSoloSweepstakes = getClaim('sweepstakes.solo.create')
  const canViewSignupForm = useSelector(
    state => state.currentBrand.features.canViewSignupForm
  ) || false

  const options = [
    canViewSignupForm && {
      icon: <img alt="Signup Forms" src={SignupFormsIcon} />,
      label: 'Signup Forms',
      description: 'Turn your website visitors into subscribers',
      to: '/sms-marketing/acquisition-tools?signup_form=true',
    },
    {
      icon: <img alt="Solo Sweepstakes" src={soloSweepsIcon} />,
      label: 'Solo Sweepstakes',
      description: 'Engage leads and scale your audience',
      locked: !canCreateSoloSweepstakes,
      to: !canCreateSoloSweepstakes ? '/upgrade-plan' : '/new-solo-sweepstakes',
    },
    {
      icon: <img alt="Partnership Sweepstakes" src={partnershipSweepsIcon} />,
      label: 'Partnership Sweepstakes',
      description: 'Partner up and expand your audience reach',
      locked: !canCreatePartnershipSweepstakes,
      to: !canCreatePartnershipSweepstakes ? '/upgrade-plan' : '/new-campaign',
    },
    {
      icon: <img alt="SMS Campaign" src={smsCampaignIcon} />,
      label: 'SMS Campaign',
      description: 'Engage with your SMS subscribers',
      to: '/sms-marketing/campaigns/new',
    },
    {
      icon: <img alt="Automations" src={automationsIcon} />,
      label: 'Automations',
      description: 'Set your SMS engagement on autopilot',
      to: '/sms-marketing/automations',
    },
  ].filter(Boolean)

  return <DropdownButton label="Create New" options={options} menuComponent={CreateNewMenu} />
}

export default React.memo(CreateNewButton)

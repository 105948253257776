import React, { useState } from 'react'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'

import { isWebsite } from 'utils/formValidations'

import { Input } from 'components/inputs'
import { P } from 'components/texts'
import { Button } from 'components/buttons'
import validator from 'validator'

import { SMSEditorContext } from 'contexts/SMSEditorContext'

const useStyles = makeStyles(theme => ({
  wrapper: {
    border: `1px solid ${theme.colors.silver}`,
    width: 552,
    height: 120,
    position: 'absolute',
    zIndex: 1,
    top: 'calc(100% + 2px)',
    left: 0,
    padding: 16,
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
  },
  input: {
    marginTop: 12,
    display: 'flex',
    alignItems: 'flex-end',
    '& > div:first-child': {
      // Input component
      width: 'calc(100% - 140px)',
      marginRight: 10,
      '& ::placeholder': {
        marginTop: 2,
      },
    },
    '& > button': {
      flex: 1,
      marginBottom: 2,
    },
  },
}))

const VALIDATOR_OPTIONS = {
  require_host: true,
  require_protocol: true,
  require_valid_protocol: true,
}

function LinkInsertModal(props) {
  const { show, onClose } = props
  const [link, setLink] = useState('')
  const [disable, setDisabled] = useState(true)
  const {
    actions: { onLinkMinifyAndInsert },
  } = React.useContext(SMSEditorContext)
  const css = useStyles()

  if (!show) return null

  const onLinkShortened = () => {
    onClose()
    setLink('')
    setDisabled(false)
  }

  const onInsert = () => {
    setDisabled(true)
    onLinkMinifyAndInsert(link, onLinkShortened, true)
  }

  const onType = value => {
    setLink(value)
    setDisabled(!validator.isURL(value, VALIDATOR_OPTIONS))
  }

  return (
    <ClickAwayListener onClickAway={onClose}>
      <Paper classes={{ root: css.wrapper }}>
        <P>Minimize the number of characters and help us to track your conversions.</P>
        <div className={css.input}>
          <Input
            value={link}
            onChange={onType}
            validations={[isWebsite]}
            label="Original Link"
            placeholder="Paste Your Link"
            // TODO: Find out why placeholder is not aligned
          />
          <Button disabled={disable} onClick={onInsert} label="Shorten & Track" />
        </div>
      </Paper>
    </ClickAwayListener>
  )
}

LinkInsertModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default React.memo(LinkInsertModal)

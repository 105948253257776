import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import { MobileModal } from 'components/modals'
import { ButtonGroup } from 'components/buttons'

import MediaTabs from './MediaTabs'
import UploadImageSection from './UploadImageSection'
import SearchImageSection from './SearchImageSection'
import SearchGIFSection from './SearchGIFSection'

const useStyles = makeStyles(() => ({
  wrapper: {
    width: 672,
    height: 654,
    padding: 20,
    '& [class*=buttons]': {
      paddingRight: 32,
    },
  },
}))

const TABS = ['Upload', 'Stock Photos', 'Giphy']

function InsertImageGIFModal(props) {
  const { onSubmit, open, onClose } = props
  const css = useStyles()

  const [selectedTab, setSelectedTab] = useState(0)
  const [selectedMedia, setSelectedMedia] = useState(null)

  const onTabChange = tab => {
    setSelectedTab(tab)
    setSelectedMedia(null)
  }

  const onInsert = () => {
    onSubmit(selectedMedia)
    setSelectedMedia(null)
  }

  return (
    <MobileModal allowOutsideClick={false} open={open} noPad>
      <div className={css.wrapper}>
        <MediaTabs tabs={TABS} selectedTab={selectedTab} onChange={onTabChange} onClose={onClose} />
        {
          [
            <UploadImageSection onMediaSelect={setSelectedMedia} />,
            <SearchImageSection onMediaSelect={setSelectedMedia} />,
            <SearchGIFSection onMediaSelect={setSelectedMedia} />,
          ][selectedTab]
        }
        <ButtonGroup
          border
          submitLabel="Insert"
          canSubmit={!!selectedMedia}
          onSubmit={onInsert}
          onCancel={onClose}
        />
      </div>
    </MobileModal>
  )
}

InsertImageGIFModal.propTypes = {
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

InsertImageGIFModal.defaultProps = {
  open: false,
  onSubmit() {},
  onClose() {},
}

export default React.memo(InsertImageGIFModal)

import React from 'react'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import CompactBanner from './CompactBanner'

function ESPRequiredOrNotSupportedBanner() {
  const {
    values: { containsESPValue },
    builder: { errors },
  } = React.useContext(BuilderContainerContext)

  const isRequired = errors.verify('ESPRequired')
  const isNotSupported = errors.verify('ESPNotSupported')

  if (!containsESPValue) return null

  if (!isRequired && !isNotSupported) return null

  if (isRequired) return <CompactBanner text="Field values require ESP connection." type="error" />

  return <CompactBanner text="Default ESP is not supported for forms." type="error" />
}

export default React.memo(ESPRequiredOrNotSupportedBanner)

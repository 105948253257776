import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import ESPNotSupportedBanner from 'views/mobile/lightbox-builder/banners/ESPNotSupportedBanner'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

import FormPagesTitleAddPageButton from './FormPagesTitleAddPageButton'
import FormPage from './FormPage'
import SuccessPage from './SuccessPage'
import MissingFieldsBanner from '../../../../../banners/MissingFieldsBanner'

const useStyles = makeStyles(theme => ({
  title: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  pagesContainer: {
    height: 'fit-content',
    position: 'relative',
    paddingLeft: 24,
    marginTop: 14,
    '& > :not(:first-child)': {
      marginTop: 12,
    },
  },
  progressLine: {
    position: 'absolute',
    top: 0,
    left: 6,
    height: 'calc(100% - 101px)', // Offsets the bottom of SuccessPage
    width: 1,
    backgroundColor: theme.colors.charcoalLight,
    margin: '25px 0 0 0',
  },
}))

const PAGE_COPY_BY_FORM_TYPE = {
  popup: {
    key: 'page',
    description: 'Images & Content Blocks',
    editorTitle: 'Success Page Content',
  },
  embed: {
    key: 'step',
    description: 'Content Blocks',
    editorTitle: 'Thank You: Content Blocks',
  },
}

function FormPages() {
  const css = useStyles()
  const { lightboxSettings } = React.useContext(BuilderContainerContext)
  const { pages, settings } = lightboxSettings.getSettings()

  let formType = settings.type
  if (formType === 'lightbox') formType = 'popup'

  let formDescriptor = 'page'
  if (formType === 'embed') formDescriptor = 'step'

  const pageCopy = PAGE_COPY_BY_FORM_TYPE[formType]

  return (
    <div>
      <ESPNotSupportedBanner />
      <MissingFieldsBanner formDescriptor={formDescriptor} />
      <FormPagesTitleAddPageButton />

      <div className={css.pagesContainer}>
        <div className={css.progressLine} />

        {/* slicing last page (success page) */}
        {pages.slice(0, -1).map((page, index) => (
          <FormPage
            key={`page_${index}`.toString()}
            index={index}
            page={page}
            pageCopy={pageCopy}
          />
        ))}

        <SuccessPage page={pages[pages.length - 1]} idx={pages.length - 1} pageCopy={pageCopy} />
      </div>
    </div>
  )
}

export default React.memo(FormPages)

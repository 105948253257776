import { useContext, useEffect } from 'react'
import uid from 'uuid/v4'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

const useEditLightboxFields = () => {
  const context = useContext(BuilderContainerContext)

  const {
    builder: {
      drawer: { data: drawerData },
    },
    actions: lightboxActions,
  } = context

  const { data: input } = drawerData ?? {}

  useEffect(() => {
    if (input?.type === 'dropdown') {
      input.dropdownOptionsWithIDs = input.dropdownOptions.map(value => ({ value, id: uid() }))
    }
  }, [input])

  if (!drawerData) return [null]

  const actions = {}

  actions.onLabelChange = label => {
    input.label = label || null
  }
  actions.onPlaceholderChange = placeholder => {
    input.placeholder = placeholder || null
  }
  actions.onRequiredChange = isRequired => {
    input.isRequired = isRequired
  }
  actions.onESPChange = espValue => {
    input.espValue = espValue || null
  }

  actions.onValidateESPField = async () => lightboxActions.onValidateESPValue(input.espValue)

  if (input?.type === 'dropdown') {
    // dropdownOptionsWithIDs is a workaround to the current items, as they're needed
    // in order to prevent ui issues
    actions.onOptionAdd = () => {
      input.dropdownOptionsWithIDs = [...input.dropdownOptionsWithIDs, { value: '', id: uid() }]

      input.dropdownOptions = [
        ...input.dropdownOptions,
        '', // add a new one
      ]
    }

    actions.onOptionChange = (idx, newValue) => {
      const newOptions = input.dropdownOptions.map((value, index) =>
        (index === idx ? newValue : value))

      const newOptionsWithIDs = input.dropdownOptionsWithIDs.map((option, index) =>
        (index === idx ? { ...option, value: newValue } : option))
      input.dropdownOptionsWithIDs = newOptionsWithIDs
      input.dropdownOptions = newOptions
    }

    actions.onOptionRemove = index => {
      const filterFn = (_, idx) => idx !== index
      input.dropdownOptionsWithIDs = input.dropdownOptionsWithIDs.filter(filterFn)
      input.dropdownOptions = input.dropdownOptions.filter(filterFn)
    }
  }

  return [input, actions]
}

export default useEditLightboxFields

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import Checkbox from './Checkbox'
import NoBorderInput from './NoBorderInput'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'start',
    width: '100%',
    height: 40,
    borderRadius: 2,
    border: `1px solid ${theme.colors.silver}`,
  },
  error: {
    border: `1px solid ${theme.colors.alertRed}`,
  },
  checkboxContainer: {
    width: '100px !important',
    height: 38,
    borderRight: `1px solid ${theme.colors.silver}`,
    '& > label': {
      marginLeft: 0,
      '& [class*=MuiIconButton-root]': {
        height: 36,
        width: 36,
      },
      '& > div': {
        marginTop: 13,
      },
    },
  },
  inputContainer: {
    height: 38,
    width: 'calc(100% - 100px)',
    '& [class*=MuiFormControl-root]': {
      margin: 0,
      '& [class*=MuiInput-root]': {
        minHeight: 38,
      },
    },
    '& div': {
      borderRadius: 0,
    },
  },
  borderRight: {
    borderRight: `1px solid ${theme.colors.silver}`,
  },
  twoInputs: {
    width: 'calc(50% - 50px)',
  },
}))

function CheckboxPlusInput({
  label,
  disabled,
  checked,
  twoInputs,
  value,
  value2,
  placeholder,
  placeholder2,
  onCheck,
  onChange,
  error,
  validations,
  formatter,
}) {
  const classes = useStyles()
  const [isChecked, setIsChecked] = useState(checked)
  const [valueA, setValueA] = useState(value)
  const [valueB, setValueB] = useState(value2)

  const [blurredA, setBlurredA] = useState(true)
  const [blurredB, setBlurredB] = useState(true)

  const onCheckChange = () => {
    setIsChecked(!isChecked)
    const key = twoInputs ? 'values' : 'value'
    const val = twoInputs ? [valueA, valueB] : valueA

    return onCheck({ checked: !isChecked, [key]: val })
  }
  const onChangeValueA = newValue => {
    if (validations.some(validate => validate(newValue))) return null

    setValueA(newValue)
    return onChange(twoInputs ? [newValue, valueB] : newValue)
  }
  const onChangeValueB = newValue => {
    if (validations.some(validate => validate(newValue))) return null

    setValueB(newValue)
    return onChange([valueA, newValue])
  }

  const setError = isChecked && error

  return (
    <div className={`${classes.container} ${setError ? classes.error : ''}`}>
      <div className={classes.checkboxContainer}>
        <Checkbox disabled={disabled} checked={isChecked} label={label} onChange={onCheckChange} />
      </div>
      <div
        className={`${classes.inputContainer} ${
          twoInputs ? `${classes.twoInputs} ${classes.borderRight}` : ''
        }`}
      >
        <NoBorderInput
          disabled={!isChecked}
          required
          value={blurredA && valueA ? formatter(valueA) : valueA}
          onChange={onChangeValueA}
          placeholder={placeholder}
          onBlur={() => setBlurredA(true)}
          onFocus={() => setBlurredA(false)}
        />
      </div>
      {twoInputs && (
        <div className={`${classes.inputContainer} ${classes.twoInputs}`}>
          <NoBorderInput
            required
            disabled={!isChecked}
            value={blurredB && valueB ? formatter(valueB) : valueB}
            onChange={onChangeValueB}
            placeholder={placeholder2}
            onBlur={() => setBlurredB(true)}
            onFocus={() => setBlurredB(false)}
          />
        </div>
      )}
    </div>
  )
}

CheckboxPlusInput.propTypes = {
  label: PropTypes.node,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  error: PropTypes.bool,
  twoInputs: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  placeholder2: PropTypes.string,
  onChange: PropTypes.func,
  onCheck: PropTypes.func,
  validations: PropTypes.arrayOf(PropTypes.func),
  formatter: PropTypes.func,
}

CheckboxPlusInput.defaultProps = {
  label: '',
  disabled: false,
  checked: false,
  error: false,
  twoInputs: false,
  value: '',
  value2: '',
  placeholder: '0',
  placeholder2: '0',
  onChange() {},
  onCheck() {},
  validations: [],
  formatter: val => val,
}

export default React.memo(CheckboxPlusInput)

import moment from 'moment-timezone'

export function relativeDate(timestamp) {
  let formattedTimestamp = ''
  const userTimezone = moment.tz.guess()

  if (moment.tz(timestamp, userTimezone).isAfter(moment.tz(userTimezone).startOf('day'))) {
    formattedTimestamp = moment.tz(timestamp, userTimezone).format('h:mm a')
  } else if (
    moment.tz(timestamp, userTimezone).isBetween(
      moment
        .tz(userTimezone)
        .startOf('day')
        .subtract(1, 'day'),
      moment.tz(userTimezone).startOf('day')
    )
  ) {
    formattedTimestamp = 'Yesterday'
  } else if (
    moment.tz(timestamp, userTimezone).isBetween(
      moment
        .tz(userTimezone)
        .startOf('day')
        .subtract(5, 'days'),
      moment
        .tz(userTimezone)
        .startOf('day')
        .subtract(1, 'day')
    )
  ) {
    formattedTimestamp = moment.tz(timestamp, userTimezone).format('ddd')
  } else if (
    moment.tz(timestamp, userTimezone).isBetween(
      moment
        .tz(userTimezone)
        .startOf('day')
        .subtract(1, 'year'),
      moment
        .tz(userTimezone)
        .startOf('day')
        .subtract(1, 'day')
    )
  ) {
    formattedTimestamp = moment.tz(timestamp, userTimezone).format('MMM D')
  } else {
    formattedTimestamp = 'More than a year ago'
  }

  return formattedTimestamp
}

export function messagingRelativeDate(timestamp) {
  let formattedTimestamp = ''
  const userTimezone = moment.tz.guess()

  if (moment.tz(timestamp, userTimezone).isAfter(moment.tz(userTimezone).startOf('day'))) {
    formattedTimestamp = moment.tz(timestamp, userTimezone).format('h:mm a')
  } else if (
    moment.tz(timestamp, userTimezone).isBetween(
      moment
        .tz(userTimezone)
        .startOf('day')
        .subtract(1, 'year'),
      moment
        .tz(userTimezone)
        .startOf('day')
        .subtract(1, 'day')
    )
  ) {
    formattedTimestamp = moment.tz(timestamp, userTimezone).format('MMM D')
  } else {
    formattedTimestamp = moment.tz(timestamp, userTimezone).format('M/D/YY')
  }

  return formattedTimestamp
}

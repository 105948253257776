import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Lock } from '@material-ui/icons'

import VirtualTheme from 'components/virtual/VirtualTheme'
import Button from 'components/virtual/buttons/Button'
import Text from 'components/texts/Text'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import BeforeLeavingWarningModal from '../modals/ESPIntegrationsModal/BeforeLeavingWarningModal'

const useStyles = makeStyles(theme => ({
  limitBanner: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100vw',
    zIndex: 100,
    height: 72,
    backgroundColor: theme.colors.skyLight,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > :not(:last-child)': {
      marginRight: 20,
    },
  },
  lockIcon: {
    color: theme.colors.charcoalDarker,
    width: 20,
  },
}))

function SubscriberLimitReachedBanner() {
  const css = useStyles()
  const [showModal, setShowModal] = useState(false)
  const { values, actions } = React.useContext(BuilderContainerContext)
  const { hasSubscribersLeft } = values
  const { onSaveBeforeUpgrade } = actions

  if (hasSubscribersLeft) return null

  return (
    <>
      <div className={css.limitBanner}>
        <Lock className={css.lockIcon} />
        <Text size={16} charcoalDarker>
          You&apos;ve hit your subscriber limit!
          {' '}
        </Text>
        <VirtualTheme>
          <Button size="smaller" label="Upgrade" onClick={() => setShowModal(true)} />
        </VirtualTheme>
      </div>
      <BeforeLeavingWarningModal
        show={showModal}
        onCancel={() => setShowModal(false)}
        onConfirm={onSaveBeforeUpgrade}
      />
    </>
  )
}

export default React.memo(SubscriberLimitReachedBanner)

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import cn from 'classnames'

import styleHelper from 'utils/styleHelper'
import useMobileDetect from 'hooks/useMobileDetect'

import Text from 'components/texts/Text'
import PageCounter from 'components/virtual/utility/PageCounter'

import { ReactComponent as Lightbulb } from 'images/icons/lightbulb-icon.svg'
import { ReactComponent as LightbulbNavy } from 'images/icons/lightbulb-icon-navy.svg'

const useStyles = makeStyles(theme => ({
  blurb: {
    [styleHelper.max(1023)]: {
      alignItems: 'center',
      display: 'flex',
    },
  },
  bottomEllipse: {
    background: theme.colors.navyBase,
    bottom: -252,
    borderRadius: '50%',
    height: 287,
    position: 'absolute',
    right: -230,
    transform: 'matrix(-0.94, 0.33, -0.33, -0.94, 0, 0)',
    width: 611,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  dynamicSizing: {
    [styleHelper.max(1023)]: {
      width: '66%',
      margin: 'auto',
    },
    [styleHelper.mobile]: {
      width: '100%',
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 72px)',
    justifyContent: 'space-between',
    position: 'absolute',
    width: 'calc(100% - 48px)',
    zIndex: 2,
    [styleHelper.max(1023)]: {
      flexDirection: 'row',
      height: 'calc(100% - 32px)',
      width: 'calc(100% - 64px)',
    },
    [styleHelper.mobile]: {
      height: 'calc(100% - 24px)',
      width: 'calc(100% - 32px)',
    },
  },
  headerText: {
    '& p': {
      color: theme.colors.blueLighter,
    },
    [styleHelper.max(1023)]: {
      '& p': {
        color: 'white',
      },
    },
  },
  hideOnTablet: {
    [styleHelper.max(1023)]: {
      display: 'none',
    },
  },
  lightbulb: {
    margin: '0px 0px 12px 0px',
    [styleHelper.max(1023)]: {
      margin: '0px 20px 0px 0px',
    },
  },
  main: {
    background: theme.colors.blueDarker,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    padding: '36px 24px',
    position: 'relative',
    width: '100%',
    [styleHelper.max(1023)]: {
      background: theme.colors.navyDarker,
      padding: '16px 32px',
    },
    [styleHelper.mobile]: {
      padding: '12px 16px',
    },
  },
  subtitle: {
    [styleHelper.max(1023)]: {
      marginBottom: 16,
    },
    [styleHelper.mobile]: {
      marginBottom: 8,
    },
  },
  tabletInfo: {
    backgroundColor: theme.colors.skyLight,
    display: 'none',
    padding: '24px 16px',
    width: '100%',
    [styleHelper.max(1023)]: {
      display: 'inline',
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    minHeight: 277,
    zIndex: 2,
    [styleHelper.max(1023)]: {
      minHeight: 'auto',
    },
  },
  title: {
    marginBottom: 16,
    [styleHelper.max(1023)]: {
      marginBottom: 12,
    },
  },
  topEllipse: {
    background: theme.colors.navyBase,
    borderRadius: '50%',
    height: 583,
    left: -720,
    position: 'absolute',
    top: -288,
    transform: 'matrix(-0.99, -0.1, 0.1, -0.99, 0, 0)',
    width: 1244,
  },
}))

function Sidebar(props) {
  const {
    blurb, page, subtitle, title,
  } = props
  const classes = useStyles()
  const isTablet = useMobileDetect().max(1024)

  const renderBlurb = () => (
    <div className={classes.dynamicSizing}>
      <div className={classes.blurb}>
        <div className={classes.lightbulb}>{isTablet ? <LightbulbNavy /> : <Lightbulb />}</div>
        <Text size={12} white={!isTablet} navyDarker={isTablet}>
          {blurb}
        </Text>
      </div>
    </div>
  )

  return (
    <div className={classes.container}>
      <div className={classes.main}>
        <div className={classes.header}>
          <div className={classes.headerText}>
            <Text size={10}>CREATE BRAND PROFILE</Text>
          </div>
          <PageCounter numPages={3} page={page} />
        </div>
        <div className={cn(classes.textContainer, classes.dynamicSizing)}>
          <div className={classes.title}>
            <Text size={34} white>
              {title}
            </Text>
          </div>
          <div className={classes.subtitle}>
            <Text size={16} skyLight>
              {subtitle}
            </Text>
          </div>
        </div>
        <div className={classes.hideOnTablet}>{renderBlurb()}</div>
        <div className={cn(classes.topEllipse, classes.hideOnTablet)} />
        <div className={cn(classes.bottomEllipse, classes.hideOnTablet)} />
      </div>
      <div className={classes.tabletInfo}>{renderBlurb()}</div>
    </div>
  )
}

Sidebar.propTypes = {
  blurb: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default React.memo(Sidebar)

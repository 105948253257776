import React from 'react'
import PropTypes from 'prop-types'

import GenericModal from 'components/virtual/modals/GenericModal'

function ShortUrlWarningModal(props) {
  const { onCancel, onConfirm, show } = props

  return (
    <GenericModal
      title="Oops – it looks like you used an URL shortener!"
      onCancel={onCancel}
      onConfirm={onConfirm}
      cancelLabel="Ignore"
      confirmLabel="Update Link"
      show={show}
      canSave
      body={(
        <>
          Links shorted by a third-party service–such as Bitly, TinyURL, or Rebrandly-risk being
          blocked, increase your chance of being marked as spam, and interrupt your campaign&apos;s
          reporting and attribution!
          <br />
          <br />
          DojoMojo automatically shortens links for you. Paste the original link in the editor, and
          see how it&apos;s trimmed in the text preview.
        </>
      )}
    />
  )
}

ShortUrlWarningModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  show: PropTypes.bool,
}

ShortUrlWarningModal.defaultProps = {
  show: false,
}

export default React.memo(ShortUrlWarningModal)

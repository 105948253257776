import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import cn from 'classnames'

import { ReactComponent as Checkmark } from 'images/checkmark-green-bg.svg'
import giphyLogo from 'images/logos/powered-by-giphy.png'

import { SearchInput } from 'components/inputs'
import { Spinner } from 'components/spinners'
import useImageSearch from 'hooks/useImageSearch'

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: '24px 12px',
  },
  subtext: {
    textAlign: 'right',
    marginTop: 12,
    '& img': {
      height: 16,
      marginRight: -8,
    },
  },
  imagesPreview: {
    display: 'grid',
    gridTemplateColumns: '200px 200px 200px',
    gridAutoRows: '128px',
    gridColumnGap: 4,
    gridRowGap: 4,
    overflowY: 'auto',
    position: 'relative',
    margin: '8px 0 0',
    height: 344,
  },
  preview: {
    backgroundColor: theme.colors.silver,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '& img': {
      width: '100%',
    },
  },
  selected: {
    position: 'relative',
    border: `4px solid ${theme.colors.seaGreen}`,
    borderRadius: 2,
  },
  checkmark: {
    position: 'absolute',
    top: 4,
    left: 4,
    height: 16,
    width: 16,
  },
  spinner: props => {
    if (props.all) return null

    return {
      position: 'relative',
      gridColumn: '1/4',
    }
  },
}))

const POWERED_BY = (
  <a href="https://giphy.com/" target="_blank" rel="noopener noreferrer">
    <img src={giphyLogo} alt="giphy" />
  </a>
)

function SearchGIFSection(props) {
  const { onMediaSelect } = props
  const {
    onScroll, onQueryChange, loading, images: gifs, query,
  } = useImageSearch({}, true)
  const [selectedGIF, setSelectedGIF] = useState(null)
  const css = useStyles({ all: !gifs.length })

  const onType = value => {
    setSelectedGIF(null)
    onQueryChange(value)
  }

  const onSelect = gif => {
    const selected = !selectedGIF || selectedGIF.id !== gif.id ? gif : null
    setSelectedGIF(selected)
    onMediaSelect(selected)
  }

  return (
    <section className={css.wrapper}>
      <div>
        <SearchInput value={query} onChange={onType} placeholder="Search GIFs" />
        <div className={css.subtext}>{POWERED_BY}</div>
      </div>
      <div onScroll={onScroll} className={css.imagesPreview}>
        {gifs.length > 0
          && gifs.map(gif => {
            const selected = selectedGIF && selectedGIF.id === gif.id
            return (
              <div
                key={gif.id}
                className={cn(css.preview, { [css.selected]: selected })}
                onClick={() => onSelect(gif)}
              >
                <img loading="lazy" src={gif.src} alt="preview" />
                {selected && <Checkmark className={css.checkmark} />}
              </div>
            )
          })}
        {loading && (
          <div className={css.spinner}>
            <Spinner full={false} />
          </div>
        )}
      </div>
    </section>
  )
}

SearchGIFSection.propTypes = {
  onMediaSelect: PropTypes.func.isRequired,
}

export default React.memo(SearchGIFSection)

import React from 'react'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

import ThemeButtons from './LightboxBuilderThemeButtons'
import ThemeColors from './LightboxBuilderThemeColors'
import ThemeLayout from './LightboxBuilderThemeLayout'
import ThemeLogo from './LightboxBuilderThemeLogo'

function LightboxBuilderThemeEdit() {
  const { builder } = React.useContext(BuilderContainerContext)

  const { drawer } = builder
  const { data: drawerData } = drawer

  const EditComponent = {
    buttons: ThemeButtons,
    layout: ThemeLayout,
    colors: ThemeColors,
    logo: ThemeLogo,
  }[drawerData?.section]

  return EditComponent && <EditComponent />
}

export default React.memo(LightboxBuilderThemeEdit)

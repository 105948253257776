import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import { Button } from 'components/buttons'
import SearchInput from 'components/inputs/SearchInput'
import { MobileModal } from 'components/modals'

import PreviousSearchSuggestions from './PreviousSearchSuggestions'
import SearchSuggestions from './SearchSuggestions'

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'absolute',
    top: 80,
    left: 0,
    width: '100vw',
    height: 'calc(100vh - 80px)',
    overflowY: 'auto',
  },
  inputWrapper: {
    height: 80,
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px 0 20px',
    borderBottom: `1px solid ${theme.colors.silver}`,
    backgroundColor: theme.colors.cream,
    '& button': {
      marginLeft: 4,
    },
    '& input': {
      padding: '12px 0 7px',
    },
  },
}))

function SearchWithFiltersModal(props) {
  const {
    show, onClose, onChange, onFilter, handleEnter, searchValue, suggestions,
  } = props

  const [search, setSearch] = useState(searchValue)
  const [focused, setFocused] = useState(false)
  const css = useStyles()

  const onSearchChange = val => {
    setFocused(true)
    setSearch(val)
    onChange(val)
  }

  const onEnter = val => {
    if (val) {
      setSearch(val)
    }

    handleEnter(val || search)
    setFocused(false)
    onClose()
  }

  const handleFilter = (attr, value, searchType) => {
    onFilter(attr, value, searchType)
    setFocused(false)
    onClose()
  }

  const previousSearchSuggestions = JSON.parse(localStorage.getItem('previousSearchSuggestions'))

  return (
    <MobileModal full open={show} noPad>
      <div className={css.inputWrapper}>
        <SearchInput
          onChange={onSearchChange}
          autoFocus
          onFocus={() => setFocused(true)}
          onBlur={() => setTimeout(() => setFocused(false), 100)}
          placeholder="Try fashion, food, fitness, travel..."
          value={search}
          onEnter={onEnter}
        />
        <Button type="text" label="Cancel" onClick={onClose} />
      </div>
      <div className={css.wrapper}>
        {focused && (
          <>
            {!search ? (
              <PreviousSearchSuggestions
                suggestions={previousSearchSuggestions}
                onSuggestionClick={onEnter}
                isMobile
              />
            ) : (
              <SearchSuggestions
                searchValue={search}
                suggestions={suggestions}
                onFilterClick={handleFilter}
                onSuggestionClick={onEnter}
                containerHeight={0}
              />
            )}
          </>
        )}
      </div>
    </MobileModal>
  )
}

SearchWithFiltersModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  handleEnter: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  suggestions: PropTypes.arrayOf(PropTypes.object),
}

SearchWithFiltersModal.defaultProps = {
  show: false,
  searchValue: '',
  suggestions: [],
}

export default React.memo(SearchWithFiltersModal)

import React, { useContext } from 'react'
import { Snackbar, SnackbarContent } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import { makeStyles } from '@material-ui/core/styles'
import Text from 'components/texts/Text'
import { ToasterContext } from 'contexts'

const useStyles = makeStyles(theme => ({
  background: {
    backgroundColor: ({ background }) => theme.colors[background],
  },
}))

function TransitionUp(props) {
  return <Slide {...props} direction="up" />
}

function Toaster() {
  const {
    open,
    message,
    closeToaster,
    background = 'stone',
    delay = 2000,
    action = null,
  } = useContext(ToasterContext)
  const css = useStyles({ background })

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      onClose={closeToaster}
      autoHideDuration={delay}
      TransitionComponent={TransitionUp}
      ClickAwayListenerProps={{
        onClickAway: closeToaster,
      }}
    >
      <SnackbarContent
        classes={{ root: css.background }}
        aria-describedby="message-id"
        message={(
          <span id="message-id">
            <Text white>{message}</Text>
          </span>
        )}
        action={action}
      />
    </Snackbar>
  )
}

export default React.memo(Toaster)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import Text from 'components/texts/Text'
import InputWithSideLabel from 'components/virtual/inputs/InputWithSideLabel'

const useStyles = makeStyles({
  option: {
    textTransform: 'capitalize',
    marginTop: 12,
  },
})

const CORNER_RADIUS_MIN = 0

function LightboxBuilderThemeButtons() {
  const css = useStyles()
  const {
    lightboxSettings,
    builder: { errors },
  } = React.useContext(BuilderContainerContext)
  const settings = lightboxSettings.getSettings()

  const radiusOptions = [
    {
      which: 'fields',
      label: 'Form Field Corner Radius',
      value: settings.fields.borderRadius,
      error: errors.verify('formFieldCornerRadius'),
    },
    {
      which: 'buttons',
      label: 'Button Corner Radius',
      // since both are the same, we get it from primaryButton
      value: settings.primaryButton.borderRadius,
      error: errors.verify('buttonCornerRadius'),
    },
  ]

  const onBorderRadiusChange = which => ({ target }) => {
    const borderRadius = Number(target.value || 0)

    if (!/^\d*$/.test(borderRadius)) return // we only accept numbers, pal

    if (which === 'fields') settings.fields = { borderRadius }
    else {
      settings.primaryButton = { borderRadius }
      settings.secondaryButton = { borderRadius }
    }
  }

  const onKeyDown = which => e => {
    let { value } = e.target
    switch (e.key) {
      case 'ArrowUp':
        value = Number(value) + 1
        break
      case 'ArrowDown':
        value = Math.max(CORNER_RADIUS_MIN, Number(value) - 1)
        break
      default:
        break
    }

    onBorderRadiusChange(which)({ target: { value } })
  }

  return (
    <div style={{ paddingTop: 24 }}>
      <Text size={16} charcoalBase>
        Corner Radius
      </Text>
      {radiusOptions.map(option => (
        <div className={css.option}>
          <InputWithSideLabel
            controlledInput
            label={option.label}
            value={option.value}
            error={option.error}
            onChange={onBorderRadiusChange(option.which)}
            onKeyDown={onKeyDown(option.which)}
          />
        </div>
      ))}
    </div>
  )
}

export default React.memo(LightboxBuilderThemeButtons)

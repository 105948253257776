import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import { MobileModal } from 'components/modals'
import { ButtonGroup } from 'components/buttons'
import { H3, P } from 'components/texts'
import { Checkbox } from 'components/inputs'

import useMobileDetect from 'hooks/useMobileDetect'

import UpdatedTerms from './UpdatedTerms'

const useStyles = makeStyles(() => ({
  wrapper: { maxWidth: 672 },
  mobile: {
    '& button': {
      width: '100%',
    },
    '& > label': {
      marginBottom: 24,
    },
  },
}))

const TITLE = 'Terms Of Use Update'

function UpdatedTermsModal({ open, onTermsAccept }) {
  const [checked, setChecked] = useState(false)
  const mobileDetect = useMobileDetect()
  const css = useStyles({ checked })

  const isMobile = mobileDetect.isMobile()

  return (
    <MobileModal open={open} title={<H3>{TITLE}</H3>} centerTitle>
      <div className={isMobile ? css.mobile : css.wrapper}>
        <UpdatedTerms />
        <Checkbox
          oldDesign
          label={(
            <P multiline>
              <strong>Yes, I agree to these Terms of Use updates.</strong>
            </P>
          )}
          onChange={setChecked}
          checked={checked}
        />
        <ButtonGroup
          relative
          canSubmit={checked}
          onCancel={null}
          onSubmit={onTermsAccept}
          submitLabel="Accept & Continue"
        />
      </div>
    </MobileModal>
  )
}

UpdatedTermsModal.propTypes = {
  open: PropTypes.bool,
  onTermsAccept: PropTypes.func.isRequired,
}

UpdatedTermsModal.defaultProps = {
  open: false,
}

export default React.memo(UpdatedTermsModal)

import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import moment from 'moment-timezone'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import ActionBanner from './ActionBanner'

function NoSMSCreditsAvailableBanner() {
  const { actions } = React.useContext(BuilderContainerContext)
  const { onSaveBeforeUpgrade } = actions
  const location = useLocation()
  const currentBrand = useSelector(state => state.currentBrand)
  const isBasic = currentBrand.belt === 'white'

  const onUpgradeSMSMarketingClick = () => {
    if (isBasic) onSaveBeforeUpgrade()
    else {
      localStorage.setItem(
        'future_app_return_url',
        JSON.stringify({
          value: `/app${location.pathname}`,
          expiry: moment().add(15, 'minutes'),
        })
      )

      window.location.href = '/app/manage-sms-marketing'
    }
  }

  const [actionLabel, title] = isBasic
    ? ['Select Plan', 'No SMS credits. SMS plan required to publish.']
    : ['Upgrade', 'No SMS credits available. Upgrade to publish.']

  return (
    <ActionBanner
      wrapperStyles={{ marginTop: 32 }}
      type="error"
      actionLabel={actionLabel}
      action={onUpgradeSMSMarketingClick}
      title={title}
    />
  )
}

export default React.memo(NoSMSCreditsAvailableBanner)

/* eslint-disable no-control-regex */
const charsMap = {
  ' ': [/([\u0000-\u0007]|[\u0010-\u0017]|[\u0021-\u0027]|[\u0030-\u0037]|\u0177)/],
  '~': ['˜'],
  '"': ['¨', '“', '„', '¨', '”', '˝'],
  "'": ['`', '´', '‘', '`', '´', '’'],
}

export default function replaceEscapeChars(text) {
  let hasSpecialChars = false

  Object.entries(charsMap).forEach(([key, patterns]) => {
    patterns.forEach(pattern => {
      const regex = new RegExp(pattern)
      if (regex.exec(text)) {
        text = text.replace(regex, key)
        hasSpecialChars = true
      }
    })
  })

  return { text, hasSpecialChars }
}

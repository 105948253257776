import { useEffect, useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import validator from 'validator'
import _ from 'lodash'
import moment from 'moment-timezone'
import axios from 'axios'
import qs from 'query-string'

import useLightboxSettings from '@innovationdepartment/dojo-signups-web-settings'

import { ToasterContext } from 'contexts'

import useLightboxFields from 'hooks/lightbox-builder/useLightboxFields'
import useESPIntegration from 'hooks/useESPIntegration'
import useBrandBank from 'hooks/useBrandBank'
import useBrandTcpa from 'hooks/useBrandTcpa'

import { checkPagesForField } from 'views/mobile/lightbox-builder/utils/check-page-fields'

function verifyIfValueIsBeingUsed(currentInput, pages, isESPValue = false) {
  return pages.some(page =>
    page.inputs.some(input => {
      if (input === currentInput) return false
      if (input.isTemplate && currentInput.isTemplate) return false
      if (isESPValue) return currentInput.espValue && input.espValue === currentInput.espValue
      return input.label === currentInput.label
    }))
}

function checkForESPListIdType(settings) {
  // checks for espSettings.listId type
  if (settings.esp.listId !== null) {
    settings.esp.listId = /^[\d]*$/.test(settings.esp.listId)
      ? +settings.esp.listId // if value is a valid number, convert it to number
      : settings.esp.listId // if string, keep it as is
  }
}

// TODO: If draft, re-evaluate other places, same as before saving/publishing
function mapValuesToValidate(opts) {
  const {
    settings, lightboxSmsSend, ESP, isDraftOrSaving = false, containsESPValue,
  } = opts

  if (!settings) return {}

  const validationValuesMap = {
    validDomain: { path: 'domain' },
    domain: {
      path: 'domain',
      validateOnSave: true,
    },
    delay: {
      path: 'trigger.delay',
      validateOnSave: true,
      condition: setting => setting.trigger.type === 'delay',
    },
  }

  const specialFields = {}
  const { pages } = settings

  // get values from inputs
  const getInputs = (accumulator, input) => {
    const hasError = verifyIfValueIsBeingUsed(input, pages)
    const hasESPError = verifyIfValueIsBeingUsed(input, pages, true)
    if (hasError) specialFields[`repeatedValue_${input.name}`] = true
    if (hasESPError) specialFields[`espValue_${input.name}`] = true
    return {
      ...accumulator,
      [`cantExceed_${input.name}`]: input.label,
      ...(isDraftOrSaving && { [`placeholder_${input.name}`]: input.placeholder }),
      ...(isDraftOrSaving && { [input.name]: input.label }),
    }
  }

  // check page values
  const pageInputs = pages.reduce((accumulator, page, index) => {
    // success page doesn't require fields
    if (isDraftOrSaving) {
      const formType = settings.type
      const requiresImage = formType === 'popup'
      const checkForImage = settings.heroImage.position !== 'none' && !page.heroImage.url
      if (requiresImage && checkForImage) specialFields[`pageMissingImage_${index}`] = true
      if (page.inputs.length === 0 && index !== pages.length - 1) {
        specialFields.pageMissingFields = true
      }
    }

    const inputs = page.inputs.reduce(getInputs, {})
    return { ...accumulator, ...inputs }
  }, {})

  const valuesToEvaluate = {
    ...specialFields,
    ...pageInputs,
  }

  // map special values
  Object.keys(validationValuesMap).forEach(propName => {
    const { path, validateOnSave, condition = () => true } = validationValuesMap[propName]
    const value = _.get(settings, path)
    if (validateOnSave) {
      if (isDraftOrSaving && condition(settings)) valuesToEvaluate[propName] = value
      return
    }
    valuesToEvaluate[propName] = value
  })

  if (isDraftOrSaving) {
    // Add more stuff here
    if (containsESPValue) {
      valuesToEvaluate.ESPRequired = !!ESP
      valuesToEvaluate.ESPNotSupported = ESP && ESP.lightboxEnabled
    }
    valuesToEvaluate.formFieldCornerRadius = settings.fields.borderRadius
    valuesToEvaluate.buttonCornerRadius = settings.primaryButton.borderRadius

    if (checkPagesForField(settings, 'phone')) {
      valuesToEvaluate.lightboxSmsSendMissing = !!lightboxSmsSend
    }
  }

  return valuesToEvaluate
}

function processSettingsBeforeSaving(settings) {
  let inputCount = 1
  // adds default values to label and placeholder if empty
  settings.pages.forEach(page => {
    page.inputs.forEach(input => {
      if (!input.isTemplate) {
        if (!input.label) input.label = `untitled ${inputCount++}`
      }
      if (!input.placeholder) input.placeholder = 'untitled'
    })
  })
}

const verifyDomain = domain => {
  try {
    if (validator.isURL(domain)) return domain
  } catch (err) {
    /* */
  }
  return null
}

function useLightboxBuilder(builder) {
  // TODO: might want to add a check if builder is allowed on brand here
  // or maybe in main sms router
  const currentBrand = useSelector(state => state.currentBrand)
  const lightboxSettings = useLightboxSettings()
  const brandBank = useBrandBank()
  const brandTCPA = useBrandTcpa()
  const toastr = useContext(ToasterContext)
  const fields = useLightboxFields(lightboxSettings)
  const espIntegration = useESPIntegration({ type: 'lightbox' })
  const history = useHistory()
  const location = useLocation()

  const [lightboxSmsSend, setLightboxSmsSend] = useState(null)
  const [allTimeAnalytics, setAllTimeAnalytics] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [ESP, setESP] = useState(null)
  const [isPublish, setIsPublish] = useState(false)
  const [selectedPage, setSelectedPage] = useState(0)
  const [returnUrl, setReturnUrl] = useState('/sms-marketing/acquisition-tools')

  const { isNew, id: lightboxId } = builder

  const {
    containsESPValue = false,
    // hasMissingESPValue = false,
    containsEmailField = false,
    integrationRequiresESPValues = true,
  } = lightboxSettings.getSettings()
    ? lightboxSettings.getSettings().pages.reduce((conditionsObj, page) => {
      page.inputs.forEach(input => {
        const isEmailField = input.label === 'Email'
        if (ESP) {
          if (input.espValue) conditionsObj.integrationRequiresESPValues = false
        }
        if (isEmailField) conditionsObj.containsEmailField = true
        if (input.espValue) conditionsObj.containsESPValue = true
      })

      return conditionsObj
    }, {})
    : {}

  const requiresEmailField = ESP && !containsEmailField

  /* publish modal flags */
  const isBasic = currentBrand.belt === 'white'
  const hasSubscribersLeft = isBasic ? (allTimeAnalytics.subscriber_count ?? 0) < 500 : true
  const tcpaReady = !!brandTCPA.hasAcceptedTCPATerms
  const hasEnoughCredits = brandBank.bank.credits
    ? brandBank.bank.credits.count + brandBank.bank.credits.freeCount > 0
    : false

  /* publish modal ultimate flag!! */
  const canPublish = tcpaReady && hasEnoughCredits && hasSubscribersLeft && !requiresEmailField

  const baseUrl = `/signup-form/${isNew ? 'new' : lightboxId}`

  // allValidations = true when either clicked publish or save
  const onPerformValidationsBeforeAction = (opts = {}) => {
    const { allValidations = true, skipBuilderValidations = false } = opts
    // VALIDATIONS!!!
    const valuesToValidate = mapValuesToValidate({
      ESP,
      settings: lightboxSettings.getSettings(),
      lightboxSmsSend,
      containsESPValue,
      isDraftOrSaving: !isNew || allValidations,
    })
    return builder.errors.validate(valuesToValidate, skipBuilderValidations)
  }

  // Formats data to a "welcome modal"-friendly format
  const setLightboxSmsSendData = (data, isFetch = false) => {
    data.followups = data.followups.map(followup => {
      if (isFetch) {
        const {
          criteria: { unit_of_time, send_interval },
        } = followup
        return {
          ...followup,
          unit_of_time,
          send_interval,
        }
      }
      const { criteria, unit_of_time, send_interval } = followup
      return { ...followup, criteria: { criteria, unit_of_time, send_interval } }
    })
    setLightboxSmsSend(data)
  }

  const onSaveSmsSend = async savedLightboxId => {
    let smsSendData = lightboxSmsSend
    if (smsSendData) {
      const smsSend = { ...smsSendData }

      if (smsSend.followups?.length > 0) {
        // change to db-friendly data
        smsSend.followups = smsSend.followups.map(followup => ({
          ...followup,
          criteria: followup.criteria.criteria,
        }))
      }

      ({ data: smsSendData } = await axios.put(`/lightbox/${savedLightboxId}/sms-send`, smsSend))
    }

    return smsSendData
  }

  // saves draft to backend
  const onSaveLightbox = async (opts = {}) => {
    async function lightboxSavingFunction() {
      const settings = lightboxSettings.getSettings()
      const { closeBuilder = false, returnId = false, status = null } = opts
      let isError = false

      setIsLoading(true)
      processSettingsBeforeSaving(settings)
      const isValid = onPerformValidationsBeforeAction()

      const lightboxSettingsObj = settings.getSaveObj()

      try {
        let lightboxUrl = '/lightbox'
        if (lightboxId) lightboxUrl = `${lightboxUrl}/${lightboxId}`
        if (status) lightboxSettingsObj.status = status

        const axiosResponse = await axios.put(lightboxUrl, lightboxSettingsObj)
        const { data: lightboxSettingsData } = axiosResponse

        const smsSendData = await onSaveSmsSend(lightboxSettingsData.id)

        const toasterText = status
          ? status === 'active'
            ? 'Signup form published successfully'
            : 'Signup form unpublished'
          : 'Signup form saved'

        toastr.showToaster(toasterText, { timeout: 5000, type: 'success' })

        if (closeBuilder) return history.push(returnUrl)

        // TODO: Rename this prop better??
        if (returnId) return lightboxSettingsData.id

        if (isNew) {
          history.replace(location.pathname.replace('new', lightboxSettingsData.id))
        }

        checkForESPListIdType(lightboxSettingsData)

        lightboxSettings.setSettings(lightboxSettingsData)
        setLightboxSmsSend(smsSendData)

        lightboxSettings.resetIsUpdated()
      } catch (err) {
        toastr.showToaster('Something happened while saving the signup form data', {
          type: 'warning',
          timeout: 5000,
        })
        isError = true
      }
      setIsLoading(false)

      if (isError) return false
      return isValid
    }

    if (builder.drawer.isOpen) builder.drawer.close({ immediate: true })

    return new Promise(res => setTimeout(() => lightboxSavingFunction().then(res), 100))
  }

  const onSaveBeforePublish = async () => {
    const isValid = await onSaveLightbox()
    setIsPublish(isValid)
  }

  // saves and publishes
  const onPublishLightbox = async () => {
    if (canPublish) return onSaveLightbox({ status: 'active' })
    return false
  }

  const onUnpublishLightbox = async () => onSaveLightbox({ status: 'inactive' })

  const fetchESPIntegrationInfo = async (opts = {}) => {
    const { ESPId = null, ESPListId = null } = opts
    if (ESPId) {
      let integration = await espIntegration.fetchIntegrationInfo(ESPId)
      integration = await espIntegration.getESPInfoById(integration.id)
      let listId = ESPListId

      if (!listId && integration) {
        listId = integration.integrationData.details.lists?.[0]?.value ?? null
      }

      return {
        esp: integration,
        espSettings: { listId, brandIntegrationId: ESPId },
      }
    }

    return { esp: null, espSettings: {} }
  }

  const onValidateESPValue = async espDojoValue => {
    const settings = lightboxSettings.getSettings()

    if (!ESP || ESP.key !== 'mailchimp') {
      if (!settings.espSettings.brandIntegrationId /* || isNew*/) return false
      return !!espDojoValue
    }

    // // might want to use with other integrations
    // const validateFromTemplates = () => {
    //   if (ESP?.key !== 'mailchimp') return false
    //   return ESP.integrationData.mergeTagHelpers.some(({ value }) => value === espDojoValue)
    // }

    const { listId, brandIntegrationId: espId } = settings.espSettings
    const { data: isValid } = await axios.get(
      `/mailchimp/fetch-merge-tag?espId=${espId}&listId=${listId}&mergeTag=${espDojoValue}`
    )

    return isValid
  }

  const onSetESPValue = async newESPValue => {
    const settings = lightboxSettings.getSettings()
    if (newESPValue) {
      const { id: ESPId } = newESPValue.integrationData
      const { esp, espSettings } = await fetchESPIntegrationInfo({ ESPId })
      settings.espSettings = espSettings
      setESP(esp)
    } else {
      settings.espSettings = { brandIntegrationId: null, listId: null }
      setESP(null)
    }
  }

  const setESPValuesOnPageFields = () => {
    if (espIntegration.loaded) {
      const { mergeTagHelpers, mergeTags } = ESP?.integrationData || {}
      let getESPValue = () => null

      const tags = mergeTags?.length ? mergeTags : mergeTagHelpers

      if (tags) {
        // add a name prop to inputs before anything else
        fields.actions.setInputNames()
        getESPValue = fieldName =>
          (
            tags.find(
              tagHelper => tagHelper.attribute.toLowerCase() === fieldName.toLowerCase()
            ) ?? { value: null }
          ).value
      }

      const settings = lightboxSettings.getSettings()

      settings.pages.forEach(page => {
        page.inputs.forEach(input => {
          if (input.espValue) return

          let inputName = input.label
          if (input.isTemplate) inputName = input.name
          // prioritize value already saved, else get it from current ESP
          input.espValue = getESPValue(inputName)
        })
      })
    }
  }

  // removes the history state
  if (history.location.state && history.location.state.lightboxSmsSend) {
    const state = { ...history.location.state }
    delete state.lightboxSmsSend
    history.replace({ ...history.location, state })
  }

  const onSaveBeforeUpgrade = async () => {
    const id = await onSaveLightbox({ returnId: true })

    if (id) {
      let url = location.pathname
      if (isNew) url = location.pathname.replace('new', lightboxId)

      localStorage.setItem(
        'future_app_return_url',
        JSON.stringify({
          value: `/app${url}`,
          expiry: moment().add(15, 'minutes'),
        })
      )

      history.push('/upgrade-plan')
    }
  }

  const brandTcpaLoading = brandTCPA.isLoading

  const settingsLoaded = lightboxSettings.isLoaded

  // sets the domain/fetches integrations (once)
  useEffect(() => {
    if (settingsLoaded && !brandTcpaLoading) {
      const settings = lightboxSettings.getSettings()
      settings.domain = settings.domain || verifyDomain(currentBrand.website)
      settings.brandName = currentBrand.accountname
      settings.termsUrl = brandTCPA.brandTcpaDetails.terms_url ?? null
      settings.privacyUrl = brandTCPA.brandTcpaDetails.privacy_url ?? null
      espIntegration.fetchIntegrations().then(lightboxSettings.resetIsUpdated)
    }
  }, [settingsLoaded, brandTcpaLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  // fetches the initial/saved data (once)
  useEffect(() => {
    const fetchSettings = async () => {
      builder.setLoading(true)
      setIsLoading(true)

      const validTypes = ['popup', 'embed']
      const { type } = qs.parse(location.search)
      let formType = type
      if (!validTypes.includes(type)) formType = 'popup'

      if (isNew) {
        let formattedFormType = 'Pop-Up'
        if (formType === 'embed') formattedFormType = 'Embed'

        lightboxSettings.create({
          name: `Untitled ${formattedFormType}`,
          brandId: currentBrand.id,
          type: formType,
        })
      } else {
        const {
          data: { settings: lightboxSettingsData, smsSend },
        } = await axios.get(`/lightbox/${lightboxId}`)

        if (!lightboxSettingsData.type) lightboxSettingsData.type = formType

        if (smsSend) setLightboxSmsSendData(smsSend, true)

        checkForESPListIdType(lightboxSettingsData)

        lightboxSettings.setSettings(lightboxSettingsData)
      }
      lightboxSettings.resetIsUpdated()
      setIsLoading(false)
    }

    fetchSettings()
  }, [isNew]) // eslint-disable-line react-hooks/exhaustive-deps

  // sets view mode into settings
  useEffect(() => {
    if (settingsLoaded) {
      const settings = lightboxSettings.getSettings()
      settings.viewMode = builder.preview.type
    }
  }, [builder.preview.type, settingsLoaded]) // eslint-disable-line react-hooks/exhaustive-deps

  const espHasLoaded = espIntegration.loaded
  useEffect(() => {
    async function setESPToSettings() {
      const settings = lightboxSettings.getSettings()
      // sets ESP integration first time
      const ESPId = settings.espSettings.brandIntegrationId || espIntegration.currentEnabledESP.id
      // if no esp from settings + brand has no esps set up, dont do anything else
      if (!ESPId) return

      const ESPListId = settings.espSettings.listId
      const { esp, espSettings } = await fetchESPIntegrationInfo({ ESPId, ESPListId })
      setESP(esp)
      settings.espSettings = espSettings
      lightboxSettings.resetIsUpdated()
    }

    if (espHasLoaded) setESPToSettings()
  }, [espHasLoaded, setESP]) // eslint-disable-line react-hooks/exhaustive-deps

  // Set the espValues for every input
  useEffect(() => {
    setESPValuesOnPageFields()
  }, [ESP, espHasLoaded]) // eslint-disable-line react-hooks/exhaustive-deps

  // Fetches analytics (one time thing only)
  useEffect(() => {
    async function fetchAnalytics() {
      const { data: analytics } = await axios.get(
        `/sms/brand-analytics?from=2000-01-01&to=${moment()}&isTotal=true`
      )
      setAllTimeAnalytics(analytics || {})
    }

    fetchAnalytics()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setReturnUrl(location.state?.returnUrl || '/sms-marketing/acquisition-tools')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // waits for everything to load, then builder can be used
  useEffect(() => {
    const everythingIsLoaded = !(
      isLoading
      || espIntegration.isLoading
      || brandBank.isLoading
      || brandTCPA.isLoading
    )

    if (everythingIsLoaded) {
      builder.setLoading(false)
      lightboxSettings.resetIsUpdated()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, espIntegration.isLoading, brandBank.isLoading, brandTCPA.isLoading])

  // Ensures that the form "type" is appended as query param
  const { pathname } = history.location
  useEffect(() => {
    if (!settingsLoaded) return
    const { settings } = lightboxSettings.getSettings()
    const params = new URLSearchParams()
    const formType = settings.type || 'popup'
    params.append('type', formType)
    history.replace({ search: params.toString() })
  }, [settingsLoaded, pathname]) // eslint-disable-line react-hooks/exhaustive-deps

  let loading = isLoading || espIntegration.isLoading
  loading = loading || brandBank.isLoading || brandTCPA.isLoading

  return {
    values: {
      ...fields.values,
      lightboxSmsSend,
      isValid: onPerformValidationsBeforeAction({ allValidations: false }),
      isLightboxDataValid: onPerformValidationsBeforeAction({ skipBuilderValidations: true }),
      isLoading: loading,
      ESP,
      selectedPage,
      isPublish,
      containsESPValue,
      containsEmailField,
      requiresEmailField,
      baseUrl,
      returnUrl,

      // extras
      hasSubscribersLeft,
      tcpaReady,
      hasEnoughCredits,
      integrationRequiresESPValues,
      canPublish,
    },
    actions: {
      ...fields.actions,
      setLightboxSmsSendData,
      onSaveLightbox,
      onPublishLightbox,
      onUnpublishLightbox,
      onSetESPValue,
      onValidateESPValue,
      setSelectedPage,
      setIsPublish,
      setESPValuesOnPageFields,
      onSaveBeforeUpgrade,
      onSaveBeforePublish,
    },
    lightboxSettings,
    espIntegration,
  }
}

export default useLightboxBuilder

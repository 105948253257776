import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import moment from 'moment'

import { Button } from 'components/buttons'
import Chip from 'components/shared/Chip'

import history from 'utils/getHistory'
import makeNumbersPretty from 'utils/makeNumbersPretty'

import useMobileDetect from 'hooks/useMobileDetect'

import SearchInput from '../SearchInput'
import PreviousSearchSuggestions from './PreviousSearchSuggestions'
import SearchSuggestions from './SearchSuggestions'
import SearchWithFiltersModal from './SearchWithFiltersModal'

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    backgroundColor: 'white',
    minHeight: 48,
    borderRadius: 2,
    position: 'relative',
    zIndex: 15,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.12)',
    '& > :nth-child(2)': {
      // Search Button
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      height: 'auto',
      padding: '0 16px',
      lineHeight: 1,
    },
    [theme.breakpoints.down('xs')]: {
      '& > button': {
        display: 'none',
      },
    },
  },
  outterWrapper: {
    display: 'flex',
    width: '100%',
    position: 'relative',
  },
  inputWrapper: {
    display: 'flex',
    flexWrap: 'nowrap',
    padding: '6px 16px 2px',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: props => (props.isMobile ? `1px solid ${theme.colors.silver}` : 'none'),
    '& [class*=searchIcon]': {
      maxHeight: 36,
      '& svg:not(.MuiSvgIcon-root)': {
        // Lens icon'
        stroke: theme.colors.ink,
      },
    },
  },
  input: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: props => (props.canFlex ? 'unset' : 1),
    '& [class*=chipContainer]': {
      // Chips
      height: 32,
      margin: '0 2px 4px',
      '& .MuiChip-root': {
        height: 32,
        borderRadius: 2,
      },
    },
    '& .MuiInputBase-root': {
      minHeight: 'unset',
      width: props => {
        const { value } = props

        // TODO: Calculate better this value
        return value.length ? value.length * 14 - (value.length - 1) * 4 + 24 : '100%'
      },
      '& input': {
        padding: '9px 0 7px',
      },
    },
    '& > div:first-child': {
      flex: props => (props.canFlex ? 'unset' : 1),
      minWidth: props => (props.value.length ? 'unset' : 220),
    },
  },
}))

function SearchWithFilters(props) {
  const {
    onChange,
    onSearch,
    onFilter,
    searchValue,
    filterValues,
    suggestions,
    onFilterRemove,
  } = props

  const isMobile = useMobileDetect().isMobile()

  const wrapperRef = useRef(null)
  const searchInputRef = useRef(null)

  const [search, setSearch] = useState(searchValue)
  const [focused, setFocused] = useState(false)
  const [containerHeight, setContainerHeight] = useState(0)
  const [searchModal, setSearchModal] = useState(false)

  const canFlex = search.length || Object.keys(filterValues).length
  const css = useStyles({ isMobile, canFlex, value: search })

  const onSearchChange = val => {
    setFocused(true)
    setSearch(val)
    onChange(val)
  }

  const handleEnter = val => {
    if (val) setSearch(val)
    onSearch(val || search)
    setFocused(false)
  }

  useEffect(() => {
    const { current } = wrapperRef
    setContainerHeight(current.clientHeight)
  }, [setContainerHeight, focused, search])

  const onFocus = e => {
    if (isMobile) {
      e.target.blur()
      return setSearchModal(true)
    }

    return setFocused(true)
  }

  const deleteMediaMarketChip = () => {
    history.push(`/search/media-market${history.location.search}`)
  }

  const previousSearchSuggestions = JSON.parse(localStorage.getItem('previousSearchSuggestions'))

  const mapFilterItems = () =>
    Object.keys(filterValues).map(key => {
      const geoFilters = ['geoMinAudience', 'Northeast', 'South', 'Midwest', 'West']
      if (geoFilters.includes(key)) return null

      const { searchType, value } = filterValues[key]
      if (searchType === 'range') {
        let label
        switch (key) {
          case 'dedicatedListSize':
            label = `List Size: ${makeNumbersPretty(value[0], true)} - ${makeNumbersPretty(
              value[1]
            )}`
            break
          case 'estimatedEntries':
            label = `Est. Entries: ${makeNumbersPretty(value[0], true)} - ${makeNumbersPretty(
              value[1]
            )}`
            break
          case 'averageSignUpsPerCampaign':
            label = `Avg. Entries: ${makeNumbersPretty(value[0], true)} - ${makeNumbersPretty(
              value[1]
            )}`
            break
          case 'socialMediaImpressions':
            label = `Social Audience: ${makeNumbersPretty(value[0], true)} - ${makeNumbersPretty(
              value[1]
            )}`
            break
          case 'startDate':
            label = `Start Date: ${moment(value[0]).format('MMM DD')} - ${moment(value[1]).format(
              'MMM DD'
            )}`
            break
          case 'numberOfPartners':
            label = `${makeNumbersPretty(value[0])}-${makeNumbersPretty(value[1])} Partners`
            break
          case 'prizingValue':
            label = `Total Prize Value: ${makeNumbersPretty(value[0], true)} - ${makeNumbersPretty(
              value[1]
            )}`
            break
          case 'created_at':
            label = `Joined ${value[0].toLowerCase()}`
            break
          default:
            label = value
            break
        }

        return <Chip key={`filter_${label}`} onDelete={() => onFilterRemove(key)} label={label} />
      }

      if (key === 'mediaMarket') {
        return (
          <Chip
            key="filter_mediaMarket"
            onDelete={deleteMediaMarketChip}
            label={filterValues[key].value}
          />
        )
      }
      const relevantVal = value && Array.isArray(value)
      return (
        relevantVal
        && value.map(item => (
          <Chip key={`filter_${item}`} onDelete={() => onFilterRemove(key, item)} label={item} />
        ))
      )
    })

  const triggerFocus = () => {
    if (searchInputRef.current) searchInputRef.current.focus()
  }

  useEffect(() => setSearch(searchValue), [searchValue])

  return (
    <div className={css.wrapper}>
      <div ref={wrapperRef} className={css.outterWrapper}>
        <div onClick={triggerFocus} className={css.outterWrapper}>
          <SearchInput
            classes={{
              wrapper: css.inputWrapper,
              input: css.input,
            }}
            getRef={input => (searchInputRef.current = input)}
            onFocus={onFocus}
            onBlur={() => setTimeout(() => setFocused(false), 200)}
            value={search}
            onChange={onSearchChange}
            onEnter={handleEnter}
            placeholder="Try fashion, food, fitness, travel..."
            filterItems={isMobile ? [] : mapFilterItems()}
          />
        </div>
        {focused && (
          <>
            {!search ? (
              <PreviousSearchSuggestions
                suggestions={previousSearchSuggestions}
                onSuggestionClick={handleEnter}
              />
            ) : (
              <SearchSuggestions
                searchValue={search}
                suggestions={suggestions}
                onFilterClick={onFilter}
                onSuggestionClick={handleEnter}
                containerHeight={containerHeight}
              />
            )}
          </>
        )}
      </div>
      {isMobile ? (
        <SearchWithFiltersModal
          show={searchModal}
          handleEnter={handleEnter}
          onClose={() => setSearchModal(false)}
          {...props}
        />
      ) : (
        <Button onClick={() => handleEnter()} label="Search" themeColor="secondary" />
      )}
    </div>
  )
}

SearchWithFilters.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  onFilterRemove: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  searchValue: PropTypes.string,
  filterValues: PropTypes.object,
  suggestions: PropTypes.arrayOf(PropTypes.object),
}

SearchWithFilters.defaultProps = {
  searchValue: '',
  filterValues: {},
  suggestions: [],
}

export default React.memo(SearchWithFilters)

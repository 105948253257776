import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { ButtonBase } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  switch: {
    height: 32,
    width: 48,
    display: 'grid',
    placeContent: 'center',
    cursor: 'pointer',
  },
  track: props => {
    const styles = {
      backgroundColor: theme.colors.skyLight,
      border: `1px solid ${theme.colors.skyBase}`,
      width: 30,
      height: 12,
      borderRadius: 10,
      transition: 'all .1s linear',
      borderColor: theme.colors.skyBase,
    }

    if (props.checked) {
      styles.backgroundColor = theme.colors.blueLighter
      styles.borderColor = theme.colors.blueLighter
    }

    return styles
  },
  thumbWrapper: {
    height: 32,
    width: 32,
    borderRadius: '50%',
    display: 'grid',
    placeContent: 'center',
    marginTop: -11.5,
    transition: 'transform .1s linear',
    transform: ({ checked }) => `translate(${checked ? 6 : -10}px)`,
  },
  thumb: props => {
    const styles = {
      height: 24,
      width: 24,
      borderRadius: '50%',
      backgroundColor: theme.colors.skyBase,
      border: `1px solid ${theme.colors.skyDarker}`,
      transition: 'all .1s linear',
      borderColor: theme.colors.skyDarker,
    }

    if (props.checked) {
      styles.borderColor = theme.colors.blueDarker
      styles.backgroundColor = theme.colors.blueBase
    }

    return styles
  },
}))

function Switch(props) {
  const { onChange, checked: defaultChecked, controlled } = props

  const [checked, setChecked] = useState(defaultChecked)
  const checkedValue = controlled ? defaultChecked : checked

  const css = useStyles({ checked: checkedValue })

  const onCheckChange = () => {
    if (controlled && onChange) onChange(!checkedValue)
    else {
      setChecked(oldChecked => {
        const newChecked = !oldChecked
        if (onChange) onChange(newChecked)
        return newChecked
      })
    }
  }

  return (
    <div className={css.switch} onClick={onCheckChange}>
      <div className={css.track}>
        <ButtonBase className={css.thumbWrapper}>
          <div className={css.thumb} />
        </ButtonBase>
      </div>
    </div>
  )
}

Switch.propTypes = {
  controlled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
}

Switch.defaultProps = {
  controlled: true,
}

export default React.memo(Switch)

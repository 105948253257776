import React, {
  useContext, useEffect, useState, useRef,
} from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import cn from 'classnames'
import { ToasterContext } from 'contexts'

import styleHelper from 'utils/styleHelper'
import { updateBrand, uploadProfileImg, uploadProfileImgFromURL } from 'utils/brands'
import {
  cannotExceedInteger,
  isRequired,
  isNum,
  isPositiveInteger,
  cantExceedOfChars,
} from 'utils/formValidations'

import Button from 'components/virtual/buttons/Button'
import { Form, FormInput } from 'components/forms'
import Text from 'components/texts/Text'

import defaultLogo from 'images/icons/empty-avatar.svg'

const useStyles = makeStyles(theme => ({
  actions: {
    alignItems: 'center',
    borderTop: `1px solid ${theme.colors.skyBase}`,
    display: 'flex',
    height: 84,
    justifyContent: 'space-between',
    padding: 24,
    width: '100%',
  },
  brandLogo: {
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 0px',
    width: '100%',
  },
  brandLogoHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
  },
  dynamicSizing: {
    [styleHelper.max(1023)]: {
      width: '66%',
      margin: '0 auto',
    },
    [styleHelper.mobile]: {
      width: '100%',
    },
  },
  form: {
    marginBottom: 'auto',
    padding: '42px 28px 0px',
    [styleHelper.mobile]: {
      paddingTop: 16,
    },
  },
  input: {
    marginBottom: 28,
  },
  logo: {
    border: `1px solid ${theme.colors.skyDarker}`,
    borderRadius: '50%',
    cursor: 'pointer',
    height: 84,
    margin: '0px 12px 12px 0px',
    overflow: 'hidden',
    minWidth: 84,
  },
  logoContainer: {
    alignItems: 'center',
    display: 'flex',
    [styleHelper.mobile]: {
      alignItems: 'start',
      flexDirection: 'column',
    },
  },
  logoImage: {
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    borderRadius: '50%',
    height: 84,
    marginLeft: -1,
    marginTop: -1,
    objectFit: 'contain',
    width: 84,
  },
  uploader: {
    display: 'none',
  },
}))

const addCommas = (value = '') => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
const removeCommas = (value = '') => value.replace(/,/g, '')
const cleanNumber = value => value.replace(/\D/g, '')

const restrictDecimals = event => {
  if (event.charCode === 46) {
    event.preventDefault()
  }
}
function EditProfilePage({
  onNext, onPrev, setValues, track, values,
}) {
  const classes = useStyles()

  const { showToaster } = useContext(ToasterContext)

  const [formValid, setFormValid] = useState(true)
  const [loading, setLoading] = useState(false)

  const image = useRef(null)
  const uploader = useRef(null)
  const defaultLogoImage = values.logo ?? defaultLogo

  const openUploader = () => uploader.current.click()

  const upload = event => {
    const [file] = event.target.files
    if (file) {
      if (file.size > 10485760) {
        showToaster('Your image cannot exceed maximum file size (10MB)', { type: 'warning' })
        return
      }
      const reader = new FileReader()
      const { current } = image
      current.file = file
      reader.onload = e => {
        current.src = e.target.result
      }
      reader.readAsDataURL(file)
    }
  }

  const onSubmit = async ({ description, dedicatedListSize }) => {
    setLoading(true)
    track('submittedBrandProfile')()
    try {
      let logo
      if (image.current.file) {
        logo = await uploadProfileImg(image.current.file)
      } else if (values.logo?.startsWith(process.env.REACT_APP_BRAND_FETCH_API_URL)) {
        // If the logo is pre-populated from Brand Fetch API
        // Upload the image so we can later store it our own server
        logo = await uploadProfileImgFromURL(values.logo)
      }

      await updateBrand({
        description,
        dedicatedListSize: cleanNumber(dedicatedListSize),
        logo,
      })
      setLoading(false)
      onNext()
    } catch (err) {
      showToaster(err, { type: 'warning' })
      setLoading(false)
    }
  }

  const exposeValues = form => {
    setFormValid(form.valid)
    setValues({ ...values, ...form.values })
  }

  useEffect(() => {
    track('loadedBrandProfile')()
  }, [track])

  const disabled = loading || !formValid

  return (
    <div className={classes.container}>
      <div className={cn(classes.form, classes.dynamicSizing)}>
        <Form
          exposeValues={exposeValues}
          initialValues={{
            description: values.description,
            dedicatedListSize: values.dedicatedListSize,
            logo: values.logo,
          }}
          onSubmit={onSubmit}
          withSubmit="editProfile"
        >
          <div className={classes.input}>
            <FormInput
              characterLimit={250}
              disableErrorText
              fullWidth
              label="Brand Description"
              name="description"
              placeholder="Write a brief bio about your brand here"
              rows={4}
              theme="virtual"
              type="textarea"
              useComponentError
              validateFields={['description']}
              validations={[cantExceedOfChars(250)]}
            />
          </div>
          <div className={classes.input}>
            <FormInput
              disableErrorText
              parse={removeCommas}
              fullWidth
              label="Email List Size *"
              name="dedicatedListSize"
              format={addCommas}
              onKeyPress={restrictDecimals}
              placeholder="15,000"
              theme="virtual"
              type="text"
              useComponentError
              validateFields={['dedicatedListSize']}
              validations={[cannotExceedInteger, isRequired, isNum, isPositiveInteger]}
            />
          </div>
        </Form>
        <div className={classes.brandLogo}>
          <div className={classes.brandLogoHeader}>
            <Text charcoalBase size={16}>
              Brand Logo
            </Text>
            <Button
              color="secondary"
              label="Upload Logo"
              onClick={openUploader}
              size="small"
              type="outlined"
            />
          </div>
          <input
            accept="image/gif, image/jpeg, image/png"
            className={classes.uploader}
            onChange={upload}
            ref={uploader}
            type="file"
          />
          <div className={classes.logoContainer}>
            <div className={classes.logo} onClick={openUploader}>
              <img alt="" className={classes.logoImage} ref={image} src={defaultLogoImage} />
            </div>
            <Text charcoalLight size={12}>
              Upload a PNG or JPG with a 1:1 aspect ratio. The image should be 200 x 200 pixels
              minimum, and cannot exceed 10MB.
            </Text>
          </div>
        </div>
      </div>
      <div className={classes.actions}>
        <Button label="Previous" onClick={onPrev} size="small" type="text" />
        {/*
          TODO(Jenky): Replaced in favour of commented out code in ScheduleCallPage.js component
        */}
        {/* <Button disabled={disabled} id="editProfile" label="Next Step" size="small" /> */}
        <Button disabled={disabled} id="editProfile" label="Start My 30 Day Trial" size="small" />
      </div>
    </div>
  )
}

EditProfilePage.propTypes = {
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  track: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
}

export default React.memo(EditProfilePage)

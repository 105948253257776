import React from 'react'
import PropTypes from 'prop-types'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import CompactBanner from 'views/mobile/lightbox-builder/banners/CompactBanner'

function MissingFieldsBanner({ formDescriptor }) {
  const {
    builder: { errors },
  } = React.useContext(BuilderContainerContext)
  const isMissingFields = errors.verify('pageMissingFields')

  if (!isMissingFields) return null

  return (
    <CompactBanner
      text={`You must have at least one field per form ${formDescriptor}.`}
      type="error"
    />
  )
}

MissingFieldsBanner.propTypes = {
  formDescriptor: PropTypes.string.isRequired,
}

export default React.memo(MissingFieldsBanner)

const BASE_URLS = {
  dev: 'wss://dev.dojomojo.com:442/ws2',
  staging: 'wss://staging.dojomojo.com:442/ws2',
  production: 'wss://www.dojomojo.com:441/ws2',
}
const BASE_URL = BASE_URLS[process.env.REACT_APP_ENV] || 'ws://localhost:5000/ws2'
const DEFAULT_RETRY_WAIT = 2000 // ms
const RETRY_INCREASE_INTERVAL = 2000 // ms
const MAX_RETRY_WAIT = 60000 // ms

// TODO: Check how to refactor all of this???
class Socket {
  retryWait = DEFAULT_RETRY_WAIT

  inFocus = true

  events = {}

  handleNotification = event => {
    let { data } = event

    try {
      data = JSON.parse(data)
    } catch (err) {
      console.warn(data)
      return
    }

    if (this.events[data.type]) this.events[data.type](data)
  }

  addEventListener = (type, cb) => {
    this.events[type] = cb
  }

  removeEventListener = type => {
    delete this.events[type]
  }

  connect = () => {
    this.init()
    window.addEventListener('focus', this.retry)
    window.addEventListener('blur', this.cancel)
  }

  disconnect = () => {
    window.removeEventListener('focus', this.retry)
    window.removeEventListener('blur', this.cancel)
    this.ws.onclose = () => {}
    this.ws.close()
  }

  cancel = () => {
    this.inFocus = false
    clearTimeout(this.retryTimer)
  }

  retry = () => {
    this.retryWait = DEFAULT_RETRY_WAIT
    this.inFocus = true

    // If no Websocket exists or it is not in appropriate ready state, retry
    // WebSocket.CONNECTING = 0, WebSocket.OPEN = 1
    if (!this.ws || this.ws.readyState > 1) this.init()
  }

  retryWithBackoff = () => {
    if (!this.inFocus || !document.hasFocus()) return
    clearTimeout(this.retryTimer)
    console.log(`Retrying in ${Math.round(this.retryWait / 1000)} seconds`)
    this.retryTimer = setTimeout(() => this.init(), this.retryWait)
    // Increase retry wait by retry increase interval (up to maximum wait)
    this.retryWait = Math.min(this.retryWait + RETRY_INCREASE_INTERVAL, MAX_RETRY_WAIT)
  }

  // I feel this should be in utils
  getURL = () => {
    try {
      const imp = sessionStorage.getItem('impersonating')
      return imp ? `${BASE_URL}?impersonating=${imp}` : BASE_URL
    } catch (e) {
      return BASE_URL
    }
  }

  init = () => {
    // Don't connect if WebSocket already exists and is open
    // WebSocket.CONNECTING = 0, WebSocket.OPEN = 1
    if ((this.ws && this.ws.readyState <= 1) || !this.inFocus) return

    this.ws = new WebSocket(this.getURL())

    this.ws.onopen = () => {
      clearTimeout(this.retryTimer)
      console.log('WS 2 Connection Open')
    }
    this.ws.onmessage = this.handleNotification
    this.ws.onerror = () => {}
    this.ws.onclose = () => {
      console.log('WS Connection Closed')
      this.retryWithBackoff()
    }
  }
}

const webSocket = new Socket()

export default webSocket

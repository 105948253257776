import React, { useState, useEffect } from 'react'

import { Dialog, makeStyles } from '@material-ui/core'

import history from 'utils/getHistory'
import moment from 'moment-timezone'

import { H3, H4, Small } from 'components/texts'
import { Button } from 'components/buttons'

import smsWelcomeBanner from 'images/banners/sms-welcome-banner.svg'
import closeIcon from 'images/icons/close-x-icon-white.svg'
import acquireIcon from 'images/icons/acquire-icon.svg'
import convertIcon from 'images/icons/convert-icon.svg'
import engageIcon from 'images/icons/engage-icon.svg'

const EXPIRATION_DATE = '2020-03-12'

const useStyles = makeStyles(theme => ({
  banner: {
    marginBottom: 24,
    width: '100%',
  },
  blurb: {
    alignItems: 'center',
    backgroundColor: theme.colors.crystal,
    display: 'flex',
    flexDirection: 'column',
    height: 68,
    justifyContent: 'space-between',
    marginBottom: 24,
    padding: '17px 0px',
    width: 606,
  },
  buzzword: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: 194,
  },
  buzzwords: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 24,
    textAlign: 'center',
    '& div:not(last-child)': {
      marginRight: 16,
    },
  },
  closeIcon: {
    cursor: 'pointer',
    height: 14,
    position: 'absolute',
    right: 31,
    top: 31,
    width: 14,
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '32px 0px 36px',
    width: 672,
  },
  description: {
    marginBottom: 24,
    textAlign: 'center',
  },
  icon: {
    height: 26,
    marginBottom: 11,
    objectFit: 'contain',
    width: 26,
  },
  paper: {
    overflow: 'hidden',
  },
  marginBottom24: {
    marginBottom: 24,
  },
}))

const buzzwords = [
  {
    title: 'Acquire',
    description:
      'Leverage existing sweepstakes to quickly and easily acquire mobile phone numbers.',
    icon: acquireIcon,
  },
  {
    title: 'Engage',
    description:
      'Engage your audience with real-time messages with DojoMojo’s SMS Marketing suite.',
    icon: engageIcon,
  },
  {
    title: 'Convert',
    description:
      'Easily convert both new and existing customers by incentivizing them with an exclusive offer.',
    icon: convertIcon,
  },
]

function SmsWelcomeModal() {
  const classes = useStyles()

  const [open, setOpen] = useState(false)

  useEffect(() => {
    let seenSmsWelcomeModal

    try {
      seenSmsWelcomeModal = localStorage.getItem('seenSmsWelcomeModal')
    } catch (e) {
      //error
    }

    if (moment().isAfter(EXPIRATION_DATE)) return

    if (seenSmsWelcomeModal && !history.location.search.includes('?sms-welcome')) return

    setOpen(true)
  }, [])

  const onClose = () => {
    try {
      localStorage.setItem('seenSmsWelcomeModal', true)
    } catch (e) {
      //error
    }
    setOpen(false)
  }

  const onButtonClick = () => {
    onClose()
    history.push('/sms-marketing/dashboard')
  }

  const renderBuzzwords = () => (
    <div className={classes.buzzwords}>
      {buzzwords.map(buzzword => (
        <div key={buzzword.title} className={classes.buzzword}>
          <img src={buzzword.icon} className={classes.icon} alt={classes.title} />
          <H4 multiline>
            <i>{buzzword.title}</i>
          </H4>
          <Small multiline>{buzzword.description}</Small>
        </div>
      ))}
    </div>
  )
  return (
    <Dialog classes={{ paper: classes.paper }} maxWidth={false} open={open}>
      <div className={classes.container}>
        <img src={closeIcon} className={classes.closeIcon} onClick={onClose} alt="" />
        <div className={classes.marginBottom24}>
          <H3> Introducing SMS Marketing by DojoMojo. </H3>
        </div>
        <img className={classes.banner} src={smsWelcomeBanner} alt="DojoMojo SMS Marketing" />
        <div className={classes.description}>
          <div className={classes.marginBottom24}>
            <Small>
              <em>
                Combine our powerful acquisition funnel with SMS texting capabilities for a true
                end-to-end conversion funnel.
              </em>
            </Small>
          </div>
          <H4>
            <i>Free to try for a limited time!</i>
          </H4>
        </div>
        {renderBuzzwords()}
        <div className={classes.blurb}>
          <Small>
            <em>Did you know?</em>
          </Small>
          <Small>
            <em>Mobile subscribers are 40% more likely to convert?</em>
          </Small>
        </div>
        <Button label="Learn More" onClick={onButtonClick} />
      </div>
    </Dialog>
  )
}

export default React.memo(SmsWelcomeModal)

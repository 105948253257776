import React from 'react'
import PropTypes from 'prop-types'

import InputWithDelete from 'components/virtual/inputs/InputWithDelete'

function DropdownOptionField(props) {
  const {
    label, onChange, onDelete, value, placeholder, index,
  } = props

  const onOptionChange = newValue => onChange(index, newValue)
  const onOptionDelete = () => onDelete(index)

  return (
    <InputWithDelete
      value={value}
      placeholder={placeholder}
      label={label}
      onChange={onOptionChange}
      onDelete={onOptionDelete}
    />
  )
}

DropdownOptionField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default React.memo(DropdownOptionField)

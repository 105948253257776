import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Text from 'components/texts/Text'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import Tooltip from 'components/shared/ToolTipWrapper'

const useStyles = makeStyles({
  paths: {
    '& > :first-child': {
      marginBottom: 4,
    },
  },
  urlsGroup: {
    '&:not(:last-child)': {
      marginBottom: 20,
    },
    '& > *:not(:last-child)': {
      marginBottom: 4,
    },
  },
})

// should match `BehaviorUrlTargeting` values
const VALUES_MAP = {
  'all pages': 'Show on all pages (default)',
  whitelist: 'Show only on pages',
  blacklist: 'Hide on pages',
}

const LightboxBuilderCustomLink = ({ url, children }) => {
  const href = url.startsWith('http') ? url : `http://${url}`
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  )
}

LightboxBuilderCustomLink.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

function LightboxBuilderUrlPaths(props) {
  const css = useStyles()
  const { error } = props
  const { lightboxSettings } = React.useContext(BuilderContainerContext)
  const settings = lightboxSettings.getSettings()

  const {
    domain,
    trigger: { allowListUrls, denyListUrls },
  } = settings
  let selectedOption = 'all pages'
  let pathList = []

  if (allowListUrls.length > 0) [selectedOption, pathList] = ['whitelist', allowListUrls]
  if (denyListUrls.length > 0) [selectedOption, pathList] = ['blacklist', denyListUrls]

  const selectedOptionText = VALUES_MAP[selectedOption]

  const filterListBy = criteria => path => path.type === criteria
  const exactPaths = pathList.filter(filterListBy('exact'))
  const containPaths = pathList.filter(filterListBy('contain'))

  const isAllPages = selectedOption === 'all pages'

  const urlsGroup = (links, isExact = false) => {
    if (!links.length) return null

    // LightboxBuilderCustomLink is defined in this same file, an 'a' element wrapper
    const LinkWrapper = isExact && !error ? LightboxBuilderCustomLink : React.Fragment
    const TooltipWrapper = isExact && !error ? Tooltip : React.Fragment

    const criteria = isExact ? 'URL is' : 'URL contains'

    const prependText = isExact ? domain : ''

    return (
      <div className={css.urlsGroup}>
        <Text>{`${selectedOptionText} where ${criteria}:`}</Text>
        {links.map(({ path }) => (
          <LinkWrapper key={path} url={`${prependText}${path}`}>
            <Text blueBase={!error} redBase={error} ellipsis>
              <TooltipWrapper
                isVirtualTheme
                placement="bottom-start"
                text={`${prependText}${path}`}
              >
                <span>{`${prependText}${path}`}</span>
              </TooltipWrapper>
            </Text>
          </LinkWrapper>
        ))}
      </div>
    )
  }

  return (
    <div className={css.paths}>
      <Text charcoalBase>URL Path</Text>
      {isAllPages ? (
        <Text>{selectedOptionText}</Text>
      ) : (
        <>
          {urlsGroup(exactPaths, true)}
          {urlsGroup(containPaths)}
        </>
      )}
    </div>
  )
}

LightboxBuilderUrlPaths.propTypes = {
  error: PropTypes.bool.isRequired,
}

export default React.memo(LightboxBuilderUrlPaths)

import { useRef, useState } from 'react'
import useBuilderErrors from './useBuilderErrors'

function useBuilder(builderArgs) {
  const timeoutRef = useRef(null)
  const onDrawerCloseRef = useRef(null)
  const [previewMode, setPreviewMode] = useState(false)
  const [previewType, setPreviewType] = useState('desktop')
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [drawerConfig, setDrawerConfig] = useState({})
  const [drawerComponent, setDrawerComponent] = useState(null)
  const [drawerData, setDrawerData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [previewNavbar, setPreviewNavbar] = useState(null)
  const [section, setSection] = useState(false)
  const errors = useBuilderErrors(builderArgs)

  const openDrawer = (data, options = {}) => {
    const { title = 'Edit Field', withBackButton = false } = options
    clearTimeout(timeoutRef.current)
    setDrawerOpen(true)
    setDrawerData(data)
    setDrawerConfig({ title, withBackButton })
  }

  const closeDrawer = (opts = {}) => {
    const { immediate = false } = opts
    if (onDrawerCloseRef.current) onDrawerCloseRef.current()
    clearTimeout(timeoutRef.current)
    setDrawerOpen(false)

    function clearData() {
      setDrawerData(null)
      onDrawerCloseRef.current = null
      setDrawerConfig({})
    }

    const timer = immediate ? 0 : 500
    timeoutRef.current = setTimeout(clearData, timer)
  }

  const onDrawerClose = fn => {
    onDrawerCloseRef.current = fn
  }

  return {
    loading,
    setLoading,
    preview: {
      full: previewMode,
      type: previewType, // TODO: might want to rename this to viewMode instead
      setFull: setPreviewMode,
      setType: setPreviewType, // TODO: might want to rename this to setViewMode instead
      togglePreview: () => setPreviewMode(preview => !preview),
      setNavbar: setPreviewNavbar,
      clearNavbar: () => setPreviewNavbar(null),
      PreviewNavbar: previewNavbar,
    },
    drawer: {
      isOpen: drawerOpen,
      data: drawerData,
      open: openDrawer,
      config: drawerConfig,
      close: closeDrawer,
      drawerComponent,
      setDrawerComponent,
      onClose: onDrawerClose,
    },
    errors,
    section,
    setSection,
  }
}

export default useBuilder

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import AddButton from 'components/virtual/buttons/AddButton'

const useStyles = makeStyles(theme => ({
  addButton: {
    width: '100%',
    borderRadius: 4,
    border: `1px solid ${theme.colors.skyBase}`,
    display: 'flex',
    justifyContent: 'center',
    padding: 12,
    '& > p': { lineHeight: '20px', marginTop: 1 },
  },
}))

function AddFieldButton(props) {
  const { isOpen, onClick } = props
  const css = useStyles({ open: isOpen })

  return (
    <AddButton
      color="primary"
      onClick={() => onClick(oldIsOpen => !oldIsOpen)}
      buttonClass={css.addButton}
      label="Add Field"
    />
  )
}

AddFieldButton.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default AddFieldButton

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import React from 'react'
import { useHistory } from 'react-router-dom'

import ActionBanner from './ActionBanner'

function IncorrectFieldsSettingsBanner() {
  const { values } = React.useContext(BuilderContainerContext)
  const { baseUrl } = values
  const history = useHistory()

  const onUpdateClick = () =>
    history.push('/sms-marketing/settings/tcpa/info', { returnUrl: baseUrl })

  return (
    <ActionBanner
      wrapperStyles={{ marginTop: 32 }}
      type="error"
      actionLabel="Update"
      action={onUpdateClick}
      title="TCPA compliance setup required to publish"
    />
  )
}

export default React.memo(IncorrectFieldsSettingsBanner)

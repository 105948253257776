import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles(theme => {
  const COLORS = Object.entries(theme.colors).reduce(
    (obj, [key, val]) => ({ ...obj, [key]: { color: val } }),
    {}
  )

  return {
    text: {
      fontSize: 14,
      lineHeight: '14px',
      fontFamily: 'Larsseit-Light',
      '& i': {
        fontFamily: 'Larsseit',
        fontStyle: 'normal',
      },
      '& em': {
        fontFamily: 'Larsseit-Bold',
        fontStyle: 'normal',
      },
      '& strong': {
        fontFamily: 'Larsseit-Medium',
        fontWeight: 'normal',
      },
    },
    multiline: {
      lineHeight: '22px',
    },
    ellipsis: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    obscure: {
      filter: 'blur(3px)',
      userSelect: 'none',
    },
    ...COLORS,
  }
})

function P({ children, ...props }) {
  const css = useStyles()
  const classnames = Object.keys(props)
    .map(k => props[k] && css[k])
    .join(' ')

  return <Typography className={`${css.text} ${classnames}`}>{children}</Typography>
}

P.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default React.memo(P)

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { ReactComponent as CloseIcon } from 'images/icons/close.svg'

import { ReactComponent as AbandonedCartImg } from 'images/abandoned_product_preview_placeholder.svg'

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    height: 64,
    width: 'fit-content',
    objectFit: 'contain',
    border: ({ border }) => (border ? `1px solid ${theme.colors.silver}` : 'none'),
    borderRadius: 2,
    margin: '6px 16px 10px 0',
    '& img, & svg': {
      height: '100%',
    },
  },
  close: {
    backgroundColor: 'white',
    borderRadius: '50%',
    border: `1px solid ${theme.colors.silver}`,
    height: 16,
    width: 16,
    position: 'absolute',
    boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.2)',
    right: -8,
    top: -8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '& svg': {
      width: 6,
      height: 6,
      stroke: theme.colors.azure,
    },
  },
}))

const IMAGE_REPLACEMENTS = {
  ABANDONED_PRODUCT_IMAGE: <AbandonedCartImg />,
}

function ImageItem(props) {
  const { image, onDelete } = props

  const border = !(image in IMAGE_REPLACEMENTS)
  const css = useStyles({ border })

  return (
    <div className={css.wrapper}>
      <div className={css.close} onClick={onDelete}>
        <CloseIcon />
      </div>
      {image in IMAGE_REPLACEMENTS ? IMAGE_REPLACEMENTS[image] : <img src={image} alt="preview" />}
    </div>
  )
}

ImageItem.propTypes = {
  onDelete: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
}

export default React.memo(ImageItem)

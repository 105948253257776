import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Text from 'components/texts/Text'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import LightboxBuilderPageContentImageDrop from './LightboxBuilderPageContentImageDrop'
import LightboxBuilderPageContentEnableFields from './LightboxBuilderPageContentEnableFields'

const useStyles = makeStyles({
  editPage: {
    paddingTop: 12,
    display: 'grid',
    gridRowGap: '40px',
  },
  dropImage: {
    margin: '32px 0 40px',
  },
  dropImageTitle: {
    marginBottom: 12,
  },
  banner: {
    marginBottom: 20,
  },
})

function LightboxBuilderContentEdit() {
  const css = useStyles()

  const { builder, lightboxSettings } = React.useContext(BuilderContainerContext)
  const { settings } = lightboxSettings.getSettings()

  let formType = settings.type
  if (formType === 'lightbox') formType = 'popup'

  const isPopup = formType === 'popup'
  const isDesktop = builder.preview.type === 'desktop'
  const showImageDrop = isPopup && isDesktop

  return (
    <div className={css.editPage}>
      {showImageDrop && (
        <div>
          <Text charcoalBase size={16}>
            Main Image
          </Text>
          <LightboxBuilderPageContentImageDrop />
        </div>
      )}

      <LightboxBuilderPageContentEnableFields />
    </div>
  )
}

export default React.memo(LightboxBuilderContentEdit)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import BehaviorTiming from './BehaviorTiming'
import BehaviorFrequency from './BehaviorFrequency'

const useStyles = makeStyles({
  trigger: {
    paddingTop: 12,
    display: 'grid',
    gridRowGap: '60px',
  },
})

function BehaviorTrigger() {
  const css = useStyles()
  const { lightboxSettings } = React.useContext(BuilderContainerContext)
  const settings = lightboxSettings.getSettings()

  const onTimingChange = type => {
    settings.trigger = { type }
  }

  const onFrequencyChange = displayFrequency => {
    settings.trigger = { displayFrequency }
  }

  return (
    <div className={css.trigger}>
      <BehaviorTiming settings={settings} onTimingChange={onTimingChange} />
      <BehaviorFrequency settings={settings} onFrequencyChange={onFrequencyChange} />
    </div>
  )
}

export default React.memo(BehaviorTrigger)

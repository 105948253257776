import React from 'react'
import PropTypes from 'prop-types'
import { Typography, makeStyles } from '@material-ui/core'
import cn from 'classnames'

import textDefinitions from './TextDefinitions.json'

const stylesCreator = ({ font, size, type }) => {
  const definitionsToUse = type === 'marketing' ? 'marketingDefinitions' : 'definitions'
  const { [definitionsToUse]: definitions, fontFamilyMap } = textDefinitions

  const fontSizes = Object.keys(definitions)
    .map(Number)
    .sort()

  if (!fontSizes.includes(size)) {
    throw new Error(`${size}px is not a valid Font Size value. Use (${fontSizes.join(', ')})`)
  }

  const {
    element,
    lineHeight: definedLineHeight,
    fontFamily: definedFontFamilyList,
    letterSpacing: definedLetterSpacing = ['inherit'],
  } = definitions[size]

  if (!font) {
    // if no font supplied, use the first (default) font available for the font size
    [font] = definedFontFamilyList
  }

  if (!definedFontFamilyList.includes(font)) {
    throw new Error(
      `${font} is not a valid Font Family value. Use (${definedFontFamilyList.join(', ')})`
    )
  }

  const styleIndex = definedFontFamilyList.indexOf(font)

  const useStyles = makeStyles(theme => {
    const colors = Object.keys(theme.colors).reduce(
      (obj, key) => ({ ...obj, [key]: { color: theme.colors[key] } }),
      {}
    )

    const lineHeight = `${definedLineHeight[styleIndex]}px`
    const letterSpacing = `${definedLetterSpacing[styleIndex]}px`
    const fontFamily = fontFamilyMap[definedFontFamilyList[styleIndex]]
    const fontSize = `${size}px`

    return {
      text: {
        letterSpacing,
        lineHeight,
        fontFamily,
        fontSize,
      },
      obscure: {
        filter: 'blur(3px)',
        userSelect: 'none',
      },
      ellipsis: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      ...colors,
    }
  })

  return [useStyles, element]
}

function Text({ children, ...props }) {
  const {
    size, type, font, ...rest
  } = props

  const [useStyles, element] = React.useMemo(
    () => stylesCreator({ font, size: Number(size), type }),
    [font, size, type]
  )
  const css = useStyles()

  const classnames = Object.keys(rest)
    .map(k => props[k] && k in css && css[k])
    .filter(Boolean)

  return (
    <Typography component={element} className={cn(css.text, ...classnames)}>
      {children}
    </Typography>
  )
}

Text.propTypes = {
  size: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  font: PropTypes.string,
  type: PropTypes.oneOf(['default', 'marketing']),
}

Text.defaultProps = {
  size: 14,
  font: null,
  type: 'default',
}

export default React.memo(Text)

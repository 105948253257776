import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Dialog, makeStyles } from '@material-ui/core'

import history from 'utils/getHistory'
import styleHelper from 'utils/styleHelper'

import { getCredentials } from 'reducers/selectors/profile'

import Button from 'components/virtual/buttons/Button'
import Text from 'components/texts/Text'
import VirtualTheme from 'components/virtual/VirtualTheme'

import { ReactComponent as Alarm } from 'images/icons/alarm-green.svg'
import { ReactComponent as Close } from 'images/icons/close-virtual.svg'

import { ReactComponent as Rocket } from 'images/banners/rocket-promo-trial-end-mobile.svg'

const useStyles = makeStyles(theme => ({
  dialog: {
    [styleHelper.max(1023)]: {
      margin: '0 154px',
    },
    [styleHelper.mobile]: {
      margin: 'unset',
    },
  },
  close: {
    cursor: 'pointer',
    height: 14,
    position: 'absolute',
    right: 24,
    top: 24,
    width: 14,
  },
  modal: {
    maxWidth: 780,
    display: 'flex',
  },
  hero: {
    width: 240,
    background: theme.colors.blueDarkest,
    overflow: 'hidden',
    position: 'relative',
    [styleHelper.max(1023)]: {
      display: 'none',
    },
  },
  banner: {
    position: 'absolute',
    height: '100%',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 20px 32px',
    [styleHelper.max(1023)]: {
      padding: 48,
    },
    [styleHelper.mobile]: {
      padding: 16,
    },
  },
  mobileGiftContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '32px auto 0px',
    [styleHelper.min(1024)]: {
      display: 'none',
    },
  },
  expirationBadge: {
    width: 'fit-content',
    alignItems: 'center',
    background: theme.colors.tealLightest,
    borderRadius: 4,
    display: 'flex',
    justifyContent: 'center',
    margin: '16px 0px',
    padding: ({ trialEnded }) => (trialEnded ? 0 : 8),
    userSelect: 'none',
    [styleHelper.max(1023)]: {
      margin: '16px auto',
    },
  },
  icon: {
    marginRight: 8,
  },
  title: {
    marginBottom: 20,
    maxWidth: 502,
    [styleHelper.max(1023)]: {
      textAlign: 'center',
    },
  },
  description: {
    marginBottom: 32,
    '& h6': {
      lineHeight: '28px',
    },
  },
  offerItem: {
    margin: 0,
    paddingLeft: 20,
  },
  upgradeBtn: {
    display: 'flex',
    marginBottom: 20,
    [styleHelper.max(1023)]: {
      justifyContent: 'center',
    },
  },
  switchBrands: {
    marginBottom: 4,
    [styleHelper.max(1023)]: {
      textAlign: 'center',
    },
  },
  updatePayment: {
    display: 'flex',
    [styleHelper.max(1023)]: {
      display: 'block',
      textAlign: 'center',
    },
  },
}))

function TrialEndModal() {
  const currentBrand = useSelector(state => state.currentBrand)
  const { hasNotFinishedSetupBrand, hasTrialEnded, showSoftPaywall } = currentBrand.conditionals
  const { length: numBrands } = useSelector(getCredentials)
  const [open, setOpen] = useState(false)
  const trialEnded = open === 'trialEnded'
  const classes = useStyles({ trialEnded })
  const trialAboutToEnd = !trialEnded

  const onClose = () => {
    sessionStorage.setItem('withinTenDaysOfTrialEndPaywallDismissed', true)
    setOpen(false)
  }

  useEffect(() => {
    const checkTrial = path => {
      const impersonating = sessionStorage.getItem('impersonating')
      if (currentBrand.isSensei || impersonating) return false

      if (
        path.match(/(cs-admin|payment-methods|missing-payment|upgrade|elite-request|switch-brand)/)
      ) return false

      if (hasTrialEnded) return 'trialEnded'

      if (showSoftPaywall) return 'trialEnding'

      return false
    }

    setOpen(!hasNotFinishedSetupBrand && checkTrial(history.location.pathname))
    history.listen(location => {
      setOpen(!hasNotFinishedSetupBrand && checkTrial(location.pathname))
    })
  }, [currentBrand, hasNotFinishedSetupBrand, hasTrialEnded, showSoftPaywall])

  const canSwitchBrands = trialEnded && numBrands > 1

  return (
    <VirtualTheme>
      <Dialog className={classes.dialog} maxWidth={false} open={open}>
        {trialAboutToEnd && <Close className={classes.close} onClick={onClose} />}
        <div className={classes.modal}>
          <div className={classes.hero}>
            <img src="/images/upgrade-modal-hero.png" alt="upgrade modal banner" />
          </div>
          <div className={classes.content}>
            <div className={classes.mobileGiftContainer}>
              <Rocket />
            </div>
            <div className={classes.expirationBadge}>
              {trialAboutToEnd && (
                <>
                  <Alarm className={classes.icon} />
                  <Text size={16} tealBase>
                    Your free trial is ending soon!
                  </Text>
                </>
              )}
            </div>
            <div className={classes.title}>
              <Text charcoalDarker size={24}>
                {trialEnded
                  ? 'Oh No! Your Free Trial Has Ended'
                  : 'Fast-track your path to success with a DojoMojo Premium plan'}
              </Text>
            </div>
            <div className={classes.description}>
              <Text size={16} charcoalBase>
                With the Premium plan, you get:
              </Text>
              <ul className={classes.offerItem}>
                <li>
                  <Text size={16} charcoalBase>
                    Unlimited giveaways and in-platform messaging
                  </Text>
                </li>
                <li>
                  <Text size={16} charcoalBase>
                    Custom giveaway add-ons to drive social growth & traffic
                  </Text>
                </li>
                <li>
                  <Text size={16} charcoalBase>
                    Signup forms to turn website visitors into subscribers
                    {' '}
                  </Text>
                </li>
                <li>
                  <Text size={16} charcoalBase>
                    Priority email support
                  </Text>
                </li>
              </ul>
            </div>
            <div className={classes.upgradeBtn}>
              <Link replace to="/upgrade-plan" onClick={onClose}>
                <Button label="Upgrade now" />
              </Link>
            </div>
            {canSwitchBrands && (
              <div className={classes.switchBrands}>
                <Link to="/profile/switch-brand" onClick={onClose}>
                  Switch Brands
                </Link>
              </div>
            )}
            <div className={classes.updatePayment}>
              <Text size={12}>Need to add your payment information?</Text>
              <Text size={12}>
                <Link to="/profile/brand/payment-methods" onClick={onClose}>
                  &nbsp;Update Now
                </Link>
              </Text>
            </div>
          </div>
        </div>
      </Dialog>
    </VirtualTheme>
  )
}

export default React.memo(TrialEndModal)

import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, makeStyles } from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import cn from 'classnames'

import { H3 } from 'components/texts'

const useStyles = makeStyles(theme => ({
  closeHandler: {
    minHeight: 64,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    zIndex: 1,
    padding: '0 20px',
    '& > svg': {
      cursor: 'pointer',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 40px',
    },
    borderBottom: '1px solid #ddd',
  },
  content: {
    padding: '16px 20px',
    overflowY: 'auto',
    paddingBottom: 69,
  },
  extraPadding: {
    padding: 24,
  },
  noPadding: {
    padding: 0,
  },
  barTitle: {
    marginTop: 4,
    marginLeft: 16,
  },
  title: {
    marginBottom: 24,
  },
  centerTitle: {
    display: 'flex',
    justifyContent: 'center',
  },
  paper: {
    maxWidth: 'unset',
    [theme.breakpoints.down('xs')]: {
      margin: 20,
    },
  },
  withShadow: {
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
  },
  wrapperNew: {
    backgroundColor: theme.colors.cream,
    alignSelf: 'stretch',
    flex: 1,
  },
  closeNew: {
    height: '1.4em',
    width: '1.4em',
  },
}))

function MobileModal(props) {
  const {
    allowOutsideClick,
    barTitle,
    closeButton,
    open,
    onClose,
    children,
    title,
    centerTitle,
    full,
    noPad,
    customBarComponent,
    newDesign,
    customClose,
  } = props

  const css = useStyles()

  const wrapperClasses = cn(css.content, {
    [css.extraPadding]: !full,
    [css.noPadding]: noPad,
    [css.wrapperNew]: newDesign,
  })

  return (
    <Dialog
      fullScreen={full}
      open={open}
      onClose={allowOutsideClick ? onClose : null}
      classes={{ paper: cn({ [css.paper]: !full }) }}
    >
      {!closeButton ? null : (
        <div className={cn(css.closeHandler, { [css.withShadow]: barTitle })}>
          {customClose || (
            <CloseOutlined className={cn({ [css.closeNew]: newDesign })} onClick={onClose} />
          )}
          {barTitle && (
            <div className={css.barTitle}>
              <H3>{barTitle}</H3>
            </div>
          )}
          {customBarComponent}
        </div>
      )}
      <div className={wrapperClasses}>
        {title ? (
          <div className={cn(css.title, { [css.centerTitle]: centerTitle })}>
            {React.isValidElement(title) ? (
              title
            ) : (
              <H3>
                <strong>{title}</strong>
              </H3>
            )}
          </div>
        ) : null}
        {children}
      </div>
    </Dialog>
  )
}

MobileModal.defaultProps = {
  closeButton: false,
  full: false,
  noPad: false,
  allowOutsideClick: false,
  title: '',
  barTitle: '',
  centerTitle: false,
  open: false,
  onClose() {},
  customBarComponent: null,
  newDesign: false,
  customClose: null,
}

MobileModal.propTypes = {
  allowOutsideClick: PropTypes.bool,
  closeButton: PropTypes.bool,
  centerTitle: PropTypes.bool,
  open: PropTypes.bool,
  noPad: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  full: PropTypes.bool,
  barTitle: PropTypes.string,
  customBarComponent: PropTypes.element,
  newDesign: PropTypes.bool,
  customClose: PropTypes.node,
}

export default React.memo(MobileModal)

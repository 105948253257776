import React from 'react'
import PropTypes from 'prop-types'
import ButtonBase from '@material-ui/core/ButtonBase'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

import { colors } from 'components/virtual/VirtualTheme'
import Text from 'components/texts/Text'

const useStyles = makeStyles(theme => ({
  main: {
    cursor: 'default',
    overflow: 'hidden',
    position: 'absolute',
    top: 'calc(100% + 4px)',
    left: 0,
    width: 360,
    height: 96,
    backgroundColor: 'white',
    border: `1px solid ${theme.colors.skyBase}`,
    borderRadius: 4,
    display: 'flex',
    flexFlow: 'column',
    '& > :first-child': {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  anchorMenuRight: {
    left: 'auto',
    right: 0,
  },
  buttons: {
    height: 38,
    display: 'flex',
  },
  button: {
    cursor: 'pointer',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: `1px solid ${colors.skyBase}`,
    transition: '200ms background-color ease-in-out',
    '&:hover': {
      backgroundColor: colors.skyLight,
    },
    '&:last-child': {
      backgroundColor: colors.blueBase,
      '&:hover': {
        backgroundColor: colors.navyLight,
      },
    },
  },
}))

function BuilderCloseBeforeSavingPopup(props) {
  const {
    onDiscard, onSave, show, anchorMenuRight,
  } = props

  const css = useStyles()

  if (!show) return null

  const stopPropagate = onClickFn => e => {
    e.stopPropagation()
    if (onClickFn) onClickFn(e)
  }

  return (
    <div
      className={cn(css.main, { [css.anchorMenuRight]: anchorMenuRight })}
      onMouseDown={stopPropagate()}
    >
      <div>
        <Text size={16}>Close without saving?</Text>
      </div>
      <div className={css.buttons}>
        <ButtonBase className={css.button} onMouseDown={stopPropagate(onDiscard)}>
          <Text>Discard Changes</Text>
        </ButtonBase>
        <ButtonBase className={css.button} onMouseDown={stopPropagate(onSave)}>
          <Text white>Save &amp; Close</Text>
        </ButtonBase>
      </div>
    </div>
  )
}

BuilderCloseBeforeSavingPopup.propTypes = {
  onDiscard: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  show: PropTypes.bool,
  anchorMenuRight: PropTypes.bool,
}

BuilderCloseBeforeSavingPopup.defaultProps = {
  show: false,
  anchorMenuRight: false,
}

export default React.memo(BuilderCloseBeforeSavingPopup)

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import React from 'react'

import ActionBanner from './ActionBanner'

function SubscriberLimitReachedBanner() {
  const { actions } = React.useContext(BuilderContainerContext)
  const { onSaveBeforeUpgrade } = actions

  return (
    <ActionBanner
      wrapperStyles={{ marginTop: 32 }}
      type="error"
      actionLabel="Update"
      action={onSaveBeforeUpgrade}
      title="Subscriber limit reached. Upgrade to publish."
    />
  )
}

export default React.memo(SubscriberLimitReachedBanner)

import React, { Component } from 'react'
import { node, object } from 'prop-types'
import { withRouter } from 'react-router-dom'
import { triggerModal } from 'utils/analytics/ErrorReporting'

export class DumbErrorBoundary extends Component {
  static propTypes = {
    children: node,
    location: object.isRequired,
  }

  static defaultProps = {
    children: null,
  }

  state = { hasError: false }

  componentDidUpdate(prevProps) {
    // reset error if changing location
    const { location } = this.props
    if (prevProps.location && prevProps.location !== location) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ hasError: false })
    }
  }

  componentDidCatch(error, errorInfo) {
    triggerModal(error, errorInfo)
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props

    if (hasError) {
      return (
        <style>
          {`.sentry-error-embed-wrapper {
              z-index: 2000 !important;
            }
          `}
        </style>
      )
    }

    return children
  }
}

export default withRouter(DumbErrorBoundary)

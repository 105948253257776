import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import ActionBanner from './ActionBanner'

// Must match ESPIntegrationsModal.js:58
const QUERY_PROP_NAME = 'configEsp'

function NoESPDetectedBanner(props) {
  const {
    espIntegration, lightboxSettings, values, isNew,
  } = React.useContext(
    BuilderContainerContext
  )
  const { ESP, baseUrl } = values
  const settings = lightboxSettings.getSettings()
  const { isLoading } = espIntegration
  const history = useHistory()

  const { withAction } = props

  const hideBanner = settings?.espSettings.brandIntegrationId || ESP || isLoading || isNew

  if (hideBanner) return null

  const onOpenESPModal = () => {
    if (withAction) {
      history.push(`${baseUrl}/settings/integrations?${QUERY_PROP_NAME}=true`)
    }
  }

  return (
    <ActionBanner
      actionLabel={withAction ? 'Integrate ESP' : ''}
      action={onOpenESPModal}
      buttonRight={withAction}
      title="No ESP detected"
    />
  )
}

NoESPDetectedBanner.propTypes = {
  withAction: PropTypes.bool,
}

NoESPDetectedBanner.defaultProps = {
  withAction: false,
}

export default React.memo(NoESPDetectedBanner)

import React from 'react'
import PropTypes from 'prop-types'
import { NavLink, Link } from 'react-router-dom'
import moment from 'moment-timezone'
import cn from 'classnames'

import { makeStyles } from '@material-ui/core'

import styleHelper from 'utils/styleHelper'
import { isTrialing } from 'utils/brandStripeBeltHelper'

const useStyles = makeStyles(theme => ({
  hideTablet: {
    [styleHelper.tablet]: {
      display: 'none',
    },
  },
  link: {
    cursor: 'pointer',
    margin: '0 12px',
    fontFamily: 'Larsseit',
    color: theme.colors.cornflower,
    '&:hover': {
      fontSize: '14px',
      fontWeight: 'bold',
      color: theme.colors.white,
    },
  },
  upgradeLink: {
    fontSize: '16px',
    fontFamily: 'Larsseit',
    fontWeight: 'bold',
    margin: '0px 12px',
    color: theme.colors.neonMint,
    '&:hover': {
      color: theme.colors.neonMint,
    },
  },
  trialDays: {
    fontFamily: 'Larsseit',
    fontSize: '16px',
    fontWeight: 'normal',
    textAlign: 'right',
    marginRight: 4,
    color: theme.colors.white,
  },
}))

function NavBarStatusInfo(props) {
  const css = useStyles()
  const {
    currentBrand, daysLeftInTrial, upgradeAvailable, isAdmin, promoCountdown,
  } = props

  // Logic to either display days left in trial or Promo Countdowns
  // Promo Countdown takes priority over Trial Countdown
  const shouldShowTrialCountdown = isTrialing(currentBrand, currentBrand.customer)

  const availablePromo = promoCountdown.find(({ startDisplay, endDisplay }) =>
    moment().isBetween(startDisplay, endDisplay))

  const daysLeft = availablePromo && moment(availablePromo.endDisplay).diff(moment(), 'days') + 1
  const daysLeftText = availablePromo
    ? `${daysLeft} day${daysLeft > 1 ? 's' : ''} left`
    : shouldShowTrialCountdown && daysLeftInTrial > 0
      ? `${daysLeftInTrial} day${daysLeftInTrial > 1 ? 's' : ''} left in trial`
      : null

  const promoText = availablePromo ? availablePromo.promoText : upgradeAvailable ? 'Upgrade' : null
  const promoLink = availablePromo
    ? availablePromo.promoLink
    : upgradeAvailable
      ? '/upgrade-plan'
      : null

  const AdminLink = () => (
    <NavLink
      to="/cs-admin"
      className={cn(css.link, css.hideTablet)}
      activeStyle={{ fontWeight: 'bold', color: 'white' }}
    >
      Admin
    </NavLink>
  )

  const DaysLeft = () => (
    <span className={cn(css.trialDays, css.hideTablet, 'trial-days-placeholder')}>
      {daysLeftText}
    </span>
  )

  const PromoDescription = promoText
    ? () => (
      <Link to={promoLink} className={cn(css.upgradeLink, css.hideTablet)}>
        {promoText}
      </Link>
    )
    : null

  return (
    <>
      {isAdmin && <AdminLink />}
      {daysLeftText && <DaysLeft />}
      {promoText && <PromoDescription />}
    </>
  )
}

NavBarStatusInfo.propTypes = {
  currentBrand: PropTypes.object.isRequired,
  daysLeftInTrial: PropTypes.number.isRequired,
  upgradeAvailable: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  promoCountdown: PropTypes.array.isRequired,
}

export default NavBarStatusInfo

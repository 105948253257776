import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  tag: {
    textTransform: 'capitalize',
    color: 'white',
    backgroundColor: theme.colors.cerulean,
    padding: 4,
    fontFamily: 'Larsseit',
    borderRadius: 2,
    userSelect: 'none',
    whiteSpace: 'pre',
  },
}))

function EditorTagWrapper(props) {
  const css = useStyles()
  const { children, tag } = props

  // IMPORTANT: this was a PITA to deal with... In order to show something different
  // from what's in the editor, you can force it by doing the logic I did down there...
  // BUT the values must be the same length in order for the editor to maintain its state
  // accordingly with what's intended to be shown (we want to keep the *|WORD|* text part
  // for us to know where to do the replacements in the preview)
  const value = tag
    .replace(/[^A-Z]/g, ' ')
    .toLowerCase()
    .replace('url', 'URL') // temporary solution for the URL part

  if (!value) return null

  const childCopy = React.cloneElement(children[0], { text: value })

  return (
    <span contentEditable={false} className={css.tag}>
      {childCopy}
    </span>
  )
}

EditorTagWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  tag: PropTypes.string.isRequired,
}

export default React.memo(EditorTagWrapper)

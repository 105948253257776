import React from 'react'

import { PageTitle } from 'components/builder/titles'

const TITLE = 'ESP Integration'

const TOOLTIP_TEXT = 'Connect your ESP to migrate data collected by your signup form to your email marketing platform.'
const ACTION_LABEL = 'Learn More'
const KB_LINK = 'https://help.dojomojo.com/hc/en-us/articles/360055722432-Integrating-Your-ESP-to-Your-Lightbox'

function SectionTitle() {
  const onTooltipClick = () => window.open(KB_LINK, '_blank')

  const tooltip = {
    text: TOOLTIP_TEXT,
    action: onTooltipClick,
    actionLabel: ACTION_LABEL,
  }

  return <PageTitle text={TITLE} tooltip={tooltip} />
}

export default React.memo(SectionTitle)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

import Text from 'components/texts/Text'

import fieldOptions from 'views/mobile/lightbox-builder/utils/field-options'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import ESPFieldValuesEmptyState from './ESPFieldValuesEmptyState'

const { getFieldIcon } = fieldOptions

const useStyles = makeStyles(theme => ({
  table: {
    width: '100%',
  },
  header: {
    backgroundColor: theme.colors.skyLight,
    display: 'flex',
    flex: 1,
  },
  row: {
    display: 'flex',
    flex: 1,
    padding: '8px 0',
    borderBottom: `1px solid ${theme.colors.skyBase}`,
  },
  item: {
    overflow: 'hidden',
    padding: '12px 16px',
    height: 40,
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    '&:first-of-type': {
      flexGrow: 1.4,
    },
    '& > p': {
      paddingTop: 2,
    },
    '& > :nth-child(2)': {
      marginLeft: 8,
    },
    '& > svg': {
      color: theme.colors.navyBase,
    },
  },
  statusIndicator: {
    height: 8,
    width: 8,
    borderRadius: '50%',
    backgroundColor: theme.colors.skyBase,
    left: 0,
  },
  notsynced: { backgroundColor: theme.colors.redBase },
  connected: { backgroundColor: theme.colors.greenBase },
}))

function ESPFieldValuesMergeTags() {
  const { values, lightboxSettings, espIntegration } = React.useContext(BuilderContainerContext)
  const settings = lightboxSettings.getSettings()
  const { ESP, containsEmailField } = values
  const css = useStyles()

  if (!ESP) return null

  const getESPFieldsFromPages = () =>
    settings.pages.reduce(
      (fields, page) => [...fields, ...page.inputs.filter(input => input.name !== 'email')],
      []
    )

  const ESPFields = getESPFieldsFromPages()

  const isESPFieldConnected = input => {
    if (input.name === 'email') return true

    const { mergeTags, mergeTagHelpers } = ESP.integrationData || {}
    const tags = mergeTags?.length ? mergeTags : mergeTagHelpers

    return tags.some(tag => {
      if (input.isTemplate) {
        const name = input.fixedName ?? input.name
        return tag.attribute === name && tag.value === input.espValue
      }
      return tag.attribute === input.label && tag.value === input.espValue
    })
  }

  const getESPFieldStatus = input => {
    const isConnected = isESPFieldConnected(input)
    const status = isConnected ? 'Connected' : 'Not Synced'

    const indicatorClassnames = cn(
      css.statusIndicator,
      css[isConnected ? 'connected' : 'notsynced']
    )

    return (
      <div className={css.item}>
        <div className={indicatorClassnames} />
        <Text ellipsis>{status}</Text>
      </div>
    )
  }

  const renderField = input => (
    <div className={css.row} key={input.name}>
      <div className={css.item}>
        {getFieldIcon(input.name)}
        <Text navyBase ellipsis>
          {input.isTemplate ? input.placeholder : input.label}
        </Text>
      </div>
      <div className={css.item}>
        <Text charcoalDarker ellipsis>
          {input.espValue || '-'}
        </Text>
      </div>
      {getESPFieldStatus(input)}
    </div>
  )

  const renderMailField = () => {
    if (containsEmailField) return renderField({ name: 'email', espValue: 'EMAIL', label: 'Email' })
    return null
  }

  const isEmpty = !ESPFields.length || !espIntegration.canSupportESP(ESP?.key)

  if (isEmpty) {
    return <ESPFieldValuesEmptyState />
  }

  return (
    <div className={css.table}>
      <div className={css.header}>
        {['FIELD', 'VALUE', 'STATUS'].map(header => (
          <div className={css.item} key={header}>
            <Text size={10} charcoalDarker>
              {header}
            </Text>
          </div>
        ))}
      </div>
      {renderMailField()}
      {ESPFields.map(renderField)}
    </div>
  )
}

export default React.memo(ESPFieldValuesMergeTags)

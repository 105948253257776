import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import { H3, Small } from 'components/texts'
import { Button } from 'components/buttons'

const useStyles = makeStyles(theme => ({
  customBarContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  createNewText: {
    marginLeft: 12,
    marginBottom: -4,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    margin: 0,
  },
  questionMark: {
    color: 'white',
    fontSize: 8,
    lineHeight: '12px',
    fontWeight: 'bold',
    backgroundColor: theme.colors.azure,
    height: 12,
    width: 12,
    borderRadius: '50%',
    textAlign: 'center',
    padding: 0,
    margin: '0 5px 0 12px',
  },
  buttonGroup: {
    maxWidth: '50%',
    '& > button': {
      minWidth: 100,
    },
  },
}))

const CustomBar = ({
  knowledgeBaseObj,
  saveAndExit,
  previewOnly,
  title,
  buttonType,
  badge,
  isLoading,
  saveButton,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.customBarContainer}>
      <div className={classes.createNewText}>
        {React.isValidElement(title) ? title : <H3>{title}</H3>}
        {badge}
        {knowledgeBaseObj && (
          <a
            href={knowledgeBaseObj.to}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.header}
          >
            <p className={classes.questionMark}>?</p>
            <Small azure>
              <em>{knowledgeBaseObj.text}</em>
            </Small>
          </a>
        )}
      </div>
      {!previewOnly && (
        <div className={classes.buttonGroup}>
          {saveButton || (
            <Button
              disabled={isLoading}
              label="Save & Exit"
              onClick={saveAndExit}
              type={buttonType}
              id="saveAndCloseId" // TODO: remove once automated messages are reworked
            />
          )}
        </div>
      )}
    </div>
  )
}

CustomBar.propTypes = {
  saveAndExit: PropTypes.func,
  knowledgeBaseObj: PropTypes.object,
  previewOnly: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  badge: PropTypes.node,
  buttonType: PropTypes.string,
  saveButton: PropTypes.node,
}

CustomBar.defaultProps = {
  title: '',
  isLoading: false,
  badge: null,
  buttonType: 'contained',
  knowledgeBaseObj: null,
  saveButton: null,
  saveAndExit: null,
}

export default CustomBar

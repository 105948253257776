/* eslint-disable no-useless-escape */

// to match usePostEntrySms.js:19
export default function checkForInvalidShortUrlLink(messages) {
  const services = ['tinyurl.com', 'bit.ly', 'onelink.to']
  // TODO: Figure out how to deal with rebrandly
  const regex = new RegExp(`^https?:\/\/(www\.)?(${services.join('|')})`)
  return messages.some(({ custom_sms_message: msg }) =>
    msg.split(' ').some(word => regex.test(word)))
}

import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox as MuiCheckbox, makeStyles } from '@material-ui/core'
import cn from 'classnames'

import { P } from 'components/texts'

const useStyles = makeStyles(theme => ({
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: -10,
    '& span:first-child': {
      backgroundColor: 'transparent !important',
    },
  },
  checkbox: {
    '& span:first-child': {
      color: theme.colors.ink,
      transition: 'color .2s linear, opacity: .2 linear',
    },
  },
  disabled: {
    '& span:first-child': {
      opacity: 0.26,
    },
  },
  label: {
    marginTop: 3,
  },
  checked: {
    '& span:first-child svg': {
      color: theme.colors.aquamarine,
    },
  },
}))

const useOldStyles = makeStyles(theme => ({
  checkbox: {
    margin: '16px 0',
    '& > span': {
      width: 20,
      height: 20,
      '& path': {
        stroke: props => (props.checked ? null : 'white'),
        color: props => (props.checked ? theme.colors.aquamarine : theme.colors.darkGrey),
      },
      '& + div': {
        paddingLeft: 10,
      },
    },
  },
}))

function CheckboxInput(props) {
  const classes = useStyles()
  const oldClasses = useOldStyles(props)

  const {
    label, disabled, checked, onChange, oldDesign,
  } = props

  return (
    <label className={cn(classes.checkboxLabel, { [oldClasses.checkbox]: oldDesign })}>
      <MuiCheckbox
        classes={{
          root: classes.checkbox,
          checked: classes.checked,
          disabled: classes.disabled,
        }}
        disabled={disabled}
        checked={checked}
        onChange={() => onChange(!checked)}
        color="primary"
      />
      <div className={classes.label}>{React.isValidElement(label) ? label : <P>{label}</P>}</div>
    </label>
  )
}

CheckboxInput.propTypes = {
  label: PropTypes.node,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  oldDesign: PropTypes.bool,
  onChange: PropTypes.func,
}

CheckboxInput.defaultProps = {
  label: '',
  disabled: false,
  checked: false,
  oldDesign: false,
  onChange() {},
}

export default React.memo(CheckboxInput)

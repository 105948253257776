import React from 'react'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

import BehaviorTrigger from './trigger'
import BehaviorUrlTargeting from './urlTargeting'
import BehaviorAutoCloseDelay from './autoCloseDelay'

function LightboxBuilderBehaviorEdit() {
  const { builder } = React.useContext(BuilderContainerContext)
  const {
    drawer: { data: drawerData },
  } = builder

  const EditComponent = {
    trigger: BehaviorTrigger,
    urlTargeting: BehaviorUrlTargeting,
    autoCloseDelay: BehaviorAutoCloseDelay,
  }[drawerData?.type]

  return EditComponent && <EditComponent />
}

export default React.memo(LightboxBuilderBehaviorEdit)

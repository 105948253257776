import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import UnpublishedFormRequiredModal from 'views/mobile/lightbox-builder/modals/UnpublishedFormRequiredModal'
import ESPRequiredOrNotSupportedBanner from 'views/mobile/lightbox-builder/banners/ESPRequiredOrNotSupportedBanner'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import ESPList from './ESPList'
import ESPIntegrationTitle from './ESPIntegrationTitle'
import ESPIntegrationActions from './ESPIntegrationActions'
import ESPIntegrationImage from './ESPIntegrationImage'

const useStyles = makeStyles(theme => ({
  integration: {
    marginTop: 20,
    border: `1px solid ${theme.colors.skyBase}`,
    borderRadius: 4,
  },
  current: {
    position: 'relative',
    display: 'flex',
    padding: 20,
  },
  banners: {
    padding: '0 20px',
  },
}))

function ESPIntegration() {
  const { values } = React.useContext(BuilderContainerContext)
  const { ESP } = values
  const [ESPToSet, setESPToSet] = useState(null)
  const css = useStyles()

  return (
    <div className={css.integration}>
      <div>
        <div className={css.current}>
          <ESPIntegrationImage />
          <ESPIntegrationTitle />
          <ESPIntegrationActions setESP={setESPToSet} />
        </div>
        {!ESP && (
          <div className={css.banners}>
            <ESPRequiredOrNotSupportedBanner />
          </div>
        )}
      </div>
      <ESPList />
      <UnpublishedFormRequiredModal esp={ESPToSet} onClose={() => setESPToSet(null)} />
    </div>
  )
}

export default React.memo(ESPIntegration)

import React from 'react'
import {
  Snackbar, SnackbarContent, IconButton, makeStyles,
} from '@material-ui/core'
import { CloseSharp } from '@material-ui/icons'
import { H3 } from 'components/texts'

const useStyles = makeStyles(theme => ({
  snackbar: {
    opacity: 0.7,
  },
  content: {
    backgroundColor: theme.colors.alertRed,
  },
  closeAction: {
    color: 'white',
  },
}))

const Action = () => {
  const css = useStyles()

  return (
    <IconButton color="inherit" onClick={window.close}>
      <CloseSharp className={css.closeAction} />
    </IconButton>
  )
}

export default function ImpersonatingSnackbar() {
  const classes = useStyles()
  return (
    <Snackbar
      className={classes.snackbar}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open
    >
      <SnackbarContent
        message={<H3>You are impersonating</H3>}
        action={<Action />}
        className={classes.content}
      />
    </Snackbar>
  )
}

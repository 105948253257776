import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { SMSEditorContext } from 'contexts/SMSEditorContext'
import ImageItem from './ImageItem'

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
}))

function ImagesPreview() {
  const css = useStyles()

  const {
    images,
    actions: { onSetImages },
  } = useContext(SMSEditorContext)

  if (!images.length) return null

  return (
    <div className={css.wrapper}>
      {images.map(image => (
        <ImageItem
          key={image}
          image={typeof image === 'string' ? image : image.fullSrc || image.img64}
          onDelete={() => onSetImages(image, true)}
        />
      ))}
    </div>
  )
}

export default React.memo(ImagesPreview)

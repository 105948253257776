import React from 'react'
import { object, func } from 'prop-types'
import { Link } from 'react-router-dom'
import { Small } from 'components/texts'
import { MenuItem, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  logoWrapper: {
    borderRadius: '50%',
    overflow: 'hidden',
    height: '36px',
    width: '36px',
  },
  logo: {
    objectFit: 'contain',
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
  },
  name: {
    margin: '0px 12px',
    width: '150px',
  },
}))

function ManagedBrand({ brand, onClose }) {
  const { id, accountname, logo } = brand
  const classes = useStyles()
  return (
    <div>
      <Link to={`/?brandId=${id}`} onClick={onClose} target="_blank">
        <MenuItem>
          <div className={classes.logoWrapper}>
            <img src={logo} className={classes.logo} alt={accountname} />
          </div>
          <div className={classes.name}>
            <Small ellipsis>
              <i className="brandAccountName">
                {' '}
                {accountname}
              </i>
            </Small>
          </div>
        </MenuItem>
      </Link>
    </div>
  )
}

ManagedBrand.propTypes = {
  brand: object.isRequired,
  onClose: func.isRequired,
}

export default ManagedBrand

import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { PageSubtitle, PageTitle } from 'components/builder/titles'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

import { LightboxBuilderFields } from '../../setup/fields'

const useStyles = makeStyles(() => ({
  container: {
    display: 'grid',
    gridRowGap: '18px',
    padding: 20,
    height: 'fit-content',
  },
}))

const PAGE_TITLE_COPY_MAP = {
  popup: {
    title: 'Pop-Up Content',
    subtitle: 'Add, arrange, and customize pages and fields for your form.',
  },
  embed: {
    title: 'Embed Content',
    subtitle: 'Add, arrange, and edit steps and fields for your form.',
  },
}

function LightboxBuilderContent() {
  const css = useStyles()

  const { lightboxSettings } = useContext(BuilderContainerContext)
  const { settings } = lightboxSettings.getSettings()

  let formType = settings.type
  if (formType === 'lightbox') formType = 'popup'
  const pageCopy = PAGE_TITLE_COPY_MAP[formType]

  return (
    <div className={css.container}>
      <div>
        <PageTitle text={pageCopy.title} />
        <PageSubtitle text={pageCopy.subtitle} />
      </div>

      <LightboxBuilderFields />
    </div>
  )
}

export default React.memo(LightboxBuilderContent)

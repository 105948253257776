import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Paper from '@material-ui/core/Paper'

import { P } from 'components/texts'
import { Button } from 'components/buttons'

const useStyles = makeStyles(theme => ({
  wrapper: {
    border: `1px solid ${theme.colors.silver}`,
    width: ({ setWidth }) => (setWidth ? 280 : 'unset'),
    maxWidth: ({ setWidth }) => (setWidth ? 384 : 'none'),
    position: ({ placement }) => (placement ? 'fixed' : 'absolute'),
    top: ({ placement }) => (placement ? `calc(${placement.top}px + 14px)` : 'calc(100% + 2px)'),
    left: ({ placement }) => (placement ? placement.left : 0),
    padding: '16px 12px 8px 16px',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
    zIndex: 20,
    '& > p': {
      marginBottom: 4,
      '&:first-child': {
        marginBottom: 8,
      },
    },
  },
  buttons: {
    float: 'right',
    '& > button:first-child': {
      marginRight: 4,
    },
  },
}))

function SMSEditorGenericModal(props) {
  const {
    show, title, content, onConfirm, onCancel, cancelLabel, confirmLabel, placement,
  } = props
  const [div, setDiv] = useState(null)
  const isElement = React.isValidElement(content)

  const css = useStyles({ placement, setWidth: !isElement })

  useEffect(() => {
    if (!placement) return () => {}

    const container = document.getElementById('sms-editor-wrapper')

    const tmpDiv = document.createElement('div')
    tmpDiv.style = 'position: relative;'

    setDiv(tmpDiv)
    if (!div) container.appendChild(tmpDiv)

    return () => {
      if (div) div.remove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [div])

  if (!show) return null

  const modal = (
    <Paper contentEditable={false} classes={{ root: css.wrapper }}>
      {title && (
        <P>
          <strong>{title}</strong>
        </P>
      )}
      {isElement ? content : <P multiline>{content}</P>}
      {onCancel && onConfirm && (
        <div className={css.buttons}>
          <Button type="text" label={cancelLabel} onClick={onCancel} />
          <Button type="text" label={confirmLabel} onClick={onConfirm} />
        </div>
      )}
    </Paper>
  )

  if (div) return ReactDOM.createPortal(modal, div)

  return modal
}

SMSEditorGenericModal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  placement: PropTypes.object,
}

SMSEditorGenericModal.defaultProps = {
  confirmLabel: 'Confirm',
  cancelLabel: 'Cancel',
  onConfirm: null,
  onCancel: null,
  placement: null,
}

export default React.memo(SMSEditorGenericModal)

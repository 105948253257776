import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import {
  Editor, EditorState, ContentState, Modifier, SelectionState,
} from 'draft-js'

import { replaceEscapeChars } from 'utils/sms'
import ImagesPreview from './utils/ImagesPreview'
import decorators from './utils/editorUtils'

const styles = {
  content: {
    padding: 12,
    flex: 1,
    overflow: 'auto',
  },
  contentEditable: {
    outline: 'none',
    font: '14px/22px Larsseit-Light', // fontSize/lineHeight fontFamily
  },
  editor: {
    height: '100%',
    '& .public-DraftStyleDefault-block': {
      font: '14px/28px Larsseit-Light',
    },
  },
}

class SMSEditorBody extends PureComponent {
  containerRef = React.createRef()

  editorRef = React.createRef()

  state = {
    editorState: EditorState.createEmpty(null),
    lastSelection: null,
  }

  componentDidMount() {
    let { text } = this.props
    const {
      actions: { actionsRef },
    } = this.props

    this._replaceLinks({
      text,
      callback: link => {
        text = text.replace(link.originalUrl, link.minifiedUrl)
      },
    })

    this._replaceCouponCodes({
      text,
      callback: coupon => {
        text = text.replace(new RegExp(coupon, 'g'), '*|COUPON(_CODE)?|*')
      },
    })

    const editorState = text
      ? EditorState.createWithContent(
        ContentState.createFromText(text),
        decorators(this._getMergeTagsAvailable())
      )
      : EditorState.createEmpty(decorators(this._getMergeTagsAvailable()))
    const lastSelection = editorState.getSelection()

    this.setState({ editorState, lastSelection })

    actionsRef.insertText = this._insertText
    actionsRef.replaceText = this._replaceTextInEditor
  }

  // Force re-rendering when coupon is tracked
  componentDidUpdate(prevProps) {
    const { coupon, minifiedLinks } = this.props
    if (prevProps.coupon !== coupon || minifiedLinks !== prevProps.minifiedLinks) {
      const { editorState } = this.state
      this._onEditorChange(editorState)
    }
  }

  _reflectChanges = () => {
    this.editorRef.current.blur()
    this.editorRef.current.focus()
  }

  _getMergeTagsAvailable = () => {
    const { mergeTags } = this.props

    if (Array.isArray(mergeTags)) return mergeTags.map(({ value }) => value)

    return Object.keys(mergeTags)
      .filter(key => !key.startsWith('*')) // filter tag keys we dont want in our blue tags regex
      .reduce((tags, key) => [...tags, ...mergeTags[key].map(({ value }) => value)], [])
    // tags: { section: [{ value: 'Value1' }, { value: 'Value2 }]}
    // basically, converts tags Object into array of values => ['Value', 'Value2']
  }

  _replaceTextInEditor = opts => {
    const { editorState } = this.state

    const currentContentState = editorState.getCurrentContent()
    const blockMap = currentContentState.getBlockMap()

    const {
      text,
      textToReplace,
      blocks = blockMap,
      blockKey: currentBlockKey = null,
      match: currentMatch = null,
    } = opts

    let { contentState = currentContentState } = opts

    const replacerFn = (...params) => {
      const [match, blockKey] = params
      const { index: start } = match

      const selection = SelectionState.createEmpty(blockKey)
        .set('anchorOffset', start)
        .set('focusOffset', start + text.length)

      contentState = Modifier.replaceText(contentState, selection, textToReplace)
    }

    if (currentBlockKey && currentMatch) {
      replacerFn(currentMatch, currentBlockKey)

      return contentState
    }

    const regex = new RegExp(text.replace(/(\||\\|\*)/g, '\\$1'), 'g')

    // eslint-disable-next-line no-restricted-syntax
    for (const [, block] of blocks) {
      const { text: blockText, key: blockKey } = block

      let match
      // eslint-disable-next-line no-cond-assign
      while ((match = regex.exec(blockText))) replacerFn(match, blockKey)
    }

    return this.setState(
      {
        editorState: EditorState.createWithContent(
          contentState,
          decorators(this._getMergeTagsAvailable())
        ),
      },
      this._reflectChanges
    )
  }

  // Insert tags/links into editor state
  _insertText = (text, spaces = true) => {
    const { lastSelection: selection, editorState } = this.state
    const {
      actions: { setText },
    } = this.props

    let contentState = editorState.getCurrentContent()

    contentState = Modifier.insertText(
      contentState,
      selection,
      `${spaces ? ' ' : ''}${text}${spaces ? ' ' : ''}`
    )

    const newEditorState = EditorState.push(editorState, contentState, 'insert-characters')

    const newText = contentState.getPlainText()

    setText(newText)

    this.setState({ editorState: newEditorState })
  }

  _replaceLinks = opts => {
    const { text, blockKey, callback } = opts
    let { contentState } = opts
    const { minifiedLinks } = this.props

    minifiedLinks.forEach(link => {
      let textCursor = 0
      const escapedLink = link.originalUrl.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&')
      const regex = new RegExp(`^${escapedLink}$`, 'g')

      text.split(/\s|\n/).forEach(word => {
        if (regex.test(word)) {
          const start = text.indexOf(word, textCursor)

          if (callback) callback(link)
          else {
            contentState = this._replaceTextInEditor({
              text: link.originalUrl,
              textToReplace: link.minifiedUrl,
              blockKey,
              match: { index: start },
              contentState,
            })
          }
        }

        textCursor += word.length + 1
      })
    })

    return contentState
  }

  _replaceCouponCodes = opts => {
    const { text, blockKey, callback } = opts
    let { contentState } = opts
    const { coupon } = this.props

    if (!coupon) return contentState

    const regex = new RegExp(`^${coupon}$`, 'g')

    let textCursor = 0

    text.split(/\s|\n/).forEach(word => {
      if (regex.test(word)) {
        const start = text.indexOf(word, textCursor)

        if (callback) callback(word)
        else {
          contentState = this._replaceTextInEditor({
            blockKey,
            text: word,
            textToReplace: '*|COUPON_CODE|*',
            match: { index: start },
          })
        }
      }

      textCursor += word.length + 1
    })

    return contentState
  }

  _applyEditorReplacements = nextEditorState => {
    const {
      actions: { setText },
    } = this.props

    let contentState = nextEditorState.getCurrentContent()
    const blocks = contentState.getBlockMap()

    let blockCount = blocks.length

    // eslint-disable-next-line no-restricted-syntax
    for (const [, block] of blocks) {
      const { text: blockText, key: blockKey } = block

      contentState = this._replaceLinks({
        text: blockText,
        block: --blockCount,
        blockKey,
        contentState,
      })

      contentState = this._replaceCouponCodes({
        text: blockText,
        blockKey,
        contentState,
      })
    }

    nextEditorState = EditorState.push(nextEditorState, contentState, 'insert-fragment')

    const newText = contentState.getPlainText()

    setText(newText)

    return nextEditorState
  }

  _onEditorChange = nextEditorState => {
    nextEditorState = this._applyEditorReplacements(nextEditorState)

    nextEditorState = EditorState.set(nextEditorState, {
      decorator: decorators(this._getMergeTagsAvailable()),
    })

    this.setState({ editorState: nextEditorState })
  }

  _onEditorBlur = () => {
    const { editorState } = this.state

    const lastSelection = editorState.getSelection()

    this.setState({ lastSelection })
  }

  _onContentPasted = pastedText => {
    const { text, hasEscapeChars } = replaceEscapeChars(pastedText)

    if (hasEscapeChars) {
      const { editorState } = this.state

      // we'll add a message for the offending user to the editor state
      const newContent = Modifier.insertText(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        text
      )

      const newEditorState = EditorState.push(editorState, newContent, 'insert-characters')

      this._onEditorChange(newEditorState)

      return true
    }
    return false
  }

  render() {
    const {
      readOnly,
      classes: css,
      text,
      inputName,
      imageName,
      images: [image], // Only return one for now
    } = this.props
    const { editorState } = this.state

    return (
      <div className={css.content}>
        <ImagesPreview />
        <div
          id="sms-editor-wrapper"
          className={css.editor}
          onClick={() => this.editorRef.current.focus()}
        >
          <Editor
            readOnly={readOnly}
            editorState={editorState}
            onBlur={this._onEditorBlur}
            onChange={this._onEditorChange}
            ref={this.editorRef}
            handlePastedText={this._onContentPasted}
            spellCheck
          />
          {/* Allow use for Final Form */}
          {inputName && <input type="text" hidden value={text} name={inputName} readOnly />}
          {imageName && <input type="text" hidden value={image} name={imageName} readOnly />}
        </div>
      </div>
    )
  }
}

SMSEditorBody.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  mergeTags: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  text: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  imageName: PropTypes.string.isRequired,
  coupon: PropTypes.string,
  readOnly: PropTypes.bool.isRequired,
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  minifiedLinks: PropTypes.arrayOf(PropTypes.object),
}

SMSEditorBody.defaultProps = {
  text: '',
  coupon: '',
  minifiedLinks: [],
}

export default withStyles(styles)(SMSEditorBody)

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core'

import Text from 'components/texts/Text'
import Separator from 'components/shared/Separator'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

import fieldConf from 'views/mobile/lightbox-builder/utils/field-options'

const { getFieldIcon } = fieldConf

const useStyles = makeStyles(theme => ({
  fields: {
    marginTop: 8,
    overflowY: 'scroll',
    position: 'relative',
    top: 'calc(100% + 12px)',
    width: '100%',
    maxHeight: ({ open }) => (open ? 1200 : 0),
    transition: 'max-height .1s ease-in-out',
    boxShadow: '0px 2px 8px rgba(90, 116, 129, 0.1)',
    border: ({ open }) => open && `1px solid ${theme.colors.skyBase}`,
    borderRadius: 4,
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  item: {
    margin: '4px 0',
    height: 38,
    padding: '24px 12px',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: 8,
    color: theme.colors.navyBase,
  },
  field: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.skyLight,
    },
    '& > p': {
      marginTop: 2,
    },
  },
  textDisabled: { color: theme.colors.skyDarker },
  disabled: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'white !important',
    },
  },
}))

const CATEGORY_MAP = {
  standard: 'Standard Fields',
  custom: 'Custom Fields',
}

function FieldSelectionFields(props) {
  const { open, onSelect } = props
  const css = useStyles({ open })
  const fieldsDropdownRef = React.useRef(null)
  const timeoutRef = React.useRef(null)

  const { values } = React.useContext(BuilderContainerContext)
  const { templates } = values

  const CategoryTitle = titleProps => {
    // eslint-disable-next-line react/prop-types
    const { type } = titleProps

    return (
      <div className={cn(css.item, css.capitalize)}>
        <Text size={10} charcoalBase>
          {CATEGORY_MAP[type]}
        </Text>
      </div>
    )
  }

  const CategoryItem = field => {
    // eslint-disable-next-line react/prop-types
    const { label, selected, name } = field

    const onItemClick = () => {
      if (!selected) onSelect(name)
    }

    const icon = React.cloneElement(getFieldIcon(name), {
      className: cn(css.icon, { [css.textDisabled]: selected }),
    })

    return (
      <div className={cn(css.item, css.field, { [css.disabled]: selected })} onClick={onItemClick}>
        {icon}
        <Text navyBase={!selected} skyDarker={selected}>
          {label}
        </Text>
      </div>
    )
  }

  useEffect(() => {
    clearTimeout(timeoutRef.current)
    if (open && fieldsDropdownRef.current) {
      timeoutRef.current = setTimeout(() => {
        const { lastChild } = fieldsDropdownRef.current
        lastChild.scrollIntoView({ behavior: 'smooth' })
      }, 100)
    }
    return () => clearTimeout(timeoutRef.current)
  }, [fieldsDropdownRef, open])

  return (
    // TODO: maybe add ClickAwayListener here?
    <div ref={fieldsDropdownRef} className={css.fields}>
      {Object.keys(templates).map((category, i) => (
        <React.Fragment key={category}>
          {i ? <Separator /> : null}
          <CategoryTitle type={category} />
          {Object.keys(templates[category]).map(fieldName => (
            <CategoryItem key={fieldName} name={fieldName} {...templates[category][fieldName]} />
          ))}
        </React.Fragment>
      ))}
    </div>
  )
}

FieldSelectionFields.propTypes = {
  open: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default React.memo(FieldSelectionFields)

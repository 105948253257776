import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

import { PageTitle } from 'components/builder/titles'
import Text from 'components/texts/Text'

import Button from 'components/virtual/buttons/Button'
import Dropdown from 'components/virtual/selectors/Dropdown'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import { Info as InfoIcon } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  espListContainer: {
    padding: 20,
    borderBottom: `1px solid ${theme.colors.skyBase}`,
    borderTop: `1px solid ${theme.colors.skyBase}`,
  },
  integrationButton: {
    padding: '0 20px 20px',
    display: 'flex',
    flexFlow: 'column',
    '& > button': {
      alignSelf: 'flex-end',
    },
  },
  infoSection: {
    padding: ({ padding }) => (padding ? '16px 20px' : '0'),
    marginBottom: ({ padding }) => (padding ? 'none' : 20),
    display: 'flex',
    '& > p': {
      paddingTop: 2,
    },
  },
  infoIcon: {
    width: 20,
    marginRight: 8,
    color: theme.colors.charcoalLighter,
  },
}))

// eslint-disable-next-line react/prop-types
const InformationalSection = ({ text, padding }) => {
  const css = useStyles({ padding })
  return (
    <div className={css.infoSection}>
      <InfoIcon className={css.infoIcon} />
      <Text charcoalLight size={12}>
        {text || 'Don’t see your list? Save your work and refresh this page'}
      </Text>
    </div>
  )
}

const ESP_LIST_TOOLTIP = "Choose the list you'd like to add your new subscribers to. Tip: Send a welcome email series to all contacts added to this list."
const ESP_LIST_KB = 'https://help.dojomojo.com/hc/en-us/articles/115003905192-How-to-Optimize-Your-Email-Drip-Campaign'
const ESP_LIST_ACTION_TITLE = 'Welcome Email Setup'

const titleProps = {
  text: 'ESP List',
  textProps: {
    size: 14,
    charcoalBase: true,
  },
  tooltip: {
    text: ESP_LIST_TOOLTIP,
    action: () => window.open(ESP_LIST_KB, '_blank'),
    actionLabel: ESP_LIST_ACTION_TITLE,
  },
}

// Must match ESPIntegrationsModal.js:58
const QUERY_PROP_NAME = 'configEsp'

function ESPList() {
  const { lightboxSettings, values, espIntegration } = React.useContext(BuilderContainerContext)
  const settings = lightboxSettings.getSettings()
  const { ESP } = values
  const history = useHistory()
  const css = useStyles()

  const onSelectESPList = list => {
    settings.espSettings = { listId: list.value }
  }

  if (ESP) {
    const supportsESP = espIntegration.canSupportESP(ESP.key)
    const hasListItems = ESP?.integrationData.details.lists?.length > 0 ?? false
    const ESPListOptions = supportsESP && hasListItems ? ESP.integrationData.details.lists : null

    const options = ESPListOptions || [{ value: 0, label: 'Signup form subscriber list' }]

    let defaultValue
    if (settings.espSettings.listId) {
      defaultValue = options.find(({ value }) => value === settings.espSettings.listId)
    }
    if (!defaultValue) [defaultValue] = options
    defaultValue = defaultValue.value

    return (
      <>
        <div className={css.espListContainer}>
          <PageTitle {...titleProps} />
          <Dropdown
            fullWidth
            options={options}
            onChange={onSelectESPList}
            defaultValue={defaultValue}
            disabled={!ESPListOptions}
          />
        </div>
        <InformationalSection padding />
      </>
    )
  }

  const onOpenEspModal = () => history.push(`${history.location.pathname}?${QUERY_PROP_NAME}=true`)

  return (
    <div className={css.integrationButton}>
      <div>
        <InformationalSection text="You can connect one ESP per signup form. Select an ESP from our list of available providers." />
      </div>
      <Button label="Connect" color="secondary" size="small" onClick={onOpenEspModal} />
    </div>
  )
}

export default React.memo(ESPList)

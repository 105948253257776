import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import Text from 'components/texts/Text'
import Slider from 'components/virtual/selectors/Slider'

import ImageDropPreview from 'components/virtual/utility/ImageDropPreview'

const useStyles = makeStyles({
  logo: {
    '& > :first-child': {
      // first text
      marginBottom: 32,
    },
    '& > :nth-child(3)': {
      // last text
      marginBottom: 20,
    },
    '& > :last-child': {
      // slider
      maxWidth: 'calc(100% - 8px)',
    },
  },
})

function LightboxBuilderThemeLogo() {
  const css = useStyles()
  const { lightboxSettings } = React.useContext(BuilderContainerContext)
  const settings = lightboxSettings.getSettings()

  const onLogoImageChange = ({ imageUrl = null, imageName = null }) => {
    settings.brandLogo = { fileName: imageName, url: imageUrl }
  }
  const onLogoImageDelete = () => onLogoImageChange({})

  const onLogoSizeChange = size => {
    settings.brandLogo = { size }
  }

  return (
    <div className={css.logo}>
      <Text size={16}>Brand Logo (Optional)</Text>
      <ImageDropPreview
        onDelete={onLogoImageDelete}
        onChange={onLogoImageChange}
        imageUrl={settings.brandLogo.url}
        imageName={settings.brandLogo.fileName}
      />
      {settings.brandLogo.url && (
        <>
          <Text size={16}>Logo Size</Text>
          <Slider onChange={onLogoSizeChange} value={settings.brandLogo.size} />
        </>
      )}
    </div>
  )
}

export default React.memo(LightboxBuilderThemeLogo)

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { Link } from 'react-router-dom'

import opportunitiesIcon from 'images/icons/search-icons/opportunities@2x.png'
import sweepstakesIcon from 'images/icons/search-icons/sweepstakes@2x.png'

import history from 'utils/getHistory'

import { P, H4, Small } from 'components/texts'
import SearchResultsWrapper from './SearchResultsWrapper'

const useStyles = makeStyles(() => ({
  searchSection: {
    width: '100%',
    '& > *:not(:last-child) > div': {
      marginBottom: 16,
    },
  },
  searchItemWrapper: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    minHeight: 36,
  },
  searchItemLogo: {
    height: 36,
    width: 36,
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    marginRight: 16,
  },
  hostedByWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    minHeight: '100%',
    '& p:first-of-type': {
      marginBottom: 8,
    },
  },
}))

function SearchResults(props) {
  const css = useStyles()
  const { suggestions } = props

  if (!suggestions.length) return null

  const [campaignSuggestions, brandSuggestions, opportunitySuggestions] = suggestions

  const renderBrands = brands => (
    <div className={css.searchSection}>
      {brands.map(brand => (
        <Link to={`/explore/brands/${brand.id}`} key={`brand_${brand.id}`}>
          <div className={css.searchItemWrapper}>
            <div
              className={css.searchItemLogo}
              style={{ backgroundImage: `url("${brand.logo}")`, borderRadius: '50%' }}
            />
            <H4 ink>
              <strong>{brand.accountname}</strong>
            </H4>
          </div>
        </Link>
      ))}
    </div>
  )

  const renderCampaigns = items => {
    const onItemClick = url => e => {
      if (e.target.nodeName.toLowerCase() === 'a') return e.stopPropagation()

      return history.push(url)
    }

    const backgroundImage = `url("/${sweepstakesIcon}")`

    return (
      <div className={css.searchSection}>
        {items.map(item => (
          <div
            key={`campaign_${item.id}`}
            onClick={onItemClick(`/explore/campaigns/${item.id}/details`)}
            className={css.searchItemWrapper}
          >
            <div className={css.searchItemLogo} style={{ backgroundImage }} />
            <div className={css.hostedByWrapper}>
              <P ink>
                <em>{item.name}</em>
              </P>
              <Small>
                Hosted by
                {' '}
                <Link to={`/explore/brands/${item.hostBrandId}`}>{item.hostBrandName}</Link>
              </Small>
            </div>
          </div>
        ))}
      </div>
    )
  }

  const renderOpportunities = items => {
    const onItemClick = url => e => {
      if (e.target.nodeName.toLowerCase() === 'a') return e.stopPropagation()

      return history.push(url)
    }

    const backgroundImage = `url(/"${opportunitiesIcon}")`

    return (
      <div className={css.searchSection}>
        {items.map(item => (
          <div
            key={`opportunity_${item.type}_${item.id}`}
            onClick={onItemClick(`/explore/opportunities/${item.type}/${item.id.split('__')[1]}`)}
            className={css.searchItemWrapper}
          >
            <div className={css.searchItemLogo} style={{ backgroundImage }} />
            <div className={css.hostedByWrapper}>
              <P ink>
                <em>{item.headline}</em>
              </P>
              <Small>
                Posted by
                {' '}
                <Link to={`/explore/brands/${item.brand_id}`}>{item.brandName}</Link>
              </Small>
            </div>
          </div>
        ))}
      </div>
    )
  }

  const suggestionOptions = [
    {
      title: 'Sweepstakes',
      source: campaignSuggestions,
      render: () => renderCampaigns(suggestions[0]),
    },
    {
      title: 'Brands',
      source: brandSuggestions,
      render: () => renderBrands(suggestions[1]),
    },
    {
      title: 'Opportunities',
      source: opportunitySuggestions,
      render: () => renderOpportunities(suggestions[2]),
    },
  ]

  return (
    <>
      {suggestionOptions.map(option =>
        (option.source.length ? (
          <SearchResultsWrapper title={option.title} loading={false}>
            {option.render()}
          </SearchResultsWrapper>
        ) : null))}
    </>
  )
}

SearchResults.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.object),
}

SearchResults.defaultProps = {
  suggestions: [],
}

export default React.memo(SearchResults)

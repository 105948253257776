import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

// import Button from 'components/virtual/buttons/Button'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import ESPFieldValuesTitle from './ESPFieldValuesTitle'
import ESPFieldValuesMergeTags from './ESPFieldValuesMergeTags'

const useStyles = makeStyles(theme => ({
  espFieldContainer: {
    marginTop: 12,
    border: `1px solid ${theme.colors.skyBase}`,
    borderRadius: 4,
  },
  /* TODO: Confirm with product about this button */
  refreshButton: {
    padding: '16px 20px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  refreshIcon: {
    animation: ({ loading }) => (loading ? '$rotateIcon 1s linear infinite' : 'unset'),
  },
  '@keyframes rotateIcon': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
}))

function ESPFieldValues() {
  const { values } = React.useContext(BuilderContainerContext)
  const { ESP } = values
  const css = useStyles()

  if (!ESP) return null

  // TODO: Remove when confirmed
  // const refreshingIcon = (
  //   <svg
  //     className={css.refreshIcon}
  //     width="14"
  //     height="19"
  //     viewBox="0 0 14 19"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <path
  //       d="M7.00016 3V0.5L3.66683 3.83333L7.00016
  //          7.16667V4.66667C9.7585 4.66667 12.0002 6.90833 12.0002
  //          9.66667C12.0002 10.5083 11.7918 11.3083 11.4168 12L12.6335 13.2167C13.2835
  //          12.1917 13.6668 10.975 13.6668 9.66667C13.6668 5.98333 10.6835 3 7.00016
  //          3ZM7.00016 14.6667C4.24183 14.6667 2.00016 12.425 2.00016
  //          9.66667C2.00016 8.825 2.2085 8.025 2.5835 7.33333L1.36683
  //          6.11667C0.716829 7.14167 0.333496 8.35833 0.333496 9.66667C0.333496 13.35
  //          3.31683 16.3333 7.00016 16.3333V18.8333L10.3335 15.5L7.00016 12.1667V14.6667Z"
  //       fill="#004F87"
  //     />
  //   </svg>
  // )

  const refreshButton = (
    <div className={css.refreshButton}>
      {/*<Button
        label="Refresh Connection"
        type="outlined"
        color="secondary"
        size="smaller"
        onClick={() => {}}
        icon={refreshingIcon}
      /> */}
    </div>
  )

  return (
    <div className={css.espFieldContainer}>
      <ESPFieldValuesTitle />
      <ESPFieldValuesMergeTags />
      {refreshButton}
    </div>
  )
}

export default React.memo(ESPFieldValues)

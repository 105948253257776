import React from 'react'
import PropTypes from 'prop-types'
import CheckboxMUI from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles } from '@material-ui/core/styles'

import VirtualTheme, { colors } from 'components/virtual/VirtualTheme'
import Text from 'components/texts/Text'

const MARGIN_LEFT = 40

const useStyles = makeStyles({
  label: { marginLeft: MARGIN_LEFT },
  checkbox: { marginLeft: 4, position: 'absolute' }, // absolute cuz I want ripple to show, could be removed
})

const Checked = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.5"
      y="0.5"
      width="17"
      height="17"
      rx="1.5"
      fill={colors.blueLightest}
      stroke={colors.blueLight}
    />
    <path
      d="M6.75012 12.1274L3.62262 8.99988L2.55762 10.0574L6.75012 14.2499L15.7501 5.24988L14.6926 4.19238L6.75012 12.1274Z"
      fill={colors.blueLight}
    />
  </svg>
)

const Unchecked = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="17" height="17" rx="1.5" fill="white" stroke={colors.skyBase} />
  </svg>
)

function Checkbox(props) {
  const { checked, onChange, label } = props

  const css = useStyles()

  const onCheck = () => onChange(!checked)

  return (
    <VirtualTheme>
      <FormControlLabel
        classes={{ label: css.label }}
        label={label && <Text size={12}>{label}</Text>}
        control={(
          <CheckboxMUI
            classes={{ root: css.checkbox }}
            checkedIcon={<Checked />}
            icon={<Unchecked />}
            checked={checked}
            onChange={onCheck}
          />
        )}
      />
    </VirtualTheme>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
}

Checkbox.defaultProps = {
  label: '',
}

export default React.memo(Checkbox)

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Divider } from '@material-ui/core'
import cn from 'classnames'

import VirtualTheme from 'components/virtual/VirtualTheme'
import VirtualButton from 'components/virtual/buttons/Button'
import OldButton from './Button'

const useStyles = makeStyles({
  container: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    backgroundColor: 'white',
    width: '100%',
    backfaceVisibility: 'hidden',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 16,
  },
  buttonsNoPadding: {
    padding: 0,
  },
  static: {
    position: 'fixed',
  },
  small: {
    padding: 12,
  },
  relative: {
    position: 'static',
  },
  reverse: {
    flexDirection: 'row-reverse',
  },
})

function ButtonGroup(props) {
  const {
    theme,
    onCancel,
    cancelLabel,
    onSubmit,
    canSubmit,
    submitLabel,
    types,
    small,
    fixed,
    relative,
    reverse,
    border,
    submitId,
    submitIcon,
    cancelIcon,
    morePad,
    virtual,
  } = props

  const css = useStyles()

  const [type1, type2] = types.split('&')

  const Wrapper = virtual ? VirtualTheme : React.Fragment
  const Button = virtual ? VirtualButton : OldButton

  const containerClasses = cn(css.container, { [css.static]: fixed, [css.relative]: relative })
  const buttonsClasses = cn(css.buttons, {
    [css.small]: small,
    [css.reverse]: reverse,
    [css.buttonsNoPadding]: relative,
  })

  return (
    <Wrapper>
      <div className={containerClasses}>
        {border && <Divider />}
        <div className={buttonsClasses}>
          {onCancel && (
            <>
              <Button
                morePad={morePad}
                reverseIcon
                icon={cancelIcon}
                small={small}
                type={type1}
                label={cancelLabel}
                onClick={onCancel}
                themeColor={theme}
              />
              <div style={{ marginRight: '16px' }} />
            </>
          )}
          {onSubmit && (
            <Button
              morePad={morePad}
              reverseIcon
              icon={submitIcon}
              disabled={!canSubmit}
              small={small}
              type={type2}
              label={submitLabel}
              onClick={onSubmit}
              themeColor={theme}
              id={submitId}
            />
          )}
        </div>
      </div>
    </Wrapper>
  )
}

ButtonGroup.defaultProps = {
  theme: 'primary',
  border: false,
  morePad: false,
  onCancel() {},
  onSubmit() {},
  cancelLabel: 'Cancel',
  submitLabel: 'Submit',
  types: 'text&contained',
  submitIcon: '',
  cancelIcon: '',
  canSubmit: true,
  small: false,
  relative: false,
  reverse: false,
  submitId: '',
  fixed: false,
  virtual: false,
}

ButtonGroup.propTypes = {
  theme: PropTypes.oneOf(['primary', 'secondary']),
  onCancel: PropTypes.func,
  cancelLabel: PropTypes.string,
  onSubmit: PropTypes.func,
  submitLabel: PropTypes.string,
  submitIcon: PropTypes.string,
  cancelIcon: PropTypes.string,
  types: PropTypes.string,
  fixed: PropTypes.bool,
  morePad: PropTypes.bool,
  canSubmit: PropTypes.bool,
  small: PropTypes.bool,
  relative: PropTypes.bool,
  reverse: PropTypes.bool,
  border: PropTypes.bool,
  virtual: PropTypes.bool,
  submitId: PropTypes.string,
}

export default React.memo(ButtonGroup)

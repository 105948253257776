import React, {
  useState, useCallback, useEffect, useRef,
} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import { withStyles } from '@material-ui/core'

import { P, H6, Tiny } from 'components/texts'
import clockIcon from 'images/icons/clock-icon.svg'
// This is more intended to be used in Mobile

const styles = theme => ({
  dateWrapper: {
    position: 'relative',
    height: 40,
    backgroundColor: 'white',
    margin: '8px 0 0 0',
    border: `1px solid ${theme.colors.silver}`,
    padding: '13px 12px 12px 12px',
    display: 'flex',
    alignItems: 'flex-end',
  },
  dateInput: {
    position: 'absolute',
    top: 0,
    opacity: 0,
    width: 'calc(100% - 12px)',
    height: '100%',
    '&::-webkit-calendar-picker-indicator': {
      background: 'transparent',
      bottom: 0,
      color: 'transparent',
      cursor: 'pointer',
      height: 'auto',
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      width: 'auto',
    },
  },
  datePickerWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  clearDatesWrapper: {
    marginLeft: 12,
    padding: '0 8px',
    display: 'flex',
    alignItems: 'center',
  },
  labels: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline',
    width: '100%',
  },
  sublabel: {
    paddingTop: 4,
  },
  clockIcon: {
    marginRight: 8,
  },
})

function DateInput({
  classes, onChange, value, label, sublabel, placeholder,
}) {
  const dateRef = useRef(null)
  const onSetDate = useCallback(e => onChange(e.target.value), [onChange])

  return (
    <div className={classes.dateWrapper}>
      {(label || sublabel) && (
        <div className={classes.labels}>
          {!label ? null : <H6>{label}</H6>}
          {!sublabel ? null : <Tiny classes={{ text: classes.sublabel }}>{sublabel}</Tiny>}
        </div>
      )}
      <img className={classes.clockIcon} src={clockIcon} alt="clock" />
      {value ? (
        <P>
          <span ref={dateRef}>{moment(value).format('MMM D, YYYY')}</span>
        </P>
      ) : (
        <P darkGrey>
          <i ref={dateRef}>{placeholder}</i>
        </P>
      )}
      <input
        className={classes.dateInput}
        type="date"
        onChange={onSetDate}
        value={value ? moment(value).format('YYYY-MM-DD') : ''}
      />
    </div>
  )
}

DateInput.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  label: PropTypes.string,
  sublabel: PropTypes.string,
  placeholder: PropTypes.string,
}

DateInput.defaultProps = {
  value: '',
  label: '',
  sublabel: '',
  placeholder: '',
}

const StyledDateInput = withStyles(styles)(DateInput)

function DatePickerWrapper({
  onClose, onChange, min, max, classes, startDateValue, endDateValue,
}) {
  const [date1, setDate1] = useState(startDateValue || null)
  const [date2, setDate2] = useState(endDateValue || null)

  const onSetDate1 = useCallback(val => setDate1(moment(val || min).toDate()), [setDate1, min])
  const onSetDate2 = useCallback(val => setDate2(moment(val || max).toDate()), [setDate2, max])
  const onClearDates = () => {
    setDate1(null)
    setDate2(null)
    onClose()
  }

  useEffect(() => {
    if (date1 && date2 && date1 > date2) onSetDate2(moment(date1).add(1, 'day'))
    else if (date2 && date1 && date2 < date1) onSetDate1(moment(date2).subtract(1, 'day'))

    if (date1 && min && date1 < min) onSetDate1(min)
    if (date1 && max && date1 > max) onSetDate1(moment(max).subtract(1, 'day'))
    if (date2 && max && date2 > max) onSetDate2(max)
    if (date2 && max && date2 < min) onSetDate2(moment(min).add(1, 'day'))
  }, [date1, date2, max, min, onSetDate1, onSetDate2])

  useEffect(() => {
    if (date1 && date2) onChange([date1, date2])
  }, [date1, date2, onChange])

  return (
    <>
      <div className={classes.datePickerWrapper}>
        <StyledDateInput
          onChange={onSetDate1}
          value={date1}
          min={min}
          max={max}
          placeholder="Start Date"
        />
        <P multiline azure>
          <strong>&nbsp;-&nbsp;</strong>
        </P>
        <StyledDateInput
          onChange={onSetDate2}
          value={date2}
          min={date1}
          max={max}
          placeholder="End Date"
        />
        {date1 || date2 ? (
          <div className={classes.clearDatesWrapper} onClick={onClearDates}>
            <P>
              <strong>Clear</strong>
            </P>
          </div>
        ) : null}
      </div>
    </>
  )
}

DatePickerWrapper.propTypes = {
  onChange: PropTypes.func,
  min: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  max: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  startDateValue: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  endDateValue: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
}

DatePickerWrapper.defaultProps = {
  onChange() {},
  onClose() {},
  startDateValue: null,
  endDateValue: null,
  min: null,
  max: null,
}

const StyledDatePickerWrapper = withStyles(styles)(DatePickerWrapper)

function DatePickerBase({
  classes,
  onChange,
  startDate,
  endDate,
  min,
  max,
  label,
  sublabel,
  placeholder,
  isShowing,
}) {
  const [show, setShow] = useState(isShowing)

  const onOpen = useCallback(() => setShow(true), [])
  const onClose = useCallback(() => setShow(false), [])

  const onDatesChange = useCallback(
    dates => {
      onChange(dates)
    },
    [onChange]
  )

  return (
    <div className={classes.wrapper}>
      {(label || sublabel) && (
        <div className={classes.labels}>
          {!label ? null : <H6>{label}</H6>}
          {!sublabel ? null : <Tiny classes={{ text: classes.sublabel }}>{sublabel}</Tiny>}
        </div>
      )}
      {show ? (
        <StyledDatePickerWrapper
          onChange={onDatesChange}
          onClose={onClose}
          startDateValue={startDate ? moment(startDate) : null}
          endDateValue={endDate ? moment(endDate) : null}
          min={min ? moment(min) : null}
          max={max ? moment(max) : null}
        />
      ) : (
        <div onClick={onOpen}>
          <P multiline azure>
            <strong>{placeholder}</strong>
          </P>
        </div>
      )}
    </div>
  )
}

DatePickerBase.propTypes = {
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  isShowing: PropTypes.bool,
  startDate: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  endDate: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  min: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  max: PropTypes.oneOfType([
    PropTypes.instanceOf(moment),
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  label: PropTypes.string,
  sublabel: PropTypes.string,
  placeholder: PropTypes.string,
}

DatePickerBase.defaultProps = {
  onChange() {},
  startDate: null,
  endDate: null,
  min: null,
  max: null,
  label: '',
  sublabel: '',
  placeholder: '',
  isShowing: false,
}

export const DatePicker = React.memo(StyledDateInput)
export default React.memo(withStyles(styles)(DatePickerBase))

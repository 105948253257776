import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Input from 'components/virtual/inputs/Input'
import useInputDebounce from 'hooks/useInputDebounce'
import VirtualTheme from 'components/virtual/VirtualTheme'
import { BuilderContainerContext } from '../BuilderContainerContext'

const useStyles = makeStyles({
  field: {
    marginTop: 24,
    marginBottom: 20,
  },
})

function BuilderField(props) {
  const {
    name, label, value, onChange, tooltip, placeholder, helperText, isPlaceholder,
  } = props
  const css = useStyles()

  const {
    builder: { errors },
  } = React.useContext(BuilderContainerContext)

  const fieldName = isPlaceholder ? `placeholder_${name}` : name
  const error = errors.verify([fieldName, `cantExceed_${fieldName}`, `repeatedValue_${fieldName}`])

  const [inputValue, onInputChange] = useInputDebounce(value, onChange)

  return (
    <VirtualTheme>
      <div className={css.field}>
        <Input
          value={inputValue}
          onChange={onInputChange}
          fullWidth
          tooltip={tooltip}
          label={label}
          placeholder={placeholder}
          helperText={helperText}
          error={error}
        />
      </div>
    </VirtualTheme>
  )
}

BuilderField.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isPlaceholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.string,
  tooltip: PropTypes.object,
  name: PropTypes.string.isRequired,
}

BuilderField.defaultProps = {
  tooltip: null,
  helperText: '',
}

export default React.memo(BuilderField)

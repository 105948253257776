import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, IconButton, InputAdornment } from '@material-ui/core'

import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import Input from './Input'
import PasswordHelper from './PasswordHelper'

const useStyles = makeStyles(() => ({
  passwordHelper: {
    marginTop: 12,
  },
}))

function PasswordInput(props) {
  const {
    compact,
    disabled,
    error,
    fullWidth,
    helperText,
    multiline,
    onChange,
    passwordHelper,
    placeholder,
    showToggle,
    value,
    ...rest
  } = props

  const classes = useStyles()

  const [showPassword, setShowPassword] = useState(false)

  const onToggle = event => {
    event.preventDefault()
    setShowPassword(prevValue => !prevValue)
  }

  const toggleButton = (
    <InputAdornment position="end">
      <IconButton aria-label="Toggle Password Visibility" onClick={onToggle} tabindex="-1">
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  )

  return (
    <div>
      <Input
        {...rest}
        compact={compact}
        disabled={disabled}
        endAdornment={showToggle ? toggleButton : undefined}
        error={error}
        fullWidth={fullWidth}
        helperText={helperText}
        multiline={multiline}
        onChange={onChange}
        placeholder={placeholder}
        type={showPassword ? 'text' : 'password'}
        value={value}
      />
      {passwordHelper && (
        <div className={classes.passwordHelper}>
          <PasswordHelper value={value} />
        </div>
      )}
    </div>
  )
}

PasswordInput.propTypes = {
  compact: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  passwordHelper: PropTypes.bool,
  placeholder: PropTypes.string,
  showToggle: PropTypes.bool,
  value: PropTypes.string,
}

PasswordInput.defaultProps = {
  compact: false,
  disabled: false,
  error: undefined,
  fullWidth: false,
  helperText: '',
  multiline: false,
  onChange: () => {},
  passwordHelper: false,
  placeholder: '',
  showToggle: false,
  value: '',
}

export default React.memo(PasswordInput)

import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useForm, useFormState } from 'react-final-form'

import { ButtonGroup } from 'components/buttons'

import { getClaim } from 'actions/brandClaims'

const useStyles = makeStyles({
  buttonGroup: {
    padding: '14px 20px',
  },
})

const CreateLightboxButtonGroup = () => {
  const css = useStyles()

  const [allowed, setAllowed] = useState(true)
  const { values } = useFormState()
  const form = useForm()
  const {
    isValid,
    isReview,
    isBuilder,
    // SUBMIT_ID, // TODO: Enable when I figure out why this is not working?
    haveEnoughCredits,
    autorefill,
    actions,
  } = values

  const { triggerCouponValidation, onClose, goToEdit } = actions

  const onSubmitClick = () => {
    if (isValid) form.submit()
    else {
      setAllowed(false)
      triggerCouponValidation()
    }
  }

  const isInactive = form.getState().values.message.status !== 'active'

  const canCreateSmsLightbox = getClaim('sms.lightbox.create')
  const isDisabled = !(autorefill || haveEnoughCredits || canCreateSmsLightbox)
  const canSubmit = (allowed || isValid) && !isDisabled

  let buttonActions = {
    submitLabel: isBuilder ? 'Save' : 'Review',
    cancelLabel: 'Cancel',
    onCancel: onClose,
  }

  if (isReview) {
    buttonActions = {
      submitLabel: 'Update',
      cancelLabel: 'Go Back',
      onCancel: goToEdit,
    }

    if (isBuilder && isInactive) buttonActions.submitLabel = 'Done'
  }

  return (
    <div className={css.buttonGroup}>
      <ButtonGroup
        border
        morePad
        canSubmit={canSubmit}
        onSubmit={onSubmitClick}
        {...buttonActions}
      />
    </div>
  )
}

export default React.memo(CreateLightboxButtonGroup)

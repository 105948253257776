export default (partners, id, data) => {
  if (id) {
    if (partners[id]) {
      partners[id] = { ...partners[id], ...data }
    } else {
      partners[id] = {
        partner_id: '',
        partnership_invite_id: '',
        partnership_agreement_type: '',
        custom_file_name: '',
        accountname: '',
        logo: '',
        entries: 0,
        visits: 0,
        impressions: 0,
        newEmails: 0,
        postEntries: 0,
        conversions: 0,
        minimumDelivery: 0,
        ...data,
      }
    }
  }
}

import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { makeStyles } from '@material-ui/core/styles'
import { Menu, MenuItem } from '@material-ui/core'

import Text from 'components/texts/Text'
import MessageBadge from 'components/virtual/badges/MessageBadge'

const useStyles = makeStyles(theme => ({
  badge: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  icon: {
    display: 'flex',
    height: 40,
    width: 40,
    marginRight: 12,
    position: 'relative',
  },
  item: {
    alignItems: 'center',
    display: 'flex',
    height: 72,
    position: 'relative',
    '&:hover': { backgroundColor: theme.colors.skyLight },
  },
  locked: {
    backgroundColor: theme.colors.skyBase,
  },
  menu: {
    borderRadius: 4,
    padding: '12px 0',
    marginTop: 8,
    width: 366,
    maxHeight: 300,
  },
}))

function CreateNewMenu(props, ref) {
  const { options, open, ...rest } = props

  const classes = useStyles()

  return (
    <Menu
      anchorEl={ref.current}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      classes={{ paper: classes.menu }}
      getContentAnchorEl={null}
      open={open}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      {...rest}
    >
      {options.map(({
        icon, label, description, onClick, to, locked,
      }) => (
        <Link key={label} to={to}>
          <MenuItem
            classes={{ root: cn(classes.item, { [classes.locked]: locked }) }}
            onClick={onClick}
          >
            {icon && <div className={classes.icon}>{icon}</div>}
            <div>
              {label && (
              <Text charcoalBase size={14}>
                {label}
              </Text>
              )}
              {description && (
              <Text charcoalLight size={12}>
                {description}
              </Text>
              )}
              {locked && (
              <div className={classes.badge}>
                <MessageBadge type="upgrade" />
              </div>
              )}
            </div>
          </MenuItem>
        </Link>
      ))}
    </Menu>
  )
}

CreateNewMenu.propTypes = {
  anchorEl: PropTypes.any.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.bool.isRequired,
}

export default React.memo(React.forwardRef(CreateNewMenu))

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import SliderUI from '@material-ui/core/Slider'

import SliderTooltip from './SliderTooltip'

const StyledStyles = withStyles(theme => ({
  track: {
    height: 6,
    borderRadius: 10,
    backgroundColor: theme.colors.blueBase,
  },
  rail: {
    height: 6,
    backgroundColor: theme.colors.skyBase,
  },
  thumb: {
    marginTop: -5,
    marginLeft: -5,
    height: 16,
    width: 16,
    backgroundColor: 'white',
    border: `3px solid ${theme.colors.blueBase}`,
  },
}))(SliderUI)

function Slider(props) {
  const {
    min, max, value: defaultValue, onChange,
  } = props

  const timeoutRef = React.useRef(null)
  const [value, setValue] = useState(defaultValue || (max - min) / 2)

  const onValueChange = (e, v) => {
    clearTimeout(timeoutRef.current)
    setValue(v)
    timeoutRef.current = setTimeout(() => onChange(v), 50)
  }

  return (
    <StyledStyles
      min={min}
      max={max}
      value={value}
      onChange={onValueChange}
      ValueLabelComponent={SliderTooltip}
    />
  )
}

Slider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
}

Slider.defaultProps = {
  min: 0,
  max: 100,
}

export default React.memo(Slider)

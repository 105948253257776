import React from 'react'
import PropTypes from 'prop-types'

import { P } from 'components/texts'
import ToolTipWrapper from 'components/shared/ToolTipWrapper'

import { SMSEditorContext } from 'contexts/SMSEditorContext'

import SMSEditorGenericModal from './SMSEditorGenericModal'

function LinkDetectorModal(props) {
  const {
    actions: { onLinkMinifyAndInsert },
  } = React.useContext(SMSEditorContext)

  const {
    show, onClose, originalLink, placement, link,
  } = props

  const onConfirm = () => onLinkMinifyAndInsert(link, onClose)

  if (originalLink) {
    return (
      <SMSEditorGenericModal
        show={show}
        placement={placement}
        content={(
          <a href={originalLink} target="_blank" rel="noreferrer noopener">
            <P ellipsis azure>
              <ToolTipWrapper isVirtualTheme text={originalLink} placement="bottom-start">
                <strong>{originalLink}</strong>
              </ToolTipWrapper>
            </P>
          </a>
        )}
        title="Original Link"
      />
    )
  }

  return (
    <SMSEditorGenericModal
      show={show}
      placement={placement}
      title="Shorten your link and track it?"
      content="Minimize the number of characters and help us to track your conversions."
      confirmLabel="Shorten It"
      cancelLabel="Dismiss"
      onConfirm={onConfirm}
      onCancel={onClose}
    />
  )
}

LinkDetectorModal.propTypes = {
  show: PropTypes.bool.isRequired,
  originalLink: PropTypes.string,
  link: PropTypes.string.isRequired,
  placement: PropTypes.object,
  onClose: PropTypes.func.isRequired,
}

LinkDetectorModal.defaultProps = {
  originalLink: null,
  placement: null,
}

export default React.memo(LinkDetectorModal)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

import Text from 'components/texts/Text'
import LightboxBuilderPageContentField from './LightboxBuilderPageContentField'

const useStyles = makeStyles({
  titles: {
    '& > :first-child': {
      marginBottom: 4,
    },
  },
})
function LightboxBuilderPageContentEnableFields() {
  const {
    lightboxSettings,
    builder: { drawer },
  } = React.useContext(BuilderContainerContext)
  const {
    data: { index },
  } = drawer
  const css = useStyles()

  const settings = lightboxSettings.getSettings()
  const page = settings.pages[index]
  const isLastPage = index === settings.pages.length - 1

  const titleBlocks = [
    { label: 'Title Block', enabled: page.title.isViewable, type: 'title' },
    { label: 'Subtitle Block', enabled: page.subtitle.isViewable, type: 'subtitle' },
  ]

  if (index > 0 && !isLastPage) {
    titleBlocks.push({
      label: 'Secondary Button',
      enabled: page.secondaryButton.isViewable,
      type: 'secondaryButton',
    })
  }

  const onClick = whichTitle => {
    const isViewable = !page[whichTitle].isViewable
    page[whichTitle] = { isViewable }
  }

  return (
    <div>
      <div className={css.titles}>
        <Text charcoalBase size={16}>
          Content Blocks
        </Text>
        <Text charcoalLight>Manage the visibility of your content blocks.</Text>
      </div>
      {titleBlocks.map(title => (
        <LightboxBuilderPageContentField
          key={title.label}
          label={title.label}
          enabled={title.enabled}
          onClick={() => onClick(title.type)}
        />
      ))}
    </div>
  )
}

export default React.memo(LightboxBuilderPageContentEnableFields)

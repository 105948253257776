import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import cn from 'classnames'

import { ReactComponent as Checkmark } from 'images/checkmark-green-bg.svg'

import { P, Tiny } from 'components/texts'

import { SearchInput } from 'components/inputs'
import { Spinner } from 'components/spinners'
import useImageSearch from 'hooks/useImageSearch'

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: '24px 12px',
  },
  subtext: {
    textAlign: 'right',
    marginTop: 4,
  },
  imagesPreview: {
    display: 'grid',
    gridTemplateColumns: '200px 200px 200px',
    gridAutoRows: '128px',
    gridColumnGap: 4,
    gridRowGap: 4,
    overflowY: 'auto',
    position: 'relative',
    margin: '20px 0 0',
    height: ({ smaller }) => (smaller ? 290 : 344),
  },
  preview: {
    backgroundColor: theme.colors.silver,
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    '& img': {
      width: '100%',
    },
  },
  spinner: props => {
    if (props.all) return null

    return {
      position: 'relative',
      gridColumn: '1/4',
    }
  },
  disclaimer: {
    padding: '12px 0 0',
    backgroundColor: 'white',
  },
  selected: {
    position: 'relative',
    border: `4px solid ${theme.colors.seaGreen}`,
    borderRadius: 2,
  },
  checkmark: {
    position: 'absolute',
    top: 4,
    left: 4,
    height: 16,
    width: 16,
  },
}))

const termsMap = {
  unsplash: {
    label: 'Unsplash',
    url: 'https://unsplash.com/terms',
  },
  pixabay: {
    label: 'Pixabay',
    url: 'https://pixabay.com/service/terms/',
  },
}

const disclaimerText = provider => {
  if (!provider) return null

  return (
    <P multiline>
      By clicking &ldquo;Insert,&rdquo; you are agreeing to
      {' '}
      <a href={termsMap[provider].url} rel="noopener noreferrer">
        {termsMap[provider].label}
        &apos;s Terms
      </a>
      {' '}
      and you understand that you are publishing the image and are responsible for it. This image is
      provided by a third party called
      {' '}
      {termsMap[provider].label}
      ; it is not provided by DojoMojo.
    </P>
  )
}

const POWERED_BY = (
  <Tiny>
    <strong>
      Powered by
      {' '}
      <a href="https://unsplash.com/" target="_blank" rel="noopener noreferrer">
        Unsplash
      </a>
      {' '}
      and
      {' '}
      <a href="https://pixabay.com/" target="_blank" rel="noopener noreferrer">
        Pixabay
      </a>
    </strong>
  </Tiny>
)

function SearchImageSection(props) {
  const { onMediaSelect } = props
  const {
    onScroll, onQueryChange, loading, images, query,
  } = useImageSearch()
  const [selectedImage, setSelectedImage] = useState(null)
  const css = useStyles({ all: !images.length, smaller: selectedImage !== null })

  const onType = value => {
    setSelectedImage(null)
    onQueryChange(value)
  }

  const onSelect = img => {
    const selected = !selectedImage || selectedImage.id !== img.id ? img : null
    setSelectedImage(selected)
    onMediaSelect(selected)
  }

  return (
    <section className={css.wrapper}>
      <div>
        <SearchInput value={query} onChange={onType} placeholder="Search Images" />
        <div className={css.subtext}>{POWERED_BY}</div>
      </div>
      <div onScroll={onScroll} className={css.imagesPreview}>
        {images.length > 0
          && images.map(image => {
            const selected = selectedImage && selectedImage.id === image.id
            if (image.type === 'pixabay') return null
            return (
              <div
                key={image.id}
                className={cn(css.preview, { [css.selected]: selected })}
                onClick={() => onSelect(image)}
              >
                <img loading="lazy" src={image.src} alt="preview" />
                {selected && <Checkmark className={css.checkmark} />}
              </div>
            )
          })}
        {loading && (
          <div className={css.spinner}>
            <Spinner full={false} />
          </div>
        )}
      </div>
      {selectedImage !== null && (
        <div className={css.disclaimer}>{disclaimerText(selectedImage.type)}</div>
      )}
    </section>
  )
}

SearchImageSection.propTypes = {
  onMediaSelect: PropTypes.func.isRequired,
}

export default React.memo(SearchImageSection)

import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { ToasterContext } from 'contexts'

import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'
import { CloseOutlined } from '@material-ui/icons'
import Button from 'components/virtual/buttons/Button'
import Text from 'components/texts/Text'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles(theme => ({
  input: {
    marginTop: 4,
    '& .MuiInputBase-root': {
      border: `1px solid ${theme.colors.skyBase}`,
      borderRadius: 4,
      fontSize: 16,
      fontFamily: 'Larsseit',
      color: theme.colors.charcoalLighter,
      '& input': {
        color: theme.colors.charcoalDarker,
      },
      '& textarea': {
        color: theme.colors.charcoalDarker,
        paddingTop: 12,
      },
    },
    '& .Mui-focused': {
      borderColor: theme.colors.blueBase,
    },
  },
  inputContainer: {
    marginTop: 24,
  },
  buttons: {
    alignSelf: 'flex-end',
    marginTop: 32,
  },
  close: {
    cursor: 'pointer',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0px 32px 32px',
  },
  title: {
    padding: '32px 20px 8px 32px',
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

function SupportModal(props) {
  const { open, setOpenSupport } = props
  const classes = useStyles()
  const { showToaster } = useContext(ToasterContext)

  const useInput = (css, type = 'text') => {
    const [value, setValue] = useState('')
    const placeHolder = type === 'text' ? 'e.g. Mailchimp' : 'Let us know more about your request...'
    const input = (
      <TextField
        placeholder={placeHolder}
        fullWidth
        multiline={type === 'textArea'}
        rows={5}
        value={value}
        classes={{ root: css.input }}
        onChange={e => setValue(e.target.value)}
        required
      />
    )
    return [value, input]
  }

  const [storeName, storeInput] = useInput(classes)
  const [comment, commentInput] = useInput(classes, 'textArea')

  const handleClose = () => {
    setOpenSupport(false)
  }

  const contactSupport = async () => {
    if (!storeName) showToaster('Preferred store is required.', { type: 'warning' })
    else {
      try {
        await axios.all([
          axios.post('/zendesk-contact-support', {
            storeName,
            comment,
            requestType: 'Store Integrations Request',
          }),
          axios.post('/sms/support-integrations', {
            storeName,
            comment,
          }),
        ])
        handleClose()
        showToaster('Thank you for letting us know.', { type: 'success' })
      } catch (err) {
        showToaster('There was an error sending your request.', { type: 'warning' })
      }
    }
  }

  return (
    <Dialog open={open}>
      <div className={classes.title}>
        <Text charcoalDarker size={20}>
          Can&apos;t find your ESP?
        </Text>
        <CloseOutlined className={classes.close} onClick={handleClose} />
      </div>
      <div className={classes.content}>
        <Text charcoalLight size={16}>
          Let us know which ESP you&apos;re using, and we&apos;ll try adding it to the list.
        </Text>
        <div className={classes.inputContainer}>
          <Text charcoalBase size={14} font="medium">
            Preferred ESP
          </Text>
          {storeInput}
        </div>
        <div className={classes.inputContainer}>
          <Text charcoalBase size={14} font="medium">
            Comment
          </Text>
          {commentInput}
        </div>
        <div className={classes.buttons}>
          <Button label="Cancel" onClick={handleClose} size="small" type="text" />
          <Button label="Send" onClick={contactSupport} size="small" />
        </div>
      </div>
    </Dialog>
  )
}

SupportModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpenSupport: PropTypes.func.isRequired,
}

export default React.memo(SupportModal)

import { useState, useEffect, useCallback } from 'react'

const getMobileDetect = userAgent => {
  const isAndroid = () => Boolean(userAgent.match(/Android/i))
  const isIos = () => Boolean(userAgent.match(/iPhone|iPad|iPod/i))
  const isOpera = () => Boolean(userAgent.match(/Opera Mini/i))
  const isWindows = () => Boolean(userAgent.match(/IEMobile/i))

  const isUnder720 = () => window.innerWidth < 720
  const max = n => window.innerWidth < n
  const isOver768 = () => window.innerWidth >= 768

  const isMobile = () => Boolean(isAndroid() || isIos() || isOpera() || isWindows() || isUnder720())
  const isDesktop = () => !isMobile()
  const isOver1023 = () => window.innerWidth >= 1023 && !isMobile()

  return {
    isMobile,
    isDesktop,
    isAndroid,
    isIos,
    isUnder720,
    max,
    isOver768,
    isOver1023,
  }
}

const useMobileDetect = () => {
  const [, setReload] = useState(Date.now())

  const onResize = useCallback(() => setReload(Date.now()), [])

  useEffect(() => {
    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  }, [onResize])

  return getMobileDetect(navigator.userAgent)
}

export default useMobileDetect

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import SearchResults from './SearchResults'
import RelatedIndustryTags from './RelatedIndustryTags'
import AutoComplete from './AutoComplete'

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'absolute',
    width: 'calc(100% - 1px)',
    top: props => props.containerHeight,
    left: 0,
    boxShadow: theme.shadows.slice(-1),
    '& > div': {
      top: 0,
      position: 'relative',
      flexDirection: 'row',
    },
  },
}))

function SearchSuggestions(props) {
  const {
    searchValue, suggestions, onFilterClick, onSuggestionClick, containerHeight,
  } = props
  const css = useStyles({ containerHeight })

  return (
    <div className={css.wrapper}>
      <SearchResults searchValue={searchValue} onSearchClick={onSuggestionClick} />
      <RelatedIndustryTags searchValue={searchValue} onTagClick={onFilterClick} />
      <AutoComplete suggestions={suggestions} />
    </div>
  )
}

SearchSuggestions.propTypes = {
  searchValue: PropTypes.string,
  suggestions: PropTypes.arrayOf(PropTypes.object),
  onFilterClick: PropTypes.func.isRequired,
  onSuggestionClick: PropTypes.func.isRequired,
  containerHeight: PropTypes.number.isRequired,
}

SearchSuggestions.defaultProps = {
  searchValue: '',
  suggestions: [],
}

export default React.memo(SearchSuggestions)

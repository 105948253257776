import React from 'react'
import PropTypes from 'prop-types'
import { Typography, withStyles } from '@material-ui/core'
import styleHelper from 'utils/styleHelper'

const styles = theme => {
  const colors = Object.entries(theme.colors).reduce(
    (obj, [key, val]) => ({ ...obj, [key]: { color: val } }),
    {}
  )

  return {
    text: {
      fontSize: 16,
      [styleHelper.min(768)]: {
        fontSize: 18,
      },
      [styleHelper.min(1280)]: {
        fontSize: 20,
      },
      lineHeight: '20px',
      fontFamily: 'Larsseit-Medium',
      '& small': {
        fontSize: 18,
        lineHeight: '18px',
        '& > i': {
          fontFamily: 'Larsseit',
          fontStyle: 'normal',
        },
        '& > b': {
          fontFamily: 'Larsseit-Bold',
          fontStyle: 'normal',
          fontWeight: 'normal',
        },
      },
      '& i': {
        fontFamily: 'Larsseit',
        fontStyle: 'normal',
      },
      '& strong': {
        fontFamily: 'Larsseit-Bold',
        fontStyle: 'normal',
        fontWeight: 'normal',
      },
    },
    multiline: {
      lineHeight: '28px',
    },
    ellipsis: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    obscure: {
      filter: 'blur(3px)',
      userSelect: 'none',
    },
    ...colors,
  }
}

function H3({ children, classes, ...props }) {
  const classnames = Object.keys(props)
    .map(k => props[k] && classes[k])
    .join(' ')

  return (
    <Typography component="h3" className={`${classes.text} ${classnames}`}>
      {children}
    </Typography>
  )
}

H3.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  classes: PropTypes.object.isRequired,
}

export default React.memo(withStyles(styles)(H3))

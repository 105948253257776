import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { PageSubtitle, PageTitle } from 'components/builder/titles'
import LightboxBuilderInstallation from './LightboxBuilderInstallation'
import LightboxBuilderInstallationKnowledgeBase from './LightboxBuilderInstallationKnowledgeBase'

const useStyles = makeStyles({
  installationSection: {
    height: '100%',
  },
  title: {
    flex: 1,
    '& > :first-child': {
      marginBottom: 12,
    },
  },
  content: {
    marginTop: 32,
    height: '100%',
    display: 'flex',
    gap: '24px',
  },
})

const TITLE = 'Installation'
const SUBTITLE = 'Get your signup form up and running on your website. '

function LightboxBuilderInstallationSection() {
  const css = useStyles()

  return (
    <div className={css.installationSection}>
      <div className={css.title}>
        <PageTitle textProps={{ size: 20 }} text={TITLE} />
        <PageSubtitle text={SUBTITLE} />
      </div>
      <div className={css.content}>
        <LightboxBuilderInstallation />
        <LightboxBuilderInstallationKnowledgeBase />
      </div>
    </div>
  )
}

export default React.memo(LightboxBuilderInstallationSection)

import React from 'react'
import PropTypes from 'prop-types'
import { useDrop } from 'react-dnd'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core/styles'

import Drag from './Drag'

const useStyles = makeStyles({
  dragWrapper: {
    opacity: ({ isOver }) => (isOver ? 0.5 : 1),
  },
})

function SortableDrag(props) {
  const { index, onSort, containerId } = props
  const ref = React.useRef(null)
  const [{ isOver }, drop] = useDrop({
    accept: 'drag',
    collect: monitor => ({ isOver: monitor.isOver() }),
    drop: item => {
      if (containerId === item.containerId) onSort(item.index, index)
    },
  })
  const css = useStyles({ isOver })

  drop(ref)

  return (
    <div className={cn('draggable', css.dragWrapper)}>
      <Drag ref={ref} {...props} />
    </div>
  )
}

SortableDrag.propTypes = {
  onSort: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  containerId: PropTypes.string.isRequired,
}

export default React.memo(SortableDrag)

import IMask from 'imask'

// https://imask.js.org/guide.html#masked-pattern
const masked = new IMask.MaskedPattern({
  mask: '(000) 000-0000',
})

export default function formatPhoneNumber(phone) {
  if (!phone) return '-'
  if (phone.startsWith('1')) phone = phone.slice(1)
  else if (phone.startsWith('+1')) phone = phone.slice(2)
  return masked.resolve(phone)
}

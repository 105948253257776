import React from 'react'

export default function withCloseFn(Component, handleClose, useOnClick = false) {
  return function Wrapped(props) {
    return useOnClick ? (
      <Component {...props} onClick={handleClose} />
    ) : (
      <Component {...props} onClose={handleClose} />
    )
  }
}

//Design: https://www.figma.com/file/Jzn9QRV8FZsCqcZWdMXTYR/Component-Library?node-id=156%3A0

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, TextField } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  inputDisabled: {
    color: `${theme.colors.charcoalLighter} !important`,
    backgroundColor: theme.colors.skyLighter,
    borderColor: theme.colors.skyDarker,
  },
  helper: {
    fontSize: 12,
    marginLeft: 2,
    '&.Mui-focused': {
      color: theme.colors.blueDarker,
    },
    '&.Mui-error': {
      color: theme.colors.redDarkest,
    },
  },
  input: {
    fontFamily: 'Larsseit',
    fontSize: 16,
    lineHeight: '24px',
    height: 24,
    padding: 12,
  },
  inputAdornment: {
    padding: 0,
  },
  inputError: {
    '&.Mui-error.Mui-error > fieldset': {
      borderColor: theme.colors.redBase,
      '& > legend': {
        padding: ({ compact }) => (compact ? '0px 12px 0px 0px' : 0),
      },
    },
  },
  label: {
    fontFamily: 'Larsseit',
    fontSize: 16,
    transform: 'translate(14px, 17px) scale(1)',
  },
  labelError: {
    '&[class*=MuiInputLabel-shrink]': {
      '&:after': {
        backgroundColor: theme.colors.redLight,
        borderRadius: '50%',
        content: "''",
        display: 'auto',
        height: 8,
        marginLeft: 8,
        position: 'absolute',
        top: 4,
        width: 8,
      },
    },
  },
  labelShrink: {
    color: theme.colors.charcoalBase,
    '&.Mui-focused': {
      color: theme.colors.charcoalBase,
    },
    '&.Mui-error': {
      color: theme.colors.charcoalBase,
    },
  },
  multiline: {
    height: 'auto',
    padding: 0,
  },
}))

function InputBase(props) {
  const {
    compact,
    disabled,
    endAdornment,
    error,
    fullWidth,
    helperText,
    multiline,
    placeholder,
    startAdornment,
    type,
    value,
    ...rest
  } = props

  const classes = useStyles({ compact })

  return (
    <>
      <TextField
        {...rest}
        classes={{ root: classes.root }}
        disabled={disabled}
        error={!!error}
        FormHelperTextProps={{
          classes: {
            root: classes.helper,
          },
        }}
        fullWidth={fullWidth}
        helperText={error || helperText}
        InputLabelProps={{
          shrink: error ? true : undefined,
          classes: {
            error: classes.labelError,
            root: classes.label,
            shrink: classes.labelShrink,
          },
        }}
        InputProps={{
          endAdornment,
          startAdornment,
          classes: {
            adornedEnd: classes.inputAdornment,
            adornedStart: classes.inputAdornment,
            error: classes.inputError,
            input: classes.input,
            inputMultiline: classes.multiline,
            disabled: classes.inputDisabled,
          },
        }}
        multiline={multiline}
        placeholder={placeholder}
        type={type}
        value={value}
        variant="outlined"
      />
    </>
  )
}

InputBase.propTypes = {
  compact: PropTypes.bool,
  disabled: PropTypes.bool,
  endAdornment: PropTypes.string,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  multiline: PropTypes.bool,
  placeholder: PropTypes.string,
  startAdornment: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
}

InputBase.defaultProps = {
  compact: false,
  disabled: false,
  endAdornment: null,
  error: '',
  fullWidth: false,
  helperText: '',
  multiline: false,
  placeholder: '',
  startAdornment: null,
  type: '',
  value: '',
}

export default React.memo(InputBase)

const initState = {
  conversations: {
    meta: { count: 0, outOfMessages: true },
  },
  // TODO: bad legacy naming convention, this is actually unread messages
  unreadNotifications: [],
  notifications: { unreadCount: 0, items: [] },

  conversationsLoading: true,
  // TODO: bad legacy naming convention, this is actually unread messages
  unreadNotificationsLoading: true,
  notificationsLoading: true,
}

export default (state = initState, action) => {
  const { type, payload = {} } = action

  switch (type) {
    // Loaders
    case 'CONVERSATIONS_LOADING':
      return { ...state, conversationsLoading: true }
    case 'UNREAD_CONVERSATIONS_LOADING': {
      return { ...state, unreadNotificationsLoading: true }
    }
    case 'NOTIFICATIONS_LOADING': {
      return { ...state, notificationsLoading: true }
    }

    // Rest of actions
    case 'CONVERSATIONS_FAILED':
    case 'CONVERSATIONS_LOAD': {
      return { ...state, conversations: { ...payload }, conversationsLoading: false }
    }

    case 'UNREAD_CONVERSATIONS_FAILED':
    case 'UNREAD_CONVERSATIONS_LOAD': {
      return { ...state, unreadNotifications: [...payload], unreadNotificationsLoading: false }
    }

    case 'ADD_MESSAGE': {
      const { conversations } = state
      let { messages } = conversations

      messages = payload.messages || { ...messages, [payload.id]: payload }

      return {
        ...state,
        conversations: {
          ...conversations,
          messages: { ...messages },
        },
      }
    }

    case 'NOTIFICATIONS_FAILED':
    case 'NOTIFICATIONS_LOADED': {
      return { ...state, notifications: payload, notificationsLoading: false }
    }

    case 'READ_NOTIFICATION': {
      let { unreadCount } = state.notifications
      const items = state.notifications.items.map(item => {
        if (item.id === payload && !item.read_at) {
          unreadCount--
          return { ...item, read_at: new Date() }
        }
        return item
      })
      return { ...state, notifications: { ...state.notifications, items, unreadCount } }
    }

    case 'READ_ALL': {
      const { messages: oldMessages } = state.conversations
      const messages = Object.keys(oldMessages).reduce((object, key) => {
        object[key] = { ...oldMessages[key], last_read: new Date() }
        return object
      }, {})
      const items = state.notifications.items.map(item => ({ ...item, read_at: new Date() }))
      return {
        ...state,
        conversations: { messages, unread: 0 },
        notifications: { items, unreadCount: 0 },
      }
    }

    case 'UNARCHIVE_CONVERSATION': {
      const { messages: oldMessages = {} } = state.conversations
      const { conversationId } = payload

      const unarchivedMessages = Object.keys(oldMessages).reduce((all, msgId) => {
        const msg = oldMessages[msgId]

        if (conversationId === msg.conversation_id) msg.conversation_archived = false

        return { ...all, [msgId]: msg }
      }, {})

      const conversations = {
        ...state.conversations,
        messages: { ...unarchivedMessages },
      }

      return { ...state, conversations }
    }

    default:
      return state
  }
}

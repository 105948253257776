import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import cn from 'classnames'

import Input from './Input'

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    '& [class*=multiline]': {
      padding: 12,
      display: 'block',
      lineHeight: '24px',
      overflowY: 'auto',
    },
  },
  noBorder: {
    '& [class*=MuiInputBase-root]': {
      border: 'none',
    },
    '& [class*=MuiFormControl-root]': {
      margin: 0,
    },
  },
  textarea: props => ({
    overflow: 'auto',
    maxHeight: props.maxLines ? (props.maxLines + 1) * 24 : 144,
  }),
  charLimitRight: {
    '& [class*=infoText]': {
      justifyContent: 'flex-end',
    },
  },
}))

function TextAreaInput(props) {
  const {
    classes, customClasses, maxLines, border, charLimitRight, ...restProps
  } = props
  const css = useStyles()

  const wrapperClasses = cn(css.wrapper, {
    [css.noBorder]: !border,
    [css.charLimitRight]: charLimitRight,
  })

  const { root, ...restCustomClasses } = customClasses

  return (
    <div className={wrapperClasses}>
      <Input
        multiline
        customClasses={{
          ...restCustomClasses,
          root: cn(root || '', css.textarea),
        }}
        {...restProps}
      />
    </div>
  )
}

TextAreaInput.defaultProps = {
  border: true,
  maxLines: 5,
  charLimitRight: false,
  customClasses: {},
}

TextAreaInput.propTypes = {
  border: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  customClasses: PropTypes.object,
  maxLines: PropTypes.number,
  charLimitRight: PropTypes.bool,
}

export default React.memo(TextAreaInput)

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Text from 'components/texts/Text'

const useStyles = makeStyles({
  sublabel: { marginTop: 4 },
})

function PageSubtitle(props) {
  const { text } = props
  const css = useStyles()

  return (
    <span className={css.sublabel}>
      <Text charcoalLight>{text}</Text>
    </span>
  )
}

PageSubtitle.propTypes = {
  text: PropTypes.string.isRequired,
}

export default React.memo(PageSubtitle)

import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

import { AppBar, Toolbar, makeStyles } from '@material-ui/core'

import NavBarMainMenu from './NavBarMainMenu'
import NavBarStatusInfo from './NavBarStatusInfo'
import NavBarRightMenu from './NavBarRightMenu'
import NavBarCurrentBrandIcon from './NavBarCurrentBrandIcon'
import Banner from './banners/Banner'


const useStyles = makeStyles(() => ({
  navbar: {
    boxShadow: 'none',
  },
}))

const PROMO_COUNTDOWN = [
  {
    startDisplay:
      process.env.REACT_APP_ENV === 'production'
        ? moment.tz('2019-12-01', 'America/New_York')
        : moment.tz('2019-11-01', 'America/New_York'),
    endDisplay:
      process.env.REACT_APP_ENV === 'production'
        ? moment.tz('2019-12-31', 'America/New_York').endOf('day')
        : moment.tz('2019-11-30', 'America/New_York').endOf('day'),
    promoText: '25% Holiday Discount',
    promoLink: '/upgrade-plan',
  },
]

function AppNavBar(props) {
  const {
    daysLeftInTrial,
    upgradeAvailable,
    currentBrand,
    managedBrands,
    messages,
    notifications,
    unreadMessageCount,
    unreadNotificationCount,
    isAdmin,
    promoCountdown,
    messagesLoading,
  } = props

  const css = useStyles()

  return (
    <>
      <AppBar classes={{ root: css.navbar }} position="static">
        <Toolbar>
          <NavBarMainMenu currentBrand={currentBrand} />
          {/* ---- ANNOUNCEMENTS | PROMOS | TRIAL STATUS ---- */}
          <NavBarStatusInfo
            currentBrand={currentBrand}
            daysLeftInTrial={daysLeftInTrial}
            upgradeAvailable={upgradeAvailable}
            isAdmin={isAdmin}
            promoCountdown={promoCountdown}
          />
          <NavBarRightMenu
            messages={messages}
            currentBrand={currentBrand}
            notifications={notifications}
            unreadMessageCount={unreadMessageCount}
            unreadNotificationCount={unreadNotificationCount}
            messagesLoading={messagesLoading}
          />
          {/* ---- CURRENT BRAND MENU ---- */}
          <NavBarCurrentBrandIcon managedBrands={managedBrands} currentBrand={currentBrand} />
        </Toolbar>
      </AppBar>
      <Banner
        currentBrand={currentBrand}
      />
    </>
  )
}

AppNavBar.defaultProps = {
  messages: [],
  notifications: [],
  unreadMessageCount: 0,
  unreadNotificationCount: 0,
  daysLeftInTrial: null,
  upgradeAvailable: false,
  promoCountdown: PROMO_COUNTDOWN,
}

AppNavBar.propTypes = {
  isAdmin: PropTypes.bool.isRequired,
  messagesLoading: PropTypes.bool.isRequired,
  managedBrands: PropTypes.array.isRequired,
  currentBrand: PropTypes.object.isRequired,
  messages: PropTypes.array,
  unreadMessageCount: PropTypes.number,
  notifications: PropTypes.array,
  unreadNotificationCount: PropTypes.number,
  daysLeftInTrial: PropTypes.number,
  upgradeAvailable: PropTypes.bool,
  promoCountdown: PropTypes.array,
}

export default React.memo(AppNavBar)

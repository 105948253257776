export default theme => ({
  '@global': {
    '-webkit-overflow-scrolling': 'touch',
    'scroll-behavior': 'smooth',
    fontColor: theme.colors.ink,
    '@font-face': [
      {
        fontFamily: 'Larsseit-Bold',
        fontStyle: 'bold',
        fontWeight: 'bold',
        src: 'url("/fonts/Larsseit-Bold.otf")',
      },
      {
        fontFamily: 'Larsseit-BoldItalic',
        fontStyle: 'italic',
        fontWeight: 'bold',
        src: 'url("/fonts/Larsseit-BoldItalic.otf")',
      },
      {
        fontFamily: 'Larsseit-ExtraBold',
        fontStyle: 'bold',
        fontWeight: 'bolder',
        src: 'url("/fonts/Larsseit-ExtraBold.otf")',
      },
      {
        fontFamily: 'Larsseit-ExtraBoldItalic',
        fontStyle: 'italic',
        fontWeight: 'bolder',
        src: 'url("/fonts/Larsseit-ExtraBoldItalic.otf")',
      },
      {
        fontFamily: 'Larsseit-Italic',
        fontStyle: 'italic',
        fontWeight: 'normal',
        src: 'url("/fonts/Larsseit-Italic.otf")',
      },
      {
        fontFamily: 'Larsseit-LightItalic',
        fontStyle: 'italic',
        fontWeight: '200',
        src: 'url("/fonts/Larsseit-LightItalic.otf")',
      },
      {
        fontFamily: 'Larsseit-Light',
        fontStyle: 'normal',
        fontWeight: '200',
        src: 'url("/fonts/Larsseit-Light.otf")',
      },
      {
        fontFamily: 'Larsseit-Medium',
        fontStyle: 'normal',
        fontWeight: '400',
        src: 'url("/fonts/Larsseit-Medium.otf")',
      },
      {
        fontFamily: 'Larsseit-MediumItalic',
        fontStyle: 'italic',
        fontWeight: '400',
        src: 'url("/fonts/Larsseit-MediumItalic.otf")',
      },
      {
        fontFamily: 'Larsseit-Thin',
        fontStyle: 'normal',
        fontWeight: 'lighter',
        src: 'url("/fonts/Larsseit-Thin.otf")',
      },
      {
        fontFamily: 'Larsseit-ThinItalic',
        fontStyle: 'italic',
        fontWeight: 'lighter',
        src: 'url("/fonts/Larsseit-ThinItalic.otf")',
      },
      {
        fontFamily: 'Larsseit',
        fontStyle: 'normal',
        fontWeight: 'normal',
        src: 'url("/fonts/Larsseit.otf")',
      },
      {
        fontFamily: 'Arizonia-Regular',
        fontStyle: 'normal',
        fontWeight: 'normal',
        src: 'url("/fonts/Arizonia-Regular.ttf")',
      },
    ],
    'a, .link-button': {
      fontFamily: 'Larsseit-Medium',
      fontStyle: 'normal',
      color: theme.colors.azure,
      textTransform: 'none',
      textDecoration: 'none',
      cursor: 'pointer',
      '&:focus': {
        textDecoration: 'none',
      },
      '&:hover': {
        color: theme.colors.azure,
        textTransform: 'none',
        textDecoration: 'none',
      },
    },
    p: {
      color: theme.colors.ink,
    },
  },
})

import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Button, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  actionButton: {
    backgroundColor: 'transparent',
    width: 'fit-content',
    color: 'white',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}))

export const ToasterContext = React.createContext()

export function ToasterProvider({ children }) {
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState('')
  const [background, setBackground] = useState('stone')
  const [delay, setDelay] = useState(2000)
  const [dismiss, setDismiss] = useState(false)

  const classes = useStyles()

  const showToaster = (msg, options = {}) => {
    const { type = 'default', timeout = 2000, showDismiss = false } = options
    const backgroundType = {
      default: 'stone',
      success: 'greenDarker',
      warning: 'alertRed',
    }

    setMessage(msg)
    setBackground(backgroundType[type])
    setOpen(true)
    setDelay(timeout)
    setDismiss(showDismiss)
  }

  const closeToaster = () => {
    setOpen(false)
  }

  return (
    <ToasterContext.Provider
      value={{
        open,
        showToaster,
        closeToaster,
        message,
        background,
        delay,
        action: dismiss ? (
          <Button size="medium" className={classes.actionButton} onClick={() => setOpen(false)}>
            Dismiss
          </Button>
        ) : null,
      }}
    >
      {children}
    </ToasterContext.Provider>
  )
}

ToasterProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
}

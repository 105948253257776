import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

import Text from 'components/texts/Text'

import { ReactComponent as Star } from 'images/icons/star.svg'
import { ReactComponent as Lock } from 'images/icons/lock-white.svg'

const useStyles = makeStyles(theme => ({
  badge: {
    borderRadius: 14,
    display: 'flex',
    justifyContent: 'center',
    padding: '4px 8px',
    userSelect: 'none',
    '& p': {
      textTransform: 'capitalize',
      whiteSpace: 'nowrap',
    },
  },
  icon: {
    marginRight: 4,
  },
  green: {
    backgroundColor: theme.colors.tealBase,
    '& p': {
      color: 'white',
    },
  },
  yellow: {
    backgroundColor: theme.colors.yellowLight,
  },
}))

const values = {
  recommended: { Icon: Star, color: 'yellow' },
  upgrade: { Icon: Lock, color: 'green' },
}

function MessageBadge(props) {
  const { hideIcon, type } = props
  const classes = useStyles()

  const { Icon, color } = values[type]

  return (
    <div className={cn(classes.badge, classes[color])}>
      {!hideIcon && <Icon className={classes.icon} />}
      <Text charcoalDarker size={12}>
        {type}
      </Text>
    </div>
  )
}

MessageBadge.propTypes = {
  hideIcon: PropTypes.bool,
  type: PropTypes.oneOf(['recommended', 'upgrade']),
}

MessageBadge.defaultProps = {
  hideIcon: false,
  type: 'recommended',
}

export default React.memo(MessageBadge)

import React from 'react'
import PropTypes from 'prop-types'

import { DragDropContainer } from 'components/shared/draggable'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

import FormPageEmptyState from './FormPageEmptyState'
import FieldOption from '../fields/FieldOption'

const REQUIRED_FIELDS = ['phone', 'email']

function FormPageInputs(props) {
  const {
    onDelete, onSort, inputs, showRequiredFieldsToaster, hasSingleRequiredField,
  } = props

  const {
    builder: { drawer },
  } = React.useContext(BuilderContainerContext)

  const render = input => {
    const isPhoneOrEmailField = REQUIRED_FIELDS.includes(input.name)

    const onRemove = () => {
      if (hasSingleRequiredField && isPhoneOrEmailField) {
        return showRequiredFieldsToaster()
      }
      return onDelete(input)
    }

    const onModify = () => drawer.open({ data: input })

    return (
      <FieldOption isInPage key={input.name} input={input} onDelete={onRemove} onEdit={onModify} />
    )
  }

  return (
    <DragDropContainer data={inputs} itemRender={render} onSort={onSort}>
      <FormPageEmptyState />
    </DragDropContainer>
  )
}

FormPageInputs.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  inputs: PropTypes.array.isRequired,
  showRequiredFieldsToaster: PropTypes.func.isRequired,
  hasSingleRequiredField: PropTypes.bool.isRequired,
}

export default React.memo(FormPageInputs)

import moment from 'moment-timezone'

const estTimezone = 'America/New_York'

// TODO: Implement this in automations too
export default (currentDate = Date.now()) => {
  const deliveryTimes = []
  const isToday = moment(currentDate).isSame(moment(), 'day')
  const tomorrow = moment(currentDate).endOf('day')

  const time = moment(currentDate).startOf('day')

  while (time.isBefore(tomorrow)) {
    let disabled = false
    if (isToday) {
      const h = time.hour()
      const m = time.minute()
      // only consider EST times
      const estTime = moment().tz(estTimezone)
      if (h < estTime.hour() || (h === estTime.hour() && m <= estTime.minute())) {
        disabled = true
      }
    }
    const formattedTime = time.format('hh:mm A')
    deliveryTimes.push({ label: formattedTime, value: formattedTime, disabled })

    time.add(15, 'minutes')
  }

  return deliveryTimes
}

// Returns time in EST
export const getClosestTimeIn15MinsInterval = () => {
  const currentTime = moment().tz(estTimezone)
  currentTime.set({ minutes: Math.ceil((currentTime.minutes() + 1) / 15) * 15 })
  return currentTime.clone()
}

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import Input from './Input'
import Button from '../buttons/Button'
import VirtualTheme from '../VirtualTheme'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    position: 'relative',
    alignItems: 'flex-end',
    '& > :first-child': {
      flex: 1,
    },
    '& > button': {
      position: 'absolute',
      right: 0,
      top: 32,
    },
  },
})

function InputWithAction(props) {
  const { buttonLabel, buttonAction, disabled } = props

  const css = useStyles(props)
  const ref = React.useRef(null)

  const isButtonAvailable = !disabled && buttonAction

  useEffect(() => {
    const input = ref.current.querySelector('input')
    if (isButtonAvailable) {
      const button = ref.current.querySelector('button')
      const width = button.offsetWidth
      const [padding] = getComputedStyle(input).paddingLeft.split('px')
      button.style.padding = '0 8px'
      button.style.margin = '0 4px 6px'
      input.style.width = `calc(100% - ${width - padding}px)`
    } else {
      input.style.width = ''
    }
  }, [isButtonAvailable])

  return (
    <VirtualTheme>
      <div ref={ref} className={css.container}>
        <Input fullWidth {...props} />
        {isButtonAvailable && (
          <Button
            color="secondary"
            size="small"
            type="text"
            label={buttonLabel}
            onClick={buttonAction}
          />
        )}
      </div>
    </VirtualTheme>
  )
}

InputWithAction.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  buttonAction: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}

InputWithAction.defaultProps = {
  disabled: false,
}

export default React.memo(InputWithAction)

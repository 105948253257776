import React, { useState } from 'react'
import PropTypes from 'prop-types'

export const AnnouncementsModalContext = React.createContext()

export function AnnouncementsModalProvider({ children }) {
  const [open, setOpen] = useState(false)
  const [modalTitle, setTitle] = useState('')
  const [modalLabel, setLabel] = useState('')
  const [modalAction, setModalAction] = useState(null)
  const [modalContent, setContent] = useState('')
  const [modalCancel, setModalCancel] = useState('')
  const [modalCancelAction, setModalCancelAction] = useState(null)

  const setValues = args => {
    const {
      action = null,
      cancelAction = null,
      cancelLabel = '',
      title = '',
      label = '',
      content = '',
    } = args

    setTimeout(
      () => {
        setTitle(title)
        setLabel(label)
        setContent(content)
        setModalCancel(cancelLabel)
        setModalAction(() => action)
        setModalCancelAction(() => cancelAction)
      },
      !title && !label && !content ? 500 : 1
    )

    setOpen(false)
  }

  const actions = {
    showModal(show = true) {
      setOpen(show)
    },
    setValues,
  }

  const values = {
    open,
    title: modalTitle,
    label: modalLabel,
    content: modalContent,
    cancelLabel: modalCancel,
    cancelAction: modalCancelAction,
    action: modalAction || setValues,
  }

  return (
    <AnnouncementsModalContext.Provider value={{ ...actions, values }}>
      {children}
    </AnnouncementsModalContext.Provider>
  )
}

AnnouncementsModalProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
}

const initState = {
  brandClaimsLoading: true,
  brandClaimsError: false,
}

export default (state = initState, action) => {
  const { type, payload = {} } = action

  switch (type) {
    case 'LOADING_BRAND_CLAIMS':
      return {
        ...state,
        brandClaimsLoading: true,
      }

    case 'LOAD_BRAND_CLAIMS_SUCCESS':
      return {
        ...state,
        ...payload,
        brandClaimsLoading: false,
      }

    case 'LOAD_BRAND_CLAIMS_FAILURE':
      return {
        ...state,
        brandClaimsLoading: false,
        brandClaimsError: payload.response
          ? `${payload.response.status}: ${payload.response.data}`
          : 'Error',
      }

    default:
      return state
  }
}

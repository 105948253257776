import _ from 'lodash'
import store from 'utils/reduxStore'

import enhanceMessageObject from './enhanceMessageObject'

export default conversations => {
  const { id: currentBrandId } = store.getState().currentBrand
  const { id: currentUserId } = store.getState().profile

  let hasSent = false

  const receivedInvitations = []
  const receivedApplications = []
  const sentInvitations = []
  const sentApplications = []

  const unreadConvos = []

  _.each(conversations, message => {
    if (currentUserId === message.sender_id) hasSent = true

    message = enhanceMessageObject(message, currentUserId, currentBrandId)

    if (!message.partnership_action) {
      if (message.messageType === 'receivedInvitation') receivedInvitations.push(message.id)
      else if (message.messageType === 'sentInvitation') sentInvitations.push(message.id)
      else if (message.messageType === 'receivedApplication') receivedApplications.push(message.id)
      else if (message.messageType === 'sentApplication') sentApplications.push(message.id)
    }

    if (
      !unreadConvos.includes(message.conversation_id)
      && new Date(message.createdAt) > new Date(message.last_read)
      && !message.conversation_archive
    ) {
      unreadConvos.push(message.conversation_id)
    }
  })

  const messagesHashes = {}
  _.each(conversations, message => {
    messagesHashes[message.id] = message
  })

  return {
    messages: messagesHashes,
    unread: unreadConvos.length,
    receivedInvitations,
    receivedApplications,
    sentInvitations,
    sentApplications,
    hasSent,
  }
}

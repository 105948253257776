import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import MobileDesktopPreviewSelect from './MobileDesktopPreviewSelect'
import { BuilderContainerContext } from '../BuilderContainerContext'
import MobileDesktopWarningBanner from './MobileDesktopWarningBanner'

const useStyles = makeStyles(() => ({
  warningBannerWrapper: {
    height: '51px',
  },
}))

function MobileDesktopRadioButtons() {
  const { builder } = React.useContext(BuilderContainerContext)
  const {
    preview: { full, type, PreviewNavbar },
  } = builder

  const css = useStyles()

  let PreviewWrapper = React.Fragment

  if (!full) PreviewWrapper = PreviewNavbar || React.Fragment

  return (
    <div>
      <PreviewWrapper previewType={type}>
        <MobileDesktopPreviewSelect />
      </PreviewWrapper>
      <div className={css.warningBannerWrapper}>
        {type === 'mobile' && <MobileDesktopWarningBanner />}
      </div>
    </div>
  )
}

export default React.memo(MobileDesktopRadioButtons)

import React from 'react'
import PropTypes from 'prop-types'

import InputWithSideLabel from 'components/virtual/inputs/InputWithSideLabel'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

function BehaviorTriggerDelay(props) {
  const { disabled } = props
  const {
    lightboxSettings,
    builder: { errors },
  } = React.useContext(BuilderContainerContext)
  const settings = lightboxSettings.getSettings()

  const onDelayChange = ({ target }) => {
    const delay = Number(target.value || 0)
    if (!/^\d*$/.test(delay)) return
    settings.trigger = { delay }
  }

  const onKeyDown = e => {
    let { value: delay } = e.target
    switch (e.key) {
      case 'ArrowUp':
        delay = Number(delay) + 1
        break
      case 'ArrowDown':
        delay = Math.max(0, Number(delay) - 1)
        break
      default:
        break
    }

    onDelayChange({ target: { value: delay } })
  }

  const error = errors.verify('delay')

  return (
    <div style={{ marginTop: 8 }}>
      <InputWithSideLabel
        controlledInput
        disabled={disabled}
        value={settings.trigger.delay}
        onChange={onDelayChange}
        onKeyDown={onKeyDown}
        label="Seconds"
        error={error}
      />
    </div>
  )
}

BehaviorTriggerDelay.propTypes = {
  disabled: PropTypes.bool.isRequired,
}

export default React.memo(BehaviorTriggerDelay)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import RadioSelector from 'components/virtual/buttons/RadioSelector'
import Text from 'components/texts/Text'
import LightboxBuilderThemeButtonsRadius from './LightboxBuilderThemeButtonsRadius'

const useStyles = makeStyles(theme => ({
  radios: {
    display: 'flex',
  },
  option: {
    textTransform: 'capitalize',
    margin: '20px 20px 32px 0',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
  },
  buttons: {
    width: 140,
    padding: 20,
    marginBottom: 12,
    '&, & > div': {
      transition: 'all .1s linear',
      borderRadius: 2,
      border: `1px solid ${theme.colors.skyDarker}`,
      '&.text': {
        border: 'none',
      },
    },
    '& > div': {
      height: 30,
      display: 'grid',
      placeContent: 'center',
    },
  },
  selected: {
    '&, & > div': {
      borderColor: theme.colors.navyLight,
      backgroundColor: theme.colors.navyLightest,
    },
  },
  text: {
    '& > div': {
      border: 'none',
    },
  },
  filled: {
    '& > div': {
      backgroundColor: theme.colors.navyLight,
    },
  },
}))

const THEME_BUTTONS_LAYOUTS = {
  primaryButton: ['filled', 'outline'],
  secondaryButton: ['outline', 'text link'],
}

function LightboxBuilderThemeButtons() {
  const css = useStyles()
  const { lightboxSettings } = React.useContext(BuilderContainerContext)
  const settings = lightboxSettings.getSettings()

  const onButtonChange = which => design => {
    settings[which] = { design }
  }

  const render = radio => {
    const textOptions = {}
    const classNames = [css.buttons]

    if (radio.selected) {
      classNames.push(css.selected)
      if (radio.value === 'filled') {
        textOptions.white = true
        classNames.push(css.filled)
      } else if (radio.value === 'text') {
        textOptions.navyBase = true
        classNames.push(css.text)
      } else textOptions.navyLight = true
    } else textOptions.skyDarker = true

    return (
      <div className={css.option}>
        <div className={cn(classNames)}>
          <div className={radio.value}>
            <Text {...textOptions}>Button</Text>
          </div>
        </div>
        <Text size={16} charcoalLight={!radio.selected} navyBase={radio.selected}>
          {radio.value}
        </Text>
      </div>
    )
  }

  const radioOptions = [
    {
      which: 'primaryButton',
      label: 'Primary Button',
      onChange: onButtonChange('primaryButton'),
    },
  ]

  const formTypesThatIncludeSecondaryButton = ['lightbox', 'popup']
  if (formTypesThatIncludeSecondaryButton.includes(settings.type)) {
    radioOptions.push({
      which: 'secondaryButton',
      label: 'Secondary Button',
      onChange: onButtonChange('secondaryButton'),
    })
  }

  return (
    <div>
      <LightboxBuilderThemeButtonsRadius />
      <div style={{ paddingTop: 32 }} />
      {radioOptions.map(option => (
        <div key={option.which}>
          <Text size={16} charcoalBase>
            {option.label}
          </Text>
          <RadioSelector
            value={settings[option.which].design}
            values={THEME_BUTTONS_LAYOUTS[option.which].map(value => ({ value }))}
            onChange={option.onChange}
            render={render}
            className={css.radios}
          />
        </div>
      ))}
    </div>
  )
}

export default React.memo(LightboxBuilderThemeButtons)

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
  },
  page: {
    backgroundColor: theme.colors.skyDarker,
    borderRadius: '50%',
    height: 12,
    width: 12,
    marginRight: 12,
    '&:last-of-type': {
      marginRight: 0,
    },
  },
  active: {
    backgroundColor: theme.colors.blueLight,
  },
}))

function PageCounter(props) {
  const { numPages, page } = props

  const classes = useStyles()

  const pages = []
  for (let i = 0; i < numPages; i++) {
    pages.push(<div className={cn(classes.page, { [classes.active]: page === i + 1 })} key={i} />)
  }

  return <div className={classes.container}>{pages}</div>
}

PageCounter.propTypes = {
  numPages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
}

export default React.memo(PageCounter)

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Text from 'components/texts/Text'
import Button from 'components/virtual/buttons/Button'
import VirtualTheme from 'components/virtual/VirtualTheme'
import ExpandableSection from './ExpandableSection'

const useStyles = makeStyles(theme => ({
  content: {
    padding: 20,
  },
  icon: {
    border: `1px solid ${theme.colors.blueBase}`,
    backgroundColor: theme.colors.blueLightest,
    borderRadius: '50%',
    height: 30,
    width: 30,
    display: 'grid',
    placeItems: 'center',
    '& h4': {
      lineHeight: 1,
    },
  },
  button: {
    marginTop: 40,
  },
}))

// TODO: Name subject to change?
function EnableEmailDrip(props) {
  const { open, onToggle } = props
  const css = useStyles()

  const icon = (
    <div className={css.icon}>
      <Text size={24} blueBase>
        3
      </Text>
    </div>
  )

  const topText = "Now that you're migrating data from the signup form to your ESP, its time to set up you email welcome flow. This can be completed in your ESP."
  const onReadGuideClick = () =>
    window.open('https://help.dojomojo.com/hc/en-us/articles/115003905192', '_blank')

  return (
    <ExpandableSection
      text="Confirm Your Email Welcome Series"
      icon={icon}
      open={open}
      onToggle={onToggle}
    >
      <div className={css.content}>
        <Text charcoalLight>{topText}</Text>
        <div className={css.button}>
          <VirtualTheme>
            <Button label="Read Guide" type="outlined" size="smaller" onClick={onReadGuideClick} />
          </VirtualTheme>
        </div>
      </div>
    </ExpandableSection>
  )
}

EnableEmailDrip.propTypes = {
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
}

export default React.memo(EnableEmailDrip)

import React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import { ToasterContext } from 'contexts'
import copyToClipboard from 'utils/copyToClipboard'

import Text from 'components/texts/Text'
import Button from 'components/virtual/buttons/Button'
import SubscriberLimitReachedBottomBanner from '../../banners/SubscriberLimitReachedBottomBanner'
import LightboxBuilderSaveAndPublishButton from '../../shared/LightboxBuilderSaveAndPublishButton'

const useStyles = makeStyles(theme => ({
  installation: {
    flex: 2,
    border: `1px solid ${theme.colors.skyBase}`,
    borderRadius: 4,
    padding: 20,
    display: 'grid',
    gridRowGap: '32px',
    maxWidth: 792,
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: theme.colors.skyBase,
  },
  textGroup: {
    flex: 1,
    borderTop: `1px solid ${theme.colors.skyBase}`,
    '& > :first-child': {
      marginTop: 32,
      marginBottom: 3,
    },
    '&:first-of-type': {
      borderTop: 'none',
      '& > :first-child': {
        marginTop: 0,
      },
    },
  },
  codeBlockText: {
    height: 124,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '38px 32px',
    borderRadius: 4,
    backgroundColor: ({ isNew }) => theme.colors[isNew ? 'skyLight' : 'charcoalLight'],
    marginTop: 32,
  },
  isNew: {
    '& h6': {
      textAlign: 'center',
    },
  },
  buttonRow: {
    display: 'grid',
    justifyItems: 'end',
    marginTop: 32,
  },
  infoRow: {
    gridTemplateColumns: '19px 1fr auto',
    gridColumnGap: '12px',
  },
}))

function LightboxBuilderInstallation() {
  const currentBrand = useSelector(state => state.currentBrand)
  const toastr = React.useContext(ToasterContext)
  const { isNew, lightboxSettings } = React.useContext(BuilderContainerContext)

  const { settings } = lightboxSettings.getSettings()

  const css = useStyles({ isNew })

  const isEmbed = settings.type === 'embed'

  const popupFormScript = `<script data-dojomojo-id="${currentBrand.id}" src="${process.env.REACT_APP_ENV_LIGHTBOX_URL}/sign-up.js"></script>`
  const embedFormDiv = `<div class="dojo-embed-root-${settings.id}"></div>`

  const generateCodeComponent = (codeText, newFormCopy) => (
    <div className={css.codeBlockText}>
      <Text white={!isNew} charcoalDarker={isNew} size={16}>
        {isNew ? (
          newFormCopy
        ) : (
          <>
            {'<!-- DOJOMOJO SIGNUP FORM -->'}
            <br />
            {codeText}
          </>
        )}
      </Text>
    </div>
  )

  const scriptText = generateCodeComponent(
    popupFormScript,
    'The signup form script will be generated after you save your progress.'
  )
  const anchorDivText = generateCodeComponent(
    embedFormDiv,
    'The signup form HTML element will be generated after you save your progress.'
  )

  const onCopyToClipboard = codeToCopy => {
    if (isNew) return

    const isCopied = copyToClipboard(
      `
        <!-- DOJOMOJO SIGNUP FORM -->
        ${codeToCopy}
      `
    )

    const [msg, toastrOpts = {}] = isCopied
      ? ['Copied to Clipboard', { type: 'success' }]
      : ['Could not copy to clipboard', { type: 'warning' }]

    toastr.showToaster(msg, toastrOpts)
  }

  const installStep1 = {
    title: 'Step 1: Publish Your Form',
    subtitle:
      "Once you've finished creating your form, hit “Save & Publish” to set the latest changes live.",
    children: (
      <div className={css.buttonRow}>
        <LightboxBuilderSaveAndPublishButton />
      </div>
    ),
  }

  let step2InfoCopy = "If you have already installed a code snippet in your site's header for another DojoMojo signup form (pop-up or embed), you do not need to install it again"
  if (isEmbed) step2InfoCopy += ' and can jump directly to Step 3.'
  else step2InfoCopy += '.'

  const installStep2 = {
    title: 'Step 2: Install Code in Your Website Header',
    subtitle:
      "Copy the code snippet below and paste it before the closing </head> tag in your site's main theme file.",
    children: (
      <>
        {scriptText}
        <div className={cn(css.buttonRow, css.infoRow)}>
          <svg
            fill="none"
            height="18"
            viewBox="0 0 18 18"
            width="18"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m9.00065 17.334c4.60005 0 8.33335-3.7333 8.33335-8.33335 0-4.6-3.7333-8.333334-8.33335-8.333334-4.6 0-8.333334 3.733334-8.333334 8.333334 0 4.60005 3.733334 8.33335 8.333334 8.33335zm-.83333-12.50002h1.66666v1.66667h-1.66666zm0 3.33334h1.66666v4.99998h-1.66666z"
              fill="#86a0ac"
            />
          </svg>
          <Text charcoalLight size={12}>
            {step2InfoCopy}
          </Text>
          <Button
            disabled={isNew}
            size="small"
            label="Copy Code"
            type="outlined"
            onClick={() => onCopyToClipboard(popupFormScript)}
          />
        </div>
      </>
    ),
  }

  const installStep3 = {
    title: 'Step 3: Add the Code to a Specific Page on Your Site',
    subtitle:
      "To display the embed on your site, paste the snippet on the specific page you'd like the form to appear using a custom HTML block. Save your changes and voila!",
    children: (
      <>
        {anchorDivText}
        <div className={css.buttonRow}>
          <Button
            disabled={isNew}
            size="small"
            label="Copy Code"
            type="outlined"
            onClick={() => onCopyToClipboard(embedFormDiv)}
          />
        </div>
      </>
    ),
  }

  const installSteps = [installStep1, installStep2]
  if (isEmbed) installSteps.push(installStep3)

  return (
    <div className={css.installation}>
      {installSteps.map(step => (
        <div className={css.textGroup} key={step.title}>
          <Text size={18} charcoalDarker>
            {step.title}
          </Text>
          <Text charcoalBase>{step.subtitle}</Text>
          {step.children}
        </div>
      ))}

      <SubscriberLimitReachedBottomBanner />
    </div>
  )
}

export default React.memo(LightboxBuilderInstallation)

import {
  createStore, applyMiddleware, combineReducers, compose,
} from 'redux'
import thunk from 'redux-thunk'
import * as reducers from '../reducers'

const composeEnhancers = (process.env.NODE_ENV !== 'production'
    && typeof window !== 'undefined'
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
  || compose

export default createStore(combineReducers(reducers), composeEnhancers(applyMiddleware(thunk)))

import { lazy } from 'react'

const RETRIES = 5
const INTERVAL = 1000

function retryChunk(fn, retriesLeft = RETRIES, interval = INTERVAL) {
  return new Promise((res, rej) => {
    fn()
      .then(res)
      .catch(err => {
        setTimeout(() => {
          if (retriesLeft === 1) return rej(err)

          return retryChunk(fn, retriesLeft - 1, interval).then(res, rej)
        }, interval)
      })
  })
}

export default fn => lazy(() => retryChunk(fn))

import React from 'react'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

import LightboxBuilderContentEdit from './content/LightboxBuilderContentEdit'
import LightboxBuilderThemeEdit from './theme/LightboxBuilderThemeEdit'
import LightboxBuilderSetupFieldsEditMode from '../setup/fields/edit-mode/LightboxBuilderFieldsEditMode'

function LightboxBuilderDesignEdit() {
  const { builder, lightboxSettings } = React.useContext(BuilderContainerContext)

  const { drawer } = builder
  const { data: drawerData } = drawer

  if (drawerData?.data?.input) return <LightboxBuilderSetupFieldsEditMode />

  if (!lightboxSettings.isLoaded) return null

  const { settings } = lightboxSettings.getSettings()

  const EditComponent = {
    page: LightboxBuilderContentEdit, // lightbox, popup
    step: LightboxBuilderContentEdit, // embed
    theme: LightboxBuilderThemeEdit,
  }[drawerData?.type]

  return EditComponent ? <EditComponent formType={settings.type} /> : null
}

export default React.memo(LightboxBuilderDesignEdit)

import React from 'react'
import { useSelector } from 'react-redux'
import { getUsersOtherBrands } from 'reducers/selectors/profile'
import {
  pickMessageThreadsUnreadFirst,
  getUnreadNotificationsFirst,
} from 'reducers/selectors/messaging'
import { getDaysLeftInTrial, canUpgrade } from 'reducers/selectors/currentBrand'

import { NavBar } from 'components/navbars'

export { default as ImpersonatingMarker } from './ImpersonatingMarker'

function HydratedNavBar() {
  const isAdmin = useSelector(state => state.profile.canImpersonate)
  const currentBrand = useSelector(state => state.currentBrand)
  const managedBrands = useSelector(getUsersOtherBrands)
  const unreadMessageCount = useSelector(state => state.messaging.conversations.unread)
  const messages = useSelector(pickMessageThreadsUnreadFirst)
  const unreadNotificationCount = useSelector(state => state.messaging.notifications.unreadCount)
  const notifications = useSelector(getUnreadNotificationsFirst)
  const daysLeftInTrial = useSelector(getDaysLeftInTrial)
  const upgradeAvailable = useSelector(canUpgrade)
  const messagesLoading = useSelector(
    state => state.messaging.conversationLoading || state.messaging.notificationsLoading
  )

  return (
    <NavBar
      isAdmin={isAdmin}
      currentBrand={currentBrand}
      managedBrands={managedBrands}
      messages={messages}
      unreadMessageCount={unreadMessageCount}
      notifications={notifications}
      unreadNotificationCount={unreadNotificationCount}
      daysLeftInTrial={daysLeftInTrial}
      upgradeAvailable={upgradeAvailable}
      messagesLoading={messagesLoading}
    />
  )
}

export default HydratedNavBar

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

import { ReactComponent as TooltipIcon } from 'images/icons/tooltip-icon.svg'
import Text from 'components/texts/Text'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import Button from 'components/virtual/buttons/Button'
import VirtualTheme from 'components/virtual/VirtualTheme'
import ExpandableSection from './ExpandableSection'

const useStyles = makeStyles(theme => ({
  content: {
    padding: 20,
  },
  icon: {
    border: `1px solid ${theme.colors.blueBase}`,
    backgroundColor: theme.colors.blueLightest,
    borderRadius: '50%',
    height: 30,
    width: 30,
    display: 'grid',
    placeContent: 'center',
  },
  tooltipIcon: {
    minHeight: 16,
    minWidth: 16,
  },
  input: {
    marginTop: 32,
    maxWidth: 414,
  },
  banner: {
    backgroundColor: theme.colors.skyLight,
    display: 'flex',
    padding: 20,
    '& > svg': {
      marginRight: 12,
    },
  },
  listName: {
    flex: 1,
    '& > :last-child': {
      height: 48,
      padding: '4px 4px 4px 12px',
      marginTop: 8,
      border: `1px solid ${theme.colors.skyDarker}`,
      borderRadius: 4,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: ({ hasList }) => (hasList ? 'white' : theme.colors.skyLighter),
    },
  },
}))

function ESPAudienceList(props) {
  const { lightboxSettings, values } = React.useContext(BuilderContainerContext)

  const { ESP, baseUrl } = values
  const settings = lightboxSettings.getSettings()
  const selectedId = settings?.espSettings.listId
  const history = useHistory()
  const { open, onToggle } = props
  const css = useStyles({ hasList: !!selectedId })

  const icon = (
    <div className={css.icon}>
      <Text size={24} blueBase>
        1
      </Text>
    </div>
  )

  const getListLabel = () => {
    if (selectedId && ESP) {
      const { lists } = ESP.integrationData.details
      if (lists) {
        return lists.find(listObj => listObj.value === selectedId).label
      }
    }

    return 'Signup Form Welcome List'
  }

  const onChangeList = () => history.push(`${baseUrl}/settings/integrations`)

  const inputWithAction = (
    <div className={css.listName}>
      <Text charcoalBase>Choose ESP List</Text>
      <div>
        <Text ellipsis size={16} charcoalDarker={selectedId} charcoalLighter={!selectedId}>
          {getListLabel()}
        </Text>
        <VirtualTheme>
          <Button
            color="secondary"
            type="text"
            size="smaller"
            label="Change"
            disabled={!selectedId}
            onClick={onChangeList}
          />
        </VirtualTheme>
      </div>
    </div>
  )

  return (
    <ExpandableSection
      text="Send New Signups to Your ESP"
      icon={icon}
      open={open}
      onToggle={onToggle}
    >
      <div>
        <div className={css.content}>
          <Text charcoalLight>
            Select the list you&apos;d like your new email subscribers to be added to.
          </Text>
          <div className={css.input}>{inputWithAction}</div>
        </div>
        <div className={css.banner}>
          <TooltipIcon className={css.tooltipIcon} />
          <Text charcoalBase size={12}>
            If you do not have your email welcome series set up for the selected list, you will need
            to create one in your ESP.
          </Text>
        </div>
      </div>
    </ExpandableSection>
  )
}

ESPAudienceList.propTypes = {
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
}

export default React.memo(ESPAudienceList)

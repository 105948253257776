import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

import Text from 'components/texts/Text'
import Tooltip from 'components/virtual/utility/Tooltip'
import IntegrationSwitch from './IntegrationSwitch'

const useStyles = makeStyles({
  connectedIntegrations: {
    marginBottom: 32,
  },
  title: {
    marginBottom: 12,
    display: 'flex',
    alignItems: 'center',
    '& > :first-child': {
      marginRight: 8,
    },
  },
  integrations: {
    display: 'flex',
    gap: '24px',
    flexWrap: 'wrap',
  },
})

const TOOLTIP = 'Sync new email contacts collected with your ESP. Choose one of your existing email providers to connect to this Signup Form.'

const STATUS_TEXT_MAP = {
  ready: 'Connected to Signup Form',
  standby: 'ESP Ready to Connect',
  notsupported: 'ESP Not Supported for Signup Forms',
}

function ConnectedIntegrations(props) {
  const { setSelectedESP, selectedESP } = props
  const { espIntegration, lightboxSettings } = React.useContext(BuilderContainerContext)
  const settings = lightboxSettings.getSettings()
  const { integratedESPs } = espIntegration
  const css = useStyles()

  const onEspConnect = esp => selected => setSelectedESP(selected ? esp : null)

  return (
    <div className={css.connectedIntegrations}>
      <div className={css.title}>
        <Text size={16}>Existing Integrations</Text>
        <Tooltip text={TOOLTIP} />
      </div>
      <div className={css.integrations}>
        {Object.values(integratedESPs).map(esp => {
          const status = esp.lightboxEnabled
            ? settings?.espSettings.brandIntegrationId === esp.integrationData.id
              ? 'ready'
              : 'standby'
            : 'notsupported'

          return (
            <IntegrationSwitch
              key={esp.key}
              onEnable={onEspConnect(esp)}
              enabled={esp.key === selectedESP?.key}
              title={esp.label}
              image={esp.img}
              status={status}
              statusText={STATUS_TEXT_MAP[status]}
            />
          )
        })}
      </div>
    </div>
  )
}

ConnectedIntegrations.propTypes = {
  setSelectedESP: PropTypes.func.isRequired,
  selectedESP: PropTypes.string,
}

ConnectedIntegrations.defaultProps = {
  selectedESP: null,
}

export default React.memo(ConnectedIntegrations)

import React from 'react'
import PropTypes from 'prop-types'

import BuilderContainerProvider from './BuilderContainerContext'
import BuilderContainer from './BuilderContainer'

function BuilderContainerWrapper(props) {
  return (
    <BuilderContainerProvider {...props}>
      <BuilderContainer />
    </BuilderContainerProvider>
  )
}

BuilderContainerWrapper.propTypes = {
  config: PropTypes.object.isRequired,
}

export default React.memo(BuilderContainerWrapper)

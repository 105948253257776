import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import Text from 'components/texts/Text'
import useInputDebounce from 'hooks/useInputDebounce'
import InputBase from './InputBase'
import VirtualTheme from '../VirtualTheme'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    '& > :first-child': {
      // input component
      position: 'relative',
      maxWidth: 48,
      marginRight: 12,
      // error text (if any)
      '& > p': {
        top: '100%',
        whiteSpace: 'nowrap',
      },
      '& input': {
        // input element
        padding: '6px 8px',
        height: 16,
        fontSize: 14,
        textAlign: 'center',
      },
    },
  },
  textContainer: {
    alignSelf: 'center',
    '& h6': {
      lineHeight: 1,
    },
  },
})

function InputWithSideLabel(props) {
  const {
    disabled, value, label, onChange, controlledInput, ...rest
  } = props

  const css = useStyles(props)
  const inputRef = React.useRef(null)

  const [inputValue, onInputChange] = useInputDebounce(value, onChange, controlledInput)

  return (
    <VirtualTheme>
      <div className={css.container} onClick={inputRef.current?.focus}>
        <InputBase
          ref={inputRef}
          onChange={onInputChange}
          value={inputValue}
          disabled={disabled}
          {...rest}
        />
        <div className={css.textContainer}>
          <Text size={16} skyDarker={disabled} charcoalBase={!disabled}>
            {label}
          </Text>
        </div>
      </div>
    </VirtualTheme>
  )
}

InputWithSideLabel.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  controlledInput: PropTypes.bool,
}

InputWithSideLabel.defaultProps = {
  disabled: false,
  controlledInput: false,
}

export default React.memo(InputWithSideLabel)

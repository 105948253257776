import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { SketchPicker } from 'react-color'
import { makeStyles } from '@material-ui/core/styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import ButtonBase from '@material-ui/core/ButtonBase'
import Dialog from '@material-ui/core/Dialog'

import Text from 'components/texts/Text'

const useStyles = makeStyles(theme => ({
  preview: {
    padding: 8,
    '& > div': {
      height: 40,
      borderRadius: 4,
    },
  },
  wrapper: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    '& > :nth-child(2)': {
      boxShadow: 'none !important',
    },
  },
  buttonWrapper: {
    padding: 8,
    textAlign: 'center',
  },
  button: {
    padding: '4px 8px',
    borderRadius: 2,
    backgroundColor: theme.colors.blueBase,
  },
}))

function ColorPicker(props) {
  const {
    show, color: colorToSet, onClose, onColorSelect,
  } = props
  const css = useStyles()
  const [color, setColor] = useState({ hex: '#ffffff' })

  useEffect(() => {
    if (show) setColor({ hex: colorToSet })
  }, [show, setColor]) // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeColor = () => onColorSelect(color.hex)

  return (
    <Dialog open={show}>
      <ClickAwayListener onClickAway={onClose} mouseEvent="onMouseDown" touchEvent="onTouchStart">
        <div className={css.wrapper}>
          <div className={css.preview}>
            <div style={{ backgroundColor: color.hex }} />
          </div>
          <SketchPicker presetColors={[]} disableAlpha color={color} onChange={setColor} />
          <div className={css.buttonWrapper}>
            <ButtonBase onClick={onChangeColor} className={css.button}>
              <Text size={12} white>
                Select Color
              </Text>
            </ButtonBase>
          </div>
        </div>
      </ClickAwayListener>
    </Dialog>
  )
}

ColorPicker.propTypes = {
  show: PropTypes.bool.isRequired,
  color: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onColorSelect: PropTypes.func.isRequired,
}

export default React.memo(ColorPicker)

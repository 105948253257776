import * as metrics from 'utils/metrics'

const clickedOn = (itemName, opts = {}) => {
  if (itemName === 'Create New Menu') {
    metrics.create('navBarClickedCreateNew')
  }

  metrics.create('navBar', {
    meta: {
      itemName,
      action: 'clickedOnItem',
      ...opts,
    },
  })
}

const onMenuClick = (fn, item) => e =>
  fn(open => {
    if (e) e.stopPropagation()

    clickedOn(item, { isMenuOpen: !open })
    return !open
  })

export { onMenuClick, clickedOn }

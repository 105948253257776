import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { IconButton, Badge, makeStyles } from '@material-ui/core'

import mailIcon from 'images/nav-bar/mail-icon.svg'
import blueMailIcon from 'images/nav-bar/blue/mail-icon.svg'
import LoadingIconSvg from 'images/icons/loading-icon.svg'

import MessagesMenu from 'components/menus/MessagesMenu'

import useMobileDetect from 'hooks/useMobileDetect'

import { onMenuClick } from './commonUtils'

const useStyles = makeStyles(theme => ({
  iconWrapper: {
    backgroundColor: 'inherit',
    visibility: 'visible !important',
    margin: '0 6px',
    height: 36,
    width: 36,
    '&:hover': {
      backgroundColor: 'rgba(44, 53, 65, 0.28)',
      '& [class*=badge]': {
        borderColor: 'rgba(44, 53, 65, 0.28)',
      },
    },
    '&#my-account': {
      marginRight: 0,
      '&:hover': {
        backgroundColor: theme.colors.blueDarker,
      },
    },
  },
  badge: {
    marginTop: 10,
    marginRight: 14,
    height: 14,
    width: 14,
    borderRadius: '50%',
    border: `2px solid ${theme.colors.blueDarker}`,
    minWidth: 'unset',
    background: props => (props.loading ? 'white' : theme.colors.alertRed),
  },
  loadingIcon: {
    width: 12,
    height: 12,
    animation: '$rotate 1s linear infinite',
  },
  '@keyframes rotate': {
    to: {
      transform: 'rotate(-360deg)',
    },
  },
}))

function MessagesNotifications(props) {
  const {
    currentBrand,
    messages,
    notifications,
    unreadMessageCount,
    unreadNotificationCount,
    messagesLoading: loading,
  } = props
  const detectMobile = useMobileDetect()
  const isUnder720 = detectMobile.isUnder720()

  const [openMessagesMenu, setOpenMessagesMenu] = useState(false)

  const messagesRef = useRef(null)

  const css = useStyles({ loading })

  const onMessagesMenuClick = onMenuClick(setOpenMessagesMenu, 'Messages Menu')

  const LoadingIcon = () => (
    <div className={css.loadingIcon}>
      <img alt="loading" src={LoadingIconSvg} />
    </div>
  )

  return (
    <Badge
      badgeContent={loading ? <LoadingIcon /> : unreadMessageCount + unreadNotificationCount}
      color="error"
      variant={loading ? null : 'dot'} // To allow icon to render
      classes={{ badge: css.badge }}
    >
      <IconButton
        onClick={openMessagesMenu ? null : onMessagesMenuClick}
        color={openMessagesMenu ? 'primary' : 'secondary'}
        className={css.iconWrapper}
        ref={messagesRef}
      >
        <img src={openMessagesMenu ? blueMailIcon : mailIcon} alt="Messages" />
        <MessagesMenu
          anchorEl={messagesRef.current}
          messages={messages}
          notifications={notifications}
          open={openMessagesMenu}
          onClose={onMessagesMenuClick}
          unreadMessageCount={unreadMessageCount}
          unreadNotificationsCount={unreadNotificationCount}
          isMobile={isUnder720}
          currentBrandId={currentBrand.id}
        />
      </IconButton>
    </Badge>
  )
}

MessagesNotifications.propTypes = {
  currentBrand: PropTypes.object.isRequired,
  messages: PropTypes.array.isRequired,
  unreadMessageCount: PropTypes.number.isRequired,
  notifications: PropTypes.array.isRequired,
  unreadNotificationCount: PropTypes.number.isRequired,
  messagesLoading: PropTypes.bool.isRequired,
}

export default React.memo(MessagesNotifications)

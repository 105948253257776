import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { SMSEditorContext } from 'contexts/SMSEditorContext'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import ToolTipWrapper from 'components/shared/ToolTipWrapper'

import { ReactComponent as ImageIcon } from 'images/icons/sms-editor-icons/insert-image-v2.svg'
import InsertImageGIFModal from 'components/shared/InsertImageGIFModal'
import { P } from 'components/texts'

const useStyles = makeStyles(theme => ({
  wrapper: {
    backgroundColor: 'white',
    position: 'relative',
    margin: '0 6px',
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background .1s linear',
    cursor: 'pointer',
    borderRadius: 2,
    '&:hover': {
      background: theme.colors.crystal,
    },
  },
  picker: {
    zIndex: 1,
    position: 'absolute',
    background: 'white',
    top: '100%',
    left: 'calc(100% - 24px)',
    minWidth: 244,
    boxShadow: ({ open }) => (open ? theme.shadows[1] : 'none'),
    height: ({ open }) => (open ? 'auto' : '0'),
    overflow: ({ open }) => (open ? 'auto' : 'hidden'),
  },
  item: {
    margin: '4px 0',
    whiteSpace: 'nowrap',
    padding: '8px 12px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.silver,
    },
  },
}))

function ImageSelector() {
  const {
    imageOptions,
    actions: { onSetImages },
  } = React.useContext(SMSEditorContext)
  const [open, setOpen] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const css = useStyles({ open })

  const onSubmit = media => {
    setShowModal(false)
    onSetImages(media)
  }

  // either go dropdown or just modal
  const handleOpening = () => {
    if (imageOptions) setOpen(true)
    else setShowModal(true)
  }

  const handleClick = item => {
    if (item === 'insert media') setShowModal(true)
    else onSubmit(item)

    setOpen(false)
  }

  // eslint-disable-next-line react/prop-types
  const Item = ({ label, onClick }) => (
    <div className={css.item} onClick={onClick}>
      <P multiline>
        <i>{label}</i>
      </P>
    </div>
  )

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={css.wrapper}>
        <ToolTipWrapper isVirtualTheme text="Insert Image">
          <ImageIcon onClick={handleOpening} />
        </ToolTipWrapper>
        <div className={css.picker}>
          <Item label="Insert Media" onClick={() => handleClick('insert media')} />
          {imageOptions
            && imageOptions.map(item => (
              <Item label={item.label} onClick={() => handleClick(item.value)} />
            ))}
        </div>
        <InsertImageGIFModal
          onClose={() => setShowModal(false)}
          open={showModal}
          onSubmit={onSubmit}
        />
      </div>
    </ClickAwayListener>
  )
}

export default React.memo(ImageSelector)

import React, { useState, useEffect } from 'react'
import { Dialog, makeStyles } from '@material-ui/core'
import moment from 'moment-timezone'
import { useSelector } from 'react-redux'
import axios from 'axios'
import Text from 'components/texts/Text'
import Button from 'components/virtual/buttons/Button'

import proximaInfoBanner from 'images/banners/proxima-info-modal-banner.svg'

const useStyles = makeStyles(theme => ({
  modalBody: {
    display: 'flex',
    margin: 32,
  },
  bodyLeft: {
    width: 245,
    marginRight: 32,
  },
  bodyRight: {
    width: 451,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: 32,
    paddingRight: 32,
  },
  cancelButton: {
    color: theme.colors.blueBase,
    marginRight: 16,
    padding: 0,
    background: '#fff',
    border: 'none',
    fontFamily: 'Larsseit',
    cursor: 'pointer',
  },
}))

const openIcon = (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.6667 10.6667H1.33333V1.33333H6V0H1.33333C0.593333 0 0 0.6 0 1.33333V10.6667C0 11.4 0.593333 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667V6H10.6667V10.6667ZM7.33333 0V1.33333H9.72667L3.17333 7.88667L4.11333 8.82667L10.6667 2.27333V4.66667H12V0H7.33333Z" fill="#F4F7F8" />
  </svg>
)

function ProximaInfoModal() {
  const currentBrand = useSelector(state => state.currentBrand)
  const classes = useStyles()

  const [open, setOpen] = useState(false)
  const features = currentBrand.brandfeatures

  useEffect(() => {
    // check if loaded and brand is at least a 5 days old
    if (features && moment().diff(currentBrand.createdAt, 'days') >= 5) {
      // if modal was never closed before
      if (!features.proximaModalClosed) {
        setOpen(true)
        return
      }
      // if modal was closed more than 60 days ago
      const closedDate = moment(JSON.parse(features.proximaModalClosed).lastClosed)
      // check if it's been 60 days since the modal was last closed
      if (closedDate.isValid() && moment().diff(closedDate, 'days') >= 60) {
        setOpen(true)
      }
    }
  }, [currentBrand, features])

  const onClose = isClosedForever => {
    try {
      axios.post('/brands/set-flag/proximaModalClosed', {
        value: String(isClosedForever),
      })
    } catch (e) {
      // TODO: Add error handling
      console.error(e)
    }
    setOpen(false)
  }

  return (
    <Dialog classes={{ paper: classes.paper }} maxWidth={false} open={open}>
      <div className={classes.container}>
        <img src={proximaInfoBanner} className={classes.banner} alt="Proxima modal banner" />
        <div className={classes.modalBody}>
          <div className={classes.bodyLeft}>
            <Text size="24" style={{ fontWeight: 700 }}>
              Reduce CPAs by 30%+ with AI Audiences for Meta & TikTok
              <span role="img" aria-label="sparkle">✨</span>
            </Text>
          </div>
          <div className={classes.bodyRight}>
            Introducing Proxima: AI-powered ad targeting for social.
            <br />
            <br />
            Find new customers, lower acquisition costs,
            and scale your ads on social profitably with Proxima&apos;s AI Audiences.
          </div>
        </div>
        <div className={classes.buttons}>
          <button
            className={classes.cancelButton}
            type="button"
            onClick={() => onClose(false)}
          >
            I don&apos;t want lower CPAs
          </button>
          <a
            href="https://www.proxima.ai/dojomojo"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => onClose(true)}
          >
            <Button
              label={(
                <>
                  Learn More
                  &nbsp;
                  {openIcon}
                </>
              )}
              size="small"
            />
          </a>
        </div>
      </div>
    </Dialog>
  )
}

export default React.memo(ProximaInfoModal)

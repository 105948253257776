import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { Field as RFField } from 'react-final-form'
import {
  Input,
  TextAreaInput,
  SelectInput,
  DatePicker,
  DateRangePicker,
  Checkbox,
  KeyboardDatePicker,
  Switch,
} from 'components/inputs'
import {
  Input as VirtualInput,
  PasswordInput as VirtualPasswordInput,
  Textarea as VirtualTextarea,
} from 'components/virtual/inputs'
import { Tiny } from 'components/texts'

const useStyles = makeStyles(theme => ({
  formInputError: {
    '& div:first-child': {
      borderColor: theme.colors.alertRed,
    },
  },
  error: {
    marginTop: 4,
    height: 10,
  },
}))

const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)

function InputAdapter(props) {
  const {
    input,
    theme,
    meta,
    onChange,
    onFocus,
    onBlur,
    customClasses,
    disableErrorText,
    useComponentError,
    ...rest
  } = props
  const classes = useStyles()

  const Component = {
    text: theme === 'virtual' ? VirtualInput : Input,
    number: Input,
    textarea: theme === 'virtual' ? VirtualTextarea : TextAreaInput,
    select: SelectInput,
    date: DatePicker,
    daterange: DateRangePicker,
    checkbox: Checkbox,
    keyboardDatePicker: KeyboardDatePicker,
    switch: Switch,
    password: theme === 'virtual' && VirtualPasswordInput,
  }[input.type] || Input

  const onInputChange = value => {
    if (onChange) value = onChange(value)
    return input.onChange(value)
  }

  if (input.type === 'switch') rest.checked = input.value

  return (
    <>
      <Component
        {...input}
        {...rest}
        disableerror={meta.error && meta.touched}
        error={useComponentError && meta.touched ? meta.error : undefined}
        onChange={onInputChange}
        onBlur={() => {
          if (onBlur) onBlur(input)
          input.onBlur()
        }}
        onFocus={() => {
          if (onFocus) onFocus(input)
          input.onFocus()
        }}
        customClasses={{
          root: meta.error && meta.touched ? classes.formInputError : undefined,
          ...customClasses,
        }}
      />
      {!disableErrorText && (
        <div className={classes.error}>
          {meta.error && meta.touched ? <Tiny alertRed>{meta.error}</Tiny> : null}
        </div>
      )}
    </>
  )
}

InputAdapter.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  customClasses: PropTypes.object,
  disableErrorText: PropTypes.bool,
  useComponentError: PropTypes.bool,
  theme: PropTypes.string,
}

InputAdapter.defaultProps = {
  onChange: null,
  onFocus: null,
  onBlur: null,
  customClasses: {},
  disableErrorText: false,
  useComponentError: false,
  theme: 'default',
}

const FormInput = React.forwardRef(({ type, validations, ...rest }, ref) => (
  <div ref={ref}>
    <RFField
      type={type}
      component={InputAdapter}
      validate={composeValidators(...validations)}
      {...rest}
    />
  </div>
))

FormInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
  placeholder: PropTypes.string,
  validations: PropTypes.array,
}

FormInput.defaultProps = {
  type: 'text',
  label: '',
  placeholder: '',
  validations: [],
}

export default FormInput

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import LinkDetectorModal from '../LinkDetectorModal'

const useStyles = makeStyles(theme => ({
  link: {
    position: 'relative',
    '& > a:first-child': {
      cursor: 'text',
      color: ({ show }) => (show ? theme.colors.coral : theme.colors.azure),
    },
  },
}))

function EditorLinkWrapper(props) {
  const { minifiedUrl, url, children } = props

  const timeoutRef = React.useRef(null)
  const spanRef = React.useRef(null)
  const [show, setShow] = useState(false)
  const [shown, setShown] = useState(false)
  const css = useStyles({ show })

  const onLinkClick = e => {
    if (show) return

    if (minifiedUrl) {
      e.preventDefault()
      setShown(false)
    }
    setShow(true)
  }

  const onMouseHover = () => {
    clearTimeout(timeoutRef.current)
    if (shown) return
    setShow(true)
  }

  const onMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setShow(false)
      setShown(true)
    }, 1000)
  }

  const placement = spanRef.current && spanRef.current.getBoundingClientRect()

  return (
    <span ref={spanRef} className={css.link} onMouseOver={onMouseHover} onMouseLeave={onMouseLeave}>
      <a onClick={onLinkClick} href={url}>
        {children}
      </a>
      <LinkDetectorModal
        placement={placement}
        show={show}
        originalLink={minifiedUrl && url}
        link={url}
        onClose={() => setShow(false)}
      />
    </span>
  )
}

EditorLinkWrapper.propTypes = {
  url: PropTypes.string.isRequired,
  minifiedUrl: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default React.memo(EditorLinkWrapper)

import React, { useEffect, useState, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import axios from 'axios'
import moment from 'moment-timezone'
import _ from 'lodash'

import { Form } from 'components/forms'
import { ToasterContext } from 'contexts'

import WarningModal from 'views/mobile/sms-marketing/shared/modals/WarningModal'
import CreateLightboxModal from 'views/mobile/sms-marketing/CreateLightbox/CreateLightboxModal'

import {
  checkForInvalidShortUrlLink,
  NEWYORK_TIMEZONE,
  NEWYORK_TIMEZONE_ABBR,
  INTEGRATIONS_LIST_DEFAULT_CRITERIA,
} from 'utils/sms'

import useHasIntegration from 'hooks/useHasIntegration'
import useBrandBank from 'hooks/useBrandBank'
import { Spinner } from 'components/spinners'
import { DeleteFollowupsModal } from 'components/shared/SmsFollowupsComponents'
import ShortUrlWarningModal from './ShortUrlWarningModal'

const timezone = NEWYORK_TIMEZONE
const timezoneAbbreviation = NEWYORK_TIMEZONE_ABBR

const MESSAGE_DEFAULT_VALUES = {
  id: `initial-dummy-${Date.now()}`,
  name: 'Initial Message',
  coupon_code: '',
  url_link: '',
  custom_sms_message:
    "You're on the list! Stay tuned for early access to our best promotions and new drops. Here's a little something to hold you over: use code *|COUPON_CODE|* for 10% off your next purchase 😎.\n\nShop now: ADD STORE URL HERE",
  image_url: null,
  image_obj: null,

  // control props
  sent_date: null,
  isEditing: true,
}

const FOLLOWUPS_DEFAULT_VALUES = {
  custom_sms_message:
    'Have you checked out our best sellers?\n\nUse *|COUPON_CODE|* for 10% off before they sell out...again!\n\n👉 Get yours: ADD STORE URL HERE',
  unit_of_time: 'day',
  send_interval: 2,
  isFollowup: true,
}

const LIGHTBOX_SEND_SETTINGS_DEFAULT_VALUES = {
  name: 'Welcome Message',
  status: 'draft',
  timezone,
  timezone_abbreviation: timezoneAbbreviation,

  // others
  followup_criteria: 'all recipients',
  followupCriteriaEnabled: true, // TODO: remove once the ability to disable lightbox is enabled
}

const FOLLOWUP_CRITERIA_OPTIONS = [
  { label: 'All Original Recipients', value: 'all recipients' },
  { label: "Hasn't clicked", value: 'not clicked' },
  {
    label: "Hasn't purchased",
    value: 'not purchased',
    disabled: true,
    link: '/app/profile/brand/data-integrations?v=d',
    linkLabel: 'Add Data Integrations',
  },
  {
    label: "Hasn't clicked or purchased",
    value: 'not clicked or purchased',
    disabled: true,
    link: '/app/profile/brand/data-integrations?v=d',
    linkLabel: 'Add Data Integrations',
  },
  {
    label: "Clicked, but hasn't purchased",
    value: 'clicked but not purchased',
    disabled: true,
    link: '/app/profile/brand/data-integrations?v=d',
    linkLabel: 'Add Data Integrations',
  },
]

const SUBMIT_ID = 'bulkSendingMessagesId'

function formatDataBeforeSending(items) {
  const {
    additionalData, smsSend, followups, settings,
  } = items

  const PLACEHOLDER_REGEX = /https:\/\/.*\.(do?jo?mo?jo?)\.io\/.{8}/g

  ;[smsSend, ...followups].forEach(messageObj => {
    if (PLACEHOLDER_REGEX.test(messageObj.custom_sms_message)) {
      messageObj.custom_sms_message = messageObj.custom_sms_message.replace(
        PLACEHOLDER_REGEX,
        '*|LINK|*'
      )
    }

    if (messageObj.isFollowup) messageObj.criteria = settings.followup_criteria
    else Object.assign(messageObj, additionalData)

    messageObj.safe_send = settings.safe_send
    messageObj.coupon_code = messageObj.coupon_code || null

    delete messageObj.isEditing
  })

  const dataToSend = {
    ...settings,
    ...smsSend,
    name: settings.name,
    followups: followups.map(followup => _.omit(followup, ['status', 'isFollowup'])),
  }

  delete dataToSend.followup_criteria
  delete dataToSend.isEditing

  return dataToSend
}

const CreateLightbox = props => {
  const {
    history,
    match,
    data: outsideData,
    // isNew, // TODO: use this ?
    // id: builderId, // TODO: use this ?
    isBuilder,
  } = props

  const { hasIntegrations, isLoading: isIntegrationLoading, integrations } = useHasIntegration()
  const { isLoading: isBankLoading, bank: smsBank } = useBrandBank()
  const { lightboxId } = match.params
  const { showToaster } = useContext(ToasterContext)
  const currentBrand = useSelector(state => state.currentBrand)
  const FOLLOWUPS_LIMIT = +(currentBrand.features.followupMessagesLimit || 3)

  const { id: brandId } = currentBrand

  /* form */
  const [form, setForm] = useState(null)

  /* messages */
  const [message, setMessage] = useState(MESSAGE_DEFAULT_VALUES)
  const [followups, setFollowups] = useState([])
  const [lightboxSendSettings, setLightboxSendSettings] = useState(
    LIGHTBOX_SEND_SETTINGS_DEFAULT_VALUES
  )
  const [dataToSave, setDataToSave] = useState(null)

  /* control values */
  const [isLoading, setIsLoading] = useState(true)
  const [isValid, setIsValid] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isReview, setIsReview] = useState(false) // review page
  const [showUrlWarning, setShowUrlWarning] = useState(false) // TinyURL, bitly, etc. modal

  const [showWarning, setShowWarning] = useState(false)

  /* others */
  const [oldCoupons, setOldCoupons] = useState([])
  const [messageId, setMessageId] = useState(null)
  const [followupDeleteId, setFollowupDeleteId] = useState(null)

  const creditsCount = smsBank.credits ? smsBank.credits.count + smsBank.credits.freeCount : 0

  const title = isReview
    ? 'Review'
    : isBuilder
      ? `Signup Form: ${lightboxSendSettings.name}`
      : lightboxSendSettings.name

  const currentCoupons = [message, ...followups].map(({ coupon_code }) => coupon_code)
  const filteredOldCoupons = oldCoupons.filter(coupon => !currentCoupons.includes(coupon))
  // TODO: Come up with how to only show this once per campaign
  const hasInvalidShortUrl = checkForInvalidShortUrlLink([message, ...followups])
  const defaultFollowupCriteria = INTEGRATIONS_LIST_DEFAULT_CRITERIA.some(
    store => integrations[store]
  )
    ? 'not purchased'
    : 'not clicked'

  const loading = isLoading || isIntegrationLoading || isBankLoading

  const checkIsOldCoupon = coupon => filteredOldCoupons.includes(coupon)

  // TODO: to be discussed
  const haveEnoughCredits = () => creditsCount > 0

  const triggerCouponValidation = () => {
    function forceCouponValidation(name) {
      form.batch(() => {
        form.focus(`${name}.coupon_code`)
        form.blur(`${name}.coupon_code`)
      })
    }
    forceCouponValidation('message')
    followups.forEach((followup, i) => forceCouponValidation(`followups[${i}]`))
  }

  const processData = data => {
    const messageFields = [
      'id',
      'name',
      'coupon_code',
      'url_link',
      'custom_sms_message',
      'image_url',
      'sent_date',
      // 'analytics', // TODO: Do I even need this?
      'status',
    ]
    const sendSettingsData = {
      ...lightboxSendSettings,
      ..._.pick(data, ['name', 'safe_send', 'status']),
    }

    const smsSendData = {
      ...message,
      ..._.pick(data, messageFields),
      isEditing: false,
      isDisabled: haveEnoughCredits(), // data.status === 'active',
    }

    const followupsData = data.followups.map(followup => {
      sendSettingsData.followup_criteria = followup.criteria.criteria
      return {
        ..._.pick(followup, messageFields.concat(['status', 'sent_date', 'criteria'])),
        ..._.pick(followup.criteria, ['unit_of_time', 'send_interval']),
        isEditing: false,
        isFollowup: true,
        isDisabled: haveEnoughCredits(), // followup.status === 'active',
      }
    })

    sendSettingsData.timezone = timezoneAbbreviation

    if (!followupsData.length) sendSettingsData.followup_criteria = defaultFollowupCriteria

    setMessage(smsSendData)
    setFollowups(followupsData)
    setLightboxSendSettings(sendSettingsData)
    setIsReview(sendSettingsData.status !== 'draft')
    setMessageId(smsSendData.id || null)
  }

  const onClose = () => {
    // sends data back to builder
    if (isBuilder) history.push(match.path, { lightboxSmsSend: dataToSave, fromModal: true })
    else {
      history.push({
        pathname: '/sms-marketing/acquisition-tools',
        refresh: Date.now(),
      })
    }
  }

  const exposeValues = formValues => {
    setIsValid(formValues.valid)
    setMessage(formValues.values.message)
    setLightboxSendSettings(formValues.values.lightboxSendSettings)
    setFollowups(formValues.values.followups)
  }

  const onSubmitData = async (additionalData = {}) => {
    setIsSaving(true)
    showToaster('Saving Changes...', { timeout: 10000 })
    try {
      const dataToSend = formatDataBeforeSending({
        smsSend: message,
        followups,
        settings: lightboxSendSettings,
        additionalData,
      })
      // create or update if messageId
      const promise = !messageId
        ? axios.post('/sms/sms-send?type=lightbox', dataToSend)
        : axios.put(`/sms/sms-send/${messageId}?type=lightbox`, dataToSend)
      const { data } = await promise
      processData(data)
      showToaster(`Lightbox message successfully ${messageId ? 'saved' : 'created'}.`)
      setIsSaving(!messageId) // if creating, set as false
      setIsSaving(false)
      return data
    } catch (err) {
      showToaster('An error occurred while saving your message. Please try again later', {
        type: 'warning',
      })
    }
    setIsSaving(false)
    return null
  }

  const saveAndExit = async additionalData => {
    await onSubmitData(additionalData)
    onClose()
  }

  const onChangeCoupon = async (id, coupon) => {
    try {
      if (lightboxSendSettings.status === 'active') {
        await axios.put(`/sms/sms-send/${id}/coupon?type=lightbox`, { coupon_code: coupon })
        showToaster('Coupon changes successfully saved.')
      }
    } catch (err) {
      showToaster('An error occurred while saving your message. Please try again later', {
        type: 'warning',
      })
    }
  }

  const editMessage = msgId => {
    setIsReview(false) // back to editing page

    const setIsEditingValue = msg => ({ ...msg, isEditing: msg.id === msgId })

    setFollowups(prevFollowups => prevFollowups.map(setIsEditingValue))
    setMessage(setIsEditingValue)
  }

  const addFollowup = () => {
    if (followups.length + 1 > FOLLOWUPS_LIMIT) return

    if (!isValid) {
      triggerCouponValidation()
      showToaster('Please fix the messages before adding followups', { type: 'warning' })
      return
    }

    const emptyFollowup = {
      ...MESSAGE_DEFAULT_VALUES,
      ...FOLLOWUPS_DEFAULT_VALUES,
      isEditing: true,
      canDelete: true,
      name: `Follow-up Message #${followups.length + 1}`,
      id: `followup-dummy-${Date.now()}`,
    }

    const closeEdit = msg => ({ ...msg, isEditing: false })

    setFollowups(prevFollowups => [...prevFollowups.map(closeEdit), emptyFollowup])
    setMessage(closeEdit)
  }

  const deleteFollowups = async () => {
    const index = followups.findIndex(({ id }) => followupDeleteId === id)
    const followupsToDelete = followups.slice(index).map(({ id }) => id)
    const newFollowups = followups.filter(({ id }) => !followupsToDelete.includes(id))

    const isMessageIdDummy = typeof messageId === 'string' && messageId.indexOf('initial-dummy-') > -1
    try {
      setFollowupDeleteId(null)
      setIsSaving(true)
      if (messageId && !isMessageIdDummy) {
        await axios.delete(`/sms/sms-send/${messageId}/followups?type=lightbox`, {
          data: { followupsIds: followupsToDelete },
        })
        showToaster(`Followup${followupsToDelete.length > 1 ? 's' : ''} successfully deleted`)
      }
      setFollowups(newFollowups)
    } catch (err) {
      if (messageId) {
        showToaster('Error while trying to delete followups. Please try again later.', {
          type: 'warning',
        })
      }
    }
    setIsSaving(false)
  }

  const goToPreview = () => {
    editMessage(-1) // close all messages, avoid sending data
    setIsReview(true)
  }

  const processInvalidUrl = () => {
    const invalidUrlKey = messageId
      ? `sms_invalid_url_lightbox_${messageId}`
      : 'sms_invalid_url_lightbox_placeholder'

    const ignoreFor = localStorage.getItem(invalidUrlKey) // either a date string or null
    const showModal = ignoreFor ? moment().isAfter(moment(ignoreFor)) : true

    if (showModal) {
      if (!showUrlWarning && hasInvalidShortUrl) {
        setShowUrlWarning(true)
        return true
      }
    }

    return false
  }

  const onProcessSubmit = async (isSave = false) => {
    setShowWarning(false)

    if (processInvalidUrl()) return null // just show modal, dont process submit
    if (isReview) {
      if (isSave && lightboxSendSettings.status === 'active') return setShowWarning(true)
      if (isBuilder) return onClose()

      return saveAndExit({ status: 'active' })
    }

    if (isBuilder) {
      const data = formatDataBeforeSending({
        smsSend: message,
        followups,
        settings: lightboxSendSettings,
      })

      setDataToSave(data)
    }

    return goToPreview()
  }

  const onProcessShortUrlWarning = (proceed = false) => () => {
    setShowUrlWarning(false)
    if (proceed) {
      const [invalidUrlKey, expiry] = messageId
        ? [`sms_invalid_url_lightbox_${messageId}`, moment().add(9999, 'day')]
        : ['sms_invalid_url_lightbox_placeholder', moment().add(1, 'hour')]

      localStorage.setItem(invalidUrlKey, expiry)
      form.submit()
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)

      const promises = [
        axios.get(`/sms/sms-send/coupons/${brandId}`),
        lightboxId
          && !isBuilder
          && axios
            .get(`/sms/sms-send/${lightboxId}?type=lightbox&followups=true`)
            .then(({ data: smsSend }) => processData(smsSend)),
      ].filter(Boolean)

      const [{ data: coupons }] = await Promise.all(promises)

      setOldCoupons(coupons)
      setIsLoading(false)
    }

    if (isBuilder) {
      if (outsideData) processData(outsideData)
      setIsLoading(false)
      return
    }

    if (!isIntegrationLoading) fetchData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandId, lightboxId, outsideData, isIntegrationLoading])

  // special case, not sure why doing setLightboxSendSettings inside
  // deleteFollowup method doesn't update the object
  useEffect(() => {
    if (!followups.length) {
      setLightboxSendSettings(oldSettings => ({
        ...oldSettings,
        followup_criteria: defaultFollowupCriteria,
      }))
    }
  }, [followups.length, defaultFollowupCriteria])

  const values = {
    haveEnoughCredits: haveEnoughCredits(),
    title,
    hasIntegrations,
    message, // parent message
    followups,
    lightboxSendSettings,

    oldCoupons,
    isValid,
    isReview,
    isLoading: loading,
    isSaving,
    isBuilder,
    creditsCount,
    SUBMIT_ID,
    FOLLOWUP_CRITERIA_OPTIONS: hasIntegrations
      ? FOLLOWUP_CRITERIA_OPTIONS.map(({ label, value }) => ({ label, value }))
      : FOLLOWUP_CRITERIA_OPTIONS,
    FOLLOWUPS_LIMIT,

    autorefill: smsBank.autorefill,
  }

  const actions = {
    onChangeCoupon, // edits coupon when sent/scheduled
    onClose,
    checkIsOldCoupon,
    goToPreview,
    goToEdit: () => setIsReview(false),

    // Followups actions
    addFollowup,
    setToDelete: setFollowupDeleteId,

    // General actions
    editMessage,
    triggerCouponValidation,
  }

  if (loading) return <Spinner />

  return (
    <Form
      validateOnBlur
      // withSubmit={SUBMIT_ID} // TODO: For some reason, this is not working anymore...
      onSubmit={onProcessSubmit}
      initialValues={{ ...values, actions }}
      exposeValues={exposeValues}
      exposeForm={setForm}
    >
      <CreateLightboxModal saveAndExit={() => saveAndExit()} onClose={onClose} />
      <WarningModal
        show={showWarning}
        onClose={() => setShowWarning(false)}
        onSubmit={() => onProcessSubmit()}
        submitCopy="Update"
        title="Are you sure you want to update your message?"
        body="Your latest changes will immediately go live once you publish your updates."
      />
      <DeleteFollowupsModal
        show={Boolean(followupDeleteId)}
        onClose={() => setFollowupDeleteId(false)}
        onDelete={deleteFollowups}
        plural={followups.findIndex(({ id }) => id === followupDeleteId) !== followups.length - 1}
      />
      <ShortUrlWarningModal
        show={showUrlWarning}
        onCancel={onProcessShortUrlWarning(true)}
        onConfirm={onProcessShortUrlWarning(false)}
      />
    </Form>
  )
}

CreateLightbox.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  // isNew: PropTypes.bool,
  isBuilder: PropTypes.bool,
  // id: PropTypes.number,
}

CreateLightbox.defaultProps = {
  // isNew: false,
  isBuilder: false,
  // id: undefined,
}

export default React.memo(withRouter(CreateLightbox))

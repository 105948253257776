import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { makeStyles } from '@material-ui/core/styles'

import Text from 'components/texts/Text'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import CreateLightbox from 'containers/sms-marketing/CreateLightbox'
import SMSWelcomeDripRequiredBanner from 'views/mobile/lightbox-builder/banners/SMSWelcomeDripRequiredBanner'
import LightboxBuilderFullScreenSection from '../LightboxBuilderFullScreenSection'
import NoDripPreview from './NoDripPreview'
import DripPreview from './DripPreview'

import lightbulbIcon from '../../../../../../images/icons/messages-lightbulb.svg'

const useStyles = makeStyles({
  factoid: {
    display: 'grid',
    gridTemplateColumns: '12px 1fr',
    gridColumnGap: '10px',
    marginTop: 22,
    '& img': {
      objectFit: 'contain',
    },
  },
})

const TOOLTIP_TEXT = 'Automatically engage new subscribers who opt-in to SMS with a text message welcome series. Messages are crafted and sent directly through DojoMojo.'
const ACTION_LABEL = 'Learn More'
const KB_LINK = 'https://help.dojomojo.com/hc/en-us/articles/4412570364045'
const FACTOID = 'SMS welcome messages generate 8x more revenue than an email welcome series on average.'

function LightboxBuilderSMSWelcomeMessage() {
  const tooltip = {
    action: () => window.open(KB_LINK, '_blank'),
    actionLabel: ACTION_LABEL,
    text: TOOLTIP_TEXT,
  }
  const { search, state } = useLocation()
  const {
    id,
    isNew,
    values: { lightboxSmsSend },
    actions: { setLightboxSmsSendData },
  } = React.useContext(BuilderContainerContext)

  const css = useStyles()

  useEffect(() => {
    // TODO: clear history state cache?
    if (state?.fromModal && state?.lightboxSmsSend) setLightboxSmsSendData(state.lightboxSmsSend)
  }, [state]) // eslint-disable-line react-hooks/exhaustive-deps

  const { edit } = queryString.parse(search)

  const showLightboxWelcomeModal = edit === 'true'

  return (
    <div>
      <SMSWelcomeDripRequiredBanner />
      <LightboxBuilderFullScreenSection
        title="SMS Welcome Series"
        subtitle="Welcome new subscribers with an SMS flow that provides a warm introduction to your business and reaches customers through their preferred communication channel."
        tooltip={tooltip}
        factoid={(
          <div className={css.factoid}>
            <img src={lightbulbIcon} alt="lightbulb icon" />
            <Text charcoalLight>{FACTOID}</Text>
          </div>
        )}
      >
        {lightboxSmsSend ? <DripPreview /> : <NoDripPreview />}
        {showLightboxWelcomeModal && (
          <CreateLightbox id={id} isNew={isNew} data={lightboxSmsSend} isBuilder />
        )}
      </LightboxBuilderFullScreenSection>
    </div>
  )
}

export default React.memo(LightboxBuilderSMSWelcomeMessage)

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Popover } from '@material-ui/core'
import cn from 'classnames'

import { P, H4 } from 'components/texts'
import { SearchInput } from 'components/inputs'

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: 24,
    display: 'flex',
    alignItems: 'center',
    padding: '0 4px',
    position: 'relative',
    transition: 'background .1s linear',
    cursor: 'default',
    borderRadius: 2,
    '&:hover': {
      backgroundColor: theme.colors.silver,
    },
  },
  arrow: {
    marginLeft: 10,
    marginRight: 6,
    borderTop: '4px solid black',
    borderRight: '4px solid transparent',
    borderLeft: '4px solid transparent',
    transition: 'transform .1s linear',
  },
  menuOpen: {
    backgroundColor: theme.colors.silver,
  },
  open: {
    transform: 'rotate(180deg)',
  },

  // DropdownItems
  popover: {
    minWidth: 144,
    maxHeight: 296,
    overflowY: 'auto',
    padding: ({ simple }) => (simple ? 0 : 20),
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    width: ({ simple }) => (simple ? '100%' : 'calc(100% + 24px)'),
    margin: ({ simple }) => (simple ? '4px 0' : '4px 0 0 -12px'),
    height: 30,
    padding: '0 12px',
    transition: 'background .1s linear',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.silver,
    },
  },
  divider: {
    borderBottom: `1px solid ${theme.colors.silver}`,
  },
  category: {
    '&:not(:last-child)': {
      marginBottom: 24,
    },
  },
  input: {
    marginBottom: 24,
    '& > *': {
      maxHeight: 40,
    },
  },
}))

function DropdownItems(props) {
  const {
    open, options, onClick, anchor,
  } = props
  const css = useStyles({ simple: Array.isArray(options) })
  const [search, setSearch] = useState('')

  let items

  if (!options) return null

  /*
    options signature:
    options: [
      {label: String, value: String}
    ]

    or

    options: {
      Category: [
        {label: String, value:}
      ],
    }
  */

  const renderer = item => (
    <div key={item.label} className={css.item} onClick={() => onClick(item.value)}>
      <P multiline>{item.label}</P>
    </div>
  )

  if (Array.isArray(options)) items = options.map(renderer)
  else {
    const onInputClick = e => {
      e.stopPropagation()
      e.preventDefault()
    }

    let itemsToShow = {}

    if (search.length) {
      Object.keys(options).forEach(category => {
        const filteredItems = options[category].filter(({ label }) =>
          new RegExp(search, 'i').test(label))

        if (filteredItems.length) itemsToShow[category] = filteredItems
      })
    } else itemsToShow = options

    items = Object.keys(itemsToShow).map(category => (
      <div key={category} className={css.category}>
        <H4 multiline>
          <strong>{category}</strong>
        </H4>
        <div className={css.divider} />
        {itemsToShow[category].map(renderer)}
      </div>
    ))

    items.unshift(
      <div key="dropdown-search-input" className={css.input} onClick={onInputClick}>
        <SearchInput value={search} onChange={setSearch} />
      </div>
    )
  }

  return (
    <Popover
      open={open}
      classes={{ paper: css.popover }}
      anchorEl={anchor}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
    >
      {items}
    </Popover>
  )
}

DropdownItems.propTypes = {
  open: PropTypes.bool.isRequired,
  options: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]).isRequired,
  onClick: PropTypes.func.isRequired,
  anchor: PropTypes.object,
}

DropdownItems.defaultProps = {
  anchor: null,
}

function DropdownButtonV2(props) {
  const { label, onSelect, options } = props
  const [open, setOpen] = useState(false)
  const css = useStyles()
  const ref = React.useRef(null)

  const onOpen = e => {
    e.stopPropagation()

    setOpen(isOpen => !isOpen)
  }

  const onItemSelect = item => onSelect(item)

  const onClickAway = () => setOpen(false)

  return (
    <div ref={ref} className={cn(css.wrapper, { [css.menuOpen]: open })} onClick={onOpen}>
      <P>
        <i>{label}</i>
      </P>
      <div className={cn(css.arrow, { [css.open]: open })} />
      <DropdownItems
        options={options}
        open={open}
        onClick={onItemSelect}
        anchor={ref.current}
        onClickAway={onClickAway}
      />
    </div>
  )
}

DropdownButtonV2.propTypes = {
  label: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  options: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]).isRequired,
}

DropdownButtonV2.defaultProps = {
  onSelect() {},
}

export default React.memo(DropdownButtonV2)

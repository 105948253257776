import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import cn from 'classnames'

import Text from 'components/texts/Text'

const useStyles = makeStyles(theme => ({
  bullet: {
    border: `1px solid ${theme.colors.skyBase}`,
    borderRadius: '50%',
    height: 12,
    marginRight: 12,
    width: 12,
  },
  parameter: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 4,
  },
  title: {
    marginBottom: 6,
  },
  verified: {
    background: theme.colors.blueBase,
    border: `1px solid ${theme.colors.blueBase}`,
  },
}))

function PasswordHelper(props) {
  const { value } = props

  const classes = useStyles()

  const [min8characters, setMin8characters] = useState(false)
  const [hasUppercaseCharacter, setHasUppercaseCharacter] = useState(false)
  const [hasADigit, setHasADigit] = useState(false)
  const [hasASpecialCharacter, setHasASpecialCharacter] = useState(false)

  const parameters = [
    {
      label: 'Minimum 8 Characters',
      set: setMin8characters,
      value: min8characters,
      verify: input => input.length >= 8,
    },
    {
      label: 'An Uppercase Character',
      set: setHasUppercaseCharacter,
      value: hasUppercaseCharacter,
      verify: input => /[A-Z]/.test(input),
    },
    {
      label: 'A Digit',
      set: setHasADigit,
      value: hasADigit,
      verify: input => /\d/.test(input),
    },
    {
      label: 'A Special Character [!?@#\\&/*+]',
      set: setHasASpecialCharacter,
      value: hasASpecialCharacter,
      verify: input => /(?=.*[!\\/?@#$&*+()^%])/.test(input),
    },
  ]

  const renderParameters = () =>
    parameters.map(parameter => (
      <div className={classes.parameter} key={parameter.label}>
        <div className={cn(classes.bullet, { [classes.verified]: parameter.value })} />
        <Text charcoalLight={!parameter.value} size={12}>
          {parameter.label}
        </Text>
      </div>
    ))

  useEffect(() => {
    const checkPassword = () => {
      parameters.forEach(parameter => {
        parameter.set(value && parameter.verify(value))
      })
    }
    checkPassword()
  }, [parameters, value])

  return (
    <>
      <div className={classes.title}>
        <Text size={14}>Passwords must include the following: </Text>
      </div>
      {renderParameters()}
    </>
  )
}

PasswordHelper.propTypes = {
  value: PropTypes.string.isRequired,
}

export default React.memo(PasswordHelper)

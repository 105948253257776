import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import useEditLightboxFields from 'hooks/lightbox-builder/useEditLightboxFields'

import NoESPDetectedBanner from 'views/mobile/lightbox-builder/banners/NoESPDetectedBanner'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import { EditDropdownField, EditTextField } from 'components/builder/editables'
import EditESPField from './EditESPField'

const useStyles = makeStyles({
  fields: {
    flex: 1,
    overflowY: 'scroll',
    paddingBottom: 48,
  },
})

function LightboxBuilderSetupFieldsEditMode() {
  const css = useStyles()
  const [input, actions] = useEditLightboxFields()
  const {
    values: { containsESPValue },
  } = React.useContext(BuilderContainerContext)

  const EditComponent = {
    dropdown: EditDropdownField,
    text: EditTextField,
  }[input?.type] || EditTextField

  if (!input) return null

  // dont show if EditESPField returns null
  const showNoESPDetectedBanner = input.name !== 'email' && containsESPValue

  return (
    <section className={css.fields}>
      {showNoESPDetectedBanner && <NoESPDetectedBanner />}
      <EditComponent input={input} actions={actions}>
        <EditESPField input={input} actions={actions} />
      </EditComponent>
    </section>
  )
}

export default React.memo(LightboxBuilderSetupFieldsEditMode)

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Text from 'components/texts/Text'

const useStyles = makeStyles(theme => ({
  colorSection: {
    '&:not(:last-of-type)': {
      borderBottom: `1px solid ${theme.colors.skyBase}`,
      marginBottom: 32,
      paddingBottom: 32,
    },
  },
  colorItem: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 24,
  },
  color: {
    cursor: 'pointer',
    marginRight: 16,
    height: 36,
    width: 60,
    border: `1px solid ${theme.colors.skyBase}`,
    borderRadius: 4,
  },
}))

function LightboxBuilderThemeColorsSection(props) {
  const {
    label, colors, onColorClick, getColor,
  } = props

  const css = useStyles()

  return (
    <div className={css.colorSection}>
      <Text size={16} charcoalBase>
        {label}
      </Text>
      {Object.keys(colors).map(colorKey => (
        <div key={colorKey} className={css.colorItem}>
          <div
            className={css.color}
            onClick={onColorClick(colorKey)}
            style={{ backgroundColor: getColor(colorKey) }}
          />
          <Text size={16} charcoalBase>
            {colors[colorKey]}
          </Text>
        </div>
      ))}
    </div>
  )
}

LightboxBuilderThemeColorsSection.propTypes = {
  label: PropTypes.object.isRequired,
  colors: PropTypes.arrayOf(PropTypes.object).isRequired,
  onColorClick: PropTypes.func.isRequired,
  getColor: PropTypes.func.isRequired,
}

export default React.memo(LightboxBuilderThemeColorsSection)

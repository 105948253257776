import axios from 'axios'
import store from 'utils/reduxStore'

export const loadBrandClaims = () => async dispatch => {
  dispatch({ type: 'LOADING_BRAND_CLAIMS' })

  try {
    const { data: payload } = await axios.get('/brand-claims')
    return dispatch({ type: 'LOAD_BRAND_CLAIMS_SUCCESS', payload })
  } catch (err) {
    return dispatch({ type: 'LOAD_BRAND_CLAIMS_FAILURE', payload: err })
  }
}

export const getClaim = claim => {
  const { brandClaims } = store.getState()
  return brandClaims[claim]
}

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Slider from '@material-ui/core/Slider'

const useStyles = makeStyles(theme => ({
  root: {
    width: 'calc(100% - 5px)',
    margin: '0 auto 0 auto',
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: 'white',
    border: `1px solid ${theme.colors.azure}`,
    marginTop: -6,
    '&:focus,&:hover,&:active': {
      boxShadow: 'inherit',
    },
  },
  track: {
    height: 6,
    backgroundColor: theme.colors.azure,
  },
  rail: {
    height: 6,
    backgroundColor: theme.colors.cloudGrey,
  },
}))

function RangeSlider(props) {
  const {
    min, max, values, setValues,
  } = props

  const classes = useStyles()

  const handleChange = (e, newValues) => {
    setValues(newValues)
  }

  return (
    <div className={classes.root}>
      <Slider
        classes={classes}
        min={min}
        max={max}
        value={values.length === 2 ? values : values[0]}
        onChange={handleChange}
      />
    </div>
  )
}

RangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  values: PropTypes.array.isRequired,
  setValues: PropTypes.func.isRequired,
}

export default React.memo(RangeSlider)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import validator from 'validator'

export const generateRandom = n => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  return [...new Array(n)]
    .map(() => chars.charAt(Math.floor(Math.random() * chars.length)))
    .join('')
    .toLowerCase()
}

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.colors.azure,
  },
}))

const sanitizeWebsite = website => {
  if (!website) return 's'
  const [brandWebsite] = website.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('.')
  return brandWebsite
}

const getDummyUrl = website => `https://${sanitizeWebsite(website)}.djmj.io/${generateRandom(8)}`

const generateGIPHYAttribution = creator => `Posted using GIPHY | GIF by ${creator}`

// removes attribution when shown in editor/adds attribution when saved
export const processGIPHYAttribution = function processGIPHYAttribution(obj) {
  const { image_url, image_obj } = obj
  let { custom_sms_message: text } = obj

  ;[text] = text.split(`\n\n${generateGIPHYAttribution('')}`)

  if (image_url && validator.isURL(image_url, { require_protocol: true })) {
    const url = new URL(image_url)
    const isGIF = url.pathname.slice(-4) === '.gif'
    if (isGIF) {
      if (image_obj) {
        // if image_obj, then it's local
        const { original } = image_obj
        if (original?.user) {
          const { username } = original.user
          text = `${text}\n\n${generateGIPHYAttribution(username)}`
        }
      } else {
        const username = url.searchParams.get('username')
        // if username, then it's been already uploaded
        if (username) text = `${text}\n\n${generateGIPHYAttribution(username)}`
      }
    }
  }

  return text
}

export const replaceTags = (formValues, brandName, brandWebsite) => {
  const { coupon_code, custom_sms_message: msg, url_link } = formValues
  const mapping = {
    LINK: url_link ? getDummyUrl(brandWebsite) : '',
    COUPON_CODE: coupon_code || '',
    COUPON: coupon_code || '',
    BRAND_NAME: brandName,
    ABANDONED_CART_URL: '[ABANDONED_CART_URL]',
    ABANDONED_CART_TOTAL: '[ABANDONED_CART_TOTAL]',
    ABANDONED_PRODUCT_IMAGE: '',
    ABANDONED_PRODUCT_NAME: '[ABANDONED_PRODUCT_NAME]',
    ABANDONED_PRODUCT_PRICE: '[ABANDONED_PRODUCT_PRICE]',
    TRACKING_NUMBER: '[TRACKING_NUMBER]',
    TRACKING_URL: '[TRACKING_URL]',
    ORDER_STATUS_URL: '[ORDER_STATUS_URL]',
    ORDER_NUMBER: '[ORDER_NUMBER]',
  }

  const mergeTagRegex = new RegExp(`\\*\\|(${Object.keys(mapping).join('|')})\\|\\*`)

  const customMergeTagRegex = /\*\|[A-Z_]+_OR__([\w'!?]+)_\|\*/

  return msg
    .split(/\n/)
    .join(' *||* ')
    .split(/\s/)
    .map(textPart => {
      let match = mergeTagRegex.exec(textPart)
      if (match) {
        const [tag, tagKey] = match

        return textPart.replace(tag, mapping[tagKey])
      }

      // check for custom tag?
      match = customMergeTagRegex.exec(textPart)

      if (match) {
        const [, fallback] = match
        if (fallback.toLowerCase() === 'fallback') return ''

        return fallback.replace(/_/g, ' ')
      }

      return textPart
    })
    .join(' ')
}

export const hasUntrackedLink = text => {
  let hasLink = false
  let isUntrackedLink = false

  const words = text.split(' ')

  for (let i = 0; i < words.length; i++) {
    const word = words[i]
    if (validator.isURL(word)) {
      hasLink = true
      isUntrackedLink = !/do?jo?mo?jo?\.io|\*\|LINK\|\*/.test(word)

      if (isUntrackedLink) {
        break
      }
    }
  }

  return hasLink && isUntrackedLink
}

export default function generatePreview(...params) {
  const [formValues, brandName, brandWebsite] = params

  const message = {
    ...formValues,
    custom_sms_message: processGIPHYAttribution(formValues),
  }

  const text = replaceTags(message, brandName, brandWebsite)

  // eslint-disable-next-line react/prop-types
  function LinkItem({ value }) {
    const css = useStyles()

    return (
      <a href={formValues.url_link || value} rel="noopener noreferrer" target="_blank">
        <span className={css.link}>{` ${value}`}</span>
      </a>
    )
  }
  return text.split(/\s/).map((part, i) => {
    // Add new line to preview
    if (part === '*||*') return <br />

    const key = `${part}_${i}`.toString()
    if (validator.isEmail(part) || validator.isURL(part)) {
      const value = validator.isEmail(part) ? `mailto:${part}` : part || formValues.url_link
      return <LinkItem key={key} value={value} />
    }

    if (/\[[A-Z_]*]/.test(part)) {
      return (
        <span key={key} style={{ whiteSpace: 'nowrap' }}>
          {part.replace(/_/g, ' ')}
        </span>
      )
    }

    return <span key={key}>{` ${part}`}</span>
  })
}

import React, {
  useRef, useCallback, useEffect, useState,
} from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import { ToasterContext } from 'contexts'

function useFileUploader(uploadFile = false, limiter = () => {}) {
  const [file, setFile] = useState(null)
  const [file64, setFile64] = useState(null)
  const fileRef = useRef(null)

  const [loading, setLoading] = useState(false)
  const [url, setUrl] = useState('')
  const toaster = React.useContext(ToasterContext)

  const clear = useCallback(() => {
    setUrl('')
    setFile(null)
    setFile64(null)
    setLoading(false)
  }, [])

  const uploadAsset = useCallback(
    async data => {
      if (!limiter(data)) {
        toaster.showToaster('File too big', { type: 'warning' })
        return
      }

      setLoading(true)
      const formData = new FormData()
      formData.append('file', data, data.name)
      try {
        toaster.showToaster('Processing image', { type: 'default', timeout: 10000 })
        const { data: assetUrl } = await axios.post('/assets', formData)
        setUrl(assetUrl)
        setFile(data)
        toaster.closeToaster()
      } catch (err) {
        toaster.showToaster('Error while uploading file', { type: 'warning' })
      }
      setLoading(false)
    },
    [toaster] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const onFileChange = useCallback(
    ({ target }) => {
      const [value] = target.files
      setFile(value)
      if (uploadFile) {
        uploadAsset(value).then(clear)
        return
      }
      const reader = new FileReader()
      reader.readAsDataURL(value)
      reader.onload = () => setFile64(reader.result)
      reader.onerror = e => console.log(e)
    },
    [uploadFile, uploadAsset, clear]
  )

  const fileInput = (
    <input
      onChange={onFileChange}
      accept="image/x-png,image/gif,image/jpeg"
      type="file"
      hidden
      ref={fileRef}
    />
  )

  const openFileUploader = useCallback(() => {
    const { current } = fileRef

    current.click()
  }, [fileRef])

  useEffect(() => {
    const portalDiv = document.createElement('div')

    document.body.appendChild(portalDiv)
    ReactDOM.render(fileInput, portalDiv)

    return () => {
      ReactDOM.unmountComponentAtNode(portalDiv)
      document.body.removeChild(portalDiv)
    }
  }, [fileRef, fileInput])

  if (uploadFile) {
    return {
      url,
      loading,
      clear,
      file,
      file64,
      openFileUploader,
    }
  }

  return [file, openFileUploader, clear, file64]
}

export default useFileUploader

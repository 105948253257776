const usRegions = [
  {
    key: 'Northeast',
    defaultValue: 'All Northeast',
    options: [
      {
        key: 'All Northeast',
        label: 'All Northeast',
      },
      {
        key: 'CT',
        label: 'Connecticut',
      },
      {
        key: 'ME',
        label: 'Maine',
      },
      {
        key: 'MA',
        label: 'Massachusetts',
      },
      {
        key: 'NH',
        label: 'New Hampshire',
      },
      {
        key: 'NJ',
        label: 'New Jersey',
      },
      {
        key: 'NY',
        label: 'New York',
      },
      {
        key: 'PA',
        label: 'Pennsylvania',
      },
      {
        key: 'RI',
        label: 'Rhode Island',
      },
      {
        key: 'VT',
        label: 'Vermont',
      },
    ],
  },
  {
    key: 'South',
    defaultValue: 'All South',
    options: [
      {
        key: 'All South',
        label: 'All South',
      },
      {
        key: 'AL',
        label: 'Alabama',
      },
      {
        key: 'AR',
        label: 'Arkansas',
      },
      {
        key: 'DE',
        label: 'Delaware',
      },
      {
        key: 'DC',
        label: 'District of Columbia',
      },
      {
        key: 'FL',
        label: 'Florida',
      },
      {
        key: 'GA',
        label: 'Georgia',
      },
      {
        key: 'KY',
        label: 'Kentucky',
      },
      {
        key: 'LA',
        label: 'Louisiana',
      },
      {
        key: 'MD',
        label: 'Maryland',
      },
      {
        key: 'MS',
        label: 'Mississippi',
      },
      {
        key: 'NC',
        label: 'North Carolina',
      },
      {
        key: 'OK',
        label: 'Oklahoma',
      },
      {
        key: 'SC',
        label: 'South Carolina',
      },
      {
        key: 'TN',
        label: 'Tennessee',
      },
      {
        key: 'TX',
        label: 'Texas',
      },
      {
        key: 'VA',
        label: 'Virginia',
      },
      {
        key: 'WV',
        label: 'West Virginia',
      },
    ],
  },
  {
    key: 'Midwest',
    defaultValue: 'All Midwest',
    options: [
      {
        key: 'All Midwest',
        label: 'All Midwest',
      },
      {
        key: 'IL',
        label: 'Illinois',
      },
      {
        key: 'IN',
        label: 'Indiana',
      },
      {
        key: 'IA',
        label: 'Iowa',
      },
      {
        key: 'KS',
        label: 'Kansas',
      },
      {
        key: 'MI',
        label: 'Michigan',
      },
      {
        key: 'MN',
        label: 'Minnesota',
      },
      {
        key: 'MO',
        label: 'Missouri',
      },
      {
        key: 'NE',
        label: 'Nebraska',
      },
      {
        key: 'ND',
        label: 'North Dakota',
      },
      {
        key: 'OH',
        label: 'Ohio',
      },
      {
        key: 'SD',
        label: 'South Dakota',
      },
      {
        key: 'WI',
        label: 'Wisconsin',
      },
    ],
  },
  {
    key: 'West',
    defaultValue: 'All West',
    options: [
      {
        key: 'All West',
        label: 'All West',
      },
      {
        key: 'AK',
        label: 'Alaska',
      },
      {
        key: 'AZ',
        label: 'Arizona',
      },
      {
        key: 'CA',
        label: 'California',
      },
      {
        key: 'CO',
        label: 'Colorado',
      },
      {
        key: 'HI',
        label: 'Hawaii',
      },
      {
        key: 'ID',
        label: 'Idaho',
      },
      {
        key: 'MT',
        label: 'Montana',
      },
      {
        key: 'NV',
        label: 'Nevada',
      },
      {
        key: 'NM',
        label: 'New Mexico',
      },
      {
        key: 'OR',
        label: 'Oregon',
      },
      {
        key: 'UT',
        label: 'Utah',
      },
      {
        key: 'WA',
        label: 'Washington',
      },
      {
        key: 'WY',
        label: 'Wyoming',
      },
    ],
  },
]

export const usStates = [
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
]

export default usRegions

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory, useLocation } from 'react-router-dom'

import Text from 'components/texts/Text'
import { ReactComponent as DripsSvg } from 'images/lightbox-builder/drips.svg'
import VirtualTheme from 'components/virtual/VirtualTheme'
import Button from 'components/virtual/buttons/Button'

const useStyles = makeStyles(theme => ({
  noDrip: {
    padding: 16,
    display: 'flex',
    flexFlow: 'column',
    border: `1px solid ${theme.colors.skyBase}`,
    borderRadius: 4,
    alignItems: 'flex-end',
  },
  titles: {
    alignSelf: 'flex-start',
    '& > :first-child': {
      marginBottom: 4,
    },
  },
  image: {
    alignSelf: 'center',
    margin: '20px 0',
  },
}))

function NoDripPreview() {
  const css = useStyles()
  const history = useHistory()
  const { pathname: url } = useLocation()

  const onCreateDrip = () => history.push(`${url}?edit=true`)

  return (
    <div className={css.noDrip}>
      <div className={css.titles}>
        <Text size={16} charcoalBase>
          SMS Welcome Series Preview
        </Text>
        <Text charcoalLight>No messages created.</Text>
      </div>
      <DripsSvg className={css.image} />
      <VirtualTheme>
        <Button size="smaller" label="Create Series" onClick={onCreateDrip} />
      </VirtualTheme>
    </div>
  )
}

export default React.memo(NoDripPreview)

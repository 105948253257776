/* eslint-disable react/no-danger */
import React from 'react'
import { makeStyles } from '@material-ui/core'

import { H4 } from 'components/texts'

const useStyles = makeStyles(() => ({
  bottom: { marginBottom: 24 },
  bottomLess: { marginBottom: 16 },
}))

const BODY_HIGHLIGHT = 'Thanks for using DojoMojo! We’ve updated our <a href="/home/terms-of-use" rel="noreferrer" target="_blank">Terms of Use</a> to clarify how we provide services to our customers.'
const BODY = null

const UpdatedTerms = () => {
  const css = useStyles()

  return (
    <>
      <div className={css.bottom}>
        <H4 multiline>
          <span dangerouslySetInnerHTML={{ __html: BODY_HIGHLIGHT }} />
        </H4>
      </div>
      {BODY && (
        <div className={css.botomLess}>
          <H4 multiline>
            <span dangerouslySetInnerHTML={{ __html: BODY }} />
          </H4>
        </div>
      )}
    </>
  )
}

export default UpdatedTerms

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

import Close from '@material-ui/icons/Close'

const useStyles = makeStyles(theme => ({
  banner: {
    alignItems: props => props.align,
    borderRadius: 4,
    boxShadow: '0px 2px 4px rgba(90, 116, 129, 0.1)',
    display: 'flex',
    justifyContent: 'space-between',
    padding: props => props.padding || 16,
    width: props => (props.fullWidth ? '100%' : 'auto'),
  },
  children: {
    width: '100%',
  },
  close: {
    cursor: 'pointer',
    height: 20,
    width: 20,
  },
  closeIcon: {
    paddingLeft: 16,
  },
  green: {
    backgroundColor: theme.colors.greenLightest,
    border: `1px solid ${theme.colors.greenLight}`,
  },
  grey: {
    backgroundColor: theme.colors.skyLight,
    border: `1px solid ${theme.colors.charcoalLighter}`,
  },
  indigo: {
    backgroundColor: theme.colors.indigoLightest,
    border: `1px solid ${theme.colors.indigoLight}`,
  },
  orange: {
    backgroundColor: theme.colors.orangeLightest,
    border: `1px solid ${theme.colors.orangeLight}`,
  },
  purple: {
    backgroundColor: theme.colors.purpleLightest,
    border: `1px solid ${theme.colors.purpleBase}`,
  },
  red: {
    backgroundColor: theme.colors.redLightest,
    border: `1px solid ${theme.colors.redLight}`,
  },
  yellow: {
    backgroundColor: theme.colors.yellowLightest,
    border: `1px solid ${theme.colors.yellowLight}`,
  },
}))

function Banner(props) {
  const {
    align, children, close, color, fullWidth, onClose, open, padding,
  } = props

  const classes = useStyles({ align, fullWidth, padding })

  return (
    open && (
      <div className={cn(classes.banner, classes[color])}>
        <div className={classes.children}>{children}</div>
        {close && (
          <div className={classes.closeIcon}>
            <Close className={classes.close} onClick={onClose} />
          </div>
        )}
      </div>
    )
  )
}

Banner.propTypes = {
  align: PropTypes.string,
  children: PropTypes.any,
  color: PropTypes.oneOf(['green', 'grey', 'indigo', 'orange', 'purple', 'red', 'yellow']),
  close: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  padding: PropTypes.number,
}

Banner.defaultProps = {
  align: 'stretch',
  padding: 16,
  children: null,
  close: false,
  color: 'grey',
  fullWidth: false,
  onClose: () => {},
  open: true,
}

export default React.memo(Banner)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import NotInterested from '@material-ui/icons/NotInterested'

import VirtualTheme from 'components/virtual/VirtualTheme'

import HoverPopup from 'components/modals/HoverPopup'
import Button from 'components/virtual/buttons/Button'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import UnpublishedFormRequiredModal from '../modals/UnpublishedFormRequiredModal'
import LightboxBuilderSaveAndPublishButton from '../shared/LightboxBuilderSaveAndPublishButton'
import LightboxBuilderPublishModal from '../modals/LightboxBuilderPublishModal'
import LightboxBuilderUpdateConfirmationModal from '../modals/LightboxBuilderUpdateConfirmationModal'

const useStyles = makeStyles(theme => ({
  headerButtons: {
    display: 'flex',
    alignItems: 'center',
    '& > :not(:first-child)': {
      marginLeft: 12,
    },
  },
  saveLabel: { color: theme.colors.charcoalDarker },
  saveBtn: {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: theme.colors.skyLight,
    },
  },
  previewLabel: { color: ({ disabled }) => (disabled ? theme.colors.skyDarker : 'white') },
  previewBtn: {
    backgroundColor: `${theme.colors.navyDarker} !important`,
    border: ({ disabled }) => `1px solid ${disabled ? theme.colors.skyDarker : 'white'}`,
  },
  popoverContainer: {
    display: 'flex',
    position: 'relative',
  },
  popoverDisabledIcon: {
    color: theme.colors.redBase,
  },
}))

function LightboxBuilderNavbarActionButtons() {
  const { actions, values, lightboxSettings } = React.useContext(BuilderContainerContext)
  const settings = lightboxSettings.getSettings()
  const { onSaveLightbox } = actions
  const { isLightboxDataValid } = values

  const testRef = React.useRef(null)
  const isTestReady = isLightboxDataValid && settings.id
  const css = useStyles({ disabled: !isTestReady })

  const isActive = settings.status === 'active'

  const PublishModal = isActive
    ? LightboxBuilderUpdateConfirmationModal
    : LightboxBuilderPublishModal

  const onTestLightboxClick = () => {
    if (isTestReady) {
      window.open(
        `${process.env.REACT_APP_ENV_LIGHTBOX_URL}/index.html?preview=${settings.id}`,
        '_blank'
      )
    }
  }

  const onClose = () => actions.setIsPublish(false)

  const save = !isActive && (
    <Button
      classes={{ label: css.saveLabel, root: css.saveBtn }}
      size="small"
      label="Save"
      color="secondary"
      onClick={onSaveLightbox}
    />
  )

  const testPreview = (
    <div className={css.popoverContainer} ref={testRef}>
      <Button
        classes={{ label: css.previewLabel, root: css.previewBtn }}
        size="small"
        label="Test"
        color="secondary"
        disabled={!isTestReady}
        onClick={onTestLightboxClick}
      />
      <HoverPopup
        action="click"
        hide={isTestReady}
        ref={testRef}
        icon={<NotInterested className={css.popoverDisabledIcon} />}
        type="warning"
        title="Test Not Available"
        body="Review any errors and save your signup form before testing."
      />
    </div>
  )

  return (
    <VirtualTheme>
      <div className={css.headerButtons}>
        {testPreview}
        {save}
        <LightboxBuilderSaveAndPublishButton />
      </div>

      {/* TODO: might want to move this out of here */}
      <UnpublishedFormRequiredModal />
      <PublishModal show={values.isPublish} onClose={onClose} />
    </VirtualTheme>
  )
}

export default React.memo(LightboxBuilderNavbarActionButtons)

import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, makeStyles } from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'

import Button from 'components/virtual/buttons/Button'
import Text from 'components/texts/Text'

const useStyles = makeStyles({
  dialog: {
    maxWidth: 588,
  },
  buttons: {
    alignSelf: 'flex-end',
    marginTop: 32,
  },
  close: {
    cursor: 'pointer',
    position: 'absolute',
    right: 12,
    top: 12,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: 32,
    '& h6': {
      letterSpacing: '0.44px',
    },
  },
  title: {
    marginBottom: 8,
  },
})

function ConfirmationModal(props) {
  const {
    cancelLabel,
    close,
    onCancel,
    onClose,
    onSubmit,
    open,
    submitLabel,
    text,
    title,
    ...rest
  } = props

  const classes = useStyles()

  return (
    <Dialog open={open} {...rest} classes={{ paper: classes.dialog }}>
      {close && <CloseOutlined className={classes.close} onClick={onClose} />}
      <div className={classes.content}>
        <div className={classes.title}>
          <Text charcoalDarker size={20}>
            {title}
          </Text>
        </div>
        {React.isValidElement(text) ? (
          text
        ) : (
          <Text charcoalLight size={16}>
            {text}
          </Text>
        )}
        {submitLabel && (
          <div className={classes.buttons}>
            {cancelLabel && (
              <Button label={cancelLabel} onClick={onCancel} size="small" type="text" />
            )}
            <Button label={submitLabel} onClick={onSubmit} size="small" />
          </div>
        )}
      </div>
    </Dialog>
  )
}

ConfirmationModal.propTypes = {
  cancelLabel: PropTypes.string,
  close: PropTypes.bool,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  submitLabel: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
}

ConfirmationModal.defaultProps = {
  cancelLabel: '',
  close: false,
  onCancel: () => {},
  onClose: () => {},
}

export default React.memo(ConfirmationModal)

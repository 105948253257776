import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { PageSubtitle, PageTitle } from 'components/builder/titles'

const useStyles = makeStyles({
  container: {
    height: '100%',
    padding: '32px 8px 0',
    display: 'flex',
    overflow: 'hidden',
  },
  title: {
    flex: 1,
    '& > :first-child': {
      marginBottom: 4,
    },
  },
  content: {
    flex: 1,
    paddingLeft: 24,
  },
})

function LightboxBuilderFullScreenSection(props) {
  const {
    title, subtitle, tooltip, factoid, children,
  } = props
  const css = useStyles()

  return (
    <div className={css.container}>
      <div className={css.title}>
        <PageTitle textProps={{ size: 16 }} text={title} tooltip={tooltip} />
        <PageSubtitle text={subtitle} />
        {factoid}
      </div>
      <div className={css.content}>{children}</div>
    </div>
  )
}

LightboxBuilderFullScreenSection.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  tooltip: PropTypes.object,
  children: PropTypes.node.isRequired,
  factoid: PropTypes.node,
}

LightboxBuilderFullScreenSection.defaultProps = {
  tooltip: null,
  factoid: null,
}

export default React.memo(LightboxBuilderFullScreenSection)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import FormPagesTitle from './FormPagesTitle'
import AddPageButton from './AddPageButton'

const useStyles = makeStyles({
  topSection: {
    display: 'flex',
    justifyContent: 'space-between',
  },
})

const PAGE_TITLE_COPY_MAP = {
  popup: {
    key: 'Page',
    tooltip: {
      text:
        'These are the sections that will appear in your signup form. You can create a multi-page form by adding more pages to your submission form.',
      label: 'Form Best Practices',
      kbLink: 'https://help.dojomojo.com/hc/en-us/articles/360055721872-Editing-Your-Lightbox',
    },
  },
  embed: {
    key: 'Step',
    tooltip: {
      text:
        'These are the sections that will appear in your form. You can create a multi-step form by adding more steps.',
      label: 'Form Best Practices',
      kbLink: 'https://help.dojomojo.com/hc/en-us/articles/4412480978445/',
    },
  },
}

function FormPagesTitleAddButton() {
  const { lightboxSettings } = React.useContext(BuilderContainerContext)
  const css = useStyles()

  const { settings } = lightboxSettings.getSettings()

  let formType = settings.type
  if (formType === 'lightbox') formType = 'popup'
  const pageCopy = PAGE_TITLE_COPY_MAP[formType]

  return (
    <div className={css.topSection}>
      <FormPagesTitle pageCopy={pageCopy} />
      <AddPageButton pageCopy={pageCopy} />
    </div>
  )
}

export default React.memo(FormPagesTitleAddButton)

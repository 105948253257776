import { useCallback, useState } from 'react'
import axios from 'axios'

import ESP_MAP from '../utils/espMap'

const isIntegrationActive = integration =>
  (integration?.details ? !!integration.activated && !!integration.verified : false)

// ATTENTION: This only gets the latest enabled ESP, but highest ID
const getCurrentEnabledESP = integrations => integrations.find(isIntegrationActive) || {}

function mapIntegrations(currentBrandIntegrations = [], opts = {}) {
  const { type = 'lightbox' } = opts

  const integrations = {
    supported: {},
    integrated: {},
  }

  const connectedIntegrations = currentBrandIntegrations.reduce(
    (allIntegrations, integration) => ({
      ...allIntegrations,
      [integration.integration_type]: integration,
    }),
    {}
  )

  ESP_MAP.forEach(esp => {
    let listKey = 'supported'
    let integrationData
    if (esp.key in connectedIntegrations) {
      listKey = 'integrated'
      integrationData = connectedIntegrations[esp.key]
    }

    if (listKey === 'supported' && !esp[`${type}Enabled`]) return

    integrations[listKey][esp.key] = { ...esp, integrationData }
  })

  return integrations
}

async function refreshESPLists(integration) {
  const { integration_type, details } = integration
  // Note: this isn't good and should really be refactored to not include the keys in the query
  // It's how we are currently fetching lists for brand/campaign integrations.
  // All should be updated ASAP
  const urls = {
    'active-campaign': `/active-campaign/fetch-lists?key=${details.apiKey}&url=${details.apiUrl}`,
    aweber: `/aweber/fetch-lists?id=${details.account_id}&token=${details.oauth_token}&secret=${details.oauth_token_secret}`,
    'campaign-monitor': `/campaign-monitor/fetch-lists?key=${encodeURIComponent(details.apiKey)}`,
    'conde-nast': `/conde-nast/fetch-lists?key=${details.siteCode}`,
    'convert-kit': `/convert-kit/fetch-lists?key=${details.apiKey}`,
    freshmail: `/freshmail/fetch-lists?key=${details.apiKey}&secret=${details.apiSecret}`,
    hubspot: `/hubspot/fetch-lists?key=${details.apiKey}`,
    infusionsoft: `/infusionsoft/fetch-lists?key=${details.access_token}`,
    iterable: `/iterable/fetch-lists?key=${details.apiKey}`,
    klaviyo: `/klaviyo/fetch-lists?key=${details.apiKey}`,
    mailchimp: `/mailchimp/fetch-lists?dc=${details.dc}&mailchimpAccessToken=${details.mailchimpAccessToken}`,
    mailgun: `/mailgun/fetch-lists?key=${details.apiKey}`,
    mailjet: `/mailjet/fetch-lists?key=${details.apiKey}&secret=${details.apiSecret}`,
    maropost: `/maropost/fetch-lists?id=${details.accountId}&key=${details.apiKey}`,
    postup: `/postup/fetch-lists?user=${details.username}&pass=${details.password}`,
    sailthru: `/sailthru/fetch-lists?key=${details.apiKey}&secret=${details.apiSecret}`,
    sendgrid: `/sendgrid/fetch-lists?version=${details.marketingVersion}&key=${details.apiKey}`,
  }

  try {
    const { data: lists } = await axios.get(urls[integration_type])
    return lists
  } catch (e) {
    return null
  }
}

function useESPIntegration(opts = {}) {
  const { type = 'lightbox' } = opts
  const [brandIntegrations, setBrandIntegrations] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [loaded, setLoaded] = useState(false)

  const brandIntegrationsList = mapIntegrations(brandIntegrations, opts)

  const canSupportESP = espKey => {
    if (!espKey) return false
    let integration = ESP_MAP.find(espObj => espObj.key === espKey)
    if (integration) return !!integration[`${type}Enabled`]

    integration = getCurrentEnabledESP(brandIntegrations)
    const integrationKey = integration.integration_type
    const esp = ESP_MAP.find(espObj => espObj.key === integrationKey) || {}
    return !!esp[`${type}Enabled`]
  }

  const getESPInfoById = async integrationId => {
    let esp = null

    Object.values(brandIntegrationsList.integrated).forEach(integration => {
      if (esp) return
      if (integration.integrationData.id === integrationId) esp = integration
    })

    if (esp.integrationData.details) {
      const lists = await refreshESPLists(esp.integrationData)
      esp.integrationData.details = { ...esp.integrationData.details, lists }
    }
    return esp
  }

  const fetchIntegrations = useCallback(async () => {
    setIsLoading(true)
    const { data } = await axios.get(`/integrations/all/${type}`)

    setBrandIntegrations(data.sort((a, b) => b.id - a.id))
    setLoaded(true)
    setIsLoading(false)
  }, [type])

  const fetchIntegrationInfo = useCallback(async integrationId => {
    setIsLoading(true)
    const { data } = await axios.get(`/integrations/${integrationId}`)
    setIsLoading(false)
    return data
  }, [])

  return {
    // values
    loaded,
    isLoading,
    hasESPIntegration: brandIntegrations.length > 0,
    integratedESPs: brandIntegrationsList.integrated,
    supportedESPs: brandIntegrationsList.supported,
    currentEnabledESP: getCurrentEnabledESP(brandIntegrations),

    // actions
    canSupportESP,
    getESPInfoById,
    fetchIntegrations,
    fetchIntegrationInfo,
    onRefreshIntegrations: () => fetchIntegrations(),
  }
}

export default useESPIntegration

import activeCampaignLogo from 'images/esp-logos/updated-active-campaign-logo.png'
// import aweberLogo from 'images/esp-logos/updated-aweber-logo.png'
import blueshiftLogo from 'images/esp-logos/updated-blueshift-logo.png'
import campaignMonitorLogo from 'images/esp-logos/updated-campaign-monitor-logo.png'
import condeNastLogo from 'images/esp-logos/updated-conde-nast-logo.png'
import convertKitLogo from 'images/esp-logos/updated-convert-kit-logo.png'
import dripLogo from 'images/esp-logos/drip-logo.png'
import experianLogo from 'images/esp-logos/updated-experian-logo.png'
import freshmailLogo from 'images/esp-logos/updated-freshmail-logo.png'
import hubspotLogo from 'images/esp-logos/updated-hubspot-logo.png'
import infusionsoftLogo from 'images/esp-logos/updated-infusionsoft-logo.png'
import iterableLogo from 'images/esp-logos/updated-iterable-logo.png'
import klaviyoLogo from 'images/esp-logos/updated-klaviyo-logo.png'
import mailchimpLogo from 'images/esp-logos/updated-mailchimp-logo.png'
import mailgunLogo from 'images/esp-logos/updated-mailgun-logo.png'
import mailjetLogo from 'images/esp-logos/updated-mailjet-logo.png'
import maropostLogo from 'images/esp-logos/updated-maropost-logo.png'
import omnisendLogo from 'images/esp-logos/omnisend-logo.png'
import postupLogo from 'images/esp-logos/updated-postup-logo.png'
import retentionScienceLogo from 'images/esp-logos/retention-science-logo.png'
import sailthruLogo from 'images/esp-logos/updated-sailthru-logo.png'
import salesforceLogo from 'images/esp-logos/updated-salesforce-logo.png'
import salesforceMarketingCloudLogo from 'images/esp-logos/salesforce-marketing-cloud-logo.png'
import sendgridLogo from 'images/esp-logos/updated-sendgrid-logo.png'
import zapierLogo from 'images/esp-logos/updated-zapier-logo.png'

export const getIntegrationUrl = (integration, type = 'lists') => {
  const urls = {
    'active-campaign': `/active-campaign/fetch-${type}?key=${integration.apiKey}&url=${integration.apiUrl}`,
    aweber: `/aweber/fetch-${type}?id=${integration.account_id}&token=${integration.oauth_token}&secret=${integration.oauth_token_secret}`,
    'campaign-monitor': `/campaign-monitor/fetch-${type}?key=${encodeURIComponent(
      integration.apiKey
    )}`,
    'conde-nast': `/conde-nast/fetch-${type}?key=${integration.siteCode}`,
    'convert-kit': `/convert-kit/fetch-${type}?key=${integration.apiKey}`,
    freshmail: `/freshmail/fetch-${type}?key=${integration.apiKey}&secret=${integration.apiSecret}`,
    hubspot: `/hubspot/fetch-${type}?key=${integration.apiKey}`,
    infusionsoft: `/infusionsoft/fetch-${type}?key=${integration.access_token}`,
    iterable: `/iterable/fetch-${type}?key=${integration.apiKey}`,
    klaviyo: `/klaviyo/fetch-${type}?key=${integration.apiKey}`,
    mailchimp: `/mailchimp/fetch-${type}?dc=${integration.dc}&mailchimpAccessToken=${integration.mailchimpAccessToken}`,
    mailgun: `/mailgun/fetch-${type}?key=${integration.apiKey}`,
    mailjet: `/mailjet/fetch-${type}?key=${integration.apiKey}&secret=${integration.apiSecret}`,
    maropost: `/maropost/fetch-${type}?id=${integration.accountId}&key=${integration.apiKey}`,
    postup: `/postup/fetch-${type}?user=${integration.username}&pass=${integration.password}`,
    sailthru: `/sailthru/fetch-${type}?key=${integration.apiKey}&secret=${integration.apiSecret}`,
    sendgrid: `/sendgrid/fetch-${type}?version=${integration.marketingVersion}&key=${integration.apiKey}`,
  }

  return urls[integration.integration_type]
}

export default [
  {
    key: 'active-campaign',
    label: 'Active Campaign',
    img: activeCampaignLogo,
    lightboxEnabled: true,
  },
  // {
  //   key: 'aweber',
  //   label: 'AWeber',
  //   img: aweberLogo,
  //   lightboxEnabled: true,
  // },
  {
    key: 'blueshift',
    label: 'Blueshift',
    img: blueshiftLogo,
    lightboxEnabled: false,
  },
  {
    key: 'campaign-monitor',
    label: 'Campaign Monitor',
    img: campaignMonitorLogo,
    lightboxEnabled: false,
  },
  {
    key: 'conde-nast',
    label: 'Condé Nast',
    img: condeNastLogo,
    lightboxEnabled: false,
  },
  {
    key: 'convert-kit',
    label: 'Convert Kit',
    img: convertKitLogo,
    lightboxEnabled: true,
  },
  {
    key: 'drip',
    label: 'Drip',
    img: dripLogo,
    lightboxEnabled: true,
  },
  {
    key: 'experian',
    label: 'Experian CheetahMail',
    img: experianLogo,
    lightboxEnabled: false,
  },
  {
    key: 'freshmail',
    label: 'FreshMail',
    img: freshmailLogo,
    lightboxEnabled: false,
  },
  {
    key: 'hubspot',
    label: 'Hubspot',
    img: hubspotLogo,
    lightboxEnabled: true,
  },
  {
    key: 'infusionsoft',
    label: 'InfusionSoft',
    img: infusionsoftLogo,
    lightboxEnabled: false,
  },
  {
    key: 'iterable',
    label: 'Iterable',
    img: iterableLogo,
    lightboxEnabled: true,
  },
  {
    key: 'klaviyo',
    label: 'Klaviyo',
    img: klaviyoLogo,
    lightboxEnabled: true,
  },
  {
    key: 'mailchimp',
    label: 'MailChimp',
    img: mailchimpLogo,
    lightboxEnabled: true,
  },
  {
    key: 'mailgun',
    label: 'Mailgun',
    img: mailgunLogo,
    lightboxEnabled: false,
  },
  {
    key: 'mailjet',
    label: 'Mailjet',
    img: mailjetLogo,
    lightboxEnabled: false,
  },
  {
    key: 'maropost',
    label: 'Maropost',
    img: maropostLogo,
    lightboxEnabled: false,
  },
  {
    key: 'omnisend',
    label: 'Omnisend',
    img: omnisendLogo,
    lightboxEnabled: true,
  },
  {
    key: 'postup',
    label: 'Postup',
    img: postupLogo,
    lightboxEnabled: false,
  },
  {
    key: 'retention-science',
    label: 'Retention Science',
    img: retentionScienceLogo,
    lightboxEnabled: true,
  },
  {
    key: 'sailthru',
    label: 'Sailthru',
    img: sailthruLogo,
    lightboxEnabled: true,
  },
  {
    key: 'salesforce',
    label: 'Salesforce',
    img: salesforceLogo,
    lightboxEnabled: false,
  },
  {
    key: 'salesforce-marketing-cloud',
    label: 'Salesforce Marketing Cloud',
    img: salesforceMarketingCloudLogo,
    lightboxEnabled: false,
  },
  {
    key: 'sendgrid',
    label: 'Sendgrid',
    img: sendgridLogo,
    lightboxEnabled: true,
  },
  {
    key: 'zapier',
    label: 'Zapier',
    img: zapierLogo,
    lightboxEnabled: true,
  },
].sort((a, b) => a.key - b.key)

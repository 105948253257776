const initState = {
  currentBrandLoading: true,
  belt: 'white',
}

const completedProfileFields = ['facebookCoverPhoto', 'description']
const completedProfileSocialFields = [
  'facebookUrl',
  'twitterHandle',
  'instagramHandle',
  'pinterestHandle',
  'youtubeHandle',
]

const profileComplete = brand => {
  if (brand.profileComplete) {
    return true
  }

  return (
    completedProfileFields.every(item => !!brand[item])
    && completedProfileSocialFields.every(item => !!brand[item])
  )
}

export default (state = initState, action) => {
  const { type, payload = {} } = action

  switch (type) {
    // loaders
    case 'CURRENT_BRAND_LOADING':
    case 'BRAND_RECOMMENDATIONS_LOADING':
      return { ...state, currentBrandLoading: true }

    case 'CURRENT_BRAND_LOAD': {
      return {
        ...state,
        currentBrandLoading: false,
        ...payload,
        profileComplete: profileComplete(payload),
      }
    }

    case 'BRAND_RECOMMENDATIONS_LOAD': {
      return {
        ...state,
        currentBrandLoading: false,
        ...payload,
      }
    }

    case 'CURRENT_BRAND_FAILED':
    case 'BRAND_RECOMMENDATIONS_FAILED':
      return { ...state, currentBrandLoading: false }
    case 'ACCEPT_BRAND_TERMS':
      return { ...state, has_accepted_latest_terms: true }

    default:
      return state
  }
}

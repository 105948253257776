import React from 'react'
import PropTypes from 'prop-types'
import {
  AppBar, Toolbar, IconButton, withStyles,
} from '@material-ui/core'
import { ExitToApp } from '@material-ui/icons'

import { H4, P } from 'components/texts'

import history from 'utils/getHistory'

const styles = theme => ({
  root: {
    [theme.breakpoints.down('md')]: {
      height: 64,
    },
  },
  paper: {
    border: 'none',
    backgroundColor: 'white',
  },
  grow: {
    flexGrow: 1,
    '& > p': {
      color: theme.colors.ink,
    },
  },
  brandMenu: {
    display: 'flex',
    minHeight: 200,
    '& > div': {
      flex: 1,
      alignItems: 'center',
    },
  },
  icon: { width: 'unset', backgroundColor: 'white' },
  iconText: {
    color: theme.colors.ink,
    '& p': {
      paddingTop: 4,
    },
  },
})

function CampaignBuilderNavbar({ classes }) {
  return (
    <AppBar position="absolute" classes={{ root: classes.paper }}>
      <Toolbar classes={{ root: classes.root }}>
        <div className={classes.grow}>
          <H4 multiline>Sweepstakes Builder</H4>
        </div>
        <IconButton
          disableRipple
          onClick={() => history.push('/')}
          classes={{ label: classes.iconText, root: classes.icon }}
        >
          <ExitToApp />
          <P>Exit</P>
        </IconButton>
      </Toolbar>
    </AppBar>
  )
}

CampaignBuilderNavbar.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default React.memo(withStyles(styles)(CampaignBuilderNavbar))

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { CheckCircleOutline, InfoOutlined } from '@material-ui/icons'
import cn from 'classnames'

import Text from 'components/texts/Text'
import InputWithAction from 'components/virtual/inputs/InputWithAction'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import ExpandableSection from './ExpandableSection'

const useStyles = makeStyles(theme => ({
  content: {
    padding: 20,
  },
  summary: {
    paddingInlineStart: 16,
    marginBlockStart: 5,
    '& > li': {
      marginBottom: 4,
      fontFamily: 'Larsseit-Medium, sans-serif',
      '& span': {
        fontFamily: 'Larsseit, sans-serif',
        position: 'relative',
        left: 4,
      },
    },
  },
  icon: {
    border: `1px solid ${theme.colors.blueBase}`,
    backgroundColor: theme.colors.blueLightest,
    borderRadius: '50%',
    height: 30,
    width: 30,
    display: 'grid',
    placeContent: 'center',
    '& h4': {
      lineHeight: 1,
    },
  },
  input: {
    marginTop: 44,
    maxWidth: 414,
  },
  validation: {
    display: 'flex',
    whiteSpace: 'nowrap',
    alignItems: 'center',
  },
  validationIcon: {
    width: 16,
    marginRight: 4,
    '& + p': {
      paddingTop: 2,
    },
  },
  red: { color: theme.colors.redDarkest },
  green: { color: theme.colors.greenDarker },
}))

function ESPCustomFieldTag(props) {
  const { open, onToggle } = props
  const { lightboxSettings, actions } = React.useContext(BuilderContainerContext)
  const { onValidateESPValue } = actions
  const settings = lightboxSettings.getSettings()
  const [validationValue, setValidationValue] = useState(null)
  const css = useStyles()

  const espDojoProperty = settings?.espSettings.espDojoProperty

  const icon = (
    <div className={css.icon}>
      <Text size={24} blueBase>
        2
      </Text>
    </div>
  )

  const topText = 'To help keep the contacts in your ESP organized, DojoMojo will attach a “DojoMojo Embed” tag to each new subscriber who signs up through your form. All you need to do is create a custom field or property in your ESP (in the appropriate list) so that your ESP can neatly categorize the tagged contacts.'
  const summaryText = [
    'Create a custom text field value or property in your ESP. We suggest calling it something like "Source.”',
    'Whichever label you choose, paste it below and hit <strong>Validate</strong>.',
    'Everyone who subscribes through your form will be auto-tagged with a custom value ("DojoMojo Embed"), which you\'ll see populate under the "Source" field in your ESP.',
  ]

  const onESPValueChange = ({ target }) => {
    settings.espSettings = { espDojoProperty: target.value }
  }

  const onValidateClick = async () => {
    const isValid = await onValidateESPValue(espDojoProperty)
    setValidationValue(isValid ? 'valid' : 'invalid')
  }

  const getValidationMessage = () => {
    if (!validationValue || !espDojoProperty) return ''
    let Icon = CheckCircleOutline
    let textProps = { greenDarker: true }
    let text = 'Connection successful!'
    let iconColor = 'green'

    if (validationValue === 'invalid') {
      iconColor = 'red'
      Icon = InfoOutlined
      textProps = { redDarkest: true }
      text = 'We could not find a matching field in your ESP.  Enter the ESP value and validate the connection.'
    }

    return (
      <span className={css.validation}>
        <Icon className={cn(css.validationIcon, css[iconColor])} />
        <Text size={12} {...textProps}>
          {text}
        </Text>
      </span>
    )
  }

  return (
    <ExpandableSection
      text="Optional: Use a Custom Field to Tag New Contacts"
      icon={icon}
      open={open}
      onToggle={onToggle}
    >
      <div className={css.content}>
        <Text charcoalLight>
          {topText}
          <br />
          <br />
          <strong>Here&apos;s What to Do:</strong>
          <ol className={css.summary}>
            {summaryText.map(text => (
              <li key={text}>
                <span dangerouslySetInnerHTML={{ __html: text }} />
              </li>
            ))}
          </ol>
        </Text>
        <div className={css.input}>
          <InputWithAction
            value={settings.espSettings.espDojoProperty}
            onChange={onESPValueChange}
            buttonAction={onValidateClick} // TODO:
            buttonLabel="Validate"
            label="ESP Value"
            placeholder="FIELD_VALUE"
            helperText={getValidationMessage()}
          />
        </div>
      </div>
    </ExpandableSection>
  )
}

ESPCustomFieldTag.propTypes = {
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
}

export default React.memo(ESPCustomFieldTag)

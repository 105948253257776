// https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
export default function copyToClipboard(text) {
  if (window.clipboardData && window.clipboardData.setData) {
    // IE specific code path to prevent textarea being shown while dialog is visible.
    window.clipboardData.setData('Text', text)
    return true
  }

  if (navigator.clipboard?.writeText) {
    navigator.clipboard.writeText(text)
    return true
  }

  let success = false
  if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
    const textarea = document.createElement('textarea')
    textarea.textContent = text
    textarea.style.visibility = 'none'
    textarea.style.position = 'fixed' // Prevent scrolling to bottom of page in MS Edge.
    document.body.appendChild(textarea)
    textarea.select()
    try {
      document.execCommand('copy') // Security exception may be thrown by some browsers.
      success = true
    } catch (ex) {
      console.warn('Copy to clipboard failed.', ex)
    } finally {
      document.body.removeChild(textarea)
    }
  }

  return success
}

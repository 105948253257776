import React from 'react'
import PropTypes from 'prop-types'
import { Typography, withStyles } from '@material-ui/core'

const styles = theme => {
  const colors = Object.entries(theme.colors).reduce(
    (obj, [key, val]) => ({ ...obj, [key]: { color: val } }),
    {}
  )

  return {
    text: {
      fontSize: 11,
      lineHeight: 11,
      fontFamily: 'Larsseit-Light',
    },
    multiline: {
      lineHeight: '20px',
    },
    ellipsis: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    obscure: {
      filter: 'blur(3px)',
      userSelect: 'none',
    },
    ...colors,
  }
}

function Smaller({ children, classes, ...props }) {
  const classnames = Object.keys(props)
    .map(k => props[k] && classes[k])
    .join(' ')

  return <Typography className={`${classes.text} ${classnames}`}>{children}</Typography>
}

Smaller.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  classes: PropTypes.object.isRequired,
}

export default React.memo(withStyles(styles)(Smaller))

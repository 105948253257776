import axios from 'axios'

import { setAnalyticsBrand } from 'utils/analytics'
import { checkPaidStatus } from 'utils/currentBrand'

export const loadCurrentBrand = () => async dispatch => {
  dispatch({ type: 'CURRENT_BRAND_LOADING' })

  try {
    const [{ data: payload }, { data: customer }] = await Promise.all([
      axios.get('/current-brand-future'),
      axios.get('/customer'),
    ])

    const brandInfo = { ...payload, customer }

    const { statusOk, redirectTo, conditionals = {} } = await checkPaidStatus(brandInfo)

    if (!statusOk) return window.location.replace(`/app${redirectTo}`)

    setAnalyticsBrand(payload)

    return dispatch({
      type: 'CURRENT_BRAND_LOAD',
      payload: {
        ...brandInfo,
        brandfeatures: brandInfo.features,
        conditionals,
      },
    })
  } catch (err) {
    //todo handle this
    console.log(err)
  }

  return dispatch({ type: 'CURRENT_BRAND_FAILED' })
}

export const acceptCurrentPlatformTerms = () => async dispatch => {
  try {
    await axios.post('/terms-of-use/accept', { type: 'platform', accepted: true })

    dispatch({ type: 'ACCEPT_BRAND_TERMS' })

    // TODO: Add Analytics here ???? Cc. Alexsey
  } catch (err) {
    console.log(err)
  }
}

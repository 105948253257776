import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import { P } from 'components/texts'
import SearchWrapper from './SearchWrapper'

const useStyles = makeStyles(theme => ({
  wrapper: {
    cursor: 'pointer',
    backgroundColor: theme.colors.silver,
  },
}))

function SearchResults(props) {
  const { searchValue, onSearchClick } = props
  const css = useStyles()

  const onItemClick = value => e => {
    e.preventDefault()
    onSearchClick(value)
  }

  return (
    <div onClick={onItemClick(searchValue)}>
      <SearchWrapper customClasses={css.wrapper}>
        <P>
          Search
          <span>
            {' "'}
            <em>{searchValue}</em>
            &quot;
          </span>
        </P>
      </SearchWrapper>
    </div>
  )
}

SearchResults.propTypes = {
  searchValue: PropTypes.string,
  onSearchClick: PropTypes.func.isRequired,
}

SearchResults.defaultProps = {
  searchValue: '',
}

export default React.memo(SearchResults)

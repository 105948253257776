import React from 'react'
import PropTypes from 'prop-types'
import TooltipMUI from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import Text from 'components/texts/Text'

const useStyles = makeStyles(theme => ({
  tooltip: {
    padding: 0,
    backgroundColor: theme.colors.charcoalBase,
    maxWidth: 228,
    borderRadius: 4,
  },
  tooltipArrow: {
    color: theme.colors.charcoalBase,
  },
  title: {
    padding: 8,
  },
}))

function SliderTooltip(props) {
  const { children, open, value } = props
  const css = useStyles()

  const title = (
    <p className={css.title}>
      <Text size={12} white>
        {value}
      </Text>
    </p>
  )

  const tooltipProps = {
    open,
    classes: {
      tooltip: css.tooltip,
      arrow: css.tooltipArrow,
    },
    placement: 'top',
    arrow: true,
    enterDelayTouch: 0,
    title,
  }

  return <TooltipMUI {...tooltipProps}>{children}</TooltipMUI>
}

SliderTooltip.propTypes = {
  value: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
}

export default React.memo(SliderTooltip)

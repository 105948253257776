import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { ToasterContext } from 'contexts'
import { makeStyles } from '@material-ui/core'
import ConfirmationModal from 'components/virtual/modals/ConfirmationModal'
import Text from 'components/texts/Text'
import { Spinner } from 'components/spinners'

const useStyles = makeStyles({
  warning: {
    marginTop: 16,
  },
  spinner: {
    position: 'relative',
    marginTop: 48,
  },
})

function DisconnectModal(props) {
  const { showToaster } = useContext(ToasterContext)
  const [loading, setLoading] = useState(false)
  const classes = useStyles()

  const {
    open, setOpenDisconnect, store, disconnect, activeDependents,
  } = props

  const handleClose = () => {
    setOpenDisconnect(false)
  }

  const onDisconnect = async () => {
    try {
      setLoading(true)
      if (activeDependents) {
        await Promise.all(activeDependents.map(dependent => disconnect(dependent)))
      }
      await disconnect(store)
      handleClose()
    } catch (e) {
      showToaster('Could not disconnect integration.', { type: 'warning' })
    }
    setLoading(false)
  }

  const capitalizeStoreName = store.charAt(0).toUpperCase() + store.slice(1)

  const title = `Disconnect ${capitalizeStoreName}?`
  const text = loading ? (
    <div className={classes.spinner}>
      <Spinner full={false} />
    </div>
  ) : (
    <>
      <Text charcoalLight size={16}>
        {`By removing the ${capitalizeStoreName} integration, DojoMojo will no longer receive data from your store, which may affect your analytics reporting. Previously synced data will remain in your DojoMojo account.`}
      </Text>
      {store === 'shopify' && (
        <div className={classes.warning}>
          <Text charcoalLight size={16}>
            Please be aware that disconnecting Shopify
            <b> will also disconnect Recharge </b>
            from your account (if applicable).
          </Text>
        </div>
      )}
    </>
  )

  return (
    <ConfirmationModal
      open={open}
      title={title}
      text={text}
      cancelLabel="Cancel"
      submitLabel="Disconnect"
      onCancel={handleClose}
      onSubmit={onDisconnect}
    />
  )
}

DisconnectModal.propTypes = {
  store: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpenDisconnect: PropTypes.func.isRequired,
  disconnect: PropTypes.func.isRequired,
  activeDependents: PropTypes.array,
}

DisconnectModal.defaultProps = {
  activeDependents: null,
}

export default React.memo(DisconnectModal)

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

function HoverPopupWrapper(props) {
  const { children, show } = props
  const [showPopup, setShowPopup] = useState(false)
  const timerRef = React.useRef(null)

  useEffect(() => {
    if (show) setShowPopup(true)
    else {
      clearTimeout(timerRef.current)
      timerRef.current = setTimeout(() => setShowPopup(false), 500) // unset it in 5 secs
    }

    return () => {
      clearTimeout(timerRef.current)
    }
  }, [show])

  if (!showPopup) return null

  return children
}

HoverPopupWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default React.memo(HoverPopupWrapper)

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Text from 'components/texts/Text'
import Separator from 'components/shared/Separator'
import Checkbox from 'components/virtual/checkboxes/Checkbox'
import BuilderField from './BuilderField'

const useStyles = makeStyles({
  editTextField: {
    '& > h5': {
      marginBottom: 32,
    },
  },
})

const CUSTOM_FIELD_MAP = {
  dropdown: 'Dropdown',
  text: 'Text',
}

function EditTextField(props) {
  const {
    input, actions, auxiliaryComponent, children,
  } = props
  const { onLabelChange, onPlaceholderChange, onRequiredChange } = actions

  const css = useStyles()

  const labelToUse = input.isTemplate ? input.label : `Custom ${CUSTOM_FIELD_MAP[input.type]}`

  return (
    <div className={css.editTextField}>
      <Text size={18} charcoalDarker>
        {labelToUse}
      </Text>
      {!input.isTemplate && (
        <BuilderField
          name={input.name}
          value={input.label}
          onChange={onLabelChange}
          tooltip={{
            text:
              'Choose a unique field label. This is the label that will only appear in your reports and will not be displayed on your form. For consistent reporting, we recommend that you do not change your field label once your signup form is published.',
          }}
          label="Field Name"
          placeholder="Field Name"
          helperText="Choose a unique label for reporting. This won’t display on your lightbox."
        />
      )}
      <BuilderField
        name={input.name}
        isPlaceholder
        value={input.placeholder}
        onChange={onPlaceholderChange}
        fullWidth
        tooltip={{
          text: 'This is the placeholder text that will appear in the field on your form.',
        }}
        label="Display Name"
        placeholder="Display Name"
      />
      <Checkbox
        checked={input.isRequired}
        label="This is a required field"
        onChange={onRequiredChange}
      />
      {auxiliaryComponent}
      {children && (
        <>
          <Separator top={24} bottom={24} />
          {children}
        </>
      )}
    </div>
  )
}

EditTextField.propTypes = {
  input: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  children: PropTypes.node,
  auxiliaryComponent: PropTypes.node,
}

EditTextField.defaultProps = {
  children: null,
  auxiliaryComponent: null,
}

export default React.memo(EditTextField)

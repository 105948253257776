import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  banner: {
    position: 'relative',
    height: 60,
    width: '100%',
    background: '#121212',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: 20,
    height: 20,
    fill: 'white',
  },
}))

function ShutdownBanner() {
  const classes = useStyles()

  const icon = (
    <svg className={classes.icon} focusable="false" viewBox="0 0 24 24">
      <path d="M1 21h22L12 2zm12-3h-2v-2h2zm0-4h-2v-4h2z" />
    </svg>
  )

  return (
    <div className={classes.banner}>
      {icon}
      &nbsp;
      <b>Important Notice:</b>
      &nbsp;Dojo Mojo 2.0 is in progress. Working diligently to fix site issues. Contact us: &nbsp;
      <a href="mailto:hello@dojomojo.com" rel="noopener noreferrer">
        hello@dojomojo.com
      </a>
    </div>
  )
}

export default ShutdownBanner

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import MessageEditor from './MessageEditorSection'
import MessagePreview from './MessagePreviewSection'

const useStyles = makeStyles({
  wrapper: {
    padding: 40,
  },
  container: {
    display: 'flex',
    '& > *': {
      flex: 1,
      '&:first-child': {
        marginRight: 48,
      },
    },
  },
})

function EditingContent(props) {
  const css = useStyles()

  const { settingsComponent, ...restProps } = props

  return (
    <div className={css.wrapper}>
      {settingsComponent}
      <div className={css.container}>
        <MessageEditor {...restProps} />
        <MessagePreview {...restProps} />
      </div>
    </div>
  )
}

EditingContent.propTypes = {
  settingsComponent: PropTypes.node,
  name: PropTypes.string,
}

EditingContent.defaultProps = {
  settingsComponent: null,
  name: 'message',
}

export default React.memo(EditingContent)

import React from 'react'
import { useFormState } from 'react-final-form'

import Text from 'components/texts/Text'
import EditorWrapper from 'components/shared/SmsEditorWrapper'
import { FollowupsWrapper, AddFollowup } from 'components/shared/SmsFollowupsComponents'

function CreateEditLightbox() {
  const { values } = useFormState()
  const { followups, FOLLOWUPS_LIMIT, actions } = values
  const { addFollowup } = actions

  const Separator = React.memo(p => <div style={{ marginTop: p.margin || 16 }} />)
  const showAddFollowup = followups.length < FOLLOWUPS_LIMIT

  return (
    <div>
      <Text size={18}>Compose Your Message</Text>
      <Separator />
      <EditorWrapper name="message" />
      {Boolean(followups.length) && (
        <>
          <Separator margin={32} />
          <FollowupsWrapper settingsName="lightboxSendSettings">
            {followups.map((followup, i) => (
              <EditorWrapper key={followup.id} name={`followups[${i}]`} />
            ))}
          </FollowupsWrapper>
        </>
      )}
      {showAddFollowup && <AddFollowup onClick={addFollowup} />}
    </div>
  )
}

export default React.memo(CreateEditLightbox)

import React from 'react'

import BuilderContainer from 'components/builder'
import { DragContext } from 'components/shared/draggable'
import useLightboxBuilder from 'hooks/lightbox-builder/useLightboxBuilder'
import useLightboxBuilderConfig from './useLightboxBuilderConfig'

function LightboxBuilder() {
  const config = useLightboxBuilderConfig()

  return (
    <DragContext>
      <BuilderContainer config={config} hook={useLightboxBuilder} />
    </DragContext>
  )
}

export default React.memo(LightboxBuilder)

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import SearchWrapper from './SearchWrapper'

const useStyles = makeStyles(() => ({
  wrapper: {
    flexDirection: 'column !important',
    alignItems: 'flex-start',
    '& [class*=spinnerWrapper]': {
      width: '100%',
      height: '100%',
      '& [class*=MuiCircularProgress]': {
        width: '40px !important',
        height: '40px !important',
      },
    },
    '&:not(:last-child)': {
      paddingBottom: 0,
    },
  },
  resultsWrapper: {
    minHeight: 40,
    width: '100%',
    position: 'relative',
  },
}))

function SearchResultsWrapper(props) {
  const { title, children } = props
  const css = useStyles()

  return (
    <SearchWrapper title={title} customClasses={css.wrapper}>
      <div className={css.resultsWrapper}>{children}</div>
    </SearchWrapper>
  )
}

SearchResultsWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default React.memo(SearchResultsWrapper)

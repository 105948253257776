import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, IconButton, InputAdornment } from '@material-ui/core'
import Search from '@material-ui/icons/Search'

import { ReactComponent as Clear } from 'images/icons/clear-icon.svg'

import InputBase from './InputBase'

const useStyles = makeStyles(theme => ({
  searchIcon: {
    alignItems: 'center',
    backgroundColor: theme.colors.skyLight,
    display: 'flex',
    justifyContent: 'center',
    minHeight: 48,
    minWidth: 48,
  },
}))

function SearchBar(props) {
  const { onClear, value, ...rest } = props

  const classes = useStyles()

  const clear = event => {
    event.preventDefault()
    onClear()
  }

  const clearButton = (
    <InputAdornment position="end">
      <IconButton aria-label="Toggle Password Visibility" onClick={clear} tabindex="-1">
        <Clear />
      </IconButton>
    </InputAdornment>
  )

  const searchIcon = (
    <div className={classes.searchIcon}>
      <InputAdornment tabindex="-1">
        <Search />
      </InputAdornment>
    </div>
  )

  return (
    <InputBase
      {...rest}
      endAdornment={value && clearButton}
      startAdornment={searchIcon}
      value={value}
    />
  )
}

SearchBar.propTypes = {
  onClear: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

export default React.memo(SearchBar)

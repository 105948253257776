import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import { ReactComponent as TooltipIcon } from 'images/icons/tool-tip.svg'

import ToolTipWrapper from './ToolTipWrapper'

const useStyles = makeStyles(theme => ({
  mobile: {
    [theme.breakpoints.up('xs')]: {
      width: 48,
      height: 48,
      marginLeft: -18,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  virtualTheme: {
    height: 12,
    width: 12,
    marginLeft: 12,
  },
}))

function DojoToolTip(props) {
  const { isVirtualTheme } = props
  const css = useStyles()

  return (
    <ToolTipWrapper {...props}>
      <div className={isVirtualTheme ? css.virtualTheme : css.mobile}>
        <TooltipIcon />
      </div>
    </ToolTipWrapper>
  )
}

DojoToolTip.propTypes = {
  isVirtualTheme: PropTypes.bool,
}

DojoToolTip.defaultProps = {
  isVirtualTheme: false,
}

export default React.memo(DojoToolTip)

/* https://codesandbox.io/s/5w4yrpyo7k */

import React, { useState, useEffect } from 'react'
import { FormSpy } from 'react-final-form'
import diff from 'object-diff'

let timeout
let promise

const AutoSaveHOC = ({ values, debounce, save }) => {
  const [newValues, setValues] = useState(values)

  useEffect(() => {
    if (timeout) {
      clearTimeout(timeout)
    }

    timeout = setTimeout(async () => {
      if (promise) {
        await promise
      }

      const difference = diff(newValues, values)
      if (Object.keys(difference).length) {
        setValues(values)
        promise = save(difference)
        await promise
      }
    }, debounce)
  })

  return null
}

const AutoSave = props => (
  <FormSpy {...props} subscription={{ values: true }} component={AutoSaveHOC} />
)

export default AutoSave

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, CircularProgress } from '@material-ui/core'
import Text from 'components/texts/Text'

const useStyles = makeStyles(theme => ({
  spinnerWrapper: {
    width: props => (props.full ? '100vw' : '100%'),
    height: props => (props.full ? '100vh' : '100%'),
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
  spinnerInnerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  spinner: {
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: '120px !important',
      width: '120px !important',
    },
  },
  spinnerCircle: {
    [theme.breakpoints.down('sm')]: {
      strokeWidth: 2.5,
    },
  },
  margin: {
    flexBasis: '100%',
    height: 40,
  },
}))

function Spinner(props) {
  const { full, label } = props
  const classes = useStyles({ full })
  return (
    <div className={classes.spinnerWrapper}>
      <div className={classes.spinnerInnerWrapper}>
        <CircularProgress
          classes={{
            root: classes.spinner,
            circle: classes.spinnerCircle,
          }}
        />
        <div className={classes.margin} />
        {label ? (
          <Text charcoalDarker size={24}>
            {label}
          </Text>
        ) : (
          ''
        )}
      </div>
    </div>
  )
}

Spinner.propTypes = {
  full: PropTypes.bool,
  label: PropTypes.string,
}

Spinner.defaultProps = {
  full: true,
  label: '',
}

export default React.memo(Spinner)

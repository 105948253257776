import React from 'react'
import { useHistory } from 'react-router-dom'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import ActionBanner from './ActionBanner'

function EmailRequiredBeforePublishBanner() {
  const history = useHistory()
  const { values, actions } = React.useContext(BuilderContainerContext)
  const { baseUrl } = values

  const onUpdateClick = () => {
    actions.setIsPublish(false)
    history.push(`${baseUrl}/design/content`)
  }

  return (
    <ActionBanner
      wrapperStyles={{ marginTop: 32 }}
      type="error"
      actionLabel="Update"
      action={onUpdateClick}
      title="Email field required with active ESP integration"
    />
  )
}

export default React.memo(EmailRequiredBeforePublishBanner)

import * as Sentry from '@sentry/browser'

const SENTRY_OPTIONS = {
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  release: process.env.REACT_APP_VERSION,
}

export function init(sessionId) {
  Sentry.init(SENTRY_OPTIONS)
  Sentry.configureScope(scope => {
    scope.setTag('transaction_id', sessionId)
  })
}

export function setUser(profile) {
  const {
    id, email, displayName: name, impersonator: impersonatorId, impersonatorEmail,
  } = profile

  Sentry.configureScope(scope => {
    scope.setUser({
      id,
      email,
      name,
      impersonatorId,
      impersonatorEmail,
    })
  })
}

export function setBrand(brand = {}) {
  const { id: brandId } = brand
  Sentry.configureScope(scope => {
    scope.setTag('brand', brandId)
  })
}

export function triggerModal(error, extras) {
  Sentry.withScope(scope => {
    scope.setTag('platformCrash', true)
    scope.setExtras(extras)
    const eventId = Sentry.captureException(error)
    Sentry.showReportDialog({
      eventId,
      title: 'Oops! We’re Experiencing Some Technical Difficulties',
      subtitle:
        "Try refreshing the page, or if you'd like to submit a crash report, please tell us what happened below:",
      subtitle2: '',
      user: scope._user,
    })
  })
}

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import FieldsTitle from './FieldsTitle'
import FieldSelection from './FieldSelection'

const useStyles = makeStyles({
  fields: {
    marginTop: 46,
  },
})

function Fields() {
  const css = useStyles()

  return (
    <div className={css.fields}>
      <FieldsTitle />
      <FieldSelection />
    </div>
  )
}

export default React.memo(Fields)

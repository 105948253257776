import { createSelector } from 'reselect'

import { isTrialing, isOffsitePlan, isTrialingAndNotUpgraded } from 'utils/brandStripeBeltHelper'

export const getLegalInfo = createSelector(
  state => state.currentBrand,
  currentBrand => {
    const objNames = [
      'legalName',
      'incorporationState',
      'entityType',
      'addressL1',
      'addressL2',
      'city',
      'state',
      'zipcode',
      'country',
    ]

    const data = {}

    objNames.forEach(key => {
      data[key] = currentBrand[key]
    })

    return data
  }
)

export const getDaysLeftInTrial = createSelector(
  state => state.currentBrand,
  currentBrand => {
    const { trial_end } = currentBrand

    const oneDay = 86400000 /* 24*60*60*1000 ... hours*minutes*seconds*milliseconds */

    const daysLeft = Math.round(Math.abs(new Date(trial_end).getTime() - new Date()) / oneDay)

    return daysLeft > 0 ? daysLeft : 0
  }
)

// TODO: Rename this. B3G1 plans don't exist anymore, this should be
// more like "getAvailablePromo" or something

// B3G1 available for users who have never purchased a plan
export const getB3G1Available = createSelector(
  state => state.currentBrand,
  currentBrand => {
    if (isTrialing(currentBrand)) return isTrialingAndNotUpgraded(currentBrand)

    return !currentBrand.customer.invoices.some(invoice => {
      // filter only invoices that are charges for a plan
      const plans = invoice.lines.data.filter(item => item.plan)
      return invoice.charge && invoice.status === 'paid' && plans.length
    })
  }
)

export const canUpgrade = createSelector(
  state => state.currentBrand,
  currentBrand => {
    const {
      customer: { sources },
    } = currentBrand

    // Check if brand is on offsite plan
    const offsitePlan = currentBrand.stripe_belt && isOffsitePlan(currentBrand.stripe_belt)

    const hasCard = sources && sources.data && sources.data.length > 0
    const pendingPreferredTermsPlan = !!currentBrand.pendingPreferredTermsPlan
    const trialing = isTrialing(currentBrand, currentBrand.customer)

    const shouldShowUpgrade = currentBrand.isSensei || (offsitePlan && !pendingPreferredTermsPlan)
      ? false
      : pendingPreferredTermsPlan || !(hasCard && currentBrand.belt === 'pro' && !trialing)

    return shouldShowUpgrade
  }
)

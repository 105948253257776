import React, { useRef, useState } from 'react'
import { useFormState } from 'react-final-form'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'
import _ from 'lodash'

import { ReactComponent as ErrorIcon } from 'images/icons/error-simple.svg'
import Text from 'components/texts/Text'
import { Button } from 'components/buttons'
import { Small } from 'components/texts'
import SendTestModal from 'components/modals/SendTestModal'

const useStyles = makeStyles(theme => ({
  border: {
    outline: `1px solid ${theme.colors.silver}`,
  },
  container: {
    height: 72,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  title: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      margin: '-2px 8px 0',
    },
  },
  padding: {
    padding: '0 32px',
  },
  buttons: {
    position: 'absolute',
    marginTop: 20,
    right: 0,
    display: 'flex',
    '& button': {
      marginBottom: 8,
    },
    '& > :not(:last-child)': {
      marginRight: 8,
    },
  },
  followups: {
    '& > :not(:last-child)': {
      marginRight: 8,
    },
  },
}))

// TODO: Organize this component... too many conditionals...
function EditorWrapperTitle(props) {
  const {
    name, isPreview, enableActions, noButtons, canDelete, customComponent,
  } = props
  const [showTestModal, setShowTestModal] = useState(false)
  const css = useStyles()
  const {
    valid, values, errors, touched,
  } = useFormState()

  const message = _.get(values, name)
  const { actions } = values
  const { name: messageTitle, isEditing, isFollowup } = message

  const { current: onDelete } = useRef(() => actions.setToDelete(message.id))
  const { current: onCloseEdit } = useRef(() => actions.editMessage())
  const { current: onEdit } = useRef(() => actions.editMessage(message.id))
  const { current: showTest } = useRef(() => setShowTestModal(true))

  const DeleteButton = () =>
    enableActions && <Button danger label="Delete Message" onClick={onDelete} type="raised" />
  const EditButton = () =>
    enableActions && <Button icon="blueEdit" label="Edit" onClick={onEdit} type="raised" />
  const DoneButton = () => <Button label="Done" onClick={valid && onCloseEdit} />

  const hasError = Boolean(_.get(errors, name))
    && Object.keys(_.get(errors, name)).some(field => touched[`${name}.${field}`])

  const PreviewActions = () => (
    <div className={css.buttons}>
      <div>
        <Button label="Send Me a Test" onClick={showTest} type="raised" />
        <Small multiline>Test messages are free.</Small>
      </div>
      <EditButton />
      {isFollowup && canDelete && <DeleteButton />}
    </div>
  )
  const FollowupEditingButtons = () => (
    <div className={css.followups}>
      {enableActions && <DeleteButton />}
      <DoneButton />
    </div>
  )

  const wrapperClasses = cn(css.container, { [css.border]: !isPreview, [css.padding]: !isPreview })

  return (
    <div className={wrapperClasses}>
      {customComponent}
      <div className={css.title}>
        <Text size={16} font="medium">
          {isFollowup ? messageTitle : 'Initial Message'}
        </Text>
        {!valid
        && hasError && ( // TODO: make this conditional (if dirty/error)
        <>
          <ErrorIcon />
          <Text size={10} alertRed>
            Action Required
          </Text>
        </>
        )}
      </div>
      {isPreview && !noButtons ? ( // message.isEditing = false
        <PreviewActions />
      ) : isEditing && isFollowup ? (
        <FollowupEditingButtons />
      ) : null}
      <SendTestModal
        show={showTestModal}
        message={message}
        onClose={() => setShowTestModal(false)}
      />
    </div>
  )
}

EditorWrapperTitle.propTypes = {
  name: PropTypes.string,
  isPreview: PropTypes.bool,
  enableActions: PropTypes.bool,
  noButtons: PropTypes.bool,
  canDelete: PropTypes.bool,
  customComponent: PropTypes.node,
}

EditorWrapperTitle.defaultProps = {
  name: 'message',
  isPreview: false,
  enableActions: true,
  noButtons: false,
  canDelete: true,
  customComponent: null,
}

export default React.memo(EditorWrapperTitle)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CheckIcon from '@material-ui/icons/CheckCircleOutline'

import Text from 'components/texts/Text'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import InfoOutlined from '@material-ui/icons/InfoOutlined'

const useStyles = makeStyles(theme => ({
  integrationTitle: {
    marginLeft: 32,
    display: 'flex',
    justifyContent: 'space-evenly',
    flexFlow: 'column',
    '& > *': {
      flex: 1,
    },
  },
  check: {
    display: 'flex',
    alignItems: 'center',
  },
  checkIcon: {
    color: theme.colors.greenDarker,
    width: 16,
    marginBottom: 4,
    marginRight: 8,
  },
  espName: {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      paddingTop: 2,
    },
  },
  infoIcon: {
    transform: 'rotate(180deg)',
    color: theme.colors.charcoalLight,
    width: 16,
    marginRight: 8,
  },
}))

function ESPIntegrationTitle() {
  const { values, espIntegration } = React.useContext(BuilderContainerContext)
  const { ESP } = values

  const css = useStyles()

  return (
    <div className={css.integrationTitle}>
      <Text charcoalDarker size={16}>
        ESP Integration
      </Text>
      <div className={css.espName}>
        {!ESP && <InfoOutlined className={css.infoIcon} />}
        <Text size={12} charcoalLight>
          {ESP?.label ?? 'No ESP Detected'}
        </Text>
      </div>
      <div className={css.check}>
        {espIntegration.canSupportESP(ESP?.key) && (
          <>
            <CheckIcon className={css.checkIcon} />
            <Text size={12} greenDarker>
              ESP Connected
            </Text>
          </>
        )}
      </div>
    </div>
  )
}

export default React.memo(ESPIntegrationTitle)

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import * as metrics from 'utils/metrics'

import ContactSupportModal from 'components/modals/ContactSupportModal'
import dojomojoLogo from 'images/logos/dojomojo-text-logo.svg'

const useStyles = makeStyles(theme => ({
  footer: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    backgroundColor: theme.colors.crystal,
    bottom: 0,
    left: 0,
    height: '32px',
    padding: '0px 12px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  footerText: {
    fontFamily: 'Larsseit-Light',
    fontSize: '12px',
    margin: '0px 12px',
    color: theme.colors.ink,
    '&:hover': {
      fontWeight: '600',
      color: theme.colors.ink,
    },
  },
  dojomojoLogo: {
    margin: '0px 12px 0px auto',
    objectFit: 'contain',
  },
  divider: {
    width: '1px',
    height: '100%',
    margin: '0px 8px',
    backgroundColor: theme.colors.cornflower,
  },
}))

const Divider = () => {
  const classes = useStyles()

  return <span className={classes.divider} />
}

const clickedOn = item => () =>
  metrics.create('footer', { meta: { item, action: 'clickedOnItem' } })

const FooterItem = props => {
  const classes = useStyles()
  const { url, text, internal } = props
  const Component = internal ? Link : 'a'
  const childProps = {
    onClick: clickedOn(text),
    className: classes.footerText,
    [internal ? 'to' : 'href']: url,
  }

  return <Component {...childProps}>{` ${text}`}</Component>
}

FooterItem.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  internal: PropTypes.bool,
}

FooterItem.defaultProps = {
  internal: false,
}

function Footer() {
  const classes = useStyles()
  const [contactSupportModalOpen, setContactSupportModalOpen] = useState(false)

  return (
    <div className={classes.footer}>
      <FooterItem text="Privacy" url="https://www.dojomojo.com/home/privacy" />
      <FooterItem text="Terms of Use" url="https://www.dojomojo.com/home/terms-of-use" />
      <Divider />
      <FooterItem text="Blog" url="http://blog.dojomojo.com" />
      <FooterItem text="Resources" url="https://www.dojomojo.com/home/resources/all" />
      <FooterItem text="Help Center" url="https://help.dojomojo.com/hc/en-us" />
      <div onClick={() => setContactSupportModalOpen(true)}>
        <FooterItem text="Contact Us" />
      </div>
      <Divider />
      <FooterItem text="Refer Brand" url="/profile/brand/referrals" internal />
      <img className={classes.dojomojoLogo} src={dojomojoLogo} alt="" />
      <ContactSupportModal
        onClose={() => setContactSupportModalOpen(false)}
        open={contactSupportModalOpen}
      />
    </div>
  )
}

export default React.memo(Footer)

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import { H3, H4, Small } from 'components/texts'
import { Button } from 'components/buttons'
import SendTestModal from 'components/modals/SendTestModal'

import { ReactComponent as AbandonedProduceImagePlaceholder } from 'images/abandoned_product_placeholder.svg'

import generatePreview from 'views/mobile/sms-marketing/utils'

const useStyles = makeStyles(theme => ({
  phoneBorder: {
    width: '100%',
    minHeight: ({ noMargin }) => (noMargin ? 530 : 392),
    border: '1px solid #e0e0e0',
    borderRadius: 32,
    padding: '40px 46px',
    marginTop: ({ noMargin }) => (noMargin ? 0 : 56),
    position: 'relative',
  },
  reviewPhone: {
    marginTop: 8,
  },
  title: {
    marginTop: 0,
  },
  messageContainer: {
    backgroundColor: theme.colors.silver,
    borderTopRightRadius: 12,
    borderTopLeftRadius: 12,
    borderBottomRightRadius: 12,
    width: 320,
    padding: '8px 16px',
    wordWrap: 'break-word',
  },
  img: {
    overflow: 'hidden',
    maxWidth: 200,
    maxHeight: 200,
    borderRadius: 16,
    marginBottom: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      objectFit: 'cover',
      width: '100%',
    },
  },
  azureText: {
    color: theme.colors.azure,
  },
  button: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    marginTop: 16,
    '& > :first-child': {
      marginBottom: 8,
    },
  },
  testBody: {
    width: '100%',
    textAlign: 'left',
  },
  preview: {
    position: 'sticky',
    top: '90px',
  },
  imgPlaceholder: {
    '& tspan': {
      fontFamily: 'Larsseit-Light',
    },
  },
}))

const SMSPreview = props => {
  const css = useStyles(props)
  const currentBrand = useSelector(state => state.currentBrand)

  const {
    formValues, isReview, isValid, previewOnly, buttonType,
  } = props

  const { custom_sms_message, image_url } = formValues

  const [testModalOpen, setTestModalOpen] = useState(false)

  const previewText = generatePreview(formValues, currentBrand.accountname, currentBrand.website)

  let image = null

  if (image_url) {
    const CUSTOM_IMAGE_PREVIEWS = {
      ABANDONED_PRODUCT_IMAGE: <AbandonedProduceImagePlaceholder className={css.imgPlaceholder} />,
    }

    if (image_url in CUSTOM_IMAGE_PREVIEWS) image = CUSTOM_IMAGE_PREVIEWS[image_url]
    else {
      image = (
        <div className={css.img}>
          <img src={image_url} alt="promo" />
        </div>
      )
    }
  }

  return (
    <>
      <div className={css.preview}>
        {isReview && (
          <div className={css.title}>
            <H3>Message</H3>
          </div>
        )}
        <div className={` ${css.phoneBorder} ${isReview ? css.reviewPhone : ''}`}>
          {image}
          {Boolean(custom_sms_message && custom_sms_message.length) && (
            <div className={css.messageContainer}>
              <H4 multiline>{previewText}</H4>
            </div>
          )}
        </div>
        <div className={css.button}>
          {!previewOnly && (
            <>
              <Button
                label="Send Me a Test"
                onClick={() => setTestModalOpen(true)}
                type={buttonType}
                themeColor="primary"
                disabled={!isValid}
              />
              <Small multiline>Test messages are free.</Small>
            </>
          )}
        </div>
      </div>
      <SendTestModal
        show={testModalOpen}
        onClose={() => setTestModalOpen(false)}
        message={formValues}
      />
    </>
  )
}

SMSPreview.propTypes = {
  formValues: PropTypes.object.isRequired,
  isValid: PropTypes.bool.isRequired,
  isReview: PropTypes.bool,
  buttonType: PropTypes.string,
  previewOnly: PropTypes.bool.isRequired,
}

SMSPreview.defaultProps = {
  isReview: false,
  buttonType: 'contained',
}

export default React.memo(SMSPreview)

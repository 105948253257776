import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import Chip from 'components/shared/Chip'

import relatedIndustries from 'utils/relatedIndustries'

import SearchWrapper from './SearchWrapper'

const useStyles = makeStyles(theme => ({
  wrapper: {
    borderBottom: `1px solid ${theme.colors.silver}`,
    flexDirection: 'column !important',
    alignItems: 'flex-start',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > [class*=chipContainer]': {
      '& > div': {
        cursor: 'pointer !important',
        borderRadius: 2,
      },
    },
  },
}))

function RelatedIndustryTags(props) {
  const { searchValue, onTagClick } = props
  const classes = useStyles()

  const related = []
  Object.keys(relatedIndustries).forEach(key => {
    if (
      relatedIndustries[key].some(
        industry => industry === searchValue || industry.includes(searchValue)
      )
    ) {
      related.push(key)
    }
  })

  if (!searchValue || searchValue.length < 3 || !related.length) return null

  const tagItems = related.map(tag => (
    <Chip
      key={tag}
      onClick={() => onTagClick('industry.raw', [tag], 'terms')}
      onDelete={null}
      label={tag}
    />
  ))

  return (
    <SearchWrapper title="Related Industry Tags" customClasses={classes.wrapper}>
      <div className={classes.chips}>{tagItems}</div>
    </SearchWrapper>
  )
}

RelatedIndustryTags.propTypes = {
  searchValue: PropTypes.string,
  onTagClick: PropTypes.func.isRequired,
}

RelatedIndustryTags.defaultProps = {
  searchValue: '',
}

export default React.memo(RelatedIndustryTags)

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button as MuiButton, makeStyles } from '@material-ui/core'
import cn from 'classnames'

import editActive from 'images/icons/menu-icons/edit-active@2x.png'
import { ReactComponent as BlueEdit } from 'images/icons/blue-edit.svg'
import addIcon from 'images/nav-bar/add-icon.svg'
import seagreenAddIcon from 'images/nav-bar/seagreen-add-icon.svg'
import azureEllipsesIcon from 'images/icons/azure-ellipses.svg'
import { ReactComponent as UpdateIcon } from 'images/icons/red-autorenew.svg'
import { ReactComponent as PlayIcon } from 'images/icons/play-circle-outline.svg'
import { ReactComponent as PauseIcon } from 'images/icons/pause-circle-outline.svg'
import { ReactComponent as BlueAddIcon } from 'images/icons/blue-add-icon.svg'

const icons = {
  pause: classes => <PauseIcon className={classes.icon} />,
  play: classes => <PlayIcon className={classes.icon} />,
  blueEdit: classes => <BlueEdit className={classes.icon} />,
  edit: classes => <img className={classes.icon} src={editActive} alt="" />,
  addIcon: classes => <img className={classes.icon} src={addIcon} alt="" />,
  blueAdd: classes => <BlueAddIcon className={classes.icon} />,
  seagreenAddIcon: classes => <img className={classes.icon} src={seagreenAddIcon} alt="" />,
  azureEllipses: classes => <img className={classes.icon} src={azureEllipsesIcon} alt="" />,
  update: classes => <UpdateIcon className={cn(classes.icon, classes.updateIcon)} />,
}

const useStyles = makeStyles(theme => ({
  // Raised | Flat are being removed. This is a workaround
  raised: {
    backgroundColor: 'white',
    color: props => theme.palette[props.themeColor].main,
    padding: '6px 16px',
    boxShadow: '0 1px 2px 0 rgba(0,0,0,0.2)',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  outlined: {
    backgroundColor: 'white',
    border: `1px solid ${theme.colors.silver}`,
    borderRadius: 2,
    boxShadow: 'none',
    color: props => theme.palette[props.themeColor].main,
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  transparent: {
    backgroundColor: 'transparent',
    width: 'fit-content',
    color: props => theme.palette[props.themeColor].main,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  icon: {
    width: 16,
    height: 16,
    marginLeft: ({ reverseIcon }) => (reverseIcon ? 0 : 8),
    marginRight: ({ reverseIcon }) => (reverseIcon ? 8 : 0),
  },
  updateIcon: {
    '& path:not(:first-child)': {
      fill: ({ type }) => `${type === 'contained' ? 'white' : theme.colors.azure} !important`,
    },
  },
  root: {
    [theme.breakpoints.up('xs')]: {
      padding: '0 16px',
    },
  },
  morePad: {
    padding: '0 16px',
  },
  danger: {
    color: `${theme.colors.lightRed} !important`,
  },
}))

function Button(props) {
  const {
    id,
    icon,
    small,
    label,
    onClick,
    disabled,
    themeColor,
    reverseIcon,
    morePad,
    danger,
  } = props
  let { type } = props

  const [buttonId] = useState(id || `button-${Date.now()}`)

  const size = small ? 'small' : 'medium'

  const classes = useStyles({ themeColor, reverseIcon, type })

  const className = cn(classes[type] || '', {
    [classes.morePad]: morePad,
    [classes.danger]: danger,
  })

  if (type === 'raised') type = 'contained'

  let content = [<span>{label}</span>, icon && (icons[icon] || icons.edit)(classes)].filter(Boolean)

  if (reverseIcon) content = content.reverse()

  return (
    <MuiButton
      id={buttonId}
      disabled={disabled}
      size={size}
      color={themeColor}
      onClick={onClick}
      variant={type}
      className={className}
    >
      {content}
    </MuiButton>
  )
}

Button.propTypes = {
  disabled: PropTypes.bool,
  morePad: PropTypes.bool,
  danger: PropTypes.bool,
  reverseIcon: PropTypes.bool,
  icon: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  small: PropTypes.bool,
  id: PropTypes.string,

  themeColor: PropTypes.oneOf(['primary', 'secondary', 'transparent', 'default']),
  type: PropTypes.oneOf(['contained', 'flat', 'text', 'raised', 'outlined']),
}

Button.defaultProps = {
  disabled: false,
  morePad: false,
  danger: false,
  reverseIcon: false,
  icon: null,
  small: false,
  id: '',

  label: 'click me',
  type: 'contained', // Types: {contained|flat|text|raised}
  themeColor: 'primary', // themeColors: {primary|secondary|transparent}
  onClick() {},
}

export default React.memo(Button)

import React from 'react'
import PropTypes from 'prop-types'

import InputWithSideLabel from 'components/virtual/inputs/InputWithSideLabel'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

function BehaviorFrequencyDelay(props) {
  const { disabled } = props
  const {
    lightboxSettings,
    builder: { errors },
  } = React.useContext(BuilderContainerContext)
  const settings = lightboxSettings.getSettings()

  const onDelayChange = ({ target }) => {
    const displayFrequencyDelay = Number(target.value || 0)
    if (!/^\d*$/.test(displayFrequencyDelay)) return
    settings.trigger = { displayFrequencyDelay }
  }

  const onKeyDown = e => {
    let { value: displayFrequencyDelay } = e.target
    switch (e.key) {
      case 'ArrowUp':
        displayFrequencyDelay = Number(displayFrequencyDelay) + 1
        break
      case 'ArrowDown':
        displayFrequencyDelay = Math.max(0, Number(displayFrequencyDelay) - 1)
        break
      default:
        break
    }

    onDelayChange({ target: { value: displayFrequencyDelay } })
  }

  const error = errors.verify('delay')

  return (
    <div style={{ marginLeft: 8 }}>
      <InputWithSideLabel
        controlledInput
        disabled={disabled}
        value={settings.trigger.displayFrequencyDelay}
        onChange={onDelayChange}
        onKeyDown={onKeyDown}
        label="days"
        error={error}
      />
    </div>
  )
}

BehaviorFrequencyDelay.propTypes = {
  disabled: PropTypes.bool.isRequired,
}

export default React.memo(BehaviorFrequencyDelay)

import React from 'react'
import { makeStyles } from '@material-ui/core'

import InputBase from './InputBase'

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    flex: 1,
    '& [class*=MuiInputBase-root]': {
      border: 'none',
      backgroundColor: 'transparent',
    },
  },
}))

function NoBorderInput(props) {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <InputBase {...props} />
    </div>
  )
}

export default React.memo(NoBorderInput)

import React from 'react'
import PropTypes from 'prop-types'

import { AnnouncementsModalProvider } from './AnnouncementsModalContext'
import { ToasterProvider } from './ToasterContext'
import { MessageEditorProvider } from './MessageEditorContext'

const providers = [AnnouncementsModalProvider, ToasterProvider, MessageEditorProvider]

function ContextWrapper({ children, contexts }) {
  if (!contexts.length) return children

  const [Component, ...rest] = contexts

  return (
    <ContextWrapper contexts={rest}>
      <Component>{children}</Component>
    </ContextWrapper>
  )
}

ContextWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
  contexts: PropTypes.array.isRequired,
}

function GlobalContext({ children }) {
  return <ContextWrapper contexts={providers}>{children}</ContextWrapper>
}

GlobalContext.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
}

export default GlobalContext

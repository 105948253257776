import { format } from 'd3-format'

export default (data, showZero, formatter) => {
  let displayedNumber

  if (!data || data < 0) {
    displayedNumber = showZero ? 0 : '-'
  } else if (formatter === 'percent') {
    displayedNumber = format('.1%')(data)
  } else if (formatter === 'decimal') {
    displayedNumber = format(',.2f')(data)
  } else if (formatter === 'currency') {
    displayedNumber = format('($,.2f')(data)
  } else {
    data = parseInt(data, 10)
    if (data <= 9999) {
      displayedNumber = format(',')(data)
    } else if (data >= 1000000 && data < 1100000) {
      displayedNumber = format('.1s')(data)
    } else if (data >= 1100000 && data < 100000000) {
      displayedNumber = format('.2s')(data)
    } else {
      displayedNumber = format('.3s')(data)
        .replace('G', 'B')
        .replace('k', 'K')
    }
  }
  return displayedNumber
}

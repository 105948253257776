import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

import { MoreHoriz } from '@material-ui/icons'
import { ButtonBase, ClickAwayListener } from '@material-ui/core'

import { colors } from 'components/virtual/VirtualTheme'
import PopUpMenu from './PopUpMenu'

import DropdownOutlined from '../selectors/Dropdown/DropdownOutlined'

const useStyles = makeStyles({
  button: { borderRadius: props => (props.useRadius ? '50%' : 4) },
})

function MoreMenu(props) {
  const { children, actions, color } = props
  const ref = React.useRef()
  const css = useStyles({ useRadius: !children })

  const [open, setOpen] = useState(false)

  const onMenuOpenClick = () => setOpen(prevOpen => !prevOpen)

  const menuComponent = children
    ? React.cloneElement(children, { onClick: onMenuOpenClick })
    : <MoreHoriz className="more-icon" onClick={onMenuOpenClick} style={{ color }} />

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <ButtonBase className={css.button} ref={ref}>
        {menuComponent}
        <PopUpMenu ref={ref} options={actions} open={open} onClose={() => setOpen(false)} />
      </ButtonBase>
    </ClickAwayListener>
  )
}

MoreMenu.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.object).isRequired,
  color: PropTypes.string,
  children: PropTypes.oneOf([DropdownOutlined]),
}

MoreMenu.defaultProps = {
  color: colors.navyBase,
  children: null,
}

export default React.memo(MoreMenu)

export const checkPageForField = ({ pageInputs }, fieldName) => {
  if (!pageInputs) return false
  return pageInputs.some(({ name }) => name === fieldName)
}

export const checkPagesForField = (settings, fieldName) => {
  if (!settings) return false
  const { settingsPages } = settings
  return settingsPages.some(page => checkPageForField(page, fieldName))
}

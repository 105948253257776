import store from 'utils/reduxStore'
import { useEffect } from 'react'
import useEnv from 'hooks/useEnvironment'

const useDebug = () => {
  const env = useEnv()

  // Set dev variables
  useEffect(() => {
    if (env === 'DEV') window.store = store
  }, [env])

  return env
}

export default useDebug

import React, { useRef, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import {
  Menu as MuiMenu,
  MenuItem,
  MenuList,
  Divider,
  makeStyles,
  ClickAwayListener,
  ListItemIcon,
} from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import EditIcon from '@material-ui/icons/Edit'
import DuplicateIcon from '@material-ui/icons/ControlPointDuplicateOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import ShowChartIcon from '@material-ui/icons/ShowChart'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import PauseCircleOutlineOutlinedIcon from '@material-ui/icons/PauseCircleOutlineOutlined'

import { P, Small } from 'components/texts'
import cn from 'classnames'

const useMenuStyles = makeStyles(() => ({
  paper: {
    minWidth: props => `${props.width}px`,
    width: props => `${props.width}px`,
    marginTop: props => props.virtualButton && '-13px',
  },
}))

const useStyles = makeStyles(theme => ({
  separator: {
    margin: '8px 0',
    flexGrow: 1,
    maxWidth: 280,
  },
  menu: {
    '& .MuiMenu-list': {
      padding: '4px 0px',
    },
    '& .MuiListItem-root': {
      minHeight: props => props.itemMinHeight,
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      marginTop: 4,
      marginBottom: 4,
      whiteSpace: 'inherit',
    },
    '& p': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: props => props.virtualButton && theme.colors.navyBase,
    },
  },
  iconMaterial: {
    marginRight: 10,
  },
  materialColor: {
    color: theme.colors.navyBase,
  },
  iconDojo: {
    marginRight: 14,
  },
  deleteIcon: {
    color: theme.colors.redBase,
  },
}))

/*eslint-disable */
const renderMenuItem = (classes, onClose, virtualButton) => ({
  onClick,
  label,
  hasSeparator,
  href,
  secondary,
  to,
  iconType,
  /*eslint-enable */
}) => {
  const iconMaterialClass = cn(classes.iconMaterial, { [classes.materialColor]: virtualButton })
  const icons = {
    edit: <EditIcon fontSize="small" className={iconMaterialClass} />,
    delete: <DeleteIcon className={cn(classes.iconMaterial, classes.deleteIcon)} />,
    duplicate: <DuplicateIcon className={iconMaterialClass} />,
    view: <VisibilityIcon fontSize="small" className={iconMaterialClass} />,
    report: <ShowChartIcon fontSize="small" className={iconMaterialClass} />,
    live: <PlayCircleOutlineIcon fontSize="small" className={iconMaterialClass} />,
    pause: <PauseCircleOutlineOutlinedIcon fontSize="small" className={iconMaterialClass} />,
  }
  const item = (
    <ClickAwayListener onClickAway={onClose}>
      <MenuItem
        onClick={e => {
          if (onClick) onClick(e)
          setTimeout(onClose, 50)
        }}
      >
        <P multiline>
          {iconType && <ListItemIcon>{icons[iconType]}</ListItemIcon>}
          <i className={cn({ [classes.deleteIcon]: iconType === 'delete' })}>{label}</i>
        </P>
        {secondary && <Small multiline>{secondary}</Small>}
      </MenuItem>
    </ClickAwayListener>
  )

  const withLink = href ? <a href={href}>{item}</a> : to ? <Link to={to}>{item}</Link> : item

  return (
    <div className="menu-item" key={label}>
      {withLink}
      {hasSeparator && <Divider className={classes.separator} />}
    </div>
  )
}

function Menu(props) {
  const {
    open,
    onClose,
    items,
    itemMinHeight,
    PaperProps,
    width,
    vertical,
    virtualButton,
    horizontal,
  } = props
  const menuClasses = useMenuStyles({ width, virtualButton })
  const classes = useStyles({ itemMinHeight, virtualButton })

  const menuRef = useRef(null)
  const itemsRenderer = useCallback(renderMenuItem(classes, onClose, virtualButton), [])
  const getAnchorEl = useCallback(() => menuRef.current, [])
  const elements = useMemo(() => items.map(itemsRenderer), [items, itemsRenderer])

  if (!items || !items.length) return null

  return (
    <div ref={menuRef}>
      <MuiMenu
        anchorOrigin={{ vertical, horizontal }}
        transformOrigin={{ vertical, horizontal }}
        getContentAnchorEl={null}
        marginThreshold={26}
        open={open}
        onClose={onClose}
        PaperProps={PaperProps}
        anchorEl={getAnchorEl}
        className={classes.menu}
        classes={menuClasses}
        autoFocus={false}
      >
        <MenuList>{elements}</MenuList>
      </MuiMenu>
    </div>
  )
}

Menu.defaultProps = {
  open: false,
  items: [],
  onClose() {},
  itemMinHeight: 54,
  PaperProps: {},
  width: 240,
  horizontal: 'right',
  virtualButton: false,
  vertical: 'bottom',
}

Menu.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  items: PropTypes.array,
  itemMinHeight: PropTypes.number,
  PaperProps: PropTypes.object,
  width: PropTypes.number,
  horizontal: PropTypes.string,
  vertical: PropTypes.string,
  virtualButton: PropTypes.bool,
}

export default React.memo(Menu)

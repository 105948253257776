import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

import CustomFallbackTagModal from '../CustomFallbackTagModal'

const useStyles = makeStyles(theme => ({
  tag: {
    position: 'relative',
    color: 'white',
    backgroundColor: theme.colors.cerulean,
    padding: 4,
    fontFamily: 'Larsseit',
    borderRadius: 2,
    userSelect: 'none',
    whiteSpace: 'pre',
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  error: {
    backgroundColor: theme.colors.alertRed,
  },
}))

// Tags with fallback
function EditorCustomTagWrapper(props) {
  const spanRef = useRef()
  const [show, setShow] = useState(false)
  const css = useStyles()
  const { children, tag } = props

  if (!tag) return null

  let [mergeTag, fallback] = tag.split('_OR_')

  mergeTag = mergeTag.replace(/[^A-Z]/g, ' ').toLowerCase()
  fallback = fallback.replace(/(_|\||\*)/g, ' ').trim()

  const hasError = fallback.toLowerCase() === 'fallback'

  if (hasError) fallback = `${fallback[0].toUpperCase()}${fallback.slice(1).toLowerCase()}`

  const {
    props: { offsetKey },
  } = children[0]

  const child = (
    <span data-offset-key={offsetKey}>
      <span data-text="true">
        {/* IMPORTANT: See rant in EditorTagWrapper.js */}
        <span className={css.capitalize}>{`${mergeTag}`}</span>
        <span> or </span>
        <span>{`(${fallback}) `}</span>
      </span>
    </span>
  )

  const placement = spanRef.current && spanRef.current.getBoundingClientRect()

  return (
    <span
      ref={spanRef}
      contentEditable={false}
      onClick={() => setShow(true)}
      className={cn(css.tag, { [css.error]: hasError })}
    >
      {child}
      <CustomFallbackTagModal
        placement={placement}
        show={show}
        customTag={tag}
        fallback={fallback}
        onClose={() => setShow(false)}
      />
    </span>
  )
}

EditorCustomTagWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  tag: PropTypes.string.isRequired,
}

export default React.memo(EditorCustomTagWrapper)

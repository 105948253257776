import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { Dialog, makeStyles } from '@material-ui/core'

import { isRequired } from 'utils/formValidations'
import styleHelper from 'utils/styleHelper'
import { ToasterContext } from 'contexts'

import closeIcon from 'images/icons/close-x-icon-white.svg'

import { Form, FormInput } from 'components/forms'
import Button from 'components/buttons/Button'
import Text from 'components/texts/Text'

const useStyles = makeStyles(() => ({
  closeIcon: {
    cursor: 'pointer',
    height: 14,
    position: 'absolute',
    right: 31,
    top: 31,
    width: 14,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 32px 16px',
    width: '100%',
  },
  input: {
    marginBottom: 18,
  },
  paper: {
    minWidth: 600,
    [styleHelper.mobile]: {
      minWidth: 'calc(100vw - 32px)',
    },
  },
  subtitle: {
    marginBottom: 24,
  },
  successModal: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '24px 32px 16px',
  },
  title: {
    margin: '0px auto 24px',
  },
}))

function ContactSupportModal(props) {
  const { onClose, open } = props

  const classes = useStyles()
  const [success, setSuccess] = useState(false)
  const { showToaster } = useContext(ToasterContext)

  const closeSuccessModal = () => setSuccess(false)

  const contactSupport = async ({
    name, email, subject, message,
  }) => {
    try {
      await axios.post('/zendesk-contact-support', {
        name,
        email,
        subject,
        message,
      })
      onClose()
      setSuccess(true)
    } catch (err) {
      showToaster('There was an error sending your request.', { type: 'warning' })
    }
  }

  return (
    <>
      <Dialog
        classes={{ paper: classes.paper }}
        maxWidth="false"
        onBackdropClick={onClose}
        onEscapeKeyDown={onClose}
        open={open}
      >
        <div className={classes.container}>
          <img src={closeIcon} className={classes.closeIcon} onClick={onClose} alt="" />
          <div className={classes.title}>
            <Text font="medium" size={18.5}>
              Contact Support
            </Text>
          </div>
          <Form
            initialValues={{
              name: '',
              email: '',
              subject: '',
              message: '',
            }}
            onSubmit={contactSupport}
            withSubmit="submit"
          >
            <div className={classes.input}>
              <FormInput
                fullWidth
                label="Contact Name *"
                name="name"
                type="text"
                validations={[isRequired]}
              />
            </div>
            <div className={classes.input}>
              <FormInput
                fullWidth
                label="Contact Email *"
                name="email"
                type="text"
                validations={[isRequired]}
              />
            </div>
            <div className={classes.input}>
              <FormInput
                fullWidth
                label="Subject *"
                name="subject"
                type="text"
                validations={[isRequired]}
              />
            </div>
            <div className={classes.input}>
              <FormInput
                fullWidth
                label="Message *"
                name="message"
                rows={8}
                type="textarea"
                validations={[isRequired]}
              />
            </div>
          </Form>
          <div className={classes.submitButton}>
            <Button color="primary" fullWidth id="submit" label="Submit" type="contained" />
          </div>
        </div>
      </Dialog>
      <Dialog maxWidth={false} onBackdropClick={closeSuccessModal} open={success}>
        <div className={classes.successModal}>
          <div className={classes.title}>
            <Text font="medium" size={18.5}>
              Your message has been sent!
            </Text>
          </div>
          <div className={classes.subtitle}>
            <Text size={16}>We&apos;ll be in touch shortly!</Text>
          </div>
          <div className={classes.submitButton}>
            <Button color="primary" label="Got it" onClick={closeSuccessModal} type="contained" />
          </div>
        </div>
      </Dialog>
    </>
  )
}

ContactSupportModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default React.memo(ContactSupportModal)

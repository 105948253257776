import React from 'react'
import PropTypes from 'prop-types'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

import GenericModal from 'components/virtual/modals/GenericModal'

function LightboxBuilderUpdateConfirmationModal(props) {
  const { show, onClose } = props
  const { actions, values } = React.useContext(BuilderContainerContext)
  const { onPublishLightbox } = actions
  const { isLoading } = values

  const onPublishClick = () => onPublishLightbox().then(onClose)

  return (
    <GenericModal
      show={show}
      title="Are you sure you want to update your message?"
      body="Your latest changes will immediately go live once you publish your updates."
      confirmLabel="Publish Updates"
      onCancel={onClose}
      onConfirm={onPublishClick}
      canSave={!isLoading}
    />
  )
}

LightboxBuilderUpdateConfirmationModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default React.memo(LightboxBuilderUpdateConfirmationModal)

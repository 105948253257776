import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  pageWrapper: {
    minHeight: 'calc(100vh - 32px)',
    backgroundColor: 'white',
    '&:after': {
      content: '',
      display: 'block',
      height: '32px',
    },
  },
}))
function PageWrapper(props) {
  const { children } = props
  const classes = useStyles()

  return <div className={classes.pageWrapper}>{children}</div>
}

PageWrapper.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
}

export default React.memo(PageWrapper)

import React from 'react'
import PropTypes from 'prop-types'
import { Typography, withStyles } from '@material-ui/core'

const styles = theme => {
  const colors = Object.entries(theme.colors).reduce(
    (obj, [key, val]) => ({ ...obj, [key]: { color: val } }),
    {}
  )

  return {
    text: {
      fontSize: 12,
      lineHeight: '12px',
      fontFamily: 'Larsseit-Light',
      '& > em': {
        fontFamily: 'Larsseit-Medium',
        fontStyle: 'normal',
      },
      '& > i': {
        fontFamily: 'Larsseit',
        fontStyle: 'normal',
      },
      '& > b': {
        fontFamily: 'Larsseit-Bold',
        fontStyle: 'normal',
      },
    },
    multiline: {
      lineHeight: '20px',
      '& > i': {
        fontFamily: 'Larsseit-Light',
        fontStyle: 'normal',
      },
    },
    micro: {
      lineHeight: '14px',
      fontFamily: 'Larsseit',
      fontSize: 9,
    },
    ellipsis: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    obscure: {
      filter: 'blur(3px)',
      userSelect: 'none',
    },
    ...colors,
  }
}

function Small({ children, classes, ...props }) {
  const classnames = Object.keys(props)
    .map(k => props[k] && classes[k])
    .join(' ')

  return <Typography className={`${classes.text} ${classnames}`}>{children}</Typography>
}

Small.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  classes: PropTypes.object.isRequired,
}

Small.defaultProps = {
  children: null,
}

export default React.memo(withStyles(styles)(Small))

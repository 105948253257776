import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import NotInterested from '@material-ui/icons/NotInterested'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

import Button from 'components/virtual/buttons/Button'
import HoverPopup from 'components/modals/HoverPopup'
import DropdownButton from 'components/virtual/buttons/DropdownButton'

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
  },
}))

function LightboxBuilderSaveAndPublishButton() {
  const css = useStyles()
  const { values, actions, lightboxSettings } = React.useContext(BuilderContainerContext)
  const settings = lightboxSettings.getSettings()

  const publishRef = React.useRef(null)

  const isActive = settings.status === 'active'

  const onClickShowPublish = async () => {
    if (!values.isValid) return
    // show only the confirmation to update modal
    if (isActive) actions.setIsPublish(true)
    else actions.onSaveBeforePublish()
  }

  if (isActive) {
    const options = [
      { label: 'Publish Updates', onClick: onClickShowPublish },
      { label: 'Unpublish', onClick: actions.onUnpublishLightbox },
    ]

    return <DropdownButton onClick={onClickShowPublish} label="Update" options={options} />
  }

  return (
    <div className={css.wrapper} ref={publishRef}>
      <Button size="small" label="Save & Publish" onClick={onClickShowPublish} />
      <HoverPopup
        action="click"
        hide={values.isValid}
        ref={publishRef}
        icon={<NotInterested className={css.popoverDisabledIcon} />}
        type="warning"
        title="Can't Publish"
        body="Your signup form can't be published right now! There are a few items that need to be fixed. Please resolve all of the (red) errors before publishing your form."
      />
    </div>
  )
}

export default React.memo(LightboxBuilderSaveAndPublishButton)

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Dropzone from 'react-dropzone'
import cn from 'classnames'

import { ReactComponent as UploadIcon } from 'images/icons/upload-icon.svg'
import { ReactComponent as AddImage } from 'images/icons/add-image.svg'

import { Small } from 'components/texts'
import useFileUploader from 'hooks/useFileUploader'
import { Spinner } from 'components/spinners'
import VirtualTheme from 'components/virtual/VirtualTheme'
import Button from 'components/virtual/buttons/Button'
import Text from 'components/texts/Text'

const useStyles = makeStyles(theme => ({
  dropzone: {
    position: 'relative',
    outline: 'none',
    userSelect: 'none',
    cursor: 'pointer',
    width: 200,
    height: 128,
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // Adds the dashes to the box
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='${encodeURIComponent(
      theme.colors.skyDarker
    )}' stroke-width='1' stroke-dasharray='16' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e")`,
    '& svg[class*=icon]': {
      marginBottom: 24,
      height: 32,
      width: 32,
      '& path': {
        fill: `${theme.colors.cloudGrey} !important`,
      },
    },
  },
  dragReject: {
    cursor: 'not-allowed !important',
  },
  preview: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '& img': {
      width: '100%',
    },
  },
  new: {
    cursor: 'default',
    width: 'calc(100% - 2px)',
    height: 180,
    '& svg[class*=icon]': {
      marginBottom: 20,
      height: 48,
      width: 48,
      '& path': {
        fill: `${theme.colors.navyLighter} !important`,
      },
    },
  },
  helperText: {
    marginTop: 20,
  },
}))

const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

const beforeUpload = size => file => file.size < size

function ImageDropzoneUploader(props) {
  const {
    persist,
    label,
    onImageUpload,
    onImageReject,
    maxSize,
    old,
    accept,
    exposeOpenFileUploader,
    helperText,
  } = props
  const css = useStyles({ old })
  const fileUploader = useFileUploader(persist, beforeUpload(maxSize))

  let image
  let openFileUploader
  let clear
  let img64
  let loadingUpload
  let url

  // upload straight up to s3 bucket, return url
  if (persist) {
    ({
      url,
      file: image,
      file64: img64,
      openFileUploader,
      clear,
      loading: loadingUpload,
    } = fileUploader)
  } else {
    // handle in browser, don't upload
    [image, openFileUploader, clear, img64] = fileUploader
  }

  const [loading, setLoading] = useState(false)

  const isLoading = loadingUpload || loading

  useEffect(() => {
    if (image) {
      switch (true) {
        case maxSize && image.size > maxSize:
          onImageReject()
          break
        default:
          if (!img64 && !url) return
          onImageUpload({ image, img64, url })
      }
    }
    clear()
  }, [image, img64, onImageUpload, clear, maxSize, onImageReject, url])

  useEffect(() => {
    exposeOpenFileUploader(openFileUploader)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const content = old ? (
    <>
      <UploadIcon className="icon" />
      <Small azure>
        <b>{label}</b>
      </Small>
    </>
  ) : (
    <VirtualTheme>
      <AddImage className="icon" />
      <Button onClick={openFileUploader} label="Upload Image" size="small" />
      {helperText && (
        <div className={css.helperText}>
          <Text size={12} charcoalLight>
            {helperText}
          </Text>
        </div>
      )}
    </VirtualTheme>
  )

  return (
    <Dropzone
      maxSize={maxSize || undefined}
      accept={accept}
      onDropAccepted={async ([file]) => {
        const image64 = await toBase64(file)
        onImageUpload({ image: file, img64: image64 })
        setLoading(false)
      }}
      onDropRejected={([file]) => {
        onImageReject(file)
        setLoading(false)
      }}
      onDrop={() => setLoading(true)}
      disabled={isLoading}
    >
      {({ getRootProps, isDragReject }) => (
        <div
          className={cn(css.dropzone, { [css.dragReject]: isDragReject, [css.new]: !old })}
          {...getRootProps()}
          onClick={old ? openFileUploader : null}
        >
          {/* // TODO: maybe change the icon when loading??? */}
          {isLoading && <Spinner full={false} />}
          {content}
        </div>
      )}
    </Dropzone>
  )
}

ImageDropzoneUploader.propTypes = {
  label: PropTypes.string,
  helperText: PropTypes.string,
  onImageUpload: PropTypes.func,
  onImageReject: PropTypes.func,
  exposeOpenFileUploader: PropTypes.func,
  maxSize: PropTypes.number,
  old: PropTypes.bool,
  persist: PropTypes.bool,
  accept: PropTypes.arrayOf(PropTypes.string),
}

ImageDropzoneUploader.defaultProps = {
  label: 'Upload Image/GIF',
  helperText: null,
  onImageUpload() {},
  onImageReject() {},
  exposeOpenFileUploader() {},
  old: true,
  accept: ['image/x-png,image/gif,image/jpeg'],
  persist: false,
  maxSize: 450000, // 450kb
}

export default React.memo(ImageDropzoneUploader)

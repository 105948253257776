import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Text from 'components/texts/Text'

const useStyles = makeStyles(theme => ({
  title: {
    margin: '8px 0',
    position: 'relative',
    '&:before': {
      display: ({ hasFollowups }) => (hasFollowups ? 'block' : 'none'),
      content: '""',
      position: 'absolute',
      backgroundColor: theme.colors.charcoalLight,
      height: 12,
      width: 12,
      borderRadius: '50%',
      left: -40,
      top: 4,
    },
  },
  delay: {
    marginBottom: 4,
  },
  message: {
    display: 'flex',
    padding: 12,
    borderRadius: 4,
    backgroundColor: theme.colors.skyLight,
    // maxHeight: 90, // TODO: work this out... for now it stays like this
    overflow: 'hidden',
  },
  image: {
    display: ({ hasImage }) => (hasImage ? 'block' : 'none'),
    height: 64,
    width: 64,
    borderRadius: 4,
    border: `1px solid ${theme.colors.skyBase}`,
    backgroundColor: theme.colors.skyBase,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    marginRight: 16,
  },
}))

function DripPreviewMessage(props) {
  const {
    title, image, message, delay, hasFollowups,
  } = props
  const css = useStyles({ hasImage: image, hasFollowups })

  return (
    <div className="drip-message-content" style={{ paddingBottom: 16 }}>
      <div className={css.title}>
        <Text charcoalDarker>{title}</Text>
        {delay && (
          <div className={css.delay}>
            <Text size={12} charcoalLight>
              {`Delay: ${delay}`}
            </Text>
          </div>
        )}
      </div>
      <div className={css.message}>
        <div style={{ backgroundImage: `url(${image})` }} className={css.image} />
        <div style={{ flex: 1 }}>
          <Text charcoalBase>{message}</Text>
        </div>
      </div>
    </div>
  )
}

DripPreviewMessage.propTypes = {
  hasFollowups: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  delay: PropTypes.string,
}

DripPreviewMessage.defaultProps = {
  delay: null,
}

export default React.memo(DripPreviewMessage)

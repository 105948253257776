const initState = {
  agreementLoading: true,
}

export default (state = initState, action) => {
  const { type, payload = {} } = action

  switch (type) {
    // Loaders
    case 'PARTNERSHIP_AGREEMENT_LOADING': {
      return { ...state, agreementLoading: true }
    }

    // Rest of actions
    case 'PARTNERSHIP_AGREEMENT_FAILED':
    case 'PARTNERSHIP_AGREEMENT_LOAD': {
      return { ...state, ...payload, agreementLoading: false }
    }

    default:
      return state
  }
}

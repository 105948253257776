import React, { useState } from 'react'
import { ClickAwayListener, makeStyles } from '@material-ui/core'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

import AddFieldButton from './AddFieldButton'
import FieldSelectionFields from './FieldSelectionFields'
import SelectedFields from './SelectedFields'

const useStyles = makeStyles({
  fieldSelection: {
    position: 'relative',
  },
})

function FieldSelection() {
  const css = useStyles()
  const context = React.useContext(BuilderContainerContext)

  const { actions } = context
  const { onAddFieldToList } = actions

  const [open, setOpen] = useState(false)

  const closeMenu = () => setOpen(false)

  const onSelectField = field => {
    onAddFieldToList(field)
    closeMenu()
  }

  return (
    <div className={css.fieldSelection}>
      <SelectedFields />
      <ClickAwayListener onClickAway={closeMenu}>
        <div>
          <AddFieldButton isOpen={open} onClick={setOpen} />
          <FieldSelectionFields open={open} onSelect={onSelectField} />
        </div>
      </ClickAwayListener>
    </div>
  )
}

FieldSelection.propTypes = {}

FieldSelection.defaultProps = {}

export default FieldSelection

import React from 'react'
import {
  Route, Redirect, Switch, withRouter,
} from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

import { BuilderContainerContext } from './BuilderContainerContext'
import BuilderTabs from './tabs/BuilderTabs'

const useStyles = makeStyles({
  drawer: {
    backgroundColor: 'white',
    boxShadow: ({ full }) => (full ? 'none' : '2px 0px 4px rgba(90, 116, 129, 0.1)'),
    width: ({ full }) => (full ? '100%' : 448),
    height: '100%',
    transition: 'width .2s linear',
    flex: 1,
  },
  fullSize: { width: 'calc(100vw - 84px)' },
})

function BuilderSidebarDrawer(props) {
  const { match } = props
  const fullsize = false // TODO: use fullPage prop from builder
  const context = React.useContext(BuilderContainerContext)
  const {
    config: {
      sidebar: { sections },
    },
    builder: { section: selectedSection },
  } = context
  const css = useStyles({ full: selectedSection.fullPage })
  const { url } = match

  return (
    <div className={cn(css.drawer, { [css.fullSize]: fullsize })}>
      <Switch>
        {sections.map(section => {
          const { path, tabs, component: Component } = section // TODO: add independent component?

          if (tabs?.length) {
            return (
              <Route key={path} path={`${url}${path}`} render={() => <BuilderTabs tabs={tabs} />} />
            )
          }

          return <Route key={path} path={`${url}${path}`} component={Component} />
        })}
        <Redirect to={`${url}${sections[0].path}`} />
      </Switch>
    </div>
  )
}

BuilderSidebarDrawer.propTypes = {
  match: PropTypes.object.isRequired,
}

export default React.memo(withRouter(BuilderSidebarDrawer))

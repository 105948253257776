import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

import Text from 'components/texts/Text'

const useStyles = makeStyles(theme => ({
  contentField: {
    cursor: 'pointer',
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 16,
    borderRadius: 4,
    border: `1px solid ${theme.colors.skyDarker}`,
  },
  iconWrapper: {
    height: 20,
    width: 20,
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    opacity: 0,
    transition: 'opacity .2s linear',
    color: theme.colors.blueBase,
  },
  showIcon: {
    opacity: 1,
  },
  disabled: {
    color: theme.colors.charcoalLighter,
  },
}))

function LightboxBuilderPageContentField(props) {
  const { label, enabled, onClick } = props
  const css = useStyles()

  return (
    <div className={css.contentField} onClick={onClick}>
      <Text charcoalBase size={16}>
        {label}
      </Text>
      <div className={css.iconWrapper}>
        <Visibility className={cn(css.icon, { [css.showIcon]: enabled })} />
        <VisibilityOff className={cn(css.icon, css.disabled, { [css.showIcon]: !enabled })} />
      </div>
    </div>
  )
}

LightboxBuilderPageContentField.propTypes = {
  label: PropTypes.string.isRequired,
  enabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default React.memo(LightboxBuilderPageContentField)

import 'emoji-mart/css/emoji-mart.css'

import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { Picker as EmojiPicker } from 'emoji-mart'

import ToolTipWrapper from 'components/shared/ToolTipWrapper'

import { ReactComponent as EmojiIcon } from 'images/icons/sms-editor-icons/insert-emoji.svg'
import { SMSEditorContext } from 'contexts/SMSEditorContext'

import { colors } from 'GlobalTheme'

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: '0 6px',
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background .1s linear',
    cursor: 'pointer',
    borderRadius: 2,
    zIndex: 1,
    position: 'relative',
    '&:hover': {
      background: theme.colors.crystal,
    },
  },
  picker: {
    position: 'absolute',
    top: '100%',
    left: 'calc(100% - 24px)',
    height: ({ open }) => (open ? 'auto' : '0'),
    overflow: ({ open }) => (open ? 'auto' : 'hidden'),
  },
}))

function EmojiSelector() {
  const {
    actions: { actionsRef },
  } = React.useContext(SMSEditorContext)
  const [open, setOpen] = useState(false)
  const css = useStyles({ open })

  const onSelect = emoji => {
    if (actionsRef.insertText) actionsRef.insertText(emoji.native, false)

    setOpen(false)
  }

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div className={css.wrapper}>
        <ToolTipWrapper isVirtualTheme text="Emoji">
          <EmojiIcon onClick={() => setOpen(true)} />
        </ToolTipWrapper>
        <div className={css.picker}>
          <EmojiPicker
            native
            onSelect={onSelect}
            color={colors.azure}
            emojiTooltip
            perLine={8}
            title="Pick an emoji"
          />
        </div>
      </div>
    </ClickAwayListener>
  )
}

export default React.memo(EmojiSelector)

import React from 'react'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core/styles'

import { ReactComponent as LayoutLeft } from 'images/lightbox-builder/layout-left.svg'
import { ReactComponent as LayoutRight } from 'images/lightbox-builder/layout-right.svg'
import { ReactComponent as LayoutTop } from 'images/lightbox-builder/layout-top.svg'
import { ReactComponent as LayoutNone } from 'images/lightbox-builder/layout-none.svg'

import RadioSelector from 'components/virtual/buttons/RadioSelector'
import Text from 'components/texts/Text'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: 32,
    '& > :first-child': {
      marginBottom: 4,
    },
  },
  radios: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: 'minmax(160px, auto)',
  },
  radio: {
    textAlign: 'center',
    height: 140,
    width: 'fit-content',
  },
  selected: {
    '& svg': {
      '& > rect': {
        transition: 'all .1s linear',
        '&:not(:first-of-type)': {
          fill: theme.colors.blueBase,
        },
        '&:first-of-type': {
          stroke: theme.colors.blueBase,
        },
      },
    },
  },
}))

function LightboxBuilderThemeLayout() {
  const { lightboxSettings } = React.useContext(BuilderContainerContext)
  const settings = lightboxSettings.getSettings()

  const onLayoutChange = position => {
    settings.heroImage = { position }
  }

  const css = useStyles()

  const options = [
    { value: 'left', label: 'Left', icon: <LayoutLeft /> },
    { value: 'right', label: 'Right', icon: <LayoutRight /> },
    { value: 'top', label: 'Top', icon: <LayoutTop /> },
    { value: 'none', label: 'None', icon: <LayoutNone /> },
  ]

  const render = radio => (
    <div className={cn(css.radio, { [css.selected]: radio.selected })}>
      {radio.icon}
      <Text charcoalLight={!radio.selected} navyBase={radio.selected}>
        {radio.label}
      </Text>
    </div>
  )

  return (
    <>
      <div className={css.title}>
        <Text size={16} charcoalBase>
          Image Options
        </Text>
        <Text charcoalLight>Choose how the image will appear on the form.</Text>
      </div>
      <RadioSelector
        values={options}
        value={settings.heroImage.position}
        className={css.radios}
        onChange={onLayoutChange}
        render={render}
      />
    </>
  )
}

export default React.memo(LightboxBuilderThemeLayout)

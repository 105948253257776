import React from 'react'
import PropTypes from 'prop-types'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

import GenericModal from 'components/virtual/modals/GenericModal'

function UnpublishedFormRequiredModal(props) {
  const { esp, onClose } = props
  const { actions, values } = React.useContext(BuilderContainerContext)
  const { onUnpublishLightbox, onSetESPValue } = actions
  const { isLoading } = values

  const onUnpublish = async () => {
    await onUnpublishLightbox()
    onSetESPValue(esp)
    onClose()
  }

  return (
    <GenericModal
      show={!!esp}
      title="Unpublished Form Required"
      body="Unpublish your signup form to update your ESP connection. The form will be offline until you publish new changes."
      confirmLabel="Unpublish"
      onConfirm={onUnpublish}
      onCancel={onClose}
      canSave={!isLoading}
    />
  )
}

UnpublishedFormRequiredModal.propTypes = {
  esp: PropTypes.object,
  onClose: PropTypes.func.isRequired,
}

UnpublishedFormRequiredModal.defaultProps = {
  esp: null,
}

export default React.memo(UnpublishedFormRequiredModal)

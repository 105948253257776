import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import _ from 'lodash'

import { mergeTags as getDefaultMergeTags } from 'utils/sms'

const sanitizeWebsite = website => {
  if (!website) return 's'
  const [brandWebsite] = website.replace(/^(?:https?:\/\/)?(?:www\.)?/i, '').split('.')
  return brandWebsite
}

const generateRandom = n => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

  return [...new Array(n)]
    .map(() => chars.charAt(Math.floor(Math.random() * chars.length)))
    .join('')
    .toLowerCase()
}

const generateMinifiedPlaceholder = website => {
  const brandWebsite = sanitizeWebsite(website)
  return `https://${brandWebsite}.djmj.io/${generateRandom(8)}`
}

export const SMSEditorContext = React.createContext()

const stateToProps = ({ currentBrand }) => ({ currentBrand })

class SMSEditorProvider extends PureComponent {
  actionsRef = {}

  _onTextChangeTimeout = null

  _minifiedDummyUrl = generateMinifiedPlaceholder(this.props.currentBrand.website)

  state = {
    loading: true,
    readOnly: false,
    text: '',
    coupon: '',
    images: [],
    minifiedLinks: [],
    ignoredCoupons: [],
    minifiedLinksList: [],
    defaultMergeTags: {},
  }

  _exposeValues = _.debounce(() => {
    const { onChange } = this.props
    onChange(this._getValues())
  }, 300)

  _onCouponChange = _.debounce(couponCode => this._setValue('coupon', couponCode), 300)

  async componentDidMount() {
    const {
      url: link,
      couponCode,
      image,
      message,
      currentBrand: { website },
    } = this.props

    const newState = {
      text: message,
      coupon: couponCode,
      images: image ? [{ fullSrc: image }] : [],
      loading: false,
    }

    this._minifiedDummyUrl = generateMinifiedPlaceholder(website)

    if (/(\*\|LINK\|\*|https:\/\/.*\.do?jo?mo?jo?\.io\/\w{8}x?)/.test(message) && link) {
      newState.text = message.replace(
        /(\*\|LINK\|\*|https:\/\/.*\.do?jo?mo?jo?\.io\/\w{8}x?)/g,
        this._minifiedDummyUrl
      )
      const url = { originalUrl: link, minifiedUrl: this._minifiedDummyUrl }

      newState.minifiedLinks = [{ ...url }]

      newState.minifiedLinksList = [{ ...url }]
    }

    // in case of old coupon tag
    if (/\*\|COUPON\|\*/.test(message)) newState.text = message.replace(/\*\|COUPON\|\*/g, '*|COUPON_CODE|*')

    const defaultMergeTags = await getDefaultMergeTags()
    newState.defaultMergeTags = defaultMergeTags

    this.setState(newState)
  }

  componentDidUpdate(prevProps) {
    const { couponCode } = this.props

    if (prevProps.couponCode !== couponCode) this._onCouponChange(couponCode)
  }

  _setValue = (prop, value) => {
    this.setState({ [prop]: value }, this._exposeValues)
  }

  _getValues = () => {
    const {
      readOnly,
      coupon,
      ignoredCoupons,
      images,
      text,
      minifiedLinks,
      minifiedLinksList,
      defaultMergeTags,
    } = this.state

    const {
      credits,
      charsInUse,
      name,
      imageName,
      customError,
      mergeTags,
      imageOptions,
    } = this.props

    return {
      mergeTags: { ...mergeTags, ...defaultMergeTags },
      customError,
      readOnly,
      images,
      credits,
      type: images.length ? 'MMS' : 'SMS',
      text,
      coupon,
      minifiedLinks,
      minifiedLinksList,
      ignoredCoupons,
      charsInUse: charsInUse || text.length,
      inputName: name,
      imageName,
      imageOptions,
    }
  }

  _getActions = () => ({
    setText: this._onTextChange,
    clearItem: item => this.setState({ [item]: item === 'coupon' ? '' : [] }),
    onSetImages: this._onSetImages,
    onLinkMinifyAndInsert: this._onLinkMinifyAndInsert,
    onCouponTrack: this._onCouponTrack,
    onDismissCouponTrack: this._onDismissCouponTrack,
    onAddTag: this._onAddTag,
    actionsRef: this.actionsRef,
    onAddFallback: this._onAddFallback,
    setEditorReadOnly: this._setEditorReadOnly,
  })

  _onSetImages = (img, isDelete = false) => {
    if (isDelete) return this.setState({ images: [] }, this._exposeValues)

    const images = [img]

    return this.setState({ images }, this._exposeValues)
  }

  _onAddTag = which => {
    const newTag = `*|${which
      .toUpperCase()
      .split(' ')
      .join('_')}|*`

    if (this.actionsRef.insertText) this.actionsRef.insertText(newTag)
  }

  _onCouponTrack = (coupon, cb = () => {}) => {
    this.setState({ coupon }, this._exposeValues)
    cb()
  }

  _onDismissCouponTrack = (code, cb = () => {}) => {
    this.setState(({ ignoredCoupons }) => ({
      ignoredCoupons: [...ignoredCoupons, code],
    }))
    cb()
  }

  _onLinkMinifyAndInsert = (originalUrl, cb = () => {}, isInsert = false) => {
    const {
      currentBrand: { website },
    } = this.props
    let { text } = this.state

    this._minifiedDummyUrl = generateMinifiedPlaceholder(website)

    if (isInsert && this.actionsRef.insertText) this.actionsRef.insertText(this._minifiedDummyUrl)
    else {
      const sanitizedWebsite = sanitizeWebsite(website)
      const REGEX = new RegExp(`(https://${sanitizedWebsite}.djmj.io/.{8}|${originalUrl})`, 'g')

      text = text.replace(REGEX, this._minifiedDummyUrl)
    }

    // This is always 1. At first this was meant to hold more than 1 link,
    // then I re-read the specs, but the logic was already built...
    const minifiedLinks = [
      {
        originalUrl: /http(s)?:\/\//.test(originalUrl) ? originalUrl : `http://${originalUrl}`,
        minifiedUrl: this._minifiedDummyUrl, // This is just a placeholder
      },
    ]

    this.setState(
      ({ minifiedLinksList }) => ({
        text,
        minifiedLinks,
        minifiedLinksList: [...minifiedLinksList, minifiedLinks[0]],
      }),
      this._exposeValues
    )
    return cb()
  }

  _onAddFallback = ({ fallback, tag }) => {
    const [, fallbackTag] = /\*\|(.+_OR__)|\*/.exec(tag)

    const newTag = `*|${fallbackTag}${fallback.split(' ').join('_')}_|*`

    if (this.actionsRef.replaceText) {
      this.actionsRef.replaceText({ text: tag, textToReplace: newTag })
    }
  }

  _setEditorReadOnly = (readOnly = true) => this.setState({ readOnly })

  _onTextChange = text => {
    const newState = { text }

    clearTimeout(this._onTextChangeTimeout)
    // TODO: Disabled. Allowing brands to use coupon code even if merge tag is not present.
    // if (!/\*\|COUPON_CODE\|\*/.test(text)) newState.coupon = ''

    this._onTextChangeTimeout = setTimeout(() => {
      this.setState(newState, this._exposeValues)
    }, 100)
  }

  render() {
    const { children } = this.props
    const { loading } = this.state
    const values = this._getValues()
    const actions = this._getActions()

    if (loading) return null

    return (
      <SMSEditorContext.Provider
        value={{
          ...values,
          actions,
        }}
      >
        {children}
      </SMSEditorContext.Provider>
    )
  }
}

SMSEditorProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
  name: PropTypes.string,
  currentBrand: PropTypes.object.isRequired,
  mergeTags: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  imageName: PropTypes.string,
  image: PropTypes.string,
  message: PropTypes.string,
  url: PropTypes.string,
  couponCode: PropTypes.string,
  customError: PropTypes.string,
  onChange: PropTypes.func,
  credits: PropTypes.number.isRequired,
  charsInUse: PropTypes.number.isRequired,
  imageOptions: PropTypes.arrayOf(PropTypes.string),
}

SMSEditorProvider.defaultProps = {
  name: '',
  imageName: '',
  image: '',
  couponCode: '',
  customError: '',
  url: null,
  imageOptions: null,
  onChange() {},
  mergeTags: {},
  message:
    '*|BRAND_NAME|*: This is a sample text message. Add a *|COUPON_CODE|* below and paste/shorten your links.',
}

export default connect(stateToProps)(SMSEditorProvider)

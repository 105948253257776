import React from 'react'
import PropTypes from 'prop-types'

import { MobileModal } from 'components/modals'
import { ButtonGroup } from 'components/buttons'
import { H4 } from 'components/texts'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  wrapper: {
    width: 600,
    padding: 12,
    marginBottom: 16,
  },
})

function DeleteFollowupsModal(props) {
  const css = useStyles()
  const {
    show, onClose, onDelete, plural,
  } = props

  return (
    <MobileModal
      centerTitle
      allowOutsideClick
      open={show}
      onClose={onClose}
      title="Before You Delete"
    >
      <div className={css.wrapper}>
        <H4 multiline>
          {plural ? (
            <>
              Deleting a follow-up message will delete
              {' '}
              <strong>all subsequent messages as well.</strong>
              {' '}
              Are you sure you want to delete?
            </>
          ) : (
            'Are you sure you want to delete?'
          )}
        </H4>
      </div>
      <ButtonGroup
        relative
        morePad
        onSubmit={onDelete}
        onCancel={onClose}
        cancelLabel="Cancel"
        submitLabel="Delete"
      />
    </MobileModal>
  )
}

DeleteFollowupsModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  plural: PropTypes.bool.isRequired,
}

export default React.memo(DeleteFollowupsModal)

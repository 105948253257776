import React from 'react'

import MuiTab from '@material-ui/core/Tab'
import { withStyles } from '@material-ui/core/styles'

import { colors } from 'components/virtual/VirtualTheme'

// TODO: use ahnaf's tabs if possible
const BuilderTab = withStyles({
  root: {
    minWidth: 'unset',
  },
  textColorInherit: {
    color: colors.charcoalLighter,
  },
  selected: {
    color: colors.charcoalDarker,
  },
  wrapper: {
    textTransform: 'capitalize',
  },
})(MuiTab)

export default React.memo(BuilderTab)

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { P, Small } from 'components/texts'
import { MenuItem, makeStyles, Divider } from '@material-ui/core'
import moment from 'moment-timezone'

import { messagingRelativeDate } from 'utils/relativeDate'

const useStyles = makeStyles(theme => ({
  menuItem: {
    height: '72px',
    alignContent: 'center',
    backgroundColor: props => (!props.isRead ? theme.colors.iceBlue : null),
  },
  logoWrapper: {
    overflow: 'hidden',
    background: theme.colors.white,
    borderRadius: '50%',
    height: '48px',
    minWidth: '48px',
    width: '48px',
    marginRight: '12px',
    '& > img': {
      objectFit: 'contain',
      height: '100%',
      width: '100%',
    },
  },
  messageContainer: {
    overflow: 'hidden',
    width: '100%',
  },
  title: {
    display: 'flex',
  },
  name: {
    display: 'flex',
    flexGrow: 1,
    marginBottom: '12px',
  },
  separator: {
    margin: '0px',
    flexGrow: 1,
    width: '100%',
  },
}))

function MessageMenuItem({
  to, brandName, logo, text, sentAt, isRead, onClose, onClick,
}) {
  const classes = useStyles({ isRead })
  return (
    <Link to={to} onClick={onClose || onClick}>
      <MenuItem className={classes.menuItem}>
        <div className={classes.logoWrapper}>
          <img src={logo} alt={brandName} />
        </div>
        <div className={classes.messageContainer}>
          <div className={classes.title}>
            <div className={classes.name}>
              <P>
                <em>{brandName}</em>
              </P>
            </div>
            <Small>{messagingRelativeDate(sentAt)}</Small>
          </div>
          <P ellipsis>
            <i>{text}</i>
          </P>
        </div>
      </MenuItem>
      <Divider className={classes.separator} />
    </Link>
  )
}

MessageMenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  brandName: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  sentAt: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
    PropTypes.instanceOf(moment),
  ]).isRequired,
  isRead: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func,
}

MessageMenuItem.defaultProps = {
  onClick: null,
}

export default MessageMenuItem

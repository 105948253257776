import axios from 'axios'
import _ from 'lodash'
import moment from 'moment-timezone'

import history from 'utils/getHistory'
import { isTrialing, isDelinquent } from 'utils/brandStripeBeltHelper'

export const updateCompanyLegalDetails = async details => {
  const objNames = [
    'legalName',
    'incorporationState',
    'entityType',
    'addressL1',
    'addressL2',
    'city',
    'state',
    'zipcode',
    'country',
  ]

  const dataToSend = _.pick(details, objNames)

  await axios.put('/brands', dataToSend)
}

export const checkPaidStatus = async currentBrand => {
  const { customer } = currentBrand
  const { subscriptions } = customer

  const impersonating = sessionStorage.getItem('impersonating')

  // No need to check
  if (currentBrand.isSensei || impersonating) return { statusOk: true }

  if (
    history.location.pathname.match(
      /(cs-admin|payment-methods|missing-payment|upgrade|elite-request|switch-brand)/
    )
  ) return { statusOk: true }

  const trialEndDate = moment(currentBrand.trial_end)

  const oneDay = 86400000 /* 24*60*60*1000 ... hours*minutes*seconds*milliseconds */
  const remainingDays = Math.round(Math.abs(trialEndDate._d.getTime() - new Date()) / oneDay)
  const withinTrialModalDays = [7, 3, 2, 1].includes(remainingDays)

  const extendedTrialModalShown = sessionStorage.getItem('extendedTrialModalShown') === 'true'
  const dismissedTrialWarningPaywall = sessionStorage.getItem('withinTenDaysOfTrialEndPaywallDismissed') === 'true'

  const dismissedPreferredTermsPlan = sessionStorage.getItem('prerferredTermsPlanDismissed') === 'true'
  const hasPendingPreferredTermsPlan = !!currentBrand.pendingPreferredTermsPlan
  const showpreferredTermsPlan = !dismissedPreferredTermsPlan && hasPendingPreferredTermsPlan

  const hasSubscriptions = Boolean(subscriptions && subscriptions.data && subscriptions.data.length)
  const subscription = hasSubscriptions ? subscriptions.data[0] : null

  const trialing = isTrialing(currentBrand, customer)

  const hasSources = customer.sources.data.length > 0

  // If delinquent because bad payment source, redirect to missing payment screen
  const delinquent = isDelinquent(currentBrand, customer)

  const delinquentWithSources = Boolean(delinquent && hasSources)
  const delinquentWithoutSrcs = Boolean(delinquent && !hasSources)

  // If brand is done trialing and has no subscription and is not white belt, show trial ended modal
  const hasTrialEnded = currentBrand.belt === 'post-trial-paywall'
    || (!trialing
      && !subscription
      // if subscription is through Shopify then ignore this logic. It'll be handled by Shopify
      && currentBrand.payment_method !== 'shopify'
      && currentBrand.belt !== 'white'
      && currentBrand.has_accepted_latest_terms)

  // If brand is in trial and has been given trial extension
  const hasTrialExtension = currentBrand.extended_trial_end && !extendedTrialModalShown

  // Soft paywall shown every time the user logs in, starting 10 days before trial ends
  const showSoftPaywall = trialing
    && currentBrand.trial_type === 'trial'
    && withinTrialModalDays
    && !dismissedTrialWarningPaywall
    && !hasSources
    && currentBrand.has_accepted_latest_terms

  const hasNotFinishedSetupBrand = currentBrand.id && !currentBrand.dedicatedListSize

  const conditionals = {
    trialEndDate,
    withinTrialModalDays,
    extendedTrialModalShown,
    dismissedTrialWarningPaywall,
    hasSubscriptions,
    subscription,
    trialing,
    delinquent,
    hasTrialEnded,
    hasTrialExtension,
    showSoftPaywall,
    hasNotFinishedSetupBrand,
  }

  // TODO: redirectTo in future app does not work

  // If delinquent because bad payment source, redirect to missing payment screen
  if (delinquentWithSources) return { statusOk: false, conditionals, redirectTo: '/missing-payment' }

  // If delinquent with no sources, send to select plan, will collect payment info upon new signup
  if (delinquentWithoutSrcs) return { statusOk: false, conditionals, redirectTo: '/upgrade-plan' }

  // If brand has a pending preferredTermsPlan
  if (showpreferredTermsPlan) return { statusOk: false, conditionals, redirectTo: '/?v=d' }

  // if brand is in trial and has been given trial extension
  if (hasTrialExtension) return { statusOk: false, conditionals, redirectTo: '/?v=d' }

  // if brand is done trialing and has no subscription and is not white belt, show trial ended modal
  // if trial ending, Soft paywall shown every time user logs in, starting 10 days before trial ends
  if (hasTrialEnded || showSoftPaywall) return { statusOk: true, conditionals, redirectTo: '/' }

  // Redirect if not finished setting up brand
  if (hasNotFinishedSetupBrand) return { statusOk: true, conditionals, redirectTo: '/' }

  // TODO: Show delighted widget here?
  return { statusOk: true, conditionals }
}

export const fetchCurrentBrandTagAndSocial = async () => {
  const { data } = await axios.get('/brand/tags-and-social')

  return data
}

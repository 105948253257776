import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { ArrowForwardIos } from '@material-ui/icons'
import ButtonBase from '@material-ui/core/ButtonBase'
import cn from 'classnames'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import Text from 'components/texts/Text'

const useStyles = makeStyles({
  changePageContainer: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 96,
  },
  text: {
    margin: '0 12px',
  },
  button: {
    display: 'grid',
    placeContent: 'center',
    padding: 8,
    borderRadius: '50%',
  },
  icon: {
    color: 'white',
    width: 20,
  },
  flip: {
    transform: 'rotate(180deg)',
  },
})

function LightboxBuilderPreviewChangePageArrows() {
  const css = useStyles()
  const {
    builder, lightboxSettings, values, actions,
  } = React.useContext(BuilderContainerContext)
  const { selectedPage } = values
  const settings = lightboxSettings.getSettings()
  const pagesCount = settings?.pages.length ?? 0
  const {
    preview: { full },
  } = builder

  if (!full) return null

  const onPageChange = nextPage => {
    if (nextPage < 0 || nextPage === settings.pages.length) return

    actions.setSelectedPage(nextPage)
  }

  const onMovePageClick = addToPage => () => onPageChange(selectedPage + addToPage)

  return (
    <div className={css.changePageContainer}>
      <ButtonBase className={css.button} onClick={onMovePageClick(-1)}>
        <ArrowForwardIos className={cn(css.icon, css.flip)} />
      </ButtonBase>
      <div className={css.text}>
        <Text white size={16}>
          {`${selectedPage + 1} / ${pagesCount}`}
        </Text>
      </div>
      <ButtonBase className={css.button} onClick={onMovePageClick(1)}>
        <ArrowForwardIos className={css.icon} />
      </ButtonBase>
    </div>
  )
}

export default React.memo(LightboxBuilderPreviewChangePageArrows)

/* eslint-disable import/no-unresolved */
import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { SMSEditorContext } from 'contexts/SMSEditorContext'

import { P, Small } from 'components/texts'
import ToolTip from 'components/shared/ToolTip'

import { ReactComponent as ErrorIcon } from 'images/icons/error-outline.svg'

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    display: 'flex',
    height: 32,
    padding: '8px 12px 0',
    '& > p': {
      lineHeight: 'unset',
      marginTop: 1,
      marginLeft: 4,
    },
  },
  type: {
    flex: 1,
  },
  space: {
    margin: 4,
  },
  errorBanner: {
    borderRadius: 2,
    backgroundColor: theme.colors.pumpkin,
    height: 32,
    margin: '0 12px',
    display: 'flex',
    alignItems: 'center',
    padding: '0 12px',
    '& > svg': {
      marginLeft: 2,
      marginTop: -2,
      marginRight: 12,
      transform: 'scale(1.2)',
    },
  },
}))

const TOOLTIP_TEXT = 'Some messages may require additional language appended for complaince. This language will be added automatically every 30 days on a per subscriber basis and will add 89 additional characters to your message.'

function SMSEditorFooter() {
  const css = useStyles()
  const {
    credits, type, charsInUse, customError,
  } = useContext(SMSEditorContext)

  const CustomError = () => (
    <div className={css.errorBanner}>
      <ErrorIcon />
      <P>{customError}</P>
    </div>
  )

  return (
    <div>
      {customError && <CustomError />}
      <div className={css.wrapper}>
        <div className={css.type}>{type}</div>
        <Small stormySkies>
          <i>Credits:</i>
        </Small>
        <Small>
          <i>{` ${credits}`}</i>
        </Small>
        <div className={css.space} />
        <Small stormySkies>
          <i>Characters:</i>
        </Small>
        <Small>
          <i>{`~${charsInUse}`}</i>
        </Small>
        <ToolTip padding isVirtualTheme text={TOOLTIP_TEXT} />
      </div>
    </div>
  )
}

export default React.memo(SMSEditorFooter)

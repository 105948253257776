import React from 'react'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core/styles'

import { colors } from 'components/virtual/VirtualTheme'

import Text from 'components/texts/Text'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

const useStyles = makeStyles({
  badge: {
    display: 'flex',
    alignItems: 'baseline',
    '& > :first-child': {
      marginRight: 8,
    },
    '& span': {
      textTransform: 'capitalize',
    },
  },
  status: {
    borderRadius: '50%',
    width: 12,
    height: 12,
  },
  draft: { backgroundColor: colors.skyDarker },
  inactive: { backgroundColor: colors.redLight },
  active: { backgroundColor: colors.greenLight },
})

function LightboxBuilderStatusBadge() {
  const css = useStyles()
  const { lightboxSettings } = React.useContext(BuilderContainerContext)
  const settings = lightboxSettings.getSettings()
  let { status } = settings

  if (settings.launchedAt === null) status = 'draft'

  return (
    <div className={css.badge}>
      <div className={cn(css.status, css[status])} />
      <Text size={16} white>
        <span>{status}</span>
      </Text>
    </div>
  )
}

export default React.memo(LightboxBuilderStatusBadge)

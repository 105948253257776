import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { H5 } from 'components/texts'
import {
  Menu as MuiMenu, MenuList, Divider, makeStyles,
} from '@material-ui/core'

import withCloseFn from 'components/menus/withCloseFn'
import MenuItem from './MyAccountMenuItem'
import ManagedBrand from './ManagedBrand'
import MyAccountMenuMobile from './MyAccountMenuMobile'

const useStyles = makeStyles(() => ({
  currentBrandContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '80px',
    padding: '16px',
  },
  currentBrandLogoWrapper: {
    borderRadius: '50%',
    overflow: 'hidden',
    height: '48px',
    width: '48px',
  },
  logo: {
    backgroundColor: 'white',
    objectFit: 'contain',
    height: '100%',
    width: '100%',
  },
  menu: {
    '& .MuiMenu-paper': {
      minWidth: '240px',
    },
    '& .MuiMenuItem-root': {
      fontSize: '14px',
      minHeight: 52,
    },
  },
  name: {
    margin: '0px 12px',
    width: '150px',
  },
  separator: {
    margin: '4px 0',
    flexGrow: 1,
    maxWidth: 280,
  },
}))

export function MyAccountMenuDesktop(props) {
  const {
    open, onClose, currentBrand, managedBrands,
  } = props
  const classes = useStyles()

  const menuRef = useRef(null)

  const ManagedBrandWithClose = withCloseFn(ManagedBrand, onClose)
  const MenuItemWithClose = withCloseFn(MenuItem, onClose)

  const mBrands = managedBrands
    .slice(0, 3)
    .map(brand => <ManagedBrandWithClose key={brand.id} brand={brand} />)

  if (currentBrand.currentBrandLoading) return null

  return (
    <div ref={menuRef}>
      <MuiMenu
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        marginThreshold={26}
        open={open}
        onClose={onClose}
        anchorEl={menuRef.current}
        className={classes.menu}
      >
        <div className={classes.currentBrandContainer}>
          <div className={classes.currentBrandLogoWrapper}>
            <img src={currentBrand.logo} className={classes.logo} alt={currentBrand.name} />
          </div>
          <div className={classes.name}>
            <H5 ellipsis>{currentBrand.accountname}</H5>
          </div>
        </div>
        <MenuItemWithClose label="Brand Profile" to={`/explore/brands/${currentBrand.id}`} />
        <MenuItemWithClose label="Account Settings" to="/profile/brand/" />
        <MenuItemWithClose label="Reviews" to="/reviews" />
        <MenuItemWithClose azure label="Refer a Brand & Get $25" to="/profile/brand/referrals" />
        <Divider className={classes.separator} />
        <div id="brand-list">
          {mBrands.length ? <MenuList>{mBrands}</MenuList> : null}
          {mBrands.length > 2 ? (
            <MenuItemWithClose label="Manage Brands" to="/profile/switch-brand" />
          ) : (
            <MenuItemWithClose
              onClick={() => (window.location.href = '/app/add-brand')}
              label="Create New Brand"
            />
          )}
        </div>
        <Divider className={classes.separator} />
        <MenuItemWithClose label="Personal Settings" to="/profile/user/info" />
        <MenuItemWithClose
          label="Log Out"
          to="/"
          onClick={() => {
            onClose()
            sessionStorage.setItem('extendedTrialModalShown', false)

            if (window.gapi && window.gapi.auth2) {
              const auth2 = window.gapi.auth2.getAuthInstance()
              if (auth2 && auth2.signOut) auth2.signOut().then(() => console.log('User signed out.'))
            }

            const newHost = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:5000'
            setTimeout(() => (window.location.href = `${newHost}/logout`), 500)
          }}
        />
      </MuiMenu>
    </div>
  )
}

MyAccountMenuDesktop.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  currentBrand: PropTypes.object.isRequired,
  managedBrands: PropTypes.array.isRequired,
}

function MyAccountMenu({ isMobile, ...rest }) {
  return isMobile ? <MyAccountMenuMobile {...rest} /> : <MyAccountMenuDesktop {...rest} />
}

MyAccountMenu.propTypes = {
  isMobile: PropTypes.bool.isRequired,
}

export default React.memo(MyAccountMenu)

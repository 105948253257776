import axios from 'axios'

import { getClaim } from 'actions/brandClaims'

import { markConversationAsRead, filterConversations, enhanceMessageObject } from 'utils/messaging'

import history from 'utils/getHistory'

export const loadCurrentBrandConversationCount = async () => {
  // count only gets amount of conversations in current period (month) of active plan (brand.belt)
  const meta = { count: 0, outOfMessages: true }

  if (!getClaim('messaging.message.create')) return meta

  const {
    data: { count: currentPeriodCount },
  } = await axios.get('/conversations/period-count')

  if (getClaim('messaging.message.limit')) {
    meta.limit = getClaim('messaging.message.limit')
    meta.outOfMessages = currentPeriodCount >= meta.limit
  } else {
    meta.outOfMessages = false
    meta.limit = false
  }

  meta.count = currentPeriodCount

  return meta
}

export const addNewMessage = (data, local = true) => async (dispatch, getState) => {
  const { currentBrand, profile: currentUser } = getState()

  if (local) data.last_read = Date.now()

  dispatch({
    type: 'ADD_MESSAGE',
    payload: enhanceMessageObject(
      {
        displayBrandLogo: currentBrand.logo,
        displayBrandName: currentBrand.accountname,
        messageType: 'normal',
        sender_brand_logo: currentBrand.logo,
        sender_brand_name: currentBrand.accountname,
        ...data,
      },
      currentUser.id,
      currentBrand.id
    ),
  })
}

export const loadNotifications = () => async dispatch => {
  dispatch({ type: 'NOTIFICATIONS_LOADING' })

  try {
    const { data } = await axios.get('/notifications')
    return dispatch({
      type: 'NOTIFICATIONS_LOADED',
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }

  return dispatch({ type: 'NOTIFICATIONS_FAILED' })
}

export const loadUnreadNotifications = () => async dispatch => {
  dispatch({
    type: 'UNREAD_CONVERSATIONS_LOADING',
  })

  try {
    const { data: unreadNotifications = [] } = await axios.get('/unread-notifications')

    return dispatch({
      type: 'UNREAD_CONVERSATIONS_LOAD',
      payload: unreadNotifications,
    })
  } catch (err) {
    //todo handle this
    console.log(err)
  }

  return dispatch({ type: 'UNREAD_CONVERSATIONS_FAILED' })
}

export const loadConversations = () => async (dispatch, getState) => {
  getState().profile.ws.addEventListener('new_message', msg => {
    dispatch(
      addNewMessage(
        {
          displayBrandLogo: msg.details.from_brand_logo,
          displayBrandName: msg.details.from_brand,
          brand_id: msg.details.from_brand_id,
          messageType: 'normal',
          sender_brand_logo: msg.details.from_brand_logo,
          sender_brand_name: msg.details.from_brand,
          sender_id: msg.details.sender_user_id,
          email: msg.details.sender_user_email,
          content: msg.details.content,
          id: msg.details.id,
          conversation_id: msg.details.conversation_id,
          conversation_brand_name: msg.details.from_brand,
          conversation_brand_logo: msg.details.from_brand_logo,
        },
        false
      )
    )
  })

  dispatch({
    type: 'CONVERSATIONS_LOADING',
    payload: 'conversationsLoading',
  })

  try {
    const [{ data: unfilteredConversations = [] }, conversationsMeta] = await Promise.all([
      axios.get('/conversations'),
      loadCurrentBrandConversationCount(),
    ])

    const conversations = filterConversations(unfilteredConversations)

    return dispatch({
      type: 'CONVERSATIONS_LOAD',
      payload: {
        ...conversations,
        meta: conversationsMeta,
      },
    })
  } catch (err) {
    //todo handle this
    console.log(err)
  }

  return dispatch({ type: 'CONVERSATIONS_FAILED' })
}

export const sendMessage = (message, conversationId, isArchived) => async dispatch => {
  try {
    let { data } = await axios.post(`/new-message/${conversationId}`, {
      content: message,
      // TODO: Check what to do with this
      // opportunityId:
    })

    if (isArchived) {
      await axios.get(`/messages/un-archive/${conversationId}`)

      dispatch({
        type: 'UNARCHIVE_CONVERSATION',
        payload: { conversationId },
      })

      data = {
        ...data,
        last_read: Date.now(),
        conversation_brand_name: data.receivingBrand.accountname,
        conversation_brand_logo: data.receivingBrand.logo,
      }
    }

    dispatch(addNewMessage(data))
  } catch (err) {
    console.log({ err })
  }
}

export const newConversation = (message, brandId) => async dispatch => {
  try {
    const { data } = await axios.post(`/new-conversation/${brandId}`, { content: message })

    const { conversation_id } = data

    const [newMessage] = data.messages

    dispatch(
      addNewMessage({
        displayBrandLogo: data.conversation_brand_logo,
        displayBrandName: data.conversation_brand,
        brand_id: data.conversation_brand_id,
        messageType: 'normal',
        sender_brand_logo: data.conversation_brand_logo,
        sender_brand_name: data.conversation_brand,
        sender_id: newMessage.sender_user_id,
        content: newMessage.content,
        id: newMessage.id,
        createdAt: Date.now(),

        conversation_id: data.conversation_id,
        conversation_brand_logo: data.conversation_brand_logo,
        conversation_brand_name: data.conversation_brand_name,
      })
    )
    history.replace(`/messaging/inbox/${conversation_id}`)
  } catch (err) {
    if (err && err.response && err.response.status === 402) {
      throw err
    }
    // TODO: handle other errors
    console.log({ err })
  }
}

export const markConversationRead = conversationId => async (/*dispatch*/) => {
  try {
    await axios.get(`/messages/read-conversation/${conversationId}`)

    markConversationAsRead(conversationId)
  } catch (err) {
    console.log({ err })
  }
}

export const markNotificationRead = id => async dispatch => {
  try {
    await axios.post('/notification-read', { id })
    dispatch({
      type: 'READ_NOTIFICATION',
      payload: id,
    })
  } catch (err) {
    console.log({ err })
  }
}

export const markAllRead = () => async dispatch => {
  try {
    await Promise.all([
      axios.post('/notification-read', { id: 'all' }),
      axios.get('/messages/mark-all-read'),
    ])
    dispatch({ type: 'READ_ALL' })
  } catch (err) {
    console.log({ err })
  }
}

export const fetchConversationIdFromInvite = async partnershipAgreementId => {
  try {
    const { data } = await axios.get(
      `/messages/get-conversation-from-invite/${partnershipAgreementId}`
    )

    const { convoId } = data

    return convoId
  } catch (err) {
    console.log({ err })
  }

  return null
}

import React from 'react'
import PropTypes from 'prop-types'

import { makeStyles } from '@material-ui/core/styles'

import { Menu, MenuItem } from '@material-ui/core'

import Text from 'components/texts/Text'

const useStyles = makeStyles(theme => ({
  menu: {
    padding: '12px 0',
    minWidth: 180,
    maxHeight: 300,
  },
  item: {
    height: 48,
    display: 'flex',
    alignItems: 'center',
    padding: '12px 24px',
    '&:hover': { backgroundColor: theme.colors.skyLight },
  },
}))

function PopUpMenu(props, ref) {
  const { open, onClose, options } = props
  const css = useStyles()

  const onItemClicked = (onClick, disabled) => () => {
    if (disabled) return
    onClick()
    onClose()
  }

  return (
    <Menu classes={{ paper: css.menu }} anchorEl={ref.current} open={open}>
      {options.map(
        ({
          label, onClick, color = 'navyBase', disabled, colorDisabled = 'skyDark',
        }) => (
          <MenuItem
            key={label}
            onClick={onItemClicked(onClick, disabled)}
            classes={{ root: css.item }}
            className={css.item}
          >
            <Text {...{ [disabled ? colorDisabled : color]: true }}>
              <span style={{ opacity: disabled ? 0.4 : 1 }}>{label}</span>
            </Text>
          </MenuItem>
        )
      )}
    </Menu>
  )
}

PopUpMenu.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default React.memo(React.forwardRef(PopUpMenu))

import _ from 'lodash'
import { createSelector } from 'reselect'

export const pickReceivedApplications = createSelector(
  state => state.messaging.conversations.messages,
  state => state.messaging.conversations.receivedApplications,
  (messages, receivedApplications) => Object.values(_.pick(messages, receivedApplications) || {})
)

export const pickReceivedInvitations = createSelector(
  state => state.messaging.conversations.messages,
  state => state.messaging.conversations.receivedInvitations,
  (messages, receivedInvitations) => Object.values(_.pick(messages, receivedInvitations) || {})
)

export const pickSentApplications = createSelector(
  state => state.messaging.conversations.messages,
  state => state.messaging.conversations.sentApplications,
  (messages, sentApplications) => Object.values(_.pick(messages, sentApplications) || {})
)

export const pickSentInvitations = createSelector(
  state => state.messaging.conversations.messages,
  state => state.messaging.conversations.sentInvitations,
  (messages, sentInvitations) => Object.values(_.pick(messages, sentInvitations) || {})
)

export const pickMessageThreads = createSelector(
  state => state.messaging.conversations.messages,
  messages => {
    if (!messages) return []

    const threads = {}

    Object.keys(messages)
      .sort((a, b) => b - a)
      .forEach(messageId => {
        const message = messages[messageId]

        if (message.conversation_archive) return

        const conversationId = message.conversation_id
        const read = new Date(message.createdAt) < new Date(message.last_read)

        if (!threads[conversationId]) {
          const messageToShow = {
            conversationId,
            text: message.content.replace(/(<([^>]+)>)/gi, ''),
            createdAt: message.createdAt,
            title: message.conversation_brand_name,
            image: message.conversation_brand_logo,
          }

          threads[conversationId] = [messageToShow, read]
        } else if (!threads[conversationId][0].title || !threads[conversationId][0].title) {
          threads[conversationId][0].title = message.conversation_brand_name
          threads[conversationId][0].image = message.conversation_brand_logo
        }
      })

    const messageItems = Object.values(threads).sort(
      (a, b) => new Date(b[0].createdAt) - new Date(a[0].createdAt)
    )

    return messageItems
  }
)

export const pickMessageThreadsUnreadFirst = createSelector(
  pickMessageThreads,
  messages => {
    // eg Number(false) - Number(true) === 0 - 1 === -1
    const messagesUnreadFirst = [...messages].sort(([, a], [, b]) => Number(a) - Number(b))

    // flatten arrays
    // TODO: @Jenky, why does pickMessageThreads return an array of arrays?
    return messagesUnreadFirst.map(([message, isRead]) => ({ ...message, isRead }))
  }
)

export const pickConversationMessages = (id, kind) =>
  createSelector(
    state => state.messaging.conversations.messages,
    messages => {
      if (!messages) return []

      let objectToCheck = 'conversation_id'

      if (kind === 'brand') objectToCheck = 'conversation_brand_id'

      const thread = Object.values(messages)
        .filter(message => Number(message[objectToCheck]) === Number(id))
        .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))

      return thread
    }
  )

export const getUnreadNotificationsFirst = createSelector(
  state => state.messaging.notifications.items,
  notifications => [...notifications].sort((a, b) => Number(!!a.read_at) - Number(!!b.read_at))
)

import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { Form as RFForm, FormSpy } from 'react-final-form'

import { Button } from 'components/buttons'

const externalSubmit = (id, handleSubmit) => {
  const el = document.getElementById(id)
  if (el) document.getElementById(id).onclick = handleSubmit
}

function Form(formProps) {
  const {
    onSubmit,
    initialValues,
    withSubmit,
    children,
    exposeValues,
    exposeForm,
    ...restProps
  } = formProps

  const formRef = useRef(null)

  useEffect(() => {
    if (exposeForm) exposeForm(formRef.current)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <RFForm
      {...restProps}
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({
        handleSubmit, form, submitting, submitFailed, invalid,
      }) => {
        let childToRender = children
        if (typeof children === 'function') childToRender = children(form)
        if (withSubmit && typeof withSubmit === 'string') externalSubmit(withSubmit, handleSubmit)
        if (exposeForm && !formRef.current) formRef.current = form

        return (
          <form onSubmit={handleSubmit}>
            {childToRender}
            {withSubmit && typeof withSubmit === 'boolean' && (
              <Button
                onClick={form.submit}
                label="Submit"
                disabled={submitting || (submitFailed && invalid)}
              />
            )}
            <FormSpy
              subscription={{ values: true, valid: true, dirty: true }}
              onChange={state => {
                const { values, valid, dirty } = state
                exposeValues({ values, valid, dirty })
              }}
            />
          </form>
        )
      }}
    />
  )
}

Form.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  exposeValues: PropTypes.func, // exposes Final Form State => https://final-form.org/docs/final-form/types/FormState
  exposeForm: PropTypes.func, // exposes Final Form API => https://final-form.org/docs/final-form/types/FormApi
  withSubmit: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

Form.defaultProps = {
  initialValues: null,
  onSubmit: () => {},
  exposeValues: () => {},
  exposeForm: null,
  withSubmit: false,
}

export default Form

import React from 'react'
import PropTypes from 'prop-types'
import {
  Switch, Route, Redirect, withRouter, useRouteMatch,
} from 'react-router-dom'
import useBuilder from 'hooks/builder/useBuilder'

export const BuilderContainerContext = React.createContext()

function BuilderContainerComponent(props) {
  const { children, hook: useHook, config } = props
  const builder = useBuilder({ errorsMap: config.errors })
  const { params } = useRouteMatch()
  const { builderId } = params
  const { actions, values, ...rest } = useHook({
    ...builder,
    isNew: !builderId,
    id: builderId,
  })

  const contextObj = {
    ...rest,
    config,
    isNew: !builderId,
    id: builderId,
    actions,
    values,
    builder,
  }

  return (
    <BuilderContainerContext.Provider value={contextObj}>
      {children}
    </BuilderContainerContext.Provider>
  )
}

BuilderContainerComponent.propTypes = {
  children: PropTypes.node.isRequired,
  config: PropTypes.object.isRequired,
  hook: PropTypes.func.isRequired,
}

const MemoedBuilderContainerComponent = React.memo(BuilderContainerComponent)

function BuilderContainerProvider(props) {
  const routeParams = useRouteMatch()
  const { url } = routeParams

  return (
    <div id="builder" style={{ display: 'relative' }}>
      <Switch>
        <Route
          path={`${url}/new`}
          render={renderProps => <MemoedBuilderContainerComponent {...renderProps} {...props} />}
        />
        <Route
          path={`${url}/:builderId`}
          render={renderProps => <MemoedBuilderContainerComponent {...renderProps} {...props} />}
        />
        <Redirect to={`${url}/new`} />
      </Switch>
    </div>
  )
}

BuilderContainerProvider.propTypes = {
  children: PropTypes.node.isRequired,
  config: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  hook: PropTypes.func.isRequired,
}

export default React.memo(withRouter(BuilderContainerProvider))

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker as MuiKeyboardDatePicker,
} from '@material-ui/pickers'

import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'

import { H6 } from 'components/texts'

import { ReactComponent as CalendarIcon } from 'images/icons/calendar-icon-grey.svg'

const useStyles = makeStyles(theme => ({
  datePicker: {
    maxHeight: 40,
    overflow: 'visible',
    '& [class*=MuiIconButton-root ]': {
      marginLeft: 0,
      backgroundColor: 'transparent',
    },
    '& [class*=MuiInputBase-input ]': {
      fontFamily: 'Larsseit-Light',
      padding: '4px 0px 0px 0px',
    },
  },
  label: {
    marginBottom: 8,
  },
  popover: {
    '& [class*=MuiIconButton-root ]': {
      backgroundColor: 'inherit',
    },
    '& [class*=MuiIconButton-label ]': {
      color: 'inherit',
    },
    '& [class*=MuiPickersDay-day ]': {
      backgroundColor: 'inherit',
      '&:hover': {
        backgroundColor: theme.colors.skyDarker,
      },
    },
    '& [class*=MuiPickersDay-daySelected ]': {
      backgroundColor: `${theme.colors.blueBase} !important`,
      color: 'white',
    },
  },
}))

function KeyboardDatePicker(props) {
  const {
    value, disabled, format, id, icon, label, max, min, onChange, ...restProps
  } = props

  // restProps: https://material-ui-pickers.dev/api/KeyboardDatePicker

  const classes = useStyles()

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {label && (
        <div className={classes.label}>
          <H6>{label}</H6>
        </div>
      )}
      <div className={classes.datePicker}>
        <MuiKeyboardDatePicker
          disabled={disabled}
          disableToolbar
          format={format}
          id={id}
          InputAdornmentProps={{ position: 'start' }}
          keyboardIcon={icon}
          maxDate={max}
          minDate={min}
          onChange={d => onChange(d)}
          PopoverProps={{
            classes: { root: classes.popover },
            ...restProps.popoverProps,
          }}
          value={value}
          variant="inline"
          {...restProps}
        />
      </div>
    </MuiPickersUtilsProvider>
  )
}

KeyboardDatePicker.defaultProps = {
  disabled: false,
  format: 'MM/dd/yyyy',
  icon: <CalendarIcon />,
  id: '',
  label: '',
  max: undefined,
  min: undefined,
}

KeyboardDatePicker.propTypes = {
  value: PropTypes.instanceOf(Date).isRequired,
  disabled: PropTypes.bool,
  format: PropTypes.string,
  icon: PropTypes.node,
  id: PropTypes.string,
  label: PropTypes.string,
  max: PropTypes.string,
  min: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default React.memo(KeyboardDatePicker)

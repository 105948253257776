// import { format } from 'd3-format'

export default (message, currentUserId, currentBrandId) => {
  if (message.sender_id === currentUserId && message.brand_id === currentBrandId) {
    message.isSender = true
  }

  if (message.partnershipinvite_id) {
    if (message.type === 'campaignAnnouncement') {
      message.messageType = 'normal'
      message.heading = `Campaign Announcement: ${message.campaign_name}`
      message.displayBrandName = message.sender_brand_name
      message.displayBrandLogo = message.sender_brand_logo
    } else if (!message.partnership_action) {
      //Invite AND currentBrand = partner
      if (message.application === false && message.invitee_id === currentBrandId) {
        message.messageType = 'receivedInvitation'
        message.responses = ['interested', 'decline', 'viewCampaign']
        message.highlight = message.status === 'pending'
        message.displayBrandName = message.brand_id && message.brand_id === message.invitee_id
          ? message.invitee_brand_name
          : message.host_brand_name
        message.displayBrandLogo = message.brand_id && message.brand_id === message.invitee_id
          ? message.invitee_brand_logo
          : message.host_brand_logo
        message.heading = `Invitation: ${message.campaign_name}`

        //Application and currentBrand = Host
      } else if (message.application === true && message.invitee_id !== currentBrandId) {
        message.messageType = 'receivedApplication'
        message.responses = ['interested', 'decline']

        if (message.status === 'interested') {
          message.responses.push('createAgreement')
        }

        message.highlight = message.status === 'pending'
        message.displayBrandName = message.brand_id
          ? message.brand_id === message.invitee_id
            ? message.invitee_brand_name
            : message.host_brand_name
          : message.invitee_brand_name
        message.displayBrandLogo = message.brand_id
          ? message.brand_id === message.invitee_id
            ? message.invitee_brand_logo
            : message.host_brand_logo
          : message.invitee_brand_logo
        message.heading = `Application: ${message.campaign_name}`

        //Invitation and currentBrand = Host
      } else if (message.application === false && message.invitee_id !== currentBrandId) {
        message.messageType = 'sentInvitation'
        message.responses = ['viewCampaign']
        message.displayBrandName = message.brand_id && message.brand_id === message.invitee_id
          ? message.invitee_brand_name
          : message.host_brand_name
        message.displayBrandLogo = message.brand_id && message.brand_id === message.invitee_id
          ? message.invitee_brand_logo
          : message.host_brand_logo
        message.heading = `Invitation: ${message.campaign_name}`

        // Application and currentBrand = Partner
      } else if (message.application === true && message.invitee_id === currentBrandId) {
        message.messageType = 'sentApplication'
        message.responses = ['viewCampaign']
        message.displayBrandName = message.brand_id
          ? message.brand_id === message.invitee_id
            ? message.invitee_brand_name
            : message.host_brand_name
          : message.invitee_brand_name
        message.displayBrandLogo = message.brand_id
          ? message.brand_id === message.invitee_id
            ? message.invitee_brand_logo
            : message.host_brand_logo
          : message.invitee_brand_logo
        message.heading = `Application: ${message.campaign_name}`
      }
    } else if (
      message.partnership_action === 'host_shared_agreement'
      && message.invitee_id === currentBrandId
    ) {
      message.messageType = 'agreementAction'
      message.responses = ['viewAgreement']
      message.displayBrandLogo = message.brand_id && message.brand_id === message.invitee_id
        ? message.invitee_brand_logo
        : message.host_brand_logo
      message.displayBrandName = message.brand_id
        ? message.brand_id === message.invitee_id
          ? message.invitee_brand_name
          : message.host_brand_name
        : message.invitee_brand_name
      message.heading = `Action Required: Partnership Agreement for ${message.campaign_name}`
    } else if (
      message.partnership_action === 'partner_signed'
      && message.invitee_id !== currentBrandId
    ) {
      message.messageType = 'agreementAction'
      message.responses = ['signAgreement']
      message.displayBrandLogo = message.brand_id && message.brand_id === message.invitee_id
        ? message.invitee_brand_logo
        : message.host_brand_logo
      message.displayBrandName = message.brand_id
        ? message.brand_id === message.invitee_id
          ? message.invitee_brand_name
          : message.host_brand_name
        : message.invitee_brand_name
      message.heading = `${message.invitee_brand_name} Has Signed Your Partnership Agreement for ${message.campaign_name}`
    } else if (
      message.partnership_action === 'partner_confirmed'
      && message.invitee_id === currentBrandId
    ) {
      message.messageType = 'agreementAction'
      message.responses = ['viewAgreement', 'viewCampaign']
      message.displayBrandLogo = message.brand_id && message.brand_id === message.invitee_id
        ? message.invitee_brand_logo
        : message.host_brand_logo
      message.displayBrandName = message.brand_id
        ? message.brand_id === message.invitee_id
          ? message.invitee_brand_name
          : message.host_brand_name
        : message.invitee_brand_name
      message.heading = `You Are a Confirmed Partner on ${message.campaign_name}`
    } else if (
      message.partnership_action === 'partner_indicate_interested'
      && message.invitee_id !== currentBrandId
    ) {
      message.messageType = 'agreementAction'
      message.responses = ['createAgreement']
      message.displayBrandLogo = message.brand_id && message.brand_id === message.invitee_id
        ? message.invitee_brand_logo
        : message.host_brand_logo
      message.displayBrandName = message.brand_id
        ? message.brand_id === message.invitee_id
          ? message.invitee_brand_name
          : message.host_brand_name
        : message.invitee_brand_name
      message.heading = `${message.invitee_brand_name} is interested in ${message.campaign_name}`
    } else {
      message.messageType = 'normal'
      message.displayBrandName = message.sender_brand_name
      message.displayBrandLogo = message.sender_brand_logo
    }
  } else {
    message.messageType = 'normal'
    message.displayBrandName = message.sender_brand_name
    message.displayBrandLogo = message.sender_brand_logo
  }

  if (message.type === 'newBrandWelcome') {
    message.messageType = 'newBrandWelcome'
    message.responses = ['explore']
    message.heading = 'Welcome to DojoMojo!'
    message.partnership_action = true
  }

  return message
}

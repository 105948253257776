import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { DropdownButtonV2 } from 'components/buttons'
import { SMSEditorContext } from 'contexts/SMSEditorContext'
import ImageSelector from './utils/ImageSelector'
import EmojiSelector from './utils/EmojiSelector'
import LinkInsert from './utils/LinkInsert'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    height: 32,
    borderBottom: `1px solid ${theme.colors.silver}`,
    alignItems: 'center',
    padding: '0 6px',
  },
  separator: {
    height: '100%',
    marginLeft: 6,
    marginRight: 4,
    borderRight: `1px solid ${theme.colors.silver}`,
  },
}))

function SMSUtilsBar() {
  const {
    actions: { onAddTag },
    mergeTags,
  } = React.useContext(SMSEditorContext)
  const css = useStyles()

  let tags
  if (Array.isArray(mergeTags)) tags = mergeTags
  else {
    tags = {}
    Object.keys(mergeTags).forEach(key => {
      tags[key.replace(/^\*/, '')] = mergeTags[key]
    })
  }

  return (
    <div className={css.wrapper}>
      <ImageSelector />
      <LinkInsert />
      <EmojiSelector />
      <div className={css.separator} />
      <DropdownButtonV2 label="Merge Tags" options={tags} onSelect={onAddTag} />
    </div>
  )
}

export default React.memo(SMSUtilsBar)

import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import MobileDesktopRadioButtons from './MobileDesktopRadioButtons'
import { BuilderContainerContext } from '../BuilderContainerContext'

const useStyles = makeStyles(theme => ({
  previewContainer: {
    flex: 1,
    backgroundColor: ({ bg }) => bg || theme.colors.charcoalBase,
  },
}))

function BuilderPreviewContent() {
  const previewRef = React.useRef(null)
  const { config } = React.useContext(BuilderContainerContext)
  const {
    preview: { component: previewComponent },
  } = config

  const css = useStyles({ bg: config.preview.colors?.backgroundColor })

  const container = previewRef.current
  const [mobileDesktopRadioButtons, preview] = container?.children || [null, null]
  const previewNavHeight = mobileDesktopRadioButtons?.offsetHeight
  useEffect(() => {
    if (container && preview) {
      preview.style.height = `calc(100% - ${previewNavHeight + 12}px)` // 12px extra to account for margin
    }
  }, [container, preview, previewNavHeight])

  return (
    <div ref={previewRef} className={css.previewContainer}>
      <MobileDesktopRadioButtons />
      {previewComponent}
    </div>
  )
}

export default React.memo(BuilderPreviewContent)

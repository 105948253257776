import React from 'react'
import PropTypes from 'prop-types'
import ButtonBase from '@material-ui/core/ButtonBase'
import { makeStyles } from '@material-ui/core/styles'

import Text from 'components/texts/Text'

const useStyles = makeStyles(theme => ({
  imagePreview: {
    border: `1px solid ${theme.colors.skyDarker}`,
    borderRadius: 4,
  },
  imageSection: {
    height: 124,
    padding: 20,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  image: {
    backgroundImage: ({ imageUrl }) => `url(${imageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: 2,
    border: `1px solid ${theme.colors.skyBase}`,
    height: 84,
    width: 84,
    marginRight: 32,
  },
  imageName: {
    overflow: 'hidden',
    flex: 1,
  },
  buttonsSection: {
    display: 'flex',
  },
  button: {
    borderTop: `1px solid ${theme.colors.skyDarker}`,
    '&:first-child': {
      borderRight: `1px solid ${theme.colors.skyDarker}`,
    },
    height: 60,
    flex: 1,
    display: 'grid',
    placeContent: 'center',
  },
}))

function ContentImagePreview(props) {
  const {
    onDelete, onChange, imageUrl, imageName,
  } = props
  const css = useStyles({ imageUrl })

  return (
    <div className={css.imagePreview}>
      <div className={css.imageSection}>
        <div className={css.image} />
        <div className={css.imageName}>
          <Text ellipsis charcoalBase>
            {imageName}
          </Text>
        </div>
      </div>
      <div className={css.buttonsSection}>
        <ButtonBase className={css.button} onClick={onDelete}>
          <Text redDarker>Remove</Text>
        </ButtonBase>
        <ButtonBase className={css.button} onClick={onChange}>
          <Text>Change</Text>
        </ButtonBase>
      </div>
    </div>
  )
}

ContentImagePreview.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
  imageName: PropTypes.string.isRequired,
}

export default React.memo(ContentImagePreview)

import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

import ActionBanner from './ActionBanner'

// Must match ESPIntegrationsModal.js:58
const QUERY_PROP_NAME = 'configEsp'

function ESPNotSupportedBanner() {
  const { espIntegration, values } = React.useContext(BuilderContainerContext)
  const { ESP } = values
  const { canSupportESP, isLoading } = espIntegration
  const history = useHistory()
  const [show, setShow] = useState(!canSupportESP(ESP?.key))

  if (isLoading || !show || canSupportESP(ESP?.key)) return null

  if (!ESP) return null // no esp on this brand, no need to show banner

  const onOpenEspModal = () => {
    history.push(`${history.location.pathname}?${QUERY_PROP_NAME}=true`)
  }

  return (
    <ActionBanner
      show={show}
      align="flex-start"
      buttonRight={false}
      onClose={() => setShow(false)}
      type="alert"
      actionLabel="Integrate ESP"
      action={onOpenEspModal}
      title="ESP not supported"
      body="Your current ESP provider is not supported for this builder. You can integrate another ESP."
    />
  )
}

export default React.memo(ESPNotSupportedBanner)

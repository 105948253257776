import { useMemo, useEffect } from 'react'
import { withRouter } from 'react-router-dom'

function GoogleAnalytics({ location, history }) {
  const { pathname, search, hash } = location

  const page_path = useMemo(() => history.createHref({ pathname, search, hash }), [
    pathname,
    search,
    hash,
    history,
  ])

  useEffect(() => {
    if (window.gtag) {
      window.gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS_ID, {
        page_path,
      })
    }
  }, [page_path])

  return null
}

export default withRouter(GoogleAnalytics)

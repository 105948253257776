import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import useIframe from 'hooks/useIframe'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import LightboxBuilderPreviewChangePageArrows from './LightboxBuilderPreviewChangePageArrows'

const useStyles = makeStyles({
  lightboxPreview: {
    position: 'relative',
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > iframe': {
      height: '100%',
      width: '100%',
      border: 'none',
    },
  },
})

function LightboxBuilderPreview() {
  const css = useStyles()
  const { lightboxSettings, values, builder } = React.useContext(BuilderContainerContext)
  const { type: viewMode } = builder.preview
  const settings = lightboxSettings.getSettings()
  const [loaded, setLoaded] = useState(false)

  const onLoad = ({ onSendMessage: sendMessage }) => {
    setLoaded(true)
    sendMessage({ settings: settings.getSaveObj() })
  }

  const onMessageReceived = data => {
    lightboxSettings.setSettings(data.settings)
    // eslint-disable-next-line no-self-assign
    settings.name = settings.name // wysiwyg doesn't update isUpdated flag, this forces it to do so
  }

  const iframeHook = useIframe({
    src: `${process.env.REACT_APP_ENV_LIGHTBOX_URL}/index.html?mode=edit`,
    type: 'builder',
    onLoad,
    onMessageReceived,
  })

  const { containerRef, onSendMessage } = iframeHook

  // updates lightbox in iframe
  if (loaded) {
    onSendMessage({
      settings: settings.getSaveObj(),
      viewMode,
      page: values.selectedPage,
    })
  }

  // sets page on first load
  useEffect(() => {
    if (loaded) onSendMessage({ page: values.selectedPage })
  }, [loaded]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div ref={containerRef} className={css.lightboxPreview}>
      <LightboxBuilderPreviewChangePageArrows />
    </div>
  )
}

export default React.memo(LightboxBuilderPreview)

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Text from 'components/texts/Text'

const useStyles = makeStyles(theme => ({
  loading: {
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1000,
    backgroundColor: theme.colors.charcoalBase,
    display: 'grid',
    placeContent: 'center',
  },
  loadingComponents: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
  },
  loadingText: {
    marginTop: 48,
  },
  icon: {
    animation: '$loading .5s linear infinite',
  },
  '@keyframes loading': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(-360deg)' },
  },
}))

function BuilderLoading(props) {
  const css = useStyles()
  const { loadingText } = props

  return (
    <div className={css.loading}>
      <div className={css.loadingComponents}>
        <svg
          className={css.icon}
          width="148"
          height="148"
          viewBox="0 0 148 148"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 74C12 39.7583 39.7583 12 74 12"
            stroke="url(#paint0_linear)"
            strokeWidth="12"
          />
          <path
            d="M136 74C136 108.242 108.242 136 74 136C39.7583 136 12 108.242 12 74"
            stroke="#03A9F4"
            strokeWidth="12"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="17.6364"
              y1="52.2597"
              x2="69.1688"
              y2="17.6364"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#03A9F4" />
              <stop offset="0.71875" stopColor="white" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
        {loadingText && (
          <div className={css.loadingText}>
            <Text size={24} white>
              {loadingText}
            </Text>
          </div>
        )}
      </div>
    </div>
  )
}

BuilderLoading.propTypes = {
  loadingText: PropTypes.string,
}

BuilderLoading.defaultProps = {
  loadingText: '',
}

export default React.memo(BuilderLoading)

import React, { useEffect, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { ClickAwayListener, Menu, MenuItem } from '@material-ui/core'
import cn from 'classnames'

import Text from 'components/texts/Text'

const useStyles = makeStyles(theme => ({
  dropdownWrapper: ({ fullWidth }) => {
    if (fullWidth) return { flex: 1, width: '100%' }
    return {}
  },
  menu: {
    padding: '12px 0',
    minWidth: 180,
    maxHeight: 524,
    marginTop: 52,
  },
  item: {
    width: ({ type }) => (type === 'noborder' ? '100% !important' : undefined),
    height: 48,
    display: 'flex',
    alignItems: 'center',
    padding: '12px 24px',
    '&:hover': { backgroundColor: theme.colors.skyLight },
  },
  selected: {
    backgroundColor: theme.colors.skyBase,
  },
}))

function DropdownBase(props) {
  const {
    dropdownStyles,
    options,
    value: currentValue, // TODO: To be removed
    defaultValue,
    onChange,
    disabled,
    fullWidth,
    children,
    type,
    textProps,
    label,
    placeholder,
  } = props

  const optionsAsObjects = useMemo(
    () =>
      options.map(option =>
        (typeof option === 'string' ? { value: option, label: option } : option)),
    [options]
  )

  const dropdownRef = React.useRef(null)
  const [selected, setSelected] = useState(null)
  const [open, setOpen] = useState(false)
  const css = useStyles({
    disabled,
    fullWidth,
    open,
    type,
  })

  const onToggle = () => !disabled && setOpen(!open)
  const onClose = () => !disabled && setOpen(false)
  const onSelect = option => {
    if (!disabled && !option.disabled) {
      setSelected(option)
      onChange(option)
      onClose()
    }
  }

  const dropdownItems = (
    <Menu classes={{ paper: css.menu }} anchorEl={dropdownRef.current} open={open}>
      {optionsAsObjects.map(option => {
        const { value: optionValue, label: optionLabel } = option
        const isSelected = optionValue === selected
        const isDisabled = option.disabled
        const color = isDisabled ? 'charcoalLighter' : 'navyBase'
        return (
          <MenuItem
            key={optionValue}
            style={{ width: Math.max(dropdownRef.current?.offsetWidth ?? -Infinity, 180) }}
            onClick={() => onSelect(option)}
            classes={{ root: cn(css.item, { [css.selected]: isSelected }) }}
            className={css.item}
          >
            <Text {...{ [color]: true }} ellipsis>
              {optionLabel}
            </Text>
          </MenuItem>
        )
      })}
    </Menu>
  )

  // selects default value if not provided with default value
  useEffect(() => {
    if (selected || (placeholder && !defaultValue)) return

    if (optionsAsObjects.length && defaultValue) {
      const hasDefault = ![undefined, '', null].includes(defaultValue)
      let selectedOption
      if (hasDefault) {
        selectedOption = optionsAsObjects.find(option => option.value === defaultValue)
      } else {
        [selectedOption] = optionsAsObjects
      }
      setSelected(selectedOption)
      return
    }

    // TODO: Remove this part of the logic,
    // TODO! defaultValue has the same functionality as value/currentValue
    if (currentValue !== null) {
      // sets value assigned to value
      setSelected(optionsAsObjects.find(option => currentValue === option.value))
    }
  }, [selected, defaultValue, optionsAsObjects, placeholder, currentValue])

  const dropdownComponent = React.cloneElement(children, {
    dropdownStyles,
    textProps,
    label: selected?.label, // handles null if not selected
    disabled,
    onToggle,
    fullWidth,
    placeholder,
    open,
    isPlaceholder: !selected,
  })

  const dropdownLabel = label ? (
    <div className={css.label}>
      <Text charcoalBase>{label}</Text>
    </div>
  ) : null

  return (
    <div className={css.dropdownWrapper} ref={dropdownRef}>
      <ClickAwayListener onClickAway={onClose}>
        <div>
          {dropdownLabel}
          {dropdownComponent}
        </div>
      </ClickAwayListener>
      {dropdownItems}
    </div>
  )
}

DropdownBase.propTypes = {
  dropdownStyles: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        label: PropTypes.string,
        disabled: PropTypes.bool,
      }),
      PropTypes.string,
    ])
  ).isRequired,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.node.isRequired,
  defaultValue: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  textProps: PropTypes.object,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
}

DropdownBase.defaultProps = {
  dropdownStyles: '',
  disabled: false,
  fullWidth: false,
  textProps: null,
  value: null,
  label: null,
  onChange() {},
}

export default React.memo(DropdownBase)

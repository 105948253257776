import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Text from 'components/texts/Text'

const useStyles = makeStyles(theme => ({
  installation: {
    flex: 1,
    border: `1px solid ${theme.colors.skyBase}`,
    borderRadius: 4,
    padding: 20,
    height: 'fit-content',
    alignSelf: 'stretch',
    position: 'relative',
    display: 'grid',
    gridRowGap: '20px',
    maxWidth: 384,
  },
  textGroup: {
    flex: 1,
    '& > :first-child': {
      marginBottom: 12,
    },
  },
  linkGridRow: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '32px 1fr',
    gridColumnGap: 8,
    alignItems: 'center',
    '&:first-of-type': {
      marginBottom: 12,
      '& > img': {
        width: 24,
      },
    },
  },
  logo: {
    width: 32,
    justifySelf: 'center',
  },
}))

function LightboxBuilderInstallationKnowledgeBase() {
  const css = useStyles()

  const articleLinks = [
    {
      copy: 'How to install on Wordpress',
      href:
        'https://help.dojomojo.com/hc/en-us/articles/360061127512-Publish-Signup-Forms-to-Wordpress',
      image: {
        src: '/images/brand-logos/wordpress-simple.svg',
        alt: 'Wordpress logo',
      },
    },
    {
      copy: 'How to install on Shopify',
      href:
        'https://help.dojomojo.com/hc/en-us/articles/360061228292-Publish-Signup-Forms-to-Shopify',
      image: {
        src: '/images/brand-logos/shopify-simple.svg',
        alt: 'Shopify logo',
      },
    },
  ]

  return (
    <div className={css.installation}>
      <div className={css.textGroup}>
        <Text size={16} charcoalDarker>
          Knowledge Base
        </Text>
        <Text charcoalLight>
          Installing the signup form may vary across different website builders. Visit our Knowledge
          Base for more detailed
          {' '}
          <a
            href="https://help.dojomojo.com/hc/en-us/articles/360056170991-Installing-the-Lightbox-on-Your-Website"
            target="_blank"
            rel="noopener noreferrer"
          >
            installation instructions
          </a>
          .
        </Text>
      </div>

      <div className={css.textGroup}>
        <Text size={16} charcoalDarker>
          Publish Signup Forms to Wordpress or Shopify
        </Text>
        <Text charcoalLight>
          If you’re using Wordpress or Shopify, view our installation guides for those platforms.
        </Text>
      </div>

      <div className={css.textGroup}>
        {articleLinks.map(link => (
          <a
            className={css.linkGridRow}
            href={link.href}
            target="_blank"
            rel="noopener noreferrer"
            key={link.copy}
          >
            <img src={link.image.src} alt={link.image.alt} className={css.logo} />
            <span>{link.copy}</span>
          </a>
        ))}
      </div>
    </div>
  )
}

export default React.memo(LightboxBuilderInstallationKnowledgeBase)

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { IconButton, makeStyles } from '@material-ui/core'

import MyAccountMenu from 'components/menus/MyAccountMenu'

import mobileMenuIcon from 'images/nav-bar/mobile-menu-icon.svg'

import useMobileDetect from 'hooks/useMobileDetect'

import { onMenuClick } from './commonUtils'

const useStyles = makeStyles(() => ({
  logo: {
    objectFit: 'contain',
    height: '100%',
    width: '100%',
    backgroundColor: 'white',
  },
  logoWrapper: {
    borderRadius: '50%',
    overflow: 'hidden',
    height: 36,
    minWidth: 36,
  },
}))

function NavBarCurrentBrandIcon(props) {
  const { currentBrand, managedBrands } = props
  const [open, setOpen] = useState(false)
  const css = useStyles()

  const useMobile = useMobileDetect()
  const isMobile = useMobile.isMobile()

  const onBrandMenuClick = onMenuClick(setOpen, 'Brand Menu')

  return (
    <>
      <IconButton
        onClick={open ? null : onBrandMenuClick}
        color="inherit"
        className={css.iconWrapper}
        id="my-account"
      >
        <div className={css.logoWrapper}>
          <img
            src={isMobile || currentBrand.currentBrandLoading ? mobileMenuIcon : currentBrand.logo}
            className={isMobile ? null : css.logo}
            alt="My Account"
          />
        </div>
      </IconButton>
      <MyAccountMenu
        isMobile={isMobile}
        currentBrand={currentBrand}
        managedBrands={managedBrands}
        open={open}
        onClose={onBrandMenuClick}
      />
    </>
  )
}

NavBarCurrentBrandIcon.propTypes = {
  managedBrands: PropTypes.array.isRequired,
  currentBrand: PropTypes.object.isRequired,
}

export default NavBarCurrentBrandIcon

import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { v4 as uuid } from 'uuid'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import Text from 'components/texts/Text'
import AddButton from 'components/virtual/buttons/AddButton'
import RadioGroup from 'components/virtual/buttons/RadioGroup'

import UrlPathInput from './UrlPathInput'

const useStyles = makeStyles({
  urlList: {
    marginTop: 32,
  },
  addButton: { marginTop: 24 },
  blockTitle: { marginBottom: 8 },
})

const URL_RULES = [
  { value: 'all pages', label: 'Show on all pages (default)' },
  { value: 'whitelist', label: 'Show only on certain pages' },
  { value: 'blacklist', label: 'Hide on certain pages' },
]

function AllowOrHideUrlList() {
  const { builder, lightboxSettings } = React.useContext(BuilderContainerContext)
  const [listType, setListType] = useState(null)
  const [listToUse, setListToUse] = useState([])

  const settings = lightboxSettings.getSettings()

  const { errors } = builder
  const isError = errors.verify('domain')

  const css = useStyles()

  const listName = listType ? (listType === 'whitelist' ? 'allowListUrls' : 'denyListUrls') : ''

  const showListOptions = listType !== 'all pages'

  const addToList = () => {
    if (showListOptions) {
      const newPathObject = { type: 'exact', path: '', key: uuid() }
      setListToUse(list => [...list, newPathObject])
    }
  }

  const removeFromList = key => {
    if (showListOptions) {
      setListToUse(list => list.filter(path => path.key !== key))
    }
  }

  const onPathChange = (key, newPathObj) => {
    if (showListOptions) {
      const onMapPathChange = path => (key === path.key ? { ...path, ...newPathObj } : path)
      setListToUse(list => list.map(onMapPathChange))
    }
  }

  // sets list type on mount
  useEffect(() => {
    if (settings.trigger.allowListUrls.length) setListType('whitelist')
    else if (settings.trigger.denyListUrls.length) setListType('blacklist')
    else setListType('all pages')
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // sets list to use on mount
  useEffect(() => {
    if (listType) {
      const list = showListOptions ? settings.trigger[listName] : []
      setListToUse(list.map(listObj => ({ ...listObj, key: uuid() })))
    }
  }, [listType, listName, showListOptions, settings])

  // sets list when unmounted
  useEffect(
    () => () => {
      const { domain } = settings

      const mapFn = pathObj => {
        const { type, path } = pathObj
        if (type === 'contains') return { path, type }
        const domainRegex = new RegExp(domain)
        const match = domainRegex.exec(path)
        const sliceIndex = match ? match.index + domain.length : 0
        let newPath = path.slice(sliceIndex) // strips homepage from url
        const anotherDomainRegex = /((https?:\/\/)?.*?\/)/
        if (anotherDomainRegex.test(newPath)) {
          const { 0: domainMatch, index } = anotherDomainRegex.exec(newPath)
          newPath = newPath.slice(index + domainMatch.length)
        }

        // Add slash before appending to object
        if (!newPath.startsWith('/')) newPath = `/${newPath}`

        return { path: newPath, type }
      }

      if (listType) {
        const trigger = {}
        if (listType === 'all pages') {
          trigger.allowListUrls = []
          trigger.denyListUrls = []
        } else {
          const listToDelete = listType === 'blacklist' ? 'allowListUrls' : 'denyListUrls'
          const paths = showListOptions ? listToUse.filter(path => !!path.path) : []
          const mappedPaths = paths.map(mapFn)

          trigger[listName] = mappedPaths // sets/unsets previous used list
          trigger[listToDelete] = [] // unsets list that's not in use
        }

        settings.trigger = { ...trigger }
      }
    },
    [listName, listToUse, listType, settings, showListOptions]
  )

  const actionTitle = listType === 'whitelist' ? 'Show on:' : 'Hide on:'

  return (
    <div>
      <div className={css.blockTitle}>
        <Text charcoalDarker size={16}>
          Rules
        </Text>
      </div>
      <RadioGroup values={URL_RULES} value={listType} onChange={setListType} />
      {showListOptions && (
        <div className={css.urlList}>
          <Text charcoalBase>{actionTitle}</Text>
          {listToUse.map(path => (
            <UrlPathInput
              key={path.key}
              isError={isError}
              path={path}
              onChange={value => onPathChange(path.key, value)}
              onDelete={() => removeFromList(path.key)}
              disabled={isError}
            />
          ))}
          <div className={css.addButton}>
            <AddButton disabled={isError} onClick={addToList} label="Add URL" />
          </div>
        </div>
      )}
    </div>
  )
}

export default React.memo(AllowOrHideUrlList)

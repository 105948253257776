import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { useFormState } from 'react-final-form'
import _ from 'lodash'

import { P } from 'components/texts'

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginTop: 32,
    position: 'relative',
    padding: '8px 12px',
    backgroundColor: theme.colors.crystal,
    borderRadius: 2,
  },
}))

const FOLLOWUP_CRITERIA_LANGUAGE = {
  'all recipients': 'all subscribers',
  'not clicked': "subscribers that haven't clicked",
  'not purchased': "subscribers that haven't purchased",
  'not clicked or purchased': "subscribers that haven't clicked or purchased",
  'clicked but not purchased': "subscribers that have clicked but haven't purchased",
}

// TODO: Change stuff in here
function SendFollowupCriteria({ name }) {
  const css = useStyles()
  const { values } = useFormState()
  const { followup_criteria } = _.get(values, name)

  return (
    <div className={css.wrapper}>
      <P>{`Send to ${FOLLOWUP_CRITERIA_LANGUAGE[followup_criteria]}`}</P>
    </div>
  )
}

SendFollowupCriteria.propTypes = {
  name: PropTypes.string.isRequired,
}

export default React.memo(SendFollowupCriteria)

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { ButtonBase } from '@material-ui/core'
import { DeleteOutline as DeleteIcon } from '@material-ui/icons'

import Input from 'components/virtual/inputs/Input'
import useInputDebounce from 'hooks/useInputDebounce'
import VirtualTheme from '../VirtualTheme'

const useStyles = makeStyles({
  inputWithDelete: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: 20,
    '& > :first-child': {
      marginRight: 12,
      flex: 1,
    },
  },
  icon: {
    height: 40,
    width: 40,
    borderRadius: '50%',
    margin: '0 12px 12px',
  },
})

// eslint-disable-next-line react/prop-types
const DeleteButton = ({ buttonClass, onDelete }) => (
  <ButtonBase className={buttonClass} onClick={onDelete}>
    <DeleteIcon />
  </ButtonBase>
)

function InputWithDelete(props) {
  const css = useStyles()

  const {
    value, placeholder, label, onChange, onDelete, compact, controlled, ...restProps
  } = props
  const [text, onInputChange] = useInputDebounce(value, onChange, controlled)

  return (
    <VirtualTheme>
      <div className={css.inputWithDelete}>
        <Input
          compact={compact}
          fullWidth
          label={label}
          placeholder={placeholder}
          value={text}
          onChange={onInputChange}
          {...restProps}
        />
        <DeleteButton buttonClass={css.icon} onDelete={onDelete} />
      </div>
    </VirtualTheme>
  )
}

InputWithDelete.propTypes = {
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  compact: PropTypes.bool,
  controlled: PropTypes.bool,
}

InputWithDelete.defaultProps = {
  compact: false,
  controlled: false,
}

export default React.memo(InputWithDelete)

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { useFormState } from 'react-final-form'

import { ReactComponent as ErrorIcon } from 'images/icons/error-complex.svg'

import { Spinner } from 'components/spinners'
import { MobileModal } from 'components/modals'
import Badge from 'components/shared/Badge'

import SMSInfoBanner from 'views/mobile/shared/SMSInfoBanner'
import CustomBar from 'views/mobile/sms-marketing/shared/utils/CustomBar'

import CreateLightboxButtonGroup from './CreateLightboxButtonGroup'
import CreateEditLightbox from './CreateEditLightbox'
import PreviewLightbox from './PreviewLightbox'

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    '& > div': {
      maxWidth: 1200,
      margin: '32px auto 0',
      // not banners div and not placeholder div (<div />)
      '&:not(:first-child):not(:last-child)': {
        background: 'white',
        padding: 40,
        outline: `1px solid ${theme.colors.silver}`,
      },
    },
    '& + div': {
      // prevents emoji to pickaboo
      zIndex: 1,
    },
    paddingBottom: 70,
  },
  badge: {
    marginLeft: 12,
  },
}))

const CreateSMSMessageModal = props => {
  const {
    saveAndExit,
    onClose, // onClose, check for warningModal if form is dirty (fix this, too)
  } = props
  const { values } = useFormState()
  const {
    haveEnoughCredits,
    title,
    isReview,
    isSaving,
    isLoading,
    isBuilder,
    lightboxSendSettings,
    // SUBMIT_ID,
  } = values

  const BANNERS = {
    almostOutCredits: {
      title: 'You don’t have enough credits',
      body: 'Your balance is too low to send. Select an SMS Marketing plan to continue!',
      cta: {
        label: 'Upgrade Your Subscription & Select an SMS Marketing Plan',
        url: '/manage-sms-marketing',
      },
      type: 'warning',
      border: true,
      icon: <ErrorIcon />,
    },
  }

  const css = useStyles({ isReview })

  const previewOnly = lightboxSendSettings.status !== 'draft'
  let showBadge = previewOnly || (isReview && lightboxSendSettings.status === 'draft')
  showBadge = showBadge && !isBuilder

  const CustomBarComponent = () => (
    <CustomBar
      title={title}
      saveAndExit={saveAndExit}
      preview
      isLoading={isLoading}
      previewOnly={previewOnly || isReview || isBuilder}
      badge={
        showBadge ? (
          <div className={css.badge}>
            <Badge type={lightboxSendSettings.status} />
          </div>
        ) : null
      }
    />
  )

  return (
    <>
      <MobileModal
        full
        closeButton
        noPad
        open
        customBarComponent={<CustomBarComponent />}
        onClose={onClose}
        newDesign
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <div className={css.wrapper}>
              <div>
                {/* Top Banners go here */}
                {isSaving && <Spinner />}
                {!haveEnoughCredits && <SMSInfoBanner {...BANNERS.almostOutCredits} />}
              </div>
              {// if no credits, it locks the brand from changing anything here
              isReview || !haveEnoughCredits ? <PreviewLightbox /> : <CreateEditLightbox />}
              <div />
            </div>
            {haveEnoughCredits && <CreateLightboxButtonGroup isBuilder={isBuilder} />}
          </>
        )}
      </MobileModal>
    </>
  )
}

CreateSMSMessageModal.propTypes = {
  saveAndExit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default React.memo(CreateSMSMessageModal)

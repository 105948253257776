import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import SMSEditorProvider, { SMSEditorContext } from 'contexts/SMSEditorContext'
import SMSUtilsBar from './SMSUtilsBar'
import SMSEditorFooter from './SMSEditorFooter'
import SMSEditorBody from './SMSEditorBody'

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: 'auto',
    minHeight: 212,
    borderRadius: 2,
    backgroundColor: 'white',
    border: `1px solid ${theme.colors.silver}`,
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'stretch',
  },
}))

function SMSEditor(props) {
  const css = useStyles()

  return (
    <SMSEditorProvider {...props}>
      <div className={css.wrapper}>
        <SMSUtilsBar />
        <SMSEditorContext.Consumer>
          {({ actions, ...values }) => <SMSEditorBody {...{ actions, ...values }} />}
        </SMSEditorContext.Consumer>
        <SMSEditorFooter />
      </div>
    </SMSEditorProvider>
  )
}

export default React.memo(SMSEditor)

import React from 'react'
import PropTypes from 'prop-types'
import { Chip as MuiChip, makeStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'

const useStyles = makeStyles(() => ({
  chipContainer: {
    marginRight: 4,
    marginBottom: 4,
  },
}))

function Chip(props) {
  const classes = useStyles()
  const { label, onDelete, onClick } = props

  return (
    <div onClick={onClick} className={classes.chipContainer}>
      <MuiChip deleteIcon={<Close />} label={label} onDelete={onDelete} />
    </div>
  )
}

Chip.propTypes = {
  label: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  onClick: PropTypes.func,
}

Chip.defaultProps = {
  label: '',
  onClick: null,
}

export default React.memo(Chip)

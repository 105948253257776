import { useEffect, useState } from 'react'

import fieldConf from 'views/mobile/lightbox-builder/utils/field-options'

const { fields: templateFields } = fieldConf

let inputCount = 0
let nameCount = 1

// TODO: might rename hook to useLightboxInputTemplates instead?
function useLightboxFields(lightboxSettings) {
  const settings = lightboxSettings.getSettings()
  const [selectedTemplates, setSelectedTemplates] = useState([])

  // get field labels, compare in order to disable from list
  const getFieldsInUse = () => {
    const labels = settings?.pages.reduce(
      (allFields, page) =>
        [].concat(
          allFields,
          page.inputs.reduce((inputs, input) => [].concat(inputs, input.label), [])
        ),
      []
    ) || []

    return []
      .concat(
        labels,
        selectedTemplates.map(field => field.label)
      )
      .filter(Boolean)
  }

  // inserts inputs into already selected fields, from the dropdown
  const onAddFieldToList = fieldName => {
    const fieldsInUse = getFieldsInUse()
    const input = lightboxSettings.getInput(fieldName)
    if (input.isTemplate && fieldsInUse.includes(input.label)) return
    input.name = fieldName
    // add counter
    if (!input.isTemplate) {
      input.label = `Untitled ${nameCount++}`
      input.placeholder = 'untitled'
      input.name = `${input.name}_${inputCount++}`
    }
    setSelectedTemplates([...selectedTemplates, input])
  }

  // inserts inputs back to already selected fields list
  const insertFieldsBackToList = inputs => {
    setSelectedTemplates(oldTemplates => [...oldTemplates, ...inputs])
  }

  // removes them from the already selected fields list
  const onRemoveFieldsFromList = field => {
    let items = [field]
    if (Array.isArray(field)) items = field
    items.forEach(input =>
      setSelectedTemplates(selectedTemplates.filter(selectedField => selectedField !== input)))
  }

  // moves between page/already selected fields list, generic question
  const onMoveFieldFromList = add => (page, input) => {
    page[add ? 'addInput' : 'removeInput'](input)
    if (add) {
      setSelectedTemplates(oldSelectedFields => oldSelectedFields.filter(field => field !== input))
    } else onRemoveFieldsFromList(input)
  }

  const onInsertIntoPage = onMoveFieldFromList(true)
  const onRemoveFromPage = onMoveFieldFromList(false)

  // disable fields in dropdown list
  const getFieldsForDropdownList = () => {
    const fieldsInUse = getFieldsInUse()
    const disableFields = field => ({ ...field, selected: fieldsInUse?.includes(field.label) })

    return {
      standard: templateFields.STANDARD_FIELDS.map(disableFields),
      custom: templateFields.CUSTOM_FIELDS,
    }
  }

  const setInputNames = () => {
    if (settings) {
      settings.pages.forEach(page => {
        page.inputs.forEach(input => {
          if (input.label && input.label.includes('Phone')) input.name = 'phone'
          else if (!input.isTemplate) {
            const name = `${input.type[0].toUpperCase()}${input.type.slice(1)}`
            input.name = `custom${name}_${inputCount++}`
          } else {
            Object.values(templateFields).forEach(templates => {
              const template = templates.find(field => field.label === input.label)
              if (template) input.name = template.name
              if (input.name === 'zipCode') input.fixedName = input.name.toLowerCase() // TODO: Coordinate with Aleksey to agree ont his field name
            })
          }
        })
      })
    }
  }

  // Attaches names to default inputs
  // TODO: Further discuss with Aleksey
  useEffect(() => {
    setInputNames()
  }, [settings]) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    values: {
      templates: getFieldsForDropdownList(),
      selectedTemplates,
    },
    actions: {
      onAddFieldToList,
      onRemoveFieldsFromList,
      onInsertIntoPage,
      onRemoveFromPage,
      setInputNames,
      insertFieldsBackToList,
    },
  }
}

export default useLightboxFields

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import uid from 'uuid/v4'

import Drag from './Drag'
import SortableDrag from './SortableDrag'

const useStyles = makeStyles({
  dropWrapper: {
    display: 'grid',
    gridRowGap: '12px',
    padding: '12px',
  },
})

function DragDropContainer(props) {
  const {
    data, itemRender, sortable, onSort, children,
  } = props

  const { current: containerId } = React.useRef(uid())

  const css = useStyles()

  const DragComponent = sortable && onSort ? SortableDrag : Drag

  return (
    <div className={css.dropWrapper}>
      {data.length
        ? data.map((item, index, itemsList) => (
          <DragComponent
            key={item.uniqueId}
            index={index}
            data={item}
            onSort={onSort}
            containerId={containerId}
          >
            {itemRender(item, index, itemsList)}
          </DragComponent>
        ))
        : children}
    </div>
  )
}

DragDropContainer.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  onSort: PropTypes.func,
  itemRender: PropTypes.func.isRequired,
  children: PropTypes.node,
  sortable: PropTypes.bool,
}

DragDropContainer.defaultProps = {
  data: [],
  onSort: null,
  sortable: true,
  children: [],
}

export default React.memo(DragDropContainer)

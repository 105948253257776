import React from 'react'
import { render } from 'react-dom'

import App from './App'
import * as serviceWorker from './serviceWorker'

// TODO:
// import { version } from '../../version.json'

// TODO: Configure Sentry

// TODO: Configure impersonation

const domElement = document.getElementById('root')

render(<App />, domElement)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import React from 'react'
import PropTypes from 'prop-types'

import CompactBanner from './CompactBanner'

function ImageErrorBanner({ text }) {
  return <CompactBanner type="error" text={text} />
}

ImageErrorBanner.propTypes = {
  text: PropTypes.string,
}

ImageErrorBanner.defaultProps = {
  text: 'File size too large. Must be under 5MB.',
}

export default React.memo(ImageErrorBanner)

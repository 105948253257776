import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Text from 'components/texts/Text'

const useStyles = makeStyles(theme => ({
  emptyState: {
    padding: '16px 20px',
    borderBottom: `1px solid ${theme.colors.skyBase}`,
  },
}))

function ESPFieldValuesEmptyState() {
  const css = useStyles()

  return (
    <div className={css.emptyState}>
      <Text charcoalLight>No field values detected</Text>
    </div>
  )
}

export default React.memo(ESPFieldValuesEmptyState)

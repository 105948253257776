import { useState, useCallback, useRef } from 'react'

import useWindowEvent from './useWindowEvent'

const useWindowResize = () => {
  const [height, setHeight] = useState(window.innerHeight)
  const [width, setWidth] = useState(window.innerWidth)
  const debounceRef = useRef(null)

  const onResize = useCallback(() => {
    clearTimeout(debounceRef.current)

    debounceRef.current = setTimeout(() => {
      setHeight(window.innerHeight)
      setWidth(window.innerWidth)
    }, 50)
  }, [])

  useWindowEvent(window, 'resize', onResize)

  return { height, width }
}

export default useWindowResize

import React from 'react'
import ReactDOM from 'react-dom'
// import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

// import Text from 'components/texts/Text'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

const useStyles = makeStyles({
  loading: {
    width: '100vw',
    height: '100vh',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2000,
    backgroundColor: 'rgba(63, 80, 89, 0.4)', // charcoalBase, opacity 0.4
    display: 'grid',
    placeContent: 'center',
  },
  loadingComponents: {
    transform: 'scale(0.4)',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
  },
  loadingText: {
    marginTop: 48,
  },
  icon: {
    animation: '$loading .5s linear infinite',
    transform: 'scale(0.2)',
  },
  '@keyframes loading': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(-360deg)' },
  },
})

function LightboxBuilderLoading() {
  const css = useStyles()

  const { values } = React.useContext(BuilderContainerContext)

  const { isLoading } = values

  if (!isLoading) return null

  const loadingComponent = (
    <div className={css.loading}>
      <div className={css.loadingComponents}>
        <svg
          className={css.icon}
          width="148"
          height="148"
          viewBox="0 0 148 148"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 74C12 39.7583 39.7583 12 74 12"
            stroke="url(#paint0_linear)"
            strokeWidth="12"
          />
          <path
            d="M136 74C136 108.242 108.242 136 74 136C39.7583 136 12 108.242 12 74"
            stroke="#03A9F4"
            strokeWidth="12"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="17.6364"
              y1="52.2597"
              x2="69.1688"
              y2="17.6364"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#03A9F4" />
              <stop offset="0.71875" stopColor="white" stopOpacity="0" />
            </linearGradient>
          </defs>
        </svg>
        {/* <div className={css.loadingText}>
          <Text size={20} white>
            Processing Changes
          </Text>
        </div> */}
      </div>
    </div>
  )
  return ReactDOM.createPortal(loadingComponent, document.body)
}

// LightboxBuilderLoading.propTypes = {
//   loadingText: PropTypes.string,
// }

// LightboxBuilderLoading.defaultProps = {
//   loadingText: '',
// }

export default React.memo(LightboxBuilderLoading)

import React from 'react'
import PropTypes from 'prop-types'

import { PageTitle } from 'components/builder/titles'

function FormPagesTitle({ pageCopy }) {
  const { text, label, kbLink } = pageCopy.tooltip

  const onTooltipClick = () => window.open(kbLink, '_blank')

  const tooltip = {
    text,
    action: onTooltipClick,
    actionLabel: label,
  }

  const title = `Form ${pageCopy.key}s`

  return <PageTitle textProps={{ size: 16, charcoalBase: true }} text={title} tooltip={tooltip} />
}

FormPagesTitle.propTypes = {
  pageCopy: PropTypes.object.isRequired,
}

export default React.memo(FormPagesTitle)

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { ButtonBase, ClickAwayListener } from '@material-ui/core'
import {
  DeleteOutline as DeleteIcon,
  Create as EditIcon,
  DragIndicator as DragIcon,
  Error as ErrorIcon,
} from '@material-ui/icons'
import cn from 'classnames'

import Text from 'components/texts/Text'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

// TODO: Hide component when dragging
const useStyles = makeStyles(theme => ({
  container: {
    cursor: 'pointer',
    height: 52,
    display: 'flex',
    alignItems: 'center',
    padding: '20px 20px 20px 3px',
    backgroundColor: ({ focus }) => (focus ? theme.colors.navyLightest : 'white'),
    border: ({ focus }) => `1px solid ${theme.colors[focus ? 'navyLight' : 'skyDarker']}`,
    borderRadius: 4,
    boxShadow: '0px 2px 4px rgba(90, 116, 129, 0.1)',
  },
  icon: {
    color: theme.colors.navyBase,
    borderRadius: '50%',
  },
  dragTitle: {
    overflow: 'hidden',
    flex: 1,
    display: 'flex',
    '& > :first-child': {
      marginRight: 6,
    },
  },
  actions: {
    display: 'flex',
    '& > :last-child': {
      marginLeft: 24,
    },
  },
  dragIcon: {
    color: theme.colors.charcoalLighter,
  },
  errorIcon: {
    marginLeft: 8,
    color: theme.colors.redBase,
  },
  alertIcon: {
    transform: 'rotate(180deg)',
    color: theme.colors.yellowBase,
  },
}))

// ripple wrapper
function ButtonIcon(props) {
  const css = useStyles()
  const { icon } = props

  return (
    <ButtonBase className={css.icon} {...props}>
      {icon}
    </ButtonBase>
  )
}

ButtonIcon.propTypes = {
  icon: PropTypes.node.isRequired,
}

const FIELD_MAP = {
  dropdown: 'Dropdown',
  text: 'Text',
}

function FieldOption(props) {
  const {
    onDelete, onEdit, highlight, input, isInPage,
  } = props
  const {
    values: { ESP },
  } = React.useContext(BuilderContainerContext)
  const [focus, setFocus] = useState(false)
  const css = useStyles({ focus: highlight || focus })

  const {
    builder: { errors },
  } = React.useContext(BuilderContainerContext)

  const name = input.isTemplate ? `placeholder_${input.name}` : input.name

  const isError = errors.verify([name, `cantExceed_${input.name}`, `repeatedValue_${name}`])
  const skipValidate = input.name === 'email'
  const isAlert = isInPage && !input.espValue && ESP && !skipValidate

  const Delete = () => <ButtonIcon icon={<DeleteIcon className={css.icon} />} onClick={onDelete} />
  const Edit = () => <ButtonIcon icon={<EditIcon className={css.icon} />} onClick={onEdit} />

  const getFieldLabel = () => {
    if (!input.isTemplate) {
      const prefix = FIELD_MAP[input.type]
      return `${prefix}: ${input.label || 'Untitled'}`
    }

    return input.label
  }

  const notifyIcon = isError ? (
    <ErrorIcon className={cn(css.errorIcon)} />
  ) : isAlert ? (
    <ErrorIcon className={cn(css.errorIcon, css.alertIcon)} />
  ) : null

  return (
    <ClickAwayListener onClickAway={() => setFocus(false)}>
      <div onClick={() => setFocus(true)} className={css.container}>
        <div className={css.dragTitle}>
          {/* Make drag start when this is clicked */}
          <DragIcon className={cn(css.icon, css.dragIcon)} />
          <Text ellipsis size={16} navyBase>
            {getFieldLabel()}
          </Text>
          {notifyIcon}
        </div>
        <div className={css.actions}>
          {onDelete && <Delete />}
          <Edit />
        </div>
      </div>
    </ClickAwayListener>
  )
}

FieldOption.propTypes = {
  highlight: PropTypes.bool,
  isInPage: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  input: PropTypes.object.isRequired,
}

FieldOption.defaultProps = {
  highlight: false,
  isInPage: false,
  onDelete: null,
}

export default React.memo(FieldOption)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { PageTitle } from 'components/builder/titles'

const useStyles = makeStyles(theme => ({
  espFieldTitle: {
    padding: '16px 20px',
    borderBottom: `1px solid ${theme.colors.skyBase}`,
  },
}))

const ESP_FIELD_LIST_TOOLTIP = 'Link the form fields with the corresponding values in your ESP. Make sure the values are exactly as they appear in your ESP.'

function ESPFieldValuesTitle() {
  const css = useStyles()

  const titleProps = {
    text: 'ESP Field Values',
    textProps: {
      size: 16,
      charcoalDarker: true,
    },
    tooltip: { text: ESP_FIELD_LIST_TOOLTIP },
  }

  return (
    <div className={css.espFieldTitle}>
      <PageTitle {...titleProps} />
    </div>
  )
}

export default React.memo(ESPFieldValuesTitle)

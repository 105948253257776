import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import ESPIntegrationsModal from 'views/mobile/lightbox-builder/modals/ESPIntegrationsModal'
import SectionTitle from './SectionTitle'
import ESPCurrentIntegration from './ESPCurrentIntegration'

const useStyles = makeStyles({
  integration: {
    padding: 20,
  },
})

function ESPIntegration() {
  const css = useStyles()

  return (
    <section className={css.integration}>
      <SectionTitle />
      <ESPCurrentIntegration />
      <ESPIntegrationsModal />
    </section>
  )
}

export default React.memo(ESPIntegration)

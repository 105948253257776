import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import cn from 'classnames'

import Text from 'components/texts/Text'
import Input from './Input'

const useStyles = makeStyles(() => ({
  characterLimit: {
    bottom: 12,
    position: 'absolute',
    right: 12,
  },
  characterLimitBuffer: {
    bottom: 32,
  },
  container: {
    position: 'relative',
  },
}))

function Textarea(props) {
  const {
    characterLimit, error, helperText, value, allowCharacterOverflow, ...rest
  } = props

  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Input
        {...rest}
        error={error}
        inputProps={{
          maxLength: !allowCharacterOverflow && characterLimit,
        }}
        multiline
        helperText={helperText}
        value={value}
      />
      {characterLimit && (
        <div
          className={cn(classes.characterLimit, {
            [classes.characterLimitBuffer]: error || helperText,
          })}
        >
          <Text charcoalLight size={12}>
            {`${value.length} / ${characterLimit}`}
          </Text>
        </div>
      )}
    </div>
  )
}

Textarea.propTypes = {
  characterLimit: PropTypes.number,
  error: PropTypes.string,
  helperText: PropTypes.string,
  value: PropTypes.string.isRequired,
  allowCharacterOverflow: PropTypes.bool,
}

Textarea.defaultProps = {
  characterLimit: null,
  allowCharacterOverflow: false,
  error: '',
  helperText: '',
}

export default React.memo(Textarea)

import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'
import { Avatar, makeStyles } from '@material-ui/core'

import { ReactComponent as Logo } from 'images/nav-bar/mojo-white-tiger-logo.svg'

import styleHelper from 'utils/styleHelper'

import CreateNewButton from './CreateNewButton'
import { clickedOn } from './commonUtils'

const useStyles = makeStyles(theme => ({
  createNewButton: {
    marginLeft: 12,
  },
  wrapper: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    border: 'none !important',
    width: '40px !important',
    height: '40px !important',
    marginRight: '12px',
    backgroundColor: 'transparent !important',
    overflow: 'unset !important',
  },
  prideLogo: {
    marginTop: -12,
  },
  hideTablet: {
    [styleHelper.tablet]: {
      display: 'none',
    },
  },

  link: {
    cursor: 'pointer',
    margin: '0 12px',
    fontFamily: 'Larsseit',
    lineHeight: '1', // TODO: come back to this
    color: theme.colors.cornflower,
    '&:hover': {
      fontSize: '14px',
      fontWeight: 'bold',
      color: theme.colors.white,
    },
  },
  proximaLink: {
    color: theme.colors.proxima.aqua,
  },
}))

const starsIcon = (
  <svg width="12" height="12" style={{ marginTop: '-3px' }} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.0438 12.9562C11.0813 11.9937 7.5 11.5875 7.5 11.25C7.5 10.9125 11.0813 10.5062 12.0438 9.54375C13.0062 8.58125 13.4125 5 13.75 5C14.0875 5 14.4937 8.58125 15.4562 9.54375C16.4187 10.5062 20 10.9125 20 11.25C20 11.5875 16.4187 11.9937 15.4562 12.9562C14.4937 13.9187 14.0875 17.5 13.75 17.5C13.4125 17.5 13.0062 13.9187 12.0438 12.9562Z" fill="#1CE8AB" />
    <path d="M4.92333 6.74333C4.41 6.23 2.5 6.01333 2.5 5.83333C2.5 5.65333 4.41 5.43667 4.92333 4.92333C5.43667 4.41 5.65333 2.5 5.83333 2.5C6.01333 2.5 6.23 4.41 6.74333 4.92333C7.25667 5.43667 9.16667 5.65333 9.16667 5.83333C9.16667 6.01333 7.25667 6.23 6.74333 6.74333C6.23 7.25667 6.01333 9.16667 5.83333 9.16667C5.65333 9.16667 5.43667 7.25667 4.92333 6.74333Z" fill="#1CE8AB" />
    <path d="M4.545 15.455C4.28833 15.1983 3.33333 15.09 3.33333 15C3.33333 14.91 4.28833 14.8017 4.545 14.545C4.80167 14.2883 4.91 13.3333 5 13.3333C5.09 13.3333 5.19833 14.2883 5.455 14.545C5.71167 14.8017 6.66667 14.91 6.66667 15C6.66667 15.09 5.71167 15.1983 5.455 15.455C5.19833 15.7117 5.09 16.6667 5 16.6667C4.91 16.6667 4.80167 15.7117 4.545 15.455Z" fill="#1CE8AB" />
  </svg>
)

const openIcon = (
  <svg width="12" height="12" style={{ marginBottom: '-2px' }} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.6667 10.6667H1.33333V1.33333H6V0H1.33333C0.593333 0 0 0.6 0 1.33333V10.6667C0 11.4 0.593333 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667V6H10.6667V10.6667ZM7.33333 0V1.33333H9.72667L3.17333 7.88667L4.11333 8.82667L10.6667 2.27333V4.66667H12V0H7.33333Z" fill="#F4F7F8" />
  </svg>

)

function NavBarMainMenu(props) {
  const { currentBrand } = props
  const classes = useStyles()
  const currentMonth = new Date().getMonth()
  const isPrideMonth = currentMonth === 5
  const navItems = [
    {
      to: '/',
      content: (
        <Avatar classes={{ colorDefault: classes.logo }}>
          {isPrideMonth ? (
            <img
              alt="PrideMojo"
              src="/images/nav-bar/pride/mojo-pride.png"
              srcSet="/images/nav-bar/pride/mojo-pride.png, /images/nav-bar/pride/mojo-pride@2x.png 2x, /images/nav-bar/pride/mojo-pride@3x.png 3x"
              className={classes.prideLogo}
            />
          ) : (
            <Logo />
          )}
        </Avatar>
      ),
      noClasses: true,
      clickedOn: 'Dojo Icon',
    },
    {
      to: '/',
      content: 'Discover',
      exact: true,
    },
    {
      to: '/sweepstakes',
      content: 'Sweepstakes',
    },
    !currentBrand.features.block_sms && {
      to: '/sms-marketing',
      content: 'SMS Marketing',
    },
    {
      to: '/analytics/dashboard',
      content: 'Analytics',
    },
    {
      to: 'https://www.proxima.ai/dojomojo',
      content: (
        <>
          {starsIcon}
          <span className={classes.proximaLink}>
            AI Audiences
          </span>
          &nbsp;
          {openIcon}
        </>
      ),
      external: true,
    },
  ].filter(Boolean)

  const generateLink = item => {
    const linkClasses = item.noClasses ? null : cn(classes.link, classes.hideTablet)
    const activeStyles = item.noClasses
      ? {}
      : {
        fontWeight: 'bold',
        color: 'white',
      }

    if (item.external) {
      return (
        <a
          key={item.clickedOn || item.content}
          href={item.to}
          className={linkClasses}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => clickedOn('', item.clickedOn)}
        >
          {item.content}
        </a>
      )
    }

    return (
      <NavLink
        key={item.clickedOn || item.content}
        exact={item.exact}
        to={item.to}
        className={linkClasses}
        activeStyle={activeStyles}
        onClick={() => clickedOn('', item.clickedOn)}
      >
        {item.content}
      </NavLink>
    )
  }

  return (
    <div className={classes.wrapper}>
      {navItems.map(generateLink)}
      <div className={cn(classes.createNewButton, classes.hideTablet)}>
        <CreateNewButton />
      </div>
    </div>
  )
}

NavBarMainMenu.propTypes = {
  currentBrand: PropTypes.object.isRequired,
}

export default React.memo(NavBarMainMenu)

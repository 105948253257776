import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Tooltip } from '@material-ui/core'
import cn from 'classnames'

const buildText = text => text.split('\\n').map(line => <div key={line}>{line}</div>)

const useStyles = makeStyles(theme => ({
  tooltip: {
    backgroundColor: ({ isVirtualTheme }) => (isVirtualTheme ? theme.colors.stone : 'inherit'),
    padding: ({ padding }) => (padding ? 12 : '4px 8px'),
    lineHeight: 1.67,
  },
  tooltipRedesign: {
    backgroundColor: theme.colors.charcoalBase,
    borderRadius: '4px',
    padding: '8px',
    fontFamily: 'Larsseit',
    fontSize: '12px',
    lineHeight: '18px',
    alignItems: 'center',
    width: 228,
    height: 70,
    margin: '12px 0',
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',
      lineHeight: '24px',
      height: 112,
    },
  },
  arrow: {
    color: theme.colors.charcoalBase,
    fontSize: 9,
  },
}))

function ToolTipWrapper(props) {
  const {
    text, isVirtualTheme, children, padding, tooltipRedesign, ...restProps
  } = props

  const css = useStyles({ padding, isVirtualTheme })

  return (
    <Tooltip
      classes={{
        tooltip: cn(
          { [css.tooltip]: !tooltipRedesign },
          { [css.tooltipRedesign]: tooltipRedesign }
        ),
        arrow: cn({ [css.arrow]: tooltipRedesign }),
      }}
      enterTouchDelay={100}
      title={React.isValidElement(text) ? text : buildText(text)}
      {...restProps}
    >
      {children}
    </Tooltip>
  )
}

ToolTipWrapper.propTypes = {
  text: PropTypes.string,
  isVirtualTheme: PropTypes.bool,
  children: PropTypes.node.isRequired,
  padding: PropTypes.bool,
  tooltipRedesign: PropTypes.bool,
}

ToolTipWrapper.defaultProps = {
  text: '',
  isVirtualTheme: false,
  padding: false,
  tooltipRedesign: false,
}

export default React.memo(ToolTipWrapper)

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

import Text from 'components/texts/Text'

const useStyles = makeStyles(theme => ({
  dropdown: {
    position: 'relative',
    marginTop: 8,
    maxWidth: ({ fullWidth }) => (fullWidth ? 'unset' : 360),
    height: 48,
    width: '100%',
    border: `1px solid ${theme.colors.skyBase}`,
    borderRadius: 4,
    cursor: ({ disabled }) => (disabled ? 'default' : 'pointer'),
    backgroundColor: ({ disabled }) => (disabled ? theme.colors.skyLighter : 'white'),
  },
  dropdownSelected: {
    padding: 12,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  arrow: {
    transition: 'transform .2s linear',
    border: '5px solid transparent',
    borderTop: `5px solid ${theme.colors.navyBase}`,
    borderTopColor: ({ disabled }) => theme.colors[disabled ? 'skyDarker' : 'navyBase'],
    transform: ({ open }) => `rotateX(${open ? 180 : 0}deg)`,
    transformOrigin: '50% 25%',
    marginLeft: 8,
    marginTop: 4,
  },
}))

function DropdownOutlined(props) {
  const {
    label,
    placeholder,
    onToggle,
    disabled,
    fullWidth,
    open,
    textProps,
    dropdownStyles,
    isPlaceholder,
  } = props

  const css = useStyles({ disabled, fullWidth, open })

  const labelProps = textProps || { navyBase: !disabled, charcoalLighter: disabled }

  const selectionText = (
    <Text {...labelProps} size={16} ellipsis charcoalLighter={isPlaceholder}>
      {isPlaceholder ? placeholder : label}
    </Text>
  )

  return (
    <div className={cn(dropdownStyles, css.dropdown)}>
      <div className={css.dropdownSelected} onClick={onToggle}>
        {selectionText}
        <div className={css.arrow} />
      </div>
    </div>
  )
}

DropdownOutlined.propTypes = {
  dropdownStyles: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  isPlaceholder: PropTypes.bool.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  textProps: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
}

export default React.memo(DropdownOutlined)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { BuilderContainerContext } from './BuilderContainerContext'

const useStyles = makeStyles(theme => ({
  navbar: {
    zIndex: 1,
    backgroundColor: theme.colors.navyDarker,
    height: 64,
    width: '100vw',
    boxShadow: '0px 2px 4px rgba(90, 116, 129, 0.1)',
    padding: '0 32px',
    display: 'flex',
    alignItems: 'center',
    position: 'sticky',
    top: 0,
    left: 0,
  },
}))

function BuilderNavbar() {
  const css = useStyles()
  const context = React.useContext(BuilderContainerContext)
  const {
    config: { navbar },
  } = context

  return <div className={css.navbar}>{navbar}</div>
}

export default React.memo(BuilderNavbar)

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { ButtonBase } from '@material-ui/core'
import { DeleteOutline as DeleteIcon } from '@material-ui/icons'

import InputWithOptions from './InputWithOptions'

const useStyles = makeStyles({
  inputWithDeleteAndOptions: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
  },
  icon: {
    height: 40,
    width: 40,
    borderRadius: '50%',
    margin: '0 20px',
  },
})

// eslint-disable-next-line react/prop-types
const DeleteButton = ({ buttonClass, onDelete }) => (
  <ButtonBase className={buttonClass} onClick={onDelete}>
    <DeleteIcon />
  </ButtonBase>
)

function InputWithDeleteAndOptions(props) {
  const css = useStyles()

  const { onDelete } = props

  return (
    <div className={css.inputWithDeleteAndOptions}>
      <InputWithOptions {...props} />
      <DeleteButton buttonClass={css.icon} onDelete={onDelete} />
    </div>
  )
}

InputWithDeleteAndOptions.propTypes = {
  onDelete: PropTypes.func.isRequired,
}

export default React.memo(InputWithDeleteAndOptions)

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import cn from 'classnames'

import NoBorderInput from './NoBorderInput'

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    backgroundColor: 'white',
    display: 'flex',
    flex: 1,
    padding: '0 4px 0 16px',
    border: `1px solid ${theme.colors.silver}`,
    borderRadius: 2,
  },
  searchIcon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 4,
  },
  inputWrapper: {
    flex: 1,
    '& > div > div > [class*=MuiTextField]': {
      margin: 0,
    },
  },
}))

function SearchInput(props) {
  const {
    filterItems, classes, onEnter, ...restProps
  } = props
  const css = useStyles()

  const wrapperClasses = cn(css.wrapper, classes.wrapper)
  const inputClasses = cn(css.inputWrapper, classes.input)

  const onEnterKey = e => {
    e.preventDefault()

    if (e.keyCode === 13) onEnter()
  }

  return (
    <div className={wrapperClasses}>
      <div className={css.searchIcon}>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="-1 -1 22 22">
          <path
            fill="#B7C3CD"
            fillRule="nonzero"
            d="M7.783 15.565a7.641 7.641 0 0 0 4.97-1.812l6.04 6.04c.137.138.31.207.5.207s.362-.069.5-.207a.708.708 0 0 0 0-1l-6.04-6.04a7.77 7.77 0 0 0 1.812-4.97A7.784 7.784 0 0 0 7.783 0C3.503 0 0 3.503 0 7.783c0 4.296 3.503 7.782 7.783 7.782zm0-14.15a6.374 6.374 0 0 1 6.367 6.368 6.363 6.363 0 0 1-6.367 6.367 6.374 6.374 0 0 1-6.368-6.367c0-3.503 2.865-6.368 6.368-6.368z"
          />
        </svg>
      </div>
      <div className={inputClasses}>
        <NoBorderInput onKeyUp={onEnter ? onEnterKey : null} {...restProps} />
        {filterItems}
      </div>
    </div>
  )
}

SearchInput.propTypes = {
  filterItems: PropTypes.arrayOf(PropTypes.node),
  classes: PropTypes.object,
  onEnter: PropTypes.func,
}

SearchInput.defaultProps = {
  filterItems: [],
  onEnter: null,
  classes: {
    wrapper: '',
    input: '',
  },
}

export default React.memo(SearchInput)

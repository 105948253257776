import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Drawer as MuiDrawer, makeStyles, Paper, Popover,
} from '@material-ui/core'
import cn from 'classnames'
import useMobileDetect from 'hooks/useMobileDetect'
import { P } from 'components/texts'

const useStyles = makeStyles(theme => ({
  dropdownButton: {
    position: 'relative',
    height: 36,
    backgroundColor: 'white',
    border: `1px solid ${theme.colors.silver}`,
    padding: '11px 8px 11px 12px',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: 22,
      transform: 'rotate(180deg)',
    },
  },
  dropdownOption: {
    padding: 12,
    cursor: 'pointer',
  },
  dropdownOptionSelected: {
    '& p': {
      // TODO: Remove when we all agree with the colors :\
      color: ({ oldDesign }) => (oldDesign ? theme.colors.coral : theme.colors.ink),
      fontFamily: ({ oldDesign }) => (oldDesign ? 'Larsseit-Light' : 'Larsseit-Medium'),
    },
  },
  sortDropdown: {
    height: 24,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    '& > p': {
      // TODO: Remove when we all agree with the colors :\
      color: ({ oldDesign }) => (oldDesign ? theme.colors.coral : theme.colors.ink),
      fontFamily: ({ oldDesign }) => (oldDesign ? 'Larsseit-Medium' : 'Larsseit-Light'),
    },
    '& > svg': {
      marginLeft: 0,
      '& path': {
        // TODO: Remove when we all agree with the colors :\
        fill: ({ oldDesign }) => (oldDesign ? theme.colors.coral : theme.colors.ink),
      },
    },
  },
  popover: {
    // TODO: Remove when we all agree with the colors :\
    minWidth: 176,
  },
  sortDropdownMobile: {
    '& > svg': {
      marginLeft: 0,
      '& path': {
        fill: theme.colors.stone,
      },
    },
  },
  // Desktop
  dropdownMenu: {
    zIndex: 1001,
    minWidth: 176,
    position: 'absolute',
    right: 0,
    top: 24,
    animation: '$fadein .1s ease-in forwards',
    '& > div': {
      '&:hover': {
        backgroundColor: theme.colors.silver,
        cursor: 'pointer',
      },
    },
  },
  '@keyframes fadein': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
}))

const renderDropdownOptions = ({
  options,
  selectedOption,
  classes,
  onClickItem,
  oldDesign, // TODO: Remove when we all agree with the colors :\
}) => [
  ...options.map(option => (
    <div
      key={`dropdown${option.label}`}
      onClick={e => {
        e.stopPropagation()
        onClickItem(option)
      }}
      className={`${classes.dropdownOption} ${
        option.label === selectedOption ? classes.dropdownOptionSelected : ''
      }`}
    >
      <P>
        {oldDesign ? ( // TODO: Remove when we all agree with the colors :\
          <i>{option.label}</i>
        ) : (
          option.label
        )}
      </P>
    </div>
  )),
  <div key="dropdown_cancel" onClick={() => onClickItem()} className={classes.dropdownOption}>
    <P>
      {oldDesign ? ( // TODO: Remove when we all agree with the colors :\
        <i>Cancel</i>
      ) : (
        'Cancel'
      )}
    </P>
  </div>,
]

function DropdownMenu(props) {
  const {
    open, items, onClose, customClasses,
  } = props
  const classes = useStyles(props)
  const customClass = { ...classes, ...customClasses }

  if (!open) return null
  return (
    <Paper onClose={onClose} className={customClass.dropdownMenu}>
      {items}
    </Paper>
  )
}

DropdownMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  customClasses: PropTypes.object,
}

DropdownMenu.defaultProps = {
  customClasses: {},
}

function DropdownButton(props) {
  const dropdownRef = useRef(null)
  const {
    label,
    options,
    anchor,
    sort,
    customClasses,
    isSearchMobile,
    activeOverride,
    oldDesign, // TODO: Remove when we all agree with the colors :\
  } = props

  const isMobile = useMobileDetect().isMobile()
  const classes = useStyles({ oldDesign }) // TODO: Remove when we all agree with the colors :\
  const [active, setActive] = useState(false)
  const onClose = () => setActive(false)

  const onClickItem = item => {
    if (item) item.onClick(item.label)
    onClose()
  }
  const classNames = cn(classes.dropdownButton, {
    [classes.sortDropdown]: sort,
    [classes.sortDropdownMobile]: sort && isSearchMobile,
  })
  const items = renderDropdownOptions({
    options,
    selectedOption: label,
    classes,
    onClickItem,
    oldDesign,
  })

  return (
    <>
      <div
        className={classNames}
        onClick={() => setActive(isActive => !isActive)}
        ref={dropdownRef}
      >
        {!isSearchMobile && <P>{label}</P>}
        {/* Collapsible arrow */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          style={{ marginTop: -3 }}
        >
          <path
            fill={isSearchMobile ? '#475464' : '#FF7043'}
            fillRule="nonzero"
            d="M7.41 15.16L12 10.58l4.59 4.58L18 13.75l-6-6-6 6z"
          />
        </svg>
        {!isMobile && !isSearchMobile ? (
          oldDesign ? ( // TODO: Remove when we all agree with the colors :\
            <DropdownMenu
              onClose={() => onClose()}
              open={active}
              items={items.slice(0, -1)}
              customClasses={customClasses}
            />
          ) : (
            <Popover
              classes={{ paper: classes.popover }}
              open={active}
              anchorEl={dropdownRef.current}
            >
              {items.slice(0, -1)}
            </Popover>
          )
        ) : null}
      </div>
      {isMobile || isSearchMobile ? (
        <MuiDrawer open={active || activeOverride} anchor={anchor} onClose={() => onClose()}>
          {items}
        </MuiDrawer>
      ) : null}
    </>
  )
}

DropdownButton.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  anchor: PropTypes.string,
  sort: PropTypes.bool,
  isSearchMobile: PropTypes.bool,
  oldDesign: PropTypes.bool, // TODO: Remove when we all agree with the colors :\
  customClasses: PropTypes.object,
  activeOverride: PropTypes.bool,
}

DropdownButton.defaultProps = {
  anchor: 'bottom',
  sort: false,
  oldDesign: true, // TODO: Remove when we all agree with the colors :\
  isSearchMobile: false,
  customClasses: {},
  activeOverride: false,
}

export default React.memo(DropdownButton)

import React from 'react'
import PropTypes from 'prop-types'
import { useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'

import { FormInput } from 'components/forms'
import { P, Small } from 'components/texts'
import {
  isRequired, isNum, cantExceedOfChars, isPositive,
} from 'utils/formValidations'

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    '& > div': {
      flex: 1,
      '&:first-child': {
        marginRight: 48,
      },
    },
  },
  inputs: {
    display: 'flex',
    '& > *': {
      marginRight: 8,
    },
    '& > :nth-child(1)': {
      maxWidth: 80,
    },
    '& > :nth-child(2)': {
      flex: 1,
      maxWidth: 180,
    },
    '& > :last-child': {
      marginTop: 20,
    },
  },
})

const SELECT_VALUES = [
  { label: 'Minute(s)', value: 'minute' },
  { label: 'Hour(s)', value: 'hour' },
  { label: 'Day(s)', value: 'day' },
]

function SendFollowupCriteria(props) {
  const { name } = props
  const { values } = useFormState()
  const { canDelete } = _.get(values, name)
  const css = useStyles()

  const Separator = React.memo(p => <div style={{ marginTop: p.margin || 16 }} />)

  return (
    <div className={css.wrapper}>
      <div>
        <FormInput
          type="text"
          name={`${name}.name`}
          placeholder="Message Title"
          label="Message Name"
          validations={[isRequired, cantExceedOfChars(80)]}
        />
        <Separator margin={32} />
        <P>
          <strong>Send</strong>
        </P>
        <div className={css.inputs}>
          <FormInput
            disabled={!canDelete}
            type="text"
            name={`${name}.send_interval`}
            validations={[isRequired, isNum, isPositive]}
          />
          <FormInput
            disabled={!canDelete}
            type="select"
            values={SELECT_VALUES}
            name={`${name}.unit_of_time`}
          />
          <Small>after previous message</Small>
        </div>
        <Separator margin={24} />
      </div>
      <div />
    </div>
  )
}

SendFollowupCriteria.propTypes = {
  name: PropTypes.string,
}

SendFollowupCriteria.defaultProps = {
  name: 'settings',
}

export default React.memo(SendFollowupCriteria)

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

import { ReactComponent as ArrowIcon } from 'images/icons/arrow-icons/expand.svg'
import Text from 'components/texts/Text'

const useStyles = makeStyles(theme => ({
  expandable: {
    border: `1px solid ${theme.colors.skyDarker}`,
    borderRadius: 4,
    backgroundColor: 'white',
    marginBottom: 12,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    '& > :not(:first-child)': {
      marginLeft: 12,
    },
  },
  expandBar: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-bewteen',
    padding: '0 24px 0 20px',
    cursor: 'pointer',
  },
  icon: {
    fill: theme.colors.navyBase,
    height: 68,
    transform: 'rotate(90deg)',
    transition: 'transform .1s linear',
  },
  expandSection: {
    maxHeight: 0,
    overflow: 'hidden',
    // transition: 'max-height .5s linear', // TODO: work this out?
  },
  open: {
    maxHeight: 2000,
  },
  iconOpen: {
    transform: 'rotate(0deg)',
  },
}))

function ExpandableSection(props) {
  const {
    children, text, icon, open, onToggle,
  } = props
  const css = useStyles({ open })

  return (
    <div className={css.expandable}>
      <div className={css.expandBar} onClick={onToggle}>
        <div className={css.title}>
          {icon}
          <Text size={16} charcoalDarker>
            {text}
          </Text>
        </div>
        <ArrowIcon className={cn(css.icon, { [css.iconOpen]: open })} />
      </div>
      <div className={cn(css.expandSection, { [css.open]: open })}>{children}</div>
    </div>
  )
}

ExpandableSection.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  icon: PropTypes.node,
  open: PropTypes.bool,
}

ExpandableSection.defaultProps = {
  icon: null,
  open: false,
}

export default React.memo(ExpandableSection)

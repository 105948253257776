import React from 'react'
import PropTypes from 'prop-types'
import TooltipMUI from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'

import Text from 'components/texts/Text'

const TooltipIcon = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.8335 5.33333H9.16683V6.66667H7.8335V5.33333ZM7.8335 8H9.16683V12H7.8335V8ZM8.50016 2C4.82016 2 1.8335 4.98667 1.8335 8.66667C1.8335 12.3467 4.82016 15.3333 8.50016 15.3333C12.1802 15.3333 15.1668 12.3467 15.1668 8.66667C15.1668 4.98667 12.1802 2 8.50016 2ZM8.50016 14C5.56016 14 3.16683 11.6067 3.16683 8.66667C3.16683 5.72667 5.56016 3.33333 8.50016 3.33333C11.4402 3.33333 13.8335 5.72667 13.8335 8.66667C13.8335 11.6067 11.4402 14 8.50016 14Z"
      fill="#3F5059"
    />
  </svg>
)

const useStyles = makeStyles(theme => ({
  tooltip: {
    padding: 0,
    backgroundColor: theme.colors.charcoalBase,
    maxWidth: 228,
    borderRadius: 4,
  },
  tooltipText: {
    padding: 8,
  },
  tooltipArrow: {
    color: theme.colors.charcoalBase,
  },
  action: {
    cursor: 'pointer',
    borderTop: '1px solid white',
    height: 52,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

function Tooltip(props) {
  const { action, actionLabel, text } = props
  const css = useStyles()
  const title = (
    <span>
      <div className={css.tooltipText}>
        <Text size={12} white>
          {text}
        </Text>
      </div>
      {action && actionLabel && (
        <div className={css.action} onClick={action}>
          <Text white>{actionLabel}</Text>
        </div>
      )}
    </span>
  )

  const tooltipProps = {
    classes: {
      tooltip: css.tooltip,
      arrow: css.tooltipArrow,
    },
    placement: 'bottom-start',
    title,
    arrow: true,
    interactive: true,
  }

  return (
    <TooltipMUI {...tooltipProps}>
      <span>
        <TooltipIcon />
      </span>
    </TooltipMUI>
  )
}

Tooltip.propTypes = {
  text: PropTypes.string,
  action: PropTypes.func,
  actionLabel: PropTypes.string,
}

Tooltip.defaultProps = {
  action: null,
  actionLabel: '',
  text:
    'Lorem nostrud laboris duis cillum ex irure ad aute minim sunt dolor veniam. Id mollit non et pariatur aute adipisicing. Occaecat elit eiusmod non est est labore nulla commodo.',
}

export default React.memo(Tooltip)

import React from 'react'
import PropTypes from 'prop-types'
import RadioGroupUI from '@material-ui/core/RadioGroup'

import Radio from './Radio'

function RadioGroup(props) {
  const {
    value, values, disabled, onChange,
  } = props
  const { current: name } = React.useRef(Date.now())
  const onRadioChange = ({ target }) => onChange(target.value)

  return (
    <RadioGroupUI name={name} defaultValue={value} onChange={onRadioChange}>
      {values?.map(radio => {
        let checked = false
        if (!disabled) {
          if (radio.value === value) checked = true
          if (radio.forceCheck?.includes(value)) checked = true
        }

        return <Radio {...radio} checked={Boolean(checked)} key={radio.value} />
      })}
    </RadioGroupUI>
  )
}

RadioGroup.propTypes = {
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  values: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
}

RadioGroup.defaultProps = {
  disabled: false,
}

export default React.memo(RadioGroup)

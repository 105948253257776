import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Text from 'components/texts/Text'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import IntegrationConnect from './IntegrationConnect'

const useStyles = makeStyles({
  title: {
    '& > :first-child': {
      marginBottom: 12,
    },
    marginBottom: 12,
  },
  integrations: {
    display: 'flex',
    gap: '24px',
    flexWrap: 'wrap',
  },
})

function AvailableIntegrations(props) {
  const {
    espIntegration: { supportedESPs },
  } = React.useContext(BuilderContainerContext)
  const { onConnectESP } = props
  const css = useStyles()

  const onIntegrationConnect = esp => () => onConnectESP(esp)

  return (
    <div>
      <div className={css.title}>
        <Text size={16} charcoalDarker>
          Supported Providers
        </Text>
        <Text charcoalLight>
          Connect your preferred ESP and follow the prompts to complete the integration.
        </Text>
      </div>
      <div className={css.integrations}>
        {Object.values(supportedESPs).map(esp => (
          <IntegrationConnect
            key={esp.key}
            title={esp.label}
            image={esp.img}
            onClick={onIntegrationConnect(esp.key)}
          />
        ))}
      </div>
    </div>
  )
}

AvailableIntegrations.propTypes = {
  onConnectESP: PropTypes.func.isRequired,
}

export default React.memo(AvailableIntegrations)

import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

import ToolTipWrapper from 'components/shared/ToolTipWrapper'
import { ReactComponent as LinkIcon } from 'images/icons/sms-editor-icons/insert-link.svg'

import LinkInsertModal from './LinkInsertModal'

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
    margin: '0 6px',
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'background .1s linear',
    cursor: 'pointer',
    borderRadius: 2,
    '&:hover': {
      background: theme.colors.crystal,
    },
  },
  open: {
    background: theme.colors.crystal,
  },
}))

function LinkInsert() {
  const css = useStyles()
  const [show, setShow] = useState(false)

  return (
    <div className={cn(css.wrapper, { [css.open]: show })}>
      <ToolTipWrapper padding={false} isVirtualTheme text="Insert Link">
        <LinkIcon onClick={() => setShow(true)} />
      </ToolTipWrapper>
      <LinkInsertModal show={show} onClose={() => setShow(false)} />
    </div>
  )
}

export default React.memo(LinkInsert)

//Design: https://www.figma.com/file/Jzn9QRV8FZsCqcZWdMXTYR/Component-Library?node-id=76%3A332

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, Button as MuiButton } from '@material-ui/core'
import VirtualTheme from 'components/virtual/VirtualTheme'

const useStyles = makeStyles(() => ({
  button: {
    padding: ({ size }) => (size === 'small' ? '4px 10px' : '0 24px'),
  },
  icon: {
    display: 'inherit',
    order: ({ reverseIcon }) => (reverseIcon ? 1 : 0),
    marginRight: ({ reverseIcon }) => (reverseIcon ? 0 : 12),
    marginLeft: ({ reverseIcon }) => (reverseIcon ? 12 : 0),
  },
  label: {
    display: 'flex',
  },
}))

function Button(props) {
  const {
    children,
    color,
    disabled,
    disableElevation,
    fullWidth,
    icon,
    label,
    onClick,
    size,
    type,
    reverseIcon,
    ...rest
  } = props

  const classes = useStyles({ size, reverseIcon })

  const realSize = size.startsWith('small') ? 'small' : size

  return (
    <VirtualTheme>
      <MuiButton
        classes={{ root: classes.button, label: classes.label }}
        aria-label={label}
        color={color}
        disabled={disabled}
        disableElevation={disableElevation}
        fullWidth={fullWidth}
        onClick={onClick}
        size={realSize}
        variant={type}
        {...rest}
      >
        {icon && <div className={classes.icon}>{icon}</div>}
        {label}
        {children}
      </MuiButton>
    </VirtualTheme>
  )
}

Button.propTypes = {
  children: PropTypes.any,
  color: PropTypes.oneOf(['primary', 'secondary', 'default']),
  disabled: PropTypes.bool,
  disableElevation: PropTypes.bool,
  fullWidth: PropTypes.bool,
  reverseIcon: PropTypes.bool,
  icon: PropTypes.node,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'medium']),
  type: PropTypes.oneOf(['contained', 'outlined', 'text']),
}

Button.defaultProps = {
  children: null,
  color: 'primary',
  disabled: false,
  reverseIcon: false,
  disableElevation: true,
  fullWidth: false,
  icon: null,
  size: 'small',
  type: 'contained',
}

export default React.memo(Button)

import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { ArrowForwardIos as ArrowIcon, Error as ErrorIcon } from '@material-ui/icons'
import cn from 'classnames'

import Text from 'components/texts/Text'
import { PageSubtitle, PageTitle } from './titles'

const useStyles = makeStyles(theme => ({
  accordion: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  sections: {
    overflowY: 'scroll',
  },
  section: {
    height: 94,
    padding: 20,
    borderBottom: `2px solid ${theme.colors.skyBase}`,
  },
  icon: {
    height: 30,
    width: 30,
    marginRight: 18,
    display: 'grid',
    placeContent: 'center',
    '& > h4': {
      lineHeight: 1,
    },
  },
  counter: {
    borderRadius: '50%',
    border: `1px solid ${theme.colors.charcoalBase}`,
  },
  title: {
    flex: 1,
  },
  item: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  banner: {
    position: 'absolute',
    bottom: 0,
  },
  error: {
    marginRight: 24,
    display: 'flex',
    alignItems: 'center',
    '& > svg': {
      marginRight: 8,
      color: theme.colors.redBase,
    },
  },
}))

function BuilderAccordion(props) {
  const { config } = props
  const bannerRef = React.useRef(null)
  const accordionRef = React.useRef(null)
  const css = useStyles()

  useEffect(() => {
    let height = 0
    if (bannerRef.current) height = bannerRef.current.offsetHeight
    accordionRef.current.style.paddingBottom = `${height}px`
  }, [bannerRef])

  return (
    <div className={css.accordion}>
      <div className={css.section}>
        <PageTitle text={config.title} />
        <PageSubtitle text={config.subtitle} />
      </div>
      <div className={css.sections} ref={accordionRef}>
        {config?.sections?.map((section, i) => (
          <div key={section.label} onClick={section.onClick} className={cn(css.item, css.section)}>
            <span className={cn({ [css.counter]: !section.icon }, css.icon)}>
              {section.icon ?? <Text size={18}>{i + 1}</Text>}
            </span>
            <div className={css.title}>
              <Text size={16}>{section.label}</Text>
            </div>
            {section.error && (
              <div className={css.error}>
                <ErrorIcon />
                <Text size={12} redDarkest>
                  {section.error}
                </Text>
              </div>
            )}
            <ArrowIcon />
          </div>
        ))}
      </div>
      {config.banner && (
        <div ref={bannerRef} className={css.banner}>
          {config.banner}
        </div>
      )}
    </div>
  )
}

BuilderAccordion.propTypes = {
  config: PropTypes.object.isRequired,
}

export default React.memo(BuilderAccordion)

import React, { useEffect, useRef } from 'react'
import { useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment-timezone'

import checkmark from 'images/icons/checkmarks/checkmark.svg'
import { colors } from 'GlobalTheme'

import { FollowupCriteriaPreview } from 'components/shared/SmsFollowupsComponents'
import MessagePreview from 'components/shared/SmsEditorWrapper/MessagePreview'

import SMSInfoBanner from 'views/mobile/shared/SMSInfoBanner'

const useStyles = makeStyles(theme => ({
  separator: {
    marginBottom: '32px !important',
    outline: 'none !important', // overrides
    padding: '0 !important', // overrides
    background: 'unset !important', // overrides
  },
  messages: {
    paddingLeft: 32,
    // dont do it if only one or last child
    '& > .preview-message-wrapper:not(:first-child)': {
      marginTop: 32,
    },
  },
  progress: {
    position: 'absolute',
    width: 2,
    backgroundColor: theme.colors.silver,
    marginLeft: 8,
    // marginTop: ({ oldCoupon }) => (oldCoupon ? 24 : 0),
  },
  followupCriteria: {
    marginLeft: -32,
    display: 'flex',
  },
  bannerWrapper: {
    position: 'relative',
    marginBottom: ({ oldCoupon }) => (oldCoupon ? 40 : 0),
  },
}))

const generateStatus = message => {
  const bgProps = message.status === 'sent'
    ? {
      background: `url(${checkmark}) center/cover white`,
      border: 'none',
    }
    : {
      background: 'white',
      border: `1px solid ${colors.silver}`,
    }

  return {
    content: '""',
    position: 'absolute',
    height: 16,
    width: 16,
    background: colors.silver,
    borderRadius: '50%',
    left: -32,
    ...bgProps,
  }
}

function generateSendCriteria(message, timezone, delivery_date) {
  const {
    status, sent_date, criteria, isFollowup,
  } = message

  // followup statuses are null when in stand-by. TBD
  if (!['sent', 'scheduled', null].includes(status)) return null

  const date = moment(sent_date || delivery_date)

  if (status === null && isFollowup) {
    const plural = criteria.send_interval > 1 ? 's' : ''
    const sending = `${criteria.send_interval} ${criteria.unit_of_time}${plural}`
    return {
      title: 'Sending',
      content: `${sending}s after previous message`,
    }
  }

  return {
    title: `${status[0].toUpperCase()}${status.slice(1)}`,
    content: `${date.format('MMMM DD, YYYY')} at ${date.format('HH:MM z')} ${timezone}`,
  }
}

function PreviewLightbox() {
  const progress = useRef(0)
  const { values } = useFormState()
  const {
    lightboxSendSettings, message, followups, actions,
  } = values
  const { checkIsOldCoupon } = actions
  const { timezone } = lightboxSendSettings

  const oldCoupon = [message, ...followups]
    .map(({ coupon_code }) => coupon_code)
    .find(checkIsOldCoupon)

  const css = useStyles({ oldCoupon })

  useEffect(() => {
    const elements = document.querySelectorAll('.preview-message-wrapper')

    let progressVal = 0

    const items = Array.from(elements).slice(0, -1)
    items.forEach(element => (progressVal += element.offsetHeight))

    if (items.length) progressVal += 80 + items.length * 6

    progress.current = progressVal
  }, [lightboxSendSettings, followups.length])

  const BANNERS = {
    oldCoupon: {
      title: 'Previous Code Detected',
      body: `${oldCoupon} was used in a previous campaign. Reusing coupon codes will break conversion tracking. Use a unique code`,
      type: 'warning',
    },
  }

  return (
    <>
      <div className={css.separator}>{/* filler purposes */}</div>
      <div style={{ marginTop: 1 /* avoid outline overlapping */ }}>
        {oldCoupon && (
          <div className={css.bannerWrapper}>
            <SMSInfoBanner {...BANNERS.oldCoupon} />
          </div>
        )}
        <div className={css.progress} style={{ height: progress.current }} />
        <div className={css.messages}>
          <MessagePreview
            name="message"
            titleActions={{ enableActions: !message.isDisabled }}
            sendingCriteria={generateSendCriteria(
              message,
              timezone,
              lightboxSendSettings.delivery_date
            )}
            status={generateStatus(message)}
          />
          {followups.length > 0 && [
            <div className={css.followupCriteria}>
              <FollowupCriteriaPreview name="lightboxSendSettings" />
            </div>,
            ...followups.map((followup, i) => (
              <MessagePreview
                key={followup.id}
                titleActions={{
                  enableActions: !followup.isDisabled,
                  canDelete: followup.canDelete,
                }}
                name={`followups[${i}]`}
                sendingCriteria={generateSendCriteria(followup, timezone)}
                status={generateStatus(followup)}
              />
            )),
          ]}
        </div>
      </div>
      <div className={css.separator}>{/* filler purposes */}</div>
    </>
  )
}

export default React.memo(PreviewLightbox)

import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import NoEmailFieldBanner from 'views/mobile/lightbox-builder/banners/NoEmailFieldBanner'
import NoESPDetectedBanner from 'views/mobile/lightbox-builder/banners/NoESPDetectedBanner'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import LightboxBuilderFullScreenSection from '../LightboxBuilderFullScreenSection'

import EnableEmailDrip from './EnableEmailDrip'
import ESPAudienceList from './ESPAudienceList'
import ESPCustomFieldTag from './ESPCustomFieldTag'

const useStyles = makeStyles({
  expandSection: {
    height: '100%',
    overflowY: 'scroll',
  },
})

function LightboxBuilderEmailWelcomeMessage() {
  const [open, setOpen] = useState(0)
  const { values } = React.useContext(BuilderContainerContext)
  const { ESP, containsEmailField } = values

  const css = useStyles()

  const expandables = [ESPAudienceList, ESPCustomFieldTag, EnableEmailDrip].map((Section, i) => (
    <Section key={Section.name} open={open === i} onToggle={() => setOpen(open === i ? -1 : i)} />
  ))

  const showExpandables = ESP ? containsEmailField : false

  const body = showExpandables ? (
    <div className={css.expandSection}>{expandables}</div>
  ) : ESP ? (
    <NoEmailFieldBanner />
  ) : (
    <NoESPDetectedBanner withAction />
  )

  return (
    <LightboxBuilderFullScreenSection
      title="Email Welcome Series"
      subtitle="Migrate new email contacts collected with this form to your Email Service Provider. Then, enroll those subscribers in your email welcome flow."
    >
      {body}
    </LightboxBuilderFullScreenSection>
  )
}

export default React.memo(LightboxBuilderEmailWelcomeMessage)

import React from 'react'
import LayoutIcon from '@material-ui/icons/ArtTrack'
import ColourIcon from '@material-ui/icons/PaletteOutlined'
import LogoIcon from '@material-ui/icons/PhotoSizeSelectLargeOutlined'
import ButtonIcon from '@material-ui/icons/CallToActionOutlined'

import BuilderAccordion from 'components/builder/BuilderAccordion'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

import ThemeBanner from '../../../banners/ThemeBanner'

function LightboxBuilderTheme() {
  const context = React.useContext(BuilderContainerContext)
  const { builder, lightboxSettings } = context
  const { drawer, errors } = builder
  const {
    settings: { type: formType },
  } = lightboxSettings.getSettings()

  const themeSections = {
    layout: {
      label: 'Layouts',
      onClick: () =>
        drawer.open(
          { type: 'theme', section: 'layout' },
          { title: 'Layout Settings', withBackButton: true }
        ),
      icon: <LayoutIcon />,
    },
    color: {
      label: 'Colors',
      onClick: () =>
        drawer.open(
          { type: 'theme', section: 'colors' },
          { title: 'Color Settings', withBackButton: true }
        ),
      icon: <ColourIcon />,
    },
    logo: {
      label: 'Logo',
      onClick: () =>
        drawer.open(
          { type: 'theme', section: 'logo' },
          { title: 'Logo Settings', withBackButton: true }
        ),
      icon: <LogoIcon />,
    },
    fieldAndButton: {
      label: 'Fields & Buttons',
      onClick: () =>
        drawer.open(
          { type: 'theme', section: 'buttons' },
          { title: 'Field & Button Settings', withBackButton: true }
        ),
      error: errors.verify(['formFieldCornerRadius', 'buttonCornerRadius'])
        ? 'action required'
        : false,
      icon: <ButtonIcon />,
    },
  }

  const {
    layout, color, logo, fieldAndButton,
  } = themeSections

  const sectionsByFormType = {
    lightbox: [layout, color, logo, fieldAndButton],
    popup: [layout, color, logo, fieldAndButton],
    embed: [color, logo, fieldAndButton],
  }

  const config = {
    title: 'Form Theme',
    subtitle: 'Edit your styles and settings.',
    sections: sectionsByFormType[formType],
    banner: <ThemeBanner />,
  }

  return <BuilderAccordion config={config} />
}

export default React.memo(LightboxBuilderTheme)

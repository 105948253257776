import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  separator: {
    borderTop: ({ color }) => `1px solid ${theme.colors[color || 'skyLight']}`,
    marginTop: ({ top }) => top,
    marginBottom: ({ bottom }) => bottom,
  },
}))

function Separator({ color, top, bottom }) {
  const css = useStyles({ color, top, bottom })

  return <div className={css.separator} />
}

Separator.propTypes = {
  color: PropTypes.string,
  top: PropTypes.number,
  bottom: PropTypes.number,
}

Separator.defaultProps = {
  color: 'skyBase',
  top: 0,
  bottom: 0,
}

export default React.memo(Separator)

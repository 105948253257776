import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import { H4 } from 'components/texts'
import { ButtonGroup } from 'components/buttons'
import { MobileModal } from 'components/modals'

const useStyles = makeStyles({
  warningModal: {
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 0 40px 0',
  },
  warningBody: {
    width: '100%',
    padding: '6px 0 16px 8px',
  },
})

const WarningModal = props => {
  const classes = useStyles()
  const {
    show, onClose, onSubmit, body, submitCopy, title,
  } = props

  return (
    <MobileModal open={show} title={title} centerTitle onClose={onClose}>
      <div className={classes.warningModal}>
        <div className={classes.warningBody}>
          <H4 ink>{body}</H4>
        </div>
      </div>
      <ButtonGroup
        morePad
        submitLabel={submitCopy}
        onSubmit={onSubmit}
        cancelLabel="Cancel"
        onCancel={onClose}
      />
    </MobileModal>
  )
}

WarningModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  body: PropTypes.string,
  submitCopy: PropTypes.string,
}

WarningModal.defaultProps = {
  body: 'Do you want to discard these changes?',
  submitCopy: 'Discard',
  title: 'You Have Unsaved Changes',
}

export default WarningModal

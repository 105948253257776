import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'
import LaptopIcon from '@material-ui/icons/Laptop'
import MobileIcon from '@material-ui/icons/Smartphone'

import { colors } from 'components/virtual/VirtualTheme'
import Text from 'components/texts/Text'
import RadioSelector from 'components/virtual/buttons/RadioSelector'
import { BuilderContainerContext } from '../BuilderContainerContext'

const useStyles = makeStyles(theme => ({
  radiosWrapper: {
    height: '72px',
    display: 'grid',
    placeContent: 'center',
  },
  radios: {
    display: 'flex',
  },
  radio: {
    appearance: 'none',
    margin: 0,
  },
  radioWrapper: {
    padding: '0 12px',
    width: 124,
    height: 36,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.colors.charcoalLighter}`,
    '& svg': {
      marginRight: 8,
      '&, & *': {
        transition: 'all .1 linear',
      },
    },
    '&.preview-radio-desktop': {
      transition: 'all .1 linear',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      borderColor: ({ desktopActive, buttonColors }) =>
        buttonColors[desktopActive ? 'active' : 'inactive'],
      '& p': {
        color: ({ desktopActive, buttonColors }) =>
          buttonColors[desktopActive ? 'active' : 'inactive'],
      },
    },
    '&.preview-radio-mobile': {
      transition: 'all .1 linear',
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      borderColor: ({ mobileActive, buttonColors }) =>
        buttonColors[mobileActive ? 'active' : 'inactive'],
      '& p': {
        color: ({ mobileActive, buttonColors }) =>
          buttonColors[mobileActive ? 'active' : 'inactive'],
      },
    },
  },
  laptopIcon: {
    fill: ({ desktopActive, buttonColors }) => buttonColors[desktopActive ? 'active' : 'inactive'],
  },
  mobileIcon: {
    fill: ({ mobileActive, buttonColors }) => buttonColors[mobileActive ? 'active' : 'inactive'],
  },
}))

const DEFAULT_BUTTON_COLORS = {
  active: 'white',
  inactive: colors.charcoalLighter,
}

function MobileDesktopPreviewSelect() {
  const { builder, config } = React.useContext(BuilderContainerContext)
  const {
    preview: { type, setType },
  } = builder

  const css = useStyles({
    buttonColors: config.preview.colors?.navbarButtons || DEFAULT_BUTTON_COLORS,
    desktopActive: type === 'desktop',
    mobileActive: type === 'mobile',
  })

  const radios = [
    {
      value: 'desktop',
      label: 'Desktop',
      icon: <LaptopIcon className={css.laptopIcon} />,
    },
    {
      value: 'mobile',
      label: 'Mobile',
      icon: <MobileIcon className={css.mobileIcon} />,
    },
  ]

  const render = radio => (
    <div className={cn(css.radioWrapper, `preview-radio-${radio.value}`)}>
      {radio.icon}
      <Text>{radio.label}</Text>
    </div>
  )

  return (
    <div className={css.radiosWrapper}>
      <RadioSelector
        value={type}
        values={radios}
        onChange={setType}
        render={render}
        className={css.radios}
      />
    </div>
  )
}

export default React.memo(MobileDesktopPreviewSelect)

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useForm, useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core'
import cn from 'classnames'
import _ from 'lodash'

import { P, Small } from 'components/texts'
import generatePreview, { hasUntrackedLink } from 'views/mobile/sms-marketing/utils'
import EditCouponModal from 'views/mobile/sms-marketing/shared/modals/EditCouponModal'
import SMSInfoBanner from 'views/mobile/shared/SMSInfoBanner'

import { ReactComponent as AbandonedProductImgPlaceholder } from 'images/abandoned_product_preview_placeholder.svg'

import EditorWrapperTitle from './EditorWrapperTitle'

const useStyles = makeStyles(theme => ({
  container: {
    '& > :first-child': {
      // title
      marginTop: -24,
      height: 64,

      // message status // TODO: Find a better way to do this
      position: 'relative',
      '&:before': {
        content: ({ content }) => content,
        position: ({ position }) => position,
        height: ({ height }) => height,
        width: ({ width }) => width,
        background: ({ background }) => background,
        borderRadius: ({ borderRadius }) => borderRadius,
        border: ({ border }) => border,
        left: ({ left }) => left,
      },
    },
  },
  wrapper: {
    maxWidth: 480,
  },
  messageWrapper: {
    display: 'flex',
    border: `1px solid ${theme.colors.silver}`,
    borderRadius: '16px 16px 16px 0',
    padding: '14px 16px',
    maxWidth: 480,
  },
  contentItem: {
    display: 'flex',
    alignItems: 'center',
    '& > :nth-child(2)': {
      marginLeft: 4,
      marginTop: 2,
      '& a': {
        position: 'relative',
        marginLeft: 4,
      },
    },
  },
  link: {
    fontFamily: 'Larsseit-Medium',
    color: theme.colors.azure,
    cursor: 'pointer',
    position: 'relative',
    marginLeft: 4,
    '& > div': {
      marginBottom: 20,
      left: '100%',
      marginLeft: 'unset',
    },
  },
  img: {
    maxWidth: 64,
    maxHeight: 64,
    marginRight: 12,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.colors.silver}`,
    borderRadius: 2,
    '& img': {
      objectFit: 'cover',
      width: 64,
      height: 64,
    },
  },
  svg: {
    minWidth: 64,
    minHeight: 64,
    marginRight: 12,
  },
  azureText: {
    color: theme.colors.azure,
  },
}))

function MessagePreview(props) {
  const {
    name, sendingCriteria, status, titleActions,
  } = props
  const css = useStyles({ ...status })

  const { accountname, website } = useSelector(state => state.currentBrand)
  const form = useForm()
  const { values } = useFormState()
  const message = _.get(values, name)
  const { actions } = values
  const { coupon_code, id } = message

  const [showCouponModal, setShowCouponModal] = useState(false)

  const messagePreview = generatePreview(message, accountname, website)

  const Separator = React.memo(p => <div style={{ marginTop: p.margin || 16 }} />)

  const onCouponChange = coupon => {
    form.change(`${name}.coupon_code`, coupon)
    if (actions.onChangeCoupon) actions.onChangeCoupon(id, coupon)
    setShowCouponModal(false)
  }

  const onUpdateCouponClick = e => {
    e.preventDefault()
    e.stopPropagation()
    setShowCouponModal(true)
  }

  const ContentItem = contentProps => {
    const {
      title, content, subtitle, separator = true,
    } = contentProps
    return (
      <>
        <div className={css.contentItem}>
          <P multiline>
            <strong>{title}</strong>
          </P>
          {subtitle}
        </div>
        {React.isValidElement(content) ? content : <P>{content}</P>}
        {separator && <Separator />}
      </>
    )
  }

  const NoCouponBanner = () => {
    const bannerInfo = {
      body:
        'If you are using a unique coupon code for this campaign, be sure to add it for proper conversion and revenue tracking.',
      type: 'warning',
      cta: (
        <span
          className={css.link}
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            setShowCouponModal(true)
          }}
        >
          {showCouponModal && <EditCouponModal coupon={coupon_code} onSubmit={onCouponChange} />}
          Add it now
        </span>
      ),
    }

    return <SMSInfoBanner {...bannerInfo} />
  }

  const UntrackedLinkBanner = () => {
    if (!hasUntrackedLink(message.custom_sms_message)) return null

    const bannerInfo = {
      title: 'Heads Up',
      body:
        'Your message includes a link not generated by our link shortening tool. We will not be able to track clicks for this message.',
      type: 'warning',
    }

    return [<SMSInfoBanner {...bannerInfo} />, <Separator />]
  }

  let image = null

  if (message.image_url) {
    const CUSTOM_IMAGE_PREVIEWS = {
      ABANDONED_PRODUCT_IMAGE: <AbandonedProductImgPlaceholder className={css.svg} />,
    }

    if (message.image_url in CUSTOM_IMAGE_PREVIEWS) image = CUSTOM_IMAGE_PREVIEWS[message.image_url]
    else {
      image = (
        <div className={css.img}>
          <img src={message.image_url} alt="promo" />
        </div>
      )
    }
  }

  return (
    <div className={cn('preview-message-wrapper', css.container)}>
      <EditorWrapperTitle isPreview name={name} {...titleActions} />
      <div className={css.wrapper}>
        {sendingCriteria && (
          <ContentItem title={sendingCriteria.title} content={sendingCriteria.content} />
        )}
        <P multiline>
          <strong>Message</strong>
        </P>
        <Separator margin={4} />
        <UntrackedLinkBanner />
        <div className={css.messageWrapper}>
          {image}
          <div>
            <P multiline>{messagePreview}</P>
          </div>
        </div>
        <Separator />
        <ContentItem
          separator={false}
          title="Coupon Code"
          subtitle={
            coupon_code && (
              <>
                <Small>
                  Need to update the coupon code?
                  <span className={css.link} onClick={onUpdateCouponClick}>
                    Change it here.
                    {showCouponModal && (
                      <EditCouponModal coupon={coupon_code} onSubmit={onCouponChange} />
                    )}
                  </span>
                </Small>
              </>
            )
          }
          content={coupon_code || <NoCouponBanner />}
        />
      </div>
    </div>
  )
}

MessagePreview.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.object,
  sendingCriteria: PropTypes.string,
  titleActions: PropTypes.object,
}

MessagePreview.defaultProps = {
  sendingCriteria: '',
  status: {},
  titleActions: {},
}

export default React.memo(MessagePreview)

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import { Button } from 'components/buttons'
import { Small } from 'components/texts'

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginTop: 32,
    padding: '24px 32px',
    outline: `1px solid ${theme.colors.silver}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

function AddFollowup({ onClick }) {
  const css = useStyles()

  return (
    <div className={css.wrapper}>
      <Button
        reverseIcon
        icon="blueAdd"
        type="raised"
        label="Add Follow-Up Message"
        onClick={onClick}
      />
      <Small>
        <a
          href="/app/profile/brand/data-integrations?v=d"
          target="_blank"
          rel="noreferrer noopener"
        >
          Integrate your payment processor
        </a>
        {' '}
        to exclude subscribers who may have made a purchase.
      </Small>
    </div>
  )
}

AddFollowup.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default React.memo(AddFollowup)

export function getDisplayName(profile) {
  const {
    email = '', firstName = '', lastName = '', fullName = '',
  } = profile
  const [emailName] = email.split('@')
  const name = fullName || `${firstName || ''} ${lastName || ''}`
  const displayName = name !== ' ' ? name : emailName
  return displayName
}

export default getDisplayName

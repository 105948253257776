import React from 'react'
import { useHistory } from 'react-router-dom'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import ActionBanner from './ActionBanner'

function SMSWelcomeDripRequiredBanner() {
  const {
    builder: { errors },
  } = React.useContext(BuilderContainerContext)
  const history = useHistory()

  const error = errors.verify('lightboxSmsSendMissing')

  if (!error) return null

  const onCreateDripClick = () => history.push(`${history.location.pathname}?edit=true`)

  return (
    <ActionBanner
      wrapperStyles={{ marginTop: 20 }}
      type="error"
      align="center"
      actionLabel="Create Series"
      action={onCreateDripClick}
      title="An SMS Welcome Series is required to publish."
    />
  )
}

export default React.memo(SMSWelcomeDripRequiredBanner)

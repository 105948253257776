import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Text from 'components/texts/Text'
import Button from 'components/virtual/buttons/Button'
import VirtualTheme from 'components/virtual/VirtualTheme'

const useStyles = makeStyles(theme => ({
  integrationConnect: {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${theme.colors.skyBase}`,
    borderRadius: 4,
    flex: '0 1 32%',
    height: 72,
    transition: 'border .2s linear',
    padding: 12,
    '& > button': {
      padding: '0 12px',
    },
  },
  integration: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  imageBg: {
    marginRight: 8,
    width: 48,
    height: 48,
    borderRadius: 4,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
}))

function IntegrationConnect(props) {
  const { image, onClick, title } = props
  const css = useStyles()

  return (
    <div className={css.integrationConnect}>
      <div className={css.integration}>
        <div className={css.imageBg} style={{ backgroundImage: `url(${image})` }} />
        <Text>{title}</Text>
      </div>
      <VirtualTheme>
        <Button
          size="smaller"
          type="outlined"
          color="secondary"
          label="Connect"
          onClick={onClick}
        />
      </VirtualTheme>
    </div>
  )
}

IntegrationConnect.propTypes = {
  image: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
}

export default React.memo(IntegrationConnect)

const initState = {
  profileLoading: true,

  displayName: '',
  credentials: [],
  email: '',
  firstName: '',
  fullName: '',
  id: null,
  isAdmin: false,
  lastName: '',
  lockedOut: false,
  loginAttempts: 0,
  passwordUpdatedAt: null,
  questCompleteConfirm: false,
  uid: '',
  updatedAt: '',
}

export default (state = initState, action) => {
  const { type, payload = {} } = action

  switch (type) {
    // Loaders
    case 'PROFILE_LOADING': {
      return { ...state, impersonating: payload, profileLoading: true }
    }

    // Rest of actions
    case 'PROFILE_FAILED':
    case 'PROFILE_LOAD': {
      const {
        created_at,
        deleted_at,
        updated_at,
        locked_out: lockedOut = false,
        login_attempts: loginAttempts = 0,
        ...rest
      } = payload || {}

      return {
        ...state,
        ...rest,
        lockedOut,
        loginAttempts,
        profileLoading: false,
      }
    }

    default:
      return state
  }
}

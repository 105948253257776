import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'
import Text from 'components/texts/Text'

import Button from 'components/virtual/buttons/Button'

import { ReactComponent as DesktopIcon } from 'images/computer-desktop.svg'
import styleHelper from 'utils/styleHelper'

const useStyles = makeStyles(theme => ({
  paper: {
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 32px',
    [styleHelper.min(768)]: {
      padding: '0px 124px',
    },
  },
  image: {
    marginBottom: 40,
    height: 120,
    width: 120,
    [styleHelper.min(768)]: {
      height: 200,
      width: 200,
    },
  },
  title: {
    fontSize: 24,
    lineHeight: '36px',
    color: theme.colors.charcoalDarker,
    [styleHelper.min(768)]: {
      fontSize: 34,
      lineHeight: '52px',
    },
  },
  subTitle: {
    fontSize: 20,
    lineHeight: '30px',
    color: theme.colors.charcoalLight,
    textAlign: 'center',
    [styleHelper.min(768)]: {
      fontSize: 24,
      lineHeight: '36px',
    },
    maxWidth: 520,
    marginBottom: 40,
  },
}))

function NonDesktopScreen(props) {
  const { open, setOpen, history } = props

  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
    history.goBack()
  }

  return (
    <Dialog open={open} fullScreen classes={{ paper: classes.paper }}>
      <DesktopIcon className={classes.image} />
      <div className={classes.title}>
        <Text charcoalDarker size={18}>
          View on Desktop
        </Text>
      </div>
      <div className={classes.subTitle}>
        <Text charcoalBase size={16}>
          This platform section is not currently supported on mobile. Please view this on a larger
          screen.
        </Text>
      </div>
      <div className={classes.button}>
        <Button
          color="primary"
          label="Go Back"
          size="medium"
          type="contained"
          onClick={handleClose}
        />
      </div>
    </Dialog>
  )
}

NonDesktopScreen.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
}

export default React.memo(withRouter(NonDesktopScreen))

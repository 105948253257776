import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import ButtonBase from '@material-ui/core/ButtonBase'
import ArrowBack from '@material-ui/icons/ArrowBack'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import Text from 'components/texts/Text'

import BuilderCloseBeforeSavingPopup from 'components/builder/BuilderCloseBeforeSavingPopup'
import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

const useStyles = makeStyles(theme => ({
  integrationsModal: {
    paddingBottom: 32,
    maxWidth: 1200,
    margin: '32px auto 0',
  },
  title: {
    marginBottom: 32,
    '& > :first-child': {
      marginBottom: 32,
    },
  },
  exit: {
    padding: '4px 8px',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    marginRight: 20,
  },
  backArrowSvg: {
    marginRight: 8,
    fill: theme.colors.charcoalDarker,
    transformOrigin: '50%',
    transform: 'scale(1.5)',
    cursor: 'pointer',
  },
  closeBeforeExitPopup: {
    position: 'relative',
    height: 32,
    '& > :first-child': {
      left: -60,
    },
  },
}))

function ESPCustomClose(props) {
  const { selectedESP } = props
  const { actions, values } = React.useContext(BuilderContainerContext)
  const { ESP } = values
  const { onSetESPValue } = actions
  const [openPopUp, setOpenPopUp] = useState(false)
  const history = useHistory()
  const css = useStyles()

  // removes querystring that opens modal
  const onCloseModal = () => history.push(history.location.pathname)

  const onBeforeClose = () => {
    if (selectedESP === ESP) onCloseModal()
    else setOpenPopUp(true)
  }

  const onSave = () => {
    onSetESPValue(selectedESP)
    onCloseModal()
  }

  return (
    <ClickAwayListener onClickAway={() => setOpenPopUp(false)}>
      <ButtonBase focusRipple className={css.exit} onClick={onBeforeClose}>
        <ArrowBack className={css.backArrowSvg} onClick={onBeforeClose} />
        <Text charcoalBase>Exit</Text>
        <div className={css.closeBeforeExitPopup}>
          <BuilderCloseBeforeSavingPopup
            onDiscard={onCloseModal}
            onSave={onSave}
            show={openPopUp}
          />
        </div>
      </ButtonBase>
    </ClickAwayListener>
  )
}

ESPCustomClose.propTypes = {
  selectedESP: PropTypes.object.isRequired,
}

export default React.memo(ESPCustomClose)

import * as formValidations from 'utils/formValidations'
import validator from 'validator'

const { isRequired } = formValidations

export const isRequiredWithCopy = copy => value => isRequired(value, copy)

export const isDomainRequired = isRequiredWithCopy('Domain required to publish your form')

export const hasMissingFields = value => value && 'Page with missing fields'

export const hasRequiredImageMissing = value => value && 'Image required to publish your form'

export const hasRepeatedField = value =>
  value && 'This field name is already taken. Choose another unique name'

export const hasRepeatedESPField = value =>
  value && 'This value is already in use with another field'

export const isValidDomain = value => {
  const copy = 'Must be a valid domain'
  if (value && validator.isURL(value)) return false
  return copy
}

// ! To be used on RadioGroup component ONLY
import React from 'react'
import PropTypes from 'prop-types'
import RadioUI from '@material-ui/core/Radio'
import { makeStyles } from '@material-ui/core/styles'

import Text from 'components/texts/Text'

const useStyles = makeStyles(theme => ({
  label: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  root: {
    marginLeft: 0,
    marginRight: 8,
    padding: 'unset',
    width: 'unset',
    '& svg': {
      color: theme.colors.skyDarker,
    },
  },
  checked: {
    '& svg': {
      color: theme.colors.blueBase,
    },
  },
}))

function Radio(props) {
  const { label, value, checked } = props
  const css = useStyles()

  const labelEl = typeof label !== 'string' ? (
    label
  ) : (
    <Text size={16} charcoalBase>
      {label}
    </Text>
  )

  return (
    <label className={css.label}>
      <RadioUI
        checked={checked}
        value={value}
        classes={{
          root: css.root,
          checked: css.checked,
        }}
      />
      {labelEl}
    </label>
  )
}

Radio.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.node.isRequired]).isRequired,
  checked: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
}

export default React.memo(Radio)

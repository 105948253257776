// TODO: Might move to components/shared

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Text from 'components/texts/Text'
import ImageDropzoneUploader from 'components/shared/ImageDropzoneUploader'
import ContentImagePreview from './ContentImagePreview'

const useStyles = makeStyles({
  // either show the uploader or the preview
  uploader: {
    display: ({ show }) => (!show ? 'block' : 'none'),
  },
  preview: {
    display: ({ show }) => (show ? 'block' : 'none'),
  },
  dropImage: {
    width: 'calc(100% - 2px)',
    margin: '20px 0 40px',
  },
  dropImageTitle: {
    marginBottom: 20,
  },
})

function ImageDropPreview(props) {
  const {
    title, helperText, onChange, onDelete, imageUrl, imageName, onError, error,
  } = props

  const fileUploaderRef = React.useRef(null)
  const [showPreview, setShowPreview] = useState(!!imageUrl)

  const css = useStyles({ show: showPreview })

  const onImageUpload = img => {
    if (!img) return
    onChange({
      imageUrl: img.url,
      imageName: img.image.name,
      imageObj: img.image,
    })
    setShowPreview(true)
  }

  const onChangeImage = () => {
    if (fileUploaderRef.current) fileUploaderRef.current()
  }

  const onDeleteImage = () => {
    setShowPreview(false)
    if (onDelete) onDelete()
  }

  return (
    <div className={css.dropImage}>
      <div className={css.preview}>
        <ContentImagePreview
          onChange={onChangeImage}
          onDelete={onDeleteImage}
          imageName={imageName}
          imageUrl={imageUrl}
        />
      </div>
      <div className={css.uploader}>
        <div className={css.dropImageTitle}>
          <Text charcoalBase>{title || 'Image Upload'}</Text>
          {error}
        </div>
        <ImageDropzoneUploader
          persist
          old={false}
          maxSize={5000000} // 5MB ???
          onImageUpload={onImageUpload}
          onImageReject={onError}
          accept={['jpeg', 'png'].map(subtype => `image/${subtype}`)}
          exposeOpenFileUploader={openUploader => {
            fileUploaderRef.current = openUploader
          }}
          helperText={helperText}
        />
      </div>
    </div>
  )
}

ImageDropPreview.propTypes = {
  imageName: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string,
  helperText: PropTypes.string,
}

ImageDropPreview.defaultProps = {
  title: null,
  helperText: 'File must be JPG, PNG or GIF. Recommended file size of 500KB or less.',
}

export default React.memo(ImageDropPreview)

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Text from 'components/texts/Text'
import AddButton from 'components/virtual/buttons/AddButton'
import EditTextField from './EditTextField'
import DropdownOptionField from './DropdownOptionField'

const useStyles = makeStyles({
  options: {
    marginTop: 42,
  },
  title: {
    marginBottom: 20,
  },
})

function EditDropdownField(props) {
  const css = useStyles()
  const { input, actions } = props
  const { onOptionAdd, onOptionRemove, onOptionChange } = actions

  const options = input.dropdownOptionsWithIDs ?? []
  const dropdownOptions = options.map((option, index) => (
    <DropdownOptionField
      key={option.id}
      value={option.value}
      index={index}
      placeholder="Dropdown Option"
      label={`Option ${index + 1}`}
      onChange={onOptionChange}
      onDelete={onOptionRemove}
    />
  ))

  return (
    <EditTextField
      {...props}
      auxiliaryComponent={(
        <div className={css.options}>
          <div className={css.title}>
            <Text size={16}>Dropdown Options</Text>
          </div>
          {dropdownOptions}
          <AddButton color="primary" onClick={onOptionAdd} label="Add Dropdown Option" />
        </div>
      )}
    />
  )
}

EditDropdownField.propTypes = {
  actions: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
}

export default React.memo(EditDropdownField)

import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'

import { Tiny } from 'components/texts'

const styles = theme => ({
  wrapper: {
    borderRadius: 100,
    height: 20,
    width: 62,
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'capitalize',
  },
  pending: { backgroundColor: theme.colors.cloudGrey },
  accepted: { backgroundColor: theme.colors.seaGreen },
  interested: { backgroundColor: theme.colors.cobaltBlue },
  rejected: { backgroundColor: theme.colors.grayishBlue },
  live: { backgroundColor: theme.colors.coral },
  pleaseSign: { backgroundColor: theme.colors.alertRed },
  upcoming: { backgroundColor: theme.colors.cloudGrey },
  completed: { backgroundColor: theme.colors.cerulean },
  active: { backgroundColor: theme.colors.seaGreen },
  draft: { backgroundColor: theme.colors.cloudGrey },
  inactive: { backgroundColor: theme.colors.cloudGrey },
  scheduled: { backgroundColor: theme.colors.lightYellow },
})

const badgeSelector = (which, classes) => {
  switch (which) {
    case 'accepted':
      return ['Accepted', classes.accepted]
    case 'interested':
      return ['Interested', classes.interested]
    case 'rejected':
      return ['Declined', classes.rejected]
    case 'live':
      return ['Live', classes.live]
    case 'pleaseSign':
      return ['Please Sign', classes.pleaseSign]
    case 'upcoming':
      return ['Upcoming', classes.upcoming]
    case 'completed':
      return ['Completed', classes.completed]
    case 'draft':
      return ['Draft', classes.draft, { ink: true }]
    case 'inactive':
      return ['Inactive', classes.inactive, { ink: true }]
    case 'active':
      return ['Active', classes.active, { ink: true }]
    case 'sent':
      return ['Sent', classes.active, { ink: true }]
    case 'scheduled':
      return ['Scheduled', classes.scheduled, { ink: true }]
    case 'sending':
      return ['Sending', classes.scheduled, { ink: true }]
    default:
      return ['Pending', classes.pending]
  }
}

function DojoBadge({ classes, type }) {
  const [text, bgColor, textProps] = badgeSelector(type, classes)

  return (
    <div className={`${classes.wrapper} ${bgColor}`}>
      <Tiny white={!textProps} {...textProps}>
        <em>{text}</em>
      </Tiny>
    </div>
  )
}

DojoBadge.propTypes = {
  classes: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
}

export default React.memo(withStyles(styles)(DojoBadge))

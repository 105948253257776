import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { any } from 'prop-types'
import queryString from 'query-string'
import historyGlobal from 'utils/getHistory'

const LEGACY_MARKER = { v: 'd' }

export const getLegacyUrl = ({ location, history = historyGlobal }) => {
  const newHost = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:5000'
  const parsed = queryString.parse(location.search)
  const newSearch = queryString.stringify({ ...parsed, ...LEGACY_MARKER })
  const newPath = history.createHref({ ...location, search: newSearch })
  return `${newHost}${newPath}`
}

export const RedirectToLegacyApp = routeProps => {
  window.location.replace(getLegacyUrl(routeProps))

  return null
}

export default function SwitchWithFallback({ children }) {
  return (
    <Switch>
      {children}
      <Route render={RedirectToLegacyApp} />
    </Switch>
  )
}

SwitchWithFallback.propTypes = { children: any.isRequired }

import React, { useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { useFormState, useForm } from 'react-final-form'
import _ from 'lodash'

import Text from 'components/texts/Text'
import SMSEditor from 'components/shared/SMSEditor'
import ToolTip from 'components/shared/ToolTip'
import { ComplianceBanner } from 'components/banners'

import SMSInfoBanner from 'views/mobile/shared/SMSInfoBanner'
import { replaceTags } from 'views/mobile/sms-marketing/utils'
import { FormInput } from 'components/forms'

import { calculateCredits } from 'utils/sms'
import { isRequired } from 'utils/formValidations'
import { Small } from 'components/texts'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexFlow: 'column',
  },
  couponInput: {
    marginTop: 32,
  },
  couponLabel: {
    display: 'flex',
    '& > :not(:first-child)': {
      marginLeft: 12,
    },
  },
  banner: {
    margin: '8px 0 0',
  },
  hidden: {
    display: 'none',
  },
})

function getImage(img) {
  if (!img) return ''
  // uploaded image
  if (img.img64) return img.img64

  if (img.type === 'pixabay') return img.original.imageURL
  if (img.type === 'unsplash') return img.original.urls.thumb

  // Return a gif
  if (img.type === 'giphy') return img.original.images.original.url

  // Custom images using image_url as string rather than url
  if (typeof img === 'string') return img

  return img.fullSrc
}

function MessageEditorSection({ name }) {
  const CUSTOM_ERRORS = [
    text =>
      (/\*\|[A-Z_]*_OR__FALLBACK_\|\*/.test(text.toUpperCase())
        ? 'Fallback text is required. Click the merge tag to edit.'
        : null),
  ]

  const css = useStyles()
  const form = useForm()
  const { values } = useFormState()
  const currentBrand = useSelector(state => state.currentBrand)
  const { oldCoupons } = values
  const message = _.get(values, name)
  const mounted = useRef(false)

  const isOldCoupon = oldCoupons.includes(message.coupon_code)

  const Separator = React.memo(p => <div style={{ marginTop: p.margin || 8 }} />)

  const BANNERS = {
    oldCoupon: {
      title: 'Previous Code Detected',
      body: 'Reusing coupon codes will break conversion tracking. Use a unique code!',
      type: 'warning',
      classes: { wrapper: css.banner },
    },
  }

  const hasCouponCode = message.custom_sms_message && /\*\|COUPON(_CODE)?\|\*/.test(message.custom_sms_message)

  const textToSend = replaceTags(message, currentBrand.accountname, currentBrand.website)
  const { credits, charsInUse } = calculateCredits(textToSend, !!message.image_url)

  const errorCheck = text => CUSTOM_ERRORS.reduce((error, errorFn) => error || errorFn(text), false)

  const couponRequired = coupon =>
    hasCouponCode
    && isRequired(
      coupon,
      'You must enter a coupon code if you have added the merge tag in your message.'
    )

  useEffect(() => {
    if (mounted.current) {
      form.batch(() => {
        form.focus(`${name}.coupon_code`)
        form.blur(`${name}.coupon_code`)
      })
    }

    mounted.current = true
  }, [hasCouponCode]) // eslint-disable-line react-hooks/exhaustive-deps

  const onValuesChange = useCallback(
    editorValues => {
      const {
        images: [img],
        text,
        coupon,
        minifiedLinks,
      } = editorValues
      form.batch(() => {
        form.change(`${name}.custom_sms_message`, text.trim())
        form.change(`${name}.coupon_code`, coupon && coupon.toUpperCase())
        form.change(`${name}.url_link`, minifiedLinks[0] ? minifiedLinks[0].originalUrl : '')
        form.change(`${name}.image_url`, getImage(img))
        form.change(`${name}.image_obj`, img)
        form.change(`${name}.custom_message_error`, errorCheck(text))
      })
    },
    [name] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    <div className={css.container}>
      <Text font="medium">Message</Text>
      <Separator />
      <div className={css.hidden}>
        <FormInput name={`${name}.custom_message_error`} validations={[val => Boolean(val)]} />
      </div>
      <SMSEditor
        imageOptions={message.imageOptions}
        message={message.custom_sms_message}
        couponCode={message.coupon_code}
        url={message.url_link}
        image={message.image_url}
        mergeTags={message.mergeTags}
        customError={message.custom_message_error}
        onChange={onValuesChange}
        charsInUse={charsInUse}
        credits={credits}
      />
      <Separator margin={16} />
      <ComplianceBanner />
      <div className={css.couponInput}>
        <div className={css.couponLabel}>
          <Text font="medium">Coupon Code</Text>
          <ToolTip
            isVirtualTheme
            text="For the most accurate conversion tracking, using a unique coupon code per message or campaign is highly recommended."
          />
        </div>
        <Separator margin={4} />
        <Small multiline>Create and insert your own unique Coupon Code.</Small>
        <Separator margin={4} />
        {isOldCoupon && <SMSInfoBanner {...BANNERS.oldCoupon} />}
        <FormInput
          type="text"
          name={`${name}.coupon_code`}
          placeholder="SAMPLECODE"
          validations={[couponRequired]}
          onChange={value => value.replace(/[^a-zA-Z0-9!%]/g, '').toUpperCase()}
        />
      </div>
    </div>
  )
}

MessageEditorSection.propTypes = {
  name: PropTypes.string.isRequired,
}

export default React.memo(MessageEditorSection)

import _ from 'lodash'

import filterCampaigns from './filterCampaigns'
import extendPartnerInfo from './extendPartnerInfo'

export default data => {
  const {
    campaigns, allPartnerDetails, tasks, promotionalDefaults,
  } = data
  const filteredCampaigns = filterCampaigns(campaigns)

  const pendingInvites = _.remove(campaigns, { status: 'pending' })
  const acceptedInvites = _.filter(campaigns, { status: 'accepted' })
  const rejectedInvites = _.remove(campaigns, { status: 'rejected' })

  for (let i = 0; i < campaigns.length; i++) {
    const campaign = campaigns[i]
    campaign.endDate = campaign.endDate ? new Date(campaign.endDate) : null
    campaign.startDate = campaign.startDate ? new Date(campaign.startDate) : null
    campaign.started = new Date(campaign.startDate) < new Date()
    campaign.hostBrand = {}
    campaign.partners = {}
    campaign.partnerImages = []
    campaign.impressions = 0
    campaign.postentries = 0
    campaign.conversions = 0
    campaign.entries = 0
    campaign.visits = 0
    campaign.newEmails = 0
    campaign.estimatedEntries = 0
    campaign.previewLink = `/landing/campaign/${campaign.id}?preview=true`
    campaign.allPartners = allPartnerDetails.filter(details => details.campaign_id === campaign.id)

    const campaignTasks = tasks.find(task => task.campaign_id === campaign.id) || {}
    campaign.totalTasks = campaignTasks.total_tasks || 0
    campaign.completedTasks = campaignTasks.completed_tasks || 0

    const [promo] = _.filter(promotionalDefaults, { campaign_id: campaign.id })

    if (promo) {
      campaign.previewLink = `/landing/campaign/${campaign.id}?promo_id=${promo.uid}&campaign_id=${campaign.id}&preview=true`
    }

    if (process.env.REACT_APP_ENV === 'production') {
      campaign.previewLink = `https://do-not-use-this-link.dojomojo.com${campaign.previewLink}`
    }

    extendPartnerInfo(campaign.partners, campaign.invitee_id, {
      minimumDelivery: campaign.minimumDelivery,
      successPageProportion: campaign.successPageProportion,
    })
  }

  return {
    ...data,
    ...filteredCampaigns,
    pendingInvites,
    acceptedInvites,
    rejectedInvites,
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import { useHistory, useRouteMatch } from 'react-router-dom'

import ActionBanner from './ActionBanner'

function FieldsActionRequiredBanner(props) {
  const { onButtonClick } = props
  const history = useHistory()
  const { url } = useRouteMatch()

  const onClick = () => {
    onButtonClick()
    history.push(`${url}/settings/fields`)
  }

  return (
    <ActionBanner
      wrapperStyles={{ marginTop: 32 }}
      type="alert"
      actionLabel="Review"
      action={onClick}
      title="Review form fields and field value mapping"
    />
  )
}

FieldsActionRequiredBanner.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
}

export default React.memo(FieldsActionRequiredBanner)

import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import axios from 'axios'
import moment from 'moment'
import { ToasterContext } from 'contexts'
import useMobileDetect from 'hooks/useMobileDetect'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'
import { CloseOutlined } from '@material-ui/icons'
import ToolTip from 'components/shared/ToolTip'
import Button from 'components/virtual/buttons/Button'
import Text from 'components/texts/Text'
import TextField from '@material-ui/core/TextField'
import StatusBanner from 'components/virtual/banners/StatusBanner'
import BannerContent from 'components/virtual/banners/BannerContent'
import { ReactComponent as Logo } from 'images/nav-bar/mojo-white-tiger-logo.svg'
import woocommerceIcon from 'images/icons/store-integrations/woo-commerce.svg'
import shopifyIcon from 'images/icons/store-integrations/shopify.svg'
import stripeIcon from 'images/icons/store-integrations/stripe.svg'
import rechargeIcon from 'images/icons/store-integrations/recharge.svg'
import { ReactComponent as SyncClockwise } from 'images/icons/sync-clockwise.svg'
import NonDesktopScreen from 'components/shared/NonDesktopScreen'
import { Spinner } from 'components/spinners'

const useStyles = makeStyles(theme => ({
  input: {
    marginTop: 4,
    '& .MuiInputBase-root': {
      border: `1px solid ${theme.colors.skyBase}`,
      borderRadius: 4,
      fontSize: 16,
      fontFamily: 'Larsseit',
      color: theme.colors.charcoalLighter,
      '& input': {
        color: theme.colors.charcoalDarker,
      },
      '& textarea': {
        color: theme.colors.charcoalDarker,
        paddingTop: 12,
      },
    },
    '& .Mui-focused': {
      borderColor: theme.colors.blueBase,
    },
  },
  description: {
    textAlign: 'center',
  },
  inputContainer: {
    marginTop: 32,
    paddingBottom: '32px',
    borderBottom: `1px solid ${theme.colors.skyBase}`,
  },
  label: {
    display: 'inline-flex',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 15,
  },
  close: {
    cursor: 'pointer',
    fontSize: 32,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 588,
    margin: '48px auto 0px',
  },
  title: {
    padding: '20px 25px 20px 32px',
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.colors.skyBase}`,
  },
  imagesContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 32,
  },
  image: {
    height: 80,
    width: 80,
    margin: '0px 12px',
  },
  storeKey: {
    display: 'flex',
    alignItems: 'center',
    '& h6': {
      marginLeft: 8,
      letterSpacing: '.44px',
    },
  },
  learnMoreBanner: {
    marginTop: 16,
  },
}))

function SetupModal(props) {
  const {
    store, open, setOpenSetup, reload,
  } = props

  const currentBrand = useSelector(state => state.currentBrand)
  const [storeKey, setStoreKey] = useState('')
  const [loading, setLoading] = useState(false)
  const { showToaster } = useContext(ToasterContext)
  const isDesktop = useMobileDetect().isOver1023()
  const classes = useStyles()
  const history = useHistory()

  const impersonating = sessionStorage.getItem('impersonating')

  const STORES_INFO = {
    shopify: {
      storeName: 'Shopify',
      image: shopifyIcon,
      guideLink: '360008892612-Integrating-DojoMojo-Shopify',
      connectDescription: (
        <div className={classes.center}>
          <p>
            1. By clicking &quot;Get started&quot; you will be redirected to the Shopify App Store.
          </p>
          <p> 2. Install the DojoMojo app on your store. </p>
          <p>3. You will be redirected back here after installing the app.</p>
        </div>
      ),
      action: () => {
        setLoading(true)
        window.location = 'https://apps.shopify.com/dojomojo'
      },
      actionText: 'Once you click “Get Started” you will be redirected to Shopify.',
      ctaLabel: 'Get Started',
    },
    woocommerce: {
      storeName: 'WooCommerce',
      connectLabel: 'WooCommerce Website',
      guideLink: '360026784811-Integrating-DojoMojo-WooCommerce',
      image: woocommerceIcon,
      placeholder: 'www.website.com',
      action: async () => {
        const storeData = await axios.get(`/woocommerce-auth/${encodeURIComponent(storeKey)}`, {
          headers: {
            brand: currentBrand.id,
            ...(impersonating ? { impersonating } : {}),
          },
        })
        window.location = storeData.data
      },
      actionText: 'Once you click “Connect,” you will be redirected to WooCommerce',
      ctaLabel: 'Connect',
    },
    stripe: {
      storeName: 'Stripe',
      guideLink: '360018223392-Integrating-DojoMojo-Stripe-',
      image: stripeIcon,
      action: () => {
        window.location.pathname = `/stripe-auth/${currentBrand.id}`
      },
    },
    recharge: {
      storeName: 'Recharge',
      connectLabel: 'API Key',
      connectTooltip:
        'API keys are used to read and update subscription information in Recharge. Go to the Recharge app in your Shopify Store, generate a new API token and paste the value below. Note that a DojoMojo+Shopify integration is required to connect Recharge.',
      guideLink: '4402628464525-Integrating-DojoMojo-Recharge',
      image: rechargeIcon,
      placeholder: 'Your API Key',
      action: async () => {
        setLoading(true)
        await axios.post(
          '/recharge-auth',
          { apiKey: storeKey },
          {
            headers: {
              brand: currentBrand.id,
              ...(impersonating ? { impersonating } : {}),
            },
          }
        )
        await reload()
        setLoading(false)
        history.push('/sms-marketing/settings/integrations')
      },
      actionText: '',
      ctaLabel: 'Connect',
    },
  }

  const {
    storeName,
    connectLabel,
    connectDescription,
    connectTooltip,
    guideLink,
    image,
    placeholder,
    action,
    actionText,
    ctaLabel,
  } = STORES_INFO[store]

  const handleClose = () => {
    setOpenSetup(false)
    history.push('/sms-marketing/settings/integrations')
  }

  const handleChange = event => {
    setStoreKey(event.target.value)
  }

  const onConnect = async () => {
    try {
      localStorage.setItem(
        'sms_store_integration_setup',
        JSON.stringify({
          value: true,
          expiry: moment().add(15, 'minutes'),
        })
      )
      await action()
    } catch (err) {
      showToaster('We couldn’t connect your integration. Please try again.', {
        type: 'warning',
      })
    } finally {
      setOpenSetup(false)
    }
  }

  const handleLearnMore = () => {
    window.open(`https://help.dojomojo.com/hc/en-us/articles/${guideLink}`)
  }

  const renderLearnMore = () => {
    if (store === 'recharge') {
      return (
        <>
          <Text charcoalBase size={16}>
            Complete your integration setup by filling out the information below.
          </Text>
          <div className={classes.learnMoreBanner}>
            <StatusBanner open align="center" status="informational">
              <BannerContent
                title="Having trouble finding your API key?"
                buttonLabel="Learn More"
                buttonOnClick={handleLearnMore}
                buttonRight
                buttonType="outlined"
              />
            </StatusBanner>
          </div>
        </>
      )
    }

    return (
      <Text charcoalBase size={16}>
        Connect your store to track campaign performance, get personalized recommendations, and
        activate DojoMojo&#39;s most powerful tools. For detailed instructions, view our
        <a
          href={`https://help.dojomojo.com/hc/en-us/articles/${guideLink}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {' '}
          guide.
        </a>
      </Text>
    )
  }

  return (
    <>
      {isDesktop ? (
        <Dialog open={open} fullScreen>
          <div className={classes.title}>
            <Text charcoalBase size={18}>
              Connect Online Store
            </Text>
            <CloseOutlined className={classes.close} onClick={handleClose} />
          </div>
          <div className={classes.content}>
            {loading ? (
              <Spinner label="Connecting your integration..." large />
            ) : (
              <>
                <Text charcoalDarker size={24}>
                  {`Connect to ${storeName}`}
                </Text>
                {renderLearnMore()}
                <div className={classes.imagesContainer}>
                  <img src={image} className={classes.image} alt={`${store}-icon`} />
                  <SyncClockwise />
                  <Logo className={classes.image} />
                </div>
                {connectLabel ? (
                  <div className={classes.inputContainer}>
                    <div className={classes.label}>
                      <Text charcoalBase size={14} font="medium">
                        {connectLabel}
                      </Text>
                      {connectTooltip && <ToolTip padding isVirtualTheme text={connectTooltip} />}
                    </div>
                    <div className={classes.storeKey}>
                      <TextField
                        placeholder={placeholder}
                        fullWidth
                        classes={{ root: classes.input }}
                        onChange={handleChange}
                      />
                      {store === 'shopify' && (
                        <Text font="regular" size={16}>
                          myshopify.com
                        </Text>
                      )}
                    </div>
                  </div>
                ) : null}
                {connectDescription}
                <div className={classes.buttons}>
                  <Text charcoalLight size={14}>
                    {actionText}
                  </Text>
                  <Button label={ctaLabel} onClick={onConnect} size="small" compact />
                </div>
              </>
            )}
          </div>
        </Dialog>
      ) : (
        <NonDesktopScreen open={open} setOpen={setOpenSetup} />
      )}
    </>
  )
}

SetupModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpenSetup: PropTypes.func.isRequired,
  store: PropTypes.string.isRequired,
  reload: PropTypes.func.isRequired,
}

export default SetupModal

import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import ColorPicker from 'components/shared/ColorPicker'
import LightboxBuilderThemeColorsSection from './LightboxBuilderThemeColorsSection'

const useStyles = makeStyles({
  themeColors: {
    paddingTop: 12,
  },
})

function LightboxBuilderThemeColors() {
  const css = useStyles()
  const { lightboxSettings } = React.useContext(BuilderContainerContext)
  const settings = lightboxSettings.getSettings()

  const [clr, setClr] = useState('#fff')
  const [openPicker, setOpenPicker] = useState(false)
  const [clrProp, setClrProp] = useState('')

  const getColor = propName => {
    let color = settings.themeColors[propName]
    if (propName.includes('Button')) ({ color } = settings[propName])

    return color
  }
  const onColorChange = (which, color) => {
    if (which.includes('Button')) settings[which] = { color }
    else settings.themeColors = { [which]: color }
  }
  const openColorPicker = colorKey => () => {
    const color = getColor(colorKey)
    setClr(color)
    setClrProp(colorKey)
    setOpenPicker(true)
  }
  const closeColorPicker = () => {
    setClr('#fff')
    setClrProp('')
    setOpenPicker(false)
  }
  const onPickerColorChange = color => {
    onColorChange(clrProp, color)
    closeColorPicker()
  }

  const themeColorsTemplates = {
    general: {
      label: 'General',
      colors: {
        background: 'Background Color',
        legalText: 'Legal Text',
      },
    },
    buttonsAndLinks: {
      label: 'Buttons & Links',
      colors: {
        legalLinks: 'Legal Links',
        primaryButton: 'Primary Button',
      },
    },
    formFields: {
      label: 'Form Fields',
      colors: {
        inputBorder: 'Field Border',
        inputBackground: 'Field Background',
        inputPlaceholder: 'Placeholder Text',
        inputText: 'Active Text',
      },
    },
  }

  const formTypeIsPopup = ['lightbox', 'popup']
  if (formTypeIsPopup.includes(settings.settings.type)) {
    themeColorsTemplates.general.colors.closeIcon = 'Close Icon Border'
    themeColorsTemplates.buttonsAndLinks.colors.secondaryButton = 'Secondary Button'
  }

  const colorsTemplateArray = Object.keys(themeColorsTemplates).map(
    key => themeColorsTemplates[key]
  )

  return (
    <div className={css.themeColors}>
      {colorsTemplateArray.map(theme => (
        <LightboxBuilderThemeColorsSection
          key={theme.label}
          label={theme.label}
          colors={theme.colors}
          onColorClick={openColorPicker}
          getColor={getColor}
        />
      ))}
      <ColorPicker
        show={openPicker}
        color={clr}
        onClose={closeColorPicker}
        onColorSelect={onPickerColorChange}
      />
    </div>
  )
}

export default React.memo(LightboxBuilderThemeColors)

import { createSelector } from 'reselect'

export const getCredentials = createSelector(
  state => state.profile.mostRecentBrands,
  mostRecentBrands => mostRecentBrands.map(({ brand }) => brand)
)

export const getUsersOtherBrands = createSelector(
  state => state.currentBrand.id,
  getCredentials,
  (currentBrandId, mostRecentBrands) => mostRecentBrands.filter(({ id }) => id !== currentBrandId)
)

export default getCredentials

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import FollowupCriteria from './FollowupCriteria'

const useStyles = makeStyles(theme => ({
  wrapper: {
    outline: `1px solid ${theme.colors.silver}`,
  },
  followups: {
    padding: 32,
    '& > :not(:last-of-type)': {
      marginBottom: 32,
    },
  },
}))

function FollowupsWrapper(props) {
  const css = useStyles()
  const { children } = props

  return (
    <div className={css.wrapper}>
      <FollowupCriteria {...props} />
      <div className={css.followups}>{children}</div>
    </div>
  )
}

FollowupsWrapper.propTypes = {
  children: PropTypes.arrayOf([PropTypes.node]).isRequired,
}

export default React.memo(FollowupsWrapper)

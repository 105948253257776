import React from 'react'
import PropTypes from 'prop-types'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

function DragContext({ children }) {
  return <DndProvider backend={HTML5Backend}>{children}</DndProvider>
}

DragContext.propTypes = {
  children: PropTypes.node.isRequired,
}

export default React.memo(DragContext)

import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import querystring from 'query-string'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'

import CustomBar from 'views/mobile/sms-marketing/shared/utils/CustomBar'

import Text from 'components/texts/Text'
import Button from 'components/virtual/buttons/Button'
import VirtualTheme from 'components/virtual/VirtualTheme'

import MobileModal from 'components/modals/MobileModal'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'
import ConnectedIntegrations from './ConnectedIntegrations'
import AvailableIntegrations from './AvailableIntegrations'
import BeforeLeavingWarningModal from './BeforeLeavingWarningModal'
import DontSeePreferredESPBanner from '../../banners/DontSeePreferredESPBanner'
import ESPCustomClose from './ESPCustomClose'

const useStyles = makeStyles(theme => ({
  integrationsModal: {
    paddingBottom: 32,
    maxWidth: 1200,
    margin: '32px auto 0',
  },
  title: {
    marginBottom: 32,
    '& > :first-child': {
      marginBottom: 32,
    },
  },
  exit: {
    padding: '4px 8px',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    marginRight: 20,
  },
  backArrowSvg: {
    marginRight: 8,
    fill: theme.colors.charcoalDarker,
    transformOrigin: '50%',
    transform: 'scale(1.5)',
    cursor: 'pointer',
  },
  closeBeforeExitPopup: {
    position: 'relative',
    height: 32,
    '& > :first-child': {
      left: -60,
    },
  },
}))

// Must match:
// NoESPDetectedBanner.js:8
// ESPList.js:37
// ESPNotSupportedBanner.js:9
const QUERY_PROP_NAME = 'configEsp'

function ESPIntegrationsModal() {
  const {
    lightboxSettings, espIntegration, values, actions,
  } = React.useContext(
    BuilderContainerContext
  )

  const { ESP } = values
  const { onSetESPValue, onSaveLightbox } = actions
  const settings = lightboxSettings.getSettings()
  const [showModal, setShowModal] = useState(false)
  const [espToConnect, setESPToConnect] = useState(null)
  const [selectedESP, setSelectedESP] = useState(null)
  const history = useHistory()
  const location = useLocation()
  const css = useStyles()
  const { getESPInfoById, loaded } = espIntegration

  // removes querystring that shows this modal
  const onCloseModal = () => history.push(history.location.pathname)

  const onSave = async () => {
    onSetESPValue(selectedESP)
    onCloseModal()
  }

  const customBarComponent = (
    <CustomBar
      title={(
        <div style={{ marginBottom: 4 }}>
          <Text size={18} charcoalBase>
            Signup Form Integration
          </Text>
        </div>
      )}
      saveButton={(
        <VirtualTheme>
          <Button
            label="Save & Close"
            type="text"
            size="smaller"
            color="secondary"
            onClick={onSave}
          />
        </VirtualTheme>
      )}
    />
  )

  // opens/closes modal
  useEffect(() => {
    const { [QUERY_PROP_NAME]: queryProp } = querystring.parse(window.location.search)
    const shouldOpen = queryProp === 'true'
    if (shouldOpen !== showModal) setShowModal(shouldOpen)
  }, [location, setShowModal, showModal, ESP, selectedESP])

  // gets selected esp from lightbox settings
  useEffect(() => {
    async function setESP() {
      const brandIntegrationId = settings?.espSettings.brandIntegrationId
      if (loaded && brandIntegrationId) {
        const esp = await getESPInfoById(brandIntegrationId)
        setSelectedESP(esp)
      }
    }
    setESP()
  }, [loaded]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedESP(ESP)
  }, [ESP])

  useEffect(() => {
    if (showModal) setSelectedESP(ESP)
  }, [showModal, ESP])

  const onSaveBeforeLeaveBuilder = async () => {
    const lightboxId = await onSaveLightbox({ returnId: true })
    if (lightboxId) {
      localStorage.setItem(
        'lightbox_esp_setup',
        JSON.stringify({
          value: lightboxId,
          expiry: moment().add(15, 'minutes'),
        })
      )

      window.location.href = `/app/profile/brand/esp/${espToConnect}?v=d`
    }
  }

  return (
    <MobileModal
      full
      closeButton
      noPad
      open={showModal}
      customBarComponent={customBarComponent}
      customClose={<ESPCustomClose selectedESP={selectedESP} />}
      onClose={onCloseModal}
      backButtonText="Exit"
    >
      <div className={css.integrationsModal}>
        <div className={css.title}>
          <Text size={18} charcoalDarker>
            Choose One ESP to Connect
          </Text>
          <DontSeePreferredESPBanner />
        </div>
        <ConnectedIntegrations setSelectedESP={setSelectedESP} selectedESP={selectedESP} />
        <AvailableIntegrations onConnectESP={setESPToConnect} />
      </div>
      <BeforeLeavingWarningModal
        show={!!espToConnect}
        onConfirm={onSaveBeforeLeaveBuilder}
        onCancel={() => setESPToConnect(null)}
      />
    </MobileModal>
  )
}

export default React.memo(ESPIntegrationsModal)

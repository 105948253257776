import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import { Tiny } from 'components/texts'
import InputBase from './InputBase'

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
  },
  infoText: {
    paddingTop: 4,
    position: 'absolute',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  danger: {
    '& > div [class*=root]': {
      borderColor: theme.colors.alertRed,
    },
  },
}))

function Input(props) {
  const {
    characterLimit, required, disableerror, validations, ...restProps
  } = props
  const classes = useStyles()
  const { value } = props
  const [isRequired, setIsRequired] = useState(false)

  const onFocus = () => setIsRequired(false)
  const onBlur = () => setIsRequired(required && !(value !== null ? value.length : 0))

  const remainingChars = characterLimit - (value !== null ? value.length : 0)
  const underLimit = characterLimit && remainingChars >= 0
  const characterLimitText = `${Math.abs(remainingChars)} characters ${
    underLimit ? 'remaining' : 'over the limit'
  }`

  // First error to trigger
  const validationFunc = validations.find(validation => validation(value))
  const validationError = validationFunc && validationFunc(value)

  const hasValidError = value && validationError
  const danger = (required && isRequired) || (characterLimit && !underLimit) || hasValidError
  const showError = !disableerror && (characterLimit || required || hasValidError)

  const inputProps = {
    onFocus,
    onBlur,
    ...restProps,
  }

  return (
    <div className={`${classes.wrapper} ${danger ? classes.danger : ''}`}>
      <InputBase {...inputProps} />
      {showError && (
        <div className={classes.infoText}>
          {required && (
            <Tiny alertRed>{required && isRequired ? 'This field is required' : ''}</Tiny>
          )}
          {characterLimit && (
            <Tiny alertRed={!underLimit}>{characterLimit ? characterLimitText : ''}</Tiny>
          )}
          {/* validation error message */}
          {validationError && <Tiny alertRed>{validationError}</Tiny>}
        </div>
      )}
    </div>
  )
}

Input.defaultProps = {
  characterLimit: null,
  required: false,
  disableerror: false,
  value: '',
  validations: [],
  onChange() {},
}

Input.propTypes = {
  onChange: PropTypes.func,
  required: PropTypes.bool,
  disableerror: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  characterLimit: PropTypes.number,
  validations: PropTypes.arrayOf(PropTypes.func),
}

export default React.memo(Input)

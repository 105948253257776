import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import InputWithDeleteAndOptions from 'components/virtual/inputs/InputWithDeleteAndOptions'

const useStyles = makeStyles({
  urlInput: {
    marginTop: 20,
  },
})

const getRandomPlaceholder = () => {
  const placeholders = [
    'eg. /new-arrivals',
    'eg. /checkout',
    'eg. /store/shirts',
    'eg. /placeholder',
    // TODO: Add more custom placeholders???
  ]

  return placeholders[Math.floor(Math.random() * placeholders.length)]
}

function UrlPathInput(props) {
  const {
    path, onChange, onDelete, isError,
  } = props

  const urlOptions = [
    { value: 'contain', label: 'URL Contains' },
    { value: 'exact', label: 'URL is' },
  ]
  // gets first option if not added by default
  const defaultOption = path.type || urlOptions.slice(-1)[0].value

  const css = useStyles()
  const { current: placeholder } = React.useRef(getRandomPlaceholder())
  const [url, setUrl] = useState(path.path)

  const onValuesChange = ({ inputValue, option = path.type }) => {
    setUrl(inputValue)
    onChange({ path: inputValue, type: option })
  }

  useEffect(() => {
    onValuesChange({ inputValue: url })
  }, [path.type]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={css.urlInput}>
      <InputWithDeleteAndOptions
        options={urlOptions}
        defaultSelectedOption={defaultOption}
        error={isError}
        controlled
        value={url}
        onChange={onValuesChange}
        placeholder={placeholder}
        onDelete={onDelete}
      />
    </div>
  )
}

UrlPathInput.propTypes = {
  path: PropTypes.object.isRequired,
  isError: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}

export default React.memo(UrlPathInput)

const initState = {
  campaignsLoading: true,
}

export default (state = initState, action) => {
  const { type, payload = {} } = action

  switch (type) {
    // Loaders
    case 'CAMPAIGNS_LOADING':
      return {
        ...state,
        campaignsLoading: true,
      }

    // Rest of actions
    case 'CAMPAIGNS_FAILED':
    case 'CAMPAIGNS_LOAD':
      return {
        ...state,
        ...payload,
        campaignsLoading: false,
      }

    default:
      return state
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Text from 'components/texts/Text'
import { ChevronRight } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  dropdownSelected: {
    position: 'relative',
    marginTop: 8,
    height: 48,
    borderRadius: 4,
    cursor: ({ disabled }) => (disabled ? 'default' : 'pointer'),
    backgroundColor: ({ disabled, open }) => (disabled || open ? theme.colors.skyLight : 'white'),
    minWidth: 98,
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    transition: 'background .2s linear',
    '&:hover': {
      backgroundColor: theme.colors.skyLight,
    },
  },
  icon: {
    transition: 'transform .2s linear',
    transform: ({ open }) => `rotate(${open ? 270 : 90}deg)`,
    fill: theme.colors.charcoalDarker,
  },
}))

function DropdownNoBorder(props) {
  const {
    label, isPlaceholder, placeholder, onToggle, disabled, fullWidth, open,
  } = props

  const css = useStyles({ disabled, fullWidth, open })

  return (
    <div className={css.dropdownSelected} onClick={onToggle}>
      <Text
        charcoalDarker={!disabled}
        charcoalLighter={disabled || isPlaceholder}
        size={16}
        ellipsis
      >
        {isPlaceholder ? placeholder : label}
      </Text>
      <ChevronRight className={css.icon} />
    </div>
  )
}

DropdownNoBorder.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  isPlaceholder: PropTypes.bool.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
}

export default React.memo(DropdownNoBorder)

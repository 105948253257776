import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import cn from 'classnames'

import { ReactComponent as Checkmark } from 'images/checkmark-green-bg.svg'

import { ToasterContext } from 'contexts'

import { H4 } from 'components/texts'
import ImageDropzoneUploader from 'components/shared/ImageDropzoneUploader'

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: '24px 12px',
  },
  imagesPreview: {
    display: 'grid',
    gridTemplateColumns: '200px 200px 200px',
    gridAutoRows: '128px',
    gridColumnGap: 4,
    gridRowGap: 4,
    margin: '20px 0 0',
    height: 344,
    overflowY: 'auto',
  },
  preview: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    '& img': {
      width: '100%',
    },
  },
  selected: {
    position: 'relative',
    border: `4px solid ${theme.colors.seaGreen}`,
    borderRadius: 2,
  },
  checkmark: {
    position: 'absolute',
    top: 4,
    left: 4,
    height: 16,
    width: 16,
  },
}))

const UPLOAD_TEXT = 'Add or select an image below. Files larger than 450KB are not currently supported.'

function UploadImageSection(props) {
  const { onMediaSelect } = props
  const css = useStyles()
  const [images, setImages] = useState([])
  const [selectedImage, setSelectedImage] = useState(null)
  const { showToaster } = useContext(ToasterContext)

  const onSelect = idx => {
    const selected = selectedImage !== idx ? idx : null
    setSelectedImage(selected)
    onMediaSelect(selected !== null ? images[selected] : null)
  }

  const onImageReject = () => {
    showToaster(
      'Some of your files couldn’t be uploaded because they exceed the maximum file size (450KB).',
      {
        type: 'warning',
        timeout: 5000,
      }
    )
  }

  return (
    <section className={css.wrapper}>
      <H4 multiline>{UPLOAD_TEXT}</H4>
      <div className={css.imagesPreview}>
        <ImageDropzoneUploader
          onImageReject={onImageReject}
          onImageUpload={img => setImages(imgs => [...imgs, img])}
        />
        {images.length
          ? images.map(({ image, img64 }, idx) => (
            <div
              key={image.lastModified}
              className={cn(css.preview, { [css.selected]: selectedImage === idx })}
              onClick={() => onSelect(idx)}
            >
              <img src={img64} alt="preview" />
              {selectedImage === idx && <Checkmark className={css.checkmark} />}
            </div>
          ))
          : null}
      </div>
    </section>
  )
}

UploadImageSection.propTypes = {
  onMediaSelect: PropTypes.func.isRequired,
}

export default React.memo(UploadImageSection)

import React from 'react'

import ESPIntegration from './ESPIntegration'
import ESPFieldValues from './ESPFieldValues'

function ESPCurrentIntegration() {
  return (
    <>
      <ESPIntegration />
      <ESPFieldValues />
    </>
  )
}

export default React.memo(ESPCurrentIntegration)

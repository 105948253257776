import { useEffect, useState } from 'react'

const useEnvironment = () => {
  const [env, setEnv] = useState()

  useEffect(() => {
    switch (process.env.NODE_ENV) {
      case 'development':
        return setEnv('DEV')
      default:
        return setEnv('PROD')
    }
  }, [])

  return env
}

export default useEnvironment

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import { BuilderContainerContext } from 'components/builder/BuilderContainerContext'

import GenericModalBase from 'components/virtual/modals/GenericModalBase'
import Text from 'components/texts/Text'
import Button from 'components/virtual/buttons/Button'

import copyToClipboard from 'utils/copyToClipboard'
import { ToasterContext } from 'contexts'

import FieldsActionRequiredBanner from '../banners/FieldsActionRequiredBanner'
import TCPAActionRequiredBanner from '../banners/TCPAActionRequiredBanner'
import NoSMSCreditsAvailableBanner from '../banners/NoSMSCreditsAvailableBanner'
import SubscriberLimitReachedBanner from '../banners/SubscriberLimitReachedBanner'
import EmailRequiredBeforePublishBanner from '../banners/EmailRequiredBeforePublishBanner'

const useStyles = makeStyles(theme => ({
  testUrl: {
    marginTop: 32,
  },
  testUrlLink: {
    marginTop: 8,
    border: `1px solid ${theme.colors.skyDarker}`,
    borderRadius: 4,
    padding: '4px 12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > a': {
      maxWidth: 'calc(100% - 100px)', // 100px = button width + padding
    },
  },
  link: {
    fontFamily: 'Larsseit-Medium, sans-serif',
    color: theme.colors.blueBase,
    textDecoration: 'none',
  },
}))

function LightboxBuilderPublishModal(props) {
  const { show, onClose } = props
  const toastr = React.useContext(ToasterContext)
  const { actions, values, id: lightboxId } = React.useContext(BuilderContainerContext)
  const { onPublishLightbox } = actions
  const css = useStyles()

  const {
    hasSubscribersLeft,
    tcpaReady,
    hasEnoughCredits,
    requiresEmailField,
    integrationRequiresESPValues,
    canPublish,
    isLoading,
  } = values

  let infoBannerToShow = null
  // non-blocking banner
  // this banner only shows when ESP values are missing + there's an ESP already set up
  if (integrationRequiresESPValues) infoBannerToShow = 'fieldsActionRequired'

  // blocking banners (take priority)
  if (!hasEnoughCredits) infoBannerToShow = 'noSMSCredits'
  else if (!hasSubscribersLeft) infoBannerToShow = 'subscriberLimitReached'
  else if (!tcpaReady) infoBannerToShow = 'TCPAActionRequired'
  else if (requiresEmailField) infoBannerToShow = 'emailFieldRequired'

  const testLink = `${process.env.REACT_APP_ENV_LIGHTBOX_URL}/index.html?preview=${lightboxId}`

  const onCopyLinkClick = () => {
    const isCopied = copyToClipboard(testLink)

    const [msg, toastrOpts = {}] = isCopied
      ? ['Copied to Clipboard', { type: 'success' }]
      : ['Could not copy to clipboard', { type: 'warning' }]

    toastr.showToaster(msg, toastrOpts)
  }

  const onPublishClick = () => onPublishLightbox().then(onClose)

  return (
    <GenericModalBase
      show={show}
      title="Ready to Publish?"
      confirmLabel="Publish"
      onCancel={onClose}
      onConfirm={onPublishClick}
      canSave={!isLoading && canPublish}
    >
      <div style={{ marginTop: 12 }} />
      <Text size={16} charcoalLight>
        Test your embed to review the design and functionality before publishing. To display the
        form on your site, follow the
        {' '}
        <Link to={`/signup-form/${lightboxId}/install`} className={css.link}>
          installation guide
        </Link>
        .
      </Text>
      <div className={css.testUrl}>
        <Text>Test URL</Text>
        <div className={css.testUrlLink}>
          <a href={testLink} target="_blank" rel="noreferrer noopener">
            <Text size={16} ellipsis>
              {testLink}
            </Text>
          </a>
          <Button label="Copy Link" onClick={onCopyLinkClick} type="text" size="smaller" />
        </div>
        {infoBannerToShow === 'fieldsActionRequired' && (
          <FieldsActionRequiredBanner onButtonClick={onClose} />
        )}
        {infoBannerToShow === 'TCPAActionRequired' && <TCPAActionRequiredBanner />}
        {infoBannerToShow === 'noSMSCredits' && <NoSMSCreditsAvailableBanner />}
        {infoBannerToShow === 'subscriberLimitReached' && <SubscriberLimitReachedBanner />}
        {infoBannerToShow === 'emailFieldRequired' && <EmailRequiredBeforePublishBanner />}
      </div>
    </GenericModalBase>
  )
}

LightboxBuilderPublishModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default React.memo(LightboxBuilderPublishModal)

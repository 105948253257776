import React from 'react'
import { bool, func, string } from 'prop-types'
import { Link } from 'react-router-dom'
import { P } from 'components/texts'
import { MenuItem } from '@material-ui/core'

function MyAccountMenuItem({
  to, onClick, onClose, label, azure,
}) {
  return (
    <Link to={to} onClick={onClick || onClose}>
      <MenuItem>
        <P azure={azure}>
          <i>{label}</i>
        </P>
      </MenuItem>
    </Link>
  )
}

MyAccountMenuItem.propTypes = {
  to: string.isRequired,
  onClose: func.isRequired,
  label: string.isRequired,
  azure: bool,
  onClick: func,
}

MyAccountMenuItem.defaultProps = {
  azure: false,
  onClick: null,
}

export default MyAccountMenuItem

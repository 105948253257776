import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

import Dropdown from 'components/virtual/selectors/Dropdown'
import Input from 'components/virtual/inputs/Input'
import { ClickAwayListener } from '@material-ui/core'
import useInputDebounce from 'hooks/useInputDebounce'
import VirtualTheme from '../VirtualTheme'

const useStyles = makeStyles(theme => ({
  inputWithOptions: {
    display: 'flex',
    oveflow: 'hidden',
    flex: 1,
    border: ({ focus }) => `1px solid ${theme.colors[focus ? 'blueBase' : 'skyDarker']}`,
    borderRadius: 4,
    // dropdown
    '& > :first-child': {
      borderRight: ({ focus }) => `1px solid ${theme.colors[focus ? 'blueBase' : 'skyDarker']}`,
    },
    // Input
    '& > :last-child': {
      flex: 1,
      '& > :first-child': {
        width: '100%',
        '& fieldset': {
          border: 'none',
        },
      },
    },
  },
  dropdownStyles: {
    marginTop: 0,
    border: 'none',
    padding: '12px 16px',
    '& *': {
      color: ({ disabled }) =>
        `${theme.colors[disabled ? 'charcoalLighter' : 'charcoalBase']} !important`,
      fontSize: '14px',
      lineHeight: '22px',
    },
    // arrow
    '& > :first-child > :last-child': {
      margin: '4px 0 0 16px',
      borderTopColor: ({ disabled }) =>
        `${theme.colors[disabled ? 'charcoalLighter' : 'charcoalBase']} !important`,
    },
  },
}))

function InputWithOptions(props) {
  const {
    onChange, defaultSelectedOption, options, value, disabled, controlled,
  } = props
  const [selectValue, setSelectValue] = useState(defaultSelectedOption)
  const [isFocus, setIsFocus] = useState(false)

  const focus = isFocus && !disabled
  const css = useStyles({ focus, disabled })

  const onInputChange = targetValue => {
    const inputValue = targetValue.target?.value ?? targetValue
    onChange({ option: selectValue, inputValue })
  }

  const [inputValue, onValueChange] = useInputDebounce(value, onInputChange, controlled)

  const onSelectChange = option => {
    setSelectValue(option.value)

    onChange({ option: option.value, inputValue })
  }

  return (
    <VirtualTheme>
      <ClickAwayListener onClickAway={() => setIsFocus(false)}>
        <div onClick={() => setIsFocus(true)} className={css.inputWithOptions}>
          <Dropdown
            onChange={onSelectChange}
            defaultValue={selectValue}
            options={options}
            disabled={disabled}
            dropdownStyles={css.dropdownStyles}
          />
          <Input {...props} onChange={onValueChange} value={inputValue} />
        </div>
      </ClickAwayListener>
    </VirtualTheme>
  )
}

InputWithOptions.propTypes = {
  defaultSelectedOption: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  controlled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.string, label: PropTypes.string }))
    .isRequired,
}

InputWithOptions.defaultProps = {
  disabled: false,
  controlled: false,
}

export default React.memo(InputWithOptions)

import { useEffect, useState } from 'react'
import axios from 'axios'

function useBrandBank() {
  const [isLoading, setIsLoading] = useState(true)
  const [bank, setBank] = useState({})

  useEffect(() => {
    async function getBank() {
      const { data: bankData } = await axios.get('/brand-sms-bank')

      setBank(bankData)
      setIsLoading(false)
    }
    getBank()
  }, [])

  return {
    isLoading,
    bank,
  }
}

export default useBrandBank

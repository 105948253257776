import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'

import { ReactComponent as ErrorIcon } from 'images/icons/error-simple.svg'

import { colors } from 'GlobalTheme'
import { P } from 'components/texts'

const BANNER_TYPES = {
  info: colors.foggy,
  warning: colors.coralLight,
  regular: colors.crystal,
  default: 'transparent',
}

const BORDER_TYPES = {
  warning: colors.lightRed,
}

const ICONS = {
  warning: <ErrorIcon />,
}

const useStyles = makeStyles({
  wrapper: {
    position: 'relative',
    padding: ({ pad }) => (pad ? '12px 20px' : 0),
    backgroundColor: ({ type }) => BANNER_TYPES[type] || BANNER_TYPES.default,
    marginBottom: 8,
    outline: ({ type, border }) =>
      (border ? `1px solid ${BORDER_TYPES[type] || colors.ink}` : 'none'),
    display: 'flex',
    '& > svg': {
      // icon, not close btn
      marginRight: 8,
    },
  },
  body: {
    flex: 1,
    paddingTop: ({ hasIcon }) => (hasIcon ? 2 : 0),
  },
})

function OneLineBanner(props) {
  const {
    icon, type, cta, body, pad,
  } = props
  const css = useStyles({ type, pad, hasIcon: !!icon })

  const iconComponent = icon && ICONS[icon]

  return (
    <div className={css.wrapper}>
      {icon && iconComponent}
      <div className={css.body}>{React.isValidElement(body) ? body : <P multiline>{body}</P>}</div>
      {React.isValidElement(cta) ? cta : <Link to={cta.url}>{cta.label}</Link>}
    </div>
  )
}

OneLineBanner.propTypes = {
  body: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  type: PropTypes.string,
  cta: PropTypes.object.isRequired,
  pad: PropTypes.bool,
  icon: PropTypes.string,
}

OneLineBanner.defaultProps = {
  type: 'default',
  pad: true,
  icon: null,
}

export default React.memo(OneLineBanner)

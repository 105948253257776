import React from 'react'
import { Provider } from 'react-redux'
import store from 'utils/reduxStore'
import initAxios from 'utils/initAxios'
import initAnalytics, { GoogleAnalytics, Intercom } from 'utils/analytics'
import { Router } from 'react-router-dom'

import NavBar, { ImpersonatingMarker } from 'containers/navbars'
import { Footer, PageWrapper } from 'components/navbars'
import ErrorBoundary from 'components/routing/ErrorBoundary'
import { AnnouncementsModal } from 'components/modals'
import Toaster from 'components/shared/Toaster'
import Routes from 'containers/Routes'
import ReducersLoader from 'containers/ReducersLoader'
import ReducersLoaderOptional from 'containers/ReduceLoadersOptional'

import useDebug from 'hooks/useDebug'

import history from 'utils/getHistory'

import GlobalTheme from 'GlobalTheme'
import GlobalContext from 'contexts'
import { ToasterProvider } from 'contexts/ToasterContext'
import VirtualTheme from 'components/virtual/VirtualTheme'

import UpdatedTermsModal from 'containers/terms'
import SmsWelcomeModal from 'components/modals/SmsWelcomeModal'
import TrialEndModal from 'components/modals/TrialEndModal'
import MessageEditor from 'components/virtual/messageEditor'
import CreateBrandProfileModal from 'containers/login/create-brand-profile/CreateBrandProfileModal'
import ProximaInfoModal from 'components/modals/ProximaInfoModal'
import initDataDog from './utils/datadog'

const BASENAME = '/app'
const supportsHistory = 'pushState' in window.history

const isBeforeApp = [
  '/about-us',
  // '/meet-the-team', // TODO: Disabled as of 11/30/2021
  '/login',
  '/register',
  '/forgot-password',
  '/update-password',
  '/add-brand',
  '/pricing',
  '/shopify/all-set',
].includes(history.location.pathname)

// Some routes could have a session (logged in) or not (public route)
const sessionOptional = ['/shopify/oauth'].includes(history.location.pathname)

initAxios()
initAnalytics()
initDataDog()

function App() {
  useDebug()

  if (sessionOptional) {
    return (
      <Provider store={store}>
        <Router basename={BASENAME} forceRefresh={!supportsHistory} history={history}>
          <GlobalTheme>
            <GlobalContext>
              <ReducersLoaderOptional>
                <PageWrapper>
                  <ErrorBoundary>
                    <Routes />
                  </ErrorBoundary>
                  <ImpersonatingMarker />
                  <Toaster />
                </PageWrapper>
              </ReducersLoaderOptional>
            </GlobalContext>
          </GlobalTheme>
        </Router>
      </Provider>
    )
  }

  // Public pages only
  if (isBeforeApp) {
    return (
      <Provider store={store}>
        <Router basename={BASENAME} forceRefresh={!supportsHistory} history={history}>
          <VirtualTheme>
            <ToasterProvider>
              <ErrorBoundary>
                <Routes />
              </ErrorBoundary>
              <Toaster />
            </ToasterProvider>
          </VirtualTheme>
        </Router>
      </Provider>
    )
  }

  // Pages that are not public and require a session
  return (
    <Provider store={store}>
      <Router basename={BASENAME} forceRefresh={!supportsHistory} history={history}>
        <GlobalTheme>
          <GlobalContext>
            <ReducersLoader>
              <GoogleAnalytics />
              <Intercom />
              <PageWrapper>
                <NavBar />
                <ErrorBoundary>
                  <Routes />
                </ErrorBoundary>
                <ImpersonatingMarker />
                <CreateBrandProfileModal />
                <AnnouncementsModal />
                <UpdatedTermsModal />
                <SmsWelcomeModal />
                <TrialEndModal />
                <ProximaInfoModal />
                <Toaster />
                <MessageEditor />
              </PageWrapper>
              <Footer />
            </ReducersLoader>
          </GlobalContext>
        </GlobalTheme>
      </Router>
    </Provider>
  )
}

export default App

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

const useStyles = makeStyles({
  radios: {
    margin: 0,
    padding: 0,
    border: 'none',
    position: 'relative',
  },
  radio: {
    cursor: 'pointer',
  },
})

function RadioSelector(props) {
  const {
    values, onChange, value, className, render,
  } = props

  const { current: id } = React.useRef(`radio-selector-${Date.now()}`)
  const [selected, setSelected] = useState(value)

  const css = useStyles()

  const onRadioChange = ({ target }) => {
    setSelected(target.value)
    onChange(target.value)
  }

  return (
    <fieldset id={id} className={cn(css.radios, className)} defaultValue={selected}>
      {values.map(radio => (
        <label key={radio.value} className={css.radio}>
          <input hidden onChange={onRadioChange} name={id} type="radio" value={radio.value} />
          {render({ ...radio, selected: radio.value === selected })}
        </label>
      ))}
    </fieldset>
  )
}

RadioSelector.propTypes = {
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
}

export default React.memo(RadioSelector)

import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Text from 'components/texts/Text'

const useStyles = makeStyles(theme => ({
  banner: {
    padding: 32,
    display: 'flex',
    alignItems: 'flex-start',
    background: theme.colors.tealLightest,
  },
}))

const MobileDesktopIcon = () => (
  <svg
    style={{ marginRight: 16 }}
    width="37"
    height="36"
    viewBox="0 0 37 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M6.40894 9H33.4089V6H6.40894C4.75894 6 3.40894 7.35 3.40894 9V25.5H0.408936V30H21.4089V25.5H6.40894V9ZM34.9089 12H25.9089C25.0839 12 24.4089 12.675 24.4089 13.5V28.5C24.4089 29.325 25.0839 30 25.9089 30H34.9089C35.7339 30 36.4089 29.325 36.4089 28.5V13.5C36.4089 12.675 35.7339 12 34.9089 12ZM33.4089 25.5H27.4089V15H33.4089V25.5Z"
        fill="#14CBA8"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="36" height="36" fill="white" transform="translate(0.408936)" />
      </clipPath>
    </defs>
  </svg>
)

function ThemeBanner() {
  const css = useStyles()

  return (
    <div className={css.banner}>
      <MobileDesktopIcon />
      <Text charcoalDarker>Your form theme will remain the same on both desktop and mobile.</Text>
    </div>
  )
}

export default React.memo(ThemeBanner)

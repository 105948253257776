import React, { useEffect } from 'react'

/**
 * creates an iframe object that will be mounted to containerRef and will facilitate
 * the communication between iframe and browser
 *
 * @property {Object} opts
 * @property {String} opts.src the source url that'll load into the iframe {Required}
 * @property {String=} opts.type the accepted type of message this iframe will receive/send
 *     to opts.src
 * @property {Function=} opts.onLoad function triggered when the iframe has been loaded
 * @property {Function=} opts.onMessageReceived function that triggers when a message is received
 *    from source, which depends of opts.type
 *
 * @returns {Object} { containerRef, onSendMessage } containerRef: where the iframe will mount;
 *                                                   onSendMessage: communicate to the iframe
 */
function useIframe(opts) {
  const {
    src, type = 'iframe-hook', onLoad = () => {}, onMessageReceived = () => {},
  } = opts

  const containerRef = React.useRef(null)
  const iframeRef = React.useRef(null)
  const onMessageRef = React.useRef(e => {
    try {
      if (typeof e.data === 'string') {
        const data = JSON.parse(e.data)
        if (data.source === type) onMessageReceived(data)
      }
    } catch (err) {
      /*  */
    }
  })

  function onSendMessage(data) {
    if (iframeRef.current) {
      const iframeContent = iframeRef.current.contentDocument || iframeRef.current.contentWindow
      const iframeData = JSON.stringify({ ...data, source: type })
      iframeContent.postMessage(iframeData, '*')
    }
  }

  useEffect(() => {
    const container = containerRef.current
    const onMessage = onMessageRef.current

    if (container) {
      iframeRef.current = document.createElement('iframe')

      iframeRef.current.src = src
      iframeRef.current.onload = () => onLoad({ onSendMessage })

      window.addEventListener('message', onMessage)

      container.appendChild(iframeRef.current)
    }

    return () => {
      window.removeEventListener('message', onMessage)
    }
  }, [containerRef, src]) // eslint-disable-line react-hooks/exhaustive-deps

  return { containerRef, onSendMessage }
}

export default useIframe

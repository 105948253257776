import React, { useState } from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { makeStyles } from '@material-ui/core/styles'
import Arrow from '@material-ui/icons/ExpandMore'

import Button from './Button'
import PopUpMenu from '../menus/PopUpMenu'

const useStyles = makeStyles({
  arrow: {
    color: 'white',
    transition: 'transform .17s linear',
  },
  button: {
    display: 'flex',
  },
  rotate: {
    transform: 'rotate(180deg)',
  },
  root: {
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    padding: '0px 16px',
  },
  tab: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    marginLeft: 4,
    minWidth: 0,
    padding: 0,
    width: 36,
  },
})

function DropdownButton(props) {
  const {
    color, label, onClick, size, options, menuComponent,
  } = props
  const anchorRef = React.useRef(null)
  const [open, setOpen] = useState(false)

  const classes = useStyles()

  const Menu = menuComponent || PopUpMenu

  const onOpenDropdownClick = () => setOpen(true)
  const onCloseDropdownClick = () => setOpen(false)

  const onButtonClick = () => {
    if (onClick) onClick()
    else setOpen(!open)
  }

  return (
    <ClickAwayListener onClickAway={onCloseDropdownClick}>
      <div ref={anchorRef} className={classes.button}>
        <Button
          onClick={onButtonClick}
          classes={{ root: classes.root }}
          color={color}
          label={label}
          size={size}
        />
        <Button
          classes={{
            root: classes.tab,
          }}
          color={color}
          onClick={onOpenDropdownClick}
          size={size}
        >
          <Arrow className={cn(classes.arrow, { [classes.rotate]: open })} />
        </Button>
        <Menu ref={anchorRef} open={open} options={options} onClose={() => setOpen(false)} />
      </div>
    </ClickAwayListener>
  )
}

DropdownButton.propTypes = {
  menuComponent: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  color: PropTypes.oneOf(['primary', 'secondary', 'default']),
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.string,
}

DropdownButton.defaultProps = {
  onClick: null,
  menuComponent: null,
  color: 'primary',
  size: 'small',
}

export default DropdownButton

const CHARACTER_LIMIT_DEFAULT = 160
const CHAREMOJI_LIMIT_DEFAULT = 70
const MMS_CHARS_LIMIT_DEFAULT = 1600
// eslint-disable-next-line no-control-regex
const UNICODE_REGEX = /[^\u0000-\u00ff]/

export default function calculateCredits(text, isMMS = false) {
  const hasUnicode = UNICODE_REGEX.test(text)
  const charLimit = isMMS
    ? MMS_CHARS_LIMIT_DEFAULT
    : hasUnicode
      ? CHAREMOJI_LIMIT_DEFAULT
      : CHARACTER_LIMIT_DEFAULT

  const multipart = text.length >= charLimit
  const appendedHeader = charLimit - (multipart ? 7 : 0)
  const creditsInUse = text.length !== 0 ? Math.ceil(text.length / appendedHeader) : 1
  const charsInUse = text.length

  const values = {
    charsInUse,
    credits: creditsInUse * (isMMS ? 3 : 1),
  }

  return values
}

import moment from 'moment-timezone'

export { default as calculateCredits } from './calculateCredits'
export { default as formatPhoneNumber } from './formatPhoneNumber'
export { default as mergeTags } from './smsEditorMergeTags'
export { default as checkForInvalidShortUrlLink } from './checkForInvalidShortUrlLink'
export { default as replaceEscapeChars } from './replaceEscapeChars'
export {
  default as generateDeliveryTimes,
  getClosestTimeIn15MinsInterval,
} from './generateDeliveryTimes'

export const NEWYORK_TIMEZONE = 'America/New_York' // IF THIS CHANGES, CHANGE ALSO IN generateDeliveryTimes FILE
export const NEWYORK_TIMEZONE_ABBR = moment.tz(NEWYORK_TIMEZONE).zoneAbbr()
export const INTEGRATIONS_LIST_DEFAULT_CRITERIA = ['woocommerce', 'shopify']

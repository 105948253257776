import React from 'react'
import PropTypes from 'prop-types'
import { useFormState } from 'react-final-form'
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash'
import cn from 'classnames'

import { SendFollowupCriteria } from 'components/shared/SmsFollowupsComponents'

import { P } from 'components/texts'
import EditorWrapperTitle from './EditorWrapperTitle'
import EditingContent from './EditingContent'
import MessagePreview from './MessagePreview'

const useStyles = makeStyles(theme => ({
  container: {
    outline: `1px solid ${theme.colors.silver}`,
    borderRadius: 2,
  },
  preview: {
    padding: '40px 40px 20px',
  },
  sentText: {
    display: 'inline',
    position: 'absolute',
    right: 0,
    top: 102,
  },
  extraTop: {
    top: 170,
  },
}))

function EditorWrapper(props) {
  const { name, customSettingsComponent } = props
  const { values } = useFormState()
  const css = useStyles()

  const {
    isEditing = false,
    unit_of_time,
    send_interval,
    isFollowup,
    isDisabled,
    canDelete,
    status,
  } = _.get(values, name)

  const sendingCriteria = isFollowup
    ? {
      title: 'Sending',
      content: `${send_interval} ${unit_of_time}s after previous message`,
    }
    : null

  const customComponent = status === 'sent' ? (
    <div className={cn(css.sentText, { [css.extraTop]: isFollowup })}>
      <P>
        <strong>Message was sent</strong>
      </P>
    </div>
  ) : null

  const settingsComponent = isFollowup ? (
    <SendFollowupCriteria name={name} />
  ) : (
    customSettingsComponent
  ) // or null

  return (
    <div className={css.container}>
      {isEditing && !isDisabled ? (
        <>
          <EditorWrapperTitle {...props} enableActions={isFollowup ? canDelete : true} />
          <EditingContent {...props} settingsComponent={settingsComponent} />
        </>
      ) : (
        <div className={css.preview}>
          <MessagePreview
            {...props}
            titleActions={{
              noButtons: isDisabled,
              canDelete,
              customComponent,
            }}
            sendingCriteria={sendingCriteria}
          />
        </div>
      )}
    </div>
  )
}

EditorWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  customSettingsComponent: PropTypes.node,
}

EditorWrapper.defaultProps = {
  customSettingsComponent: null,
}

export default React.memo(EditorWrapper)

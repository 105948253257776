export default {
  'Advertising & Marketing': ['advertising', 'adtech', 'advertiser', 'advertisement', 'marketing'],
  'Alcoholic Beverages': [
    'alcohol',
    'mixology',
    'libation',
    'liquor',
    'booze',
    'coffee',
    'ale',
    'spirits',
    'wine',
    'beer',
    'winery',
    'bar',
    'speakeasy',
  ],
  'Art & Entertainment': ['painting, artist, creative, movie', 'art'],
  B2B: ['business', 'industrial', 'corporation', 'service', 'saas', 'agency', 'consultant', 'b2b'],
  'Beauty & Makeup': [
    'curling iron',
    'straightener',
    'blow dryer',
    'shampoo',
    'conditioner',
    'frizz-control',
    'volumizing',
    'eyeliner',
    'eye-liner',
    'mascara',
    'beauty',
    'makeup',
  ],
  'Blogs & Social Media': [
    'facebook',
    'instagram',
    'socials',
    'communication',
    'friend',
    'blog',
    'social',
  ],
  Books: ['novel', 'literature', 'magazine', 'story', 'stories'],
  CBD: ['hemp', 'cbd', 'marijuana', 'canabidoid', 'adaptogenic', 'weed', 'cannabis'],
  'Community & Non-Profit': [
    'group',
    'neighborhood',
    'establishment',
    'churn',
    'commune',
    'organization',
    'community',
    'nonprofit',
  ],
  'Daily Deals': [
    'discount',
    'coupon',
    'groupon',
    'sweepstakes',
    'offer',
    'deal',
    'savings',
    'rebate',
  ],
  eCommerce: ['marketplace', 'amazon', 'conversion', 'ecommerce'],
  Education: ['school', 'learn', 'learning', 'teacher', 'education'],
  Fitness: ['excercise', 'workout', 'work', 'physical', 'strength', 'fitness'],
  'Food & Beverages': [
    'recipe',
    'dessert',
    'food',
    'drink',
    'cook',
    'eat',
    'kitchen',
    'grub',
    'meal',
    'feed',
    'dinner',
    'lunch',
    'breakfast',
    'brunch',
    'snack',
    'dining',
    'dine',
    'provisions',
    'groceries',
    'bite',
    'eaten',
    'grill',
    'beverage',
  ],
  Gaming: [
    'esports',
    'techie',
    'nerd',
    'gamer',
    'gaming',
    'games',
    'video game',
    'streaming',
    'streamer',
  ],
  'Health & Wellness': [
    'natural',
    'non-toxic',
    'healing',
    'health',
    'anxiety',
    'calm',
    'stress',
    'diet',
    'nutrition',
    'hydrate',
    'hydrating',
    'healthy',
    'well-being',
    'vitalality',
    'vital',
    'weight loss',
    'wellness',
  ],
  'Hobbies & Leisure': ['learn', 'tutorial', 'how-to', 'diy', 'homemade', 'hobbies', 'leisure'],
  'Home & Garden': [
    'home',
    'house',
    'apartment',
    'cozy',
    'plants',
    'furniture',
    'textiles',
    'towels',
    'bedding',
    'linens',
    'mattress',
    'mortgage',
    'condo',
    'rug',
    'flooring',
    'wall art',
    'decor',
    'landscape',
    'garden',
    'planter',
    'lighting',
    'patio',
    'hot tub',
    'bath',
    'bed',
    'dwelling',
    'abode',
  ],
  'Hotels & Accommodations': [
    'hotel',
    'resort',
    'cruise',
    'vacation rental',
    'accomodations',
    'hostel',
    'tavern',
    'lodge',
    'lodging',
    'inn',
    'bed and breakfast',
    'b&b',
    'guesthouse',
    'hotelier',
    'hospitality',
  ],
  'Kids & Babies': ['children', 'babies', 'tikes', 'toddlers', 'kids'],
  Luxury: [
    'luxury',
    'luxe',
    'exclusive',
    'premier',
    'quality',
    'one-of-a-kind',
    'expensive',
    'premium',
  ],
  Media: [
    'television',
    'content',
    'newsletter',
    'creative',
    'show',
    'event',
    'movie',
    'convention',
    'festival',
    'audience',
    'viewer',
    'media',
  ],
  "Men's Fashion": ['style', 'fashion', 'attire', 'apparel', 'clothing', 'men'],
  Music: [
    'tunes',
    'beats',
    'jive',
    'musician',
    'music',
    'concert',
    'song',
    'performer',
    'sing',
    'instrument',
    'guitar',
    'recording studio',
    'concert',
  ],
  Organic: [
    'vegan',
    'vegetarian',
    'plant',
    'non-toxic',
    'cruelty free',
    'eco',
    'nature',
    'natural',
    'organic',
  ],
  Other: ['misc', 'miscellaneous', 'random', 'other'],
  Parenting: [
    'baby',
    'babies',
    'mom',
    'mother',
    'father',
    'parent',
    'dad',
    'mama',
    'child',
    'children',
    'kid',
    'papa',
    'family',
    'stroller',
    'bib',
    'crib',
    'highchair',
    'pacifier',
    'parenting',
  ],
  'Pet Supplies': [
    'dog',
    'cat',
    'pet',
    'fur baby',
    'furry friend',
    'litter',
    'puppy',
    'puppies',
    'kitten',
    'kittens',
    'animal',
  ],
  'Plant Based': [
    'vegan',
    'vegetarian',
    'plant',
    'non-toxic',
    'cruelty free',
    'eco',
    'nature',
    'natural',
    'organic',
  ],
  'Professional Development': [
    'linkedin',
    'professional',
    'network',
    'networking',
    'executive',
    'continuing education',
    'help',
  ],
  'Real Estate': [
    'property',
    'home',
    'broker',
    'house',
    'building',
    'office space',
    'apartment',
    'condo',
    'condominium',
    'real estate',
  ],
  Restaurants: ['restaurant', 'bar', 'tavern', 'pub', 'cafe', 'vineyard', 'brewery', 'restaurant'],
  Shopping: ['shops', 'store', 'stores', 'retailer', 'retail', 'shopping'],
  'Skin Care': [
    'make-up',
    'serum',
    'anti-aging',
    'cream',
    'lotion',
    'facemask',
    'pore',
    'razors',
    'aftershave',
    'shaving cream',
    'cologne',
    'beard',
    'facial hair',
  ],
  'Software & Apps': [
    'engineer',
    'developer',
    'application',
    'computer',
    'server',
    'cloud',
    'startup',
    'start-up',
    'silicon valley',
    'software',
  ],
  'Sports & Adventures': [
    'football',
    'soccer',
    'volleyball',
    'tennis',
    'rugby',
    'balls',
    'cleats',
    'athlete',
    'athletics',
    'recreation',
    'golf',
    'jock',
    'baseball',
    'zipline',
    'extreme',
    'sports',
  ],
  Sustainability: [
    'sustainable',
    'green',
    'renewable',
    'energy',
    'organic',
    'environmental',
    'natural',
    'ecological',
    'viable',
  ],
  'Tech & Electronics': ['technology', 'techie', 'nerd', 'electronic', 'appliance', 'tech'],
  Travel: [
    'flights',
    'international',
    'tourist',
    'expedition',
    'excursion',
    'journey',
    'trek',
    'trip',
    'getaway',
    'travel',
    'wander',
    'wanderer',
    'wanerlust',
    'wanderluster',
    'voyage',
    'safari',
    'abroad',
    'fly',
    'luggage',
    'passport',
    'suitcase',
    'destination',
  ],
  'Wedding & Bridal': ['bride', 'groom', 'bridal', 'wedding', 'gown', 'veil'],
  "Women's Fashion": [
    'style',
    'stylish',
    'fashion',
    'attire',
    'apparel',
    'clothing',
    'ladies',
    'lady',
    'women',
    'womens',
    'woman',
    'girl',
    'gal',
    'babe',
    'chic',
    'female',
    'ladies',
    'dress',
  ],
}

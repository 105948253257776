import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

import styleHelper from 'utils/styleHelper'

import Text from 'components/texts/Text'

const useStyles = makeStyles(theme => ({
  category: {
    backgroundColor: 'white',
    border: `1px solid ${theme.colors.navyBase}`,
    cursor: 'pointer',
    borderRadius: 28,
    marginBottom: 12,
    marginRight: 8,
    padding: '12px 24px',
    userSelect: 'none',
    '& p': {
      color: theme.colors.navyBase,
      fontSize: 14,
      whiteSpace: 'nowrap',
    },
    [styleHelper.mobile]: {
      padding: '12px 16px',
      '& p': {
        fontSize: 12,
      },
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  selected: {
    backgroundColor: theme.colors.navyBase,
    '& p': {
      whiteSpace: 'nowrap',
      color: 'white',
    },
  },
}))

function Categories(props) {
  const {
    max, options, selected, setSelected,
  } = props

  const classes = useStyles()

  // update array `selected` minus the unselected option
  const unselect = option => setSelected(selected.filter(s => s !== option))

  // update array `selected` with new selected option appended
  const select = option => setSelected(selected.concat(option))

  if (!options.length) return null

  return (
    <div className={classes.container}>
      {options.map(option => {
        const isSelected = selected.includes(option)
        return (
          <div
            className={cn(classes.category, { [classes.selected]: isSelected })}
            key={option}
            onClick={
              isSelected
                ? () => unselect(option)
                : max && selected.length >= max
                  ? () => {}
                  : () => select(option)
            }
          >
            <Text>{option}</Text>
          </div>
        )
      })}
    </div>
  )
}

Categories.propTypes = {
  max: PropTypes.number,
  options: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  setSelected: PropTypes.func.isRequired,
}

Categories.defaultProps = {
  max: null,
}

export default React.memo(Categories)

import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import { H6 } from 'components/texts'
import blueAddIcon from 'images/icons/blue-add-icon.svg'

const useStyles = makeStyles({
  textBtn: {
    display: 'inline-flex',
    alignItems: 'flex-end',
    cursor: 'pointer',
  },
  icon: {
    marginRight: 8,
  },
})

const TextButton = props => {
  const { label, action } = props
  const css = useStyles()

  return (
    <div className={css.textBtn} onClick={action}>
      <img className={css.icon} src={blueAddIcon} alt="add" />
      <H6 azure>{label}</H6>
    </div>
  )
}

TextButton.propTypes = {
  label: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
}

export default React.memo(TextButton)

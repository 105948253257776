import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'

import { ButtonGroup } from 'components/buttons'
import { H4 } from 'components/texts'
import { AnnouncementsModalContext } from 'contexts'

import Modal from './MobileModal'

const styles = () => ({
  buttons: {
    marginTop: 32,
  },
})

function AnnouncementsModal({ classes }) {
  const { values } = useContext(AnnouncementsModalContext)

  return (
    <Modal open={values.open} title={values.title}>
      <H4 multiline>
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: values.content }} />
      </H4>
      <div className={classes.buttons}>
        <ButtonGroup
          relative
          onCancel={values.cancelAction}
          onSubmit={values.action}
          cancelLabel={values.cancelLabel}
          submitLabel={values.label}
        />
      </div>
    </Modal>
  )
}

AnnouncementsModal.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AnnouncementsModal)

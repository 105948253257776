import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { P, Small } from 'components/texts'
import {
  Menu as MuiMenu, MenuItem, MenuList, Divider, makeStyles,
} from '@material-ui/core'
import { messagingRelativeDate } from 'utils/relativeDate'
import { markNotificationRead, markAllRead } from 'actions/messaging'

import withCloseFn from 'components/menus/withCloseFn'
import MessageMenuItem from './MessageMenuItem'

const useMenuStyles = makeStyles(() => ({
  paper: {
    width: props => (props.isMobile ? '100%' : '400px'),
    maxWidth: props => (props.isMobile ? '100%' : null),
    marginTop: props => (props.isMobile ? '64px' : null),
  },
}))

const useStyles = makeStyles(theme => ({
  emptyBoxMessage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '62px',
    width: '100%',
  },
  footer: {
    display: 'flex',
    width: '100%',
    height: '41px',
    alignItems: 'center',
    justifyContent: 'center',
    '& strong': {
      cursor: 'pointer',
    },
  },
  grow: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    padding: '12px 16px',
  },
  logoWrapper: {
    overflow: 'hidden',
    background: theme.colors.white,
    borderRadius: '50%',
    height: '48px',
    minWidth: '48px',
    width: '48px',
    marginRight: '12px',
    '& > img': {
      objectFit: 'contain',
      height: '100%',
      width: '100%',
    },
  },
  menuItem: {
    height: '72px',
    alignContent: 'center',
  },
  messageContainer: {
    overflow: 'hidden',
    width: '100%',
  },
  name: {
    display: 'flex',
    flexGrow: 1,
    marginBottom: '12px',
  },
  notificationContainer: {
    display: 'flex',
    flexGrow: 1,
    minHeight: 48,
  },
  notificationTextContainer: {
    display: '-webkit-box',
    whiteSpace: 'normal',
    overflow: 'hidden',
    lineClamp: 2,
    boxOrient: 'vertical',
    flexGrow: 1,
    paddingRight: 8,
  },
  separator: {
    margin: '0px',
    flexGrow: 1,
    width: '100%',
  },
  text: {
    fontFamily: 'Larsseit-Light',
    display: 'block',
  },
  timestamp: {
    marginLeft: '16px',
  },
  title: {
    display: 'flex',
  },
  unreadMessage: {
    backgroundColor: theme.colors.iceBlue,
  },
}))

/*eslint-disable */
const renderNotification = (classes, currentBrandId, dispatch) => ({
  id,
  presentational: { img: logo, text, to },
  createdAt: timestamp,
  read_at,
  brand_id,
  /*eslint-enable */
}) => (
  <Link
    className="notification-item"
    key={id}
    to={currentBrandId === brand_id ? to || '/' : `${to}?brandId=${brand_id}`}
    target={currentBrandId === brand_id ? null : '_blank'}
    onClick={() => dispatch(markNotificationRead(id))}
  >
    <MenuItem className={`${classes.menuItem} ${!read_at ? classes.unreadMessage : ''}`}>
      <div className={classes.logoWrapper}>
        <img src={logo} alt={text} />
      </div>
      <div className={classes.notificationContainer}>
        <div className={classes.notificationTextContainer}>
          <P multiline>
            <i className="notification-item-text">{text}</i>
          </P>
        </div>
        <Small>{messagingRelativeDate(timestamp)}</Small>
      </div>
    </MenuItem>
    <Divider className={classes.separator} />
  </Link>
)

function MessagesMenu(props) {
  const {
    anchorEl,
    open,
    onClose,
    messages,
    notifications,
    unreadMessageCount,
    unreadNotificationsCount,
    isMobile,
    currentBrandId,
  } = props

  const menuClasses = useMenuStyles({ isMobile })
  const classes = useStyles()
  const dispatch = useDispatch()

  const notificationsRenderer = renderNotification(classes, currentBrandId, dispatch)

  const LinkWithClose = withCloseFn(Link, onClose, true)
  const MessageMenuItemWithClose = withCloseFn(MessageMenuItem, onClose)

  const trimTo = isMobile ? 2 : 3

  const msgs = messages
    .slice(0, trimTo)
    .map(({
      conversationId, title, image, text, createdAt, isRead,
    }) => (
      <MessageMenuItemWithClose
        key={conversationId}
        to={`/messaging/inbox/${conversationId}`}
        brandName={title}
        logo={image}
        text={text}
        sentAt={createdAt}
        isRead={isRead}
      />
    ))
  const nots = notifications.slice(0, trimTo).map(notificationsRenderer)

  return (
    <div onClick={onClose}>
      <MuiMenu
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        marginThreshold={isMobile ? 0 : 26}
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorReference={isMobile ? 'none' : 'anchorEl'}
        anchorPosition={isMobile ? { top: 0, left: 0 } : null}
        classes={menuClasses}
      >
        <div className={classes.header}>
          <div className={classes.grow}>
            <P>
              <strong>{`Messages (${unreadMessageCount})`}</strong>
            </P>
          </div>
          <P>
            <LinkWithClose to="/messaging">See all messages</LinkWithClose>
          </P>
        </div>
        <Divider className={classes.separator} />
        {messages.length ? (
          <MenuList>{msgs}</MenuList>
        ) : (
          [
            <div className={classes.emptyBoxMessage} key="emptyMessage">
              <P cloudGrey>
                <strong id="noMessageText">No messages yet. Reach out to someone!</strong>
              </P>
            </div>,
            <Divider className={classes.separator} key="divider" />,
          ]
        )}
        <div className={classes.header}>
          <div className={classes.grow}>
            <P>
              <strong>{`Notifications (${unreadNotificationsCount})`}</strong>
            </P>
          </div>
          <P>
            <LinkWithClose to="/notifications">See all notifications</LinkWithClose>
          </P>
        </div>
        <Divider className={classes.separator} />
        <MenuList>{nots}</MenuList>
        <div className={classes.footer}>
          <P seaGreen>
            <strong onClick={() => dispatch(markAllRead())}>Mark all as read</strong>
          </P>
        </div>
      </MuiMenu>
    </div>
  )
}

MessagesMenu.propTypes = {
  anchorEl: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  messages: PropTypes.array.isRequired,
  notifications: PropTypes.array.isRequired,
  unreadMessageCount: PropTypes.number.isRequired,
  unreadNotificationsCount: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  currentBrandId: PropTypes.number,
}

MessagesMenu.defaultProps = {
  anchorEl: {},
  currentBrandId: null,
}

export default React.memo(MessagesMenu)

import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Dialog, makeStyles } from '@material-ui/core'

import * as metrics from 'utils/metrics'
import styleHelper from 'utils/styleHelper'
import useMobileDetect from 'hooks/useMobileDetect'

import VirtualTheme from 'components/virtual/VirtualTheme'
import Sidebar from './Sidebar'
import ChooseIndustries from './ChooseIndustriesPage'
import EditProfile from './EditProfilePage'
// import ScheduleCall from './ScheduleCallPage'
import ConnectShopify from './ConnectShopify'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    width: 794,
    height: 568,
    [styleHelper.max(1023)]: {
      flexDirection: 'column',
      height: '100%',
      width: '100%',
    },
  },
  dialog: {
    borderRadius: 8,
    [styleHelper.max(1023)]: {
      borderRadius: 0,
    },
  },
  page: {
    height: '100%',
    width: '100%',
  },
  sidebar: {
    maxWidth: 325,
    minWidth: 325,
    [styleHelper.max(1023)]: {
      maxWidth: '100%',
      minWidth: '100%',
    },
  },
})

const track = action => () => {
  metrics.create('registrationFlow', {
    meta: {
      page: 'userRegistration',
      action,
    },
  })
}

function CreateBrandProfileModal() {
  const classes = useStyles()
  const isMobile = useMobileDetect().max(1024)
  const currentBrand = useSelector(state => state.currentBrand)
  const { hasNotFinishedSetupBrand } = currentBrand.conditionals
  const [open, setOpen] = useState(hasNotFinishedSetupBrand)
  const [page, setPage] = useState(0)
  const [values, setValues] = useState({ logo: currentBrand.logo })

  const pageUp = () => setPage(page + 1)
  const pageDown = () => setPage(page - 1)

  const pageValues = [
    {
      blurb:
        'Choosing the right tags will make it easier for brands to find you for partnership opportunities.',
      onNext: () => pageUp(),
      subtitle: 'Choose the tags that best describe your brand.',
      title: 'Describe your industry',
    },
    {
      blurb:
        'Make your profile short, sweet, and captivating! This is how other brands will see yours.',
      onPrev: () => pageDown(),
      onNext: () => pageUp(),
      subtitle: 'Add your brand details so partners can easily find you.',
      title: 'Make sure your brand is seen',
    },
    // {
    //   blurb: 'Brands who book a discovery call see 40% greater ROI on DojoMojo.',
    //   onPrev: () => pageDown(),
    //   onNext: () => pageUp(),
    //   subtitle: 'Connect with our experts to help you get started!',
    //   title: 'Almost done!',
    // },
    {
      blurb: 'Connecting your store will help us match you with brands that share similar characteristics.',
      onPrev: () => pageDown(),
      onNext: () => setOpen(false),
      subtitle: 'Connect Shopify for enhanced revenue attribution and brand partner matching.',
      title: 'Connect your store',
    },
  ]

  const Page = [
    ChooseIndustries,
    EditProfile,
    // ScheduleCall,
    ConnectShopify,
  ][page]

  return (
    <VirtualTheme>
      <Dialog
        classes={{ paper: classes.dialog }}
        fullScreen={isMobile}
        maxWidth={false}
        open={open}
      >
        <div className={classes.container}>
          <div className={classes.sidebar}>
            <Sidebar
              blurb={pageValues[page].blurb}
              page={page + 1}
              subtitle={pageValues[page].subtitle}
              title={pageValues[page].title}
            />
          </div>
          <div className={classes.page}>
            <Page
              onNext={pageValues[page].onNext}
              onPrev={pageValues[page].onPrev}
              setValues={setValues}
              track={track}
              values={values}
            />
          </div>
        </div>
      </Dialog>
    </VirtualTheme>
  )
}

export default React.memo(CreateBrandProfileModal)

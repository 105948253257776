import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

import Text from 'components/texts/Text'
import InputBase from './InputBase'
import Tooltip from '../utility/Tooltip'

const useStyles = makeStyles(theme => ({
  label: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: 6,
    paddingLeft: 1,
  },
  passwordHelper: {
    marginTop: 12,
  },
  redDot: {
    backgroundColor: theme.colors.redLight,
    borderRadius: '50%',
    height: 8,
    marginLeft: 8,
    width: 8,
  },
  tooltip: {
    width: 24,
    position: 'relative',
    '& > :first-child': {
      position: 'absolute',
      top: -10,
      left: 8,
    },
  },
}))

function Input(props) {
  const {
    compact,
    disabled,
    endAdornment,
    error,
    fullWidth,
    helperText,
    label,
    multiline,
    onChange,
    passwordHelper,
    placeholder,
    type,
    value,
    tooltip,
    ...rest
  } = props

  const classes = useStyles()

  return (
    <div>
      {!compact && label && (
        <div className={classes.label}>
          <Text ellipsis charcoalBase size={14}>
            {label}
          </Text>
          {tooltip && (
            <div className={classes.tooltip}>
              <Tooltip {...tooltip} />
            </div>
          )}
          {!!error && <div className={classes.redDot} />}
        </div>
      )}
      <InputBase
        {...rest}
        compact={compact}
        disabled={disabled}
        endAdornment={endAdornment}
        error={error}
        fullWidth={fullWidth}
        helperText={helperText}
        label={compact ? label : undefined}
        multiline={multiline}
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        value={value}
      />
    </div>
  )
}

Input.propTypes = {
  compact: PropTypes.bool,
  disabled: PropTypes.bool,
  endAdornment: PropTypes.node,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  passwordHelper: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  tooltip: PropTypes.object,
}

Input.defaultProps = {
  compact: false,
  disabled: false,
  endAdornment: null,
  error: null,
  fullWidth: false,
  helperText: '',
  multiline: false,
  onChange: () => {},
  passwordHelper: false,
  placeholder: '',
  type: 'text',
  value: '',
  tooltip: null,
}

export default React.memo(Input)

import React from 'react'
import PropTypes from 'prop-types'
import { MuiThemeProvider, CssBaseline } from '@material-ui/core'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import withStyles from '@material-ui/core/styles/withStyles'
import globalStyles from 'globalStyles'
import { colors as virtualColors } from 'components/virtual/VirtualTheme'

export const colors = {
  alertRed: '#ff3b2a',
  aquamarine: '#9ad8fb',
  azure: '#03a9f4',
  cerulean: '#0176c8',
  cloudGrey: '#b7c3ce',
  cobalt: '#1b3088',
  cobaltBlue: '#0047ab',
  coral: '#ff7043',
  coralLight: '#fff0eb',
  cornflower: '#d9eaf6',
  cream: '#fbfbfb',
  crystal: '#f4f9fc',
  darkBlue: '#0176c8',
  darkGrey: '#b7c3cd',
  darkGreen: '#071815',
  diamondBlue: '#cdecfd',
  foggy: '#ecf1f3',
  grayishBlue: '#2b3f48',
  iceBlue: '#e0f2f9',
  ink: '#2c3541',
  lightBlue: '#dff2f9',
  lightRed: '#FF3C2A',
  lightYellow: '#ffeaa9',
  neonMint: '#64ffda',
  pumpkin: '#ffe6de',
  seaGreen: '#14cba8',
  silver: '#dee4e5',
  stone: '#475464',
  stormySkies: '#5c7f98',
  white: '#fff',
  ...virtualColors,
}

export const shadows = {
  main: '0 1px 2px 0 rgba(0,0,0,0.2)',
  double: '0 2px 4px 0 rgba(0,0,0,0.2)',
}

const defaultTheme = createMuiTheme()
export const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.azure,
      dark: colors.azure,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.seaGreen,
      dark: colors.seaGreen,
      contrastText: colors.white,
    },
    transparent: {
      main: colors.azure,
    },
    divider: 'rgba(0,0,0,.12)',
    action: {
      hover: colors.silver,
      selected: colors.silver,
    },
  },
  // Shadows has to be 25 of length all the time...
  shadows: ['none', ...[...Array(24)].map(() => shadows.main)],
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: colors.blueDarker,
      },
      colorSecondary: {
        backgroundColor: colors.white,
        borderBottom: `1px solid ${colors.silver}`,
      },
      root: {
        height: 64,
      },
    },
    MuiAvatar: {
      root: {
        width: 48,
        height: 48,
        '& img': {
          objectFit: 'contain',
        },
      },
    },
    MuiBadge: {
      badge: {
        transform: 'scale(1) translate(50%, -40%)',
      },
    },
    MuiButton: {
      root: {
        height: 36,
        boxShadow: shadows.main,
        padding: '0 8px',
        '&:disabled': {
          color: `${colors.white} !important`,
          backgroundColor: `${colors.darkGrey} !important`,
          boxShadow: `${shadows.main} !important`,
        },
        [defaultTheme.breakpoints.down('xs')]: {
          padding: '0 16px',
        },
        textTransform: 'none',
      },
      label: {
        fontFamily: 'Larsseit-Medium',
        fontSize: '14px',
      },
      textPrimary: {
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: colors.diamondBlue,
        },
        '&:disabled': {
          backgroundColor: `${colors.white} !important`,
          color: `${colors.darkGrey} !important`,
          boxShadow: 'none !important',
        },
        '&:hover[disabled]': {
          color: `${colors.darkGrey} !important`,
          boxShadow: 'none !important',
          backgroundColor: `${colors.silver} !important`,
        },
      },
      sizeSmall: {
        height: 24,
        minWidth: 86,
        padding: '0 16px',
        '& > *': {
          fontSize: '12px',
          lineHeight: '12px',
        },
      },
    },
    MuiCard: {
      root: {
        marginBottom: 16,
      },
    },
    MuiCheckbox: {
      root: {
        marginLeft: 0,
        '& span:first-child': {
          color: colors.aquamarine,
        },
      },
    },
    MuiChip: {
      root: {
        borderRadius: 0,
        backgroundColor: colors.foggy,
        height: 28,
      },
      label: {
        fontFamily: 'Larsseit-Light',
        fontSize: '14px',
      },
      deleteIcon: {
        color: colors.ink,
      },
    },
    MuiDrawer: {
      paper: {
        width: '100%',
      },
      paperAnchorRight: {
        maxWidth: 320,
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
        margin: '8px 0 0',
      },
    },
    MuiCardContent: {
      root: {
        padding: '16px 20px',
        '&:last-child': {
          paddingBottom: 16,
        },
      },
    },
    MuiFormControlLabel: {
      root: {
        alignItems: 'start',
        '& span:nth-child(2)': {
          marginTop: 7,
        },
      },
    },
    MuiIconButton: {
      colorInherit: {
        backgroundColor: 'transparent',
        color: colors.ink,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      root: {
        backgroundColor: colors.azure,
        height: 32,
        width: 32,
        marginLeft: 24,
        padding: 'unset',
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      label: {
        color: colors.white,
      },
      colorPrimary: {
        backgroundColor: 'white !important',
        '& [class*=label-]': {
          color: `${colors.azure} !important`,
        },
      },
      colorSecondary: {
        backgroundColor: 'white',
        '& [class*=label-]': {
          color: `${colors.ink} !important`,
        },
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: 'Larsseit-Light',
        color: colors.ink,
      },
    },
    MuiInput: {
      root: {
        border: `solid 1px ${colors.silver}`,
        transition: 'border .1s linear',
        backgroundColor: 'white',
        minHeight: 40,
        padding: '0 12px',
        width: '100%',
        fontSize: 14,
        borderRadius: 2,
        '&:before': {
          display: 'none',
        },
        '&:after': {
          display: 'none',
        },
        '&$focused': {
          borderColor: colors.azure,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '& > fieldset': {
          display: 'none',
        },
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiListItem: {
      root: {
        flex: 1,
        width: 'unset',
      },
    },
    MuiListItemText: {
      primary: {
        fontFamily: 'Larsseit-Light',
        color: colors.ink,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 0,
      },
    },
    MuiMenu: {
      paper: {
        marginTop: 42,
        minWidth: 196,
      },
    },
    MuiMenuItem: {
      root: {
        '&:hover': {
          backgroundColor: colors.silver,
        },
        fontSize: 14,
      },
    },
    MuiSelect: {
      icon: {
        fontSize: 24,
        color: colors.ink,
      },
      select: {
        '&:focus': {
          backgroundColor: 'none',
        },
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: '20px',
        color: colors.stone,
      },
      fontSizeLarge: {
        fontSize: '27px',
      },
    },
    MuiToolbar: {
      regular: {
        minHeight: '100%',
      },
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Larsseit',
      'Larsseit-Light',
      'Larsseit-Medium',
      'Larsseit-Bold',
      'sans-serif',
      'serif',
      'verdana',
    ].join(','),
  },
  shape: {
    borderRadius: 2,
  },
  mixins: {
    colors,
    shadows,
  },
  colors,
})

const StylesWrapper = withStyles(globalStyles)(({ children }) => children)

function GlobalTheme({ children }) {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <StylesWrapper>{children}</StylesWrapper>
    </MuiThemeProvider>
  )
}

GlobalTheme.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.array]).isRequired,
}

export default GlobalTheme
